import { Params } from 'react-router-dom';
import apiClient from '../interceptors/axiosApiInterceptor';

export const makeCall = (customer: any) =>
  apiClient.post(`/channel/voice/phone-call`, customer);

export const placeCall = (obj: any) =>
  apiClient.post(`/channel/voice/phone-call`, obj);

export const getCallLogs = (page: number, params: Params) =>
  apiClient.get(`/voice/call/log?page=${page}`, { 
    params: params,
   });

export const getCallConversations = (page: number, inbox: number) =>
  apiClient.get(`/conversations/call/${inbox}?page=${page}`);

export const getSingleCallLog = (params: any) =>
  apiClient.get(`/voice/call/log`, { params });

export const searchCallLogs = (page: number, number: string) =>
  apiClient.get(`/search/call?q=${number}&page=${page}`);

export const getCallRecording = (id: number) =>
  apiClient.get(`/voice/call/recording/${id}`);

export const getInboxes = () =>
  apiClient.get(`/voice/inbox`);

export const updateInbox = (obj: any) =>
  apiClient.put(`/voice/inbox`, obj);

export const deleteInbox = (id: number) =>
  apiClient.delete(`/voice/inbox/${id}`);

export const killCall = (obj: any) =>
  apiClient.put(`/voice/call/outbound`, obj);

export const getNewCloudNumber = (page: number, state: string = 'All') =>
  apiClient.get(`/channel/voice/cloud-number`, {
    params: state === 'All' ? { page } : { page, q: state },
  });

export const buyCloudNumber = (obj: any) =>
  apiClient.post(`/voice/number/buy`, obj);

export const getCloudNumberAssignees = (id: number, page: number = 1, status: string = 'all', search: string = '') => {
  let params: Record<string, any> = {};
  if (status !== 'all') params['status'] = status;
  if (search !== '') params['q'] = search;
  return apiClient.get(`/channel/voice/cloud-number/assign/${id}`, { params });
};

export const assignToCloudNumber = (obj: any) =>
  apiClient.post(`/channel/voice/cloud-number/assign`, obj);

export const deleteCloudNumber = (obj: any) =>
  apiClient.patch(`/channel/voice/inbox`, obj);

export const assignLabelToCall = (obj: any) =>
  apiClient.put(`/labels/call`, obj);

export const holdCall = (obj: any) =>
  apiClient.put(`/channel/voice/phone-call/hold`, obj);

export const muteCall = (obj: any) =>
  apiClient.put(`/channel/voice/phone-call/mute`, obj);

export const addCallLogNote = (id: number, obj: any) =>
  apiClient.post(`/note/${id}`, obj);

export const transferCall = (inbox: number, obj: any) =>
  apiClient.post(`/channel/voice/call/transfer/${inbox}`, obj);

export const getTunes = () =>
  apiClient.get(`/channel/voice/tune`);

export const rateCallLog = (obj: any) =>
  apiClient.post(`/voice/call/rating`, obj);

export const importNumber = (data: any) =>
  apiClient.post(`/voice/number/import`, data);

export const searchAreaCode = (data: string) =>
  apiClient.get(`/voice/number/inventory?area_code=${data}`);

export const addLabel = (inbox: number, obj: any) =>
  apiClient.post(`/labels/${inbox}`, obj);

export const updateLabel = (inbox: number, obj: any, id: number) =>
  apiClient.put(`/labels/${inbox}/${id}/`, obj);

export const getLabel = (inbox: number, pageNumber: number) =>
  apiClient.get(`/labels/${inbox}?page=${pageNumber}`);

export const deleteLabel = (inbox: number, id: number) =>
  apiClient.delete(`/labels/${inbox}/${id}/`);

export const assignLabel = (inbox: number, obj: any) =>
  apiClient.put(`/labels/${inbox}/call`, obj);

export const getAssignLabel = (inbox: number) =>
  apiClient.get(`/labels/${inbox}/call`);
