import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const GoogleLogin = () => {
  const [searchParams] = useSearchParams();
  const googleClientId = searchParams.get("google_client_id");
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Check if the script is already loaded
    // @ts-ignore
    if (window.google?.accounts) {
      setScriptLoaded(true);
      return;
    }

    // Load Google API script dynamically
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    script.onerror = () => console.error("Failed to load Google API script");
    
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (scriptLoaded && googleClientId) {
      requestCode(googleClientId);
    }
  }, [scriptLoaded, googleClientId]);

  const requestCode = (clientId: string) => {
    // @ts-ignore
    if (!window.google?.accounts) {
      console.error("Google API is not available yet.");
      return;
    }

    // @ts-ignore
    window.google.accounts.oauth2
      .initCodeClient({
        client_id: clientId,
        scope:
          "openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        prompt: "consent",
        access_type: "offline", // Important to request offline access for refresh token
        callback: (response: any) => {
          console.log(response);
          if (response.code) {
            const input = {
              code: response.code,
              redirect_uri: window.location.origin,
            };

            // Send data to the parent window
            if (window.opener) {
              console.log("Inside opener");
              window.opener.postMessage(input, "*");
            }

            // Close the popup
            window.close();
          }
        },
      })
      .requestCode();
  };

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-[var(--bg-color)] flex flex-col items-center justify-start p-[60px] gap-[24px] font-inter text-[20px] font-medium leading-[30px] text-left text-[var(--primary-color)]">
      Google Login in process...
    </div>
  );
};

export default GoogleLogin;
