import React, { useEffect, useState } from 'react';
import { Button } from "../shadcn/button";
import { AgentTemplate } from '../../models/agent-templates';
import { CirclePause, Play, Plus } from 'lucide-react';

interface AgentTemplateProps {
  loading?: boolean;
  agent?: AgentTemplate;
  disable?: boolean;
  playing?: number;
  icon?: string;
  isPrompt?: boolean;
  onCreate?: (status: boolean) => void;
  onAudioStarted?: (status: boolean) => void;
}

const AgentTemplateComponent: React.FC<AgentTemplateProps> = ({
  loading = false,
  agent,
  disable = false,
  playing = 0,
  icon = './assets/svg/avatar.svg',
  isPrompt = false,
  onCreate,
  onAudioStarted,
}) => {
  const [audio, setAudio] = useState(new Audio());
  const [audioPlaying, setAudioPlaying] = useState(false);

  useEffect(() => {
    if (playing !== agent?.id && audioPlaying) {
      pauseAudio();
    }
  }, [playing]);

  useEffect(() => {
    // console.log("Agent changes"+ JSON.stringify(agent))
  }, [agent]);

  const playAudio = (url: string) => {
    if (!url) return;
    onAudioStarted?.(true);
    setAudioPlaying(true);
    const newAudio = new Audio(url);
    newAudio.play();
    newAudio.addEventListener('ended', () => {
      setAudioPlaying(false);
    });
    setAudio(newAudio);
  };

  const pauseAudio = () => {
    setAudioPlaying(false);
    audio.pause();
  };

  return (
    <div className="border border-gray-300 bg-white rounded-lg p-6 flex flex-col gap-8 w-[480px]">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <img
            src={agent?.voice?.avatar_url || icon}
            alt="Avatar"
            className="w-12 h-12 rounded-full"
          />
          <div className="flex flex-col gap-0.5">
            <span className="text-gray-800 text-base font-medium">{agent?.agent_name ?? 'something'}</span>
            <span className="text-gray-600 text-sm">{agent?.category}</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {!audioPlaying ? (
            <button
              disabled={loading || disable}
              onClick={() => playAudio(agent?.voice?.preview_url || '')}
              className={`w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center hover:bg-gray-200 disabled:bg-gray-200 ${
                loading || disable ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              <Play />
            </button>
          ) : (
            <button
              disabled={loading || disable}
              onClick={pauseAudio}
              className={`w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center hover:bg-gray-200 disabled:bg-gray-200 ${
                loading || disable ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              <CirclePause />
            </button>
          )}
          <Button
            disabled={loading || disable}
            onClick={() => onCreate?.(true)}
            variant="outline"
          >
            <Plus /> {isPrompt ? 'Select template' : 'Create agent'}
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-sm text-gray-600">Perfect for</span>
        <div className="flex flex-wrap gap-4">
          {agent?.perfect_for?.map((label: any, idx: number) => (
            <div
              key={idx}
              className="bg-gray-200 px-2 py-1 rounded-md text-sm font-medium text-gray-700"
            >
              {label?.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentTemplateComponent;