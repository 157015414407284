import React, { useEffect, useState } from "react";
import { Button } from "../../shadcn/button";
import { Input } from "../../shadcn/input";
import { Textarea } from "../../shadcn/textarea";
import { ChevronDown, EllipsisVertical, Loader2 } from "lucide-react";
import { AgentAction } from "../../../models/agent-action";
import { updateAgentAction, addAgentAction } from "../../../lib/agents.utils";
import { useNavigate } from "react-router-dom";
import { SheetContent, Sheet } from "../../shadcn/sheet";
import IntegrationsList from "../../Settings/Integrations/IntegrationsList";
import useSharedStore from "../../../store/shared.store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown-menu";
import {
  getGhlCalendars,
  getGoogleCalendars,
  getCalCalendars,
} from "../../../lib/integrations.utils";
import Toaster from "../../shared/customToast";
import { Skeleton } from "../../shadcn/skeleton";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/popover";
import { formatDateToMonthDayYear } from "../../../lib/ui.utils";
import { Calendar } from "../../shadcn/calendar";

interface CalendarActionComponentProps {
  action: AgentAction;
  isEdit?: boolean;
  agentId: number;
  onSuccess?: (data: any) => void;
  onClose: () => void;
}

const bookingWindowInfo =
  "Limit the number of days in the future that AI can offer for bookings. If set to 5 days, the AI will only provide available slots up to 5 days ahead.";
const slotsPerDayInfo =
  "Limit the number of appointment slots the AI can offer per day. Even if more slots are available, the AI will only offer the set number (e.g., 4 slots) to the caller.";
const delayInfo =
  "Add a slight, intentional delay to the AI’s responses, making it a more natural conversation.";

const CalendarActionComponent: React.FC<CalendarActionComponentProps> = ({
  action,
  isEdit,
  agentId,
  onSuccess,
  onClose,
}) => {
  const [actionName, setActionName] = useState("");
  const [direction, setDirection] = useState("inbound");
  const [trigger, setTrigger] = useState("during_call");
  const [actionPrompt, setActionPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [ghlCalendarList, setGhlCalendarList] = useState<any[]>([]);
  const [calCalendarList, setCalCalendarList] = useState<any[]>([]);
  const [googleCalendarList, setGoogleCalendarList] = useState<any[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<any>(null);
  const [selectedApp, setSelectedApp] = useState<any>(null);
  const [isGhlConnected, setIsGhlConnected] = useState(false);
  const [isGoogleCalConnected, setIsGoogleCalConnected] = useState(false);
  const [isCalConnected, setIsCalConnected] = useState(false);
  const [meetingLocation, setMeetingLocation] = useState("google_meet");
  const [meetingLink, setMeetingLink] = useState("google meet");
  const [googleCalendarLoader, setGoogleCalendarLoader] = useState(false);
  const [calCalendarLoader, setCalCalendarLoader] = useState(false);
  const [ghlCalendarLoader, setGhlCalendarLoader] = useState(false);
  const [today] = useState(new Date());
  const [initialBookingDate, setInitialBookingDate] = useState<Date | null>(
    null
  );
  const [appointmentDuration, setAppointmentDuration] = useState(30);
  const [offeringDates, setOfferingDates] = useState(10);
  const [slotsPerDay, setSlotsPerDay] = useState(8);
  const [startTime, setStartTime] = useState(["08", "00", "am"]);
  const [endTime, setEndTime] = useState(["05", "00", "pm"]);
  const [breakTime, setBreakTime] = useState(0);
  const [workingHoursScreen, setWorkingHoursScreen] = useState(false);

  const [workingHours, setWorkingHours] = useState({
    sunday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    monday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    tuesday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    wednesday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    thursday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    friday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
    saturday: { start_hr: "08:00", end_hr: "17:00", is_off: false },
  });

  const [delay, setDelay] = useState(2);

  const sharedStore = useSharedStore();

  useEffect(() => {
    setIsOpen(true);

    if (action?.id && isEdit) {
      setActionName(action?.name);
      setDirection(action?.call_type ?? "inbound");
      setActionPrompt(action?.data?.action_prompt);

      if (action?.data?.location?.length > 0) {
        setMeetingLocation(action?.data?.location[0].type);
        setMeetingLink(action?.data?.location[0].value);
      }

      if (action?.data?.start_date)
        setInitialBookingDate(new Date(action?.data?.start_date));
      if (action?.data?.appointment_duration_min)
        setAppointmentDuration(action?.data?.appointment_duration_min);
      if (action?.data?.slots_per_day)
        setSlotsPerDay(action?.data?.slots_per_day);
      if (action?.data?.days_for_offering_dates)
        setOfferingDates(action?.data?.days_for_offering_dates);
      if (action?.data?.business_working_hr)
        setWorkingHours(action?.data?.business_working_hr);
      if (action?.data?.delay) setDelay(action?.data?.delay);
      if (action?.data?.slot_break) setBreakTime(action?.data?.slot_break);
    }

    if (sharedStore.integrationsList.length > 0) {
      setSelectedApp(sharedStore.integrationsList[0]);
    }
    sharedStore.integrationsList.forEach((element) => {
      if (action?.id && isEdit && element.provider === action?.data?.provider) {
        setSelectedApp(element);
      }

      if (element.provider === "ghl") {
        setIsGhlConnected(true);
        setGhlCalendarLoader(true);
        getGhlCalendars().then(
          (resp) => {
            setGhlCalendarList(resp.data.data);
            if (action?.id && isEdit && action?.data?.provider === "ghl") {
              const selected = resp.data.data.find(
                (el: any) => el.id === action?.data?.calendar_id
              );
              if (selected) setSelectedCalendar(selected);
            }
            setGhlCalendarLoader(false);
          },
          (error) => {
            console.log(error);
            setGhlCalendarLoader(false);
            Toaster.error(error);
          }
        );
      }

      if (element.provider === "google") {
        setIsGoogleCalConnected(true);
        setGoogleCalendarLoader(true);
        getGoogleCalendars().then(
          (resp) => {
            setGoogleCalendarList(resp.data.data);
            if (action?.id && isEdit && action?.data?.provider === "google") {
              const selected = resp.data.data.find(
                (el: any) => el.id === action?.data?.calendar_id
              );
              if (selected) setSelectedCalendar(selected);
            }
            setGoogleCalendarLoader(false);
          },
          (error) => {
            console.log(error);
            setGoogleCalendarLoader(false);
            Toaster.error(error);
          }
        );
      }

      if (element.provider === "cal") {
        setIsCalConnected(true);
        setCalCalendarLoader(true);
        getCalCalendars().then(
          (resp) => {
            setCalCalendarList(resp.data.data);
            if (action?.id && isEdit && action?.data?.provider === "cal") {
              const selected = resp.data.data.find(
                (el: any) => el.id === action?.data?.event_id
              );
              if (selected) setSelectedCalendar(selected);
            }
            setCalCalendarLoader(false);
          },
          (error) => {
            console.log(error);
            setCalCalendarLoader(false);
            Toaster.error(error);
          }
        );
      }
    });
    
  }, [action, isEdit]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    if (meetingLocation === "custom" && meetingLink === "") {
      Toaster.error("Add valid meeting link");
      setIsLoading(false);
      return;
    }

    if (selectedApp?.provider === "google" && !initialBookingDate) {
      Toaster.error("Add valid initial booking date");
      setIsLoading(false);
      return;
    }

    let dateString = "";
    if (initialBookingDate) {
      dateString = `${initialBookingDate.getFullYear()}-${
        initialBookingDate.getMonth() + 1
      }-${initialBookingDate.getDate()}`;
    }

    const input: Record<string, any> = {
      name: actionName,
      type: "calendar",
      call_type: direction,
      data: {
        provider: selectedApp?.provider,
        calendar_id:
          selectedApp?.provider !== "cal" ? selectedCalendar?.id : null,
        event_id: selectedApp?.provider === "cal" ? selectedCalendar?.id : null,
        location: [
          {
            type: meetingLocation,
            value: meetingLink === "" ? null : meetingLink,
          },
        ],
        start_date: selectedApp?.provider === "google" ? dateString : null,
        appointment_duration_min:
          selectedApp?.provider === "google" ? appointmentDuration : null,
        slots_per_day: slotsPerDay,
        days_for_offering_dates:
          selectedApp?.provider === "google" ? offeringDates : null,
        business_working_hr:
          selectedApp?.provider === "google" ? workingHours : null,
        calendar_type:
          selectedApp?.provider === "ghl"
            ? selectedCalendar?.calendarType
            : null,
        delay: delay,
        slot_break: breakTime,
      },
      trigger: "during_call",
    };

    // Removing unnecessary properties based on provider type
    if (selectedApp?.provider !== "google")
      delete input.data.business_working_hr;
    if (selectedApp?.provider === "cal") delete input.data.location;

    try {
      const response = isEdit
        ? await updateAgentAction(agentId, action?.id, input)
        : await addAgentAction(agentId, input);

      Toaster.success(response.data.message);
      onSuccess?.(response.data.data);
    } catch (error) {
      console.error(error);
      Toaster.error(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sheet
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <SheetContent className="p-0 w-[450px] !max-w-[450px]">
        <div className="bg-white p-6 flex flex-col h-full gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center text-primary text-lg font-semibold leading-[30px]">
              <span>Calendar</span>
            </div>
            <span className="text-secondary text-sm font-normal leading-[21px]">
              Use Calendar to schedule appointments.
            </span>
          </div>
          {sharedStore.integrationsList?.length > 0 && (
            <div className="bg-white flex flex-col h-full gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Action name
                </span>
                <Input
                  placeholder="e.g. Calendar booking"
                  value={actionName}
                  onChange={(e) => setActionName(e.target.value)}
                  className="border p-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">App</span>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      variant="outline"
                      className="w-full justify-between font-normal"
                    >
                      {selectedApp?.provider === "ghl" && (
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/gohighlevel.png"
                            alt=""
                          />
                          High Level
                        </span>
                      )}
                      {selectedApp?.provider === "google" && (
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/google_calendar.svg"
                            alt=""
                          />
                          Google calendar
                        </span>
                      )}
                      {selectedApp?.provider === "cal" && (
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/cal.png"
                            alt=""
                          />
                          Cal
                        </span>
                      )}{" "}
                      <ChevronDown />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1 w-[400px]">
                    {sharedStore.integrationsList.length > 0 &&
                      sharedStore.integrationsList?.map((app) => (
                        <DropdownMenuItem
                          key={app.provider}
                          onClick={() => setSelectedApp(app)}
                          className="cursor-pointer w-[400px]"
                        >
                          {app.provider === "ghl" && (
                            <span className="flex gap-2 items-center">
                              <img
                                className="w-[20px] h-[20px]"
                                src="./assets/images/gohighlevel.png"
                                alt="High Level"
                              />
                              High Level
                            </span>
                          )}
                          {app.provider === "google" && (
                            <span className="flex gap-2 items-center">
                              <img
                                className="w-[20px] h-[20px]"
                                src="./assets/images/google_calendar.svg"
                                alt="Google Calendar"
                              />
                              Google Calendar
                            </span>
                          )}
                          {app.provider === "cal" && (
                            <span className="flex gap-2 items-center">
                              <img
                                className="w-[20px] h-[20px]"
                                src="./assets/images/cal.png"
                                alt="Cal"
                              />
                              Cal
                            </span>
                          )}
                        </DropdownMenuItem>
                      ))}

                    {!isGhlConnected && (
                      <DropdownMenuItem className="cursor-default w-[400px] flex justify-between items-center">
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/gohighlevel.png"
                            alt="High Level"
                          />
                          High Level
                        </span>
                        <Button
                          size={"sm"}
                          onClick={() => navigate("/settings/integrations")}
                        >
                          Connect
                        </Button>
                      </DropdownMenuItem>
                    )}

                    {!isGoogleCalConnected && (
                      <DropdownMenuItem className="cursor-default w-[400px] flex justify-between items-center">
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/google_calendar.svg"
                            alt="Google Calendar"
                          />
                          Google Calendar
                        </span>
                        <Button
                          size={"sm"}
                          onClick={() => navigate("/settings/integrations")}
                        >
                          Connect
                        </Button>
                      </DropdownMenuItem>
                    )}

                    {!isCalConnected && (
                      <DropdownMenuItem className="cursor-default w-[400px] flex justify-between items-center">
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="./assets/images/cal.png"
                            alt="Cal"
                          />
                          Cal
                        </span>
                        <Button
                          size={"sm"}
                          onClick={() => navigate("/settings/integrations")}
                        >
                          Connect
                        </Button>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Calendar
                </span>
                {isLoading ? (
                  <Skeleton className="h-[40px]" />
                ) : (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        variant="outline"
                        className="w-full justify-between font-normal"
                      >
                        {selectedCalendar
                          ? selectedCalendar.name ||
                            selectedCalendar.title ||
                            selectedCalendar.summary
                          : "Select Calendar"}{" "}
                        <ChevronDown />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                      {selectedApp?.provider === "ghl" &&
                        ghlCalendarList.map((calendar) => (
                          <DropdownMenuItem
                            key={calendar.id}
                            onClick={() => setSelectedCalendar(calendar)}
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                          >
                            {calendar.name}
                          </DropdownMenuItem>
                        ))}

                      {selectedApp?.provider === "google" &&
                        googleCalendarList.map((calendar) => (
                          <DropdownMenuItem
                            key={calendar.id}
                            onClick={() => setSelectedCalendar(calendar)}
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                          >
                            {calendar.summary}
                          </DropdownMenuItem>
                        ))}

                      {selectedApp?.provider === "cal" &&
                        calCalendarList.map((calendar) => (
                          <DropdownMenuItem
                            key={calendar.id}
                            onClick={() => setSelectedCalendar(calendar)}
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                          >
                            {calendar.title}
                          </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Call type
                </span>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      variant="outline"
                      className="w-full justify-between font-normal"
                    >
                      {direction.charAt(0).toUpperCase() + direction.slice(1)}{" "}
                      <ChevronDown />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                    <DropdownMenuItem
                      onClick={() => setDirection("inbound")}
                      className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                    >
                      Inbound
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setDirection("outbound")}
                      className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                    >
                      Outbound
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setDirection("both")}
                      className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                    >
                      Both
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              {selectedApp?.provider !== "cal" && (
                <div className="flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium">
                    Meeting location
                  </span>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        variant="outline"
                        className="w-full justify-between font-normal"
                      >
                        {meetingLocation === "google_meet" && (
                          <span className="flex gap-2 items-center">
                            <img
                              className="w-[20px] h-[20px]"
                              src="../../../../../assets/images/meet.svg"
                              alt="Google Meet"
                            />
                            Google Meet
                          </span>
                        )}
                        {meetingLocation === "zoom" && (
                          <span className="flex gap-2 items-center">
                            <img
                              className="w-[20px] h-[20px]"
                              src="../../../../../assets/images/zoom.svg"
                              alt="Zoom"
                            />
                            Zoom
                          </span>
                        )}
                        {meetingLocation === "custom" && (
                          <span className="flex gap-2 items-center">
                            Custom
                          </span>
                        )}
                        <ChevronDown />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                      <DropdownMenuItem
                        onClick={() => {
                          setMeetingLocation("google_meet");
                          setMeetingLink("google meet");
                        }}
                        className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                      >
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="../../../../../assets/images/meet.svg"
                            alt="Google Meet"
                          />
                          Google Meet
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setMeetingLocation("zoom");
                          setMeetingLink("zoom");
                        }}
                        className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                      >
                        <span className="flex gap-2 items-center">
                          <img
                            className="w-[20px] h-[20px]"
                            src="../../../../../assets/images/zoom.svg"
                            alt="Zoom"
                          />
                          Zoom
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setMeetingLocation("custom");
                          setMeetingLink("");
                        }}
                        className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                      >
                        Custom
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  {meetingLocation === "custom" && (
                    <Input
                      placeholder="e.g. https://somemeetingurl.com"
                      value={meetingLink}
                      onChange={(e) => setMeetingLink(e.target.value)}
                      className="border p-2 rounded-lg mt-2"
                    />
                  )}
                </div>
              )}
              {selectedApp?.provider === "google" && (
                <>
                  <div className="flex flex-col gap-1">
                    <span className="text-primary text-sm font-medium">
                      Starting Date
                    </span>
                    <Popover modal={false}>
                        <PopoverTrigger asChild>
                          <button className="flex items-center w-[calc(100%-120px)] h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]">
                            {initialBookingDate ? (
                              formatDateToMonthDayYear(initialBookingDate)
                            ) : (
                              <span className="text-secondary">
                                Select date
                              </span>
                            )}
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="z-[9999] w-auto p-0 pointer-events-auto">
                          <Calendar
                            mode="single"
                            selected={initialBookingDate ?? new Date()}
                            onSelect={(event) => {
                              if (event) {
                                let newDateValue = new Date(event);
                                let currentTime = new Date();
                                newDateValue.setHours(currentTime.getHours());
                                newDateValue.setMinutes(
                                  currentTime.getMinutes()
                                );
                                newDateValue.setSeconds(
                                  currentTime.getSeconds()
                                );
                                newDateValue.setMilliseconds(
                                  currentTime.getMilliseconds()
                                );
                                setInitialBookingDate(newDateValue);
                              }
                            }}
                          />
                        </PopoverContent>
                      </Popover>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-primary text-sm font-medium">
                      Duration
                    </span>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <Button
                          variant="outline"
                          className="w-full justify-between font-normal"
                        >
                          {appointmentDuration} Minutes <ChevronDown />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                        {[15, 30, 45, 60].map((duration) => (
                          <DropdownMenuItem
                            key={duration}
                            onClick={() => setAppointmentDuration(duration)}
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                          >
                            {duration}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-primary text-sm font-medium">
                      Booking Window
                    </span>
                    <Input
                      type="number"
                      value={offeringDates}
                      onChange={(e) => setOfferingDates(Number(e.target.value))}
                      className="border p-2 rounded-lg"
                      max={30}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-primary text-sm font-medium">
                      Break between slots
                    </span>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <Button
                          variant="outline"
                          className="w-full justify-between font-normal"
                        >
                          {breakTime} Minutes <ChevronDown />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                        {[0, 15, 30, 45, 60].map((breakDuration) => (
                          <DropdownMenuItem
                            key={breakDuration}
                            onClick={() => setBreakTime(breakDuration)}
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]"
                          >
                            {breakDuration}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </>
              )}
              <div className="flex items-center justify-between">
                <span className="text-primary text-sm font-medium">Delay</span>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      variant="outline"
                      className="w-full flex gap-[30px] justify-between font-normal"
                    >
                      {delay}  <span className="flex items-center gap-3"><ChevronDown /> Sec</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1 max-h-[300px] overflow-y-auto">
                    {[...Array(11).keys()].map((d) => (
                      <DropdownMenuItem
                        key={d}
                        onClick={() => setDelay(d)}
                        className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                      >
                        {d}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-primary text-sm font-medium">
                  Slots per Day
                </span>
                <Input
                  type="number"
                  value={slotsPerDay}
                  onChange={(e) => setSlotsPerDay(Number(e.target.value))}
                  className="border p-2 rounded-lg w-[125px]"
                  max={10}
                />
              </div>
              <div className="flex justify-end">
                <Button
                  disabled={isLoading || !actionName || !selectedCalendar}
                  onClick={handleSubmit}
                  className="bg-primary text-white p-2 rounded-lg"
                >
                  {isLoading ? (
                    <span className="flex gap-2 align-center">
                      <Loader2 className="animate-spin" /> Loading...
                    </span>
                  ) : (
                    <span>{isEdit ? "Save changes" : "Add action"}</span>
                  )}
                </Button>
              </div>
            </div>
          )}
          {sharedStore.integrationsList?.length === 0 && (
            <div className="flex flex-col gap-4 h-full w-full justify-center items-center text-sm font-normal text-[var(--primary-color)] py-[120px]">
              <img
                src="../../../../../assets/images/calendar_empty.png"
                alt=""
              />
              <span>Connect calendars for appointments</span>
              <div className="flex gap-4">
                <img
                  className="w-[24px] h-[24px]"
                  src="../../../../../assets/images/gohighlevel.png"
                  alt=""
                />
                <img
                  className="w-[24px] h-[24px]"
                  src="../../../../../assets/images/google_calendar.svg"
                  alt=""
                />
                <img
                  className="w-[24px] h-[24px]"
                  src="../../../../../assets/images/cal.png"
                  alt=""
                />
                <img
                  className="w-[24px] h-[24px]"
                  src="../../../../../assets/images/calendly.png"
                  alt=""
                />
              </div>
              <Button onClick={() => navigate("/settings/integrations")}>
                Connect Calendar
              </Button>
            </div>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default CalendarActionComponent;
