import React from "react";
import ReactDOM from "react-dom/client";
import "./input.css";
import "./App.css";
// import reportWebVitals from './reportWebVitals';
import "./output.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { Toaster } from "./components/shadcn/sonner";
import ErrorBoundary from "./components/shared/ErrorBoundary";

const queryClient = new QueryClient();

const addExternalScripts = () => {
  // Add Produktly Script
  const produktlyScript = document.createElement("script");
  produktlyScript.src = "https://public.produktly.com/js/main.js";
  produktlyScript.async = true;
  produktlyScript.id = "produktlyScript";
  produktlyScript.dataset.clientToken =
    "012dc0df8866cbc7223874b8c399c2f2575dc88a5788c37faa92116d301310e0ed9e7cdc0ba9d77c0edf573bd737e967c6813f69d733c08423083041d9dec42c1a300dc61fb6badfccf9f9a4b2e0b304d9c4daf7c4a54c7e5ee917a54245339304b45e69";
  document.head.appendChild(produktlyScript);

  // Add Tolt Script
  const toltScript = document.createElement("script");
  toltScript.src = "https://cdn.tolt.io/tolt.js";
  toltScript.async = true;
  toltScript.dataset.tolt = "37411156-0586-49d1-bbdb-8cb0838a29ee";
  document.head.appendChild(toltScript);

  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

// Ensure scripts are added only once
if (typeof window !== "undefined") {
  addExternalScripts();
}

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const params = new URLSearchParams(window.location.search);
const token = params.get("client_token");

// Store token in sessionStorage before React initializes
if (token) {
  sessionStorage.setItem(
    "client_token",
    `JWT ${params.get("client_token")}`
  );
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
