import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Loader2 } from 'lucide-react';
import { Button } from '../../shadcn/button';
import ConfirmationPopup from '../../shared/ConfirmationPopup';
import { getIntegrations, deleteIntegration } from '../../../lib/integrations.utils';
import Toaster from '../../shared/customToast';
import CalConnection from '../../shared/CalConnection';
import useSharedStore from '../../../store/shared.store';

const CalDotComView = () => {
  const navigate = useNavigate();
  const [cal, setCal] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [uninstallPopup, setUninstallPopup] = useState(false);
  const [connectCalPopup, setConnectCalPopup] = useState(false);
  const [integration, setIntegration] = useState<any>(null);

  const sharedStore = useSharedStore();

  useEffect(() => {
    getIntegrationsList();
  }, []);

  const getIntegrationsList = () => {
    setLoader(true);
    getIntegrations().then(resp => {
      const integrations = resp.data.data;
      integrations.forEach((element: any) => {
        if (element.provider === 'cal') {
          setIntegration(element);
          setCal(element.cal);
        }
      });
      setLoader(false);
    }).catch(error => {
      console.error(error);
      setLoader(false);
    });
  };

  const uninstallCal = () => {
    setUninstallPopup(false);
    setCal(null);
    deleteIntegration(integration?.id).then(resp => {
      Toaster.success(resp.data.message);
    }).catch(error => {
      Toaster.error(error);
    });
  };

  const backToList = () => {
    navigate('/settings/integrations');
  };

  return (
    <div className="main-container">
      <div className="flex justify-between items-center h-16 p-4 border-b border-gray-300 text-primary">
        <div className="flex items-center gap-2 text-primary font-inter text-lg font-medium leading-7">
          <span style={{ cursor: 'pointer' }} onClick={backToList}>
            <ArrowLeft className="text-gray-600" size="24px" />
          </span>
          Integrations
        </div>
      </div>
      {loader ? (
        <div className="loader-container flex justify-center p-8">
          <Loader2 size={'16px'} />
        </div>
      ) : (
        <div className="agent-details flex flex-col gap-4 p-6 overflow-y-scroll">
          <div className="ghl-heading flex items-center gap-8 justify-between">
            <div className="flex items-center gap-4">
              <span className="card-image-status flex justify-between items-center h-12 w-12">
                <img src="../../../../assets/images/cal.png" alt="" className="w-12 h-12 object-cover" />
              </span>
              <div className="ver-8px flex flex-col gap-2">
                <span className="heading text-2xl font-normal text-primary">Cal.com</span>
                <span className="tag bg-gray-100 rounded px-2 py-1 text-sm font-medium text-primary w-fit">Calendar</span>
              </div>
            </div>
            {!cal ? (
              <>{(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && <Button onClick={() => setConnectCalPopup(true)}>Install app</Button>}</>
            ) : (
              <>{(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.delete) && <Button variant="destructive" onClick={() => setUninstallPopup(true)}>Uninstall</Button>}</>
            )}
          </div>
          <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Website</span>
            <a href="https://cal.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://cal.com</a>
          </div>
          <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Overview</span>
            <span className="subheading text-sm font-normal text-secondary">
              The integration with Cal.com transforms scheduling into an effortless experience for your team and customers. By combining advanced AI calling capabilities with Cal.com’s flexible calendar management, businesses can fully automate appointment booking, rescheduling, and reminders. This integration lets you simplify workflows and increase efficiency by enabling AI agents to handle scheduling tasks seamlessly, even in high-volume scenarios.
            </span>
          </div>
          <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Key Benefits</span>
            <span className="subheading ver-2px flex flex-col gap-0.5 text-sm font-normal text-secondary">
              <span className="col-pri text-primary">Automated Scheduling and Rescheduling</span>
              Free up valuable time by allowing our AI-powered agents to take over appointment bookings and rescheduling tasks. Integrates with Cal.com to check real-time availability and instantly confirm bookings on your behalf.
              <span className="col-pri text-primary">Enhanced Customer Experience</span>
              Provide a smoother, faster experience by offering 24/7 booking capabilities. AI agents can assist customers anytime, checking availability and scheduling appointments within seconds.
              <span className="col-pri text-primary">Real-Time Syncing</span>
              All appointments are automatically synced with your Cal.com calendar, ensuring you and your team have the latest information without needing manual updates.
            </span>
          </div>
        </div>
      )}
      {uninstallPopup && (
        <ConfirmationPopup
          heading="Confirm uninstall Cal.com"
          description="Uninstalling the Cal.com app will also delete all calendar actions associated with your account."
          negativeButtonText="No, keep Cal.com"
          positiveButtonText="Yes, uninstall Cal.com"
          onClose={() => setUninstallPopup(false)}
          onSuccess={uninstallCal}
        />
      )}
      {connectCalPopup && (
        <CalConnection onClose={() => setConnectCalPopup(false)} onSuccess={() => {setConnectCalPopup(false); getIntegrationsList()}}></CalConnection>
      )}
    </div>
  );
};

export default CalDotComView;
