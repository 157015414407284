import React, { useState, useEffect } from "react";
import Toaster from "../../shared/customToast";
import { Widget } from "../../../models/widget";
import { Input } from "../../shadcn/input";
import { Button } from "../../shadcn/button";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { addWidget } from "../../../lib/widget.utils";

interface AddWidgetProps {
  onClose: () => void;
  onSuccess: (widget: Widget) => void;
}

const AddWidget: React.FC<AddWidgetProps> = ({ onClose, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onAddWidget = async () => {
    setIsLoading(true);
    const input = { name };
    addWidget(input).then((resp) => {
      setIsLoading(false);
      Toaster.success(resp.data.message);
      onSuccess(new Widget(resp.data.data));
      setIsOpen(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
      Toaster.error((error));
    })
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
        <div className="flex flex-col gap-1 justify-between text-[var(--primary-color)] font-inter font-semibold text-20 leading-30">
          <span>Add widget</span>
            <span className="text-gray-500 font-inter text-[14px] font-normal leading-21 text-left">
            Create, manage and deploy voice AI agent in your website
            </span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">Name</span>
            <Input
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end p-2">
          <Button disabled={isLoading} onClick={onAddWidget}>
            {isLoading ? "Loading..." : "Create widget"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddWidget;
