import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Settings } from "lucide-react";
import { Skeleton } from "../../shadcn/skeleton";
import { LoggedUser } from "../../../models/logged-user";
import { CloudNumber } from "../../../models/cloud-number";
import { getInboxes, getTunes } from "../../../lib/voice.utils";
import useSharedStore from "../../../store/shared.store";
import ViewNumber from "./ViewNumber";
import { Button } from "../../shadcn/button";
import AddPhoneNumber from "./AddPhoneNumber";
import ImportNumber from "./ImportNumber";
import { formatToE164 } from "../../../lib/ui.utils";

const PhoneNumbers = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );
  const [cloudNumberView, setCloudNumberView] = useState(false);
  const [viewedCloudNumberIndex, setViewedCloudNumberIndex] = useState<
    number | undefined
  >();
  const [viewedCloudNumber, setViewedCloudNumber] = useState<
    CloudNumber | undefined
  >();
  const [numberBuy, setNumberBuy] = useState(false);
  const [numberImport, setNumberImport] = useState(false);
  const [loaderList] = useState([1, 2, 3, 4, 5, 6]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberCount, setNumberCount] = useState(0);

  const sharedStore = useSharedStore();

  useEffect(() => {
    const numberId = window.location.pathname.split("/").pop();
    if (Number(numberId)) {
      // handle numberId logic
    } else {
      navigate("/settings/phone-numbers/");
    }
    getCloudInboxes();
  }, []);

  const addNumber = () => {
    setNumberBuy(true);
  };

  const getCloudInboxes = async () => {
    setIsLoading(true);
    try {
      const response = await getInboxes();
      console.log(response);
      if (response.data?.code === 200) {
        if (response.data.count) setNumberCount(response.data.count);
        sharedStore.setCloudNumbers(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTuneList = async () => {
    try {
      const response = await getTunes();
      console.log(response);
      if (response.data?.code === 200) {
        sharedStore.callTunes = response.data.data;
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cloudNumberClicked = (index: number) => {
    setCloudNumberView(true);
    setViewedCloudNumberIndex(index);
    setViewedCloudNumber(new CloudNumber(sharedStore.cloudNumbers[index]));
  };

  const updateList = (event: any) => {
    setCloudNumberView(false);
  };

  return (
    <div className="main-container h-full">
      {!cloudNumberView ? (
        <>
          <div className="container-heading flex justify-between items-center h-16 p-4 border-b border-gray-300 text-primary text-[20px] font-medium leading-[30px] text-left">
            <span className="flex items-center gap-2">
              Phone numbers{" "}
              {sharedStore.cloudNumbers.length > 0 && (
              <span className="count text-gray-500">
                {sharedStore.cloudNumbers.length}
              </span>
              )}
            </span>
            <div className="flex gap-2">
              {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.phone_number?.edit) &&  (
              <>
                <Button onClick={() => setNumberBuy(true)}>Add number</Button>
                <Button variant="outline" disabled={true} onClick={() => setNumberImport(true)}>
                Import number
                </Button>
              </>
              )}
            </div>
            </div>
          {!isLoading && sharedStore.cloudNumbers.length > 0 ? (
            <div className="agent-details flex flex-col gap-4 p-6 border-b border-gray-300 overflow-y-scroll h-[calc(100vh-64px)]">
              <div className="card-fields flex flex-col gap-1">
                <span className="heading flex gap-2 text-[var(--primary)] font-medium text-[16px] leading-[24px]">
                  Your numbers
                </span>
                <span className="subheading text-gray-500 text-[14px] leading-[21px]">
                  Manage phone number and calls
                </span>
              </div>
              {sharedStore.cloudNumbers.map((number, i) => (
                <div
                  key={i}
                  className={`number-hover flex items-center gap-2 p-1 rounded-lg cursor-pointer ${
                    (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.phone_numbers["number_"+number?.id]?.edit) ? "inactive cursor-default" : ""
                  } hover:bg-[#F0F2F5]`}
                  onClick={() =>
                    (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.phone_numbers["number_"+number?.id]?.edit) ? cloudNumberClicked(i) : null
                  }
                >
                  <span className="cloud-number-logo flex items-center justify-center w-9 h-9 rounded-full border border-gray-300 bg-gray-200 text-lg">
                  {number?.icon}
                  </span>
                  <span className="cloud-number-name-number flex flex-col items-start justify-center w-[calc(100%-76px)]">
                  <p className="name text-gray-900 text-base m-0">
                    {number?.name}{" "}
                    {!number?.is_active && (
                    <span className="deleted text-gray-500 ml-2">
                      (Deleted)
                    </span>
                    )}
                  </p>
                  <p className="number text-gray-500 text-sm m-0">
                    {formatToE164(number?.number)}
                  </p>
                  </span>
                  {user?.user_type === 1 && (
                  <span className="flex">
                    <Settings />
                  </span>
                  )}
                </div>
              ))}
            </div>
          ) : isLoading ? (
            <div className="agent-details flex flex-col gap-4 p-6 border-b border-gray-300 overflow-y-scroll h-[calc(100vh-64px)]">
              {loaderList.map((loader, index) => (
                <div key={index} className="loader-list flex gap-1">
                  <span className="skeleton-loader circle w-20">
                    <Skeleton className="w-12 h-12 bg-gray-300 rounded-full"></Skeleton>
                  </span>
                  <span className="description-loader flex flex-col w-full">
                    <span className="skeleton-loader first w-3/5">
                      <Skeleton className="h-4 bg-gray-300 rounded w-full"></Skeleton>
                    </span>
                    <span className="skeleton-loader second w-3/5">
                      <Skeleton className="h-4 bg-gray-300 rounded w-full"></Skeleton>
                    </span>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-screen flex flex-col items-center justify-center gap-4 w-full h-full text-[var(--primary)]">
              <div className="empty-card flex flex-col items-center justify-center gap-4">
                <img
                  src="../../../../assets/images/phone_number_empty.png"
                  alt="Empty"
                />
                <span>Add a phone to start the journey</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <ViewNumber viewedCloudNumber={viewedCloudNumber} back={updateList} />
      )}
      {numberBuy && <AddPhoneNumber onClose={() => setNumberBuy(false)} onSuccess={() => setNumberBuy(false)} />}
      {numberImport && <ImportNumber onClose={() => setNumberImport(false)} onSuccess={() => setNumberImport(false)} />}
    </div>
  );
};

export default PhoneNumbers;
