export class Webhook {
  private _id: number
  private _success_count: number
  private _failed_count: number
  private _agents: Agent[]
  private _added_by: AddedBy
  private _name: string
  private _url: string
  private _created_at: string
  private _updated_at: string
  private _business: number
  private _is_enabled: boolean;

    public get is_enabled(): boolean {
        return this._is_enabled;
    }

    public set is_enabled(is_enabled: boolean) {
        this._is_enabled = is_enabled;
    }


  constructor(webhookJson: any) {
    this._id = webhookJson?.id;
    this._success_count = webhookJson?.success_count;
    this._failed_count = webhookJson?.failed_count;
    this._agents = [];
    webhookJson?.agents?.forEach((element: any) => {
      this._agents.push(new Agent(element));
    })
    this._added_by = new AddedBy(webhookJson?.added_by);
    this._name = webhookJson?.name;
    this._url = webhookJson?.url;
    this._created_at = webhookJson?.created_at;
    this._updated_at = webhookJson?.updated_at;
    this._business = webhookJson?.business;
    this._is_enabled = webhookJson?.is_enabled;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get success_count(): number {
        return this._success_count;
    }

    public set success_count(success_count: number) {
        this._success_count = success_count;
    }

    public get failed_count(): number {
        return this._failed_count;
    }

    public set failed_count(failed_count: number) {
        this._failed_count = failed_count;
    }

    public get agents(): Agent[] {
        return this._agents;
    }

    public set agents(agents: Agent[]) {
        this._agents = agents;
    }

    public get added_by(): AddedBy {
        return this._added_by;
    }

    public set added_by(added_by: AddedBy) {
        this._added_by = added_by;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

}

export class Agent {
  private _id: number
  private _events: string[]
  private _is_active: boolean
  private _created_at: string
  private _updated_at: string
  private _webhook: number
  private _agent: number

  constructor(agentJson: any) {
    this._id = agentJson?.id;
    this._events = agentJson?.events;
    this._is_active = agentJson?.is_active;
    this._created_at = agentJson?.created_at;
    this._updated_at = agentJson?.updated_at;
    this._webhook = agentJson?.webhook;
    this._agent = agentJson?.agent;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get events(): string[] {
        return this._events;
    }

    public set events(events: string[]) {
        this._events = events;
    }

    public get is_active(): boolean {
        return this._is_active;
    }

    public set is_active(is_active: boolean) {
        this._is_active = is_active;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get webhook(): number {
        return this._webhook;
    }

    public set webhook(webhook: number) {
        this._webhook = webhook;
    }

    public get agent(): number {
        return this._agent;
    }

    public set agent(agent: number) {
        this._agent = agent;
    }

}

export class AddedBy {
  private _id: number
  private _name: string

  constructor(addedByJson: any) {
    this._id = addedByJson?.id;
    this._name = addedByJson?.name;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

}