export class ApiKeys {
  private _id: number
  private _access_key: string
  private _access_secret: string
  private _created_at: Date
  private _user: number

  constructor(apiKeysJson: any) {
    this._id = apiKeysJson?.id;
    this._access_key = apiKeysJson?.access_key;
    this._access_secret = apiKeysJson?.access_secret;
    this._created_at = apiKeysJson?.created_at;
    this._user = apiKeysJson?.user;
  }

  public get id(): number {
      return this._id;
  }
  public set id(id: number) {
      this._id = id;
  }
  public get access_key(): string {
      return this._access_key;
  }
  public set access_key(access_key: string) {
      this._access_key = access_key;
  }
  public get access_secret(): string {
      return this._access_secret;
  }
  public set access_secret(access_secret: string) {
      this._access_secret = access_secret;
  }
  public get created_at(): Date {
      return this._created_at;
  }
  public set created_at(created_at: Date) {
      this._created_at = created_at;
  }
  public get user(): number {
      return this._user;
  }
  public set user(user: number) {
      this._user = user;
  }

}