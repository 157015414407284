import React, { useEffect, useState } from "react";
import { Button } from "../shadcn/button";
import axios from "axios";
import { addAgent, getAgentTemplate } from "../../lib/agents.utils";
import { AgentAction } from "../../models/agent-action";
import { AgentTemplate } from "../../models/agent-templates";
import { AiAgent } from "../../models/ai-agent";
import AgentTemplateComponent from "../shared/AgentTemplate";
import { Plus } from "lucide-react";
import { ScrollArea } from "../shadcn/scrollArea";
import { Dialog, DialogContent } from "../shadcn/dialog";

interface PromptTemplatesProps {
  onClose: () => void, 
  onSave: (prompt: string) => void, 
}
const PromptTemplates: React.FC<PromptTemplatesProps> = ({ 
  onClose,
  onSave,
  }) => {
  const [agentTemplates, setAgentTemplates] = useState<AgentTemplate[]>([]);
  const [loaderTemplates, setLoaderTemplates] = useState(true);
  const [loaderAdd, setLoaderAdd] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [audioPlayingId, setAudioPlayingId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
    fetchAgentTemplates();
  }, []);

  const fetchAgentTemplates = async () => {
    setLoaderTemplates(true);
    try {
      const response = await getAgentTemplate();
      const { data } = response.data;
      setAgentTemplates((prev) =>
        data.map((item: any) => new AgentTemplate(item))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderTemplates(false);
    }
  };

  const createAgent = async (agent: AgentTemplate) => {
    onSave(agent?.general_prompt);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {setIsOpen(false); onClose()}}
    >
      <DialogContent className="max-w-[1060px]">
        <ScrollArea className="relative bg-white max-h-[582px] w-full h-fit overflow-auto">
          <div className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-y-1">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-800">Select template</h2>
            </div>
          </div>

          <div className="w-full">
            {loaderTemplates ? (
              <div className="flex flex-wrap gap-4">
                {[...Array(2)].map((_, index) => (
                  <div
                    key={index}
                    className="border rounded-lg p-6 flex flex-col gap-4 w-full sm:w-[45%] animate-pulse"
                  >
                    <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-wrap gap-4">
                {agentTemplates.map((agent: AgentTemplate, index) => (
                  <AgentTemplateComponent 
                    agent={agent}
                    isPrompt={true}
                    loading={selectedTemplate === agent?.id ? loaderAdd : false} 
                    onCreate={() => createAgent(agent)}
                    disable={loaderAdd}
                    playing={audioPlayingId}
                    onAudioStarted={() => setAudioPlayingId(agent?.id)}
                    icon={'./assets/svg/avatar'+((index%4) +1)+'.svg'}
                  />
                ))}
              </div>
            )}
          </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default PromptTemplates;
