import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";
// import { Checkbox } from "../shadcn/checkbox";
import { Textarea } from "../shadcn/textarea";
import { Customer, CustomProperty } from "../../models/customer";
import { LoggedUser } from "../../models/logged-user";
import { Note } from "../../models/note";
import {
  CalendarDays,
  EllipsisVertical,
  Hash,
  Link2,
  Mail,
  MapPin,
  Phone,
  Plus,
  SquareCheck,
  Type,
  UserRound,
} from "lucide-react";
import {
  formatDateToMonthDayYear,
  formatName,
  formatToE164,
} from "../../lib/ui.utils";
import {
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  Sheet,
} from "../shadcn/sheet";
import {
  addUser,
  deleteCustomer,
  getSingleUser,
  updateUser,
} from "../../lib/users.utils";
import Toaster from "../shared/customToast";
import {
  addNotes,
  deleteNote,
  getNotes,
  updateNote,
} from "../../lib/notes.utils";
import {
  addCustomProperty,
  deleteCustomProperty,
  getCustomProperty,
  updateCustomProperty,
} from "../../lib/business.utils";
import { set } from "date-fns";
import {
  getCountryCodeFromNumber,
  getMobileNumber,
} from "../../lib/common.utils";
import { deleteUser } from "../../lib/agents.utils";
import CustomFieldEditDelete from "../shared/CustomFieldEditDelete";
import { Checkbox } from "../shadcn/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";
import { Calendar } from "../shadcn/calendar";
import ConfirmationPopup from "../shared/ConfirmationPopup";

interface ContactDetailsProps {
  customer: Customer;
  close: (customer: Customer) => void;
  onAdd: (customer: Customer) => void;
  onDelete: () => void;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  customer,
  close,
  onAdd,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeInput, setActiveInput] = useState("");
  const [editedCustomer, setEditedCustomer] = useState<Customer>(customer);
  const [notesList, setNotesList] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState("");
  const [editingNoteId, setEditingNoteId] = useState(0);
  const [isAddCustomField, setIsAddCustomField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderAddCustomField, setLoaderAddCustomField] = useState(false);
  const [addCustomFieldDetails, setAddCustomFieldDetails] = useState({
    name: "",
    type: "",
  });

  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );

  const fieldTypeMap: Record<string, string> = {
    Text: "text",
    Number: "int",
    Address: "address",
    Date: "date",
    URL: "url",
    Checkbox: "bool",
  };

  const [isEditCustomField, setIsEditCustomField] = useState(false);
  const [editingCustomField, setEditingCustomField] =
    useState<CustomProperty | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteHeading, setDeleteHeading] = useState("");
  const [deleteDescription, setDeleteDescription] = useState("");
  const [deleteNegativeButton, setDeleteNegativeButton] = useState("");
  const [deletePositiveButton, setDeletePositiveButton] = useState("");
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    setIsOpen(true);

    if (customer?.id) {
      const newEditedCustomer = new Customer(customer);
      setEditedCustomer(newEditedCustomer);

      // Fetch Single User
      getSingleUser(customer.id)
        .then((resp) => {
          const updatedCustomer = new Customer(resp.data.data);
          setEditedCustomer(updatedCustomer);
        })
        .catch((error) => {
          Toaster.error(error);
        });

      // Fetch Notes
      getNotes({ contact: customer.id })
        .then((resp) => {
          let responseList = resp.data.data;
          const notes =
            responseList?.map((element: any) => new Note(element)) || [];
          setNotesList(notes);
        })
        .catch((error) => {
          console.error("Error fetching notes:", error);
        });
    } else {
      const newCustomer = new Customer(null);
      newCustomer.name = "";
      newCustomer.mobile = "";
      setEditedCustomer(newCustomer);

      // Fetch Custom Properties
      getCustomProperty()
        .then((resp) => {
          console.log(resp);
          let responseList = resp.data.data;
          const customProperties = responseList?.map((element: any) => {
            let field: Record<string, any> = {
              field: element?.field,
              label: element?.label,
              datatype: element?.datatype,
            };

            switch (element?.datatype) {
              case "text":
                field["text_value"] = null;
                break;
              case "url":
                field["url_value"] = null;
                break;
              case "address":
                field["address_value"] = null;
                break;
              case "int":
                field["int_value"] = null;
                break;
              case "date":
                field["date_value"] = null;
                break;
              case "bool":
                field["bool_value"] = null;
                break;
              default:
                break;
            }

            return new CustomProperty(field);
          });

          setEditedCustomer(
            (prevCustomer) =>
              new Customer({
                ...prevCustomer.toJson(),
                custom_property: customProperties,
              })
          );
        })
        .catch((error) => {
          console.error("Error fetching custom properties:", error);
        });
    }
  }, [customer]); // Runs when `customer` changes

  const updateDateProperty = (date: any, property: CustomProperty) => {
    console.log(date);
    if (date) {
      setEditedCustomer((prevCustomer) => {
        const updatedProperties = prevCustomer?.custom_property.map(
          (element) => {
            if (element.field === property?.field) {
              let newDateValue = new Date(date);
              let currentTime = new Date();
              newDateValue.setHours(currentTime.getHours());
              newDateValue.setMinutes(currentTime.getMinutes());
              newDateValue.setSeconds(currentTime.getSeconds());
              newDateValue.setMilliseconds(currentTime.getMilliseconds());

              return { ...element.toJson(), date_value: newDateValue };
            }
            return element.toJson();
          }
        );

        return new Customer({
          ...prevCustomer.toJson(),
          custom_property: updatedProperties,
        });
      });

      updateCustomer();
    }
  };

  const updateCustomer = () => {
    if (customer?.id) {
      setActiveInput("");
      setIsLoading(true);

      let input: Record<string, any> = {};
      if (editedCustomer?.name !== "") input["name"] = editedCustomer?.name;
      if (editedCustomer?.email !== "") input["email"] = editedCustomer?.email;

      let customFields = editedCustomer?.custom_property
        ?.map((element) => {
          if (!element?.field) return null;

          let field: Record<string, any> = {
            field: element?.field,
            datatype: element?.datatype,
            label: element?.label,
          };

          switch (element?.datatype) {
            case "text":
              field["text_value"] = element?.text_value;
              break;
            case "address":
              field["address_value"] = element?.address_value;
              break;
            case "url":
              field["url_value"] = element?.url_value;
              break;
            case "bool":
              field["bool_value"] = element?.bool_value;
              break;
            case "int":
              field["int_value"] = Number(element?.int_value) ?? null;
              break;
            case "date":
              if (element?.date_value) {
                let dateValue = new Date(element?.date_value);
                if (
                  dateValue.getFullYear() &&
                  dateValue.getMonth() + 1 &&
                  dateValue.getDate()
                ) {
                  field["date_value"] = `${dateValue.getFullYear()}-${
                    dateValue.getMonth() + 1
                  }-${dateValue.getDate()}`;
                  console.log(dateValue);
                } else {
                  field["date_value"] = null;
                }
              } else {
                field["date_value"] = null;
              }
              break;
            default:
              break;
          }

          return field;
        })
        .filter(Boolean); // Remove null values

      input["custom_property"] = customFields;

      updateUser(customer.id, input)
        .then((resp) => {
          Toaster.success(resp.data.message);
          customer = new Customer(editedCustomer);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          Toaster.error(error);
        });
    }
  };

  const addNote = () => {
    console.log(newNote);
    if (newNote === "") {
      return;
    }

    let input = {
      contact: customer?.id,
      note: newNote,
    };

    addNotes(input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
        if (resp.data.data) {
          setNotesList((prevNotes) => [
            ...prevNotes.slice(0, prevNotes.length - 1),
            new Note(resp.data.data),
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
      });

    let note = {
      ...input,
      added_by: {
        id: user?.id,
        name: user?.name,
      },
    };

    setNotesList((prevNotes) => [...prevNotes, new Note(note)]);
    setNewNote("");
  };

  const deleteNotes = (note: Note, index: number) => {
    deleteNote(note?.id)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });

    setNotesList((prevNotes) => prevNotes.filter((_, i) => i !== index));
  };

  const noteAction = (action: string, note: Note, index: number) => {
    if (action === "Delete") {
      deleteNotes(note, index);
    } else {
      setEditingNoteId(note?.id);
    }
  };

  const updateNotes = (text: string, note: Note, index: number) => {
    let input = { note: text };

    updateNote(input, note?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });

    setEditingNoteId(0);
    setNotesList((prevNotes) =>
      prevNotes.map((n: Note, i) =>
        i === index
          ? new Note({
              ...n,
              note: text,
              added_by: { name: user?.name, id: user?.id },
              updated_at: new Date(),
            })
          : n
      )
    );
  };

  const addContact = () => {
    let mobile = getMobileNumber(editedCustomer?.mobile);
    let code = getCountryCodeFromNumber(editedCustomer?.mobile);

    if (code === "") {
      Toaster.error("Enter valid phone number with country code");
      return;
    }

    let input: Record<string, any> = {
      mobile,
      country_code: code,
    };

    setIsLoading(true);

    if (editedCustomer?.name !== "") input["name"] = editedCustomer?.name;
    if (editedCustomer?.email !== "") input["email"] = editedCustomer?.email;

    let customFields = editedCustomer?.custom_property
      ?.map((element) => {
        if (!element?.field) return null;

        let field: Record<string, any> = {
          field: element?.field,
          datatype: element?.datatype,
          label: element?.label,
        };

        switch (element?.datatype) {
          case "text":
            field["text_value"] = element?.text_value;
            break;
          case "address":
            field["address_value"] = element?.address_value;
            break;
          case "url":
            field["url_value"] = element?.url_value;
            break;
          case "bool":
            field["bool_value"] = element?.bool_value;
            break;
          case "int":
            field["int_value"] = Number(element?.int_value) ?? null;
            break;
          case "date":
            if (element?.date_value) {
              let dateValue = new Date(element?.date_value);
              if (
                dateValue.getFullYear() &&
                dateValue.getMonth() &&
                dateValue.getDate()
              ) {
                field[
                  "date_value"
                ] = `${dateValue.getFullYear()}-${dateValue.getMonth()}-${dateValue.getDate()}`;
              } else {
                field["date_value"] = null;
              }
            } else {
              field["date_value"] = null;
            }
            break;
          default:
            break;
        }

        return field;
      })
      .filter(Boolean); // Remove null values

    input["custom_property"] = customFields;

    addUser(input)
      .then((resp) => {
        Toaster.success(resp.data.message);
        customer = new Customer(resp.data.data);
        setEditedCustomer(new Customer(resp.data.data));
        onAdd(new Customer(resp.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
        setIsLoading(false);
      });
  };

  const deleteContactConfirm = () => {
    setDeleteType("contact");
    setDeleteConfirmation(true);
    setDeleteHeading("Confirm delete contact");
    setDeleteDescription(
      `Deleting the contact <span class="col-pri">${formatToE164(
        customer?.mobile
      )}</span> removes the profile details, but the associated call logs will not be deleted.`
    );
    setDeleteNegativeButton("No, keep contact");
    setDeletePositiveButton("Yes, delete contact");
  };

  const deleteCustomFieldConfirm = () => {
    setDeleteType("field");
    setDeleteConfirmation(true);
    setDeleteHeading("Confirm delete contact property");
    setDeleteDescription(
      `Deleting the contact property <span class="col-pri">${editingCustomField?.label}</span> will remove it from all contacts.`
    );
    setDeleteNegativeButton("No, keep property");
    setDeletePositiveButton("Yes, delete property");
  };

  const deleteContact = () => {
    setDeleteConfirmation(false);
    onDelete();
    deleteCustomer(customer?.id)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
      });
  };

  const selectType = (event: React.MouseEvent, type: string) => {
    event.preventDefault();
    setAddCustomFieldDetails((prev) => ({ ...prev, type: type }));
    setTimeout(() => setIsAddCustomField(true), 100);
  };

  const addCustomField = (name: string) => {
    if (name === "") {
      Toaster.error("Enter valid field name");
      return;
    }

    let input = {
      field: name.replaceAll(" ", "_").toLowerCase(),
      label: name,
      datatype: fieldTypeMap[addCustomFieldDetails.type],
    };

    setLoaderAddCustomField(true);

    addCustomProperty(input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
        let customField: Record<string, any> = input;
        customField[`${input.datatype}_value`] = null;
        setEditedCustomer(
          (prev) =>
            new Customer({
              ...prev.toJson(),
              custom_property: [
                ...prev.toJson().custom_property,
                new CustomProperty(customField),
              ],
            })
        );
        customer.custom_property.push(new CustomProperty(customField));
        setIsAddCustomField(false);
        setLoaderAddCustomField(false);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
        setLoaderAddCustomField(false);
      });
  };

  const closeAddCustomField = () => {
    setAddCustomFieldDetails({ name: "", type: "" });
    setIsAddCustomField(false);
  };

  const saveEditCustomField = (name: string) => {
    if (name === "") {
      Toaster.error("Enter valid field name");
      return;
    }

    let input = {
      field: editingCustomField?.field,
      label: name,
      datatype: editingCustomField?.datatype,
    };

    setLoaderAddCustomField(true);

    updateCustomProperty(input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
        setEditedCustomer(
          (prev) =>
            new Customer({
              ...prev.toJson(),
              custom_property: prev.custom_property.map((element) =>
                element.field === editingCustomField?.field
                  ? {
                      ...element.toJson(),
                      label: name,
                      field: name.toLowerCase().replaceAll(" ", "_"),
                    }
                  : element.toJson()
              ),
            })
        );
        setEditingCustomField(null);
        setLoaderAddCustomField(false);
        setIsEditCustomField(false);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
        setLoaderAddCustomField(false);
      });
  };

  const deleteCustomField = () => {
    setDeleteConfirmation(false);
    setEditedCustomer(
      (prev) =>
        new Customer({
          ...prev.toJson(),
          custom_property: prev.custom_property.filter(
            (prop) => prop.field !== editingCustomField?.field
          ),
        })
    );
    setIsEditCustomField(false);

    deleteCustomProperty(editingCustomField?.field ?? "")
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
      });
  };

  useEffect(() => {
    console.log(editedCustomer);
  }, [editedCustomer]);

  return (
    <Sheet
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(!isOpen);
        close(editedCustomer);
      }}
    >
      <SheetContent className="p-6 flex flex-col gap-4 w-[450px] !max-w-[450px]">
        {/* Header */}
        <div className="flex justify-between items-center text-lg font-bold text-[var(--primary-color)]">
          <span>
            {customer?.id ? `${formatName(customer)}` : "Add contact"}
          </span>
          {customer?.id && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost">
                  <EllipsisVertical color="var(--icon-sec-color)" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={deleteContactConfirm}>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        <div className="space-y-4">
          <div className="flex flex-col gap-1">
            {/* Name Property */}
            <div className="flex items-center">
              <span className="flex items-center gap-2 p-2 text-[14px] font-medium leading-[21px] text-secondary w-[120px] truncate">
                <UserRound size="16px" color="var(--icon-sec-color)" /> Name
              </span>
              {activeInput === "name" ? (
                <Input
                  value={editedCustomer.name}
                  onChange={(e) => {
                    setEditedCustomer(
                      new Customer({
                        ...editedCustomer.toJson(),
                        name: e.target.value,
                      })
                    );
                  }}
                  autoFocus={true}
                  className="w-[calc(100%-120px)]"
                  onBlur={() => {
                    setActiveInput("");
                    if (editedCustomer.name !== customer.name) updateCustomer();
                  }}
                />
              ) : (
                <span
                  className="flex items-center w-[calc(100%-120px)] h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]"
                  onClick={() => setActiveInput("name")}
                  style={{
                    color: editedCustomer.name
                      ? "var(--primary-color)"
                      : "var(--secondary-color)",
                  }}
                >
                  {editedCustomer.name || "John Doe"}
                </span>
              )}
            </div>

            {/* Phone Property */}
            <div className="flex items-center">
              <span className="flex items-center gap-2 p-2 text-[14px] font-medium leading-[21px] text-secondary w-[120px] truncate">
                <Phone size="16px" color="var(--icon-sec-color)" /> Phone
              </span>
              {activeInput === "mobile" ? (
                <Input
                  value={editedCustomer.mobile}
                  onChange={(e) =>
                    setEditedCustomer(
                      new Customer({
                        ...editedCustomer.toJson(),
                        mobile: e.target.value,
                      })
                    )
                  }
                  disabled={customer?.id ? true : false}
                  autoFocus={true}
                  className="w-[calc(100%-120px)]"
                  onBlur={() => {
                    setActiveInput("");
                    if (editedCustomer.mobile !== customer.mobile)
                      updateCustomer();
                  }}
                />
              ) : (
                <span
                  className="flex items-center w-[calc(100%-120px)] h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]"
                  onClick={() => setActiveInput("mobile")}
                  style={{
                    color: editedCustomer.mobile
                      ? "var(--primary-color)"
                      : "var(--secondary-color)",
                  }}
                >
                  {formatToE164(editedCustomer.mobile) || "12512500508"}
                </span>
              )}
            </div>

            {/* Email Property */}
            <div className="flex items-center">
              <span className="flex items-center gap-2 p-2 text-[14px] font-medium leading-[21px] text-secondary w-[120px] truncate">
                <Mail size="16px" color="var(--icon-sec-color)" /> Email
              </span>
              {activeInput === "email" ? (
                <Input
                  value={editedCustomer.email}
                  onChange={(e) =>
                    setEditedCustomer(
                      new Customer({
                        ...editedCustomer.toJson(),
                        email: e.target.value,
                      })
                    )
                  }
                  autoFocus={true}
                  className="w-[calc(100%-120px)]"
                  onBlur={() => {
                    setActiveInput("");
                    if (editedCustomer.email !== customer.email)
                      updateCustomer();
                  }}
                />
              ) : (
                <span
                  className="flex items-center w-[calc(100%-120px)] h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]"
                  onClick={() => setActiveInput("email")}
                  style={{
                    color: editedCustomer.email
                      ? "var(--primary-color)"
                      : "var(--secondary-color)",
                  }}
                >
                  {editedCustomer.email || "john@example.com"}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              {editedCustomer.custom_property.map((property, i) => (
                <div key={i} className="flex w-full relative">
                  <DropdownMenu
                    open={isEditCustomField && editingCustomField === property}
                    onOpenChange={(state) => {
                      setIsEditCustomField(state);
                      if (state) {
                        setEditingCustomField(property);
                      } else {
                        setEditingCustomField(null);
                      }
                    }}
                  >
                    <DropdownMenuTrigger asChild>
                      <span
                        // onClick={() => setEditingCustomField(property)}
                        className="flex items-center gap-2 p-2 text-[14px] font-medium leading-[21px] text-secondary w-[120px] truncate cursor-pointer"
                      >
                        {property?.datatype === "text" && (
                          <Type size="16px" color="var(--icon-sec-color)" />
                        )}
                        {property?.datatype === "int" && (
                          <Hash size="16px" color="var(--icon-sec-color)" />
                        )}
                        {property?.datatype === "address" && (
                          <MapPin size="16px" color="var(--icon-sec-color)" />
                        )}
                        {property?.datatype === "date" && (
                          <CalendarDays
                            size="16px"
                            color="var(--icon-sec-color)"
                          />
                        )}
                        {property?.datatype === "url" && (
                          <Link2 size="16px" color="var(--icon-sec-color)" />
                        )}
                        {property?.datatype === "bool" && (
                          <SquareCheck
                            size="16px"
                            color="var(--icon-sec-color)"
                          />
                        )}
                        <span
                          title={property?.label}
                          className="max-w-[80px] truncate"
                        >
                          {property?.label}
                        </span>
                      </span>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent>
                      <CustomFieldEditDelete
                        type={editingCustomField?.datatype}
                        name={editingCustomField?.label || ""}
                        loader={loaderAddCustomField}
                        onSave={(value) => saveEditCustomField(value)}
                        onDelete={() => deleteCustomFieldConfirm()}
                        onClose={() => setIsEditCustomField(false)}
                      />
                    </DropdownMenuContent>
                  </DropdownMenu>

                  {property?.datatype === "bool" && (
                    <span className="p-2 flex items-center">
                      <Checkbox
                        checked={property.bool_value}
                        onCheckedChange={() => {
                          setEditedCustomer(
                            (prev: any) =>
                              new Customer({
                                ...prev.toJson(),
                                custom_property: prev.custom_property.map(
                                  (prop: any) =>
                                    prop.field === property.field
                                      ? new CustomProperty({
                                          ...prop.toJson(),
                                          bool_value: !property.bool_value,
                                        })
                                      : prop
                                ),
                              })
                          );
                          updateCustomer();
                        }}
                      />
                    </span>
                  )}

                  {/* Date Picker */}
                  {property?.datatype === "date" && (
                    <Popover modal={false}>
                      <PopoverTrigger asChild>
                        <button className="flex items-center w-[calc(100%-120px)] h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]">
                          {property?.date_value ? (
                            formatDateToMonthDayYear(property.date_value)
                          ) : (
                            <span className="text-secondary">Select date</span>
                          )}
                        </button>
                      </PopoverTrigger>
                      <PopoverContent className="z-[9999] w-auto p-0 pointer-events-auto">
                        <Calendar
                          mode="single"
                          selected={property.date_value ?? new Date()}
                          onSelect={(event) => {
                            updateDateProperty(event, property);
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                  )}

                  {/* Other Inputs (Text, Address, URL, Number) */}
                  {property?.datatype !== "bool" &&
                    property?.datatype !== "date" && (
                      <div className="w-[calc(100%-120px)]">
                        {activeInput !== property?.field && (
                          <span
                            onClick={() =>
                              setActiveInput(property?.field ?? "")
                            }
                            className="flex items-center w-full h-[37px] p-2 text-[14px] font-normal leading-[21px] text-left rounded-lg cursor-pointer truncate hover:bg-[var(--hover-color)]"
                            style={{
                              color:
                                property.text_value ||
                                property.address_value ||
                                property.url_value ||
                                property.int_value
                                  ? "var(--primary-color)"
                                  : "var(--secondary-color)",
                            }}
                          >
                            {property?.datatype === "text" &&
                              (property.text_value ||
                                `Set ${property.datatype}`)}
                            {property?.datatype === "address" &&
                              (property.address_value ||
                                `Set ${property.datatype}`)}
                            {property?.datatype === "url" &&
                              (property.url_value || "Set URL")}
                            {property?.datatype === "int" &&
                              (property.int_value ??
                                `Set ${property.datatype}`)}
                          </span>
                        )}

                        {activeInput === property?.field && (
                          <>
                            {property?.datatype === "text" && (
                              <Input
                                defaultValue={property?.text_value}
                                onBlur={() => {
                                  setActiveInput("");
                                  if (
                                    property?.text_value !==
                                    customer?.custom_property[i]?.text_value
                                  )
                                    updateCustomer();
                                }}
                                onChange={(e) =>
                                  setEditedCustomer(
                                    (prev: any) =>
                                      new Customer({
                                        ...prev.toJson(),
                                        custom_property:
                                          prev.custom_property.map(
                                            (prop: any) =>
                                              prop.field === property.field
                                                ? new CustomProperty({
                                                    ...prop.toJson(),
                                                    text_value: e.target.value,
                                                  })
                                                : prop
                                          ),
                                      })
                                  )
                                }
                              />
                            )}
                            {property?.datatype === "address" && (
                              <Input
                                autoFocus
                                defaultValue={property.address_value}
                                onBlur={() => {
                                  setActiveInput("");
                                  if (
                                    property?.address_value !==
                                    customer?.custom_property[i]?.address_value
                                  )
                                    updateCustomer();
                                }}
                                onChange={(e) =>
                                  setEditedCustomer(
                                    (prev: any) =>
                                      new Customer({
                                        ...prev.toJson(),
                                        custom_property:
                                          prev.custom_property.map(
                                            (prop: any) =>
                                              prop.field === property.field
                                                ? new CustomProperty({
                                                    ...prop.toJson(),
                                                    address_value:
                                                      e.target.value,
                                                  })
                                                : prop
                                          ),
                                      })
                                  )
                                }
                              />
                            )}
                            {property?.datatype === "url" && (
                              <Input
                                autoFocus
                                defaultValue={property?.url_value}
                                onBlur={() => {
                                  setActiveInput("");
                                  if (
                                    property?.url_value !==
                                    customer?.custom_property[i]?.url_value
                                  )
                                    updateCustomer();
                                }}
                                onChange={(e) =>
                                  setEditedCustomer(
                                    (prev: any) =>
                                      new Customer({
                                        ...prev.toJson(),
                                        custom_property:
                                          prev.custom_property.map(
                                            (prop: any) =>
                                              prop.field === property.field
                                                ? new CustomProperty({
                                                    ...prop.toJson(),
                                                    url_value: e.target.value,
                                                  })
                                                : prop
                                          ),
                                      })
                                  )
                                }
                              />
                            )}
                            {property?.datatype === "int" && (
                              <Input
                                type="number"
                                autoFocus
                                defaultValue={property?.int_value}
                                onBlur={() => {
                                  setActiveInput("");
                                  if (
                                    property?.int_value !==
                                    customer?.custom_property[i]?.int_value
                                  )
                                    updateCustomer();
                                }}
                                onChange={(e) =>
                                  setEditedCustomer(
                                    (prev: any) =>
                                      new Customer({
                                        ...prev.toJson(),
                                        custom_property:
                                          prev.custom_property.map(
                                            (prop: any) =>
                                              prop.field === property.field
                                                ? new CustomProperty({
                                                    ...prop.toJson(),
                                                    int_value: e.target.value,
                                                  })
                                                : prop
                                          ),
                                      })
                                  )
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                </div>
              ))}

              <div className="relative mt-2">
                <DropdownMenu
                  onOpenChange={(open) => setIsDropdownOpen(open)}
                  open={isDropdownOpen}
                >
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="text-secondary"
                      onClick={() => setIsDropdownOpen(true)}
                    >
                      <Plus size="16px" color="var(--icon-sec-color)" /> Add a
                      property
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent>
                    {!isAddCustomField ? (
                      <>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "Text")}
                        >
                          <Type size="16px" color="var(--icon-sec-color)" />{" "}
                          Text
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "Number")}
                        >
                          <Hash size="16px" color="var(--icon-sec-color)" />{" "}
                          Number
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "Address")}
                        >
                          <MapPin size="16px" color="var(--icon-sec-color)" />{" "}
                          Address
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "Date")}
                        >
                          <CalendarDays
                            size="16px"
                            color="var(--icon-sec-color)"
                          />{" "}
                          Date
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "URL")}
                        >
                          <Link2 size="16px" color="var(--icon-sec-color)" />{" "}
                          URL
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="cursor-pointer hover:bg-[var(--hover-color)]"
                          onClick={(e) => selectType(e, "Checkbox")}
                        >
                          <SquareCheck
                            size="16px"
                            color="var(--icon-sec-color)"
                          />{" "}
                          Checkbox
                        </DropdownMenuItem>
                      </>
                    ) : (
                      <CustomFieldEditDelete
                        type={addCustomFieldDetails.type}
                        loader={loaderAddCustomField}
                        onSave={(value) => {
                          addCustomField(value);
                          setIsAddCustomField(false);
                          setIsDropdownOpen(false); // Close dropdown on save
                        }}
                        onDelete={() => {
                          setIsAddCustomField(false);
                          setIsDropdownOpen(false); // Close dropdown on delete
                        }}
                        onClose={() => {
                          setIsAddCustomField(false);
                          setIsDropdownOpen(false); // Close dropdown on close
                        }}
                      />
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            {!customer?.id && (
              <div className="flex justify-end w-full">
                <Button
                  onClick={addContact}
                  disabled={
                    editedCustomer?.name === "" ||
                    editedCustomer?.mobile === "" ||
                    isLoading
                  }
                >
                  {isLoading ? "Adding..." : "Add contact"}
                </Button>
              </div>
            )}
          </div>

          {/* Notes Section */}
          {/* {customer?.id && (
            <div className="space-y-2">
              <div className="flex items-center space-x-2 text-sm font-medium text-primary">
                <span>Note</span>
                <span className="text-secondary">{notesList?.length}</span>
              </div>
              <div className="space-y-2">
                {notesList?.map((note, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between space-x-2"
                  >
                    <span>{note.added_by?.name}</span>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost">
                          <EllipsisVertical />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={deleteContactConfirm}>
                          Delete
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {}}>
                          Edit
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {}}>
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                ))}
              </div>
              <div>
                <Input
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") addNote();
                  }}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
          )} */}

          {deleteConfirmation && (
            <ConfirmationPopup
              positiveButtonText={deletePositiveButton}
              negativeButtonText={deleteNegativeButton}
              heading={deleteHeading}
              description={deleteDescription}
              onClose={() => setDeleteConfirmation(false)}
              onSuccess={
                deleteType === "field" ? deleteCustomField : deleteContact
              }
            />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ContactDetails;
