import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Toaster from "../shared/customToast";
import useSharedStore from "../../store/shared.store";

const HlOnboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sharedStore = useSharedStore()

  const [ghClientId, setGhClientId] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const ghlClientId = queryParams.get("ghl_client_id");
    const code = queryParams.get("code");

    console.log("Query Params:", Object.fromEntries(queryParams.entries()));

    if (token && ghlClientId) {
      localStorage.setItem("ghl-token", token);
      setGhClientId(ghlClientId);

      const redirectUrl = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${window.location.origin}/hl-onboarding&client_id=${ghlClientId}&scope=businesses.readonly calendars.readonly calendars.write contacts.readonly contacts.write calendars/events.write calendars/events.readonly locations.readonly`;

      window.location.href = redirectUrl;
    }

    if (code) {
      const savedToken = localStorage.getItem("ghl-token");
      if (savedToken) {
        const input = { code };

        axios
          .post("https://"+sharedStore.apiUrl+"/hl/auth/callback", input, {
            headers: {
              skip: "true",
              "Content-Type": "application/json; charset=utf-8",
              Authorization: savedToken,
              "ngrok-skip-browser-warning":
                process.env.NODE_ENV === "production" ? "false" : "true",
            },
          })
          .then((resp) => {
            localStorage.removeItem("ghl-token");
            window.close();
          })
          .catch((error) => {
            console.error(error);
            Toaster.error(error.message || "An error occurred");
            window.close();
          });
      } else {
        navigate("/");
      }
    }
  }, [location, navigate]);

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-[var(--bg-color)] flex flex-col items-center justify-start p-[60px] gap-[24px] font-inter text-[20px] font-medium leading-[30px] text-left text-[var(--primary-color)]">
      High Level app installation in process...
    </div>
  );
};

export default HlOnboarding;
