// toastWrapper.ts
import { toast } from 'sonner';

// Define default titles for different toast types
const defaultTitles = {
  success: "Success",
  error: "Error",
  info: "Information",
  warning: "Warning",
};

// Custom toast wrapper
const Toaster = {
  success: (message: string) => {
    toast.success(defaultTitles.success, { description: message });
  },
  error: (message: string) => {
    toast.error(defaultTitles.error, { description: message });
  },
  info: (message: string) => {
    toast.info(defaultTitles.info, { description: message }); // Sonner's default toast is informational
  },
  warning: (message: string) => {
    toast.warning(defaultTitles.warning, { description: message });
  },
};

export default Toaster;
