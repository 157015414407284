import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { Input } from "../shadcn/input";
import { Textarea } from "../shadcn/textarea";

interface ViewInfobaseTextProps {
  name: string;
  textContent: string;
  onClose: (closed: boolean) => void;
}

const ViewInfobaseText: React.FC<ViewInfobaseTextProps> = ({
  name,
  textContent,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose(false)}>
      <DialogContent className="w-full max-h-[80%] max-w-[500px]">
        <div className="flex flex-col gap-4" style={{ width: "450px" }}>
          <div className="flex justify-between items-center font-inter text-lg font-semibold leading-7 text-left text-primary">
            <span>View text</span>
          </div>
          <div className="flex flex-col gap-4 p-2">
            <div className="flex flex-col gap-1">
              <span className="font-inter text-sm font-medium leading-5 text-left text-primary flex items-center justify-between">
                Name
              </span>
              <Input
                type="text"
                disabled
                placeholder=""
                maxLength={40}
                autoFocus={false}
                value={name}
                onChange={(e) => {}}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-inter text-sm font-medium leading-5 text-left text-primary flex items-center justify-between">
                Content
              </span>
              <Textarea
                disabled
                className="h-52 resize-both"
                placeholder=""
                autoFocus={false}
                value={textContent}
                onChange={(e) => {}}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewInfobaseText;
