import apiClient from '../interceptors/axiosApiInterceptor';

export const getWidgets = () =>
  apiClient.get('/oneinbox/widget');

export const getSingleWidget = (id: number) =>
  apiClient.get(`/oneinbox/widget/${id}`);

export const addWidget = (obj: any) =>
  apiClient.post('/oneinbox/widget', obj);

export const updateWidget = (obj: any, id: number) =>
  apiClient.put(`/oneinbox/widget/${id}`, obj);

export const deleteWidget = (id: number) =>
  apiClient.delete(`/oneinbox/widget/${id}`);
