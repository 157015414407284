import React, { useEffect, useState } from "react";
import { Settings } from "lucide-react";
import { Skeleton } from "../../shadcn/skeleton";
import { LoggedUser } from "../../../models/logged-user";
import { Widget } from "../../../models/widget";
import useSharedStore from "../../../store/shared.store";
import { Button } from "../../shadcn/button";
import { getWidgets } from "../../../lib/widget.utils";
import AddWidget from "./AddWidget";
import ViewWidget from "./ViewWidget";
import withFallbackImage from "../../hoc/useGlobalImageErrorHandler";

const Widgets = () => {
  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );
  const [widgetView, setWidgetView] = useState(false);
  const [viewedWidgetIndex, setViewedWidgetIndex] = useState<
    number | undefined
  >();
  const [viewedWidget, setViewedWidget] = useState<Widget | undefined>();
  const [addWidget, setAddWidget] = useState(false);
  const [loaderList] = useState([1, 2, 3, 4, 5, 6]);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetCount, setWidgetCount] = useState(0);

  const sharedStore = useSharedStore();

  useEffect(() => {
    getWidgetList();
  }, []);

  const getWidgetList = async () => {
    setIsLoading(true);
    try {
      const response = await getWidgets();
      if (response.data?.code === 200) {
        if (response.data.count) setWidgetCount(response.data.count);
        sharedStore.setWidgetList(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const widgetClicked = (index: number) => {
    setWidgetView(true);
    setViewedWidgetIndex(index);
    setViewedWidget(new Widget(sharedStore.widgetList[index]));
  };

  const updateList = (widget: Widget) => {
    const updatedList = sharedStore.widgetList.map((element) =>
      element.id === widget.id ? widget : element
    );
    sharedStore.setWidgetList(updatedList);
    console.log(widget);
  };

  const deleteWidget = () => {
    const updatedList = sharedStore.widgetList.filter(
      (_, index) => index !== viewedWidgetIndex
    );
    sharedStore.setWidgetList(updatedList);
    setWidgetView(false);
    setWidgetCount((prevCount) => prevCount - 1);
  };

  return (
    <div className="h-full w-full overflow-x-hidden">
      {!widgetView ? (
        <>
          <div className="container-heading flex justify-between items-center h-16 p-4 border-b border-gray-300 text-primary text-[20px] font-medium leading-[30px] text-left">
            <span className="flex items-center gap-2">
              Widgets{" "}
              {sharedStore.widgetList.length > 0 && (
                <span className="count text-gray-500">
                  {sharedStore.widgetList.length}
                </span>
              )}
            </span>
            <div className="flex gap-2">
              {(sharedStore.loggedUser?.user_type === 1 ||
                sharedStore.permissions?.settings?.widgets?.edit) && (
                <Button onClick={() => setAddWidget(true)}>Add widget</Button>
              )}
            </div>
          </div>
          {!isLoading && sharedStore.widgetList.length > 0 ? (
            <div className="agent-details flex flex-col gap-4 p-6 border-b border-gray-300 overflow-y-scroll h-[calc(100vh-64px)]">
              <div className="card-fields flex flex-col gap-1">
                <span className="heading flex gap-2 text-[var(--primary)] font-medium text-[16px] leading-[24px]">
                  Your widgets
                </span>
                <span className="subheading text-gray-500 text-[14px] leading-[21px]">
                  Manage website widgets and agents
                </span>
              </div>
              {sharedStore.widgetList.map((widget, i) => (
                <div
                  key={i}
                  className={`number-hover flex items-center gap-2 p-1 rounded-lg cursor-pointer ${
                    sharedStore.loggedUser?.user_type === 1 ||
                    sharedStore.permissions?.settings?.widgets[
                      "widget_" + widget?.id
                    ]?.edit
                      ? "inactive cursor-default"
                      : ""
                  } hover:bg-[#F0F2F5]`}
                  onClick={() =>
                    sharedStore.loggedUser?.user_type === 1 ||
                    sharedStore.permissions?.settings?.widgets[
                      "widget_" + widget?.id
                    ]?.edit
                      ? widgetClicked(i)
                      : null
                  }
                >
                  <span className="cloud-number-logo flex items-center justify-center w-9 h-9 rounded-full border border-gray-300 bg-gray-200 text-lg overflow-hidden">
                    <img className="rounded-full" src={widget?.logo} alt="" />
                  </span>
                  <span className="cloud-number-name-number flex flex-col items-start justify-center w-[calc(100%-76px)]">
                    <p className="name text-gray-900 text-base m-0">
                      {widget?.name}
                    </p>
                    <p className="number text-gray-500 text-sm m-0 flex gap-1 items-center">
                      {widget?.agent?.agent_name && (
                        <>
                          <img
                            src={
                              widget?.agent?.profile_pic ??
                              "../../../../assets/images/avatar.png"
                            }
                            alt=""
                            className="h-4 w-4 rounded-full object-cover"
                          />
                          {widget?.agent?.agent_name}
                        </>
                      )}
                    </p>
                  </span>
                  {user?.user_type === 1 && (
                    <span className="flex">
                      <Settings />
                    </span>
                  )}
                </div>
              ))}
            </div>
          ) : isLoading ? (
            <div className="agent-details flex flex-col gap-4 p-6 border-b border-gray-300 overflow-y-scroll h-[calc(100vh-64px)]">
              {loaderList.map((loader, index) => (
                <div key={index} className="loader-list flex gap-1">
                  <span className="skeleton-loader circle w-20">
                    <Skeleton className="w-12 h-12 bg-gray-300 rounded-full"></Skeleton>
                  </span>
                  <span className="description-loader flex flex-col w-full">
                    <span className="skeleton-loader first w-3/5">
                      <Skeleton className="h-4 bg-gray-300 rounded w-full"></Skeleton>
                    </span>
                    <span className="skeleton-loader second w-3/5">
                      <Skeleton className="h-4 bg-gray-300 rounded w-full"></Skeleton>
                    </span>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-screen flex flex-col items-center justify-center gap-4 w-full h-full text-[var(--primary)]">
              <div className="empty-card flex flex-col items-center justify-center gap-4">
                <img
                  src="../../../../assets/images/widget_empty.png"
                  alt="Empty"
                />
                <span>Add a widget to embed into website</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <ViewWidget
          widget={viewedWidget ?? new Widget(undefined)}
          back={() => {
            setWidgetView(false);
            getWidgetList();
          }}
          update={updateList}
          delete={deleteWidget}
        />
      )}
      {addWidget && (
        <AddWidget
          onClose={() => setAddWidget(false)}
          onSuccess={() => {
            setAddWidget(false);
            getWidgetList();
          }}
        />
      )}
    </div>
  );
};

export default Widgets;
