import axios from 'axios';
import apiClient from '../interceptors/axiosApiInterceptor';

// Utility methods
export const sendOtp = (loginDetl: any) =>
  apiClient.post('/otp-login/', loginDetl);

export const sendUserOtp = (loginDetl: any) =>
  apiClient.post('/user/otp', loginDetl);

export const verifyOtp = (obj: any) =>
  apiClient.put('/otp-login/', obj);

export const verifyUserOtp = (obj: any) =>
  apiClient.put('/user/otp', obj);

export const userProfile = (obj: any) =>
  apiClient.put('/user/me/', obj);

export const getUserProfile = () =>
  apiClient.get('/user/me');

export const logout = (obj: any) =>
  apiClient.post('/user/logout/', obj);

export const signup = (obj: any) =>
  apiClient.post('/signup', obj);

export const onboard = (obj: any) =>
  apiClient.post('/channel/whatsapp/on-boarding', obj);

export const updateProfile = (obj: any) =>
  apiClient.post('/business/on-boarding', obj);

export const verifyEmailToken = (token: string) =>
  apiClient.get(`/user/verify/verify-email?token=${token}`);

export const verifyResetPasswordToken = (token: string) =>
  apiClient.get(`/user/verify/forgot-password?token=${token}`);

export const passwordLogin = (obj: any) =>
  apiClient.post('/login', obj);

export const setPassword = (obj: any) =>
  apiClient.post('/user/change-password', obj);

export const forgotPassword = (obj: any) =>
  apiClient.put('/user/forgot-password', obj);

export const resetPassword = (obj: any) =>
  apiClient.post('/user/forgot-password', obj);

export const changePassword = (obj: any) =>
  apiClient.post('/user/change-password', obj);

export const resendSignupVerificationEmail = (obj: any) =>
  apiClient.post('/user/resend-email', obj);

export const dialog360Onboarding = (obj: any) =>
  apiClient.post('/360/on-boarding', obj);

export const oneInboxSendOtp = (obj: any) =>
  apiClient.post('/oneinbox/login', obj);

export const oneInboxLogin = (obj: any) =>
  apiClient.put('/oneinbox/login', obj);

export const oneInboxOnboarding = (obj: any) =>
  apiClient.post('/oneinbox/on-boarding', obj);

export const ghlOnboarding = (obj: any) =>
  apiClient.post('/hl/auth/callback', obj);

export const googleAuth = (obj: any) =>
  apiClient.post('/integration/google/auth', obj);
