import React from "react";
import { SubscriptionPlan } from "../../models/subscription_plan";
import { useSubscriptionStore } from "../../store/subscription.store";
import { Button } from "../shadcn/button";
import { Check, Plus, X } from "lucide-react";

interface SubscriptionPlanProps {
  plan: SubscriptionPlan; // Define a more specific type if possible
  isMonthly?: boolean;
  loader?: boolean;
  numberAddOnDetails?: SubscriptionPlan; // Define a more specific type if possible
  aiAgentAddOnDetails?: SubscriptionPlan; // Define a more specific type if possible
  userAddOnDetails?: SubscriptionPlan; // Define a more specific type if possible
  infobaseAddOnDetails?: SubscriptionPlan; // Define a more specific type if possible
  concurrentAddOnDetails?: SubscriptionPlan; // Define a more specific type if possible
  currentPlan?: SubscriptionPlan; // Define a more specific type if possible
  onPlanBuy: (event: { plan: any; duration: string }) => void; // Define a more specific type if possible
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanProps> = ({
  plan,
  isMonthly = true,
  loader = false,
  numberAddOnDetails,
  aiAgentAddOnDetails,
  userAddOnDetails,
  infobaseAddOnDetails,
  concurrentAddOnDetails,
  currentPlan,
  onPlanBuy,
}) => {
  const buyPlan = () => {
    onPlanBuy({ plan, duration: isMonthly ? "monthly" : "yearly" });
  };

  const subscriptionStore = useSubscriptionStore();

  return (
    <div
      className={`flex flex-col p-4 gap-4 border border-gray-300 rounded-lg flex-1 justify-between bg-white h-full max-w-[384px] min-w-[180px] ${
        plan?.is_recommended ? "bg-[#F3E8FF]" : ""
      }`}
    >
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div>
            <span className="text-lg font-medium text-gray-900">{plan?.name}</span>
          </div>
          {plan?.is_recommended && (
            <span className="bg-white text-xs font-medium text-purple-700 border border-gray-300 px-2 py-1 rounded-md">
              Most popular
            </span>
          )}
        </div>
        <span className="text-xl font-semibold text-gray-900">
          ${isMonthly ? plan?.monthly_price : plan?.yearly_price}/m
        </span>
        <div className="flex flex-col text-sm text-gray-700">
          {plan?.features.map((feature:any, index: number) => (
            <div key={index} className="flex items-center">
              <svg
                className={`w-4 h-4 ${feature?.is_available ? "text-green-600" : "text-red-600"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                {feature?.is_available ? (
                  <Check color="#057A55" />
                ) : (
                  <X color="#E02424" />
                )}
              </svg>
              <span className="px-2 py-1">{feature.text}</span>
            </div>
          ))}
          {infobaseAddOnDetails?.id && (
            <div className="flex items-center">
              <Plus color="#6C2BD9" /><span className="px-2 py-1">Add-on infobase: ${infobaseAddOnDetails.monthly_price}/month</span>
            </div>
          )}
          {numberAddOnDetails?.id && (
            <div className="flex items-center">
               <Plus color="#6C2BD9" /><span className="px-2 py-1">Add-on numbers: ${numberAddOnDetails.monthly_price}/month</span>
            </div>
          )}
          {aiAgentAddOnDetails?.id && (
            <div className="flex items-center">
               <Plus color="#6C2BD9" /><span className="px-2 py-1">Add-on agents: ${aiAgentAddOnDetails.monthly_price}/month</span>
            </div>
          )}
          {concurrentAddOnDetails?.id && (
            <div className="flex items-center">
               <Plus color="#6C2BD9" /><span className="px-2 py-1">Add-on concurrent calls: ${concurrentAddOnDetails.monthly_price}</span>
            </div>
          )}
          {userAddOnDetails?.id && (
            <div className="flex items-center">
               <Plus color="#6C2BD9" /><span className="px-2 py-1">Add-on users: ${userAddOnDetails.monthly_price}/month</span>
            </div>
          )}
        </div>
      </div>
      <Button
        variant={subscriptionStore.currentPlan?.id === plan?.id ? "outline" : "default"}
        // className={`w-full py-2 text-white font-medium rounded-md ${
        //   plan?.is_recommended ? "bg-purple-700" : "bg-gray-700"
        // } hover:opacity-90 transition`}
        disabled={loader}
        onClick={() => onPlanBuy({
          plan:plan, 
          duration: (isMonthly ? "monthly" : "yearly")
        })}
      >
        {subscriptionStore.currentPlan?.id === plan?.id ? "Current plan" : `Buy ${plan?.name}`}
      </Button>
    </div>
  );
};

export default SubscriptionPlanCard;
