import React, { useState, useContext, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import useSharedStore from "../../store/shared.store";
import { LoggedUser } from "../../models/logged-user";
import { Blocks, Users, UsersRound } from "lucide-react";
import CustomIcon from "../shared/CustomIcon";
import { getInboxesData, getWidgetsData } from "../../lib/common.utils";

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("");
  const location = useLocation();
  const sharedStore = useSharedStore();
  const user = new LoggedUser(JSON.parse(localStorage.getItem("user")!));

  useEffect(() => {
    getInboxesData();
    getWidgetsData();
  }, []);

  return (
    <div
      className="flex w-full h-screen p-0 m-0"
      style={{ paddingTop: `${sharedStore.getNotificationBarSize()}px` }}
    >
      <div className="w-72 h-full border-r border-gray-300">
        <div className="h-16 p-4 border-b border-gray-300 font-inter text-lg font-semibold leading-7 text-left text-[var(--primary-color)]">
          <span>Settings</span>
        </div>
        <div className="p-4 flex flex-col gap-2">
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.phone_numbers?.view) && (<Link
            className={`tab-item ${
              activeTab === "phone-numbers" ? "bg-gray-100" : ""
            } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
              location.pathname === "/settings/phone-numbers"
                ? "bg-[#F3F4F6]"
                : "hover:bg-[#F3F4F6]"
            }`}
            to="/settings/phone-numbers"
            onClick={() => setActiveTab("phone-numbers")}
          >
            <CustomIcon width={20} height={20} iconName="calls"></CustomIcon>{" "}
            Phone numbers
          </Link>)}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.widgets?.view) && (<Link
            className={`tab-item ${
              activeTab === "widgets" ? "bg-gray-100" : ""
            } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
              location.pathname === "/settings/widgets"
                ? "bg-[#F3F4F6]"
                : "hover:bg-[#F3F4F6]"
            }`}
            to="/settings/widgets"
            onClick={() => setActiveTab("widgets")}
          >
            <CustomIcon width={20} height={20} iconName="widgets"></CustomIcon>{" "}
            Widgets
          </Link>)}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.users?.view) && (<Link
            className={`tab-item ${
              activeTab === "users" ? "bg-gray-100" : ""
            } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
              location.pathname === "/settings/users"
                ? "bg-[#F3F4F6]"
                : "hover:bg-[#F3F4F6]"
            }`}
            to="/settings/users"
            onClick={() => setActiveTab("users")}
          >
            <CustomIcon width={20} height={20} iconName="users"></CustomIcon>
            Users
          </Link>)}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.view) && (
            <Link
              className={`tab-item ${
                activeTab === "integrations" ? "bg-gray-100" : ""
              } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
                location.pathname === "/settings/integrations"
                  ? "bg-[#F3F4F6]"
                  : "hover:bg-[#F3F4F6]"
              }`}
              to="/settings/integrations"
              onClick={() => setActiveTab("integrations")}
            >
              <CustomIcon
                width={20}
                height={20}
                iconName="integrations"
              ></CustomIcon>{" "}
              Integrations
            </Link>
          )}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.custom_actions?.view) && (<Link
            className={`tab-item ${
              activeTab === "custom-tools" ? "bg-gray-100" : ""
            } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
              location.pathname === "/settings/custom-tools"
                ? "bg-[#F3F4F6]"
                : "hover:bg-[#F3F4F6]"
            }`}
            to="/settings/custom-tools"
            onClick={() => setActiveTab("custom-tools")}
          >
            <CustomIcon
              width={20}
              height={20}
              iconName="offline_bolt"
            ></CustomIcon>{" "}
            Custom actions
          </Link>)}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.webhooks?.view) && (<Link
            className={`tab-item ${
              activeTab === "webhooks" ? "bg-gray-100" : ""
            } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
              location.pathname === "/settings/webhooks"
                ? "bg-[#F3F4F6]"
                : "hover:bg-[#F3F4F6]"
            }`}
            to="/settings/webhooks"
            onClick={() => setActiveTab("webhooks")}
          >
            <CustomIcon width={20} height={20} iconName="webhook"></CustomIcon>{" "}
            Webhooks
          </Link>)}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.api_keys?.view) && (
            <Link
              className={`tab-item ${
                activeTab === "api-keys" ? "bg-gray-100" : ""
              } font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] p-2 gap-2 flex items-center rounded-lg ${
                location.pathname === "/settings/api-keys"
                  ? "bg-[#F3F4F6]"
                  : "hover:bg-[#F3F4F6]"
              }`}
              to="/settings/api-keys"
              onClick={() => setActiveTab("api-keys")}
            >
              <CustomIcon width={20} height={20} iconName="key"></CustomIcon>{" "}
              API keys
            </Link>
          )}
        </div>
      </div>
      <div className="w-[calc(100%-280px)]">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default Settings;
