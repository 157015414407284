import { create } from "zustand";
import apiClient from "../interceptors/axiosApiInterceptor";
import { BehaviorSubject, Subject } from 'rxjs';
import { AiAgent } from '../models/ai-agent';
import { RetellWebClient } from 'retell-client-js-sdk';
import { CloudNumber } from "../models/cloud-number";

interface RetellState {
  retellClient: any;
  mediaRecorder: MediaRecorder | null;
  audioChunks: Blob[];
  retellWebsocket: WebSocket | null;
  stream: MediaStream | null;
  audioContext: AudioContext;
  liveAgent: AiAgent | null;
  callLive: boolean;
  callLiveEvents: Subject<any>;
  outgoingCallTrigger: BehaviorSubject<any>;
  callIdSet: Set<string>;
  liveCallTranscript: Subject<any>;
  subscribeToTranscript: (callback: (data: any) => void) => () => void;
  byteStringToUint8Array: (byteString: string) => Uint8Array;
  startCall: (id: string, token: string) => void;
  stopCall: () => void;
  getAgentTemplate: () => Promise<any>;
  registerCall: (obj: any) => Promise<any>;
  liveTextCall: (id: number, obj: any) => Promise<any>;
  outgoingCall: (obj: any) => Promise<any>;
  convertUint8ToFloat32: (array: Uint8Array) => Float32Array;
  convertFloat32ToUint8: (array: Float32Array) => Uint8Array;
  makeCall: Subject<any>;
  viewedCloudNumber: CloudNumber;
  setCallLive: (value: boolean) => void;
  setLiveAgent: (agent: AiAgent) => void;
}

const sdk = new RetellWebClient();

export const useRetellStore = create<RetellState>((set, get) => ({
  retellClient: null,
  mediaRecorder: null,
  audioChunks: [],
  retellWebsocket: null,
  stream: null,
  audioContext: new (window.AudioContext || (window as any).webkitAudioContext)(),
  liveAgent: null,
  callLive: false,
  callLiveEvents: new Subject<any>(),
  outgoingCallTrigger: new BehaviorSubject<any>([]),
  callIdSet: new Set<string>(),
  liveCallTranscript: new Subject<any>(),
  makeCall: new Subject<any>(),
  viewedCloudNumber: new CloudNumber(null),

  setCallLive: (value: boolean) => {
    set({ callLive: value });
  },
  setLiveAgent: (agent: AiAgent) => {
    set({ liveAgent: agent });
  },

  subscribeToTranscript: (callback) => {
    const subscription = get().liveCallTranscript.subscribe(callback);

    return () => {
      subscription.unsubscribe(); // ✅ Only unsubscribes this instance
    };
  },


  byteStringToUint8Array: (byteString: string) => {
    const len = byteString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = byteString.charCodeAt(i);
    }
    return bytes;
  },

  startCall: (id: string, token: string) => {
    console.log("Call live status: " + get().callLive);
    console.log(token)
    sdk.startCall({ accessToken: token });
    console.log("call started");
    get().callLiveEvents.next({
      type: 'call_started',
      call_id: id,
    });

    sdk.on('ready', () => {
      console.log('Retell SDK is ready.');
    });

    sdk.on('error', (error: any) => {
      console.error('Retell SDK error:', error);
      get().stopCall();
    });

    sdk.on('callStarted', (callInfo: any) => {
      console.log('Call started:', callInfo);
    });

    sdk.on('callEnded', (callInfo: any) => {
      console.log('Call ended:', callInfo);
    });

    sdk.on('event', (event: any) => {
      console.log('Event received:', event);
    });

    sdk.on('update', (event: any) => {
      get().liveCallTranscript.next(event.transcript);
    });
  },

  stopCall: () => {
    set({ callLive: false });
    console.log("Call live status: " + get().callLive);
    sdk.stopCall();
  },

  getAgentTemplate: async () => {
    try {
      const response = await apiClient.get('/voice/templates');
      return response.data;
    } catch (error) {
      console.error("Error fetching agent template", error);
      throw error;
    }
  },

  registerCall: async (obj: any) => {
    try {
      const response = await apiClient.post('/voice/call/register', obj);
      return response.data;
    } catch (error) {
      console.error("Error registering call", error);
      throw error;
    }
  },

  liveTextCall: async (id: number, obj: any) => {
    try {
      const response = await apiClient.post(`/voice/ai-agent/test/text/${id}`, obj);
      return response.data;
    } catch (error) {
      console.error("Error with live text call", error);
      throw error;
    }
  },

  outgoingCall: async (obj: any) => {
    try {
      const response = await apiClient.post('/voice/call/outbound', obj);
      return response.data;
    } catch (error) {
      console.error("Error with outgoing call", error);
      throw error;
    }
  },

  convertUint8ToFloat32: (array: Uint8Array) => {
    const targetArray = new Float32Array(array.byteLength / 2);
    const sourceDataView = new DataView(array.buffer);
    for (let i = 0; i < targetArray.length; i++) {
      targetArray[i] = sourceDataView.getInt16(i * 2, true) / Math.pow(2, 16 - 1);
    }
    return targetArray;
  },

  convertFloat32ToUint8: (array: Float32Array) => {
    const buffer = new ArrayBuffer(array.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < array.length; i++) {
      const value = (array[i] as number) * 32768;
      view.setInt16(i * 2, value, true);
    }
    return new Uint8Array(buffer);
  },
}));
