export class AgentTemplate {
  private _id: number
  private _agent_name: string
  private _language: string
  private _voice: Voice
  private _general_prompt: string
  private _perfect_for: PerfectFor[]
  private _category: string

    public get category(): string {
        return this._category;
    }

    public set category(category: string) {
        this._category = category;
    }


  constructor(agentTemplateJson: any) {
    this._id = agentTemplateJson?.id;
    this._agent_name = agentTemplateJson?.agent_name;
    this._language = agentTemplateJson?.language;
    this._voice = new Voice(agentTemplateJson?.voice);
    this._general_prompt = agentTemplateJson?.general_prompt;
    this._perfect_for = [];
    agentTemplateJson?.perfect_for?.forEach((element: any) => {
      this._perfect_for.push(new PerfectFor(element));
    });
    this._category = agentTemplateJson?.category;
  }
    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get agent_name(): string {
        return this._agent_name;
    }

    public set agent_name(agent_name: string) {
        this._agent_name = agent_name;
    }

    public get language(): string {
        return this._language;
    }

    public set language(language: string) {
        this._language = language;
    }

    public get voice(): Voice {
        return this._voice;
    }

    public set voice(voice: Voice) {
        this._voice = voice;
    }

    public get general_prompt(): string {
        return this._general_prompt;
    }

    public set general_prompt(general_prompt: string) {
        this._general_prompt = general_prompt;
    }

    public get perfect_for(): PerfectFor[] {
        return this._perfect_for;
    }

    public set perfect_for(perfect_for: PerfectFor[]) {
      this._perfect_for = [];
      perfect_for?.forEach((element: any) => {
        this._perfect_for.push(new PerfectFor(element));
      });
    }

}

export class Voice {
  private _age: string
  private _name: string
  private _accent: string
  private _gender: string
  private _provider: string
  private _scenario: string
  private _voice_id: string
  private _avatar_url: string
  private _preview_url: string
  private _provider_voice_id: string

  constructor(voiceJson: any) {
    this._age = voiceJson?.age;
    this._name = voiceJson?.name;
    this._accent = voiceJson?.accent;
    this._gender = voiceJson?.gender;
    this._provider = voiceJson?.provider;
    this._scenario = voiceJson?.scenario;
    this._voice_id = voiceJson?.voice_id;
    this._avatar_url = voiceJson?.avatar_url;
    this._preview_url = voiceJson?.preview_url;
    this._provider_voice_id = voiceJson?.provider_voice_id;
  }
    public get age(): string {
        return this._age;
    }

    public set age(age: string) {
        this._age = age;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get accent(): string {
        return this._accent;
    }

    public set accent(accent: string) {
        this._accent = accent;
    }

    public get gender(): string {
        return this._gender;
    }

    public set gender(gender: string) {
        this._gender = gender;
    }

    public get provider(): string {
        return this._provider;
    }

    public set provider(provider: string) {
        this._provider = provider;
    }

    public get scenario(): string {
        return this._scenario;
    }

    public set scenario(scenario: string) {
        this._scenario = scenario;
    }

    public get voice_id(): string {
        return this._voice_id;
    }

    public set voice_id(voice_id: string) {
        this._voice_id = voice_id;
    }

    public get avatar_url(): string {
        return this._avatar_url;
    }

    public set avatar_url(avatar_url: string) {
        this._avatar_url = avatar_url;
    }

    public get preview_url(): string {
        return this._preview_url;
    }

    public set preview_url(preview_url: string) {
        this._preview_url = preview_url;
    }

    public get provider_voice_id(): string {
        return this._provider_voice_id;
    }

    public set provider_voice_id(provider_voice_id: string) {
        this._provider_voice_id = provider_voice_id;
    }

}

export class PerfectFor {
  private _name: string

  constructor(perfectForJson: any) {
    this._name = perfectForJson?.name
  }
    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

}
