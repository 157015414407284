export class AgentAction {
  private _id: number
  private _action_id: string
  private _name: string
  private _type: string
  private _data: any
  private _fields: any
  private _trigger: string
  private _call_type: string
  private _created_at: string
  private _updated_at: string
  private _is_active: boolean
  private _n8n_flow: number
  private _business: number
  private _agent: number

  constructor(agentActionJson: any) {
    this._id = agentActionJson?.id;
    this._action_id = agentActionJson?.action_id;
    this._name = agentActionJson?.name;
    this._type = agentActionJson?.type;
    this._data = agentActionJson?.data;
    this._fields = agentActionJson?.fields;
    this._trigger = agentActionJson?.trigger;
    this._call_type = agentActionJson?.call_type;
    this._created_at = agentActionJson?.created_at;
    this._updated_at = agentActionJson?.updated_at;
    this._is_active = agentActionJson?.is_active;
    this._n8n_flow = agentActionJson?.n8n_flow;
    this._business = agentActionJson?.business;
    this._agent = agentActionJson?.agent;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get action_id(): string {
        return this._action_id;
    }

    public set action_id(action_id: string) {
        this._action_id = action_id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get type(): string {
        return this._type;
    }

    public set type(type: string) {
        this._type = type;
    }

    public get data(): any {
        return this._data;
    }

    public set data(data: any) {
        this._data = data;
    }

    public get fields(): any {
        return this._fields;
    }

    public set fields(fields: any) {
        this._fields = fields;
    }

    public get trigger(): string {
        return this._trigger;
    }

    public set trigger(trigger: string) {
        this._trigger = trigger;
    }

    public get call_type(): string {
        return this._call_type;
    }

    public set call_type(call_type: string) {
        this._call_type = call_type;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get is_active(): boolean {
        return this._is_active;
    }

    public set is_active(is_active: boolean) {
        this._is_active = is_active;
    }

    public get n8n_flow(): number {
        return this._n8n_flow;
    }

    public set n8n_flow(n8n_flow: number) {
        this._n8n_flow = n8n_flow;
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

    public get agent(): number {
        return this._agent;
    }

    public set agent(agent: number) {
        this._agent = agent;
    }

}