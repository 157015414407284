import React, { useState } from "react";
import { Input } from "../shadcn/input";
import { Button } from "../shadcn/button";
import { Textarea } from "../shadcn/textarea";

interface AddInfobaseFileProps {
  infobaseType: string;
  onAdd: (asset: any) => void;
  onClose: () => void;
}

const AddInfobaseFile: React.FC<AddInfobaseFileProps> = ({ infobaseType, onAdd, onClose }) => {
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");

  const handleAdd = () => {
    if (infobaseType === "text") {
      onAdd({ 
        name: name.trim(),
        title: name.trim(),
        content: content.trim(),
        type: 'text',
       });
    } else if (infobaseType === "website") {
      onAdd({ 
        name: url,
        url: url,
        type: 'website',
      });
    }
    onClose();
  };

  return (
    <div className="flex flex-col w-full space-y-4 bg-white rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800">
        {infobaseType === "text" ? "Add Text" : "Add Link"}
      </h2>

      <div className="space-y-4">
        {infobaseType === "text" ? (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name
              </label>
              <Input
                placeholder={infobaseType === "text" ? "Enter title" : "Enter website name"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Content</label>
              <Textarea
                placeholder="Enter content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="mt-1 max-h-[300px]"
              />
            </div>
          </div>
        ) : (
          <div>
            <label className="block text-sm font-medium text-gray-700">Website URL</label>
            <Input
              placeholder="Enter website URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="mt-1"
            />
          </div>
        )}
      </div>

      <div className="flex justify-end space-x-2">
        <Button variant="default" onClick={handleAdd} disabled={(infobaseType === 'text' && (!name || !content)) || (infobaseType === "website" && !url)}>
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddInfobaseFile;