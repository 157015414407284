import React from "react";

interface NameTagProps {
  name: string;
}

const NameTag: React.FC<NameTagProps> = ({ name }) => {
  const getInitials = (fullName: string) => {
    if (!fullName) return "";
    const words = fullName.split(" ");
    if (words.length > 1) {
      return (words[0][0] + words[1][0]).toUpperCase();
    }
    return words[0].substring(0, 2).toUpperCase();
  };

  return (
    <span className="relative flex items-center bg-[var(--hover-color)] rounded-lg w-fit">
      <span className="absolute left-0 h-5 w-5 bg-gray-300 rounded-full flex items-center justify-center text-[10px] font-medium">
        {getInitials(name)}
      </span>
      <span className="text-[12px] font-normal text-primary pl-6 pr-2 py-[2px]">{name}</span>
    </span>
  );
};

export default NameTag;
