export class UserPermission {
  private _id: number;
  private _name: string;
  private _description: string;
  private _module: string;

  constructor(userPermissionJson: any) {
    this._id = userPermissionJson?.id;
    this._name = userPermissionJson?.name;
    this._description = userPermissionJson?.description;
    this._module = userPermissionJson?.module;
  }

  public toJson(): any {
    return {
      id: this._id,
      name: this._name,
      description: this._description,
      module: this._module,
    };
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  public get module(): string {
    return this._module;
  }

  public set module(module: string) {
    this._module = module;
  }
}

export class PermissionDetails {
  private _id: number;
  private _permission: UserPermission;
  private _view: boolean;
  private _add: boolean;
  private _edit: boolean;
  private _delete: boolean;
  private _created_at: string;
  private _updated_at: string;
  private _role: number;
  private _inbox: number;
  private _widget: number;
  private _business: number;

  constructor(permissionDetailsJson: any) {
    this._id = permissionDetailsJson?.id;
    this._permission = new UserPermission(permissionDetailsJson?.permission);
    this._view = permissionDetailsJson?.view;
    this._add = permissionDetailsJson?.add;
    this._edit = permissionDetailsJson?.edit;
    this._delete = permissionDetailsJson?.delete;
    this._created_at = permissionDetailsJson?.created_at;
    this._updated_at = permissionDetailsJson?.updated_at;
    this._role = permissionDetailsJson?.role;
    this._inbox = permissionDetailsJson?.inbox;
    this._widget = permissionDetailsJson?.widget;
    this._business = permissionDetailsJson?.business;
  }

  public toJson(): any {
    return {
      id: this._id,
      permission: this._permission.toJson(), // Convert nested object
      view: this._view,
      add: this._add,
      edit: this._edit,
      delete: this._delete,
      created_at: this._created_at,
      updated_at: this._updated_at,
      role: this._role,
      inbox: this._inbox,
      widget: this._widget,
      business: this._business,
    };
  }
  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get permission(): UserPermission {
    return this._permission;
  }

  public set permission(permission: UserPermission) {
    this._permission = permission;
  }

  public get view(): boolean {
    return this._view;
  }

  public set view(view: boolean) {
    this._view = view;
  }

  public get add(): boolean {
    return this._add;
  }

  public set add(add: boolean) {
    this._add = add;
  }

  public get edit(): boolean {
    return this._edit;
  }

  public set edit(edit: boolean) {
    this._edit = edit;
  }

  public get delete(): boolean {
    return this._delete;
  }

  public set delete(value: boolean) {
    this._delete = value;
  }

  public get created_at(): string {
    return this._created_at;
  }

  public set created_at(value: string) {
    this._created_at = value;
  }

  public get updated_at(): string {
    return this._updated_at;
  }

  public set updated_at(value: string) {
    this._updated_at = value;
  }

  public get role(): number {
    return this._role;
  }

  public set role(value: number) {
    this._role = value;
  }

  public get inbox(): number {
    return this._inbox;
  }

  public set inbox(value: number) {
    this._inbox = value;
  }

  public get widget(): number {
    return this._widget;
  }

  public set widget(value: number) {
    this._widget = value;
  }

  public get business(): number {
    return this._business;
  }

  public set business(value: number) {
    this._business = value;
  }
}

export class UserRole {
  private _id: number;
  private _description: string;
  private _user_count: number;
  private _permissions: PermissionDetails[];
  private _name: string;
  private _created_at: string;
  private _updated_at: string;
  private _business: number;

  constructor(userRoleJson: any) {
    this._id = userRoleJson?.id;
    this._description = userRoleJson?.description;
    this._user_count = userRoleJson?.user_count;
    this._permissions = [];
    userRoleJson?.permissions?.forEach((element: any) => {
      this._permissions.push(new PermissionDetails(element));
    });
    this._name = userRoleJson?.name;
    this._created_at = userRoleJson?.created_at;
    this._updated_at = userRoleJson?.updated_at;
    this._business = userRoleJson?.business;
  }

  public toJson(): any {
    return {
      id: this._id,
      description: this._description,
      user_count: this._user_count,
      permissions: this._permissions.map((permission) => permission.toJson()), // Convert each permission object
      name: this._name,
      created_at: this._created_at,
      updated_at: this._updated_at,
      business: this._business,
    };
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  public get user_count(): number {
    return this._user_count;
  }

  public set user_count(user_count: number) {
    this._user_count = user_count;
  }

  public get permissions(): PermissionDetails[] {
    return this._permissions;
  }

  public set permissions(permissions: PermissionDetails[]) {
    this._permissions = permissions;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get created_at(): string {
    return this._created_at;
  }

  public set created_at(created_at: string) {
    this._created_at = created_at;
  }

  public get updated_at(): string {
    return this._updated_at;
  }

  public set updated_at(updated_at: string) {
    this._updated_at = updated_at;
  }

  public get business(): number {
    return this._business;
  }

  public set business(business: number) {
    this._business = business;
  }
}
