import { ChevronsLeft, ChevronLeft, ChevronRight, ChevronsRight } from 'lucide-react';
import React, { useState, useEffect } from 'react';

interface PaginationProps {
  currentPage: number;
  paginationSize: number;
  listLength: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, paginationSize, listLength, onPageChange }) => {
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    setMaxPage(Math.ceil(listLength / paginationSize));
  }, [listLength, paginationSize]);

  const pageLeft = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const pageRight = () => {
    if (currentPage < maxPage) {
      onPageChange(currentPage + 1);
    }
  };

  const pageFirst = () => {
    onPageChange(1);
  };

  const pageLast = () => {
    onPageChange(maxPage);
  };

  return (
    <div className="flex gap-4 items-center justify-end">
      {listLength > paginationSize && (
        <>
          <span className="text-sm font-normal text-primary">Page {currentPage} of {maxPage}</span>
          <div className="flex items-center gap-2">
            <button
              className="border border-gray-300 bg-none h-9 w-9 flex items-center justify-center p-0 rounded-lg hover:bg-gray-200"
              disabled={currentPage === 1}
              onClick={pageFirst}
            >
              <ChevronsLeft />
            </button>
            <button
              className="border border-gray-300 bg-none h-9 w-9 flex items-center justify-center p-0 rounded-lg hover:bg-gray-200"
              disabled={currentPage === 1}
              onClick={pageLeft}
            >
              <ChevronLeft />
            </button>
            <button
              className="border border-gray-300 bg-none h-9 w-9 flex items-center justify-center p-0 rounded-lg hover:bg-gray-200"
              disabled={currentPage === maxPage}
              onClick={pageRight}
            >
              <ChevronRight />
            </button>
            <button
              className="border border-gray-300 bg-none h-9 w-9 flex items-center justify-center p-0 rounded-lg hover:bg-gray-200"
              disabled={currentPage === maxPage}
              onClick={pageLast}
            >
              <ChevronsRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
