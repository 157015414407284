import apiClient from '../interceptors/axiosApiInterceptor';

export const getNotes = (params: any) =>
  apiClient.get('/note/', { params });

export const addNotes = (obj: any) =>
  apiClient.post('/note/', obj);

export const updateNote = (obj: any, id: number) =>
  apiClient.put(`/note/${id}`, obj);

export const deleteNote = (id: number) =>
  apiClient.delete(`/note/${id}`);
