import React, { useEffect, useState } from 'react';
import { Button } from '../shadcn/button';
import { TableHeader, TableRow, TableCell, TableBody, Table } from '../shadcn/table';
import { ScrollArea } from '../shadcn/scrollArea';
import { ChevronDown, CircleCheck, PauseCircle, Play } from 'lucide-react';
import { toPascalCase } from '../../lib/ui.utils';
import CustomIcon from '../shared/CustomIcon';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../shadcn/dropdown-menu';

const VoiceListPopup = ({ voiceList = [], selectedVoice, onVoiceChange }: {voiceList: any[], selectedVoice: string, onVoiceChange: (voice:any) => void}) => {
  const [filterVoiceList, setFilterVoiceList] = useState(voiceList);
  const [genderFilter, setGenderFilter] = useState('All');
  const [accentFilter, setAccentFilter] = useState('All');
  const [ageFilter, setAgeFilter] = useState('All');
  const [playingVoice, setPlayingVoice] = useState(null);
  const [audio, setAudio] = useState(new Audio());

  const [voiceSelected, setVoiceSelected] = useState<any>(null);

  const [genderDropdown, setGenderDropdown] = useState(false);
  const [accentDropdown, setAccentDropdown] = useState(false);
  const [ageDropdown, setAgeDropdown] = useState(false);


  // Extract filters dynamically
  const accentFilterList = Array.from(new Set(voiceList.map((voice) => voice.accent)));
  const ageFilterList = Array.from(new Set(voiceList.map((voice) => voice.age)));

  const newAudio = new Audio();


  useEffect(() => {
    applyFilters();
    return () => {
      audio.pause(); // Clean up audio when unmounting
    };
  }, [genderFilter, accentFilter, ageFilter]);

  useEffect(() => {
    voiceList?.forEach((element) => {
      if(selectedVoice === element.voice_id) setVoiceSelected(element)
    })
  },[selectedVoice])

  const applyFilters = () => {
    let updatedList = voiceList;

    if (genderFilter !== 'All') {
      updatedList = updatedList.filter((voice) => voice.gender.toLowerCase() === genderFilter.toLowerCase());
    }
    if (accentFilter !== 'All') {
      updatedList = updatedList.filter((voice) => voice.accent.toLowerCase() === accentFilter.toLowerCase());
    }
    if (ageFilter !== 'All') {
      updatedList = updatedList.filter((voice) => voice.age.toLowerCase() === ageFilter.toLowerCase());
    }

    setFilterVoiceList(updatedList);
  };

  const playAudio = (voice: any) => {
    if (playingVoice === voice.id) {
      audio.pause();
      setPlayingVoice(null);
    } else {
      audio.pause();
      setPlayingVoice(null);
      newAudio.src = (voice.preview_url);
      newAudio.play();
      setAudio(newAudio);
      setPlayingVoice(voice.id);

      newAudio.addEventListener('ended', () => setPlayingVoice(null));
    }
  };

  const selectVoice = (voice:any) => {
    setVoiceSelected(voice);
  };

  return (
    <div className="bg-white w-full h-full overflow-hidden">
      <div className="flex justify-between items-center text-primary text-2xl font-semibold leading-[30px] mb-4">
        <span>
          Voice 
          <span className="font-medium text-secondary text-lg leading-6 ml-2">
            {filterVoiceList.length}
          </span>
        </span>
      </div>

      <div className="flex gap-4 mb-4">
        {/* Accent Filter */}
        <DropdownMenu open={accentDropdown} onOpenChange={setAccentDropdown}>
          <DropdownMenuTrigger>
            <Button variant="outline" className="flex w-full justify-between items-center font-normal">
              <span>Accent: {accentFilter}</span> <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='z-[9999] pointer-events-auto touch-pan-y p-2'>
              <ul className="max-h-[300px] bg-white overflow-auto touch-pan-y pointer-events-auto">
                <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {setAccentFilter('All');}}>
                  All
                </li>
                {accentFilterList.map((filter, index) => (
                  <li
                    key={index}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => {setAccentFilter(filter);}}
                  >
                    {filter}
                  </li>
                ))}
              </ul>
          </DropdownMenuContent>
        </DropdownMenu>

        {/* Gender Filter */}
        <DropdownMenu open={genderDropdown} onOpenChange={setGenderDropdown}>
          <DropdownMenuTrigger>
            <Button variant="outline" className="flex w-full justify-between items-center font-normal">
              <span>Gender: {genderFilter}</span> <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='z-[9999] pointer-events-auto touch-pan-y p-2'>
              <ul className="max-h-[300px] bg-white overflow-auto touch-pan-y pointer-events-auto">
                <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {setGenderFilter('All');setGenderDropdown(false)}}>
                  All
                </li>
                <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {setGenderFilter('Male');setGenderDropdown(false)}}>
                  Male
                </li>
                <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {setGenderFilter('Female');setGenderDropdown(false)}}>
                  Female
                </li>
              </ul>
          </DropdownMenuContent>
        </DropdownMenu>

        {/* Age Filter */}
        <DropdownMenu open={ageDropdown} onOpenChange={setAgeDropdown}>
          <DropdownMenuTrigger>
            <Button variant="outline" className="flex w-full justify-between items-center font-normal">
              <span>Age: {ageFilter}</span> <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='z-[9999] pointer-events-auto touch-pan-y p-2'>
              <ul className="max-h-[300px] bg-white overflow-auto touch-pan-y pointer-events-auto">
                <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => {setAgeFilter('All');setAgeDropdown(false)}}>
                  All
                </li>
                {ageFilterList.map((filter, index) => (
                  <li
                    key={index}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => {setAgeFilter(filter);setAgeDropdown(false)}}
                  >
                    {filter}
                  </li>
                ))}
              </ul>
          </DropdownMenuContent>
        </DropdownMenu>

      </div>

      <ScrollArea className="border border-gray-300 rounded-lg overflow-auto h-[calc(100%-160px)]">
        <Table className="w-full h-full text-left">
          <TableHeader>
            <TableRow className="bg-gray-100 sticky top-0 z-10">
              <TableCell className="p-2"></TableCell>
              <TableCell className="p-2">Name</TableCell>
              <TableCell className="p-2">Accent</TableCell>
              <TableCell className="p-2">Gender</TableCell>
              <TableCell className="p-2">Age</TableCell>
              <TableCell className="p-2"></TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filterVoiceList.map((voice) => (
              <TableRow
                key={voice.id}
                className={`cursor-pointer ${
                  voiceSelected?.voice_id === voice.voice_id ? 'bg-gray-200' : 'hover:bg-gray-100'
                }`}
                onClick={() => selectVoice(voice)}
              >
                <TableCell className="p-2">
                  {voiceSelected?.voice_id === voice.voice_id && (
                    <CustomIcon width={20} height={20} iconName="check_circle_white"></CustomIcon>
                  )}
                </TableCell>
                <TableCell className="p-2">{toPascalCase(voice.name)}</TableCell>
                <TableCell className="p-2">{toPascalCase(voice.accent)}</TableCell>
                <TableCell className="p-2">{toPascalCase(voice.gender)}</TableCell>
                <TableCell className="p-2">{toPascalCase(voice.age)}</TableCell>
                <TableCell className="p-2">
                  <span
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      playAudio(voice);
                    }}
                  >
                    {playingVoice === voice.id ? (
                      <PauseCircle size={20} />
                    ) : (
                      <CustomIcon width={20} height={20} iconName="play"></CustomIcon>
                    )}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {filterVoiceList.length === 0 && (
          <div className="flex justify-center items-center h-32 text-primary text-lg font-medium">
            No results found, try changing the filters
          </div>
        )}
      </ScrollArea>

      <div className="mt-4 flex justify-end">
        <Button onClick={() => onVoiceChange(voiceSelected)}>Select Voice</Button>
      </div>
    </div>

  );
};

export default VoiceListPopup;
