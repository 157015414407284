import React, { useState } from "react";
import { CheckCircle, ArrowLeft, Phone } from "lucide-react";
import { Button } from "../../shadcn/button";
import { CloudNumber } from "../../../models/cloud-number";
import { AiAgent } from "../../../models/ai-agent";
import { Widget } from "../../../models/widget";
import { useNavigate } from "react-router-dom";
import { useAgencyStore } from "../../../store/agency.store";
import "../../Settings/Widget/ViewWidget.css";

interface WelcomeForm4Props {
  number: CloudNumber;
  agent: AiAgent;
  widget: Widget;
  onSkip: () => void;
  onBack: () => void;
  onNext: () => void;
}

const WelcomeForm4: React.FC<WelcomeForm4Props> = ({
  number,
  agent,
  widget,
  onSkip,
  onBack,
  onNext,
}) => {
  const [callStatus, setCallStatus] = useState("");
  const navigate = useNavigate();
  const agencyStore = useAgencyStore();

  return (
    <div className="w-full flex flex-col gap-4 p-4 h-full overflow-auto">
      <div className="flex items-center justify-between p-4">
        <button onClick={onBack} className="flex items-center text-gray-500">
          <ArrowLeft size={20} className="mr-1" /> Back
        </button>
        <div className="flex items-center space-x-2 text-purple-600">
          <CheckCircle size={18} /> <span>Create AI agent</span>
          <hr className="border-t w-12" />
          <CheckCircle size={18} /> <span>Test AI agent</span>
          <hr className="border-t w-12" />
          <CheckCircle size={18} /> <span>Deploy AI agent</span>
          <hr className="border-t w-12" />
          <span>Go live</span>
        </div>
        <button onClick={onSkip} className="text-gray-500">
          Skip
        </button>
      </div>

      <div className="w-full flex items-center flex-col gap-4">
        {number?.id && (
          <>
            <div className="flex flex-col items-center gap-1 p-4">
              <h2 className="text-lg font-semibold">Go live</h2>
              <p className="text-gray-500">Make call on your phone number</p>
            </div>
            <div className="template-cards">
              <div className="p-6 flex justify-center rounded max-w-md">
                <div className="flex flex-col items-center gap-12">
                  <img
                    className="w-[180px] h-[180px]"
                    src="/assets/images/phone_number_empty.png"
                    alt="Phone"
                  />
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-lg font-semibold">
                      {number?.number}
                    </span>
                    <span className="text-gray-500">
                      Well Done !! You are now ready to go live.
                    </span>
                    {callStatus === "" && (
                      <span className="waiting-for-call mt-4 bg-[var(--hover-color)] rounded-md py-2 px-4 flex items-center gap-2 text-[var(--primary-color)] text-sm font-medium leading-6">
                        Waiting for call...
                      </span>
                    )}
                    {callStatus !== "" && (
                      <Button
                        onClick={() => navigate("/calls")}
                        className="save-button"
                      >
                        Explore {agencyStore.brand}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {widget?.id && (
          <>
            <div className="flex flex-col items-center gap-1 p-4">
              <h2 className="text-lg font-semibold">Go live</h2>
              <p className="text-gray-500">
                Add this code to your website & make a call through widget
              </p>
            </div>
            <div className="flex flex-col gap-8 p-6 items-center justify-center">
              <div className="widget-preview">
                <div className="widget-main-body">
                  <div className="widget-heading">
                    <span>
                      <img src={widget?.logo} alt="" />
                    </span>
                    <span className="widget-heading-details">
                      <span className="text-[var(--primary-color)] font-inter text-[16px] font-medium leading-6 text-left">
                        {widget?.brand_name}
                      </span>
                      <span className="text-[var(--secondary-color)] font-inter text-[14px] font-normal leading-5 text-left">
                        {widget?.properties?.subtitle}
                      </span>
                    </span>
                  </div>
                  <div className="widget-body">
                    <span>
                      <img
                        src={
                          widget?.agent?.profile_pic ??
                          "../../../../../assets/images/avatar.png"
                        }
                        alt=""
                      />
                    </span>
                    <span className="widget-body-name">
                      {widget?.agent?.agent_name}
                    </span>
                    <span className="widget-body-status">
                      <span></span> Available
                    </span>
                    <span className="widget-body-status">
                      {widget?.properties?.welcome_text}
                    </span>
                  </div>
                  <div className="widget-footer">
                    <button
                      style={{
                        background: widget?.properties?.color,
                        color: widget?.properties?.text_color,
                      }}
                    >
                      <Phone
                        size="16px"
                        color={widget?.properties?.text_color ?? "#070A0B"}
                      />
                      {widget?.properties?.button_text}
                    </button>
                    <span>
                      Powered by <img src={agencyStore?.darkLogo} alt="" />
                    </span>
                  </div>
                </div>
                <div
                  className="widget-icon"
                  style={{
                    justifyContent:
                      widget?.properties?.position === "bottom-right"
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <span style={{ background: widget?.properties?.color }}>
                    <Phone
                      size="28px"
                      color={widget?.properties?.text_color ?? "#070A0B"}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-8 p-6 items-center justify-center">
              <div className="flex flex-col gap-1 max-w-xs">
                <span className="text-gray-700 font-medium">Widget code</span>
                <span className="text-gray-500" style={{ maxWidth: "640px" }}>
                  Place the code before the closing body tag on your site.
                </span>
              </div>
              <div className="flex flex-col gap-1 max-w-xs">
                <pre className="p-1 bg-[var(--hover-color)] border border-[var(--Border-bd-1,var(--border-color))] rounded-lg text-[var(--secondary-color)]">
                  <code
                    id="copyText"
                    style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                  >
                    &lt;widget-app token="{widget?.secret}" id="
                    {widget?.id}"&gt;&lt;/widget-app&gt; &lt;script
                    src="https://widget-agent-prod.s3.ap-south-1.amazonaws.com/widget.js"&gt;&lt;/script&gt;
                  </code>
                </pre>
                <Button
                  variant="outline"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `<widget-app token="${widget?.secret}" id="${widget?.id}"></widget-app><script src="https://widget-agent-prod.s3.ap-south-1.amazonaws.com/widget.js"></script>`
                    )
                  }
                >
                  Copy embed code
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WelcomeForm4;
