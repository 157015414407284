import React, { useState, useRef, useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";
import { Smile } from "lucide-react";
import EmojiPicker from "emoji-picker-react";

interface EditableNoteProps {
  note: string;
  contact: boolean;
  disable: boolean;
  isEdit?: boolean;
  onDelete: () => void;
  onSave: (note: string) => void;
}
const EditableNote: React.FC<EditableNoteProps> = ({ note, contact = false, disable = false, isEdit = false, onDelete, onSave }) => {
  const [inputNote, setInputNote] = useState(note);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const textareaRef = useRef<any>(null);
  const [messageCursorPosition, setMessageCursorPosition] = useState(0);
  const [noteEdit, setNoteEdit] = useState(isEdit);

  const handleTextChange = (e: any) => {
    setInputNote(e.target.value);
  };

  useEffect(() => {
    setNoteEdit(isEdit);
    setInputNote(note)
  },[isEdit, note])

  const saveNote = () => {
    setInputNote(inputNote);
    setNoteEdit(false);
    onSave(inputNote); // Emits the updated note
  };
  
  const cancelNote = () => {
    setInputNote(note);
    setNoteEdit(false);
  };
  
  const openEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
    if (textareaRef.current) {
      setMessageCursorPosition(textareaRef.current.selectionStart);
    }
  };
  
  const addEmoji = (emoji: any) => {
    if (textareaRef.current) {
      const updatedString =
        inputNote.substring(0, messageCursorPosition) +
        emoji.native +
        inputNote.substring(messageCursorPosition);
        
      setInputNote(updatedString);
      setMessageCursorPosition((prev) => prev + 1);
    }
  };
  

  return (
    <div className={`relative w-fit min-w-[${isEdit ? "300px" : "0"}]`}>
      {!isEdit ? (
        <div
          className={`bg-${contact ? "white" : "gray-200"} p-2 rounded-lg w-fit font-inter text-sm font-normal leading-6 text-left text-primary whitespace-pre-wrap break-words`}
          dangerouslySetInnerHTML={{ __html: note }}
        ></div>
      ) : (
        <div className="w-full flex flex-col gap-2 rounded-lg border border-gray-500 p-2">
          <textarea
            ref={textareaRef}
            value={inputNote}
            onChange={handleTextChange}
            className="w-full border-none font-inter text-sm font-normal leading-6 text-left text-primary resize-none focus:outline-none"
          ></textarea>
          <div className="flex justify-between items-center">
          <span className="relative">
          <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker}>
            <PopoverTrigger asChild onClick={() => (!showEmojiPicker ? openEmojiPicker() : '')}>
              <Smile className="absolute bottom-0 right-0 w-3.5 h-3.5" />
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <EmojiPicker
                onEmojiClick={(emojiObject: any) => {
                  addEmoji(emojiObject);
                  setShowEmojiPicker(false);
                }}
              />
            </PopoverContent>
          </Popover>
        </span>
            <span className="flex gap-2">
              <button className="px-2 rounded-md text-primary hover:bg-gray-200" onClick={() => cancelNote()}>Cancel</button>
              <button className="px-2 bg-primary text-white rounded-md hover:bg-gray-800" onClick={() => { saveNote()}}>Save</button>
            </span>
          </div>
        </div>
      )}
      {!contact && !disable && !isEdit && (
        <div className="absolute top-[-18px] right-0 hidden border border-gray-300 p-1 flex gap-2 items-center bg-gray-200 rounded-lg hover:flex">
          <span className="cursor-pointer" onClick={() => setNoteEdit(true)}>
            <img src="../assets/svg/edit.svg" alt="Edit" className="w-5 h-5" />
          </span>
          <span className="cursor-pointer" onClick={() => onDelete()}>
            <img src="../assets/svg/delete.svg" alt="Delete" className="w-5 h-5" />
          </span>
        </div>
      )}
    </div>
  );
};

export default EditableNote;
