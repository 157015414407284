import { Type, Hash, MapPin, CalendarDays, Link2, SquareCheck, CircleCheck, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { Popover, PopoverContent } from "../shadcn/popover";
import { toPascalCase } from "../../lib/ui.utils";

interface CustomFieldEditDeleteProps {
  isAdd?: boolean;
  type?: string;
  name?: string;
  loader?: boolean;
  onSave: (name: string) => void;
  onDelete: () => void;
  onClose: () => void;
}
const CustomFieldEditDelete: React.FC<CustomFieldEditDeleteProps> = ({
  isAdd = true,
  type = "",
  name = "",
  loader = false,
  onSave,
  onDelete,
  onClose,
}) => {
  const [inputName, setInputName] = useState(name);
  const [placeholder, setPlaceholder] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    let placeholderText = type.toLowerCase();
    if (placeholderText === "int") {
      placeholderText = "Enter number";
      setInputName("Number");
    }
    else if (placeholderText === "bool") {
      placeholderText = "Enter checkbox"
      setInputName("Checkbox");
    }
    else {
      setInputName(toPascalCase(placeholderText));
      placeholderText = `Enter ${placeholderText}`
    };
    if(!name || name === "") {
      if (type === "int") {
        setInputName("Number");
      }
      else if (type === "bool") {
        setInputName("Checkbox");
      }
      else {
        setInputName(toPascalCase(type));
      };
    } else setInputName(name);
    setPlaceholder(placeholderText);
    setIsOpen(true);
  }, [type, isAdd, name]);


  const handleSave = () => {
    if (onSave) onSave(inputName.trim());
  };

  return (
    <div className="flex items-center gap-2 p-1">
      {type === "Text" || type === "text" ? <span><Type color="var(--icon-sec-color)" /></span> : null}
      {type === "Number" || type === "int" ? <span><Hash color="var(--icon-sec-color)" /></span> : null}
      {type === "Address" || type === "address" ? <span><MapPin color="var(--icon-sec-color)" /></span> : null}
      {type === "Date" || type === "date" ? <span><CalendarDays color="var(--icon-sec-color)" /></span> : null}
      {type === "URL" || type === "url" ? <span><Link2 color="var(--icon-sec-color)" /></span> : null}
      {type === "Checkbox" || type === "bool" ? <span><SquareCheck color="var(--icon-sec-color)" /></span> : null}

      <input
        type="text"
        value={inputName}
        onChange={(e) => setInputName(e.target.value)}
        placeholder={placeholder}
        maxLength={32}
        className="border border-[var(--border-color)] rounded-lg focus:outline-none focus:ring-0 focus:border-[var(--border-color)] focus:ring-offset-0 h-[38px] outline-none bg-transparent" 
        onKeyDown={(e) => e.key === "Enter" && !loader && handleSave()}
      />

      <span className="bg-gray-200 rounded-lg p-2 cursor-pointer" onClick={!loader ? handleSave : undefined}>
        {!loader ? <CircleCheck color="var(--icon-sec-color)" /> : <div className="w-5 h-5 border-2 border-gray-500 border-t-transparent rounded-full animate-spin"></div>}
      </span>

      <span className="bg-gray-200 rounded-lg p-2 cursor-pointer" onClick={onDelete}>
        <Trash color="var(--icon-sec-color)" />
      </span>
    </div>
    
  );
};

export default CustomFieldEditDelete;
