import { create } from 'zustand';
import { Subject, BehaviorSubject } from 'rxjs';

// Define Zustand store
type WebSocketStore = {
  callEvents$: Subject<any>;
  userStatus$: BehaviorSubject<any[]>;
  campaignStatus$: BehaviorSubject<any[]>;
  campaignProgress$: BehaviorSubject<any[]>;
  audioStream$: BehaviorSubject<any>;
  askCopilotEvent$: BehaviorSubject<any>;
  integrationEvents$: Subject<any>;
  testAgentTextEvents$: Subject<any>;
  subscriptionEvent$: Subject<any>;
  
  sendMessage: (message: any) => void;
  setSendMessageFunc: (func: (message: any) => void) => void;

  // Method to handle incoming messages
  handleWebSocketMessage: (data: any) => void;

  setCloseWebSocketFunc: () => void;
};

export const useWebSocketStore = create<WebSocketStore>((set: any, get: any) => ({
  // Initialize RxJS Subjects
  callEvents$: new Subject<any>(),
  userStatus$: new BehaviorSubject<any[]>([]),
  campaignStatus$: new BehaviorSubject<any[]>([]),
  campaignProgress$: new BehaviorSubject<any[]>([]),
  audioStream$: new BehaviorSubject<any>(null),
  askCopilotEvent$: new BehaviorSubject<any>(null),
  integrationEvents$: new Subject<any>(),
  testAgentTextEvents$: new Subject<any>(),
  subscriptionEvent$: new Subject<any>(),
  
  sendMessage: (message: any) => {
    console.warn('sendMessage called before WebSocket setup');
  },

  setSendMessageFunc: (func: (message: string) => void) => set({
    sendMessage: (message: any) => {
      try {
        const payload = JSON.stringify(message); // Ensure the object is stringified
        func(payload);
      } catch (error) {
        console.error('Error stringifying message:', error);
      }
    },
  }),

  // Method to route incoming WebSocket messages
  handleWebSocketMessage: (msg: string) => {
    let data: any
    try {
      data = JSON.parse(msg);
    } catch (error) {
      console.log(error);
    }
    const {
      callEvents$,
      userStatus$,
      campaignStatus$,
      campaignProgress$,
      audioStream$,
      askCopilotEvent$,
      integrationEvents$,
      testAgentTextEvents$,
      subscriptionEvent$,
    } = get();
    console.log(data)
    switch (data?.type) {
      case 'call':
        callEvents$.next(data?.data);
        break;
      case 'user_status':
        userStatus$.next(data?.data);
        break;
      case 'campaign_status':
        campaignStatus$.next(data?.data);
        break;
      case 'campaign_progress':
        campaignProgress$.next(data?.data);
        break;
      case 'audio_stream':
        audioStream$.next(data?.data);
        break;
      case 'prompt_gen':
        askCopilotEvent$.next(data?.message);
        break;
      case 'integration':
        integrationEvents$.next(data?.data);
        break;
      case 'test_agent':
        testAgentTextEvents$.next(data);
        break;
      case 'subscription_update':
        subscriptionEvent$.next(data);
        break;
      default:
        console.warn('Unhandled message type:', data?.type);
    }
  },

  setCloseWebSocketFunc: () => {
    console.warn('closeWebsocket called before WebSocket setup');
  }

}));
