import apiClient from '../interceptors/axiosApiInterceptor';

const API_BASE_URL = '/infobase';

// Add an InfoBase file
export const addInfoBaseFile = (obj: any) =>
  apiClient.post(`${API_BASE_URL}/`, obj);

// Attach an InfoBase file to an AI agent
export const attachInfoBase = (obj: any, id: number) =>
  apiClient.put(`/voice/ai-agent/${id}/assign/infobase`, obj);

// Update an InfoBase file
export const updateInfoBase = (obj: any, id: number) =>
  apiClient.put(`${API_BASE_URL}/${id}`, obj);

// Get InfoBase files by page number
export const getInfoBaseFile = (pageNumber: number) =>
  apiClient.get(`${API_BASE_URL}?page=${pageNumber}`);

// Get active InfoBase files by page number
export const getActiveInfoBaseFile = (pageNumber: number, dependent: boolean = false) => {
  const query = dependent
    ? `${API_BASE_URL}?status=complete&r_type=dependent&page=${pageNumber}`
    : `${API_BASE_URL}?status=complete&page=${pageNumber}`;
  return apiClient.get(query);
};

// Search active InfoBase files by tag and page number
export const searchActiveInfoBaseFile = (searchTag: string, pageNumber: number, dependent: boolean = false) => {
  const query = dependent
    ? `${API_BASE_URL}?status=complete&r_type=dependent&q=${searchTag}&page=${pageNumber}`
    : `${API_BASE_URL}?status=complete&q=${searchTag}&page=${pageNumber}`;
  return apiClient.get(query);
};

// Delete an InfoBase file by ID
export const deleteInfoBaseFile = (id: number) =>
  apiClient.delete(`${API_BASE_URL}/${id}`);

// Search InfoBase files by tag and page number
export const searchInfoBaseFile = (searchTag: string, pageNumber: number) =>
  apiClient.get(`${API_BASE_URL}?q=${searchTag}&page=${pageNumber}`);

// Get a single InfoBase file by ID
export const getSingleInfoBase = (id: number) =>
  apiClient.get(`${API_BASE_URL}/${id}`);
