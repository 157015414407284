import React, { useState, useEffect } from 'react';
import { Calendar } from '../shadcn/calendar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../shadcn/dropdown-menu';
import { ChevronDown } from 'lucide-react';
import { Button } from '../shadcn/button';

interface DateDropdownProps {
  filter?: boolean;
  dateFilter?: boolean;
  inputDates?: string[];
  onChange: (dates: string[]) => void;
}

const DateDropdown: React.FC<DateDropdownProps> = ({ filter = false, dateFilter = false, inputDates = [], onChange }) => {
  const [filterPopup, setFilterPopup] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [prevFilterType, setPrevFilterType] = useState('Today');
  const [filterType, setFilterType] = useState('Today');
  const [dateRangeScreen, setDateRangeScreen] = useState(false);

  useEffect(() => {
    let currentDate = new Date();
    if (inputDates.length > 1 && inputDates[0] !== '' && inputDates[1] !== '')  {
      let today = new Date(new Date().toISOString().split('T')[0]);
      let fromDate = new Date(inputDates[0]);
      let toDate = new Date(inputDates[1]);
      if (matchDates(toDate, today) && matchDates(fromDate, new Date(today.getTime() - 24 * 60 * 60 * 1000))) {
        setFilterType('Today');
        setToDate(today);
        setFromDate(new Date(today.getTime() - 24 * 60 * 60 * 1000));
        setPrevFilterType('Today');
      } else if (matchDates(toDate, new Date(today.getTime() - 24 * 60 * 60 * 1000)) && matchDates(fromDate, new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000))) {
        setFilterType('Yesterday');
        setToDate(new Date(today.getTime() - 24 * 60 * 60 * 1000));
        setFromDate(new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000));
        setPrevFilterType('Yesterday');
      } else if (matchDates(fromDate, new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000))) {
        setFilterType('Last 7 days');
        setToDate(today);
        setFromDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
        setPrevFilterType('Last 7 days');
      } else if (matchDates(fromDate, new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000))) {
        setFilterType('Last 30 days');
        setToDate(today);
        setFromDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
        setPrevFilterType('Last 30 days');
      } else if (matchDates(fromDate, new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000))) {
        setFilterType('Last 90 days');
        setToDate(today);
        setFromDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
        setPrevFilterType('Last 90 days');
      } else if (matchDates(fromDate, new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000))) {
        setFilterType('Last year');
        setToDate(today);
        setFromDate(new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000));
        setPrevFilterType('Last year');
      } else {
        setFromDate(new Date(inputDates[0]));
        setToDate(new Date(inputDates[1]));
      }
    } else {
      setDates(filterType);
    }
  }, [inputDates]);

  const matchDates = (date1: Date, date2: Date) => {
    let d1 = new Date(date1)?.toISOString().split('T')[0];
    let d2 = new Date(date2)?.toISOString().split('T')[0];
    return d1 === d2;
  };

  const setDates = (type: string) => {
    setPrevFilterType(filterType);
    let today = new Date();
    if (type === 'Today') {
      setToDate(today);
      setFromDate(new Date(today.getTime() - 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Yesterday') {
      setToDate(new Date(today.getTime() - 24 * 60 * 60 * 1000));
      setFromDate(new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Last 7 days') {
      setToDate(today);
      setFromDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Last 30 days') {
      setToDate(today);
      setFromDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Last 90 days') {
      setToDate(today);
      setFromDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Last year') {
      setToDate(today);
      setFromDate(new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000));
      setFilterType(type);
    } else if (type === 'Custom') {
      setFilterType(type);
      setToDate(null);
      setFromDate(null);
      setDateRangeScreen(true);
    }
  };

  const onFromDateChange = (date: Date) => {
    setFromDate(new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  };

  const onToDateChange = (date: Date) => {
    setToDate(new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  };

  const cancelFilter = () => {
    setFilterPopup(false);
    setFilterType(prevFilterType);
    setDates(filterType);
  };

  useEffect(() => {
    applyTimeframe();
  },[filterType])

  const applyTimeframe = () => {
    setPrevFilterType(filterType);
    if (fromDate && toDate) {
      let fromString = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      let toString = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      onChange([fromString, toString]);
    } else {
      onChange(['', '']);
    }
    setFilterPopup(false);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="w-fit flex justify-between font-normal">
          <span className="flex items-center gap-2">
            {filter && "Joining Date: "}
            {dateFilter && "Date: "}
            {prevFilterType}
          </span>
          <ChevronDown />
        </Button>
      </DropdownMenuTrigger>
      
      <DropdownMenuContent className="bg-white border border-gray-300 w-full rounded-lg p-2 max-h-[300px] overflow-auto">
        {!dateRangeScreen ? (
          <>
            <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Today" ? "font-bold" : ""}`} onSelect={() => setDates("Today")}>
              Today
            </DropdownMenuItem>
            <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Yesterday" ? "font-bold" : ""}`} onSelect={() => setDates("Yesterday")}>
              Yesterday
            </DropdownMenuItem>
            <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Last 7 days" ? "font-bold" : ""}`} onSelect={() => setDates("Last 7 days")}>
              Last 7 days
            </DropdownMenuItem>
            <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Last 30 days" ? "font-bold" : ""}`} onSelect={() => setDates("Last 30 days")}>
              Last 30 days
            </DropdownMenuItem>
            <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Last 90 days" ? "font-bold" : ""}`} onSelect={() => setDates("Last 90 days")}>
              Last 90 days
            </DropdownMenuItem>
            {/* <DropdownMenuItem className={`p-2 hover:bg-gray-100 cursor-pointer ${filterType === "Custom" ? "font-bold" : ""}`} onSelect={() => setDates("Custom")}>
              Date range...
            </DropdownMenuItem> */}
          </>
        ) : (
          <div className="p-2">
            <p className="font-medium mb-2">Select date range</p>
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <span>From</span>
                <Calendar mode="single" selected={fromDate ?? new Date()} />
              </div>
              <div className="flex flex-col">
                <span>To</span>
                <Calendar mode="single" selected={toDate ?? new Date()} />
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <Button variant="outline" onClick={() => setDateRangeScreen(false)}>Cancel</Button>
              <Button onClick={applyTimeframe}>Save</Button>
            </div>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DateDropdown;