import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Business } from "../models/business";
import { CloudNumber } from "../models/cloud-number";
import { LoggedUser } from "../models/logged-user";
import { Message } from "../models/message";
import { SubscriptionPlan } from "../models/subscription_plan";
import { WhatsappDetails } from "../models/whatsapp-details";
import { Widget } from "../models/widget";
import { Characteristics } from "../models/ai-agent";
import { Customer, CustomProperty } from "../models/customer";
import { PermissionDetails } from "../models/user-role";

interface PermissionObject {
  [key: string]: {
    view: boolean;
    add: boolean;
    edit: boolean;
    delete: boolean;
    [key: string]: any;
  };
}

// Define the store
type SharedState = {
  // States
  apiUrl: string;
  isLoggedIn: boolean;
  inAuthModule: boolean;
  business: Business | null;
  whatsappDetails: WhatsappDetails | null;
  isWabaEnabled: boolean;
  newVersionEnabled: boolean;
  isPartnerBusiness: boolean;
  partnerConversationRate: string | null;
  businessCountry: string | null;

  apiCountryList: any[];
  apiStateList: any[];
  apiCityList: any[];

  languageList: any[];
  customFields: CustomProperty[];
  agentCharacteristics: Characteristics[];

  responsiveMenuBarOpen: boolean;
  hoverActive: boolean;

  audioPlayer: boolean;
  audioPlayerMessage: Message | null;

  subscriptionDaysLeft: number | null;

  cloudNumbers: CloudNumber[];
  callTunes: any[];
  widgetList: Widget[];

  gst_no: string;

  remoteConfig: {
    frill: boolean;
    downtime: boolean;
  };

  templateStudioEnabled: boolean;
  templateStudioOpened: boolean;

  voiceList: any[];

  loggedUser: LoggedUser | null;

  paymentGatewayKeys: {
    is_default: boolean;
    stripe: string;
    default: string;
  };

  dialerPopup: boolean;
  dialerInboxSelected: CloudNumber | null;

  agencyPlans: SubscriptionPlan[];
  agencyAddOns: SubscriptionPlan[];

  gettingStartedEnabled: boolean;
  gettingStartedStepCount: number;

  importingContacts: boolean;
  importingContactsLength: number;

  integrationsList: any[];

  outgoingCallCustomerDetails: Customer | null;

  permissions: PermissionObject;
  setPermissions: (permissionsArray: PermissionDetails[]) => void;

  // Actions
  setApiUrl: (url: string) => void;
  setInAuthModule: (status: boolean) => void;
  setIsWabaEnabled: (status: boolean) => void;
  setNewVersionEnabled: (status: boolean) => void;
  setIsPartnerBusiness: (status: boolean) => void;
  setPartnerConversationRate: (rate: string | null) => void;
  setBusinessCountry: (country: string | null) => void;
  setApiCountryList: (list: any[]) => void;
  setApiStateList: (list: any[]) => void;
  setApiCityList: (list: any[]) => void;
  setLanguageList: (list: any[]) => void;
  setCustomFields: (fields: CustomProperty[]) => void;
  setAgentCharacteristics: (characteristics: Characteristics[]) => void;
  setResponsiveMenuBarOpen: (state: boolean) => void;
  setHoverActive: (state: boolean) => void;
  setAudioPlayer: (state: boolean) => void;
  setAudioPlayerMessage: (message: Message | null) => void;
  setSubscriptionDaysLeft: (days: number | null) => void;
  setCallTunes: (tunes: any[]) => void;
  setWidgetList: (widgets: Widget[]) => void;
  setGstNo: (gstNo: string) => void;
  setTemplateStudioEnabled: (status: boolean) => void;
  setTemplateStudioOpened: (status: boolean) => void;
  setVoiceList: (list: any[]) => void;
  setPaymentGatewayKeys: (keys: {
    is_default: boolean;
    stripe: string;
    default: string;
  }) => void;
  setDialerPopup: (status: boolean) => void;
  setDialerInboxSelected: (cloudNumber: CloudNumber | null) => void;
  setAgencyPlans: (plans: SubscriptionPlan[]) => void;
  setAgencyAddOns: (addOns: SubscriptionPlan[]) => void;
  setGettingStartedEnabled: (status: boolean) => void;
  setGettingStartedStepCount: (count: number) => void;
  setImportingContacts: (status: boolean) => void;
  setImportingContactsLength: (length: number) => void;
  setIntegrationsList: (list: any[]) => void;
  setOutgoingCallCustomerDetails: (customer: Customer | null) => void;

  // Actions
  setIsLoggedIn: (status: boolean) => void;
  setBusiness: (business: Business) => void;
  setLoggedUser: (user: LoggedUser) => void;
  setWhatsappDetails: (details: WhatsappDetails) => void;
  setResponsiveMenuBarState: (state: boolean) => void;
  setRemoteConfig: (config: { frill: boolean; downtime: boolean }) => void;
  setCloudNumbers: (cloudNumbers: CloudNumber[]) => void;

  // New method
  getNotificationBarSize: () => number;
};

const transformPermissions = (input: PermissionDetails[]): Record<string, any> => {
  const output: Record<string, any> = {};

  input.forEach((item) => {
      const { module, name } = item.permission;
      const inboxId = item.inbox; // This is the number ID for phone_numbers

      // Initialize module if not exists
      if (!output[module]) {
          output[module] = {
              view: false,
              edit: false,
              delete: false
          };
      }

      // If the permission name is "phone_numbers", handle number_id dynamically
      if (name === "phone_numbers" && inboxId !== null) {
          if (!output[module][name]) {
              output[module][name] = {
                  view: false,
                  edit: false,
                  delete: false
              };
          }
          if (!output[module][name][`number_${inboxId}`]) {
              output[module][name][`number_${inboxId}`] = {
                  view: false,
                  edit: false,
                  delete: false
              };
          }

          // Assign values to specific number_id
          output[module][name][`number_${inboxId}`].view = item.view;
          output[module][name][`number_${inboxId}`].edit = item.edit;
          output[module][name][`number_${inboxId}`].delete = item.delete;

          // If any child (number_id) has true, set parent phone_numbers to true
          output[module][name].view ||= item.view;
          output[module][name].edit ||= item.edit;
          output[module][name].delete ||= item.delete;
      } 
      // Handle normal permissions
      else {
          if (!output[module][name]) {
              output[module][name] = {
                  view: false,
                  edit: false,
                  delete: false
              };
          }
          output[module][name].view = item.view;
          output[module][name].edit = item.edit;
          output[module][name].delete = item.delete;
      }

      // Set permissions at the module level (if any child is true)
      output[module].view ||= item.view;
      output[module].edit ||= item.edit;
      output[module].delete ||= item.delete;
  });

  return output;
}


export const useSharedStore = create<SharedState>()(
  persist(
    (set, get) => ({
      // Initial States
      apiUrl: process.env.REACT_APP_API_URL || "",
      isLoggedIn: false,
      inAuthModule: true,
      business: JSON.parse(localStorage.getItem("business") || "null"),
      whatsappDetails: null,
      isWabaEnabled: true,
      newVersionEnabled: false,
      isPartnerBusiness: false,
      partnerConversationRate: null,
      businessCountry: localStorage.getItem("country"),

      apiCountryList: [],
      apiStateList: [],
      apiCityList: [],

      languageList: [],
      customFields: [],
      agentCharacteristics: [],

      responsiveMenuBarOpen: false,
      hoverActive: true,

      audioPlayer: false,
      audioPlayerMessage: null,

      subscriptionDaysLeft: null,

      cloudNumbers: [],
      callTunes: [],
      widgetList: [],

      gst_no: "",

      remoteConfig: {
        frill: false,
        downtime: false,
      },

      templateStudioEnabled: false,
      templateStudioOpened: false,

      voiceList: [],

      loggedUser: null,

      paymentGatewayKeys: {
        is_default: true,
        stripe: "",
        default: "",
      },

      dialerPopup: false,
      dialerInboxSelected: null,

      agencyPlans: [],
      agencyAddOns: [],

      gettingStartedEnabled: false,
      gettingStartedStepCount: 0,

      importingContacts: false,
      importingContactsLength: 0,

      integrationsList: [],
      outgoingCallCustomerDetails: null,

      permissions: {}, // Default empty object
      setPermissions: (permissionsArray) => {
        let permissionsData = transformPermissions(permissionsArray);
        console.log(permissionsData);
        set({ permissions: permissionsData });
      },

      // Actions
      setApiUrl: (url) => set({ apiUrl: url }),
      setInAuthModule: (status) => set({ inAuthModule: status }),
      setIsWabaEnabled: (status) => set({ isWabaEnabled: status }),
      setNewVersionEnabled: (status) => set({ newVersionEnabled: status }),
      setIsPartnerBusiness: (status) => set({ isPartnerBusiness: status }),
      setPartnerConversationRate: (rate) =>
        set({ partnerConversationRate: rate }),
      setBusinessCountry: (country) => set({ businessCountry: country }),
      setApiCountryList: (list) => set({ apiCountryList: list }),
      setApiStateList: (list) => set({ apiStateList: list }),
      setApiCityList: (list) => set({ apiCityList: list }),
      setLanguageList: (list) => set({ languageList: list }),
      setCustomFields: (fields) => set({ customFields: fields }),
      setAgentCharacteristics: (characteristics) =>
        set({ agentCharacteristics: characteristics }),
      setResponsiveMenuBarOpen: (state) =>
        set({ responsiveMenuBarOpen: state }),
      setHoverActive: (state) => set({ hoverActive: state }),
      setAudioPlayer: (state) => set({ audioPlayer: state }),
      setAudioPlayerMessage: (message) => set({ audioPlayerMessage: message }),
      setSubscriptionDaysLeft: (days) => set({ subscriptionDaysLeft: days }),
      setCallTunes: (tunes) => set({ callTunes: tunes }),
      setWidgetList: (widgets) => set({ widgetList: widgets }),
      setGstNo: (gstNo) => set({ gst_no: gstNo }),
      setTemplateStudioEnabled: (status) =>
        set({ templateStudioEnabled: status }),
      setTemplateStudioOpened: (status) =>
        set({ templateStudioOpened: status }),
      setVoiceList: (list) => set({ voiceList: list }),
      setPaymentGatewayKeys: (keys) => set({ paymentGatewayKeys: keys }),
      setDialerPopup: (status) => set({ dialerPopup: status }),
      setDialerInboxSelected: (cloudNumber) =>
        set({ dialerInboxSelected: cloudNumber }),
      setAgencyPlans: (plans) => set({ agencyPlans: plans }),
      setAgencyAddOns: (addOns) => set({ agencyAddOns: addOns }),
      setGettingStartedEnabled: (status) =>
        set({ gettingStartedEnabled: status }),
      setGettingStartedStepCount: (count) =>
        set({ gettingStartedStepCount: count }),
      setImportingContacts: (status) => set({ importingContacts: status }),
      setImportingContactsLength: (length) =>
        set({ importingContactsLength: length }),
      setIntegrationsList: (list) => set({ integrationsList: list }),
      setOutgoingCallCustomerDetails: (customer) =>
        set({ outgoingCallCustomerDetails: customer }),

      setIsLoggedIn: (status) => set({ isLoggedIn: status }),
      setBusiness: (business: Business) =>
        set(() => {
          let today = new Date();
          let endDate = new Date(business?.subscription?.end_date || today);
          const subscriptionDaysLeft = Math.round(
            Math.abs(endDate.getTime() - today.getTime()) /
              (24 * 60 * 60 * 1000)
          );
          if (!business.is_live) set({ gettingStartedStepCount: 4 });
          if (!business.is_agent_deployed) set({ gettingStartedStepCount: 3 });
          if (!business.is_agent_test) set({ gettingStartedStepCount: 2 });
          if (!business.is_agent_created) set({ gettingStartedStepCount: 1 });

          return { business: business };
        }),
      setWhatsappDetails: (details) =>
        set({
          whatsappDetails: details,
          isWabaEnabled: !!details?.waba_id,
        }),
      setResponsiveMenuBarState: (state) =>
        set({ responsiveMenuBarOpen: state }),
      setRemoteConfig: (config) => set({ remoteConfig: config }),
      setCloudNumbers: (cloudNumbers) => {
        localStorage.setItem("cloud-numbers", JSON.stringify(cloudNumbers));
        set({ cloudNumbers });
      },
      setLoggedUser: (user) => {
        let permissionsData = transformPermissions(user.permissions);
        console.log(permissionsData);
        set({
          loggedUser: user,
          permissions: permissionsData,
        });
      },

      // New method
      getNotificationBarSize: () => {
        const {
          newVersionEnabled,
          remoteConfig,
          business,
          subscriptionDaysLeft,
        } = get();
        let height = 0;
        if (newVersionEnabled) height += 44;
        // if (remoteConfig.downtime) height += 35;
        // if (business?.subscription?.is_expired) height += 44;
        // if (!business?.subscription?.is_expired && subscriptionDaysLeft !== null && subscriptionDaysLeft < 4) height += 44;
        return height;
      },
    }),
    {
      name: "app-storage", // State persistence key
    }
  )
);

export default useSharedStore;
