import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Check, Loader2 } from 'lucide-react';
import { Button } from '../../shadcn/button';
import ConfirmationPopup from '../../shared/ConfirmationPopup';
import Toaster from '../../shared/customToast';
import { useAgencyStore } from '../../../store/agency.store';
import { getIntegrations, deleteIntegration } from '../../../lib/integrations.utils';
import JsonText from '../../../static-text.json';
import useSharedStore from '../../../store/shared.store';

const GoogleCalendarView = () => {
  const navigate = useNavigate();
  const agencyStore = useAgencyStore();
  const staticTexts = JsonText.integrations;

  const [loader, setLoader] = useState(false);
  const [google, setGoogle] = useState<any>(null);
  const [uninstallPopup, setUninstallPopup] = useState(false);
  const [integration, setIntegration] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState('accounts');

  const sharedStore = useSharedStore()

  useEffect(() => {
    getIntegrationsList();
  }, []);

  const getIntegrationsList = () => {
    setLoader(true);
    getIntegrations().then(resp => {
      const integrations = resp.data.data;
      integrations.forEach((element: any) => {
        if (element.provider === 'google') {
          setIntegration(element);
          setGoogle(element.google);
        }
      });
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      Toaster.error(error.message);
    });
  };

  const backToList = () => {
    navigate('/settings/integrations');
  };

  const uninstallGoogleCalendar = () => {
    setUninstallPopup(false);
    setGoogle(null);
    deleteIntegration(integration?.id).then(resp => {
      Toaster.success(resp.data.message);
    }).catch(error => {
      Toaster.error(error.message);
    });
  };

  const openPopupCalendar = () => {
    const width = 500;
    const height = 400;
    const screenLeft = window.screenLeft ?? window.screenX;
    const screenTop = window.screenTop ?? window.screenY;
    const screenWidth = window.innerWidth ?? document.documentElement.clientWidth ?? window.screen.width;
    const screenHeight = window.innerHeight ?? document.documentElement.clientHeight ?? window.screen.height;
    const left = screenLeft + (screenWidth - width) / 2;
    const top = screenTop + (screenHeight - height) / 2;
    window.open(
      `${agencyStore.defaultGoogleCal ? 'https://app.oneinbox.ai' : window.location.origin}/google-login?google_client_id=${agencyStore.googleClientId}`,
      "popupWindow",
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  return (
    <div className="main-container">
      <div className="flex justify-between items-center h-16 p-4 border-b border-gray-300 text-primary">
        <div className="flex items-center gap-2 text-primary font-inter text-lg font-medium leading-7">
          <span style={{ cursor: 'pointer' }} onClick={backToList}>
            <ArrowLeft className="text-gray-600" size="24px" />
          </span>
          Integrations
        </div>
      </div>
      {loader ? (
        <div className="loader-container flex justify-center p-8">
          <Loader2 size={'16px'} />
        </div>
      ) : (
        <div className="agent-details flex flex-col gap-4 p-6 overflow-y-scroll">
          {!google ? (
            <>
              <div className="ghl-heading flex items-center gap-8">
                <div className="flex items-center gap-4">
                  <span className="card-image-status flex justify-between items-center h-12 w-12">
                    <img src="../../../../assets/images/google_calendar.svg" alt="" className="w-12 h-12 object-cover" />
                  </span>
                  <div className="ver-8px flex flex-col gap-2">
                    <span className="heading text-2xl font-normal text-primary">Google Calendar</span>
                    <span className="tag bg-gray-100 rounded px-2 py-1 text-sm font-medium text-primary w-fit">Calendar</span>
                  </div>
                </div>
                {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && <Button onClick={openPopupCalendar}>Install app</Button>}
              </div>
              <div className="card-fields flex flex-col gap-1">
                <span className="field-name text-lg font-medium text-primary">Website</span>
                <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://calendar.google.com</a>
              </div>
              <div className="card-fields flex flex-col gap-1">
                <span className="field-name text-lg font-medium text-primary">Overview</span>
                <span className="subheading text-sm font-normal text-secondary">
                  The {agencyStore?.brand} integration with Google Calendar simplifies scheduling for individuals and businesses by connecting advanced AI calling capabilities to Google’s reliable calendar platform. With this integration, users can automate appointment bookings, check real-time slot availability, and manage their schedules effortlessly.
                </span>
              </div>
              <div className="card-fields flex flex-col gap-1">
                <span className="field-name text-lg font-medium text-primary">Key Benefits</span>
                <span className="subheading ver-2px flex flex-col gap-0.5 text-sm font-normal text-secondary">
                  <span className="col-pri text-primary">AI-Powered Scheduling</span>
                  Enable AI agents to book and reschedule appointments by checking your Google Calendar availability, reducing manual effort.
                  <span className="col-pri text-primary">Improved Accessibility</span>
                  Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time.
                  <span className="col-pri text-primary">Seamless Synchronization</span>
                  Automatically sync appointments with Google Calendar to keep your schedule up-to-date and eliminate double bookings.
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="connected-ghl-heading flex justify-between items-center mb-4">
                <div className="flex items-center gap-4">
                  <span className="card-image-status flex justify-between items-center h-12 w-12">
                    <img src="../../../../assets/images/google_calendar.svg" alt="" className="w-12 h-12 object-cover" />
                  </span>
                  <div className="ver-8px flex flex-col gap-2">
                    <span className="heading-name text-2xl font-normal text-primary">Google Calendar</span>
                    <span className="tag bg-gray-100 rounded px-2 py-1 text-sm font-medium text-primary w-fit">Calendar</span>
                  </div>
                </div>
                {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.delete) && <Button variant="destructive" onClick={() => setUninstallPopup(true)}>Uninstall</Button>}
              </div>
              <div className="agent-details-tab flex items-center justify-between border-b border-gray-300">
                <div className="agent-details-tab-group flex items-center">
                  <div className={` ${currentTab === 'accounts' ? 'border-b-[3px] border-b-[var(--primary-color)]' : 'border-b-[3px] border-b-transparent'} flex items-center flex-col cursor-pointer`} onClick={() => setCurrentTab('accounts')}>
                    <span className="px-3 pb-2.5">Accounts</span>
                  </div>
                  <div className={` ${currentTab === 'info' ? 'border-b-[3px] border-b-[var(--primary-color)]' : 'border-b-[3px] border-b-transparent'} flex items-center flex-col cursor-pointer`} onClick={() => setCurrentTab('info')}>
                    <span className="px-3 pb-2.5">Info</span>
                  </div>
                </div>
              </div>
              {currentTab === 'accounts' && (
                <div className="ghl-account-details border border-gray-300 bg-gray-50 rounded p-4 flex flex-col gap-4">
                  <div className="hor-gap flex items-center justify-between">
                    <span className="field-name text-lg font-medium text-primary">{google?.name}</span>
                    <div className="hor-8px flex items-center gap-2">
                      <span className="green-label flex items-center gap-1 bg-green-100 px-2 py-1 text-sm font-medium text-green-700 rounded">
                        <Check className="text-green-700" size="16px" /> Installed
                      </span>
                    </div>
                  </div>
                  <table>
                    <tr>
                      <td className="field-title">Email Id</td>
                      <td className="field-value">{google?.email}</td>
                    </tr>
                  </table>
                </div>
              )}
              {currentTab === 'info' && (
                <div className="ghl-info ver-1rem flex flex-col gap-4">
                  <div className="card-fields flex flex-col gap-1">
                    <span className="field-name text-lg font-medium text-primary">Website</span>
                    <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">https://calendar.google.com</a>
                  </div>
                  <div className="card-fields flex flex-col gap-1">
                    <span className="field-name text-lg font-medium text-primary">Overview</span>
                    <span className="subheading text-sm font-normal text-secondary">
                      The {agencyStore?.brand} integration with Google Calendar simplifies scheduling for individuals and businesses by connecting advanced AI calling capabilities to Google’s reliable calendar platform. With this integration, users can automate appointment bookings, check real-time slot availability, and manage their schedules effortlessly.
                    </span>
                  </div>
                  <div className="card-fields flex flex-col gap-1">
                    <span className="field-name text-lg font-medium text-primary">Key Benefits</span>
                    <span className="subheading ver-2px flex flex-col gap-0.5 text-sm font-normal text-secondary">
                      <span className="col-pri text-primary">AI-Powered Scheduling</span>
                      Enable AI agents to book and reschedule appointments by checking your Google Calendar availability, reducing manual effort.
                      <span className="col-pri text-primary">Improved Accessibility</span>
                      Offer round-the-clock booking and scheduling support, ensuring customers can book appointments at any time.
                      <span className="col-pri text-primary">Seamless Synchronization</span>
                      Automatically sync appointments with Google Calendar to keep your schedule up-to-date and eliminate double bookings.
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {uninstallPopup && (
        <ConfirmationPopup
          heading="Confirm uninstall Google calendar"
          description="Uninstalling the Google calendar app will also delete all calendar actions associated with your account."
          negativeButtonText="No, keep Google calendar"
          positiveButtonText="Yes, uninstall Google calendar"
          onClose={() => setUninstallPopup(false)}
          onSuccess={uninstallGoogleCalendar}
        />
      )}
    </div>
  );
};

export default GoogleCalendarView;
