import React, { useState } from 'react';
import { Dialog, DialogContent } from '../shadcn/dialog';
import { Button } from '../shadcn/button';

interface ConfirmationPopupProps {
  heading: string;
  description: string;
  positiveButtonText: string;
  negativeButtonText: string;
  checkbox?: boolean;
  checkboxText?: string;
  negativeButton?: boolean;
  onCheckboxClicked?: (checked: boolean) => void;
  onClose?: () => void;
  onSuccess?: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  heading,
  description,
  positiveButtonText,
  negativeButtonText,
  checkbox = false,
  checkboxText = '',
  negativeButton = false,
  onCheckboxClicked,
  onClose,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [checkedState, setCheckedState] = useState(false);

  const handleCheckboxChange = () => {
    setCheckedState(!checkedState);
    if (onCheckboxClicked) {
      onCheckboxClicked(!checkedState);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => { setIsOpen(false); if (onClose) onClose(); }}>
      <DialogContent>
        <div className="flex flex-col gap-1 w-96">
          <div className="flex justify-between items-center font-inter text-lg font-semibold leading-7 text-left text-[var(--primary-color)]">
            <span>{heading}</span>
          </div>
          <div className="font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)]" dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
        {checkbox && (
          <div className="flex items-center gap-1">
            <input
              type="checkbox"
              checked={checkedState}
              onChange={handleCheckboxChange}
            />
            <label>{checkboxText}</label>
          </div>
        )}
        <div className="flex justify-end p-2 gap-4">
          <Button variant="secondary" onClick={handleClose}>{negativeButtonText}</Button>
          {!negativeButton && (
            <Button variant="default" onClick={handleSuccess}>{positiveButtonText}</Button>
          )}
          {negativeButton && (
            <Button variant="destructive" onClick={handleSuccess}>{positiveButtonText}</Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationPopup;