import { useEffect, useState } from "react";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../shadcn/dropdown-menu";
import { Skeleton } from "../shadcn/skeleton";
import { Customer } from "../../models/customer";
import useSharedStore from "../../store/shared.store";
import { DialogContent, Dialog } from "../shadcn/dialog";
import { CloudNumber } from "../../models/cloud-number";
import { Loader2, ChevronDown } from "lucide-react";
import { searchUsers } from "../../lib/users.utils";
import {
  isValidNumber2,
  getCountryCodeFromNumber,
} from "../../lib/common.utils";
import Toaster from "./customToast";
import { useRetellStore } from "../../store/retell.store";
import ProfileIcon from "./ProfileIcon";
import { formatToE164 } from "../../lib/ui.utils";

interface DialerPopupProps {
  close: () => void;
}

const DialerPopup: React.FC<DialerPopupProps> = ({ close }) => {
  const sharedStore = useSharedStore();
  const [currentInbox, setCurrentInbox] = useState(
    sharedStore.cloudNumbers[0] || null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [callingIndex, setCallingIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [customerList, setCustomerList] = useState<Customer[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [callLoader, setCallLoader] = useState(-1);

  const retellStore = useRetellStore();

  useEffect(() => {
    if(sharedStore.outgoingCallCustomerDetails?.id) {
      setSearchTerm(sharedStore.outgoingCallCustomerDetails?.mobile);
    }
    if (sharedStore.dialerInboxSelected?.id)
      setCurrentInbox(sharedStore.dialerInboxSelected);
  }, []);

  useEffect(() => {
    if(searchTerm?.length > 2) searchContacts();
    else setCustomerList([]);
  }, [searchTerm]);

  const searchContacts = async () => {
    setIsLoading(true);
    try {
      const resp = await searchUsers(1, searchTerm);
      if (resp.data?.code === 200) {
        let responseList = resp.data.data;
        responseList?.map((data: any) => new Customer(data));
        setCustomerList(responseList);
      } else {
        console.log(resp.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const callNumber = async (customer: Customer | null, index: number) => {
    let input;
    if (customer) {
      setCallingIndex(customer?.id);
      input = {
        to_number: (customer.mobile[0] === "+" ? "" : "+") + customer.mobile,
        inbox_id: currentInbox?.id ?? sharedStore.cloudNumbers[0].id,
        country_code: customer.country_code ?? "1",
      };
    } else {
      if (isValidNumber2("+" + searchTerm)) {
        setCallingIndex(0);
        let code = getCountryCodeFromNumber(searchTerm);
        input = {
          to_number: (searchTerm[0] === "+" ? "" : "+") + searchTerm,
          inbox_id: currentInbox?.id,
          country_code: code,
        };
      } else {
        Toaster.error("Invalid number");
        return;
      }
    }
    retellStore
      .outgoingCall(input)
      .then((resp) => {
        close();
        retellStore.outgoingCallTrigger.next({type: "call_started", inbox_id: currentInbox?.id});
        setCallingIndex(-1);
      })
      .catch((error) => {
        Toaster.error(error);
        setCallingIndex(-1);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        close();
      }}
    >
      <DialogContent className="p-4 rounded-lg bg-[var(--primary-color)] border-none">
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-[#FFFFFF]">Start a Call</h2>
          <p className="text-sm text-gray-500">Make the call from</p>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="w-full flex justify-between bg-[#1B1E21] p-2 rounded items-center">
              <span className="text-[#FFFFFF] flex gap-2 items-center truncate">
                {currentInbox?.icon}
                {currentInbox?.name} -{" "}
                <span className="text-secondary">{formatToE164(currentInbox?.number)}</span>
                {currentInbox?.agent?.id && <hr className="bg-[#FFFFFF] m-0 h-[18px] w-[1px]" />}
                <span className="text-secondary flex gap-1 items-center">
                  {currentInbox?.agent?.profile_pic && (
                    <img
                      className="w-6 h-6 rounded-full"
                      src={currentInbox?.agent?.profile_pic}
                      alt=""
                    />
                  )}{" "}
                  {currentInbox?.agent?.agent_name}
                </span>
              </span>
              <ChevronDown color="#FFFFFF" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[478px] bg-[#1B1E21] max-h-[300px] overflow-y-auto">
            {sharedStore.cloudNumbers.map(
              (number: CloudNumber, index: number) => (
                <DropdownMenuItem
                  className="hover:bg-[#1F2A37]"
                  key={index}
                  onClick={() => setCurrentInbox(number)}
                >
                  <span className="text-[#FFFFFF] flex gap-2 items-center truncate">
                    {number?.icon}
                    {number?.name} -{" "}
                    <span className="text-secondary">{formatToE164(number?.number)}</span>
                    {number?.agent?.id && <hr className="bg-[#FFFFFF] m-0 h-[18px] w-[1px]" />}
                    <span className="text-secondary flex gap-1 items-center">
                      {number?.agent?.profile_pic && (
                        <img
                          className="w-6 h-6 rounded-full"
                          src={number?.agent?.profile_pic}
                          alt=""
                        />
                      )}{" "}
                      {number?.agent?.agent_name}
                    </span>
                  </span>
                </DropdownMenuItem>
              )
            )}
          </DropdownMenuContent>
        </DropdownMenu>

        <Input
          className="bg-[var(--primary-color)] text-[#FFF] h-[38px] !border-none !outline-none !focus:border-none !focus-visible:border-none !focus:outline-none"
          placeholder="Enter name or phone number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {searchTerm.length > 2 && (
          <div className="">
            <p className="text-sm text-gray-500">Contacts</p>
            <div className="flex flex-col max-h-[300px] gap-2 overflow-y-auto">
              <div className="flex justify-between items-center p-2 hover:bg-[#1F2A37] rounded-md text-[#FFF]">
                <span className="flex gap-2 items-center">
                  <ProfileIcon name={searchTerm}></ProfileIcon>
                  <span className="flex flex-col gap-1">
                    <span className="text-[14px] text-[#FFF]">
                      {searchTerm}
                    </span>
                  </span>
                </span>
                <Button
                  className="ml-2"
                  disabled={callingIndex === 0}
                  onClick={() => callNumber(null, -1)}
                >
                  {callingIndex === 0 ? (
                    <Loader2 className="animate-spin" />
                  ) : (
                    "Start Call"
                  )}
                </Button>
              </div>
              {customerList.map((customer, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center p-2 hover:bg-[#1F2A37] rounded-md text-[#FFF]"
                >
                  <span className="flex gap-2 items-center">
                    <ProfileIcon
                      name={customer?.name ?? customer?.mobile}
                    ></ProfileIcon>
                    <span className="flex flex-col gap-1">
                      <span className="text-[14px] text-[#FFF]">
                        {customer.name ?? formatToE164(customer.mobile)}
                      </span>
                      <span className="text-[12px] text-secondary">
                        {formatToE164(customer?.mobile)}
                      </span>
                    </span>
                  </span>
                  <Button
                    className="ml-2"
                    disabled={callingIndex === i}
                    onClick={() => callNumber(customer, i)}
                  >
                    {callingIndex === customer?.id ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      "Start Call"
                    )}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialerPopup;
