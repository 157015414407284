import { InfoBase } from "../../models/infobase";
import React, { useState, useEffect } from "react";
import Toaster from "../shared/customToast";
import { LoggedUser } from "../../models/logged-user";
import { getPresignUrl, uploadToS3 } from "../../lib/common.utils";
import { MediaContentTypes } from "../../lib/constants";
import { addInfoBaseFile } from "../../lib/infobase.utils";
import { Input } from "../shadcn/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import { ScrollArea } from "../shadcn/scrollArea";
import { Button } from "../shadcn/button";
import {
  Check,
  FileText,
  Link2,
  Plus,
  Trash2,
  Type,
  XCircle,
} from "lucide-react";
import { Switch } from "../shadcn/switch";
import { Dialog, DialogContent } from "../shadcn/dialog";
import AddInfobaseFile from "./AddInfobaseFile";
import { truncateFileName } from "../../lib/ui.utils";
import { Progress } from "../shadcn/progress";

interface AddInfobaseProps {
  onClose: () => void;
  onAdd: (infobase: InfoBase) => void;
}

const AddInfobase: React.FC<AddInfobaseProps> = ({ onClose, onAdd }) => {
  const [name, setName] = useState("");
  const [assetsList, setAssetsList] = useState<
    Array<{
      file: File;
      fileUrl: string;
      type: string;
      name: string;
      url: string;
      title: string;
      content: string;
      progress: number;
      initialUpload: boolean;
    }>
  >([]);
  const [showAddInfobaseFilePopup, setShowAddInfobaseFilePopup] =
    useState(false);
  const [addInfoFileType, setAddInfoFileType] = useState("");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [websiteCount, setWebsiteCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const user: LoggedUser = new LoggedUser(
    JSON.parse(localStorage.getItem("user")!)
  );
  useEffect(() => {
    checkWebsiteCount();
  }, [assetsList]);

  const removeAttachment = (index: number) => {
    const newList = [...assetsList];
    newList.splice(index, 1);
    setAssetsList(newList);
    checkWebsiteCount();
  };

  const checkWebsiteCount = () => {
    const count = assetsList.filter((asset) => asset.type === "website").length;
    setWebsiteCount(count);
  };

  const addAssetFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".bmp, .csv, .doc, .docx, .eml, .epub, .heic, .html, .jpeg, .png, .md, .msg, .odt, .org, .p7s, .pdf, .jpg, .ppt, .pptx, .rst, .rtf, .tiff, .txt, .tsv, .xls, .xlsx, .xml";
    input.onchange = () => {
      const files = input.files!;
      const allowedFileTypes = [
        "bmp",
        "csv",
        "doc",
        "docx",
        "eml",
        "epub",
        "heic",
        "html",
        "jpeg",
        "png",
        "md",
        "msg",
        "odt",
        "org",
        "p7s",
        "pdf",
        "jpg",
        "ppt",
        "pptx",
        "rst",
        "rtf",
        "tiff",
        "txt",
        "tsv",
        "xls",
        "xlsx",
        "xml",
      ];

      const fileExtension =
        files[0]?.name.split(".").pop()?.toLowerCase() ?? "";

      if (allowedFileTypes.includes(fileExtension)) {
        if (files[0].size / 1024 > 51200) {
          Toaster.error("Please upload files up to 50MB");
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
          setAssetsList([
            ...assetsList,
            {
              file: files[0],
              fileUrl:
                typeof e.target!.result === "string" ? e.target!.result : "",
              type: fileExtension,
              name: files[0].name,
              url: typeof e.target!.result === "string" ? e.target!.result : "",
              title: "",
              content: "",
              progress: 0,
              initialUpload: false,
            },
          ]);
        };
      } else {
        Toaster.error(
          "Unsupported file type. Allowed types are .bmp, .csv, .doc, .docx, .eml, .epub, .heic, .html, .jpeg, .png, .md, .msg, .odt, .org, .p7s, .pdf, .jpg, .ppt, .pptx, .rst, .rtf, .tiff, .txt, .tsv, .xls, .xlsx, .xml."
        );
      }
    };
    input.click();
  };

  const addInfoBaseAsset = (type: string) => {
    setAddInfoFileType(type);
    setShowAddInfobaseFilePopup(true);
  };

  const onInfobaseFileAdded = (asset: any) => {
    setAssetsList([
      ...assetsList,
      { ...asset, progress: 100, initialUpload: true },
    ]);
    setShowAddInfobaseFilePopup(false);
    checkWebsiteCount();
  };

  const addInfoBase = () => {
    setIsLoading(true);
    const uploadTasks = assetsList.map((item, index) => {
      if (item.type !== "website" && item.type !== "text") {
        // File needs to be uploaded to S3
        const epoc =
          new Date().getTime() +
          "-" +
          user.id +
          "." +
          item.file?.name.split(".").pop();
        const presignObj = {
          key: "infobase/" + epoc,
          content_type:
            MediaContentTypes[item.file?.name.split(".").pop() ?? "pdf"], // Adjust for all types as needed
          public_file: true,
        };
        const updatedAssetsList = [...assetsList];
        updatedAssetsList[index].progress = 0;
        updatedAssetsList[index].initialUpload = true;
        setAssetsList(updatedAssetsList);
        return getPresignUrl(presignObj)
          .then((resp: { data: any }) => {
            const presignUrl = resp?.data?.data?.url;
            const presignFields = resp?.data?.data?.fields;
            return uploadToS3(
              presignUrl,
              presignFields,
              item.file,
              (progress) => {
                const updatedAssetsList = [...assetsList];
                updatedAssetsList[index].progress = progress; // Update progress dynamically
                setAssetsList(updatedAssetsList);
              }
            )
              .then((event) => {
                console.log(event);
                const updatedAssetsList = [...assetsList];
                updatedAssetsList[index].progress = 100;
                updatedAssetsList[index].initialUpload = true;
                setAssetsList(updatedAssetsList);
                return {
                  name: item.file.name,
                  url: presignFields["key"],
                  type: item.file.name.split(".").pop(),
                  title: "",
                  content: "",
                };
              })
              .catch((error: string) => {
                Toaster.error(error);
                const updatedAssetsList = [...assetsList];
                updatedAssetsList[index].progress = -1; // Mark as failed
                updatedAssetsList[index].initialUpload = true;
                setAssetsList(updatedAssetsList);
                setIsLoading(false);
                return null; // Continue processing other items
              });
          })
          .catch((error: string) => {
            Toaster.error(error);
            setIsLoading(false);
            const updatedAssetsList = [...assetsList];
            updatedAssetsList[index].progress = 0;
            updatedAssetsList[index].initialUpload = true;
            setAssetsList(updatedAssetsList);
            return null; // Continue processing other items
          });
      } else {
        // If the item does not require S3 upload, return a promise that resolves the item directly
        return Promise.resolve({
          ...item,
        });
      }
    });

    Promise.all(uploadTasks)
      .then((results) => {
        const uploadedFiles = results.filter((item) => item !== null);
        let assetJson: {
          url?: any;
          type: any;
          title?: any;
          content?: any;
          name?: any;
        }[] = [];
        uploadedFiles.forEach((element: any) => {
          if (element.type === "website") {
            assetJson.push({
              url: element.url,
              type: element.type,
            });
          } else if (element.type === "text") {
            assetJson.push({
              title: element.title,
              type: "text",
              content: element.content,
            });
          } else {
            assetJson.push({
              url: element.url,
              name: element.name,
              type: "file",
            });
          }
        });
        const input = {
          name: name,
          asset_json: assetJson,
          enable_auto_refresh: autoRefresh,
        };
        addInfoBaseFile(input)
          .then((resp: any) => {
            Toaster.success(resp.data.message);
            setIsLoading(false);
            onAdd(new InfoBase(resp.data.data));
          })
          .catch((error: string) => {
            setIsLoading(false);
            Toaster.error(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex w-full">
      <div className="rounded-lg w-full space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">Add Infobase</h2>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col gap-1">
            <label className="block text-sm font-medium">Infobase name</label>
            <Input
              placeholder="Enter infobase name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full"
            />
          </div>
          {assetsList.length > 0 && (
            <div className="flex flex-col gap-1">
              <span className="text-sm font-medium">Documents</span>
              <div className="overflow-auto max-h-64">
                {assetsList.map((asset, i) => (
                  <div
                    className="flex justify-between items-center p-2 rounded-lg bg-gray-100 mb-1"
                    key={i}
                  >
                    <span className="flex items-center gap-2">
                      {asset.type === "text" && <Type size={"20px"} />}
                      {asset.type !== "text" && asset.type !== "website" && (
                        <FileText size={"20px"} />
                      )}
                      {asset.type === "website" && <Link2 size={"20px"} />}
                      <span className="text-sm truncate w-[254px]">
                        {truncateFileName(asset.name, 30)}
                      </span>
                    </span>
                    <div className="flex items-center gap-2">
                      {asset.initialUpload && (
                        <>
                          {asset.progress === 100 && (
                            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100 p-1">
                              <Check className="h-4 w-4 text-green-500" />
                            </div>
                          )}

                          {asset.progress === -1 && (
                            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-red-100 p-1">
                              <XCircle className="h-4 w-4 text-red-500" />
                            </div>
                          )}

                          {asset.progress !== -1 && asset.progress !== 100 && (
                            <Progress
                              className="w-[200px]"
                              color="bg-[#FDE047]"
                              value={asset.progress}
                            />
                          )}
                        </>
                      )}
                      <span
                        className="cursor-pointer"
                        onClick={() => removeAttachment(i)}
                      >
                        <Trash2 size={"20px"} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {websiteCount > 0 && (
            <div className="flex flex-col gap-1">
              <span className="flex justify-between items-center text-sm font-medium text-gray-700">
                Auto refresh
                <Switch
                  checked={autoRefresh}
                  onCheckedChange={setAutoRefresh}
                />
              </span>
              <span className="text-sm text-gray-500">
                Automatically refresh infobase URLs and sync data every 12
                hours.
              </span>
            </div>
          )}

          {assetsList?.length < 25 ? (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="secondary">
                    <Plus /> Add
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-full">
                  <DropdownMenuItem
                    onClick={addAssetFile}
                    className="rounded-lg mb-1 p-2 flex gap-2 text-left text-primary cursor-pointer hover:bg-gray-100"
                  >
                    <FileText size="20px" />
                    <div className="flex flex-col gap-1.5">
                      <span>Upload file</span>
                      <span className="text-xs text-secondary">
                        File size should be less than 50MB
                      </span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => addInfoBaseAsset("website")}
                    className="rounded-lg mb-1 p-2 flex gap-2 text-left text-primary cursor-pointer hover:bg-gray-100"
                  >
                    <Link2 size="20px" />
                    <div className="flex flex-col gap-1.5">
                      <span>Add link</span>
                      <span className="text-xs text-secondary">
                        Paste the link of the website
                      </span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => addInfoBaseAsset("text")}
                    className="rounded-lg mb-1 p-2 flex gap-2 text-left text-primary cursor-pointer hover:bg-gray-100"
                  >
                    <Type size="20px" />
                    <div className="flex flex-col gap-1.5">
                      <span>Add text</span>
                      <span className="text-xs text-secondary">
                        Add articles manually
                      </span>
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            ""
          )}
        </div>

        <Dialog
          open={showAddInfobaseFilePopup}
          onOpenChange={setShowAddInfobaseFilePopup}
        >
          <DialogContent className="w-full max-h-[80%] max-w-[550px]">
            <AddInfobaseFile
              infobaseType={addInfoFileType}
              onClose={() => setShowAddInfobaseFilePopup(false)}
              onAdd={onInfobaseFileAdded}
            />
          </DialogContent>
        </Dialog>

        <div className="flex justify-end space-x-2">
          <Button
            variant="default"
            disabled={!name || isLoading}
            onClick={addInfoBase}
          >
            {isLoading ? "Uploading..." : "Create infobase"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddInfobase;
