import { logout } from "../../lib/common.utils";
import { useAgencyStore } from "../../store/agency.store";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shadcn/button";

const AgencyExpired = () => {

  const agencyStore = useAgencyStore();
  const navigate = useNavigate();
  const mailTo = () => {
    if (agencyStore.supportEmail) {
      window.location.href = `mailto:${encodeURIComponent(agencyStore.supportEmail)}`;
    }
  };

  return (
    <div className="fixed z-[99] bg-[var(--bg-color)] w-full h-full left-0 overflow-auto flex flex-col items-center justify-center">
      <div className="absolute top-0 w-full flex justify-between p-14">
        <span></span>
        <Button variant={"ghost"} onClick={() => logout(navigate)}>
          Logout
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <img src="/assets/images/agency-plan-expiry.png" alt="Agency Expiry" className="w-64" />
        <div className="flex flex-col gap-1 text-center">
          <span className="text-2xl font-semibold text-[var(--primary-color)]">Access restricted!</span>
          <span className="text-lg text-[var(--secondary-color)]">To continue using {agencyStore.brand}, contact customer support</span>
        </div>
        {agencyStore.supportEmail && (
          <Button
            onClick={mailTo}
          >
            Contact Us
          </Button>
        )}
      </div>
    </div>
  );
};

export default AgencyExpired;
