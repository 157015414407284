import { useSubscriptionStore } from "../../store/subscription.store";
import { getBusinessData } from "../../lib/common.utils";
import { useWebSocketStore } from "../../store/websocket.store";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster from "../shared/customToast";

const PartnerPaymentProcessing = () => {
  const navigate = useNavigate();
  const websocketStore = useWebSocketStore();
  const location = useLocation();
  const subscriptionStore = useSubscriptionStore();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("order_id") && localStorage.getItem("payment_id")) {
      const input = {
        order_id: params.get("order_id")!,
        payment_id: localStorage.getItem("payment_id")!,
      };
      subscriptionStore.validateSubscriptionOrder(input).then((resp) => {
        Toaster.success(resp.message);
        localStorage.removeItem("payment_id");
        getBusinessData()
        navigate("/agents");
      }).catch((error) => {
        Toaster.error(error);
      });
    }
  }, []);

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-gray-100 flex flex-col items-center justify-center p-16 gap-6 text-gray-800 text-lg font-medium leading-[30px]">
      Verifying the payment. Please wait...
    </div>
  );
};

export default PartnerPaymentProcessing;
