import { InfoBase } from "../../models/infobase";
import React, { useState } from "react";
import { deleteInfoBaseFile } from "../../lib/infobase.utils";
import Toaster from "../shared/customToast";
import {
  ArrowLeft,
  Download,
  Eye,
  FileText,
  Link2,
  Loader2,
  Trash,
  Type,
} from "lucide-react";
import ConfirmationPopup from "../shared/ConfirmationPopup";
import ViewInfobaseText from "./ViewInfobaseText";
import useSharedStore from "../../store/shared.store";

interface ViewInfobaseProps {
  infobase: InfoBase;
  onClose: () => void;
  onDelete: () => void;
}

const ViewInfobase: React.FC<ViewInfobaseProps> = ({
  infobase,
  onClose,
  onDelete,
}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [viewInfobaseText, setViewInfobaseText] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<any>(null);

  const deleteHeading = "Confirm delete infobase";
  const deleteDescription = `Deleting the infobase <span class="col-pri">${infobase?.name}</span> will remove it from all AI agents attachments.`;
  const negativeButton = "No, keep infobase";
  const positiveButton = "Yes, delete infobase";

  const sharedStore = useSharedStore()

  const deleteInfoBase = () => {
    onDelete();
    deleteInfoBaseFile(infobase?.id)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error: string) => {
        console.log(error);
        Toaster.error(error);
      });
    setDeleteConfirmation(false);
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const download = (index: number) => {
    if (infobase.asset_json[index].type === "text") {
      setViewInfobaseText(true);
      setSelectedAsset(infobase.asset_json[index]);
    } else {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.setAttribute("href", infobase.asset_json[index].url);
      link.setAttribute("download", infobase.asset_json[index].name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <div className="flex w-full h-screen p-0 m-0">
      <div className="max-w-3xl mx-auto w-full">
        <div className="py-8 cursor-pointer" onClick={() => onClose()}>
          <span className="flex gap-2 items-center px-2 text-sm font-medium text-left text-[var(--secondary-color)] rounded hover:bg-hover-color">
            <ArrowLeft size="20px" color="#64748B" /> Infobase
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between w-full text-lg font-medium text-left">
            <span className="flex gap-2 items-center">
              {infobase?.name}
              {infobase?.status === "in_progress" && (
                <span
                  className="text-sm w-fit flex gap-2 items-center rounded px-2 whitespace-nowrap"
                  style={{
                    background: "#DBEAFE",
                    color: "#1D4ED8",
                    cursor: "pointer",
                  }}
                >
                  Processing{" "}
                  <Loader2
                    size="16px"
                    className="animate-spin"
                    color="#1D4ED8"
                  ></Loader2>
                </span>
              )}
              {infobase?.status === "error" && (
                <span
                  className="text-sm w-fit flex gap-2 items-center rounded px-2 whitespace-nowrap"
                  style={{ background: "#FEE2E2", color: "#DC2626" }}
                >
                  Failed
                </span>
              )}
            </span>
            {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.infobase?.delete) && <Trash
              className="cursor-pointer"
              size="20px"
              color="#64748B"
              onClick={openDeleteConfirmation}
            />}
          </div>
          <div className="flex flex-col gap-1">
            {infobase.asset_json.map((asset, index) => (
              <div
                className="flex items-center justify-between w-full p-2 text-sm font-normal text-left text-[var(--primary-color)] rounded h-9 gap-2"
                key={index}
              >
                <span className="flex items-center gap-2">
                  {asset.type === "text" && <Type size={"20px"} />}
                  {asset.type !== "text" && asset.type !== "website" && (
                    <FileText size={"20px"} />
                  )}
                  {asset.type === "website" && <Link2 size={"20px"} />}
                  {asset?.name ?? asset?.title ?? asset?.url}
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => download(index)}
                >
                  {asset.type === "text" && <Eye size="16px" color="#64748B" />}
                  {asset.type !== "text" && (
                    <Download size="16px" color="#64748B" />
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {viewInfobaseText && (
        <ViewInfobaseText
          name={selectedAsset?.title}
          textContent={selectedAsset?.content}
          onClose={() => setViewInfobaseText(false)}
        />
      )}

      {deleteConfirmation && (
        <ConfirmationPopup
          positiveButtonText={positiveButton}
          negativeButtonText={negativeButton}
          heading={deleteHeading}
          description={deleteDescription}
          onClose={() => setDeleteConfirmation(false)}
          onSuccess={deleteInfoBase}
        />
      )}
    </div>
  );
};

export default ViewInfobase;
