import { Subscription } from "./subscription";

export class Admin {
  private _name: string;
  private _id: number;
  private _user_status: string;

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get user_status(): string {
        return this._user_status;
    }

    public set user_status(user_status: string) {
        this._user_status = user_status;
    }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  constructor(adminJson: any){
    this._name = adminJson?.name;
    this._id = adminJson?.id;
    this._user_status = adminJson?.user_status;
  }

  public toJson() {
    return {
      name: this._name,
      id: this._id,
      user_status: this._user_status,
    };
  }

}

export class Category {
  private _category: string;
  private _category_code: string;

  public get category(): string {
    return this._category;
  }

  public set category(category: string) {
    this._category = category;
  }

  public get category_code(): string {
    return this._category_code;
  }

  public set category_code(category_code: string) {
    this._category_code = category_code;
  }

  constructor(categoryJson: any){
    this._category = categoryJson?.category;
    this._category_code = categoryJson?.category_code;
  }

  public toJson() {
    return {
      category: this._category,
      category_code: this._category_code,
    };
  }

}

export class Partner {
  private _name: string;

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  constructor(partnerJson: any){
    this._name = partnerJson?.name;
  }

  public toJson() {
    return {
      name: this._name,
    };
  }

}

export class Business {
  private _id: number;
  private _name: string;
  private _admin: Admin;
  private _address: string;
  private _business_number: string;
  private _website: string;
  private _about: string;
  private _email: string;
  private _created_at: string;
  private _update_at: string;
  private _business_uuid: string;
  private _subscription: Subscription;
  private _currency: Currency;
  private _isd_code: string;
  private _iso_country_code: string;
  private _timezone: string;
  private _partner: Partner;
  private _business_logo: string;
  private _is_kyc: boolean;
  private _is_agent_created: boolean
  private _is_agent_test: boolean
  private _is_agent_deployed: boolean
  private _is_live: boolean

    public get is_agent_created(): boolean {
        return this._is_agent_created;
    }

    public set is_agent_created(is_agent_created: boolean) {
        this._is_agent_created = is_agent_created;
    }

    public get is_agent_test(): boolean {
        return this._is_agent_test;
    }

    public set is_agent_test(is_agent_test: boolean) {
        this._is_agent_test = is_agent_test;
    }

    public get is_agent_deployed(): boolean {
        return this._is_agent_deployed;
    }

    public set is_agent_deployed(is_agent_deployed: boolean) {
        this._is_agent_deployed = is_agent_deployed;
    }

    public get is_live(): boolean {
        return this._is_live;
    }

    public set is_live(is_live: boolean) {
        this._is_live = is_live;
    }



  constructor(businessJson: any){
    this._id = businessJson?.id;
    this._name = businessJson?.name;
    this._about = businessJson?.about;
    this._address = businessJson?.address;
    this._admin = new Admin(businessJson?.admin);
    this._business_number = businessJson?.business_number;
    this._created_at = businessJson?.created_at;
    this._email = businessJson?.email;
    this._website = businessJson?.website;
    this._update_at = businessJson?.update_at;
    this._business_uuid = businessJson?.business_uuid;
    this._subscription = new Subscription(businessJson?.subscription);
    this._currency = new Currency(businessJson?.currency);
    this._isd_code = businessJson?.isd_code;
    this._iso_country_code = businessJson?.iso_country_code;
    this._timezone = businessJson?.timezone;
    this._partner = new Partner(businessJson?.partner);
    this._business_logo = businessJson?.business_logo;
    this._is_kyc = businessJson?.is_kyc;
    this._is_agent_created = businessJson?.is_agent_created
    this._is_agent_test = businessJson?.is_agent_test
    this._is_agent_deployed = businessJson?.is_agent_deployed
    this._is_live = businessJson?.is_live
  }

  public toJson() {
    return {
      id: this._id,
      name: this._name,
      about: this._about,
      address: this._address,
      admin: this._admin.toJson(),
      business_number: this._business_number,
      created_at: this._created_at,
      email: this._email,
      website: this._website,
      update_at: this._update_at,
      business_uuid: this._business_uuid,
      subscription: this._subscription.toJson(),
      currency: this._currency.toJson(),
      isd_code: this._isd_code,
      iso_country_code: this._iso_country_code,
      timezone: this._timezone,
      partner: this._partner.toJson(),
      business_logo: this._business_logo,
      is_kyc: this._is_kyc,
      is_agent_created: this._is_agent_created,
      is_agent_test: this._is_agent_test,
      is_agent_deployed: this._is_agent_deployed,
      is_live: this._is_live,
    };
  }


  public get business_logo(): string {
    return this._business_logo;
}

public set business_logo(business_logo: string) {
    this._business_logo = business_logo;
}

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get admin(): Admin {
    return this._admin;
  }

  public set admin(admin: Admin) {
    this._admin = admin;
  }

  public get address(): string {
    return this._address;
  }

  public set address(address: string) {
    this._address = address;
  }

  public get business_number(): string {
    return this._business_number;
  }

  public set business_number(business_number: string) {
    this._business_number = business_number;
  }

  public get website(): string {
    return this._website;
  }

  public set website(website: string) {
    this._website = website;
  }

  public get about(): string {
    return this._about;
  }

  public set about(about: string) {
    this._about = about;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get created_at(): string {
    return this._created_at;
  }

  public set created_at(created_at: string) {
    this._created_at = created_at;
  }

  public get update_at(): string {
    return this._update_at;
  }

  public set update_at(update_at: string) {
    this._update_at = update_at;
  }

  public get business_uuid(): string {
    return this._business_uuid;
  }

  public set business_uuid(business_uuid: string) {
    this._business_uuid = business_uuid;
  }
  public get subscription(): Subscription {
    return this._subscription;
  }

  public set subscription(subscription: Subscription) {
      this._subscription = subscription;
  }

  public get currency(): Currency {
    return this._currency;
  }
  public set currency(currency: Currency) {
      this._currency = currency;
  }
  public get isd_code(): string {
      return this._isd_code;
  }
  public set isd_code(isd_code: string) {
      this._isd_code = isd_code;
  }
  public get iso_country_code(): string {
      return this._iso_country_code;
  }
  public set iso_country_code(iso_country_code: string) {
      this._iso_country_code = iso_country_code;
  }
  public get timezone(): string {
      return this._timezone;
  }
  public set timezone(timezone: string) {
      this._timezone = timezone;
  }

  public get partner(): Partner {
    return this._partner;
  }

  public set partner(partner: Partner) {
      this._partner = new Partner(partner);
  }

  public get is_kyc(): boolean {
    return this._is_kyc;
  }

  public set is_kyc(is_kyc: boolean) {
      this._is_kyc = is_kyc;
  }

}

export class Currency {
  private _code: string;
  private _country: string;

    public get code(): string {
        return this._code;
    }

    public set code(code: string) {
        this._code = code;
    }

    public get country(): string {
        return this._country;
    }

    public set country(country: string) {
        this._country = country;
    }

    constructor(currencyJson: any) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this._code = currencyJson?.code ?? 'USD',
      this._country = currencyJson?.country;
    }

    public toJson() {
      return {
        code: this._code,
        country: this._country,
      };
    }
  
}