import { GlobalEnums } from "../../lib/constants";
import { LoggedUser } from "../../models/logged-user";
import { Subscription } from "../../models/subscription";
import { SubscriptionPlan } from "../../models/subscription_plan";
import useSharedStore from "../../store/shared.store";
import { useSubscriptionStore } from "../../store/subscription.store";
import { useWalletStore } from "../../store/wallet.store";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Toaster from "../shared/customToast";
import { Button } from "../shadcn/button";
import { Progress } from "../shadcn/progress";
import { Business } from "../../models/business";
import { Check, X } from "lucide-react";
import SelectAddon from "./SelectAddon";
import AddAddons from "./AddAddons";
import { getBillingDetails } from "../../lib/common.utils";
import { flushSync } from "react-dom";
import AutoRecharge from "./AutoRecharge";
import { toPascalCase } from "../../lib/ui.utils";

const WalletComponent = () => {
  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );
  const [spreadsheetId] = useState(
    "1vJQPkTIuYD8owZnnXFrUt7xqxBrQ4fq7etukMaXwDC8"
  );
  const [sheetNo] = useState("0");
  const [url] = useState(
    `https://spreadsheets.google.com/feeds/list/${spreadsheetId}/${sheetNo}/public/values?alt=json`
  );
  const [isLoading, setIsLoading] = useState(false);
  const [rzpOrderId, setRzpOrderId] = useState<string | undefined>(undefined);
  const [loaderTransactions, setLoaderTransactions] = useState(false);
  const [transactionList, setTransactionList] = useState<any[]>([]);
  const [paginationSize, setPaginationSize] = useState<number | undefined>(
    undefined
  );
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginatedPages, setPaginatedPages] = useState(new Set());
  const [pagination, setPagination] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const [availableMinutes, setAvailableMinutes] = useState(0);
  const [freeConsumedMinutes, setFreeConsumedMinutes] = useState(0);
  const [freeTotalMinutes, setFreeTotalMinutes] = useState(0);
  const [paidConsumedMinutes, setPaidConsumedMinutes] = useState(0);
  const [paidTotalMinutes, setPaidTotalMinutes] = useState(0);

  const [voicemailTotal, setVoicemailTotal] = useState(0);
  const [voicemailConsumed, setVoicemailConsumed] = useState(0);
  const [isSelectAddon, setIsSelectAddon] = useState(false);
  const [selectedAddonType, setSelectedAddonType] = useState("");
  const [isAddAddon, setIsAddAddon] = useState(false);
  const [nextSubscription, setNextSubscription] = useState<
    Subscription | undefined
  >(undefined);
  const [loaderGetStripeUrl, setLoaderGetStripeUrl] = useState(false);
  const [autoRechargePopup, setAutoRechargePopup] = useState(false);
  const [autoRecharge, setAutoRecharge] = useState({
    minimumBalance: 0,
    amount: 0,
    enabled: false,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const walletStore = useWalletStore();
  const subscriptionStore = useSubscriptionStore();
  const sharedStore = useSharedStore();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("order_id") && localStorage.getItem("payment_id")) {
      const input = {
        order_id: params.get("order_id")!,
        payment_id: localStorage.getItem("payment_id")!,
      };

      if (params.get("add_on")) {
        subscriptionStore
          .validateAddOns(input)
          .then((resp) => {
            if (resp.body.code === GlobalEnums.payment_success) {
              console.log(resp.body.data);
              localStorage.removeItem("payment_id");
              Toaster.success(resp.body.message);
              getAddOns();
            } else {
              Toaster.error(resp.body.message);
              console.log(resp.body.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (localStorage.getItem("recharge_type") === "voicemail") {
          walletStore
            .validateVoiceDetectionRecharge(input)
            .then((resp) => {
              if (resp.body.code === GlobalEnums.payment_success) {
                console.log(resp.body.data);
                localStorage.removeItem("payment_id");
                localStorage.removeItem("recharge_type");
                Toaster.success(resp.body.message);
                setPaginationPage(1);
                setPaginatedPages(new Set());
                getWallet();
              } else {
                Toaster.error(resp.body.message);
                console.log(resp.body.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          walletStore
            .validateWalletRecharge(input)
            .then((resp) => {
              if (resp.body.code === GlobalEnums.payment_success) {
                console.log(resp.body.data);
                localStorage.removeItem("payment_id");
                localStorage.removeItem("recharge_type");
                Toaster.success(resp.body.message);
                setPaginationPage(1);
                setPaginatedPages(new Set());
                getWallet();
              } else {
                Toaster.error(resp.body.message);
                console.log(resp.body.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    setPaginationPage(1);
    setPaginatedPages(new Set());
    getSubscription();
    getWallet();
    getAddOns();
    getAllSubscriptions();
    getAutoRecharge();
    getBillingDetails();
  }, []);

  const getWallet = () => {
    setIsLoading(true);
    walletStore
      .getWallet()
      .then((resp) => {
        setIsLoading(false);
        setAvailableMinutes(
          Math.floor(resp.data.paid_mins_consumed + resp.data.sub_mins_consumed)
        );
        setFreeConsumedMinutes(Math.floor(resp.data.sub_mins_consumed));
        setFreeTotalMinutes(Math.floor(resp.data.sub_total_mins));
        setPaidConsumedMinutes(Math.floor(resp.data.paid_mins_consumed));
        setPaidTotalMinutes(Math.floor(resp.data.paid_total_mins));
        setVoicemailTotal(Math.floor(resp.data.voice_detection_total));
        setVoicemailConsumed(
          Math.floor(resp.data?.consumed_voice_detection ?? 0)
        );
        walletStore.setWalletBalance(
          Math.floor(resp.data.paid_mins_consumed + resp.data.sub_mins_consumed)
        );
        walletStore.setTotalMinutes(
          Math.floor(resp.data.paid_total_mins + resp.data.sub_total_mins)
        );
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getSubscription = () => {
    subscriptionStore.getCurrentSubscription().then((resp) => {
      const subscription = new Subscription(resp.data);
      if (sharedStore.business)
        sharedStore.business.subscription = subscription;
      else sharedStore.setBusiness(new Business({ subscription }));
    });
  };

  const getAllSubscriptions = () => {
    subscriptionStore.getAllSubscriptions().then((resp) => {
      if (resp.code === 200) {
        const subscriptionList = resp.data?.reverse();
        const allSubscriptions = [];
        for (let index = 0; index < subscriptionList?.length; index++) {
          const subscription = new Subscription(subscriptionList[index]);
          if (subscription.is_active) {
            allSubscriptions.unshift(subscription);
          } else {
            allSubscriptions.push(subscription);
          }
        }
        if (allSubscriptions.length === 0) {
          allSubscriptions.push(
            new Subscription(sharedStore?.business?.subscription)
          );
        }
        for (let index = 0; index < allSubscriptions?.length; index++) {
          if (allSubscriptions[index].is_future) {
            setNextSubscription(allSubscriptions[index]);
          } else {
            setNextSubscription(new Subscription(null));
          }
        }
      }
    });
  };

  const getAddOnsUsage = () => {
    subscriptionStore.getAddOnsUsage().then((resp) => {
      const data = resp.data;
      if (data?.total_numbers || data?.total_consumed_numbers)
        walletStore.setNumberAddOnDetails(
          new SubscriptionPlan({
            ...walletStore.numberAddOnDetails?.toJson(),
            total_numbers: data?.total_numbers,
            total_consumed_numbers: data?.total_consumed_numbers,
          })
        );
      if (data?.total_agent || data?.total_consumed_agent)
        walletStore.setAiAgentAddOnDetails(
          new SubscriptionPlan({
            ...walletStore.aiAgentAddOnDetails?.toJson(),
            total_agent: data?.total_agent,
            total_consumed_agent: data?.total_consumed_agent,
          })
        );
      if (data?.total_infobase || data?.consumed_infobase)
        walletStore.setInfobaseAddOnDetails(
          new SubscriptionPlan({
            ...walletStore.infobaseAddOnDetails?.toJson(),
            total_infobase: data?.total_infobase,
            consumed_infobase: data?.consumed_infobase,
          })
        );
    });
  };

  const getAddOns = () => {
    subscriptionStore.getAddOns().then((resp) => {
      const responseList = resp.data;
      responseList?.forEach((element: any) => {
        const plan = new SubscriptionPlan(element);
        if (plan?.add_on_type === "agent") {
          walletStore.setAiAgentAddOnDetails(plan);
        }
        if (plan?.add_on_type === "number") {
          walletStore.setNumberAddOnDetails(plan);
        }
        if (plan?.add_on_type === "infobase") {
          walletStore.setInfobaseAddOnDetails(plan);
        }
        if (plan?.add_on_type === "user") {
          walletStore.setUserAddOnDetails(plan);
        }
        if (plan?.add_on_type === "concurrent_call") {
          walletStore.setConcurrentAddOnDetails(plan);
        }
      });
      console.log(walletStore.aiAgentAddOnDetails);
      getAddOnsUsage();
    });
  };

  const addMoney = () => {
    setIsCheckout(true);
  };

  const viewPlans = () => {
    navigate("/billing/subscription");
  };

  const onAddOnSelect = (type: string) => {
    setIsSelectAddon(false);
    if (type === "minutes") {
      walletStore.setSelectedAddOn(new SubscriptionPlan(null));
    } else if (type === "numbers") {
      walletStore.setSelectedAddOn(
        walletStore.numberAddOnDetails ?? new SubscriptionPlan(null)
      );
    } else if (type === "agent") {
      walletStore.setSelectedAddOn(
        walletStore.aiAgentAddOnDetails ?? new SubscriptionPlan(null)
      );
    } else if (type === "infobase") {
      walletStore.setSelectedAddOn(
        walletStore.infobaseAddOnDetails ?? new SubscriptionPlan(null)
      );
    } else if (type === "voicemail") {
      walletStore.setSelectedAddOn(new SubscriptionPlan(null));
    }
    if (type === selectedAddonType) {
      setSelectedAddonType("");
    }
    setSelectedAddonType(type);
  };

  useEffect(() => {
    if (selectedAddonType && selectedAddonType !== "") {
      setIsAddAddon(true);
      console.log(walletStore.selectedAddOn);
    }
  }, [selectedAddonType]);

  const redirectToStripe = () => {
    setLoaderGetStripeUrl(true);
    subscriptionStore
      .getStripeUrl()
      .then((resp) => {
        setLoaderGetStripeUrl(false);
        window.open(resp.data.url, "_blank");
      })
      .catch((error) => {
        Toaster.error(error);
        setLoaderGetStripeUrl(false);
      });
  };

  const getAutoRecharge = () => {
    walletStore
      .getAutoRecharge()
      .then((resp) => {
        const data = resp.data;
        if (data?.auto_add_mins)
          setAutoRecharge((prev) => ({ ...prev, amount: data.auto_add_mins }));
        if (data?.trigger_mins)
          setAutoRecharge((prev) => ({
            ...prev,
            minimumBalance: data.trigger_mins,
          }));
        if (data?.enable_auto_recharge)
          setAutoRecharge((prev) => ({
            ...prev,
            enabled: data.enable_auto_recharge,
          }));
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const autoRechargeChange = (data: any) => {
    setAutoRechargePopup(false);
    setAutoRecharge(data);
  };

  return (
    <div className="w-full h-full">
      <div className="h-16 p-4 border-b border-gray-300 font-inter text-lg font-medium leading-7 text-left flex text-[var(--primary-color)] justify-between items-center">
        <div className="flex gap-1.5 w-full justify-between items-center">
          <span className="flex gap-1.5 items-center">Plan & Billings</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-col gap-4 border-b border-gray-300 p-6">
          <div className="flex flex-col gap-4 w-full">
            <span className="flex justify-between items-center text-[var(--primary-color)] text-lg font-medium">
              Usage
              <Button
                variant="secondary"
                onClick={() => setIsSelectAddon(true)}
              >
                Buy more add-ons
              </Button>
            </span>
            <div className="flex gap-4 w-full">
              <div className="border border-gray-300 p-4 rounded-lg flex flex-col gap-8 flex-[1] justify-between">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center text-[var(--primary-color)] text-sm font-normal">
                    <span>Minutes</span>
                    <div className="text-2xl font-semibold">
                      {availableMinutes} min
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center text-gray-500 text-sm font-normal whitespace-nowrap">
                      <span>Free</span>
                      <span>
                        {freeConsumedMinutes} of {freeTotalMinutes}
                      </span>
                    </div>
                    <Progress
                      value={
                        freeTotalMinutes === 0
                          ? 0
                          : (freeConsumedMinutes / freeTotalMinutes) * 100
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center text-gray-500 text-sm font-normal whitespace-nowrap">
                      <span>Paid</span>
                      <span>
                        {paidConsumedMinutes} of {paidTotalMinutes}
                      </span>
                    </div>
                    <Progress
                      value={
                        paidTotalMinutes === 0
                          ? 0
                          : (paidConsumedMinutes / paidTotalMinutes) * 100
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex-[2] flex flex-col gap-4">
                <div className="flex gap-4">
                  <div className="border border-gray-300 p-4 rounded-lg flex flex-col gap-8 flex-1">
                    <div className="flex justify-between items-center text-[var(--primary-color)] text-sm font-normal">
                      <span className="font-medium">AI Agents</span>
                      <span className="text-sm font-normal">
                        {walletStore?.aiAgentAddOnDetails
                          ?.total_consumed_agent ?? 0}{" "}
                        of{" "}
                        {walletStore?.aiAgentAddOnDetails?.total_agent &&
                        walletStore?.aiAgentAddOnDetails?.total_agent !== 0
                          ? walletStore?.aiAgentAddOnDetails?.total_agent
                          : "Unlimited"}
                      </span>
                    </div>
                    <Progress
                      value={
                        walletStore?.aiAgentAddOnDetails?.total_agent
                          ? ((walletStore?.aiAgentAddOnDetails
                              ?.total_consumed_agent ?? 0) /
                              walletStore?.aiAgentAddOnDetails.total_agent) *
                            100
                          : 0
                      }
                    />
                  </div>
                  <div className="border border-gray-300 p-4 rounded-lg flex flex-col gap-8 flex-1">
                    <div className="flex justify-between items-center text-[var(--primary-color)] text-sm font-normal">
                      <span className="font-medium">Numbers</span>
                      <span className="text-sm font-normal">
                        {walletStore?.numberAddOnDetails
                          ?.total_consumed_numbers ?? 0}{" "}
                        of{" "}
                        {walletStore?.numberAddOnDetails?.total_numbers &&
                        walletStore?.numberAddOnDetails?.total_numbers !== 0
                          ? walletStore?.numberAddOnDetails?.total_numbers
                          : "Unlimited"}
                      </span>
                    </div>
                    <Progress
                      value={
                        walletStore?.numberAddOnDetails?.total_numbers
                          ? ((walletStore?.numberAddOnDetails
                              ?.total_consumed_numbers ?? 0) /
                              walletStore?.numberAddOnDetails.total_numbers) *
                            100
                          : 0
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="border border-gray-300 p-4 rounded-lg flex flex-col gap-8 flex-1">
                    <div className="flex justify-between items-center text-[var(--primary-color)] text-sm font-normal">
                      <span className="font-medium">Infobase</span>
                      <span className="text-sm font-normal">
                        {walletStore?.infobaseAddOnDetails?.consumed_infobase ??
                          0}{" "}
                        of{" "}
                        {walletStore?.infobaseAddOnDetails?.total_infobase ?? 0}
                      </span>
                    </div>
                    <Progress
                      value={
                        walletStore?.infobaseAddOnDetails?.total_infobase
                          ? ((walletStore?.infobaseAddOnDetails
                              ?.consumed_infobase ?? 0) /
                              walletStore?.infobaseAddOnDetails
                                .total_infobase) *
                            100
                          : 0
                      }
                    />
                  </div>
                  <div className="border border-gray-300 p-4 rounded-lg flex flex-col gap-8 flex-1">
                    <div className="flex justify-between items-center text-[var(--primary-color)] text-sm font-normal">
                      <span className="font-medium">Voicemail</span>
                      <span className="text-sm font-normal">
                        {voicemailConsumed} of {voicemailTotal}
                      </span>
                    </div>
                    <Progress
                      value={
                        voicemailTotal
                          ? (voicemailConsumed / voicemailTotal) * 100
                          : 0
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 border-b border-gray-300 p-6">
          <span className="text-lg font-medium text-[var(--primary-color)]">
            Plan
          </span>
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-1">
              <span className="font-inter text-[20px] font-medium leading-[30px] text-left text-[var(--primary-color)]">
                {!sharedStore?.business?.subscription?.plan?.is_trial
                  ? `${toPascalCase(
                      sharedStore?.business?.subscription?.duration
                    )} ${sharedStore?.business?.subscription?.plan?.name}`
                  : sharedStore?.business?.subscription?.plan?.name}
              </span>
              <span className="text-base font-normal text-gray-500">
                Your subscription will expire on{" "}
                <span className="text-[var(--primary)]">
                  {sharedStore.business?.subscription?.end_date}
                </span>
              </span>
            </div>
            {user?.user_type === 1 && sharedStore.business?.partner?.name && (
              <div className="flex gap-4 items-center">
                <Button variant="outline" onClick={viewPlans}>
                  View plans
                </Button>
                {(sharedStore.business?.subscription?.is_expired ||
                  sharedStore.business?.subscription?.plan?.is_trial) && (
                  <Button onClick={viewPlans}>Upgrade Now</Button>
                )}
              </div>
            )}
            {user?.user_type === 1 && !sharedStore.business?.partner?.name && (
              <div className="flex gap-4 items-center">
                <Button variant="outline" onClick={viewPlans}>
                  View plans
                </Button>
                {!sharedStore.business?.subscription?.plan?.is_trial && (
                  <Button
                    onClick={redirectToStripe}
                    disabled={loaderGetStripeUrl}
                  >
                    Manage subscription
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-base font-normal text-gray-500">
              Features included
            </span>
            <div className="flex flex-wrap gap-4">
              {[...Array(4)].map((_, colIndex) => (
                <div key={colIndex} className="flex flex-col text-sm">
                  {sharedStore?.business?.subscription?.plan?.features
                    ?.slice(colIndex * 3, (colIndex + 1) * 3)
                    .map((feature: any, index: any) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 px-2 py-1"
                      >
                        <span>
                          {feature?.is_available ? (
                            <Check size={"16px"} color="rgb(22, 163, 74)" />
                          ) : (
                            <X size={"16px"} color="rgb(220, 38, 38)" />
                          )}
                        </span>
                        <span>{feature?.text}</span>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {nextSubscription?.id && (
            <div className="flex flex-col gap-1">
              <span className="text-base font-normal text-gray-500">
                Upcoming {nextSubscription?.duration}{" "}
                {nextSubscription?.plan?.name} plan will start on{" "}
                <span className="text-[var(--primary-color)]">
                  {nextSubscription?.start_date}
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center p-6">
          <div className="flex flex-col gap-1">
            <span className="text-lg font-medium text-[var(--primary-color)]">
              Auto-recharge
            </span>
            <span className="text-base font-normal text-gray-500">
              {autoRecharge.enabled
                ? `Auto-purchase ${autoRecharge.amount} minutes when balance drops below ${autoRecharge.minimumBalance} minutes.`
                : "Auto-purchase minutes when balance drops below a threshold."}
            </span>
          </div>
          <div className="flex gap-4 items-center">
            <span className="text-lg font-medium">
              {autoRecharge.enabled && `${autoRecharge.amount} min`}
            </span>
            <Button
              variant="outline"
              onClick={() => setAutoRechargePopup(true)}
            >
              {autoRecharge.enabled ? "Edit" : "Enable"}
            </Button>
          </div>
        </div>
        {isSelectAddon && (
          <SelectAddon
            agentRate={walletStore?.aiAgentAddOnDetails?.monthly_price ?? 1}
            numberRate={walletStore?.numberAddOnDetails?.monthly_price ?? 1}
            infobaseRate={walletStore?.infobaseAddOnDetails?.monthly_price ?? 1}
            minutesRate={sharedStore?.business?.subscription?.call_rate ?? 0}
            voicemailRate={
              sharedStore?.business?.subscription?.voice_detection_rate ?? 0
            }
            type={selectedAddonType}
            onSelect={onAddOnSelect}
            onClose={() => {
              setIsSelectAddon(false);
              setSelectedAddonType("");
            }}
          />
        )}
        {isAddAddon && (
          <AddAddons
            type={selectedAddonType}
            addOn={walletStore?.selectedAddOn ?? new SubscriptionPlan(null)}
            onClose={() => {
              setIsAddAddon(false);
              setIsSelectAddon(true);
            }}
          />
        )}
        {autoRechargePopup && (
          <AutoRecharge
            amount={autoRecharge.amount}
            minimumBalance={autoRecharge.minimumBalance}
            enabled={autoRecharge.enabled}
            onClose={() => setAutoRechargePopup(false)}
            onAdded={autoRechargeChange}
          />
        )}
      </div>
    </div>
  );
};

export default WalletComponent;
