import React from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../shadcn/tooltip';
import { Info } from 'lucide-react';

interface TooltipProps {
  text: string;
}

const TooltipComponent: React.FC<TooltipProps> = ({ text }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger><Info size="16px" color="var(--icon-sec-color)"/></TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipComponent;