import React from 'react';

interface LabelProps {
  labelElement: {
    name: string;
    bg_color: string;
    color: string;
  };
  closeButton?: boolean;
  onClose?: (event: React.MouseEvent) => void;
}

const Label: React.FC<LabelProps> = ({ labelElement, closeButton = false, onClose }) => {
  return (
    <span
      className="label flex items-center gap-2 px-2 py-1 rounded text-[12px] leading-4 w-fit"
      style={{
      background: `#${labelElement?.bg_color}`,
      color: `#${labelElement?.color}`,
      }}
    >
      {labelElement?.name}
      {closeButton && (
      <span
        className="cursor-pointer"
        onClick={onClose}
        style={{
        color: `#${labelElement?.color}`,
        fontSize: '12px',
        }}
      >
        &#x2715;
      </span>
      )}
    </span>
  );
};

export default Label;