import { AiAgent } from "../../models/ai-agent";
import { useRetellStore } from "../../store/retell.store";
import { useWebSocketStore } from "../../store/websocket.store";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "../shadcn/input";
import { ArrowLeft, Phone, SendHorizontal, Sparkles } from "lucide-react";
import { ScrollArea } from "../shadcn/scrollArea";

interface LiveChatProps {
  agent: AiAgent;
  close: () => void;
}

const LiveChat: React.FC<LiveChatProps> = ({ agent, close }) => {
  const [loaderCall, setLoaderCall] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState<Record<string, any>[]>([]);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const websocketStore = useWebSocketStore();
  const retellStore = useRetellStore();
  useEffect(() => {
    const initialMessage = {
      type: "test_agent",
      agent_id: agent?.id,
      message: currentMessage,
    };
    setMessageList([]);
    websocketStore.sendMessage(initialMessage);

    const subscription = websocketStore.testAgentTextEvents$.subscribe(
      (data) => {
        if (data?.agent_id === agent?.id) {
          const message = { ...data, role: "agent" };
          setMessageList((prevMessages) => [...prevMessages, message]);
          scrollToBottom();
        }
      }
    );

    return () => {
      const clearMessage = {
        type: "test_agent",
        agent_id: agent?.id,
        message: "cleared",
        command: "clear",
      };
      websocketStore.sendMessage(clearMessage);
      subscription.unsubscribe();
    };
  }, [agent]);

  const scrollToBottom = () => {
    if (scrollBottomRef.current) {
      setTimeout(() => {
        scrollBottomRef.current!.scrollTop =
          scrollBottomRef.current!.scrollHeight;
      }, 100);
    }
  };

  const testCall = () => {
    setLoaderCall(true);
    const input = {
      agent_id: agent?.id,
      is_test_call: true,
    };
    retellStore.setLiveAgent(agent);
    retellStore.setCallLive(true);
    retellStore
      .registerCall(input)
      .then((resp) => {
        setLoaderCall(false);
        retellStore.setLiveAgent(agent);
        retellStore.startCall(
          resp.data.call_id,
          resp.data.access_token
        );
      })
      .catch((error) => {
        console.error(error);
        setLoaderCall(false);
      });
  };

  const textCall = () => {
    const input = {
      type: "test_agent",
      agent_id: agent?.id,
      message: currentMessage,
    };
    websocketStore.sendMessage(input);
    setMessageList((prevMessages) => [
      ...prevMessages,
      { ...input, role: "user" },
    ]);
    scrollToBottom();
    setCurrentMessage("");
  };

  return (
    <div className="h-full">
      <div className="h-16 p-4 border-b border-[#D1D5DB] flex justify-between items-center text-[#070A0C] font-inter text-lg font-medium">
        <span className="flex gap-2 items-center">
          <span className="cursor-pointer" onClick={() => close()}>
            <ArrowLeft size="16px" />
          </span>
          Test agent
        </span>
        <div
          className={`bg-[#F3F4F6] h-9 w-10 flex justify-center items-center rounded cursor-pointer ${
            retellStore.callLive || loaderCall ? "cursor-default" : ""
          }`}
          onClick={() =>
            retellStore.callLive || loaderCall ? null : testCall()
          }
        >
          <Phone size="20px" />
        </div>
      </div>
      <ScrollArea
        className="h-[calc(100%-70px)] p-3 flex flex-col gap-4"
        ref={scrollBottomRef}
      >
        {messageList.length ? (
          messageList.map((message, index) => (
            <div
              key={index}
              className={`flex flex-col gap-1 ${message["role"]}`}
            >
              {message["role"] === "agent" && (
                <div className="flex items-center bg-[#F3F4F6] w-fit rounded p-1 gap-1 text-[#070A0C] font-inter text-xs font-normal">
                  <img
                    src="../../../../../assets/images/avatar.png"
                    alt=""
                    className="h-6 rounded-full"
                  />
                  <span>{agent?.agent_name}</span>
                  <Sparkles size="14px" />
                </div>
              )}
                <div
                className={`p-1.5 font-inter text-sm font-normal text-[#070A0C] max-w-[80%] w-fit ${
                  message["role"] === "agent"
                  ? "bg-[#F3F4F6] rounded"
                  : "bg-[#070A0C] text-[#F3F4F6] rounded self-end"
                }`}
                >
                <span>{message["message"]}</span>
                </div>
            </div>
          ))
        ) : (
          <div className="h-full flex flex-col gap-4 justify-center items-center">
            <img
              src="../../../../../assets/images/text-message-empty.png"
              alt="live-text-empty"
            />
            <p className="text-sm font-medium text-[#070A0C]">
              Start messaging the Agent
            </p>
          </div>
        )}
      </ScrollArea>
      <div className="p-4">
        <div className="flex gap-2 items-center justify-center rounded">
          <Input
            className="w-full"
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && currentMessage !== "" ? textCall() : null
            }
          />
          <div
            className={`rounded flex items-center justify-center w-9 h-9 ${
              currentMessage.length > 0 ? "bg-black" : "bg-[#D1D5DB]"
            }`}
            onClick={() => (currentMessage === "" ? null : textCall())}
          >
            <SendHorizontal size="20px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChat;
