import { useState, useEffect, useRef } from "react";
import { CheckCircle, ArrowLeft, SkipForward } from "lucide-react";
import { AiAgent } from "../../../models/ai-agent";
import { Button } from "../../shadcn/button";
import { formatDuration } from "../../../lib/ui.utils";
import Toaster from "../../shared/customToast";
import { useRetellStore } from "../../../store/retell.store";
import useSharedStore from "../../../store/shared.store";
import { Subscription } from "rxjs";

interface WelcomeForm2Props {
  agent: AiAgent;
  onBack: () => void;
  onSkip: () => void;
  onDeploy: () => void;
}
const WelcomeForm2: React.FC<WelcomeForm2Props> = ({ agent, onBack, onSkip, onDeploy }) => {
  const [transcript, setTranscript] = useState<any[]>([]);
  const [callLive, setCallLive] = useState(false);
  const [duration, setDuration] = useState(0);
  const [testCallCount, setTestCallCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const retellStore = useRetellStore();
  const sharedStore = useSharedStore();
  const subscribeToTranscript = useRetellStore((state) => state.subscribeToTranscript);

  useEffect(() => {
    if (callLive) {
      const interval = setInterval(() => {
        setDuration((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [callLive]);

  useEffect(() => {
    const unsubscribe = subscribeToTranscript((data) => {
      setTranscript(data);
    });

    return () => {
      unsubscribe(); // ✅ Proper cleanup without breaking Subject
    };
  }, [subscribeToTranscript]);

  

  useEffect(() => {
    if (scrollBottomRef.current) {
      scrollBottomRef.current!.scrollTop = scrollBottomRef.current!.scrollHeight;
    }
  }, [transcript]);

  const startTestCall = async () => {
    setLoading(true);
    const input = {
      agent_id: agent?.id,
      is_test_call: true,
    };
    try {
      const resp = await retellStore.registerCall(input);
      setLoading(false);
      setDuration(0)
      setCallLive(true);
      retellStore.startCall(resp.data?.call_id, resp.data?.access_token);
      var audio = new Audio('/assets/call_start.mp3');
      audio.load();
      audio.play();
    } catch (error: any) {
      console.error(error);
      Toaster.error(JSON.stringify(error?.message ?? error));
      setLoading(false);
      retellStore.setCallLive(false);
    }
  }

  const endCall = () => {
    setTestCallCount((prev) => prev + 1);
    setCallLive(false);
    retellStore.stopCall();
    var audio = new Audio('/assets/call_end.mp3');
    audio.load();
    audio.play();
  };

  return (
    <div className="w-full flex flex-col gap-4 p-4 h-full overflow-auto">
      {/* Stepper */}
      <div className="flex items-center justify-between p-4">
        <button className="flex items-center gap-2 text-gray-500" onClick={onBack}>
          <ArrowLeft size={20} /> Back
        </button>
        <div className="flex items-center gap-4">
          <span className="flex items-center gap-2 text-purple-700 font-semibold">
            <CheckCircle size={18} className="text-purple-700" /> Create AI agent
          </span>
          <hr className="w-20 h-[2px] bg-purple-700 rounded-md" />
          <span className="font-semibold text-purple-700">Test AI agent</span>
          <hr className="w-20 h-[1px] bg-gray-300" />
          <span>Deploy AI agent</span>
          <hr className="w-20 h-[1px] bg-gray-300" />
          <span>Go live</span>
        </div>
        <button className="flex items-center gap-2 text-gray-500" onClick={onSkip}>
          <SkipForward size={20} /> Skip
        </button>
      </div>
      
      {/* Card Fields */}
      <div className="flex flex-col items-center p-4">
        <h2 className="text-xl font-semibold text-gray-800">Test AI Agent</h2>
        <p className="text-sm text-gray-500">Test your AI agent's performance through a call.</p>
      </div>

      {/* Transcript Section */}
      <div className="w-full flex justify-center">
        <div className="border rounded-lg p-4 w-full max-w-xl flex flex-col gap-4">
          <div className="flex flex-col items-center gap-2">
            <img src={agent?.profile_pic ?? "/default-avatar.png"} alt="Agent" className="w-16 h-16 rounded-full object-cover" />
            <span className="text-lg font-medium">{agent?.agent_name}</span>
            {duration > 0 && <span className="text-sm text-gray-500">{formatDuration(duration)}</span>}
          </div>
          <div ref={scrollBottomRef} className="overflow-auto max-h-64 p-2">
          {transcript.map((message, index) => (
              <div
                key={index}
                className={`flex flex-col gap-1 mt-1 ${message?.role === 'user' ? 'items-end' : ''}`}
              >
                <div className="flex gap-1 text-sm font-normal items-center">
                  {(message?.role === "agent" ||
                    message?.role === "assistant") && (
                    <div className="relative bg-[var(--hover-color)] flex items-center pl-5 rounded-lg">
                      <span className="absolute left-0 h-5 w-5 bg-[var(--hover-color)] rounded-full flex items-center justify-center">
                        <img
                          src={
                            agent?.profile_pic ??
                            "../../../../assets/images/avatar.png"
                          }
                          alt=""
                          className="h-5 w-5 rounded-full"
                        />
                      </span>
                      <span className="bg-[var(--hover-color)] rounded-full text-primary text-sm font-normal pl-1 pr-2">
                        {agent?.agent_name}
                      </span>
                    </div>
                  )}
                </div>
                <div className="p-1 text-sm font-normal text-primary max-w-[80%] w-fit whitespace-pre-wrap break-words rounded-lg bg-[var(--hover-color)]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: message?.content ?? message?.transcript,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Action Buttons */}
      <div className="flex justify-center gap-4 p-4">
        {!callLive ? (
          <Button variant={"outline"} onClick={startTestCall} disabled={loading}>
            {loading ? "Starting..." : "Test Call"}
          </Button>
        ) : (
          <Button onClick={endCall} disabled={loading} variant="destructive">
            {loading ? "Ending..." : "End Call"}
          </Button>
        )}
        {testCallCount > 0 && (
          <Button onClick={onDeploy}>Deploy Agent</Button>
        )}
      </div>
    </div>
  );
};

export default WelcomeForm2;
