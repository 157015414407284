import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiAgent } from "../../../models/ai-agent";
import { Widget, WidgetAgent } from "../../../models/widget";
import { updateWidget, deleteWidget } from "../../../lib/widget.utils";
import Toaster from "../../shared/customToast";
import { Input } from "../../shadcn/input";
import { Button } from "../../shadcn/button";
import {
  ArrowLeft,
  ChevronDown,
  Circle,
  CircleCheck,
  EllipsisVertical,
  Phone,
} from "lucide-react";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown";
import { getAllAgents } from "../../../lib/agents.utils";
import { Switch } from "../../shadcn/switch";
import "./ViewWidget.css";
import { useSharedStore } from "../../../store/shared.store";
import { getPresignUrl, uploadToS3 } from "../../../lib/common.utils";
import { LoggedUser } from "../../../models/logged-user";
import { useAgencyStore } from "../../../store/agency.store";

interface ViewWidgetProps {
  widget: Widget;
  back: (widget: Widget) => void;
  update: (widget: Widget) => void;
  delete: (deleted: boolean) => void;
}

const ViewWidget: React.FC<ViewWidgetProps> = ({
  widget,
  back,
  update,
  delete: deleteWidgetProp,
}) => {
  const navigate = useNavigate();
  const [agentList, setAgentList] = useState<AiAgent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<AiAgent | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [imageToBeUploaded, setImageToBeUploaded] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string>("");

  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();

  const [widgetData, setWidgetData] = useState<Widget>(new Widget(widget));

  const [user, setUser] = useState<LoggedUser>(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );

  useEffect(() => {
    console.log(widget);
    let widgetAgent = new AiAgent(widgetData?.agent);
    setSelectedAgent(widgetAgent);
    getAllAgents(1, true).then((resp) => {
      setAgentList(resp.data.data);
      if (widgetData?.agent) {
        let widgetAgent = new AiAgent(widgetData?.agent);
        setSelectedAgent(widgetAgent);
      }
    });
  }, [widget]);

  const backToList = () => {
    navigate("/settings/widgets/");
    back(widgetData);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();
      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        Toaster.error("Invalid image type");
        return;
      }
      if (file.size / 1024 > 1024) {
        Toaster.error("Invalid image size");
        return;
      }
      setImageToBeUploaded(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target?.result as string);
      };
      reader.readAsDataURL(file);

      const epoc = `${new Date().getTime()}-${user?.id}.${fileType}`;
      const presignObj = {
        key: `images/${epoc}`,
        content_type: "image/jpeg",
        public_file: true,
      };

      getPresignUrl(presignObj).then((res: any) => {
        const { url, fields } = res.data.data;
        uploadToS3(url, fields, file).then(() => {
          const logoUrl = url.endsWith("/")
            ? `${url}${fields.key}`
            : `${url}/${fields.key}`;
          setWidgetData(
            (prevData) => new Widget({ ...prevData.toJSON(), logo: logoUrl })
          );
          saveWidget();
        });
      });
    }
  };

  useEffect(() => {
    saveWidget();
  }, [widgetData.logo, widgetData?.agent?.id]);

  const saveWidget = () => {
    let input: Record<string, any> = {
      name: widgetData?.name,
      brand_name: widgetData?.brand_name,
      logo: widgetData?.logo,
      properties: {
        color: widgetData?.properties?.color,
        subtitle: widgetData?.properties?.subtitle,
        position: widgetData?.properties?.position,
        button_text: widgetData?.properties?.button_text,
        welcome_text: widgetData?.properties?.welcome_text,
        text_color: widgetData?.properties?.text_color,
      },
      agent: selectedAgent?.id,
    };
    if (input["brand_name"] === "") input["brand_name"] = null;
    if (input["logo"] === "") input["logo"] = null;
    if (selectedAgent?.id) {
      widgetData.agent = new WidgetAgent(selectedAgent);
      sharedStore.business!.is_agent_deployed = true;
      sharedStore.setBusiness(sharedStore.business!);
    }
    updateWidget(input, widgetData?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
        update(widget);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const handleDeleteWidget = () => {
    setDeleteConfirmation(false);
    deleteWidget(widgetData?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
        deleteWidgetProp(true);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  return (
    <div className="h-full p-0">
      <div className="h-16 p-4 border-b border-gray-300 font-inter text-lg font-medium leading-7 text-left flex text-[var(--primary-color)] justify-between items-center">
        <div className="flex gap-1.5 w-full justify-between items-center">
          <span className="flex gap-1.5 items-center">
            <span className="cursor-pointer" onClick={backToList}>
              <ArrowLeft className="text-gray-600" />
            </span>
            {widgetData?.name}
          </span>
          <span className="flex items-center gap-2">
            <Switch
              checked={widgetData?.is_active ?? false}
              onCheckedChange={(checked: boolean) => {
                const updatedWidget = new Widget({
                  ...widgetData.toJSON(),
                  is_active: checked,
                });
                setWidgetData(updatedWidget);
                saveWidget();
              }}
            />
            {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.widgets["widget_"+widgetData?.id]?.delete) && <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="p-2">
                  <EllipsisVertical className="text-gray-600" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white border border-gray-300 w-auto rounded-lg p-2">
                <DropdownMenuItem
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onSelect={() => setDeleteConfirmation(true)}
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>}
          </span>
        </div>
      </div>
      <div className="flex">
        <div className="border-r border-gray-300 h-full w-full max-w-2xl">
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Widget name</span>
              <Input
                value={widgetData?.name ?? ""}
                onChange={(e) => {
                  const updatedWidget = new Widget({
                    ...widgetData.toJSON(),
                    name: e.target.value,
                  });
                  setWidgetData(updatedWidget);
                }}
                onBlur={saveWidget}
              />
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Brand name</span>
              <Input
                value={widgetData?.brand_name}
                onChange={(e) => {
                  const updatedWidget = new Widget({
                    ...widgetData.toJSON(),
                    brand_name: e.target.value,
                  });
                  setWidgetData(updatedWidget);
                }}
                onBlur={saveWidget}
              />
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Brand logo</span>
              <Input
                type="file"
                accept=".jpeg,.jpg,.png"
                onChange={handleImageUpload}
              />
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Subtitle</span>
              <Input
                value={widgetData?.properties?.subtitle}
                onChange={(e) => {
                  const updatedWidget = new Widget({
                    ...widgetData.toJSON(),
                    properties: {
                      ...widgetData.properties.toJSON(),
                      subtitle: e.target.value,
                    },
                  });
                  setWidgetData(updatedWidget);
                }}
                onBlur={saveWidget}
              />
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Button color</span>
              <div className="h-9 px-3 rounded-lg border border-gray-300 flex items-center gap-2 w-full">
                <span className="w-6 h-6 border-none rounded flex items-center justify-center">
                  <input
                    type="color"
                    value={widgetData?.properties?.color ?? ""}
                    className="w-6 h-6 rounded border-none cursor-pointer outline-none"
                    // style={{ background: widgetData?.properties?.color ?? "" }}
                    onChange={(e) => {
                      const updatedWidget = new Widget({
                        ...widgetData.toJSON(),
                        properties: {
                          ...widgetData.properties.toJSON(),
                          color: e.target.value,
                        },
                      });
                      setWidgetData(updatedWidget);
                    }}
                    onBlur={saveWidget}
                  />
                </span>
                <input
                  type="text"
                  className="font-inter h-[34px] text-sm font-normal leading-5 text-left border-none text-[var(--primary-color)] w-full focus:border-none focus:outline-none focus:ring-transparent"
                  value={widgetData?.properties?.color ?? ""}
                  onChange={(e) => {
                    const updatedWidget = new Widget({
                      ...widgetData.toJSON(),
                      properties: {
                        ...widgetData.properties.toJSON(),
                        color: e.target.value,
                      },
                    });
                    setWidgetData(updatedWidget);
                  }}
                  onBlur={saveWidget}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">
                Text color scheme
              </span>
              <div className="flex gap-2">
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    const updatedWidget = new Widget({
                      ...widgetData.toJSON(),
                      properties: {
                        ...widgetData.properties.toJSON(),
                        text_color: "#F3F4F6",
                      },
                    });
                    setWidgetData(updatedWidget);
                    saveWidget();
                  }}
                >
                  {widgetData?.properties?.text_color === "#F3F4F6" ? (
                    <CircleCheck size="24px" color="#070A0B" />
                  ) : (
                    <Circle size="24px" color="#070A0B" />
                  )}
                  Light
                </span>
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    const updatedWidget = new Widget({
                      ...widgetData.toJSON(),
                      properties: {
                        ...widgetData.properties.toJSON(),
                        text_color: "#070A0B",
                      },
                    });
                    setWidgetData(updatedWidget);
                    saveWidget();
                  }}
                >
                  {widgetData?.properties?.text_color === "#070A0B" ? (
                    <CircleCheck size="24px" color="#070A0B" />
                  ) : (
                    <Circle size="24px" color="#070A0B" />
                  )}
                  Dark
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Select agent</span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full flex justify-between font-normal"
                  >
                    <span className="flex items-center gap-2">
                      <img
                        src={
                          selectedAgent?.profile_pic ??
                          "../../../../../assets/images/avatar.png"
                        }
                        alt=""
                        className="w-6 h-6 rounded-full"
                      />
                      {selectedAgent?.agent_name ?? "Select agent"}
                    </span>
                    <ChevronDown />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white border border-gray-300 w-[420px] rounded-lg p-2 max-h-[300px] overflow-auto">
                  {agentList.map((agent) => (
                    <DropdownMenuItem
                      key={agent.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                      onSelect={() => {
                        setSelectedAgent(agent);
                        const updatedWidget = new Widget({
                          ...widgetData.toJSON(),
                          agent: new WidgetAgent(agent).toJSON(),
                        });
                        setWidgetData(updatedWidget);
                      }}
                    >
                      <img
                        src={
                          agent.profile_pic ??
                          "../../../../../assets/images/avatar.png"
                        }
                        alt=""
                        className="w-6 h-6 rounded-full"
                      />
                      {agent.agent_name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Position</span>
              <div className="flex gap-2">
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    const updatedWidget = new Widget({
                      ...widgetData.toJSON(),
                      properties: {
                        ...widgetData.properties.toJSON(),
                        position: "bottom-right",
                      },
                    });
                    setWidgetData(updatedWidget);
                    saveWidget();
                  }}
                >
                  {widgetData?.properties?.position === "bottom-right" ? (
                    <CircleCheck size="24px" color="#070A0B" />
                  ) : (
                    <Circle size="24px" color="#070A0B" />
                  )}
                  Bottom right
                </span>
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    const updatedWidget = new Widget({
                      ...widgetData.toJSON(),
                      properties: {
                        ...widgetData.properties.toJSON(),
                        position: "bottom-left",
                      },
                    });
                    setWidgetData(updatedWidget);
                    saveWidget();
                  }}
                >
                  {widgetData?.properties?.position === "bottom-left" ? (
                    <CircleCheck size="24px" color="#070A0B" />
                  ) : (
                    <Circle size="24px" color="#070A0B" />
                  )}
                  Bottom left
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">
                Call bubble text
              </span>
              <Input
                value={widgetData?.properties?.button_text}
                onChange={(e) => {
                  const updatedWidget = new Widget({
                    ...widgetData.toJSON(),
                    properties: {
                      ...widgetData.properties.toJSON(),
                      button_text: e.target.value,
                    },
                  });
                  setWidgetData(updatedWidget);
                }}
                onBlur={saveWidget}
              />
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Welcome text</span>
              <Input
                value={widgetData?.properties?.welcome_text}
                onChange={(e) => {
                  const updatedWidget = new Widget({
                    ...widgetData.toJSON(),
                    properties: {
                      ...widgetData.properties.toJSON(),
                      welcome_text: e.target.value,
                    },
                  });
                  setWidgetData(updatedWidget);
                }}
                onBlur={saveWidget}
              />
            </div>
          </div>
        </div>
        <div className="min-w-[368px] w-full">
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300 items-center">
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">
                Chat Widget preview
              </span>
            </div>
            <div className="widget-preview">
              <div className="widget-main-body">
                <div className="widget-heading">
                  <span>
                    <img
                      src={imageToBeUploaded ? imageSrc : widgetData?.logo}
                      alt=""
                    />
                  </span>
                  <span className="widget-heading-details">
                    <span className="text-[var(--primary-color)] font-inter text-[16px] font-medium leading-6 text-left">
                      {widgetData?.brand_name}
                    </span>
                    <span className="text-[var(--secondary-color)] font-inter text-[14px] font-normal leading-5 text-left">
                      {widgetData?.properties?.subtitle}
                    </span>
                  </span>
                </div>
                <div className="widget-body">
                  <span>
                    <img
                      src={
                        widgetData?.agent?.profile_pic ??
                        "../../../../../assets/images/avatar.png"
                      }
                      alt=""
                    />
                  </span>
                  <span className="widget-body-name">
                    {widgetData?.agent?.agent_name}
                  </span>
                  <span className="widget-body-status">
                    <span></span> Available
                  </span>
                  <span className="widget-body-status">
                    {widgetData?.properties?.welcome_text}
                  </span>
                </div>
                <div className="widget-footer">
                  <button
                    style={{
                      background: widgetData?.properties?.color,
                      color: widgetData?.properties?.text_color,
                    }}
                  >
                    <Phone
                      size="16px"
                      color={widgetData?.properties?.text_color ?? "#070A0B"}
                    />
                    {widgetData?.properties?.button_text}
                  </button>
                  <span>
                    Powered by <img src={agencyStore?.darkLogo} alt="" />
                  </span>
                </div>
              </div>
              <div
                className="widget-icon"
                style={{
                  justifyContent:
                    widgetData?.properties?.position === "bottom-right"
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <span style={{ background: widgetData?.properties?.color }}>
                  <Phone
                    size="28px"
                    color={widgetData?.properties?.text_color ?? "#070A0B"}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300 items-center">
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">Widget code</span>
              <span className="text-gray-500" style={{ maxWidth: "640px" }}>
                Place the code before the closing body tag on your site.
              </span>
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <pre className="p-1 bg-[var(--hover-color)] border border-[var(--Border-bd-1,var(--border-color))] rounded-lg text-[var(--secondary-color)]">
                <code
                  id="copyText"
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  &lt;widget-app token="{widgetData?.secret}" id="
                  {widgetData?.id}"&gt;&lt;/widget-app&gt; &lt;script
                  src="https://widget-agent-prod.s3.ap-south-1.amazonaws.com/widget.js"&gt;&lt;/script&gt;
                </code>
              </pre>
              <Button
                variant="outline"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `<widget-app token="${widgetData?.secret}" id="${widgetData?.id}"></widget-app><script src="https://widget-agent-prod.s3.ap-south-1.amazonaws.com/widget.js"></script>`
                  )
                }
              >
                Copy embed code
              </Button>
            </div>
          </div>
        </div>
      </div>
      {deleteConfirmation && (
        <ConfirmationPopup
          positiveButtonText="Yes, delete widget"
          negativeButtonText="No, keep widget"
          heading="Confirm delete widget"
          description={`Deleting the widget <span class="col-pri">${widgetData?.name}</span> will cause any embedded scripts on your website to stop working.`}
          onClose={() => setDeleteConfirmation(false)}
          onSuccess={handleDeleteWidget}
        />
      )}
    </div>
  );
};

export default ViewWidget;
