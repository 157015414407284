import apiClient from '../interceptors/axiosApiInterceptor';

export const getIntegrations = (dependent: boolean = false) => {
  const query = dependent
    ? '/integration/?r_type=dependent'
    : '/integration/';
  return apiClient.get(query);
};

export const getGhlCalendars = () =>
  apiClient.get('/calendar/ghl');

export const getGoogleCalendars = () =>
  apiClient.get('/calendar/google');

export const getCalCalendars = () =>
  apiClient.get('/calendar/cal');

export const connectCal = (obj: any) =>
  apiClient.post('/integration/cal', obj);

export const deleteIntegration = (id: number) =>
  apiClient.delete(`/integration/${id}`);

export const deleteSubAccountIntegration = (id: number) =>
  apiClient.delete(`/integration/${id}?clients=true`);
  
export const getGhlLocations = () =>
  apiClient.get('/hl/install');

export const connectGhlSubAccount = (obj: any) =>
  apiClient.post('/hl/install', obj);

export const getConnectedGhlSubAccount = () =>
  apiClient.get('/integration/?clients=true');
