import apiClient from '../interceptors/axiosApiInterceptor';

// Customer methods
export const getUsers = (page: number, type: string = 'all') =>
  type.toLowerCase() === 'blocked'
    ? apiClient.get(`/user/customer?block=1&page=${page}`)
    : apiClient.get(`/user/customer?page=${page}`);

export const getSingleUser = (id: number, dependent: boolean = false) => {
  const query = `/user/customer/${id}`;
  return dependent
    ? apiClient.get(`${query}?r_type=dependent`)
    : apiClient.get(query);
};

export const deleteCustomer = (id: number) =>
  apiClient.delete(`/user/customer/${id}`);

export const addUser = (userData: any) =>
  apiClient.post('/user/customer/', userData);

export const addExcel = (formData: FormData, fileName: string) =>
  apiClient.post('/user/customer/excel/', formData, {
    headers: {
      'Content-Disposition': `attachment; filename=${fileName.replaceAll(' ', '_')}`,
    },
  });

export const bulkCustomerUpload = (data: any) =>
  apiClient.post('/user/customer/excel/', data);

export const updateUser = (id: number, userData: any) =>
  apiClient.put(`/user/customer/${id}/`, userData);

export const updateCustomerBio = (id: number, bioData: any) =>
  apiClient.patch(`/user/customer/${id}/`, bioData);

// Search methods
export const searchUsers = (pageNumber: number, searchTag: string, type: string = 'all', dependent: boolean = false) => {
  const query = type.toLowerCase() === 'blocked'
    ? `/search/customer?q=${searchTag}&block=1&page=${pageNumber}`
    : `/search/customer?q=${searchTag}&page=${pageNumber}`;
  return dependent
    ? apiClient.get(`${query}&r_type=dependent`)
    : apiClient.get(query);
};

// Customer history
export const getCustomerHistory = (id: number | null = null, pageNumber: number) =>
  id !== null
    ? apiClient.get(`/user/customer/history/${id}?page=${pageNumber}`)
    : apiClient.get(`/user/customer/history?page=${pageNumber}`);

// Custom field methods
export const getCustomFieldsExport = () =>
  apiClient.get('/console/export-custom/', {
    responseType: 'blob',
  });

export const getCustomFields = () =>
  apiClient.get('/business/custom-field');

export const addCustomField = (fieldData: any) =>
  apiClient.post('/business/custom-field', fieldData);

// Marketing opt-out
export const getMarketingOptOutCustomers = (page: number) =>
  apiClient.get(`/user/opt-out?page=${page}`);

// Client methods
export const getClients = (page: number) =>
  apiClient.get(`/oneinbox/client?page=${page}`);

export const getClientAccess = (accessData: any) =>
  apiClient.post('/oneinbox/client', accessData);
