import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

interface CustomIconProps {
  iconName: string;
  width?: number;
  height?: number;
  color?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({ iconName, width = 20, height = 20, color = 'var(--primary-color)' }) => {

  return (
    <ReactSVG style={{ width: width+'px', height: height+'px', fill: color}} src={"/assets/svg/"+iconName+".svg"}/>
  );
};

export default CustomIcon;
