import useSharedStore from "../../store/shared.store";
import React, { useState, useEffect } from "react";
import { Input } from "../shadcn/input";
import { Skeleton } from "../shadcn/skeleton";
import { ScrollArea } from "../shadcn/scrollArea";
import { LoggedUser } from "../../models/logged-user";
import { Button } from "../shadcn/button";
import { Ellipsis, Loader2, Plus } from "lucide-react";
import { getInfoBaseFile, searchInfoBaseFile } from "../../lib/infobase.utils";
import { Dialog, DialogContent } from "../shadcn/dialog";
import AddInfobase from "./AddInfobase";
import { InfoBase } from "../../models/infobase";
import ViewInfobase from "./ViewInfobase";
import Pagination from "../shared/Pagination";
import { formatDateToMonthDayYear, formatTime } from "../../lib/ui.utils";
// import { WebSocketService } from "../services/web-socket.service";

const Infobase: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState<InfoBase[]>([]);
  const [paginationSize, setPaginationSize] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState(false);
  const [customerSearchTag, setCustomerSearchTag] = useState("");
  const [prevCustomerSearchTag, setPrevCustomerSearchTag] = useState("");

  const [isViewInfobase, setIsViewInfobase] = useState(false);
  const [viewedInfobase, setViewedInfobase] = useState<InfoBase>(
    new InfoBase(null)
  );
  const [viewedInfobaseIndex, setViewedInfobaseIndex] = useState<number>(-1);

  const [isAddInfobase, setIsAddInfobase] = useState(false);

  const sharedStore = useSharedStore();
  const user = new LoggedUser(JSON.parse(localStorage.getItem("user")!));
  // const { toast } = useToast()
  // const webSocketService = new WebSocketService();

  useEffect(() => {
    sharedStore.inAuthModule = false;
    // webSocketService.openWebSocket();
    getCustomers(paginationPage);
  }, []);

  const getCustomers = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    getInfoBaseFile(pageNumber).then(
      (resp: any) => {
        console.log(resp);
        if (resp.data.code === 200) {
          try {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > resp.data.size);
            const updatedContactList = new Array<InfoBase>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedContactList[i] = new InfoBase(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new InfoBase(
                  resp.data.data[i % paginationSize]
                );
              }
            }

            setContactList(updatedContactList);
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearchTag(e.target.value);
    setTimeout(() => {
      if (
        prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
        customerSearchTag.length > 2
      ) {
        setPaginatedPages(new Set());
        searchCampaign(1);
        setPaginationPage(1);
      } else if (
        prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
        prevCustomerSearchTag.length > 2
      ) {
        setPaginatedPages(new Set());
        setPaginationPage(1);
        getCustomers(1);
      }
    }, 300);
  };

  const searchCampaign = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    searchInfoBaseFile(customerSearchTag, pageNumber).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            if (pageNumber === 1) {
              setPagination(resp.data.count > paginationSize);
              const responseList = new Array<InfoBase>(resp.data.count);
              for (let i = 0; i < resp.data.data.length; i++) {
                responseList[i] = new InfoBase(resp.data.data[i]);
              }
              setContactList(responseList);
            } else {
              const updatedContactList = [...contactList];
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new InfoBase(
                  resp.data.data[i % paginationSize]
                );
              }
              setContactList(updatedContactList);
            }
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const viewCustomer = (index: number) => {
    setViewedInfobase(contactList[index]);
    setViewedInfobaseIndex(index);
    setIsViewInfobase(true);
  };

  const onInfobaseDelete = () => {
    const updatedContactList = [...contactList];
    updatedContactList.splice(viewedInfobaseIndex, 1);
    setContactList(updatedContactList);
    setIsViewInfobase(false);
  };

  return (
    <ScrollArea className="container mx-auto">
      {!isViewInfobase ? (
        <div className="bg-white p-6 flex flex-col gap-4">
          {/* Header Section */}
          <div className="flex justify-between align-center">
            <div className="flex gap-x-2 items-center mb-4">
              <h1 className="text-xl font-bold text-gray-800">Infobase</h1>
              <div className="text-sm text-gray-600">{contactList.length}</div>
            </div>
            {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.infobase?.edit) && <Button onClick={() => setIsAddInfobase(true)}>
              <Plus /> Add infobase
            </Button>}
          </div>
          <Dialog open={isAddInfobase} onOpenChange={setIsAddInfobase}>
            <DialogContent className="w-full max-h-[80%] max-w-[576px]">
              <AddInfobase
                onClose={() => setIsAddInfobase(false)}
                onAdd={(infobase: InfoBase) => {
                  setIsAddInfobase(false);
                  setContactList([infobase, ...contactList]);
                }}
              />
            </DialogContent>
          </Dialog>
          {/* Search Input */}
          {!(customerSearchTag === '' && contactList?.length === 0) && <div className="">
            <Input
              type="text"
              placeholder="Search Infobase"
              value={customerSearchTag}
              onChange={handleSearchInput}
              className="max-w-md"
            />
          </div>}

          {/* Table Section */}
          {isLoading ? (
            <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="w-full gap-4 flex flex-col">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton key={index} className="h-8 w-full" />
                  ))}
                </div>
              ))}
            </div>
          ) : contactList.length > 0 ? (
            <div className="overflow-x-auto border border-gray-300 rounded-lg">
              <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
                <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10 shadow-sm">
                  <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    <th
                      style={{ borderRadius: "8px 0 0 0" }}
                      className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                    >
                      Name
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Added
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Added by
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contactList.map((infobase, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                      onClick={() => {
                        viewCustomer(index);
                      }}
                    >
                      <td className="flex items-center gap-3 font-inter text-sm font-normal leading-6 text-left whitespace-nowrap text-primary py-4 px-2 min-w-[120px]">
                        {infobase?.name ?? "-"}
                        {infobase?.status === "in_progress" && (
                          <span
                            className="w-fit flex gap-2 items-center rounded px-2 whitespace-nowrap"
                            style={{
                              background: "#DBEAFE",
                              color: "#1D4ED8",
                              cursor: "pointer",
                            }}
                          >
                            Processing{" "}
                            <Loader2 size="16px" className="animate-spin" color="#1D4ED8"></Loader2>
                          </span>
                        )}
                        {infobase?.status === "error" && (
                          <span
                            className="w-fit flex gap-2 items-center rounded px-2 whitespace-nowrap"
                            style={{ background: "#FEE2E2", color: "#DC2626" }}
                          >
                            Failed
                          </span>
                        )}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {formatDateToMonthDayYear(infobase?.created_at)},{" "}
                        {formatTime(infobase?.created_at)}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {infobase?.user?.name && (
                          <span className="font-medium">
                            {infobase?.user?.name}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : customerSearchTag !== "" ? (
            <div className="w-full h-[600px] flex flex-col items-center justify-center gap-4 font-inter text-base font-medium leading-6 text-left text-[var(--primary-color)]">
            <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
                <img
                  src="../../../../assets/images/infobase_empty.png"
                  alt="No infobase found"
                />
                <span>No infobase were found matching your search</span>
              </div>
            </div>
          ) : (
            <div className="w-full h-[600px] flex flex-col items-center justify-center gap-4 font-inter text-base font-medium leading-6 text-left text-[var(--primary-color)]">
              <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
                <img
                  src="../../../../assets/images/infobase_empty.png"
                  alt="Start uploading files"
                />
                <span>Start uploading files to get started.</span>
              </div>
            </div>
          )}
          {pagination && !isLoading && (
            <div className="pagination-container">
              <Pagination
                currentPage={paginationPage}
                listLength={contactList.length}
                paginationSize={paginationSize}
                onPageChange={(page) => {
                  setPaginationPage(page);
                  customerSearchTag === ""
                    ? getCustomers(page)
                    : searchCampaign(page);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <ViewInfobase
          infobase={viewedInfobase}
          onClose={() => setIsViewInfobase(false)}
          onDelete={onInfobaseDelete}
        ></ViewInfobase>
      )}
    </ScrollArea>
  );
};

export default Infobase;
