import React, { useEffect, useState } from "react";
// import AgentView from "./AgentView"; // Import your AgentView component
import { AiAgent } from "../../models/ai-agent"; // Adjust imports for your data models
import { Button } from "../shadcn/button";
import { Plus } from "lucide-react";
import { useSharedStore } from "../../store/shared.store";
import { Characteristics } from "../../models/ai-agent";
import {
  get11LabsVoices,
  getAgentLanguages,
  getAiAgentCharacteristics,
  getAllAgents,
} from "../../lib/agents.utils";
import { ScrollArea } from "../shadcn/scrollArea";
import { Avatar, AvatarFallback, AvatarImage } from "../shadcn/avatar";
import { Skeleton } from "../shadcn/skeleton";
import AddAgent from "./AddAgent";
import {
  DialogHeader,
  DialogFooter,
  DialogClose,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/dialog";
import { Input } from "../shadcn/input";
import { Label } from "../shadcn/label";
import AgentView from "./AgentView";
import SelectAgentTemplate from "./SelectAgentTemplates";
import { getIntegrationsList } from "../../lib/common.utils";
import { useVapiStore } from "../../store/vapi.store";

const AgentsComponent: React.FC = () => {
  const [loaderTeamMemberList, setLoaderTeamMemberList] =
    useState<boolean>(true);
  const [teamMemberList, setTeamMemberList] = useState<AiAgent[]>([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState<AiAgent>();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loaderPagination, setLoaderPagination] = useState<boolean>(false);
  const [pageNext, setPageNext] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const loaderList = Array.from({ length: 10 });
  const [addAgent, setAddAgent] = useState<boolean>(false);
  const [selectTemplate, setSelectTemplate] = useState<boolean>(false);

  const sharedStore = useSharedStore();
  const vapiStore = useVapiStore();

  useEffect(() => {
    // websocketService.openWebSocket();
    sharedStore.inAuthModule = false;
    fetchTeamMembers(1);

    // Fetch other data (e.g., voices, languages, characteristics)
    // get11LabsVoices().then((response: any) => {
    //   sharedStore.voiceList = response.data;
    // });

    vapiStore.initializeVapi();

    getAgentLanguages().then((response: any) => {
      console.log(response);
      sharedStore.setLanguageList(response.data.data);
    });

    getAiAgentCharacteristics().then((response: any) => {
      console.log(response);
      let characteristics: any[] = [];
      response.data.data.forEach((item: any) =>
        characteristics.push(new Characteristics(item))
      );
      sharedStore.setAgentCharacteristics(characteristics);
    });

    getIntegrationsList()
  }, []);

  const fetchTeamMembers = async (page: number) => {
    setLoaderTeamMemberList(true);
    try {
      const response = await getAllAgents(page);
      const { count, next, data } = response.data;
      setTotalCount(count);
      setPageNext(next !== null);
      setTeamMemberList((prev) =>
        page === 1
          ? data.map((item: any) => new AiAgent(item))
          : [...prev, ...data.map((item: any) => new AiAgent(item))]
      );
      setPageNumber((prev) => prev + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderTeamMemberList(false);
    }
  };

  useEffect(() => {
    if(teamMemberList.length > 0 && !selectedTeamMember) {
      setSelectedTeamMember(teamMemberList[0]);
      setSelectedIndex(0);
    }
  }, [teamMemberList])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (
      scrollHeight - scrollTop - clientHeight < 50 &&
      pageNext &&
      !loaderPagination
    ) {
      fetchTeamMembers(pageNumber);
    }
  };

  const addAgentPopupOpen = () => {
    setAddAgent(true);
  };

  const handleAddAgent = (agent: AiAgent) => {
    setTeamMemberList((prev) => [agent, ...prev]);
    setSelectedTeamMember(agent);
    setTotalCount((prev) => prev + 1);
    setAddAgent(false);
    setSelectTemplate(false);
  };

  const handleDeleteAgent = () => {
    if (selectedIndex !== null) {
      setTeamMemberList((prev) =>
        prev.filter((_, index) => index !== selectedIndex)
      );
      setSelectedTeamMember(teamMemberList[0] || null);
      setSelectedIndex(0);
      setTotalCount((prev) => prev - 1);
    }
  };

  const handleDuplicateAgent = (agent: AiAgent) => {
    if (selectedIndex !== null) {
      setTeamMemberList((prev) => {
        const updatedList = [...prev];
        updatedList.splice(selectedIndex + 1, 0, agent);
        return updatedList;
      });
      setTotalCount((prev) => prev + 1);
    }
  };

  return (
    <div className="flex w-full h-screen overflow-hidden">
      <div className="w-[280px] h-full border-r border-gray-300">
        <div className="h-16 px-4 border-b border-gray-300 text-lg font-semibold flex items-center">
          Agents{" "}
          {totalCount > 0 && (
            <span className="text-sm font-medium text-gray-500 ml-2">
              {totalCount}
            </span>
          )}
        </div>
        <ScrollArea
          className="p-3 flex flex-col gap-2 overflow-auto h-[calc(100%-64px)]"
          onScroll={handleScroll}
        >
          {/*  */}

          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.edit) && <Button
            className="w-full mb-0.5"
            onClick={() => setSelectTemplate(true)}
            variant="default"
          >
            <Plus color="white" /> Add Agent
          </Button>}
          {selectTemplate && (
            <SelectAgentTemplate
              onClose={() => setSelectTemplate(false)}
              onBlankAgent={() => {
                setSelectTemplate(false);
                setAddAgent(true);
              }}
              onAdd={handleAddAgent}
            />
          )}

          <Dialog open={addAgent} onOpenChange={setAddAgent}>
            <DialogContent className="h-full w-full max-h-[300px] max-w-[450px]">
              {addAgent && (
                <AddAgent
                  onClose={() => setAddAgent(false)}
                  onAdd={handleAddAgent}
                />
              )}
            </DialogContent>
          </Dialog>

          {!loaderTeamMemberList ? (
            <div className="flex-col flex gap-y-2 mt-2">
              {teamMemberList.map((agent, index) => (
                <div
                  key={agent.id}
                  onClick={() => {
                    setSelectedTeamMember(agent);
                    setSelectedIndex(index);
                  }}
                  className={`flex items-center justify-between p-2 rounded-md cursor-pointer hover:bg-gray-100 ${
                    selectedTeamMember?.id === agent.id ? "bg-gray-100" : ""
                  }`}
                >
                  <div className="flex items-center gap-2 ">
                    <Avatar className="h-6 w-6 rounded-full overflow-hidden">
                      <AvatarImage src={agent?.profile_pic} />
                      <AvatarFallback>
                        {agent?.agent_name?.slice(0, 2)}
                      </AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col gap-0.5 overflow-hidden">
                      <span
                        className={
                          "truncate " +
                          (agent.number || agent.live_widget_count
                            ? "max-w-[160px]"
                            : "max-w-[210px]")
                        }
                      >
                        {agent.agent_name}
                      </span>
                      <span 
                      className={
                        "text-sm text-secondary truncate " +
                        (agent.number || agent.live_widget_count
                          ? "max-w-[160px]"
                          : "max-w-[210px]")
                      }>
                        {agent.title}
                      </span>
                    </div>
                  </div>
                  {agent.number || agent.live_widget_count ? (
                    <span className="text-xs bg-purple-100 text-purple-800 border border-gray-300 px-2 py-1 rounded-md">
                      Live
                    </span>
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            loaderList.map((_, index) => (
              <div key={index} className="flex items-center gap-2 mt-2">
                <Skeleton className="w-12 h-12 bg-gray-300 rounded-full"></Skeleton>
                <div className="flex flex-col gap-1 w-[200px]">
                  <Skeleton className="h-4 bg-gray-300 rounded w-3/4"></Skeleton>
                  <Skeleton className="h-4 bg-gray-300 rounded w-1/2"></Skeleton>
                </div>
              </div>
            ))
          )}
        </ScrollArea>
      </div>
      {/* <div className="">
        {addAgent ? <AddAgent /> : null}
      </div> */}
      <div className="flex-1">
        {
          <AgentView
            agent={selectedTeamMember ?? new AiAgent(selectedTeamMember)}
            onDelete={handleDeleteAgent}
            onChange={(updatedAgent: AiAgent) => {
              if (selectedIndex !== null) {
                setTeamMemberList((prev) => {
                  const updatedList = [...prev];
                  updatedList[selectedIndex] = updatedAgent;
                  return updatedList;
                });
              }
            }}
            onDuplicate={handleDuplicateAgent}
          />
        }
      </div>
    </div>
  );
};

export default AgentsComponent;
