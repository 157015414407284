import { ghlOnboarding } from '../../../lib/authentication.utils';
import React, { useEffect, useState } from 'react';
import Toaster from '../../shared/customToast';
import { useAgencyStore } from '../../../store/agency.store';
import { useWebSocketStore } from '../../../store/websocket.store';
import { useNavigate, useLocation } from 'react-router-dom';
import ConfirmationPopup from '../../shared/ConfirmationPopup';
import JsonText from '../../../static-text.json';
import { deleteIntegration, deleteSubAccountIntegration, getConnectedGhlSubAccount, getGhlCalendars, getGhlLocations, getIntegrations } from '../../../lib/integrations.utils';
import useSharedStore from '../../../store/shared.store';
import { Button } from '../../shadcn/button';
import { ArrowLeft, Check, Loader2 } from 'lucide-react';
import { Skeleton } from '../../shadcn/skeleton';


const GhlView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const staticTexts = JsonText.integrations;

  const websocketStore = useWebSocketStore();
  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();
  const [ghl, setGhl] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState('accounts');
  const [loader, setLoader] = useState(false);
  const [ghlCalendarList, setGhlCalendarList] = useState([]);
  const [uninstallPopup, setUninstallPopup] = useState(false);
  const [integration, setIntegration] = useState<any>(null);
  const [connectSubAccountPopup, setConnectSubAccountPopup] = useState(false);
  const [agencyBusinesses, setAgencyBusinesses] = useState([]);
  const [ghlLocations, setGhlLocations] = useState([]);
  const [loaderGetConnectedAccounts, setLoaderGetConnectedAccounts] = useState(false);
  const [connectedSubAccountsList, setConnectedSubAccountsList] = useState<any[]>([]);
  const [loaderDeleteSubAccount, setLoaderDeleteSubAccount] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      setLoader(true);
      ghlOnboarding({ code }).then(resp => {
        Toaster.success(resp.data.message);
        getIntegrationsList();
      }).catch(error => {
        Toaster.error(error);
      });
    } else {
      getIntegrationsList();
    }

    websocketStore.integrationEvents$.subscribe(event => {
      if (event.provider === 'ghl') {
        getIntegrationsList();
      }
    });
  }, [location.search]);

  const getAgencyBusiness = () => {
    agencyStore.getAgencyBusiness().then(resp => {
      setAgencyBusinesses(resp.data.data);
    }).catch(error => {
      Toaster.error(error);
    });
  };

  const getGhlLocationsList = () => {
    getGhlLocations().then(resp => {
      setGhlLocations(resp.data.data);
    }).catch(error => {
      Toaster.error(error);
    });
  };

  const getConnectedGhlSubAccountList = () => {
    setLoaderGetConnectedAccounts(true);
    getConnectedGhlSubAccount().then(resp => {
      setConnectedSubAccountsList(resp.data.data);
      setLoaderGetConnectedAccounts(false);
    }).catch(error => {
      Toaster.error(error);
      setLoaderGetConnectedAccounts(false);
    });
  };

  const getIntegrationsList = () => {
    setLoader(true);
    getIntegrations().then(resp => {
      const integrations = resp.data.data;
      integrations.forEach((element: any) => {
        if (element.provider === 'ghl') {
          setIntegration(element);
          setGhl(element.ghl);
          // getGhlCalendarsList();
          if (sharedStore.business?.subscription?.white_label) getAgencyBusiness();
          if (!element.ghl?.location_id) getGhlLocationsList();
          if (sharedStore.business?.subscription?.white_label) getConnectedGhlSubAccountList();
        }
      });
      setLoader(false);
    }).catch(error => {
      setLoader(false);
    });
  };

  const getGhlCalendarsList = () => {
    getGhlCalendars().then(resp => {
      setGhlCalendarList(resp.data.data);
    }).catch(error => {
      Toaster.error(JSON.stringify(error?.message ?? error));
    });
  };

  const installGhl = () => {
    const width = 500;
    const height = 400;
    const screenLeft = window.screenLeft ?? window.screenX;
    const screenTop = window.screenTop ?? window.screenY;
    const screenWidth = window.innerWidth ?? document.documentElement.clientWidth ?? window.screen.width;
    const screenHeight = window.innerHeight ?? document.documentElement.clientHeight ?? window.screen.height;
    const left = screenLeft + (screenWidth - width) / 2;
    const top = screenTop + (screenHeight - height) / 2;
    const token = localStorage.getItem('token');
    window.open(
      `${agencyStore.defaultGhl ? 'https://app.oneinbox.ai' : window.location.origin}/hl-onboarding?token=${token}&ghl_client_id=${agencyStore.ghlClientId}`,
      "popupWindow",
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  const backToList = () => {
    navigate('/settings/integrations');
  };

  const uninstallGhl = () => {
    setUninstallPopup(false);
    setGhl(null);
    deleteIntegration(integration?.id ? integration?.id : 0).then((resp: any) => {
      Toaster.success(resp.data.message);
    }).catch((error: string) => {
      Toaster.error(error);
    });
  };

  const copyLocation = () => {
    const copyString = ghl?.location_id;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      // Show tooltip
    });
  };

  const onSubAccountsConnected = () => {
    setConnectSubAccountPopup(false);
    if (ghl?.location_id) {
      getAgencyBusiness();
      getGhlLocationsList();
      getConnectedGhlSubAccountList();
    } else {
      getIntegrationsList();
    }
  };

  const uninstallSubAccount = (index: number) => {
    setLoaderDeleteSubAccount(connectedSubAccountsList[index]?.id);
    deleteSubAccountIntegration(connectedSubAccountsList[index]?.id).then((resp: { data: { message: string; }; }) => {
      Toaster.success(resp.data.message);
      setConnectedSubAccountsList(prev => prev.filter((_, i) => i !== index));
      setLoaderDeleteSubAccount(0);
    }).catch((error: string) => {
      Toaster.error(error);
      setLoaderDeleteSubAccount(0);
    });
  };

  return (
    <div className="main-container">
      <div className="flex justify-between items-center h-16 p-4 border-b border-gray-300 text-primary">
      <div className="flex items-center gap-2 text-primary font-inter text-lg font-medium leading-7 ">
        <span style={{ cursor: 'pointer' }} onClick={backToList}>
        <ArrowLeft className="text-gray-600" size="24px" />
        </span>
        Integrations
      </div>
      </div>
      {loader ? (
      <div className="loader-container flex justify-center p-8">
        <Loader2 size={'16px'} />
      </div>
      ) : (
      <div className="agent-details flex flex-col gap-4 p-6 overflow-y-scroll">
        {!ghl ? (
        <>
          <div className="ghl-heading flex items-center gap-8">
          <div className="flex items-center gap-4">
            <span className="card-image-status flex justify-between items-center h-12 w-12">
            <img src="../../../../assets/images/gohighlevel.png" alt="" className="w-12 h-12 object-cover" />
            </span>
            <div className="ver-8px flex flex-col gap-2">
            <span className="heading text-2xl font-normal text-primary">HighLevel</span>
            <span className="tag bg-gray-100 rounded px-2 py-1 text-sm font-medium text-primary w-fit">Calendar</span>
            </div>
          </div>
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && <Button onClick={installGhl}>Install app</Button>}
          </div>
          <div className="card-fields flex flex-col gap-1">
          <span className="field-name text-lg font-medium text-primary">Website</span>
          <a href="https://www.gohighlevel.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">www.gohighlevel.com</a>
          </div>
          <div className="card-fields flex flex-col gap-1">
          <span className="field-name text-lg font-medium text-primary">Overview</span>
          <span className="subheading text-sm font-normal text-secondary">
            The {agencyStore?.brand} integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting {agencyStore?.brand}’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.
          </span>
          </div>
          <div className="card-fields flex flex-col gap-1">
          <span className="field-name text-lg font-medium text-primary">Key Benefits</span>
          <span className="subheading ver-2px flex flex-col gap-0.5 text-sm font-normal text-secondary">
            <span className="col-pri text-primary">Automated Appointment Management</span>
            Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.
            <span className="col-pri text-primary">Streamlined Client Interactions</span>
            Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.
            <span className="col-pri text-primary">Integrated Workflows</span>
            Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.
          </span>
          </div>
        </>
        ) : (
        <>
          <div className="connected-ghl-heading flex justify-between items-center mb-4">
          <div className="flex items-center gap-4">
            <span className="card-image-status flex justify-between items-center h-12 w-12">
            <img src="../../../../assets/images/gohighlevel.png" alt="" className="w-12 h-12 object-cover" />
            </span>
            <div className="ver-8px flex flex-col gap-2">
            <span className="heading-name text-2xl font-normal text-primary">HighLevel</span>
            <span className="tag bg-gray-100 rounded px-2 py-1 text-sm font-medium text-primary w-fit">Calendar</span>
            </div>
          </div>
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.delete) && <div className="flex items-center gap-4">
            <Button variant="destructive" onClick={() => setUninstallPopup(true)}>
            Uninstall
            </Button>
          </div>}
          </div>
          <div className="agent-details-tab flex items-center justify-between border-b border-gray-300">
          <div className="agent-details-tab-group flex items-center">
            <div className={` ${currentTab === 'accounts' ? 'border-b-[3px] border-b-[var(--primary-color)]' : 'border-b-[3px] border-b-transparent'} flex items-center flex-col cursor-pointer`} onClick={() => setCurrentTab('accounts')}>
            <span className="px-3 pb-2.5">Accounts</span>
            </div>
            {sharedStore?.business?.subscription?.white_label && !ghl?.location_id && (
            <div className={` ${currentTab === 'subaccounts' ? 'border-b-[3px] border-b-[var(--primary-color)]' : 'border-b-[3px] border-b-transparent'} flex items-center flex-col cursor-pointer`} onClick={() => setCurrentTab('subaccounts')}>
              <span className="px-3 pb-2.5">Sub accounts</span>
            </div>
            )}
            <div className={` ${currentTab === 'info' ? 'border-b-[3px] border-b-[var(--primary-color)]' : 'border-b-[3px] border-b-transparent'} flex items-center flex-col cursor-pointer`} onClick={() => setCurrentTab('info')}>
            <span className="px-3 pb-2.5">Info</span>
            </div>
          </div>
          </div>
          {currentTab === 'accounts' && (
          <div className="ghl-account-details border border-gray-300 bg-gray-50 rounded p-4 flex flex-col gap-4">
            {ghl?.location_id ? (
            <div className="hor-gap flex items-center justify-between">
              <span className="field-name text-lg font-medium text-primary">{ghl?.location?.name}</span>
              <div className="hor-8px flex items-center gap-2">
              <span className="green-label flex items-center gap-1 bg-green-100 px-2 py-1 text-sm font-medium text-green-700 rounded">
                <Check className="text-green-700" size="16px" /> Installed
              </span>
              </div>
            </div>
            ) : (
            <div className="hor-gap flex items-center justify-between">
              <span className="field-name text-lg font-medium text-primary">Company id: {ghl?.company_id}</span>
              {sharedStore?.business?.subscription?.white_label && (
              <Button variant="outline" onClick={() => setConnectSubAccountPopup(true)}>
                Connect sub-accounts
              </Button>
              )}
            </div>
            )}
          </div>
          )}
          {currentTab === 'subaccounts' && (
          <div className="contacts-list border border-gray-300 rounded">
            {!loaderGetConnectedAccounts && connectedSubAccountsList.length > 0 ? (
            <table className="w-full rounded overflow-hidden">
              <thead>
              <tr className="table-heading-row border-b border-gray-300">
                <th className="table-heading p-2 bg-gray-100 text-xs font-medium text-secondary">Business name</th>
                <th className="table-heading p-2 bg-gray-100 text-xs font-medium text-secondary">GHL sub account</th>
                <th className="table-heading p-2 bg-gray-100 text-xs font-medium text-secondary">Email</th>
                <th className="table-heading p-2 bg-gray-100 text-xs font-medium text-secondary">Location ID</th>
                <th className="table-heading p-2 bg-gray-100 text-xs font-medium text-secondary"></th>
              </tr>
              </thead>
              <tbody>
              {connectedSubAccountsList.map((account, i) => (
                <tr className="table-row border-b border-gray-300 hover:bg-gray-100" key={i}>
                <td className="table-cell p-4 text-sm font-normal text-primary">{account?.business?.name}</td>
                <td className="table-cell p-4 text-sm font-normal text-primary">{account?.ghl?.location?.name}</td>
                <td className="table-cell p-4 text-sm font-normal text-primary">{account?.ghl?.location?.email}</td>
                <td className="table-cell p-4 text-sm font-normal text-primary">{account?.ghl?.location_id}</td>
                <td className="table-cell p-4 text-sm font-normal text-primary">
                  <Button variant="destructive" onClick={() => uninstallSubAccount(i)}>
                  {loaderDeleteSubAccount === account?.id ? (
                    <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Please wait
                    </>
                  ) : (
                    "Uninstall"
                  )}
                  </Button>
                </td>
                </tr>
              ))}
              </tbody>
            </table>
            ) : loaderGetConnectedAccounts ? (
            <div className="loader-container flex flex-col gap-4">
              <span className="skeleton-loader w-full"><Skeleton className="h-4 bg-gray-300 rounded w-full" /></span>
              <span className="skeleton-loader w-full"><Skeleton className="h-4 bg-gray-300 rounded w-full" /></span>
              <span className="skeleton-loader w-full"><Skeleton className="h-4 bg-gray-300 rounded w-full" /></span>
              <span className="skeleton-loader w-full"><Skeleton className="h-4 bg-gray-300 rounded w-full" /></span>
            </div>
            ) : (
            <div className="empty-screen flex flex-col items-center justify-center gap-4 text-lg font-medium text-primary">
              <div className="empty-card flex flex-col items-center justify-center gap-4 text-sm font-medium">
              <span>No sub accounts found</span>
              </div>
            </div>
            )}
          </div>
          )}
          {currentTab === 'info' && (
          <div className="ghl-info ver-1rem flex flex-col gap-4">
            <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Website</span>
            <a href="https://www.gohighlevel.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">www.gohighlevel.com</a>
            </div>
            <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Overview</span>
            <span className="subheading text-sm font-normal text-secondary">
              The {agencyStore?.brand} integration with HighLevel empowers agencies to streamline client interactions and calendar management. By connecting {agencyStore?.brand}’s advanced AI calling capabilities with HighLevel’s robust CRM and calendar tools, businesses can automate scheduling, client follow-ups, and appointment reminders. This integration simplifies workflows and enhances team productivity, even in high-demand scenarios.
            </span>
            </div>
            <div className="card-fields flex flex-col gap-1">
            <span className="field-name text-lg font-medium text-primary">Key Benefits</span>
            <span className="subheading ver-2px flex flex-col gap-0.5 text-sm font-normal text-secondary">
              <span className="col-pri text-primary">Automated Appointment Management</span>
              Allow AI-powered agents to schedule and reschedule appointments directly within HighLevel, leveraging real-time calendar availability for seamless booking.
              <span className="col-pri text-primary">Streamlined Client Interactions</span>
              Enhance customer satisfaction with 24/7 AI support, ensuring clients can book and update appointments whenever needed.
              <span className="col-pri text-primary">Integrated Workflows</span>
              Sync appointments across HighLevel calendars, ensuring your agency operates with up-to-date information without manual effort.
            </span>
            </div>
          </div>
          )}
        </>
        )}
      </div>
      )}
      {uninstallPopup && (
      <ConfirmationPopup
        heading="Confirm uninstall HighLevel"
        description="Uninstalling the HighLevel app will also delete all calendar actions associated with your account."
        negativeButtonText="No, keep HighLevel"
        positiveButtonText="Yes, uninstall HighLevel"
        onClose={() => setUninstallPopup(false)}
        onSuccess={uninstallGhl}
      />
      )}
      {/* {connectSubAccountPopup && (
      <GhlConnectSubaccount
        businesses={agencyBusinesses}
        locations={ghlLocations}
        onClose={() => setConnectSubAccountPopup(false)}
        onSuccess={onSubAccountsConnected}
      />
      )} */}
    </div>
  );
};

export default GhlView;
