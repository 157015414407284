import { Params } from 'react-router-dom';
import apiClient from '../interceptors/axiosApiInterceptor';

export const getBusiness = () =>
  apiClient.get('/business/');

export const updateBusiness = (obj: any) =>
  apiClient.post('/business/', obj);

export const updatePersonalProfile = (obj: any) =>
  apiClient.put('/user/me/', obj);

export const getBusinessCategories = () =>
  apiClient.get('/business/categories');

export const getApiKeys = () =>
  apiClient.get('/user/api-keys');

export const generateApiKeys = () =>
  apiClient.post('/user/api-keys', {});

export const deleteApiKey = (id: number) =>
  apiClient.delete(`/user/api-keys/${id}`);

export const getWhatsappDetails = () =>
  apiClient.get('/channel/whatsapp');

export const updateWhatsappDetails = (obj: any, waba_id: string) =>
  apiClient.put(`/channel/whatsapp/${waba_id}`, obj);

export const uploadKycDocuments = (obj: any) =>
  apiClient.post('/kyc/', obj);

export const getKycDocuments = () =>
  apiClient.get('/kyc');

export const updatePersonalStatus = (obj: any) =>
  apiClient.put('/user/me/status', obj);

export const downloadKycDocs = (obj: any) =>
  apiClient.put('/kyc/download', obj);

export const getMiscellaneous = () =>
  apiClient.get('/miscellaneous');

export const getActivityLogs = (params: any) =>
  apiClient.get('/activity', { params });

export const getMessagingHealthStatus = (id: string) =>
  apiClient.get(`/channel/health-status/${id}`);

export const getWelcomeMessage = () =>
  apiClient.get('/channel/whatsapp/components/?welcome_message=true');

export const getIceBreakers = () =>
  apiClient.get('/channel/whatsapp/components/?icebreakers=true');

export const updateWhatsAppComponents = (obj: any) =>
  apiClient.post('/channel/whatsapp/components/', obj);

export const getBusinessSettings = () =>
  apiClient.get('/business/settings');

export const addBusinessSettings = (obj: any) =>
  apiClient.post('/business/settings', obj);

export const getCustomProperty = () =>
  apiClient.get('/business/custom-property');

export const addCustomProperty = (obj: any) =>
  apiClient.post('/business/custom-property', obj);

export const updateCustomProperty = (obj: any) =>
  apiClient.put('/business/custom-property', obj);

export const deleteCustomProperty = (name: string) =>
  apiClient.delete(`/business/custom-property?field=${name}`);

export const getLogs = (params: Params) =>
  apiClient.get('/business/webhook-logs', { params });
