import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { Textarea } from "../shadcn/textarea";
import { Button } from "../shadcn/button";

interface CallFeedbackProps {
  onClose: () => void;
  onSuccess: (feedback: string) => void;
}

const CallFeedback: React.FC<CallFeedbackProps> = ({ onClose, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onSuccess(reason);
      setIsOpen(false);
    }, 1000);
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => {setIsOpen(false); onClose()}}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-[400px]">
        <div className="flex flex-col gap-1">
          <span className="text-[var(--primary-color)] font-inter font-semibold text-20 leading-30">
            Give Feedback
          </span>
        <span className="text-gray-500 text-[14px]">Explain what went wrong with the performance of the AI agent.</span>
        </div>
        <Textarea
          className="h-[100px] w-full border rounded-md p-2"
          placeholder="Type your feedback here..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div className="flex justify-end">
          <Button disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? "Submitting..." : "Submit Feedback"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CallFeedback;
