"use client";

import * as React from "react";
import {
  AudioWaveform,
  BadgeCheck,
  Bell,
  Blocks,
  BookOpen,
  Bot,
  Brain,
  ChartArea,
  ChartPie,
  ChevronDown,
  ChevronRight,
  ChevronsUpDown,
  Command,
  CreditCard,
  Folder,
  Forward,
  Frame,
  GalleryVerticalEnd,
  HelpCircle,
  LogOut,
  Map,
  MoreHorizontal,
  Phone,
  PieChart,
  Plus,
  ScanText,
  Settings,
  Settings2,
  Sparkles,
  SquareTerminal,
  Trash2,
  User,
  UserRound,
  Users,
} from "lucide-react";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "./components/shadcn/avatar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "./components/shadcn/breadcrumb";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./components/shadcn/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "./components/shadcn/dropdown-menu";
import { Separator } from "./components/shadcn/separator";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
  SidebarTrigger,
} from "./components/shadcn/sidebar";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAgencyStore } from "./store/agency.store";
import useSharedStore from "./store/shared.store";
import WebSocketComponent from "./Websocket";
import {
  getBusinessData,
  getInboxesData,
  getStripeKeys,
  getWidgetsData,
  logout,
} from "./lib/common.utils";
import { setupInterceptors } from "./interceptors/axiosApiInterceptor";
import { useWalletStore } from "./store/wallet.store";
import { Slider } from "./components/shadcn/slider";
import { Progress } from "./components/shadcn/progress";
import { useWebSocketStore } from "./store/websocket.store";
import { getUserProfile } from "./lib/authentication.utils";
import { LoggedUser } from "./models/logged-user";
import { useSubscriptionStore } from "./store/subscription.store";
import { setGlobalNavigate } from "./lib/navigate.utils";
import Intercom, { hide, show } from "@intercom/messenger-js-sdk";
import { getAllAgents } from "./lib/agents.utils";
import { AiAgent } from "./models/ai-agent";
import { useRetellStore } from "./store/retell.store";
import LiveCall from "./components/shared/LiveCall";
import CustomIcon from "./components/shared/CustomIcon";
import { ScrollArea } from "./components/shadcn/scrollArea";
import ProfileIcon from "./components/shared/ProfileIcon";
import DialerPopup from "./components/shared/Dialer";
import { formatToE164 } from "./lib/ui.utils";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import posthog from "posthog-js";

posthog.init("phc_j64rRtkbfXKpnwDRp9vmhAlB2PJeMLi2nIbxrW78JI7", {
  api_host: "https://app.posthog.com",
});

function App() {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  if (!isLoaded) {
    setIsLoaded(true);
    setupInterceptors(navigate);
  }

  const location = useLocation();
  const [user, setUser] = useState<LoggedUser | null>(new LoggedUser(JSON.parse(localStorage.getItem('user')!)));

  // List of routes without a sidebar
  const noSidebarRoutes = [
    "/",
    "/login",
    "/signup",
    "/onboarding",
    "/welcome",
    "/hl-onboarding",
    "/google-login",
    "/subscription-expired",
    "/onboarding-plans",
    "/invite/",
    "/invite/?invite_code=",
  ];

  // Determine if the current route should have a sidebar
  const showSidebar = !noSidebarRoutes.includes(location.pathname);
  const [minimizeSidebar, setMinimizeSidebar] = useState(true);

  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();
  const walletStore = useWalletStore();
  const subscriptionStore = useSubscriptionStore();
  const retellStore = useRetellStore();
  const [agentsList, setAgentsList] = useState<AiAgent[]>([]);

  useEffect(() => {
    const currentDomain = window.location.origin;
    const domainName = new URL(currentDomain).hostname;
    console.log(domainName);

    if (currentDomain.includes("https://cname.oneinbox.ai")) {
      navigate("/invalid");
      return;
    }

    if (
      currentDomain.includes("https://app.oneinbox.ai") ||
      currentDomain.includes("https://dev-react.oneinbox.ai") ||
      currentDomain.includes("https://dev.oneinbox.ai")
    ) {
      agencyStore.setIsAgency(false);
      initialLoad(); // Assuming this function exists in your component
    } else {
      fetch(
        `${process.env.REACT_APP_DOMAIN_ENDPOINT_JSON}${domainName.replaceAll(
          ".",
          "_"
        )}.json`
      )
        .then((resp) => resp.json())
        .then((resp) => {
          sharedStore.setApiUrl(resp.domain);
          initialLoad(); // Assuming this function exists in your component
        })
        .catch((error) => {
          // console.error("Error fetching or parsing JSON:", error);
          initialLoad(); // Assuming this function exists in your component
        });
    }
  }, []);

  useEffect(() => {
    setGlobalNavigate(navigate);
  }, [navigate]);

  const initialLoad = async () => {
    agencyStore
      .getAssets()
      .then((resp) => {
        const data = resp;
        console.log(data);

        if (data.brand) {
          agencyStore.setBrand(data.brand);
          document.title = data.brand; // Set title
        }
        if (data.dark_logo) agencyStore.setDarkLogo(data.dark_logo);
        if (data.light_logo) agencyStore.setLightLogo(data.light_logo);

        agencyStore.setTermsConditions(
          data?.terms_and_condition || "https://oneinbox.ai/terms"
        );
        agencyStore.setPrivacyPolicy(
          data?.privacy_policy || "https://oneinbox.ai/terms"
        );

        if (data.favicon) {
          agencyStore.setFavicon(data.favicon);
          changeFavicon(data.favicon);
        }
        if (data.support_email_id)
          agencyStore.setSupportEmail(data.support_email_id);
        agencyStore.setIsPricingEnabled(data.plans_and_billings);
        if (data.ghl_cliend_id) agencyStore.setGhlClientId(data.ghl_cliend_id);
        if (data.google_client_id)
          agencyStore.setGoogleClientId(data.google_client_id);
        agencyStore.setDefaultGhl(data?.default_ghl ? true : false);
        agencyStore.setDefaultGoogleCal(data?.default_google ? true : false);
      })
      .catch((error) => {
        console.error("Error fetching agency assets:", error);
      });

    handleQueryParams();

    if (localStorage.getItem("token")) {
      getInboxesData();
      getBusinessData();
      getWidgetsData();
      getStripeKeys();

      if(user?.user_type === 1 || sharedStore.permissions?.ai_agents?.view) getAllAgents(1)
        .then((resp) => {
          let agents = [];
          for (var index = 0; index < resp.data.data?.length; index++) {
            agents.push(new AiAgent(resp.data.data[index]));
          }
          setAgentsList(agents);
        })
        .catch((error) => {
          console.log(error);
        });

        if(user?.user_type === 1) getWallet();
    }

    sharedStore.setDialerPopup(false);

    if (user?.id) {
      Intercom({
        app_id: "irngw58q",
        user_id: user.id.toString(),
        name: user.name,
        email: user.email,
        created_at: new Date().getTime() / 1000, // Convert to Unix timestamp (seconds)
      });

      const intercomContainer = document.getElementById(
        "intercom-container-body"
      );
      if (intercomContainer) {
        intercomContainer.style.display = "none";
      }
    }
  };

  const getWallet = () => {
    walletStore
      .getWallet()
      .then((resp) => {
        console.log(resp);
        walletStore.setWalletBalance(
          Math.floor(resp.data.paid_mins_consumed + resp.data.sub_mins_consumed)
        );
        walletStore.setTotalMinutes(
          Math.floor(resp.data.paid_total_mins + resp.data.sub_total_mins)
        );
      })
      .catch((error) => {
        console.error("Error fetching wallet data:", error);
      });
  };

  const handleQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("client_token")) {
      sessionStorage.setItem(
        "client_token",
        `JWT ${params.get("client_token")}`
      );
      sharedStore.isLoggedIn = true;
      getUserProfile()
        .then((resp) => {
          if (resp.data.code === 200) {
            localStorage.setItem("user", JSON.stringify(resp.data.data));
            setUser(new LoggedUser(resp.data.data)); // Assuming LoggedUser class exists
            sharedStore.setLoggedUser(new LoggedUser(resp.data.data));
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    } else if (localStorage.getItem("token")) {
      getUserProfile()
        .then((resp) => {
          if (resp.data.code === 200) {
            localStorage.setItem("user", JSON.stringify(resp.data.data));
            setUser(new LoggedUser(resp.data.data));
            sharedStore.setLoggedUser(new LoggedUser(resp.data.data));
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    }
  };

  const openIntercom = () => {
    show();
  };

  return (
    <SidebarProvider open={minimizeSidebar}>
      {showSidebar ? (
        <Sidebar collapsible="icon" className="bg-[#F8FAFC]">
          <SidebarHeader
            className={`h-16 flex items-center gap-1 font-inter text-lg font-medium leading-7 text-left text-primary ${
              !minimizeSidebar ? "py-4 px-2" : "p-4"
            }`}
          >
            <SidebarMenu>
              <SidebarMenuItem>
                <img
                  src={
                    !minimizeSidebar
                      ? agencyStore.favicon
                      : agencyStore.darkLogo
                  }
                  alt=""
                  className="h-8"
                />
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarHeader>
          <SidebarContent className="flex flex-col gap-0.5">
            <ScrollArea className="flex flex-col gap-0.5">
              <SidebarGroup
                className={`${!minimizeSidebar ? "p-[6px]" : "py-3 px-4"}`}
              >
                <SidebarMenu>
                  {!(
                    sharedStore.business?.is_agent_created &&
                    sharedStore.business?.is_agent_test &&
                    sharedStore.business?.is_agent_deployed &&
                    sharedStore.business?.is_live
                  ) && (
                    <SidebarMenuItem
                      onClick={() => navigate("/getting-started")}
                      className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                        location.pathname === "/getting-started"
                          ? "bg-[#F3F4F6]"
                          : "hover:bg-[#F3F4F6]"
                      }`}
                    >
                      <SidebarMenuButton
                        className="h-10 p-3"
                        tooltip="Getting Started"
                      >
                        <div className="w-[20px] h-[20px]">
                          <CircularProgressbar
                            value={
                              (sharedStore.business?.is_agent_created
                                ? 25
                                : 0) +
                              (sharedStore.business?.is_agent_test ? 25 : 0) +
                              (sharedStore.business?.is_agent_deployed
                                ? 25
                                : 0) +
                              (sharedStore.business?.is_live ? 25 : 0)
                            }
                            strokeWidth={10}
                            styles={buildStyles({
                              pathColor: "var(--primary-color)",
                            })}
                          />
                        </div>
                        <span>Getting Started</span>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  )}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.view) && <SidebarMenuItem
                    onClick={() => navigate("/agents")}
                    className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                      location.pathname === "/agents"
                        ? "bg-[#F3F4F6]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <SidebarMenuButton className="h-10 p-3" tooltip="Agents">
                      <CustomIcon iconName="aiagents" />
                      <span>AI Agents</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.analytics?.view) && <SidebarMenuItem
                    onClick={() => navigate("/analytics")}
                    className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                      location.pathname === "/analytics"
                        ? "bg-[#F3F4F6]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <SidebarMenuButton className="h-10 p-3" tooltip="Analytics">
                      <ChartArea />
                      <span>Analytics</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.contacts?.view) && <SidebarMenuItem
                    onClick={() => navigate("/contacts")}
                    className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                      location.pathname === "/contacts"
                        ? "bg-[#F3F4F6]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <SidebarMenuButton className="h-10 p-3" tooltip="Contacts">
                      <User />
                      <span>Contacts</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.infobase?.view) && <SidebarMenuItem
                    onClick={() => navigate("/infobase")}
                    className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                      location.pathname === "/infobase"
                        ? "bg-[#F3F4F6]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <SidebarMenuButton className="h-10 p-3" tooltip="Infobase">
                      <Brain />
                      <span>Infobase</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.view) && <SidebarMenuItem
                    onClick={() => navigate("/settings/phone-numbers")}
                    className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-semibold leading-5 text-left text-primary cursor-pointer ${
                      location.pathname === "/settings"
                        ? "bg-[#F3F4F6]"
                        : "hover:bg-[#F3F4F6]"
                    }`}
                  >
                    <SidebarMenuButton className="h-10 p-3" tooltip="Settings">
                      <Settings />
                      <span>Settings</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>}
                </SidebarMenu>
                <SidebarMenu>
                  <Collapsible
                    asChild
                    defaultOpen={true}
                    className="group/collapsible"
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton
                          tooltip="Inboxes"
                          className="font-medium text-[var(--secondary-color)]"
                        >
                          <ChevronRight className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                          <span>Inboxes</span>
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub className="flex flex-col m-0 p-0 max-h-48 gap-1 overflow-auto">
                          <ScrollArea className="flex flex-col">
                            {Array.isArray(sharedStore.cloudNumbers) &&
                              sharedStore.cloudNumbers.map(
                                (number) =>
                                  number?.is_active && (
                                    <SidebarMenuSubItem
                                      key={number?.id}
                                      onClick={() =>
                                        navigate(`/inbox/${number?.id}`)
                                      }
                                      className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-medium leading-5 text-left text-secondary cursor-pointer p-1 hover:bg-gray-100 ${
                                        location.pathname ===
                                        `/inbox/${number?.id}`
                                          ? "bg-gray-100"
                                          : ""
                                      }`}
                                    >
                                      <span className="w-9 h-9 bg-gray-200 rounded flex items-center justify-center text-lg relative">
                                        {number?.icon}
                                        <span className="w-3.5 h-3.5 bg-white rounded-full flex items-center justify-center absolute bottom-[-1px] right-[-1px]">
                                          <Phone size={"10px"} />
                                        </span>
                                      </span>
                                      <span className="flex flex-col">
                                        <span className="text-primary text-sm font-normal">
                                          {number?.name}
                                        </span>
                                        <span className="text-secondary text-xs font-normal">
                                          {formatToE164(number?.number)}
                                        </span>
                                      </span>
                                    </SidebarMenuSubItem>
                                  )
                              )}
                            {Array.isArray(sharedStore.widgetList) &&
                              sharedStore.widgetList.map((widget) => (
                                <SidebarMenuSubItem
                                  key={widget?.id}
                                  onClick={() =>
                                    navigate(`/inbox?widget=${widget?.id}`)
                                  }
                                  className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-medium leading-5 text-left text-secondary cursor-pointer p-1 hover:bg-gray-100 ${
                                    location.pathname ===
                                    `/inbox?widget=${widget?.id}`
                                      ? "bg-gray-100"
                                      : ""
                                  }`}
                                >
                                  <span className="w-9 h-9 bg-gray-200 rounded flex items-center justify-center text-lg relative">
                                    {widget?.logo ? (
                                      <img
                                        className="w-5 h-5"
                                        src={widget?.logo}
                                        alt=""
                                      />
                                    ) : (
                                      widget?.name?.slice(0, 2)?.toUpperCase()
                                    )}
                                    <span className="w-3.5 h-3.5 bg-white rounded-full flex items-center justify-center absolute bottom-[-1px] right-[-1px]">
                                      <Blocks size={"10px"} />
                                    </span>
                                  </span>
                                  <span className="flex flex-col">
                                    <span className="text-primary text-sm font-normal">
                                      {widget?.name}
                                    </span>
                                  </span>
                                </SidebarMenuSubItem>
                              ))}
                          </ScrollArea>
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.view) && <Collapsible
                    asChild
                    defaultOpen={true}
                    className="group/collapsible"
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton
                          tooltip="Your AI Agents"
                          className="font-medium text-[var(--secondary-color)]"
                        >
                          <ChevronRight className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                          <span>Your AI Agents</span>
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub className="flex flex-col m-0 p-0 max-h-48 overflow-auto gap-0.5">
                          <ScrollArea className="flex flex-col">
                            {agentsList.map((agent) => (
                              <SidebarMenuSubItem
                                key={agent?.agent_id}
                                onClick={() =>
                                  navigate(`/inbox?agent=${agent.agent_id}`)
                                }
                                className={`w-full rounded-lg flex items-center gap-2 font-inter text-sm font-medium leading-5 text-left text-secondary cursor-pointer p-2 hover:bg-gray-100 ${
                                  location.pathname ===
                                  `/inbox?agent=${agent?.agent_id}`
                                    ? "bg-gray-100"
                                    : ""
                                }`}
                              >
                                <span className="w-4.5 h-4.5 rounded-full overflow-hidden">
                                  <img
                                    className="w-4 h-4 object-cover"
                                    src={agent?.profile_pic}
                                    alt=""
                                  />
                                </span>
                                <span className="flex flex-col">
                                  <span className="text-primary text-sm font-normal">
                                    {agent?.agent_name}
                                  </span>
                                </span>
                              </SidebarMenuSubItem>
                            ))}
                          </ScrollArea>
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>}
                </SidebarMenu>
              </SidebarGroup>
            </ScrollArea>
          </SidebarContent>
          <SidebarFooter>
            <SidebarMenu>
              {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.billings?.view) && <SidebarMenuItem
                onClick={() => navigate("/billing/wallet")}
                className="cursor-pointer hover:bg-[#F3F4F6] border-radius-2 padding-2"
              >
                {minimizeSidebar && (
                  <>
                    <div className="w-full flex justify-between px-3 py-0 text-left text-sm">
                      <span className="truncate font-semibold flex gap-2">
                        <ChartPie size={"20px"} /> Usage
                      </span>
                      <span className="truncate text-xs">
                        {walletStore.walletBalance}/{walletStore.totalMinutes}
                      </span>
                    </div>
                    <div className="w-full px-3 py-1.5">
                      <Progress
                        value={
                          walletStore.totalMinutes === 0
                            ? 0
                            : (walletStore.walletBalance /
                                walletStore.totalMinutes) *
                              100
                        }
                        className="progress-no-thumb"
                      />
                    </div>
                  </>
                )}
                {!minimizeSidebar && (
                  <SidebarMenuButton className="h-10 p-3" tooltip="Usage">
                    <ChartPie size={"20px"} />
                  </SidebarMenuButton>
                )}
              </SidebarMenuItem>}
              <SidebarMenuItem>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <SidebarMenuButton
                      size="lg"
                      className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                    >
                      <Avatar className="h-8 w-8 rounded-lg">
                        <AvatarImage
                          src={sharedStore.loggedUser?.profile_pic}
                          alt={sharedStore.loggedUser?.name}
                        />
                        <AvatarFallback className="rounded-lg">
                          <ProfileIcon
                            name={sharedStore.loggedUser?.name ?? ""}
                          />
                        </AvatarFallback>
                      </Avatar>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">
                          {sharedStore.loggedUser?.name}
                        </span>
                        <span className="truncate text-xs">
                          {sharedStore.loggedUser?.email}
                        </span>
                      </div>
                      <ChevronsUpDown className="ml-auto size-4" />
                    </SidebarMenuButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                    side="bottom"
                    align="end"
                    sideOffset={4}
                  >
                    <DropdownMenuLabel className="p-0 font-normal">
                      <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                        <Avatar className="h-8 w-8 rounded-lg">
                          <AvatarImage
                            src={sharedStore.loggedUser?.profile_pic}
                            alt={sharedStore.loggedUser?.name}
                          />
                          <AvatarFallback className="rounded-lg">
                            <ProfileIcon
                              name={sharedStore.loggedUser?.name ?? ""}
                            />
                          </AvatarFallback>
                        </Avatar>
                        <div className="grid flex-1 text-left text-sm leading-tight">
                          <span className="truncate font-semibold">
                            {sharedStore.loggedUser?.name}
                          </span>
                          <span className="truncate text-xs">
                            {sharedStore.loggedUser?.email}
                          </span>
                        </div>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => navigate("/profile")}
                      >
                        <UserRound />
                        Account
                      </DropdownMenuItem>
                      {!agencyStore.isAgency && (
                        <>
                          <DropdownMenuItem asChild>
                            <a
                              href="https://docs.oneinbox.ai/general/introduction"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdwon-item menu-item cursor-pointer"
                            >
                              <span className="icon">
                                <ScanText />
                              </span>
                              <span>Guide</span>
                            </a>
                          </DropdownMenuItem>
                          <DropdownMenuItem asChild>
                            <a
                              href="http://feedback.oneinbox.ai/roadmap"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdwon-item menu-item cursor-pointer"
                            >
                              <span className="icon">
                                <CustomIcon iconName="roadmap_icon" />
                              </span>
                              <span>Roadmap</span>
                            </a>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="dropdwon-item menu-item cursor-pointer"
                            onClick={() => openIntercom()}
                          >
                            <span className="icon">
                              <CustomIcon iconName="support_agent" />
                            </span>
                            <span>Support</span>
                          </DropdownMenuItem>
                          <DropdownMenuSub>
                            <DropdownMenuSubTrigger className="dropdwon-item flex items-center justify-between">
                              <span className="flex items-center gap-2">
                                <span className="icon">
                                  <CustomIcon iconName="user_group" />
                                </span>
                                <span>Community</span>
                              </span>
                            </DropdownMenuSubTrigger>
                            <DropdownMenuSubContent>
                              <DropdownMenuItem>
                                <a
                                  href="https://discord.com/invite/tAbnKbxCa8"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="subdropdown-item menu-item cursor-pointer"
                                >
                                  Discord
                                </a>
                              </DropdownMenuItem>
                              <DropdownMenuItem>
                                <a
                                  href="https://www.facebook.com/groups/1128078304935828/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="subdropdown-item menu-item cursor-pointer"
                                >
                                  Facebook
                                </a>
                              </DropdownMenuItem>
                            </DropdownMenuSubContent>
                          </DropdownMenuSub>
                        </>
                      )}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => logout(navigate)}
                    >
                      <LogOut />
                      Log out
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarFooter>
          <SidebarRail />
        </Sidebar>
      ) : (
        ""
      )}
      <SidebarInset className="overflow-hidden">
        <Outlet />
        {retellStore.callLive && <LiveCall />}
        {sharedStore.dialerPopup && (
          <DialerPopup close={() => sharedStore.setDialerPopup(false)} />
        )}
        <WebSocketComponent />
      </SidebarInset>
    </SidebarProvider>
  );
}

const changeFavicon = (iconPath: string): void => {
  const head = document.head;
  const existingFavicon = document.querySelector('link[rel="icon"]');

  if (existingFavicon) {
    // Update the existing favicon
    existingFavicon.setAttribute("href", iconPath);
  } else {
    // Create a new link element for the new favicon
    const newFavicon = document.createElement("link");
    newFavicon.setAttribute("rel", "icon");
    newFavicon.setAttribute("type", "image/x-icon");
    newFavicon.setAttribute("href", iconPath);

    // Append the new favicon to the head
    head.appendChild(newFavicon);
  }
};

export default App;
