import apiClient from '../interceptors/axiosApiInterceptor';

// Agent methods
export const getActiveAgents = (pageNumber: number) =>
  apiClient.get(`/user/agents?active=1&page=${pageNumber}`);

export const getAllAgents = (pageNumber: number, dependent: boolean = false) => {
  const query = dependent
    ? `/voice/ai-agent?page=${pageNumber}&r_type=dependent`
    : `/voice/ai-agent?page=${pageNumber}`;
  return apiClient.get(query);
};

export const deleteAgent = (id: number) =>
  apiClient.delete(`/user/agents/${id}/`);

export const addAgent = (agentData: any) =>
  apiClient.post('/voice/ai-agent', agentData);

export const duplicateAgent = (id: number) =>
  apiClient.post(`/voice/ai-agent/${id}/duplicate`);

export const addAgentAction = (agentId: number, actionData: any) =>
  apiClient.post(`/oneinbox/agent/action/${agentId}`, actionData);

export const updateAgentAction = (agentId: number, actionId: number, actionData: any) =>
  apiClient.patch(`/oneinbox/agent/action/${agentId}/${actionId}`, actionData);

export const getAgentAction = (agentId: number) =>
  apiClient.get(`/oneinbox/agent/action/${agentId}`);

export const deleteAgentAction = (agentId: number, actionId: number) =>
  apiClient.delete(`/oneinbox/agent/action/${agentId}/${actionId}`);

export const getActionVariables = (trigger: string, params: any) =>
  apiClient.get(`/oneinbox/var/action/${trigger}`, { params });

export const getPromptActionVariables = (trigger: string, params: any) =>
  apiClient.get(`/oneinbox/var/action/${trigger}`, { params });

export const updateAgent = (agentData: any, agentId: number) =>
  apiClient.patch(`/voice/ai-agent/${agentId}`, agentData);

export const deleteAiAgent = (agentId: number) =>
  apiClient.delete(`/voice/ai-agent/${agentId}`);

export const get11LabsVoices = (id: number) =>
  apiClient.get('/voice/options/agent_voice', {
    params: {
      agent_id: id,
    }
  });

export const getAiAgentCharacteristics = () =>
  apiClient.get('/voice/ai-agent/characteristics');

export const getTimezones = () =>
  apiClient.get('/oneinbox/timezones');

export const getAudioPresign = (id: number) =>
  apiClient.get(`/voice/call-recording/${id}/presign`);

export const assignAgentToNumber = (numberId: number, assignmentData: any) =>
  apiClient.patch(`/voice/number/${numberId}/assign`, assignmentData);

// User methods
export const getActiveUsers = (pageNumber: number) =>
  apiClient.get(`/user?status=active&page=${pageNumber}`);

export const getPendingUsers = (pageNumber: number) =>
  apiClient.get(`/user?status=pending&page=${pageNumber}`);

export const getAllUsers = (pageNumber: number) =>
  apiClient.get(`/user?page=${pageNumber}`);

export const deleteUser = (userId: number) =>
  apiClient.delete(`/user/${userId}/`);

export const addUser = (userData: any) =>
  apiClient.post('/user/', userData);

export const updateUser = (userData: any, id: number) =>
  apiClient.put('/user/'+id+'/', userData);

export const searchUsers = (searchTag: string, pageNumber: number) =>
  apiClient.get(`/search/team-member?q=${searchTag}&page=${pageNumber}`);

export const verifyUser = (code: string) =>
  apiClient.get(`/user/verify/${code}`);

// Utility methods
export const testWebhook = (webhookData: any) =>
  apiClient.post('/test/webhook', webhookData);

export const getAgentLanguages = () =>
  apiClient.get('/oneinbox/agent/lang');

export const getAgentBackgroundNoise = () =>
  apiClient.get('/oneinbox/agent/background-noise');

export const getAgentTemplate = () =>
  apiClient.get('/voice/templates');

export const getAgentRoles = (pageNumber: number) =>
  apiClient.get(`/user/role?page=${pageNumber}`);

export const getAgentPermissions = () =>
  apiClient.get('/user/permission');

export const addAgentRole = (roleData: any) =>
  apiClient.post('/user/role', roleData);

export const updateAgentRole = (roleData: any, id: number) =>
  apiClient.put('/user/role/'+id, roleData);

export const deleteAgentRole = (id: number) =>
  apiClient.delete('/user/role/'+id);

export const searchAgentRoles = (searchTag: string, pageNumber: number) =>
  apiClient.get(`/user/role?page=${pageNumber}&q=${searchTag}`);