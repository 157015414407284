import React, { useState } from "react";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { Button } from "../shadcn/button";
import useSharedStore from "../../store/shared.store";
import { RadioGroup, RadioGroupItem } from "../shadcn/radio-group";
import { toPascalCase } from "../../lib/ui.utils";

interface SelectAddonsProps {
  minutesRate: number;
  voicemailRate: number;
  agentRate: number;
  infobaseRate: number;
  numberRate: number;
  onClose: () => void;
  onSelect: (type: string) => void;
  type: string;
}

const SelectAddons: React.FC<SelectAddonsProps> = ({
  minutesRate,
  voicemailRate,
  agentRate,
  infobaseRate,
  numberRate,
  onClose,
  onSelect,
  type: initialType,
}) => {
  const [type, setType] = useState(initialType);
  const [isOpen, setIsOpen] = useState(true);
  const sharedStore = useSharedStore();

  const handleSelect = (selectedType: string) => {
    setType(selectedType);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-[500px]">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center text-primary text-lg font-semibold">
            <span>Buy more add-ons</span>
          </div>
          <span className="text-secondary text-sm font-normal">
            Choose add-ons to scale and optimize usage
          </span>
        </div>
        <RadioGroup defaultValue={type}>
          <div className="flex flex-col gap-4 min-w-[400px]">
            <label
              className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect("minutes")}
            >
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Minutes
                </span>
                <span className="text-secondary text-sm font-normal">
                  ${minutesRate} per minute (Minimum: 50 minutes)
                </span>
              </div>
              <RadioGroupItem checked={type === "minutes"} value={"minutes"} />
            </label>
            <label
              className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect("voicemail")}
            >
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Voicemail
                </span>
                <span className="text-secondary text-sm font-normal">
                  ${voicemailRate} per voicemail (Minimum: 50 voicemails)
                </span>
              </div>
              <RadioGroupItem
                checked={type === "voicemail"}
                value={"voicemail"}
              />
            </label>
            <label
              className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect("agent")}
            >
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  AI Agent
                </span>
                <span className="text-secondary text-sm font-normal">
                  ${agentRate} per agent, per month
                </span>
              </div>
              <RadioGroupItem checked={type === "agent"} value={"agent"} />
            </label>
            {sharedStore.business?.subscription?.subscription_type !== 1 && (
              <label
                className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelect("infobase")}
              >
                <div className="flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium">
                    Infobase
                  </span>
                  <span className="text-secondary text-sm font-normal">
                    ${infobaseRate} per infobase, per month
                  </span>
                </div>
                <RadioGroupItem
                  checked={type === "infobase"}
                  value={"infobase"}
                />
              </label>
            )}
            <label
              className="flex items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect("numbers")}
            >
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium">
                  Phone numbers
                </span>
                <span className="text-secondary text-sm font-normal">
                  ${numberRate} per number, per month
                </span>
              </div>
              <RadioGroupItem checked={type === "numbers"} value={"numbers"} />
            </label>
          </div>
        </RadioGroup>
        <div className="flex justify-end p-2">
          <Button onClick={() => onSelect(type)}>Buy {toPascalCase(type)}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectAddons;
