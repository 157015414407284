import React, { useEffect, useState } from "react";
import { show } from "@intercom/messenger-js-sdk";
import CustomIcon from "../shared/CustomIcon";
import { AiAgent } from "../../models/ai-agent";
import { getBusinessData } from "../../lib/common.utils";
import { getAllAgents } from "../../lib/agents.utils";
import useSharedStore from "../../store/shared.store";
import { useNavigate } from "react-router-dom";
import { useRetellStore } from "../../store/retell.store";
import Toaster from "../shared/customToast";
import { Business } from "../../models/business";
import { useAgencyStore } from "../../store/agency.store";
import { Button } from "../shadcn/button";
import { Check } from "lucide-react";

const GettingStarted: React.FC = ({}) => {
  const [loaderCall, setLoaderCall] = useState(false);
  const [agent, setAgent] = useState<AiAgent | null>(null);

  const navigate = useNavigate();
  const sharedStore = useSharedStore();
  const retellStore = useRetellStore();
  const agencyStore = useAgencyStore();

  useEffect(() => {
    getBusinessData();
    getAllAgents(1)
      .then((resp) => {
        if (resp.data.data.length > 0) {
          setAgent(new AiAgent(resp.data.data[0]));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const createAgent = () => {
    navigate("/agents");
    sharedStore.setGettingStartedEnabled(true);
  };

  const testCall = () => {
    setLoaderCall(true);
    const input = {
      agent_id: agent?.id,
      is_test_call: true,
    };
    retellStore.setLiveAgent(agent ?? new AiAgent(null));
    retellStore.setCallLive(true);

    retellStore
      .registerCall(input)
      .then((resp) => {
        setLoaderCall(false);
        retellStore.setLiveAgent(agent ?? new AiAgent(null));
        retellStore.startCall(resp.data.call_id, resp.data.access_token);
        sharedStore.setBusiness(
          new Business({
            ...sharedStore.business?.toJson(),
            is_agent_test: true,
          })
        );
        sharedStore.setGettingStartedEnabled(false);
      })
      .catch((error) => {
        console.error(error);
        Toaster.error(error);
        setLoaderCall(false);
        retellStore.setCallLive(false);
      });
  };

  const createWidget = () => {
    navigate("/settings/widgets");
    sharedStore.setGettingStartedEnabled(true);
  };

  const addNumber = () => {
    navigate("/settings/phone-numbers");
    sharedStore.setGettingStartedEnabled(true);
  };

  const openIntercom = () => {
    show();
  };

  return (
    <div
      className="w-full h-full pt-8 px-24 flex flex-col gap-6"
      style={{ paddingTop: `${sharedStore.getNotificationBarSize() + 32}px` }}
    >
      <div className="flex flex-col gap-1">
        <span className="text-primary text-2xl font-medium leading-9">
          Getting Started
        </span>
        <span className="text-secondary text-sm font-normal leading-5">
          Everything you need to setup {agencyStore.brand}.
        </span>
      </div>
      <div className="flex gap-4">
        <div className="border border-gray-200 rounded-lg w-full max-w-2xl p-6">
          <div className="flex flex-col gap-0.5">
            <div className="flex gap-4 items-center">
              <span className="w-11 h-11 bg-hover rounded-full flex items-center justify-center" style={{ backgroundColor: sharedStore.business?.is_agent_created ? "#E5E7EB" : "var(--hover-color)" }}>
                {!sharedStore.business?.is_agent_created ? (
                  <CustomIcon
                    iconName="aiagents"
                    color="#6B7280"
                    width={24}
                    height={24}
                  />
                ) : (
                  <Check color="#7E22CE" size="24px" />
                )}
              </span>
              <div className="flex justify-between items-center w-[calc(100%-60px)]">
                <div className="flex flex-col gap-0.5">
                  {!sharedStore.business?.is_agent_created ? (
                    <>
                      <span className="text-primary text-sm font-medium leading-5">
                        Create AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5">
                        Use our built-in template or start from scratch.
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-primary text-sm font-medium leading-5 line-through">
                        Create AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5 line-through">
                        Use our built-in template or start from scratch.
                      </span>
                    </>
                  )}
                </div>
                {sharedStore.gettingStartedStepCount === 1 && (
                  <div className="flex items-center gap-2">
                    <Button onClick={createAgent}>Create agent</Button>
                  </div>
                )}
              </div>
            </div>
            <span className="h-8 flex items-center w-11 justify-center">
              <hr className="m-0 h-px w-8 rotate-90 p-0" />
            </span>
            <div className="flex gap-4 items-center">
              <span className="w-11 h-11 bg-hover rounded-full flex items-center justify-center" style={{ backgroundColor: sharedStore.business?.is_agent_created ? "#E5E7EB" : "var(--hover-color)" }}>
                {!sharedStore.business?.is_agent_test ? (
                  <CustomIcon
                    iconName="calls"
                    color="#6B7280"
                    width={24}
                    height={24}
                  />
                ) : (
                  <Check color="#7E22CE" size="24px" />
                )}
              </span>
              <div className="flex justify-between items-center w-[calc(100%-60px)]">
                <div className="flex flex-col gap-0.5">
                  {!sharedStore.business?.is_agent_test ? (
                    <>
                      <span className="text-primary text-sm font-medium leading-5">
                        Test AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5">
                        Test your AI agent's performance through text or call.
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-primary text-sm font-medium leading-5 line-through">
                        Test AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5 line-through">
                        Test your AI agent's performance through text or call.
                      </span>
                    </>
                  )}
                </div>
                {sharedStore.gettingStartedStepCount === 2 && (
                  <div className="flex items-center gap-2">
                    <Button onClick={testCall} disabled={loaderCall}>
                      {loaderCall ? (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Web call"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <span className="h-8 flex items-center w-11 justify-center">
              <hr className="m-0 h-px w-8 rotate-90 p-0" />
            </span>
            <div className="flex gap-4 items-center">
              <span className="w-11 h-11 bg-hover rounded-full flex items-center justify-center" style={{ backgroundColor: sharedStore.business?.is_agent_created ? "#E5E7EB" : "var(--hover-color)" }}>
                {!sharedStore.business?.is_agent_deployed ? (
                  <CustomIcon
                    iconName="preview"
                    color="#6B7280"
                    width={24}
                    height={24}
                  />
                ) : (
                  <Check color="#7E22CE" size="24px" />
                )}
              </span>
              <div className="flex justify-between items-center w-[calc(100%-60px)]">
                <div className="flex flex-col gap-0.5">
                  {!sharedStore.business?.is_agent_deployed ? (
                    <>
                      <span className="text-primary text-sm font-medium leading-5">
                        Deploy AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5">
                        Deploy your AI agent on a phone number or website.
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-primary text-sm font-medium leading-5 line-through">
                        Deploy AI agent
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5 line-through">
                        Deploy your AI agent on a phone number or website.
                      </span>
                    </>
                  )}
                </div>
                {sharedStore.gettingStartedStepCount === 3 && (
                  <div className="flex items-center gap-2">
                    <Button variant="secondary" onClick={createWidget}>
                      Widget
                    </Button>
                    <Button variant="default" onClick={addNumber}>
                      Phone number
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <span className="h-8 flex items-center w-11 justify-center">
              <hr className="m-0 h-px w-8 rotate-90 p-0" />
            </span>
            <div className="flex gap-4 items-center">
              <span className="w-11 h-11 bg-hover rounded-full flex items-center justify-center" style={{ backgroundColor: sharedStore.business?.is_agent_created ? "#E5E7EB" : "var(--hover-color)" }}>
                {!sharedStore.business?.is_live ? (
                  <CustomIcon
                    iconName="signal"
                    color="#6B7280"
                    width={24}
                    height={24}
                  />
                ) : (
                  <Check color="#7E22CE" size="24px" />
                )}
              </span>
              <div className="flex justify-between items-center w-[calc(100%-60px)]">
                <div className="flex flex-col gap-0.5">
                  {!sharedStore.business?.is_live ? (
                    <>
                      <span className="text-primary text-sm font-medium leading-5">
                        Go live
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5">
                        Make call on your phone number or make a call using the
                        widget added to your website
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-primary text-sm font-medium leading-5 line-through">
                        Go live
                      </span>
                      <span className="text-secondary text-sm font-normal leading-5 line-through">
                        Make call on your phone number or make a call using the
                        widget added to your website
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!agencyStore.isAgency && (
          <div className="border border-gray-300 rounded-lg p-2 flex flex-col gap-2">
            <a
              href="https://vibtree.notion.site/OneInbox-Docs-13a2cc6eaa794e90bf819c1243bc4a45"
              target="_blank"
              rel="noreferrer"
              className="p-2 w-full rounded-lg flex items-center gap-2 text-primary text-sm font-normal leading-5 no-underline cursor-pointer hover:bg-hover"
            >
              <CustomIcon iconName="document_scanner" width={16} height={16} />
              <span>OneInbox docs</span>
            </a>
            <a
              href="https://www.youtube.com/@OneInbox-AI"
              target="_blank"
              rel="noreferrer"
              className="p-2 w-full rounded-lg flex items-center gap-2 text-primary text-sm font-normal leading-5 no-underline cursor-pointer hover:bg-hover"
            >
              <CustomIcon iconName="play_circle" width={16} height={16} />
              <span>Watch demo</span>
            </a>
            <a
              href="javascript:void(0)"
              onClick={openIntercom}
              className="p-2 w-full rounded-lg flex items-center gap-2 text-primary text-sm font-normal leading-5 no-underline cursor-pointer hover:bg-hover"
            >
              <CustomIcon iconName="support_agent" width={16} height={16} />
              <span>Chat with us</span>
            </a>
            <a
              href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0t1wKx1T-XMmCXoTbW3iSb5zebASBEFLPj0KTAAQEX1_eeLxu7F6EQySwHPIHmW9WuohNQUmxO?gv=true"
              target="_blank"
              rel="noreferrer"
              className="p-2 w-full rounded-lg flex items-center gap-2 text-primary text-sm font-normal leading-5 no-underline cursor-pointer hover:bg-hover"
            >
              <CustomIcon iconName="email" width={16} height={16} />
              <span>Schedule an onboarding call</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default GettingStarted;
