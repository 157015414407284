import { getUsers, searchUsers } from "../../lib/users.utils";
import { Customer } from "../../models/customer";
import useSharedStore from "../../store/shared.store";
import React, { useState, useEffect } from "react";
import { Input } from "../shadcn/input";
import { Skeleton } from "../shadcn/skeleton";
import { ScrollArea } from "../shadcn/scrollArea";
import {
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  Sheet,
} from "../shadcn/sheet";
import ContactDetails from "./ContactDetails";
import { LoggedUser } from "../../models/logged-user";
import { Button } from "../shadcn/button";
import { Plus, Upload, UserPlus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import ImportContacts from "./ImportContacts";
import { formatToE164 } from "../../lib/ui.utils";
// import { WebSocketService } from "../services/web-socket.service";
import Pagination from "../shared/Pagination";

const Contacts: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState<Customer[]>([]);
  const [paginationSize, setPaginationSize] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState(false);
  const [customerSearchTag, setCustomerSearchTag] = useState("");
  const [prevCustomerSearchTag, setPrevCustomerSearchTag] = useState("");

  const [showCustomerProfile, setShowCustomerProfile] = useState(false);
  const [viewedCustomer, setViewedCustomer] = useState<Customer | null>(null);
  const [viewedCustomerIndex, setViewedCustomerIndex] = useState<number>(-1);

  const [isAddContact, setIsAddContact] = useState(false);
  const [isImportContact, setIsImportContact] = useState(false);

  const sharedStore = useSharedStore();
  const user = new LoggedUser(JSON.parse(localStorage.getItem("user")!));
  // const { toast } = useToast()
  // const webSocketService = new WebSocketService();

  useEffect(() => {
    sharedStore.inAuthModule = false;
    // webSocketService.openWebSocket();
    getCustomers(paginationPage);
  }, []);

  const getCustomers = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    getUsers(pageNumber).then(
      (resp: any) => {
        console.log(resp);
        if (resp.data.code === 200) {
          try {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > resp.data.size);
            const updatedContactList = new Array<Customer>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedContactList[i] = new Customer(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new Customer(
                  resp.data.data[i % paginationSize]
                );
              }
            }

            setContactList(updatedContactList);
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearchTag(e.target.value);
  };

  useEffect(() => {
    if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      customerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      searchCampaign(1);
      setPaginationPage(1);
    } else if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      prevCustomerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      setPaginationPage(1);
      getCustomers(1);
    }
  }, [customerSearchTag]);

  const searchCampaign = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    searchUsers(pageNumber, customerSearchTag).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            if (pageNumber === 1) {
              setPagination(resp.data.count > paginationSize);
              const responseList = new Array<Customer>(resp.data.count);
              for (let i = 0; i < resp.data.data.length; i++) {
                responseList[i] = new Customer(resp.data.data[i]);
              }
              setContactList(responseList);
            } else {
              const updatedContactList = [...contactList];
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new Customer(
                  resp.data.data[i % paginationSize]
                );
              }
              setContactList(updatedContactList);
            }
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const viewCustomer = (index: number) => {
    setViewedCustomer(contactList[index]);
    setViewedCustomerIndex(index);
    setShowCustomerProfile(true);
  };

  const onCustomerAdd = (customer: Customer) => {
    const updatedContactList = [customer, ...contactList];
    setContactList(updatedContactList);
  };

  const onCustomerDelete = () => {
    const updatedContactList = [...contactList];
    updatedContactList.splice(viewedCustomerIndex, 1);
    setContactList(updatedContactList);
    setShowCustomerProfile(false);
    setViewedCustomerIndex(-1);
  };

  return (
    <ScrollArea className="container mx-auto">
      <div className="bg-white p-6 flex flex-col gap-4">
        {/* Header Section */}
        <div className="flex justify-between align-center">
          <div className="flex gap-x-2 items-center">
            <h1 className="text-xl font-bold text-gray-800">Contacts</h1>
            {contactList.length > 0 && (
              <div className="text-sm text-gray-600">{contactList.length}</div>
            )}
          </div>
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.contacts?.edit) && <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default">
                <Plus size="18px" className="mr-2" /> Add contact
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end" className="">
              <DropdownMenuItem className="cursor-pointer hover:bg-[var(--hover-color)]"
                onClick={() => setIsAddContact(true)}
              >
                <UserPlus size="20px" className="mr-2" />
                Add single contact
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer hover:bg-[var(--hover-color)]"
                onClick={(e) => {
                  setIsImportContact(true);
                }}
              >
                <Upload size="20px" className="mr-2" />
                <div className="flex flex-col">
                  <span>Import contacts</span>
                  <span className="text-xs text-muted-foreground">
                    Upload a .xlsx or .csv file
                  </span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>}
        </div>

        {/* Search Input */}
        {!(customerSearchTag === "" && contactList?.length === 0) && (
          <div className="">
            <Input
              type="text"
              placeholder="Search Contacts"
              value={customerSearchTag}
              onChange={handleSearchInput}
              className="max-w-md"
            />
          </div>
        )}

        {/* Table Section */}
        {isLoading && (
          <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="w-full gap-4 flex flex-col">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton key={index} className="h-8 w-full" />
                ))}
              </div>
            ))}
          </div>
        )}
        {!isLoading && contactList.length > 0 && (
          <div className="overflow-x-auto border border-gray-300 rounded-lg">
            <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
              <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10 shadow-sm">
                <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                  <th
                    style={{ borderRadius: "8px 0 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  >
                    Phone number
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Name
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Email
                  </th>
                  <th
                    style={{ borderRadius: "0 8px 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  >
                    Added by
                  </th>
                </tr>
              </thead>
              <tbody>
                {contactList.length > 0 &&
                  contactList.map((customer, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                      onClick={() => {
                        if(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.contacts?.edit) viewCustomer(index);
                      }}
                    >
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {formatToE164(customer?.mobile) ?? "-"}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        <span title={customer?.name}>{customer?.name}</span>
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {customer?.email || "-"}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {customer?.created_by?.name && (
                          <span className="font-medium">
                            {customer?.created_by?.name}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {customerSearchTag === "" && !isLoading && contactList.length === 0 && (
          <div className="w-full h-[600px] flex flex-col items-center justify-center gap-4 font-inter text-base font-medium leading-6 text-left text-[var(--primary-color)]">
            <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
              <img
                src="../../../../assets/images/contacts_empty.png"
                alt="Empty Webhooks"
              />
              <span>No contacts found</span>
            </div>
          </div>
        )}
        {customerSearchTag !== "" && !isLoading && contactList.length === 0 && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
              <img
                src="../../../../assets/images/contacts_empty.png"
                alt="No Webhooks Found"
              />
              <span>No contacts were found matching your search</span>
            </div>
          </div>
        )}
        {pagination && !isLoading && (
          <div className="pagination-container">
            <Pagination
              currentPage={paginationPage}
              listLength={contactList.length}
              paginationSize={paginationSize}
              onPageChange={(page) => {
                setPaginationPage(page);
                customerSearchTag === ""
                  ? getCustomers(page)
                  : searchCampaign(page);
              }}
            />
          </div>
        )}
      </div>
      {showCustomerProfile && (
        <ContactDetails
          onAdd={onCustomerAdd}
          close={() => setShowCustomerProfile(false)}
          onDelete={() => onCustomerDelete()}
          customer={viewedCustomer ?? new Customer(null)}
        />
      )}
      {isAddContact && (
        <ContactDetails
          onAdd={onCustomerAdd}
          onDelete={() => onCustomerDelete()}
          close={() => setIsAddContact(false)}
          customer={new Customer(null)}
        />
      )}
      {isImportContact && (
        <ImportContacts onClose={() => setIsImportContact(false)} />
      )}
    </ScrollArea>
  );
};

export default Contacts;
