import React, { useState, useEffect } from "react";
import { Ellipsis, Plus, Settings, Sheet, X } from "lucide-react";
import {
  deleteUser,
  getActiveUsers,
  getPendingUsers,
  searchUsers,
} from "../../../lib/agents.utils";
import { Customer } from "../../../models/customer";
import { LoggedUser } from "../../../models/logged-user";
import useSharedStore from "../../../store/shared.store";
import { Input } from "../../shadcn/input";
import { SheetContent } from "../../shadcn/sheet";
import { Skeleton } from "../../shadcn/skeleton";
import { ScrollArea } from "../../shadcn/scrollArea";
import { Button } from "../../shadcn/button";
import Pagination from "../../shared/Pagination";
import ProfileIcon from "../../shared/ProfileIcon";
import { Agent } from "../../../models/team-member";
import { formatDateToMonthDay } from "../../../lib/ui.utils";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../shadcn/dropdown-menu";
import Toaster from "../../shared/customToast";
import { useAgencyStore } from "../../../store/agency.store";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import AddUser from "./AddUser";
import UsersPermission from "./UsersPermission";

const Users: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState<Agent[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(20);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState<boolean>(false);
  const [customerSearchTag, setCustomerSearchTag] = useState<string>("");
  const [prevCustomerSearchTag, setPrevCustomerSearchTag] =
    useState<string>("");

  const [isPendingLoading, setIsPendingLoading] = useState<boolean>(false);
  const [pendingContactList, setPendingContactList] = useState<Agent[]>([]);
  const [pendingPaginationSize, setPendingPaginationSize] =
    useState<number>(20);
  const [pendingPaginationPage, setPendingPaginationPage] = useState<number>(1);
  const [pendingPaginatedPages, setPendingPaginatedPages] = useState<
    Set<number>
  >(new Set());
  const [pendingPagination, setPendingPagination] = useState<boolean>(false);

  const [showCustomerProfile, setShowCustomerProfile] =
    useState<boolean>(false);
  const [viewedAgent, setViewedAgent] = useState<Agent>(new Agent(null));
  const [viewedCustomerIndex, setViewedCustomerIndex] = useState<number>(0);

  const [isAddUser, setIsAddUser] = useState<boolean>(false);
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
  const [deleteHeading, setDeleteHeading] = useState<string>("");
  const [deleteDescription, setDeleteDescription] = useState<string>("");
  const [negativeButton, setNegativeButton] = useState<string>("");
  const [positiveButton, setPositiveButton] = useState<string>("");
  const [userPermissionsPopup, setUserPermissionsPopup] =
    useState<boolean>(false);

  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();
  const user = new LoggedUser(JSON.parse(localStorage.getItem("user")!));

  useEffect(() => {
    sharedStore.inAuthModule = false;
    getCustomers(paginationPage);
    getPendingUserList(1);
  }, []);

  const getCustomers = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    getActiveUsers(pageNumber).then(
      (resp: any) => {
        console.log(resp);
        if (resp.data.code === 200) {
          try {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > resp.data.size);
            const updatedContactList = new Array<Agent>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedContactList[i] = new Agent(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new Agent(
                  resp.data.data[i % paginationSize]
                );
              }
            }

            setContactList(updatedContactList);
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const getPendingUserList = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsPendingLoading(true);

    getPendingUsers(pageNumber).then(
      (resp: any) => {
        console.log(resp);
        if (resp.data.code === 200) {
          try {
            setPendingPaginationSize(resp.data.size);
            setPendingPagination(resp.data.count > resp.data.size);
            const updatedContactList = new Array<Agent>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedContactList[i] = new Agent(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * pendingPaginationSize;
                i <
                (pageNumber - 1) * pendingPaginationSize +
                  resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new Agent(
                  resp.data.data[i % pendingPaginationSize]
                );
              }
            }

            setPendingContactList(updatedContactList);
            setPendingPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        }
        setIsPendingLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsPendingLoading(false);
      }
    );
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearchTag(e.target.value);
  };

  useEffect(() => {
    if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      customerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      searchCampaign(1);
      setPaginationPage(1);
    } else if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      prevCustomerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      setPaginationPage(1);
      getCustomers(1);
    }
  }, [customerSearchTag]);

  const searchCampaign = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    searchUsers(customerSearchTag, pageNumber).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            if (pageNumber === 1) {
              setPagination(resp.data.count > paginationSize);
              const responseList = new Array<Agent>(resp.data.count);
              for (let i = 0; i < resp.data.data.length; i++) {
                responseList[i] = new Agent(resp.data.data[i]);
              }
              setContactList(responseList);
            } else {
              const updatedContactList = [...contactList];
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new Agent(
                  resp.data.data[i % paginationSize]
                );
              }
              setContactList(updatedContactList);
            }
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const onCustomerAdded = (user: Agent) => {
    setPendingContactList((prev) => [user, ...prev]);
    setIsAddUser(false);
    setViewedAgent(new Agent(null));
  };

  const onUserUpdated = (user: Agent) => {
    setContactList((prev) => {
      const updatedContactList = [...prev];
      updatedContactList?.forEach((item, index) => {
        if (item.id === user.id) {
          updatedContactList[index] = user;
        }
      });
      return updatedContactList;
    });
    setIsAddUser(false);
    setViewedAgent(new Agent(null));
  };

  const editUser = (user: Agent) => {
    setIsAddUser(true);
    setViewedAgent(user);
  };

  const openDeleteConfirmation = (user: Agent) => {
    setViewedAgent(user);
    if (user?.accepted) {
      setPositiveButton("Yes, remove user");
      setDeleteHeading("Confirm remove user");
      setDeleteDescription(
        `The user will be removed, and <span class="col-pri">${user?.email}</span> will no longer be able to access ${sharedStore.business?.name} on ${agencyStore.brand}.`
      );
      setNegativeButton("No, keep user");
    } else {
      setPositiveButton("Yes, cancel invite");
      setDeleteHeading("Confirm cancel invite");
      setDeleteDescription(
        `The invite will be deactivated, and <span class="col-pri">${user?.email}</span> will no longer be able to access ${sharedStore.business?.name} on ${agencyStore.brand}.`
      );
      setNegativeButton("No, keep invite");
    }
    setConfirmationPopup(true);
  };

  const deletePlatformUser = () => {
    setConfirmationPopup(false);
    deleteUser(viewedAgent?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
        console.log(error);
      });

    setPendingContactList((prev) =>
      prev.filter((contact) => contact.id !== viewedAgent?.id)
    );
    setContactList((prev) =>
      prev.filter((contact) => contact.id !== viewedAgent?.id)
    );
    setViewedAgent(new Agent(null));
  };

  return (
    <ScrollArea className="container mx-auto overflow-auto h-full">
      {!userPermissionsPopup && (
        <div className="bg-white p-6 flex flex-col gap-4">
          {/* Header Section */}
          <div className="flex justify-between align-center">
            <div className="flex gap-x-2 items-center mb-4">
              <h1 className="flex flex-col gap-1 text-xl font-bold text-gray-800">
                <span className="flex gap-x-2 items-center">
                  Users
                  <div className="text-sm text-gray-600">
                    {contactList.length}
                  </div>
                </span>
                <span className="text-sm text-secondary font-normal">
                  Manage all users in your workspace
                </span>
              </h1>
            </div>
            <div className="flex gap-4 items-center">
              {sharedStore.loggedUser?.user_type === 1 && (
                <Button
                  variant={"secondary"}
                  onClick={() => setUserPermissionsPopup(true)}
                >
                  <Settings /> Roles
                </Button>
              )}
              {(sharedStore.loggedUser?.user_type === 1 ||
                sharedStore.permissions?.settings?.users?.edit) && (
                <Button onClick={() => setIsAddUser(true)}>
                  <Plus /> Add user
                </Button>
              )}
            </div>
          </div>
          {pendingContactList?.length > 0 && (
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-[16px]">
                Pending invites{" "}
              </span>
              <div className="overflow-x-auto border border-gray-300 rounded-lg">
                <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
                  <thead className="bg-gray-100 text-gray-700 bg-gray-100 sticky top-0 z-10 shadow-sm">
                    <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      <th
                        style={{ borderRadius: "8px 0 0 0" }}
                        className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                      ></th>
                      <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                        Name
                      </th>
                      <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                        Email
                      </th>
                      <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                        Role
                      </th>
                      <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                        Created
                      </th>
                      <th
                        style={{ borderRadius: "0 8px 0 0" }}
                        className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingContactList.map((user, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                      >
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                          <ProfileIcon
                            name={user?.name}
                            size="32px"
                          ></ProfileIcon>
                        </td>
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                          {user?.name}
                        </td>
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                          {user?.email}
                        </td>
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                          <span className="py-1 px-2 min-w-[120px] truncate max-w-[200px] bg-[var(--hover-color)] rounded-md">
                            {user?.role?.name}
                          </span>
                        </td>
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                          {formatDateToMonthDay(user?.date_joined)}
                        </td>
                        <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                          {(sharedStore.loggedUser?.user_type === 1 ||
                            sharedStore.permissions?.settings?.users?.edit) && (
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <button className="p-0 border-none bg-transparent">
                                  <X className="w-5 h-5 text-gray-500" />
                                </button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="bg-white border border-gray-300 w-40 rounded-lg p-2.5">
                                <DropdownMenuItem
                                  className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                  onSelect={() => openDeleteConfirmation(user)}
                                >
                                  Delete
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* Search Input */}
          <div className=" w-full flex justify-between items-center">
            <Input
              type="text"
              className="w-fit"
              placeholder="Search Users"
              value={customerSearchTag}
              onChange={handleSearchInput}
            />
          </div>

          {isLoading ? (
            <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="w-full gap-4 flex flex-col">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton key={index} className="h-8 w-full" />
                  ))}
                </div>
              ))}
            </div>
          ) : contactList.length > 0 ? (
            <div className="overflow-x-auto border border-gray-300 rounded-lg">
              <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
                <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10 shadow-sm">
                  <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    <th
                      style={{ borderRadius: "8px 0 0 0" }}
                      className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                    ></th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Name
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Email
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Role
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Joined
                    </th>
                    <th
                      style={{ borderRadius: "0 8px 0 0" }}
                      className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {contactList.map((user, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                    >
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                        <ProfileIcon
                          name={user?.name}
                          size="32px"
                        ></ProfileIcon>
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {user?.name}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {user?.email}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        <span className="py-1 px-2 min-w-[120px] truncate max-w-[200px] bg-[var(--hover-color)] rounded-md">
                          {user?.role?.name}
                        </span>
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {user?.date_joined
                          ? formatDateToMonthDay(user?.date_joined)
                          : "-"}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                        {
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <button className="p-0 border-none bg-transparent">
                                <Ellipsis className="w-5 h-5 text-gray-500" />
                              </button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="bg-white border border-gray-300 w-40 rounded-lg p-2.5">
                              {(sharedStore.loggedUser?.user_type === 1 ||
                                sharedStore.permissions?.settings?.users
                                  ?.edit) && (
                                <DropdownMenuItem
                                  className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                  onSelect={() => editUser(user)}
                                >
                                  Edit
                                </DropdownMenuItem>
                              )}
                              {(sharedStore.loggedUser?.user_type === 1 ||
                                sharedStore.permissions?.settings?.users
                                  ?.delete) && (
                                <DropdownMenuItem
                                  className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                  onSelect={() => openDeleteConfirmation(user)}
                                >
                                  Delete
                                </DropdownMenuItem>
                              )}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-screen">
              <div className="empty-card">
                <img
                  src="../../../../assets/images/contacts_empty.png"
                  alt="Start uploading files"
                />
                <span>No users found</span>
              </div>
            </div>
          )}
          {pagination && !isLoading && (
            <div className="pagination-container">
              <Pagination
                currentPage={paginationPage}
                listLength={contactList.length}
                paginationSize={paginationSize}
                onPageChange={(page) => {
                  setPaginationPage(page);
                  getCustomers(page);
                }}
              />
            </div>
          )}
          {confirmationPopup && (
            <ConfirmationPopup
              positiveButtonText={positiveButton}
              negativeButtonText={negativeButton}
              heading={deleteHeading}
              description={deleteDescription}
              onClose={() => setConfirmationPopup(false)}
              onSuccess={deletePlatformUser}
            />
          )}
          {isAddUser && (
            <AddUser
              agent={viewedAgent}
              onClose={() => setIsAddUser(false)}
              onSuccess={onCustomerAdded}
              onEdit={onUserUpdated}
            />
          )}
        </div>
      )}
      {userPermissionsPopup && (
        <UsersPermission
          onClose={() => setUserPermissionsPopup(false)}
        ></UsersPermission>
      )}
    </ScrollArea>
  );
};

export default Users;
