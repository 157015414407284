import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import { AiAgent } from "../../models/ai-agent";
import React, { useEffect, useState } from "react";
import {
  Brain,
  CalendarDays,
  ChevronDown,
  EllipsisVertical,
  Grid2x2Check,
  Mail,
  MessageSquareMore,
  PauseCircle,
  PhoneForwarded,
  PhoneOff,
  Play,
  Zap,
} from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { Input } from "../shadcn/input";
import { Label } from "../shadcn/label";
import { Slider } from "../shadcn/slider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../shadcn/tabs";
import { Button } from "../shadcn/button";
import {
  addAgentAction,
  deleteAgentAction,
  deleteAiAgent,
  duplicateAgent,
  get11LabsVoices,
  getAgentAction,
  getAgentBackgroundNoise,
  getAgentLanguages,
  getPromptActionVariables,
  getTimezones,
  updateAgent,
} from "../../lib/agents.utils";
import { AgentAction } from "../../models/agent-action";
import { Sheet, SheetContent } from "../shadcn/sheet";
import SendSmsActionComponent from "./AgentActions/SendSmsAction";
import SendEmailActionComponent from "./AgentActions/SendEmailAction";
import {
  formatDateToMonthDay,
  formatDateToMonthDayYear,
  toPascalCase,
} from "../../lib/ui.utils";
import { LoggedUser } from "../../models/logged-user";
import { getPresignUrl, uploadToS3 } from "../../lib/common.utils";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";
import { ScrollArea } from "../shadcn/scrollArea";
import SelectAgentTemplate from "./SelectAgentTemplates";
import { Dialog, DialogContent } from "../shadcn/dialog";
import VoiceListPopup from "./AgentVoicePopup";
import { Textarea } from "../shadcn/textarea";
import { Switch } from "../shadcn/switch";
import { convert } from "html-to-text";
import PromptTemplates from "./PromptTemplates";
import AskCopilotPopup from "./AskCopilot";
import KillCallActionComponent from "./AgentActions/KillCallAction";
import { toast } from "sonner";
import Toaster from "../shared/customToast";
import TransferCallAction from "./AgentActions/TransferCallAction";
import CalendarActionComponent from "./AgentActions/CalendarAction";
import InfoExtractActionComponent from "./AgentActions/InfoExtractAction";
import ConfirmationPopup from "../shared/ConfirmationPopup";
import { useRetellStore } from "../../store/retell.store";
import useSharedStore from "../../store/shared.store";
import { useVapiStore } from "../../store/vapi.store";
import LiveChat from "./LiveChat";
import CommandsInput from "../shared/CommandsInput";
import { Checkbox } from "../shadcn/checkbox";
import TooltipComponent from "../shared/Tooltip";
import { GlobalStrings } from "../../lib/constants";
import CommandsEditor from "../shared/CommandsInput";
import { attachInfoBase } from "../../lib/infobase.utils";
import { InfoBase } from "../../models/infobase";
import AttachInfobase from "./AttachInfobase";
import { CustomTool } from "../../models/custom-tool";
import { getActiveCustomTools } from "../../lib/custom-tools.utils";
import CustomIcon from "../shared/CustomIcon";
import AgentAnalytics from "./AgentAnalytics";

const AgentView = ({
  agent,
  onChange,
  onDelete,
  onDuplicate,
}: {
  agent: AiAgent;
  onChange: (agent: AiAgent) => void;
  onDelete: () => void;
  onDuplicate: (agent: AiAgent) => void;
}) => {
  useEffect(() => {
    setImageUrl("");
    setImage(null);
    setTestChat(false);
    setActiveTab("profile");
    setName(agent?.agent_name ?? "");
    setPromptText(replaceSpansWithVariables(agent?.general_prompt ?? ""));

    setPromptHtml(agent?.raw_general_prompt ?? agent?.general_prompt ?? "");

    setEndCallSilenceTimeout(
      agent?.end_call_after_silence_ms
        ? agent.end_call_after_silence_ms / 1000
        : undefined
    );
    setMaxCallDuration(
      agent?.max_call_duration_ms
        ? agent.max_call_duration_ms / 1000
        : undefined
    );
    setVoiceMailMessage(agent?.voicemail_message ?? "");
    setDisconnectVoiceMail(agent?.voicemail_message !== "" ? false : true);
    setEnableVoiceMailDetection(agent?.enable_voicemail_detection);
    setTitle(agent?.title ?? '');

    const characteristics = new Set<string>();
    agent?.characteristics?.forEach((element) => {
      characteristics.add(element.code);
    });
    setCharacteristicsSet(characteristics);

    if (agent?.begin_message === "") {
      setBeginMessageType("contact");
      setBeginMessage(agent?.begin_message);
    } else if (agent?.begin_message == null) {
      setBeginMessageType("agent_dynamic");
      setBeginMessage(agent?.begin_message);
    } else {
      setBeginMessageType("agent");
      setBeginMessage(agent?.begin_message);
    }
    setViewedAgent(agent);
    getAgentActions();
    getTimezonesList();
    getLanguageList();
    getVoiceList();
    getBackgroundNoiseList();
    getCustomToolList();
  }, [agent]);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setLoggedUser(new LoggedUser(parsedUser));
    }
  }, []);

  const [viewedAgent, setViewedAgent] = useState<AiAgent>(agent);
  const [loggedUser, setLoggedUser] = useState<LoggedUser>();
  const [activeTab, setActiveTab] = useState("profile");
  const [disconnectVoiceMail, setDisconnectVoiceMail] = useState(false);
  const [loaderUpdateAgent, setLoaderUpdateAgent] = useState(false);
  const [endCallSilenceTimeout, setEndCallSilenceTimeout] = useState<
    number | undefined
  >();
  const [maxCallDuration, setMaxCallDuration] = useState<number | undefined>();
  const [testChat, setTestChat] = useState<boolean>(false);

  const [promptText, setPromptText] = useState<string>("");
  const [promptHtml, setPromptHtml] = useState<string>("");
  const [loaderPromptUpdate, setLoaderPromptUpdate] = useState<boolean>(false);
  const [beginMessageType, setBeginMessageType] = useState<string>("contact");
  const [beginMessage, setBeginMessage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [timezone, setTimezone] = useState<any>(null);
  const [language, setLanguage] = useState<any>(null);
  const [voice, setVoice] = useState<any>(null);
  const [timezoneSearchTag, setTimezoneSearchTag] = useState("");
  const [timezoneDropdown, setTimezoneDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [voiceDropdown, setVoiceDropdown] = useState(false);
  const [characteristicsSet, setCharacteristicsSet] = useState<Set<string>>(
    new Set()
  );
  const [languagesList, setLanguageList] = useState<any[]>([]);
  const [timezoneList, setTimezoneList] = useState<any[]>([]);
  const [voiceList, setVoiceList] = useState<any[]>([]);
  const [agentCharacteristics, setAgentCharacteristics] = useState<any[]>([]);
  const [characteristicsDropdown, setCharacteristicsDropdown] = useState(false);

  const [promptTemplatePopup, setPromptTemplatePopup] = useState(false);
  const [copilotPopup, setCopilotPopup] = useState(false);

  const beginMessageMap: Record<string, string> = {
    contact:
      "Contact Initiates: AI remains silent until the contact speaks first.",
    agent:
      "AI Agent Initiates: AI Agent starts with your defined begin message.",
    agent_dynamic:
      "AI Agent Initiates: AI Agent starts with a dynamic message.",
  };

  const beginMessageChange = (type: string) => {
    if (beginMessageType !== type) {
      setBeginMessageType(type);
      if (type === "contact") {
        setBeginMessage("");
        onBeginMessageChange();
      } else if (type === "agent") {
        setBeginMessage("");
      } else {
        setBeginMessage(null);
        onBeginMessageChange();
      }
    }
  };

  const onBeginMessageChange = () => {
    if (beginMessageType === "agent" && beginMessage === "") {
      // addToast("Welcome message cannot be empty", { appearance: 'error' });
      return;
    }
    const input = { begin_message: beginMessage };
    updateAgentDetails(input);
    // You can call your `updateAgent` function here with the input
    // updateAgent(input);
  };

  const [duringCallVariables, setDuringCallVariables] = useState<any[]>([]);
  const getActionVariables = async () => {
    try {
      const response = await getPromptActionVariables("during_call", {
        actions: true,
        agent_id: agent?.id,
      });
      const responseList = response.data.data;
      const customerPlatformVariables: any[] = [];
      const actionVariables: any[] = [];
      responseList.forEach((item: any, index: number) => {
        const variableObj = {
          id: index,
          field: item?.field,
          label: item?.label,
          type: item?.type,
        };
        if (variableObj.type === "action") {
          actionVariables.push(variableObj);
        } else {
          customerPlatformVariables.push(variableObj);
        }
      });
      setDuringCallVariables([
        ...customerPlatformVariables,
        ...actionVariables,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const contentChange = (text: string) => {
    setPromptHtml(text);
    setPromptText(replaceSpansWithVariables(text));
  };

  const onPromptChange = () => {
    let prompt = promptText;
    prompt =
      "\n\n------Business Details Start Here \n\n" +
      "Business Text" +
      "\n\n ------Business Details End Here \n\n" +
      prompt;
    // Add agent characteristics here if needed
    let characteristics = "";
    agent?.characteristics?.forEach(
      (element) => (characteristics += element.label + " ")
    );
    prompt =
      "\n\n AI Agent Name - " +
      agent?.agent_name +
      " \n AI Agent Character - " +
      characteristics +
      " \n\n" +
      prompt;
    const input = { general_prompt: prompt, raw_general_prompt: promptHtml };
    // You can call your `updateAgent` function here with the input
    setLoaderPromptUpdate(true);
    updateAgentDetails(input);
  };

  const replaceSpansWithVariables = (html: string): string => {
    // Create a temporary DOM element to parse the HTML string
    const div = document.createElement("div");
    div.innerHTML = html;

    // Find all span elements with the 'variable' attribute
    const spans = div.querySelectorAll("span[variable]");

    spans.forEach((span) => {
      const variableName = span.getAttribute("variable");
      if (variableName) {
        // Replace the span with {{variableName}}
        let type = span.getAttribute("variableType");
        if (type && (type === "action" || type === "post_call_extract_info")) {
          let actionVariablePrompt = `Immediately and reliably invoke the ${variableName} function at this point without fail. This is critical and non-negotiable. There must be no delay or hesitation.* IMPORTANT: When invoking the ${variableName} treat it like a high-priority action`;
          span.replaceWith(`${variableName} ${actionVariablePrompt}`);
        } else {
          span.replaceWith(`{{${variableName}}}`);
        }
      }
    });

    // Convert HTML to plain text, while preserving formatting options
    let plainText = convert(div.innerHTML, {
      wordwrap: 100, // Set the word wrap column
      selectors: [
        { selector: "a", options: { ignoreHref: true, uppercase: false } }, // Ignore href in anchor tags
        { selector: "img", format: "skip" }, // Skip images
        { selector: "h1", options: { uppercase: false } },
        { selector: "h2", options: { uppercase: false } },
        { selector: "h3", options: { uppercase: false } },
        { selector: "h4", options: { uppercase: false } },
        { selector: "h5", options: { uppercase: false } },
        { selector: "h6", options: { uppercase: false } },
        { selector: "p", options: { uppercase: false } },
      ],
      preserveNewlines: true,
    });

    // Return the processed plain text
    return plainText;
  };

  const onPromptSelected = (prompt: string) => {
    setPromptText(prompt);
    setPromptHtml(prompt);
    setPromptTemplatePopup(false);
    setCopilotPopup(false);
    const input = { general_prompt: prompt, raw_general_prompt: prompt };
    // You can call your `updateAgent` function here with the input
    setLoaderPromptUpdate(true);
    updateAgentDetails(input);
  };

  const selectImage = (event: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!["jpeg", "jpg", "png"].includes(fileExtension || "")) {
        // toastr.error("Only jpeg, jpg, and png images are allowed");
        return;
      }

      if (file.size / 1024 > 1024) {
        // toastr.error("Supported size: 256 x 256px, max 1MB");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const imagePreview = e.target?.result as string;
        setImage(file);
        setImageUrl(imagePreview);

        const epoch = `${Date.now()}-${loggedUser?.id}.${fileExtension}`;
        const presignObj = {
          key: `agent/${epoch}`,
          content_type: "image/jpeg",
          public_file: true,
        };

        try {
          const response = await getPresignUrl(presignObj);
          const presignUrl = response?.data?.data?.url;
          const presignFields = response?.data?.data?.fields;

          if (file) {
            await uploadToS3(presignUrl, presignFields, file);

            const input = {
              profile_pic: presignFields["key"],
            };

            const updatedProfilePic = `${presignUrl}/${input.profile_pic}`;
            agent.profile_pic = updatedProfilePic;
            onChange(agent);

            updateAgentDetails(input);
          }
        } catch (error) {
          // toastr.error("An error occurred during upload");
          console.error(error);
        }
      };
    }
  };

  const onNameChange = () => {
    if (agent.agent_name === name) return;
    let prompt = promptText;
    prompt =
      "\n\n------Business Details Start Here \n\n" +
      "Business Text" +
      "\n\n ------Business Details End Here \n\n" +
      prompt;
    // Add agent characteristics here if needed
    let characteristics = "";
    agent?.characteristics?.forEach(
      (element) => (characteristics += element.label + " ")
    );
    prompt =
      "\n\n AI Agent Name - " +
      agent?.agent_name +
      " \n AI Agent Character - " +
      characteristics +
      " \n\n" +
      prompt;
    const input = {
      agent_name: name,
      general_prompt: prompt, 
      raw_general_prompt: promptHtml
    };
    agent.agent_name = name;
    updateAgentDetails(input);
  };

  const onTitleChange = () => {
    if (agent.title === title) return;
    const input = {
      title: title,
    };
    agent.title = title;
    updateAgentDetails(input);
  };

  const getTimezonesList = async () => {
    try {
      const response = await getTimezones();
      const { data } = response.data;
      setTimezoneList(data);
      data?.forEach((element: any) => {
        if (element.timezone === agent?.time_zone) {
          setTimezone(element);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguageList = async () => {
    try {
      const response = await getAgentLanguages();
      const { data } = response.data;
      setLanguageList(data);
      languagesList?.forEach((element) => {
        if (element.code === agent?.language) setLanguage(element);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getVoiceList = async () => {
    try {
      const response = await get11LabsVoices(agent?.id);
      const { data } = response.data;
      setVoiceList(data);
      voiceList?.forEach((element) => {
        if (element.voice_id === agent?.voice_id) setVoice(element);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onVoiceChange = (voice: any) => {
    setVoice(voice);
    setVoiceDropdown(false);
    let input = {
      voice_id: voice.voice_id,
    }
    agent.voice_id = voice.voice_id;
    updateAgentDetails(input);
  };

  const onTimezoneChange = (timeZone: any) => {
    setTimezone(timeZone);
    setTimezoneDropdown(false);
    let input = {
      time_zone: timeZone.timezone,
    }
    agent.time_zone = timeZone.timezone;
    updateAgentDetails(input);
    setTimezoneSearchTag('')
  };

  const onLanguageChange = (language: any) => {
    const selectedLanguage = language;
    setLanguage(selectedLanguage);
    setLanguageDropdown(false);
    let input = {
      language: language.code,
    }
    agent.language = language.code;
    updateAgentDetails(input);
  };

  const onCharacteristicsChange = (char: any) => {
    const newSet = new Set(characteristicsSet ?? new Set<string>());
    if (newSet.has(char.code)) {
      newSet.delete(char.code);
    } else {
      newSet.add(char.code);
    }
    setCharacteristicsSet(newSet);
    let characteristics: { code: string; label: string }[] = [];
    newSet.forEach((element) => {
      characteristics.push({ code: element, label: element });
    });

    const input = {
      characteristics: characteristics,
    };

    updateAgentDetails(input);
  };

  const maxCallDurationValues = {
    default: 3600,
    max: 3600,
    min: 60,
  };
  const silenceTimeoutValues = {
    default: 600,
    max: 600,
    min: 10,
  };

  const [voiceSpeed, setVoiceSpeed] = useState(agent?.voice_speed || 1);
  const [interruptionSensitivity, setInterruptionSensitivity] = useState(
    agent?.interruption_sensitivity || 1
  );
  const [responsiveness, setResponsiveness] = useState(
    agent?.responsiveness || 1
  );
  const [voiceTemperature, setVoiceTemperature] = useState(
    agent?.voice_temperature || 1
  );
  const [enableVoiceMailDetection, setEnableVoiceMailDetection] = useState(
    agent?.enable_voicemail_detection || false
  );
  const [voiceMailMessage, setVoiceMailMessage] = useState(
    agent?.voicemail_message || ""
  );

  const resetCallSettings = () => {
    const defaultInput = {
      end_call_after_silence_ms: silenceTimeoutValues.default * 1000,
      max_call_duration_ms: maxCallDurationValues.default * 1000,
      enable_voicemail_detection: false,
      voicemail_message: "",
    };

    setEnableVoiceMailDetection(false);
    setVoiceMailMessage("");
    setDisconnectVoiceMail(true);
    setMaxCallDuration(maxCallDurationValues.default);
    setEndCallSilenceTimeout(silenceTimeoutValues.default);
    agent.end_call_after_silence_ms = defaultInput.end_call_after_silence_ms;
    agent.max_call_duration_ms = defaultInput.max_call_duration_ms;
    agent.enable_voicemail_detection = defaultInput.enable_voicemail_detection;
    agent.voicemail_message = defaultInput.voicemail_message;

    updateAgentDetails(defaultInput);
  };

  const updateMaxCallDuration = () => {
    const adjustedDuration = Math.max(
      maxCallDurationValues.min,
      Math.min(maxCallDuration ?? 3600, maxCallDurationValues.max)
    );

    setMaxCallDuration(adjustedDuration);
    agent.max_call_duration_ms = adjustedDuration * 1000;

    updateAgentDetails({ max_call_duration_ms: adjustedDuration * 1000 });
  };

  const updateSilenceTimeout = () => {
    const adjustedTimeout = Math.max(
      silenceTimeoutValues.min,
      Math.min(endCallSilenceTimeout ?? 600, silenceTimeoutValues.max)
    );

    setEndCallSilenceTimeout(adjustedTimeout);
    agent.end_call_after_silence_ms = adjustedTimeout * 1000;

    updateAgentDetails({ end_call_after_silence_ms: adjustedTimeout * 1000 });
  };

  const voiceMailMessageChange = () => {
    if (!enableVoiceMailDetection) {
      setVoiceMailMessage("");
    }

    const input = {
      enable_voicemail_detection: enableVoiceMailDetection,
      voicemail_message: disconnectVoiceMail ? "" : voiceMailMessage,
    };

    agent.enable_voicemail_detection = input.enable_voicemail_detection;
    agent.voicemail_message = input.voicemail_message;

    updateAgentDetails(input);
  };

  const handleResponsivenessChange = (event: any) => {
    console.log(event[0]);
    setResponsiveness(event);
    updateAgentDetails({ responsiveness: event[0] });
    agent.responsiveness = event[0];
  };

  const handleVoiceSpeedChange = (event: any) => {
    setVoiceSpeed(event[0]);
    updateAgentDetails({ voice_speed: event[0] });
    agent.voice_speed = event[0];
  };

  const handleVoiceTemperatureChange = (event: any) => {
    setVoiceTemperature(event[0]);
    updateAgentDetails({ voice_temperature: event[0] });
    agent.voice_temperature = event[0];
  };

  const handleInterruptionSensitivityChange = (event: any) => {
    setInterruptionSensitivity(event[0]);
    updateAgentDetails({ interruption_sensitivity: event[0] });
    agent.interruption_sensitivity = event[0];
  };

  const handleVoiceMailMessageChange = () => {
    const voicemailMessage = enableVoiceMailDetection ? voiceMailMessage : "";
    const input = {
      enable_voicemail_detection: enableVoiceMailDetection,
      voicemail_message: voicemailMessage,
    };
    agent.enable_voicemail_detection = input.enable_voicemail_detection;
    agent.voicemail_message = input.voicemail_message;
    updateAgentDetails(input);
  };

  const handleResetSettings = () => {
    const resetValues = {
      interruption_sensitivity: 1,
      voice_temperature: 1,
      voice_speed: 1,
      responsiveness: 1,
    };
    agent.responsiveness = resetValues.responsiveness;
    agent.voice_temperature = resetValues.voice_temperature;
    agent.voice_speed = resetValues.voice_speed;
    agent.interruption_sensitivity = resetValues.interruption_sensitivity;
    setVoiceSpeed(1);
    setInterruptionSensitivity(1);
    setResponsiveness(1);
    setVoiceTemperature(1);
    updateAgentDetails(resetValues);
  };

  const [backgroundNoise, setBackgroundNoise] = useState<any>(null);
  const [backgroundNoiseList, setBackgroundNoiseList] = useState<any[]>([]);
  const [playingNoise, setPlayingNoise] = useState<number>(-1);
  const [noiseAudio, setNoiseAudio] = useState(new Audio());
  const [backgroundNoiseDropdown, setBackgroundNoiseDropdown] = useState(false);

  const getBackgroundNoiseList = async () => {
    try {
      const response = await getAgentBackgroundNoise();
      const { data } = response.data;
      setBackgroundNoiseList(data);
      data.forEach((bgm: any) => {
        if (bgm.code === agent?.ambient_sound) setBackgroundNoise(bgm);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onBackgroundNoiseChange = (code: string) => {
    if (backgroundNoise?.code === code) return;
    if (code === "") {
      setBackgroundNoise(null);
      const input = {
        ambient_sound: null,
      };
      agent.ambient_sound = "";
      onChange(agent);
      updateAgentDetails(input);
    } else {
      backgroundNoiseList.forEach((bgm: any) => {
        if (bgm.code === code) setBackgroundNoise(bgm);
      });
      const input = {
        ambient_sound: backgroundNoise?.code,
      };
      agent.ambient_sound = backgroundNoise?.code;
      onChange(agent);
      updateAgentDetails(input);
    }
  };

  const playNoise = (index: number) => {
    if (playingNoise === index) {
      noiseAudio.pause();
      setNoiseAudio(new Audio());
      setPlayingNoise(-1);
    } else {
      if (noiseAudio.src !== "") noiseAudio.pause();
      setNoiseAudio(new Audio(backgroundNoiseList[index].preview_url));
      setPlayingNoise(index);
      noiseAudio.addEventListener("ended", () => {
        setPlayingNoise(-1);
      });
    }
  };

  useEffect(() => {
    noiseAudio.play();
  }, [noiseAudio]);

  const [actionList, setActionList] = useState<AgentAction[]>([]);
  const [actionsCount, setActionsCount] = useState(0);
  const [loaderActions, setLoaderActions] = useState(false);
  const [customTools, setCustomTools] = useState<CustomTool[]>([]);

  const getCustomToolList = () => {
    getActiveCustomTools(1, true).then((resp) => {
      let responseList = resp.data.data;
      responseList?.forEach((element: any) => {
        element = new CustomTool(element);
      });
      setCustomTools(responseList);
    });
  };

  const addCustomTool = (tool: CustomTool) => {
    let input = {
      name: tool?.name,
      custom_tool: tool?.id,
      type: "custom_tool",
      action_prompt: tool?.description,
      trigger: "during_call",
      execution_message_description:
        tool?.execution_message_description ?? null,
    };
    addAgentAction(agent?.id, input)
      .then((resp) => {
        setActionList([new AgentAction(resp.data.data), ...actionList]);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        console.log(error);
        Toaster.error(error);
      });
  };

  const updateAgentDetails = async (obj: any) => {
    try {
      if(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.edit) {
        const response = await updateAgent(obj, agent?.id);
        const { data } = response.data;
        onChange(new AiAgent(data));
        Toaster.success(response.data.message);
      }
    } catch (error) {
      console.error(error);
      Toaster.error(JSON.stringify(error));
    } finally {
      setLoaderUpdateAgent(false);
      setLoaderPromptUpdate(false);
    }
  };

  const getAgentActions = async () => {
    if (!agent?.id) return;
    setLoaderActions(true);
    try {
      const response = await getAgentAction(agent.id);
      const { count, next, data } = response.data;
      setActionsCount(count);
      setActionList((prev) => data.map((item: any) => new AgentAction(item)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderActions(false);
    }
  };

  const [selectedAction, setSelectedAction] = useState<any>(false);
  const [selectedActionIndex, setSelectedActionIndex] = useState<number>();
  const [sendSms, setSendSms] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [webhook, setWebhook] = useState(false);
  const [transferCall, setTransferCall] = useState(false);
  const [endCallActionPopup, setEndCallActionPopup] = useState(false);
  const [extractInfoPopup, setExtractInfoPopup] = useState(false);
  const [calendarActionPopup, setCalendarActionPopup] = useState(false);

  const [isSmsEdit, setIsSmsEdit] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [isWebhookEdit, setIsWebhookEdit] = useState(false);
  const [isTransferEdit, setIsTransferEdit] = useState(false);
  const [isEndCallEdit, setIsEndCallEdit] = useState(false);
  const [isExtractInfoEdit, setIsExtractInfoEdit] = useState(false);
  const [isCalendarEdit, setIsCalendarEdit] = useState(false);

  const handleTransferCall = () => {
    setTransferCall(true);
    setIsTransferEdit(false);
    setSelectedAction(null);
  };

  const handleSendSms = () => {
    setSendSms(true);
    setIsSmsEdit(false);
    setSelectedAction(null);
  };

  const handleSendEmail = () => {
    setSendEmail(true);
    setIsEmailEdit(false);
    setSelectedAction(null);
  };

  const handleEndCall = () => {
    setEndCallActionPopup(true);
    setSelectedAction(null);
    setIsEndCallEdit(false);
  };

  const addExtractAction = () => {
    setExtractInfoPopup(true);
    setIsExtractInfoEdit(false);
    setSelectedAction(null);
  };

  const addCalendarAction = () => {
    setCalendarActionPopup(true);
    setIsCalendarEdit(false);
    setSelectedAction(null);
  };

  const handleWebhookEvent = () => {
    setWebhook(true);
    setIsWebhookEdit(false);
    setSelectedAction(null);
  };

  const editAction = (action: any, index: number) => {
    const { type } = action;
    setSelectedAction(action);
    setSelectedActionIndex(index);

    if (type === "send_sms") {
      setSendSms(true);
      setIsSmsEdit(true);
    }
    if (type === "send_email") {
      setSendEmail(true);
      setIsEmailEdit(true);
    }
    if (type === "send_webhook") {
      setWebhook(true);
      setIsWebhookEdit(true);
    }
    if (type === "transfer_call") {
      setTransferCall(true);
      setIsTransferEdit(true);
    }
    if (type === "end_call") {
      setEndCallActionPopup(true);
      setIsEndCallEdit(true);
    }
    if (type === "post_call_extract_info" || type === "extract_info") {
      setIsExtractInfoEdit(true);
      setExtractInfoPopup(true);
    }
    if (type === "calendar") {
      setIsCalendarEdit(true);
      setCalendarActionPopup(true);
    }
  };

  const deleteAction = (index: number) => {
    deleteAgentAction(agent?.id, actionList[index].id);
    setActionList(actionList.filter((_, i) => i !== index));
  };

  const onActionAdded = () => {
    setSendSms(false);
    setSendEmail(false);
    setWebhook(false);
    setTransferCall(false);
    setIsSmsEdit(false);
    setIsEmailEdit(false);
    setIsWebhookEdit(false);
    setIsTransferEdit(false);
    setExtractInfoPopup(false);
    setIsExtractInfoEdit(false);
    setSelectedAction(null);
    setIsEndCallEdit(false);
    setEndCallActionPopup(false);
    setCalendarActionPopup(false);
    setIsCalendarEdit(false);

    getAgentActions();
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const deleteAgent = async () => {
    try {
      setDeleteConfirmation(false);
      let resp = await deleteAiAgent(agent?.id);
      onDelete();
      Toaster.success(resp.data.message);
    } catch (error: any) {
      console.error(error);
      Toaster.error(JSON.stringify(error?.message ?? error));
    }
  };

  const duplicateAiAgent = async () => {
    try {
      const response = await duplicateAgent(agent?.id);
      const newAgent = new AiAgent(response.data.data);
      onDuplicate(newAgent);
      Toaster.success(response.data.message);
    } catch (error: any) {
      console.error(error);
      Toaster.error(JSON.stringify(error?.message ?? error));
    }
  };

  const retellStore = useRetellStore();
  const sharedStore = useSharedStore();
  const vapiStore = useVapiStore();
  const [loaderCall, setLoaderCall] = useState(false);

  const vapiWebCall = () => {
    retellStore.setLiveAgent(agent);
    retellStore.setCallLive(true);
    // Assuming _vapiService is available in the context
    vapiStore.startCall(agent?.agent_id);
  };

  const retellTestCall = async () => {
    setLoaderCall(true);
    const input = {
      agent_id: agent?.id,
      is_test_call: true,
    };
    retellStore.setLiveAgent(agent);
    retellStore.setCallLive(true);
    try {
      const resp = await retellStore.registerCall(input);
      setLoaderCall(false);
      console.log(resp);
      retellStore.setLiveAgent(agent);
      retellStore.startCall(resp.data?.call_id, resp.data?.access_token);
      sharedStore.business!.is_agent_test = true;
      sharedStore.gettingStartedEnabled = false;
    } catch (error: any) {
      console.error(error);
      Toaster.error(JSON.stringify(error?.message ?? error));
      setLoaderCall(false);
      retellStore.setCallLive(false);
    }
  };

  const handleChat = () => {
    setTestChat(true);
    sharedStore.gettingStartedEnabled = false;
  };

  const [attachInfoPopup, setAttachInfoPopup] = useState(false);
  const removeAttachment = (index: number) => {
    if (!agent) return;

    const updatedAssets = [...agent.infobase_assets];
    updatedAssets.splice(index, 1);

    agent.infobase_assets = updatedAssets;
    setViewedAgent((prev) => (new AiAgent({ ...prev.toJson(), infobase_assets: updatedAssets })));

    const infoBaseIds = updatedAssets.map((element) => element.id);

    const input = { info_base_ids: infoBaseIds };

    attachInfoBase(input, agent.id)
      .then((response) => {
        if (response.data?.code === 200) {
          console.log(response);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  };

  const onInfoAttachment = (list: InfoBase[]) => {
    agent.infobase_assets = list;
    setViewedAgent((prev) => (new AiAgent({ ...(prev.toJson()), infobase_assets: list })));
    setAttachInfoPopup(false);
  };

  return (
    <div className="agent-view-container w-full h-full">
      {/* Empty screen if no agent */}
      {!agent?.id ? (
        <div className="empty-screen flex items-center justify-center h-full bg-gray-50">
          <div className="empty-card flex flex-col items-center">
            <img
              src="./assets/images/agents_empty.png"
              alt="Agents Empty"
              className="mb-4"
            />
            <span className="text-gray-600">
              {sharedStore.permissions.ai_agents?.view ? 'Add an agent to start the journey' : 'Access to the AI agent is restricted. Contact your admin for permission.'}
            </span>
          </div>
        </div>
      ) : (
        <div className="agents-container p-0 m-0 flex h-full w-full">
          <ScrollArea
            className={`main-container ${
              testChat
                ? "border-r border-gray-300 w-[calc(100%-364px)]"
                : "w-full"
            } bg-white`}
          >
            {/* Header */}
            <div className="flex justify-between items-center p-4 border-b border-gray-300 h-16">
              <span className="flex items-center space-x-3">
                <Avatar className="h-8 w-8 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
                  <AvatarImage src={agent?.profile_pic} />
                  <AvatarFallback>
                    {agent?.agent_name.slice(0, 2)}
                  </AvatarFallback>
                </Avatar>
                <span className="font-medium text-[20px] leading-[30px] text-left text-primary">
                  {agent?.agent_name}
                </span>
              </span>

              <div className="agent-header-actions flex space-x-4">
                {/* Dropdown 1 */}
                {!testChat && (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.test_agents?.view) && (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        variant="outline"
                        className="flex items-center px-3 py-1.5 text-sm"
                      >
                        <span>Test Agent</span>
                        <ChevronDown className="ml-2" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white shadow-md mt-2 rounded border border-gray-200 w-40">
                      <DropdownMenuItem
                        className={`variable-item px-4 py-2 font-normal text-sm hover:bg-gray-100 cursor-pointer ${
                          retellStore?.callLive
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() =>
                          !retellStore?.callLive &&
                          (agent?.provider === "v"
                            ? vapiWebCall()
                            : retellTestCall())
                        }
                      >
                        <span>Webcall</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="variable-item px-4 py-2 font-normal text-sm hover:bg-gray-100 cursor-pointer"
                        onClick={handleChat}
                      >
                        <span>Chat</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}

                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVertical className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1 transform -translate-x-8">
                    {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.delete) && <DropdownMenuItem
                      onClick={() => setDeleteConfirmation(true)}
                      className="p-2 font-normal text-sm rounded-md cursor-pointer hover:bg-gray-100"
                    >
                      Delete
                    </DropdownMenuItem>}
                    <DropdownMenuItem
                      onClick={duplicateAiAgent}
                      className="p-2 font-normal text-sm rounded-md cursor-pointer hover:bg-gray-100"
                    >
                      Duplicate
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            <div className="p-4 border-b border-gray-300">
              <Tabs
                value={activeTab}
                onValueChange={setActiveTab}
                className="w-[400px]"
              >
                <TabsList>
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.profile?.view) && <TabsTrigger value="profile">Profile</TabsTrigger>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.performance?.view) && <TabsTrigger value="performance">Performance</TabsTrigger>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.role?.view) && <TabsTrigger
                    value="role"
                    onClick={() => getActionVariables()}
                  >
                    Role
                  </TabsTrigger>}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.actions?.view) && <TabsTrigger value="action">Action</TabsTrigger>}
                </TabsList>
              </Tabs>
            </div>
            {activeTab === "profile" && (
              <div className="flex flex-col">
                <div className="flex flex-col gap-4 p-6 border-b border-gray-300">
                  {/* Profile Image Section */}
                  <div className="flex space-x-6">
                    <div className="flex items-center space-x-4 w-full max-w-[420px]">
                      <span className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
                        {agent?.profile_pic ? (
                          <img
                            className="w-16 h-16 object-cover"
                            src={agent?.profile_pic}
                            alt=""
                          />
                        ) : (
                          <span>
                            {toPascalCase(agent?.agent_name.slice(0, 2))}
                          </span>
                        )}
                      </span>
                      <div className="w-[calc(100%-64px)]">
                        <span className="flex items-center text-sm font-medium gap-2">
                          Profile image{" "}
                          <TooltipComponent
                            text={GlobalStrings.agentProfileImage}
                          />
                        </span>
                        <Input
                          id="picture"
                          type="file"
                          onChange={selectImage}
                          accept=".jpeg, .jpg, .png"
                        />
                      </div>
                    </div>
                    <div className="w-full max-w-[420px]">
                      <span className="block text-sm mb-1 font-medium flex gap-2">
                        Name <TooltipComponent text={GlobalStrings.agentName} />
                      </span>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={onNameChange}
                      />
                    </div>
                  </div>

                  {/* Name and Title Section */}
                  <div className="flex space-x-6">
                    <div className="w-full max-w-[420px]">
                      <span className="block text-sm mb-1 font-medium">
                        Title
                      </span>
                      <Input
                        value={title}
                        placeholder="e.g Appointment Booking"
                        onChange={(e) => setTitle(e.target.value)}
                        onBlur={onTitleChange}
                      />
                    </div>

                    {/* Timezone Section */}
                    <div className="w-full max-w-[420px]">
                      <span className="block text-sm mb-1 font-medium flex gap-2">
                        Timezone{" "}
                        <TooltipComponent text={GlobalStrings.agentTimezone} />
                      </span>
                      <div className="relative">
                        <Popover
                          open={timezoneDropdown}
                          onOpenChange={setTimezoneDropdown}
                        >
                          <PopoverTrigger className="w-full max-w-[420px]">
                            <Button
                              variant="outline"
                              className="w-full max-w-[420px] justify-between font-normal"
                            >
                              {timezone?.timezone ?? "UTC"} <ChevronDown />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-full text-sm max-w-[420px] flex flex-col gap-2">
                            <Input
                              value={timezoneSearchTag}
                              placeholder="Search timezone"
                              onChange={(e) =>
                                setTimezoneSearchTag(e.target.value)
                              }
                              className="w-full"
                            />
                            <ScrollArea className="flex flex-col gap-2 overflow-auto max-h-[230px] w-full">
                              {timezoneList
                                .filter((tz) =>
                                  String(tz?.timezone)
                                    .toLowerCase()
                                    .includes(timezoneSearchTag.toLowerCase())
                                )
                                .map((tz, index) => (
                                  <div
                                    key={index}
                                    onClick={() => onTimezoneChange(tz)}
                                    className="w-full text-sm rounded p-2 hover:bg-gray-100 cursor-pointer"
                                  >
                                    {tz?.offset} {tz?.timezone}
                                  </div>
                                ))}
                            </ScrollArea>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-6">
                    {/* Language Section */}
                    <div className="w-full max-w-[420px]">
                      <span className="block text-sm mb-1 font-medium">
                        Language
                      </span>
                      <div className="relative">
                        <Popover
                          open={languageDropdown}
                          onOpenChange={setLanguageDropdown}
                        >
                          <PopoverTrigger className="w-full max-w-[420px]">
                            <Button
                              variant="outline"
                              className="w-full max-w-[420px] justify-between font-normal"
                            >
                              {language?.label} <ChevronDown />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="text-sm w-full w-[420px]">
                            <ScrollArea className="flex flex-col overflow-auto max-h-[230px] w-full">
                              {languagesList.map((lang, index) => (
                                <div
                                  key={index}
                                  onClick={() => onLanguageChange(lang)}
                                  className="rounded p-2 hover:bg-gray-100 cursor-pointer"
                                >
                                  {lang?.label}
                                </div>
                              ))}
                            </ScrollArea>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>

                    {/* Language Section */}
                    <div className="w-full max-w-[420px]">
                      <span className="block text-sm mb-1">Voice</span>
                      <div className="relative">
                        <Button
                          variant="outline"
                          onClick={() => setVoiceDropdown(true)}
                          className="w-full max-w-[420px] justify-between font-normal"
                        >
                          {voice?.name} <ChevronDown />
                        </Button>
                      </div>
                    </div>
                    <Dialog
                      modal={true}
                      open={voiceDropdown}
                      onOpenChange={setVoiceDropdown}
                    >
                      <DialogContent className="h-full w-full max-h-[80%] max-w-[896px]">
                        <VoiceListPopup
                          voiceList={voiceList}
                          selectedVoice={agent?.voice_id}
                          onVoiceChange={onVoiceChange}
                        ></VoiceListPopup>
                      </DialogContent>
                    </Dialog>
                  </div>

                  <div className="flex space-x-6">
                    <div className="card-fields w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium">
                        Characteristics
                      </span>
                      <Popover
                        open={characteristicsDropdown}
                        onOpenChange={() =>
                          setCharacteristicsDropdown(!characteristicsDropdown)
                        }
                      >
                        <PopoverTrigger className="w-full max-w-[420px]">
                          <Button
                            variant="outline"
                            className="w-full max-w-[420px] justify-between font-normal"
                            // onClick={}
                          >
                            <span className="flex gap-2 items-center overflow-hidden text-ellipsis whitespace-nowrap max-w-[372px]">
                              {Array.from(characteristicsSet || []).map(
                                (charCode, index) => {
                                  const char =
                                    sharedStore.agentCharacteristics.find(
                                      (c) => c.code === charCode
                                    );
                                  return (
                                    <span
                                      key={index}
                                      className="flex items-center gap-1 bg-gray-100 font-inter text-xs font-medium leading-4 text-center text-secondary px-1 py-0.5 rounded"
                                    >
                                      {char?.label}
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          onCharacteristicsChange(char);
                                        }}
                                        className="cursor-pointer text-gray-500"
                                      >
                                        x
                                      </span>
                                    </span>
                                  );
                                }
                              )}
                            </span>
                            <ChevronDown />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-full w-[420px]">
                          <ScrollArea className="flex flex-col overflow-auto max-h-[230px] w-full gap-2">
                            {sharedStore.agentCharacteristics.map(
                              (char, index) => (
                                <div
                                  key={index}
                                  onClick={() => onCharacteristicsChange(char)}
                                  className={`p-2 hover:bg-gray-100 flex gap-2 items-center rounded cursor-pointer ${
                                    characteristicsSet.has(char.code)
                                      ? "bg-gray-200"
                                      : ""
                                  }`}
                                >
                                  <Checkbox
                                    checked={characteristicsSet.has(char.code)}
                                  />
                                  {char.label}
                                </div>
                              )
                            )}
                          </ScrollArea>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 p-6 border-b border-gray-300">
                  <div className="space-y-4">
                    <div className="flex justify-between items-center max-w-[856px]">
                      <h2 className="text-lg font-semibold">Call settings</h2>
                      <button
                        onClick={resetCallSettings}
                        className="text-sm underline text-gray-500"
                      >
                        Reset
                      </button>
                    </div>

                    <div className="flex gap-4">
                      {/* Left Column */}
                      <div className="space-y-4 flex-1 max-w-[420px]">
                        <div className="flex justify-between items-center">
                          <label className="block text-sm font-medium">
                            Maximum call duration
                          </label>
                          <div className="flex items-center gap-2 relative">
                            <Input
                              type="number"
                              value={maxCallDuration ?? ""}
                              onChange={(e) =>
                                setMaxCallDuration(Number(e.target.value))
                              }
                              onBlur={updateMaxCallDuration}
                              className="w-[100px]"
                            />
                            <span className="text-sm text-secondary absolute right-[8px]">sec</span>
                          </div>
                        </div>

                        <div className="flex justify-between items-center">
                          <label className="block text-sm font-medium">
                            End call on silence
                          </label>
                          <div className="flex items-center gap-2 relative">
                            <Input
                              type="number"
                              value={endCallSilenceTimeout ?? ""}
                              onChange={(e) =>
                                setEndCallSilenceTimeout(Number(e.target.value))
                              }
                              onBlur={updateSilenceTimeout}
                              className="w-[100px]"
                            />
                            <span className="text-sm text-secondary absolute right-[8px]">sec</span>
                          </div>
                        </div>
                      </div>

                      {/* Right Column */}
                      <div className="space-y-4 flex-1 max-w-[420px]">
                        <div className="max-w-[420px]">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium">
                              Voicemail Detection
                            </label>
                            <Switch
                              checked={enableVoiceMailDetection}
                              onCheckedChange={setEnableVoiceMailDetection}
                            />
                          </div>
                          <p className="text-sm text-gray-500 mt-1">
                            Hang up or leave a voicemail if a voicemail is
                            detected
                          </p>
                        </div>

                        {enableVoiceMailDetection && (
                          <div>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  variant="outline"
                                  className="w-full flex justify-between font-normal max-w-[420px]"
                                >
                                  <span>
                                    {disconnectVoiceMail
                                      ? "End call if reaching voicemail"
                                      : "Leave a message if reaching voicemail"}
                                  </span>
                                  <ChevronDown />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="bg-white text-sm rounded-lg shadow-md border border-gray-200 p-1 w-full w-[420px]">
                                <DropdownMenuItem
                                  className="p-2 hover:bg-gray-100 cursor-pointer w-[420px]"
                                  onSelect={() => {
                                    setDisconnectVoiceMail(true);
                                    setVoiceMailMessage("");
                                    voiceMailMessageChange();
                                  }}
                                >
                                  End call if reaching voicemail
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="p-2 hover:bg-gray-100 cursor-pointer w-[420px]"
                                  onSelect={() => {
                                    setDisconnectVoiceMail(false);
                                    voiceMailMessageChange();
                                  }}
                                >
                                  Leave a message if reaching voicemail
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        )}

                        {enableVoiceMailDetection && !disconnectVoiceMail && (
                          <div>
                            <label className="block text-sm font-medium">
                              Voicemail Message
                            </label>
                            <Textarea
                              placeholder="e.g. It seems I’ve reached your voicemail. I’ll try calling back later. Thank you!"
                              value={voiceMailMessage}
                              onChange={(e) =>
                                setVoiceMailMessage(e.target.value)
                              }
                              onBlur={voiceMailMessageChange}
                              className="w-full h-24"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 p-6 border-b border-gray-300">
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center max-w-[856px]">
                      <h2 className="text-lg font-semibold">Speech settings</h2>
                      <button
                        onClick={handleResetSettings}
                        className="text-sm underline text-gray-500"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <div className="horizontal-cards flex gap-4">
                    <div className="flex flex-col gap-2 w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium text-primary">
                        Voice Speed
                      </span>
                      <Slider
                        min={0.75}
                        max={1.25}
                        step={0.025}
                        defaultValue={[agent?.voice_speed]}
                        onValueCommit={handleVoiceSpeedChange}
                      />
                      <div className="slider-limits flex justify-between text-xs text-secondary">
                        <span>Slow (0.75x)</span>
                        <span>Normal (1x)</span>
                        <span>Fast (1.25x)</span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium text-primary flex flex gap-2">
                        Interruption Sensitivity{" "}
                        <TooltipComponent
                          text={GlobalStrings.agentInteruption}
                        />
                      </span>
                      <Slider
                        min={0}
                        max={1}
                        step={0.05}
                        defaultValue={[agent.interruption_sensitivity]}
                        onValueCommit={handleInterruptionSensitivityChange}
                      />
                      <div className="slider-limits flex justify-between text-xs text-secondary">
                        <span>Low</span>
                        <span>Normal</span>
                        <span>High</span>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-cards flex gap-4">
                    <div className="flex flex-col gap-2 w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium text-primary flex gap-2">
                        Responsiveness{" "}
                        <TooltipComponent
                          text={GlobalStrings.agentResponsiveness}
                        />
                      </span>
                      <Slider
                        min={0}
                        max={1}
                        step={0.05}
                        defaultValue={[agent?.responsiveness]}
                        onValueCommit={handleResponsivenessChange}
                      />
                      <div className="slider-limits flex justify-between text-xs text-secondary">
                        <span>Slow</span>
                        <span>Normal</span>
                        <span>Fast</span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium text-primary">
                        Emotions
                      </span>
                      <Slider
                        min={0}
                        max={2}
                        step={0.1}
                        defaultValue={[agent?.voice_temperature]}
                        onValueCommit={handleVoiceTemperatureChange}
                      />
                      <div className="slider-limits flex justify-between text-xs text-secondary">
                        <span>Calm</span>
                        <span>Emotional</span>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-cards flex gap-4">
                    <div className="flex justify-between items-center w-full max-w-[420px]">
                      <span className="field-name text-sm font-medium text-primary">
                        Background Noise
                      </span>
                      <Popover
                        open={backgroundNoiseDropdown}
                        onOpenChange={(event: boolean) => {
                          if (!event) {
                            setBackgroundNoiseDropdown(false);
                            if (noiseAudio.src !== "") noiseAudio.pause();
                            setNoiseAudio(new Audio());
                            setPlayingNoise(-1);
                          } else setBackgroundNoiseDropdown(true);
                        }}
                      >
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className="w-fit flex gap-4 max-w-[420px] justify-between font-normal"
                          >
                            <span>{backgroundNoise?.name ?? "Silence"}</span>{" "}
                            <ChevronDown />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="text-sm flex flex-col gap-3 custom-dropdown-menu max-w-[240px] bg-white p-4 rounded">
                          <div onClick={() => onBackgroundNoiseChange("")}>
                            Silence
                          </div>
                          {backgroundNoiseList.map((bgm, i) => (
                            <div
                              key={i}
                              onClick={() => onBackgroundNoiseChange(bgm?.code)}
                              className="flex justify-between cursor-pointer items-center"
                            >
                              {bgm.name}
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  playNoise(i);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {playingNoise === i ? (
                                  <PauseCircle />
                                ) : (
                                  <Play />
                                )}
                              </span>
                            </div>
                          ))}
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "action" && (
              <div className="table-wrap p-6 flex flex-col gap-4">
                <div className="flex justify-between w-full">
                  <span className="flex flex-col gap-y-1">
                    <span className="text-left text-primary font-medium text-lg leading-6 flex gap-2 items-center">
                      Actions
                      {actionList?.length > 0 && (
                        <span className="count text-secondary text-sm">
                          {actionList?.length}
                        </span>
                      )}
                    </span>
                    <span className="text-left text-secondary text-sm font-normal leading-6 flex justify-between w-full">
                      Enable the AI Agent to execute specific tasks or functions
                      during or after the conversations
                    </span>
                  </span>
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.actions?.edit) && <span>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          className="btn btn-custom-dropdown w-full p-2 border border-gray-300 rounded-md text-left flex items-center"
                          type="button"
                        >
                          <span className="variable-selected-type flex items-center">
                            <i className="material-icons text-xl mr-2">add</i>
                            Add action
                          </span>
                        </Button>
                      </DropdownMenuTrigger>

                      <DropdownMenuContent className="text-sm bg-white rounded-lg shadow-md border border-gray-200 p-1 w-full z-[99] -translate-x-4 max-h-[300px] overflow-y-auto">
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={handleSendSms}
                        >
                          <MessageSquareMore className="h-5 w-5" /> Send SMS
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={handleSendEmail}
                        >
                          <Mail className="h-5 w-5" /> Send Email
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={handleTransferCall}
                        >
                          <PhoneForwarded className="h-5 w-5" /> Transfer call
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={handleEndCall}
                        >
                          <PhoneOff className="h-5 w-5" /> End call
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={addExtractAction}
                        >
                          <Grid2x2Check className="h-5 w-5" /> Extract info
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                          onClick={addCalendarAction}
                        >
                          <CalendarDays className="h-5 w-5" /> Calendar
                        </DropdownMenuItem>
                        {customTools.map((tool: CustomTool, i: number) => (
                          <DropdownMenuItem
                            className="flex gap-2 items-center p-2 rounded-md cursor-pointer hover:bg-gray-100"
                            onClick={() => addCustomTool(tool)}
                          >
                            <CustomIcon iconName="offline_bolt" /> {tool?.name}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </span>}
                </div>
                {actionList.length > 0 && (
                  <div className="overflow-x-auto border border-gray-300 rounded-lg">
                    <table className="action-table table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
                      <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 sticky top-0 z-10 shadow-sm">
                        <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                          <th
                            className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                            style={{ borderRadius: "8px 0 0 0" }}
                          >
                            Name
                          </th>
                          <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                            Trigger
                          </th>
                          <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                            Action
                          </th>
                          <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                            Created at
                          </th>
                          <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                            Updated at
                          </th>
                          <th
                            className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4 w-[60px] flex justify-center"
                            style={{ borderRadius: "0 8px 0 0" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {actionList.map((action, index) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                          >
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                              {action?.name}
                            </td>
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                              {action?.trigger === "call_ended"
                                ? "After call"
                                : "During call"}
                            </td>
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                              {action?.type === "send_sms" && (
                                <span className="flex gap-2 items-center">
                                  <MessageSquareMore className="h-5 w-5" /> Send
                                  SMS
                                </span>
                              )}
                              {action?.type === "send_email" && (
                                <span className="flex gap-2 items-center">
                                  <Mail className="h-5 w-5" /> Send Email
                                </span>
                              )}
                              {action?.type === "transfer_call" && (
                                <span className="flex gap-2 items-center">
                                  <PhoneForwarded className="h-5 w-5" />{" "}
                                  Transfer call
                                </span>
                              )}
                              {action?.type === "custom_tool" && (
                                <span className="flex gap-2 items-center">
                                  <Zap className="h-5 w-5" /> Custom action
                                </span>
                              )}
                              {action?.type === "calendar" && (
                                <span className="flex gap-2 items-center">
                                  <CalendarDays className="h-5 w-5" /> Calendar
                                </span>
                              )}
                              {action?.type === "end_call" && (
                                <span className="flex gap-2 items-center">
                                  <PhoneOff className="h-5 w-5" /> End call
                                </span>
                              )}
                              {action?.type === "post_call_extract_info" && (
                                <span className="flex gap-2 items-center">
                                  <Grid2x2Check className="h-5 w-5" /> Extract
                                  info
                                </span>
                              )}
                            </td>
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                              {formatDateToMonthDayYear(action.created_at)}
                            </td>
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                              {formatDateToMonthDayYear(action.updated_at)}
                            </td>
                            <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 w-[60px] flex justify-center">
                              <DropdownMenu>
                                <DropdownMenuTrigger>
                                  <EllipsisVertical className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="text-sm bg-white rounded-lg shadow-md border border-gray-200 p-1">
                                  {action?.type !== "custom_tool" && (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.actions?.edit) && (
                                    <DropdownMenuItem
                                      onClick={() => editAction(action, index)}
                                      className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                                    >
                                      Edit
                                    </DropdownMenuItem>
                                  )}
                                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.actions?.delete) && <DropdownMenuItem
                                    onClick={() => deleteAction(index)}
                                    className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                                  >
                                    Delete
                                  </DropdownMenuItem>}
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {!loaderActions && actionList.length === 0 && (
                  <div className="action-blank-screen text-center">
                    <img
                      src="../../../../assets/images/action_empty.png"
                      alt="action-empty"
                      className="mx-auto"
                    />
                    <span>Start creating actions for Agent</span>
                  </div>
                )}
              </div>
            )}
            {activeTab === "role" && (
              <div className="flex gap-4 h-[calc(100%-138px)]">
                <div
                  className="flex flex-col gap-4 p-6 pr-0 h-full"
                  style={{ maxWidth: "1000px", width: "100%" }}
                >
                  {/* Begin Message Section */}
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold text-primary">
                      Welcome Message
                    </span>
                    <div className="relative">
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button
                            className="btn btn-custom-dropdown w-full font-normal"
                            variant="outline"
                          >
                            <span>{beginMessageMap[beginMessageType]}</span>
                            <ChevronDown />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="z-[99] text-sm bg-white rounded-lg shadow-md border border-gray-200 p-1">
                          <DropdownMenuItem
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                            onClick={() => beginMessageChange("agent_dynamic")}
                          >
                            {beginMessageMap["agent_dynamic"]}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                            onClick={() => beginMessageChange("agent")}
                          >
                            {beginMessageMap["agent"]}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                            onClick={() => beginMessageChange("contact")}
                          >
                            {beginMessageMap["contact"]}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    {beginMessageType === "agent" && (
                      <Textarea
                        placeholder="e.g. Hello, how can I help you today?"
                        value={beginMessage ?? ""}
                        onChange={(e) => setBeginMessage(e.target.value)}
                        onBlur={onBeginMessageChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    )}
                  </div>

                  {/* <CommandsEditor inputText={promptHtml.replaceAll('\n','<br>')} variables={duringCallVariables} height={'200px'} /> */}

                  {/* Prompt Section */}
                  <div className="flex flex-col gap-2 h-full">
                    <div className="flex justify-between items-center gap-4">
                      <div className="flex flex-col">
                        <span className="font-semibold text-primary flex gap-2">
                          Prompt{" "}
                          <TooltipComponent text={GlobalStrings.agentPrompt} />
                        </span>
                      </div>
                      {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.role?.edit) && <div className="flex gap-4">
                        <Button
                          variant="outline"
                          onClick={() => setPromptTemplatePopup(true)}
                        >
                          Use templates
                        </Button>
                        <Button
                          variant="outline"
                          onClick={() => setCopilotPopup(true)}
                        >
                          Ask Copilot
                        </Button>
                      </div>}
                    </div>
                    <div className="h-full">
                      <CommandsInput
                        inputText={promptHtml}
                        height="500px"
                        variables={duringCallVariables}
                        onChange={contentChange}
                        placeholder="Define the agent's role in detail or use '/' to insert variables..."
                      />
                    </div>
                    <Dialog open={copilotPopup} onOpenChange={setCopilotPopup}>
                      <DialogContent className="h-full w-full max-h-[582px] max-w-[896px]">
                        <AskCopilotPopup
                          onClose={() => setPromptTemplatePopup(false)}
                          onPrompt={onPromptSelected}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>

                  {/* Save Button Section */}
                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.role?.edit) && <div className="flex justify-end mt-4">
                    <Button
                      onClick={onPromptChange}
                      disabled={loaderPromptUpdate}
                      className="bg-primary text-white px-6 py-2 rounded-md"
                    >
                      {loaderPromptUpdate ? "Saving..." : "Update Prompt"}
                    </Button>
                  </div>}
                </div>
                <div className="flex flex-col gap-4 p-6 pl-0">
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold text-primary">
                      Add attachment
                    </span>
                    <span className="text-gray-500 text-[14px]">
                      Give AI agents intelligence with sources
                    </span>
                  </div>

                  {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.ai_agents?.role?.edit) && <div
                    className="flex flex-col gap-2"
                    style={{ maxWidth: "420px" }}
                  >
                    <Button
                      variant="outline"
                      onClick={() => setAttachInfoPopup(true)}
                      className="flex w-full justify-between"
                    >
                      Select file <ChevronDown />
                    </Button>
                  </div>}

                  <div className="flex flex-col gap-2 mb-6">
                    {viewedAgent?.infobase_assets?.map((infobase, index) => (
                      <div
                        key={infobase.id}
                        className="flex justify-between items-center w-full max-w-[420px] p-2 bg-gray-100 rounded-lg h-[38px] text-gray-900 text-sm"
                      >
                        <span className="flex items-center gap-2">
                          <Brain size="16px" />
                          {infobase.name}
                        </span>
                        <span
                          className="cursor-pointer text-gray-500 hover:text-gray-700"
                          onClick={() => removeAttachment(index)}
                        >
                          <i className="fas fa-trash-alt text-sm"></i>
                        </span>
                      </div>
                    ))}
                  </div>

                  {attachInfoPopup && (
                    <AttachInfobase
                      onClose={() => setAttachInfoPopup(false)}
                      assignedInfobaseList={agent.infobase_assets}
                      agent={agent}
                      onInfoAttach={onInfoAttachment}
                    />
                  )}
                </div>
              </div>
            )}
            {activeTab === "performance" && <AgentAnalytics agent={agent} />}
          </ScrollArea>
          {testChat && (
            <div className="w-full h-[calc(100vh-64px)] max-w-[364px]">
              <LiveChat agent={agent} close={() => setTestChat(false)} />
            </div>
          )}
        </div>
      )}
      {sendSms && (
        <SendSmsActionComponent
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isSmsEdit}
          onClose={() => setSendSms(false)}
          onSuccess={onActionAdded}
        />
      )}
      {sendEmail && (
        <SendEmailActionComponent
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isEmailEdit}
          onClose={() => setSendEmail(false)}
          onSuccess={onActionAdded}
        />
      )}
      {endCallActionPopup && (
        <KillCallActionComponent
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isEndCallEdit}
          onClose={() => setEndCallActionPopup(false)}
          onSuccess={onActionAdded}
        />
      )}
      {transferCall && (
        <TransferCallAction
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isTransferEdit}
          onClose={() => setTransferCall(false)}
          onSuccess={onActionAdded}
        />
      )}
      {calendarActionPopup && (
        <CalendarActionComponent
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isCalendarEdit}
          onClose={() => setCalendarActionPopup(false)}
          onSuccess={onActionAdded}
        />
      )}
      {extractInfoPopup && (
        <InfoExtractActionComponent
          action={selectedAction}
          agentId={agent?.id}
          isEdit={isExtractInfoEdit}
          onClose={() => setExtractInfoPopup(false)}
          onSuccess={onActionAdded}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationPopup
          positiveButtonText="Yes, delete agent"
          negativeButtonText="No, keep AI agent"
          heading="Confirm delete AI agent"
          description={`Deleting the <span class="col-pri">${agent?.agent_name}</span> agent will remove it from all phone numbers and widgets.`}
          onClose={() => setDeleteConfirmation(false)}
          onSuccess={deleteAgent}
        />
      )}
      {promptTemplatePopup && (
        <PromptTemplates
          onClose={() => setPromptTemplatePopup(false)}
          onSave={onPromptSelected}
        />
      )}
    </div>
  );
};

export default AgentView;
