import React, { useEffect, useState } from "react";
import useSharedStore from "../../store/shared.store";
import { useSubscriptionStore } from "../../store/subscription.store";
import { useWalletStore } from "../../store/wallet.store";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import Toaster from "../shared/customToast";
import { BillingDetails } from "../../models/billing-details";
import { Button } from "../shadcn/button";
import BillingForm from "../shared/BillingForm";
import NumberStepper from "../shared/NumberStepper";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { SubscriptionPlan } from "../../models/subscription_plan";

interface AddAddonsProps {
  addOn: SubscriptionPlan; // Add type
  type: string;
  onClose: (type: string) => void;
}

const AddAddons: React.FC<AddAddonsProps> = ({ addOn, type, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(50);
  const [min, setMin] = useState(1);
  const [rate, setRate] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [openBillingForm, setOpenBillingForm] = useState(false);
  const [defaultBilling, setDefaultBilling] = useState<BillingDetails>();
  const [billingDetailsUpdated, setBillingDetailsUpdated] = useState(false);

  const [stripe, setStripe] = useState<Stripe>();
  const walletStore = useWalletStore();
  const subscriptionStore = useSubscriptionStore();
  const sharedStore = useSharedStore();

  useEffect(() => {
    const keys = sharedStore.paymentGatewayKeys; // Replace with actual service
    const stripeInstance = loadStripe(
      keys.is_default ? keys.default : keys.stripe
    ).then((stripeResponse) => {if(stripeResponse) setStripe(stripeResponse)} );

    if (type === "minutes" || type === "voicemail") {
      const rate =
        type === "minutes"
          ? sharedStore.business?.subscription?.call_rate
          : sharedStore.business?.subscription?.voice_detection_rate;
      setRate(rate ?? 1);
      setMin(50);
      setCount(50);
    } else if (type === "agent" || type === "infobase" || type === "numbers") {
      setRate(addOn?.monthly_price || 1);
      setCount(1);
    }
    console.log(addOn)
    console.log(walletStore.selectedAddOn)
  }, [type, addOn]);

  useEffect(() => {
    setTotal(Number(((rate * count * 100) / 100).toFixed(2)));
  }, [rate, count]);

  const handleAddAddon = async () => {
    if ((type === "minutes" || type === "voicemail") && count < 50) {
      Toaster.error(`Minimum 50 ${type} needs to be added`);
      return;
    }

    if (subscriptionStore.billingDetails?.length === 0) {
      setOpenBillingForm(true);
      return;
    }

    setIsLoading(true);
    const billingId = subscriptionStore.billingDetails[0]?.id;

    try {
      let input;
      let response;

      switch (type) {
      case 'minutes':
        input = {
        amount: rate * count * 100,
        billing_id: billingId
        };
        setIsLoading(true);
        response = await walletStore.addMoney(input);
        break;

      case 'agent':
        input = {
        add_on_plan_id: addOn?.id,
        duration: 'monthly',
        unit: count,
        billing_id: billingId
        };
        setIsLoading(true);
        response = await subscriptionStore.buyAddOns(input);
        break;

      case 'infobase':
        input = {
        add_on_plan_id: addOn?.id,
        duration: 'monthly',
        add_on_type: 'info_base',
        unit: count,
        billing_id: billingId
        };
        setIsLoading(true);
        response = await subscriptionStore.buyAddOns(input);
        break;

      case 'numbers':
        input = {
        add_on_plan_id: addOn?.id,
        duration: 'monthly',
        unit: count,
        billing_id: billingId
        };
        setIsLoading(true);
        response = await subscriptionStore.buyAddOns(input);
        break;

      case 'voicemail':
        input = {
        amount: rate * count * 100,
        billing_id: billingId
        };
        setIsLoading(true);
        response = await walletStore.addVoiceDetectionRecharge(input);
        break;

      default:
        Toaster.error("Invalid addon type");
        setIsLoading(false);
        return;
      }

      const paymentId = response.data.pg_payment_id;
      localStorage.setItem('payment_id', JSON.stringify(response.data.payment_id));
      if (stripe) stripe.redirectToCheckout({ sessionId: paymentId });

    } catch (error: any) {
      Toaster.error(JSON.stringify(error?.message ?? error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBillingUpdate = (billing: BillingDetails) => {
    setDefaultBilling(new BillingDetails(billing));
    setOpenBillingForm(false);
    setBillingDetailsUpdated(true)
  };

  useEffect(() => {
    if(billingDetailsUpdated) handleAddAddon();
  }, [billingDetailsUpdated])

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose(type);
      }}
    >
      <DialogContent className="bg-white rounded-lg p-6 shadow-xl transform transition-all max-w-md w-full">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center text-lg font-semibold text-primary">
            <span>Buy more add-ons</span>
          </div>
          <span className="text-sm text-secondary">
            Choose add-ons to scale and optimize usage
          </span>
        </div>

        <div className="flex justify-between items-center py-4 min-w-[400px]">
          {type === "minutes" && (
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-primary">
                Minutes
              </span>
              <span className="text-sm text-secondary">
                ${rate} per minute (Minimum: 50 minutes)
              </span>
            </div>
          )}
          {type === "voicemail" && (
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-primary">
                Voicemail
              </span>
              <span className="text-sm text-secondary">
                ${rate} per voicemail (Minimum: 50 voicemails)
              </span>
            </div>
          )}
          {(type === "agent" || type === "infobase" || type === "numbers") && (
            <div className="flex flex-col gap-1">
              <span className="text-sm font-semibold text-primary">
                {type === "agent"
                  ? "AI Agent"
                  : type === "infobase"
                  ? "Infobase"
                  : "Phone Numbers"}
              </span>
              <span className="text-sm text-secondary">
                ${addOn?.monthly_price} per{" "}
                {type === "numbers" ? "number" : type}
              </span>
            </div>
          )}

          <div className="flex items-center gap-2">
            <NumberStepper
              value={count}
              onValueChange={setCount}
              min={min}
              max={5000}
            />
            <span className="text-lg font-semibold text-primary">${total}</span>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <Button variant="outline" onClick={() => onClose(type)}>
            Back
          </Button>
          <Button
            disabled={isLoading}
            className="ml-4"
            onClick={handleAddAddon}
          >
            <span>{isLoading ? "Loading..." : "Add"}</span>
          </Button>
        </div>
      </DialogContent>

      {openBillingForm && (
        <BillingForm
          onClose={() => setOpenBillingForm(false)}
          onSave={handleBillingUpdate}
        />
      )}
    </Dialog>
  );
};

export default AddAddons;
