import { create } from "zustand";
// import apiClient from "apiClient";
import apiClient from "../interceptors/axiosApiInterceptor";

interface AgencyState {
  brand: string;
  darkLogo: string;
  lightLogo: string;
  favicon: string;
  supportEmail: string;
  termsConditions: string;
  privacyPolicy: string;
  isAgency: boolean;
  isPricingEnabled: boolean;
  agencyPlans: any[];
  agencyAddOns: any[];
  ghlClientId: string;
  googleClientId: string;
  defaultGhl: boolean;
  defaultGoogleCal: boolean;
  setBrand: (brand: string) => void;
  setDarkLogo: (darkLogo: string) => void;
  setLightLogo: (lightLogo: string) => void;
  setFavicon: (favicon: string) => void;
  setSupportEmail: (supportEmail: string) => void;
  setTermsConditions: (termsConditions: string) => void;
  setPrivacyPolicy: (privacyPolicy: string) => void;
  setIsAgency: (isAgency: boolean) => void;
  setIsPricingEnabled: (isPricingEnabled: boolean) => void;
  setGhlClientId: (ghlClientId: string) => void;
  setGoogleClientId: (googleClientId: string) => void;
  setDefaultGhl: (defaultGhl: boolean) => void;
  setDefaultGoogleCal: (defaultGoogleCal: boolean) => void;
  getAgency: () => Promise<any>;
  updateAgency: (obj: any) => Promise<any>;
  updateAgencyDomain: (obj: any, id: number) => Promise<any>;
  deleteAgencyDomain: (id: number) => Promise<any>;
  getAssets: () => Promise<any>;
  getAgencyPlans: () => Promise<any>;
  getAgencyAddOns: () => Promise<any>;
  updateAgencyPlan: (id: number, obj: any) => Promise<any>;
  verifyDNS: () => Promise<any>;
  verifyEmail: () => Promise<any>;
  getAgencyBusiness: () => Promise<any>;
}

export const useAgencyStore = create<AgencyState>((set, get) => ({
  // Default state
  brand: "",
  darkLogo: "",
  lightLogo: "",
  favicon: "",
  supportEmail: "",
  termsConditions: "",
  privacyPolicy: "",
  isAgency: true,
  isPricingEnabled: true,
  agencyPlans: [],
  agencyAddOns: [],
  ghlClientId: "",
  googleClientId: "",
  defaultGhl: true,
  defaultGoogleCal: true,

  // Method to update state
  setBrand: (brand) => set({ brand }),
  setDarkLogo: (darkLogo) => set({ darkLogo }),
  setLightLogo: (lightLogo) => set({ lightLogo }),
  setFavicon: (favicon) => set({ favicon }),
  setSupportEmail: (supportEmail) => set({ supportEmail }),
  setTermsConditions: (termsConditions) => set({ termsConditions }),
  setPrivacyPolicy: (privacyPolicy) => set({ privacyPolicy }),
  setIsAgency: (isAgency) => set({ isAgency }),
  setIsPricingEnabled: (isPricingEnabled) => set({ isPricingEnabled }),
  setGhlClientId: (ghlClientId) => set({ ghlClientId }),
  setGoogleClientId: (googleClientId) => set({ googleClientId }),
  setDefaultGhl: (defaultGhl) => set({ defaultGhl }),
  setDefaultGoogleCal: (defaultGoogleCal) => set({ defaultGoogleCal }),

  // API Calls
  getAgency: async () => {
    try {
      const response = await apiClient.get("/agency/");
      return response.data;
    } catch (error) {
      console.error("Error fetching agency details", error);
      throw error;
    }
  },

  updateAgency: async (obj) => {
    try {
      const response = await apiClient.post("/agency/", obj);
      return response.data;
    } catch (error) {
      console.error("Error updating agency", error);
      throw error;
    }
  },

  updateAgencyDomain: async (obj, id) => {
    try {
      const response = await apiClient.put(`/agency/domain/${id}`, obj);
      return response.data;
    } catch (error) {
      console.error("Error updating agency domain", error);
      throw error;
    }
  },

  deleteAgencyDomain: async (id) => {
    try {
      const response = await apiClient.delete(`/agency/domain/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting agency domain", error);
      throw error;
    }
  },

  getAssets: async () => {
    try {
      const response = await apiClient.get("/conf/");
      return response.data;
    } catch (error) {
      console.error("Error fetching assets", error);
      throw error;
    }
  },

  getAgencyPlans: async () => {
    try {
      const response = await apiClient.get("/agency/plans");
      set({ agencyPlans: response.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching agency plans", error);
      throw error;
    }
  },

  getAgencyAddOns: async () => {
    try {
      const response = await apiClient.get("/agency/plans?plan_type=add-ons");
      set({ agencyAddOns: response.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching agency add-ons", error);
      throw error;
    }
  },

  updateAgencyPlan: async (id, obj) => {
    try {
      const response = await apiClient.put(`/agency/plans/${id}`, obj);
      return response.data;
    } catch (error) {
      console.error("Error updating agency plan", error);
      throw error;
    }
  },

  verifyDNS: async () => {
    try {
      const response = await apiClient.get("/agency/verify/dns");
      return response.data;
    } catch (error) {
      console.error("Error verifying DNS", error);
      throw error;
    }
  },

  verifyEmail: async () => {
    try {
      const response = await apiClient.get("/agency/verify/email");
      return response.data;
    } catch (error) {
      console.error("Error verifying email", error);
      throw error;
    }
  },

  getAgencyBusiness: async () => {
    try {
      const response = await apiClient.get("/partner/business");
      return response.data;
    } catch (error) {
      console.error("Error fetching agency business", error);
      throw error;
    }
  },
}));
