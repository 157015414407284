import { PhoneNumberUtil } from 'google-libphonenumber';
import libphonenumber from "google-libphonenumber";
import { marked } from "marked";
import { convert } from "html-to-text";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

/**
 * Formats a given phone number to E.164 format.
 * @param phoneNumber - The input phone number.
 * @param countryCode - The default country code if the number is in local format.
 * @returns The formatted E.164 number or an empty string if invalid.
 */
export const formatToE164 = (phoneNumber: string): string => {
  try {
    let countryCode = getRegionCodeFromNumber(phoneNumber);
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    return phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.E164);
  } catch (error) {
    console.error("Invalid phone number:", phoneNumber);
    return phoneNumber?.length > 0 ? (phoneNumber[0] === "+" ? phoneNumber : `+${phoneNumber}`) : "";
  }
};

export const getRegionCodeFromNumber = (number: string) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput('+' + number);
    return phoneUtil.getRegionCodeForNumber(phoneNumber);
  } catch (error) {
    return '';
  }
}

export const toPascalCase = (value: string | null | undefined) => {
  if (value === undefined || value === null) return '';
  return value.replace(/(\w)(\w*)/g, (g0: any, g1: string, g2: string) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const formatName = (value: any): string => {
  if (value === undefined || value === null) return '';
  if (value?.name) return value.name;
  else if (value?.first_name) return value.first_name;
  else if (value?.whatsapp_name) return value.whatsapp_name;
  else if (formatToE164(value?.mobile)) {
    return formatToE164(value.mobile);
  } else return '';
};


export const formatDateToMonthDay = (date: Date | string): string => {
  const options = { month: 'short', day: '2-digit' } as const;
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return dateObj?.toLocaleDateString('en-US', options);
};

export const formatDateToMonthDayYear = (date: Date | string): string => {
  const options = { month: 'short', day: '2-digit', year: 'numeric' } as const;
  const dateObj = new Date(date);
  return dateObj?.toLocaleDateString('en-US', options);
};

export const formatTime = (date: Date | string): string => {
  // Format the date as "h:mm AM/PM"
  const formattedTime = new Date(date)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',hour12: true, }).replace(" am", " AM").replace(" pm", " PM");
  return formattedTime;

};

export const formatDuration = (duration: number | undefined): string => {
  if (duration === undefined || duration === 0) return '00:00';
  const addZero = (num: number): string => (num < 10 ? '0' + num : num.toString());
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);
  if (hours === 0) return addZero(minutes) + ':' + addZero(seconds);
  return addZero(hours) + ':' + addZero(minutes) + ':' + addZero(seconds);
};

export const truncateFileName = (fileName: string, maxLength: number = 20) => {
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1) return fileName; // No extension found

  const name = fileName.substring(0, dotIndex);
  const extension = fileName.substring(dotIndex);

  if (name.length > maxLength) {
    return `${name.substring(0, maxLength)}...${extension}`;
  }
  return fileName;
};

export const isRecent = (value?: string | Date): boolean => {
  if (!value) return false;

  const msgDate = new Date(value);
  const currDate = new Date();
  const yesDate = new Date();
  yesDate.setDate(currDate.getDate() - 1); // Set to yesterday's date

  return msgDate > yesDate;
};

export const markdownToHtml = async (markdown: string = ""): Promise<string> => {
  return marked.parse(markdown); // Convert Markdown to HTML
};
