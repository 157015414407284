import React, { useState, useRef } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";
import { Smile } from "lucide-react";
import EmojiPicker from "emoji-picker-react";

interface MessageInputProps {
  message?: string;
  maxChar?: number;
  rows?: number;
  isButtons?: boolean;
  onTextChange?: (text: string) => void;
  onSend: (text: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  message,
  maxChar = 1024,
  rows = 5,
  isButtons = true,
  onTextChange,
  onSend,
}) => {
  const [text, setText] = useState(message || "");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const textareaRef = useRef<any>(null);

  const handleTextChange = (e: any) => {
    setText(e.target.value);
    if (onTextChange) onTextChange(e.target.value);
  };

  const addEmoji = (emoji: any) => {
    if (textareaRef.current) {
      const cursorPosition = textareaRef.current.selectionStart;
      const updatedText =
        text.substring(0, cursorPosition) +
        emoji.native +
        text.substring(cursorPosition);
      setText(updatedText);
      if (onTextChange) onTextChange(updatedText);
    }
  };

  return (
    <div className="border border-[var(--border-color)] rounded-lg p-2">
      <textarea
        ref={textareaRef}
        rows={rows}
        value={text}
        onChange={handleTextChange}
        placeholder="Enter text"
        className="w-full font-inter text-sm font-normal leading-6 text-left text-primary resize-none border-none bg-bg !focus:border-none focus:ring-0 focus:outline-none"
      ></textarea>
      <p className="flex justify-between items-center m-0">
        <span className="relative">
          <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker}>
            <PopoverTrigger asChild>
              <span>
                <Smile className="w-3.5 h-3.5" />
              </span>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <EmojiPicker
                onEmojiClick={(emojiObject: any) => {
                  addEmoji(emojiObject);
                  setShowEmojiPicker(false);
                }}
              />
            </PopoverContent>
          </Popover>
        </span>
        <span className="cursor-pointer" onClick={() => {
          if(text.length > 0) {
            setText(''); 
            onSend(text)
          }
        }}>
          <img
            src="../assets/svg/send.svg"
            alt="Send"
            style={{ width: 20, height: 20 }}
          />
        </span>
      </p>
    </div>
  );
};

export default MessageInput;
