import { create } from "zustand";
import apiClient from "../interceptors/axiosApiInterceptor";
import { Subscription } from "../models/subscription";
import { SubscriptionPlan } from "../models/subscription_plan";
import { BillingDetails } from "../models/billing-details";

interface SubscriptionState {
  currentPlan: SubscriptionPlan | null;
  plans: SubscriptionPlan[];
  subscription: Subscription | null;
  billingDetails: BillingDetails[];
  allSubscriptions: Subscription[];
  planToBuy: SubscriptionPlan | null;
  orderDetails: any;
  newPlans: SubscriptionPlan[];
  displayNewPlan: boolean;
  setSubscription: (subscription: any) => void;
  getCurrentSubscription: () => Promise<any>;
  getAllSubscriptions: () => Promise<any>;
  getPlans: () => Promise<any>;
  getInvoices: (type: string, page: number) => Promise<any>;
  createSubscriptionOrder: (obj: any) => Promise<any>;
  validateSubscriptionOrder: (obj: any) => Promise<any>;
  getBillingDetails: () => Promise<any>;
  addBillingDetails: (obj: any) => Promise<any>;
  updateBillingDetails: (obj: any, id: number) => Promise<any>;
  getRateCard: () => Promise<any>;
  getLoggedInRateCard: () => Promise<any>;
  getLoggedInCallRateCard: () => Promise<any>;
  checkNewVersions: () => Promise<any>;
  optInNewVersions: (obj: any) => Promise<any>;
  getAddOns: () => Promise<any>;
  buyAddOns: (obj: any) => Promise<any>;
  validateAddOns: (obj: any) => Promise<any>;
  getAddOnsUsage: () => Promise<any>;
  getLTD: (coupon: string) => Promise<any>;
  getStripeUrl: () => Promise<any>;
  buyStripePlan: (obj: any) => Promise<any>;
  setAllSubscriptions: (subscriptions: Subscription[]) => void;
  setBillingDetails: (billingDetails: BillingDetails[]) => void;
  setNewPlans: (plans: SubscriptionPlan[]) => void;
  setPlanToBuy: (plan: SubscriptionPlan | null) => void;
  setDisplayNewPlan: (display: boolean) => void;
}

export const useSubscriptionStore = create<SubscriptionState>((set, get) => ({
  currentPlan: null,
  plans: [],
  subscription: null,
  billingDetails: [],
  allSubscriptions: [],
  planToBuy: null,
  orderDetails: null,
  newPlans: [],
  displayNewPlan: false,

  setSubscription: (subscription) => set({ subscription: new Subscription(subscription) }),
  setAllSubscriptions: (subscriptions) => set({ allSubscriptions: subscriptions }),
  setBillingDetails: (billingDetails) => set({ billingDetails: billingDetails }),
  setNewPlans: (plans) => set({ newPlans: plans }),
  setPlanToBuy: (plan) => set({ planToBuy: plan }),
  setDisplayNewPlan: (display) => set({ displayNewPlan: display }),

  getCurrentSubscription: async () => {
    try {
      const response = await apiClient.get('/subscription?active=1');
      return response.data;
    } catch (error) {
      console.error("Error fetching current subscription", error);
      throw error;
    }
  },

  getAllSubscriptions: async () => {
    try {
      const response = await apiClient.get('/subscription');
      return response.data;
    } catch (error) {
      console.error("Error fetching all subscriptions", error);
      throw error;
    }
  },

  getPlans: async () => {
    try {
      const response = await apiClient.get('/subscription/plans');
      return response.data;
    } catch (error) {
      console.error("Error fetching plans", error);
      throw error;
    }
  },

  getInvoices: async (type, page) => {
    try {
      const response = await apiClient.get('/subscription/payments', {
        params: type === 'invoices' ? { page } : { page, trans: 'all' }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching invoices", error);
      throw error;
    }
  },

  createSubscriptionOrder: async (obj) => {
    try {
      const response = await apiClient.post('/subscription/order', obj);
      return response.data;
    } catch (error) {
      console.error("Error creating subscription order", error);
      throw error;
    }
  },

  validateSubscriptionOrder: async (obj) => {
    try {
      const response = await apiClient.put('/subscription/order', obj);
      return response.data;
    } catch (error) {
      console.error("Error validating subscription order", error);
      throw error;
    }
  },

  getBillingDetails: async () => {
    try {
      const response = await apiClient.get('/business/billing-address');
      return response.data;
    } catch (error) {
      console.error("Error fetching billing details", error);
      throw error;
    }
  },

  addBillingDetails: async (obj) => {
    try {
      const response = await apiClient.post('/business/billing-address', obj);
      return response.data;
    } catch (error) {
      console.error("Error adding billing details", error);
      throw error;
    }
  },

  updateBillingDetails: async (obj, id) => {
    try {
      const response = await apiClient.put(`/business/billing-address/${id}`, obj);
      return response.data;
    } catch (error) {
      console.error("Error updating billing details", error);
      throw error;
    }
  },

  getRateCard: async () => {
    try {
      const response = await apiClient.get('/rate/');
      return response.data;
    } catch (error) {
      console.error("Error fetching rate card", error);
      throw error;
    }
  },

  getLoggedInRateCard: async () => {
    try {
      const response = await apiClient.get('/rate/me');
      return response.data;
    } catch (error) {
      console.error("Error fetching logged-in rate card", error);
      throw error;
    }
  },

  getLoggedInCallRateCard: async () => {
    try {
      const response = await apiClient.get('/rate/voice/me');
      return response.data;
    } catch (error) {
      console.error("Error fetching logged-in call rate card", error);
      throw error;
    }
  },

  checkNewVersions: async () => {
    try {
      const response = await apiClient.get('/subscription/plans/opt-in');
      return response.data;
    } catch (error) {
      console.error("Error checking new versions", error);
      throw error;
    }
  },

  optInNewVersions: async (obj) => {
    try {
      const response = await apiClient.post('/subscription/plans/opt-in', obj);
      return response.data;
    } catch (error) {
      console.error("Error opting in new versions", error);
      throw error;
    }
  },

  getAddOns: async () => {
    try {
      const response = await apiClient.get('/subscription/plans?plan_type=add-ons');
      return response.data;
    } catch (error) {
      console.error("Error fetching add-ons", error);
      throw error;
    }
  },

  buyAddOns: async (obj) => {
    try {
      const response = await apiClient.post('/subscription/add-on', obj);
      return response.data;
    } catch (error) {
      console.error("Error buying add-ons", error);
      throw error;
    }
  },

  validateAddOns: async (obj) => {
    try {
      const response = await apiClient.put('/subscription/add-on', obj);
      return response.data;
    } catch (error) {
      console.error("Error validating add-ons", error);
      throw error;
    }
  },

  getAddOnsUsage: async () => {
    try {
      const response = await apiClient.get('/subscription/usage');
      return response.data;
    } catch (error) {
      console.error("Error fetching add-ons usage", error);
      throw error;
    }
  },

  getLTD: async (coupon) => {
    try {
      const response = await apiClient.get(`/subscription/ltd?code=${coupon}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching LTD", error);
      throw error;
    }
  },

  getStripeUrl: async () => {
    try {
      const response = await apiClient.get('/subscription/billing');
      return response.data;
    } catch (error) {
      console.error("Error fetching Stripe URL", error);
      throw error;
    }
  },

  buyStripePlan: async (obj) => {
    try {
      const response = await apiClient.post('/subscription/buy', obj);
      return response.data;
    } catch (error) {
      console.error("Error buying Stripe plan", error);
      throw error;
    }
  }
}));
