import apiClient from '../interceptors/axiosApiInterceptor';

export const getCustomTools = (page: number) =>
  apiClient.get(`/oneinbox/custom-tools?page=${page}`);

export const getActiveCustomTools = (page: number, dependent: boolean = false) => {
  const query = dependent
    ? `/oneinbox/custom-tools?is_enabled=true&r_type=dependent&page=${page}`
    : `/oneinbox/custom-tools?is_enabled=true&page=${page}`;
  return apiClient.get(query);
};

export const addCustomTools = (obj: any) =>
  apiClient.post('/oneinbox/custom-tools/', obj);

export const updateCustomTools = (obj: any, id: number) =>
  apiClient.put(`/oneinbox/custom-tools/${id}/`, obj);

export const deleteCustomTools = (id: number) =>
  apiClient.delete(`/oneinbox/custom-tools/${id}`);

export const searchCustomTools = (tag: string, page: number) =>
  apiClient.get(`/search/custom-tools?q=${tag}&page=${page}`);
