import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ghlOnboarding, googleAuth } from "../../../lib/authentication.utils";
import JsonText from "../../../static-text.json";
import Toaster from "../../shared/customToast";
import { useWebSocketStore } from "../../../store/websocket.store";
import { getIntegrations } from "../../../lib/integrations.utils";
import { useAgencyStore } from "../../../store/agency.store";
import CalConnection from "../../shared/CalConnection";
import { Button } from "../../shadcn/button";
import { ScrollArea } from "../../shadcn/scrollArea";
import useSharedStore from "../../../store/shared.store";

const IntegrationsList = () => {
  const [integrationsList, setIntegrationsList] = useState([]);
  const [ghl, setGhl] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] =
    useState(false);
  const [connectCalPopup, setConnectCalPopup] = useState(false);
  const [isCalConnected, setIsCalConnected] = useState(false);
  const [googleCode, setGoogleCode] = useState("");
  const [oauthLoader, setOauthLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const staticTexts = JsonText.integrations;

  const websocketStore = useWebSocketStore();
  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore()

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) {
      const input = { code };
      ghlOnboarding(input)
        .then((resp: any) => {
          console.log(resp);
          getIntegrationsList();
        })
        .catch((error: any) => {
          console.log(error);
          Toaster.error(error);
        });
    } else {
      getIntegrationsList();
    }

    const messageListener = (e: { data: { code: any; redirect_uri: any } }) => {
      console.log(e.data.code);
      if (e.data.code && e.data.redirect_uri && !oauthLoader) {
        oauthLogin(e.data);
      }
    };

    window.addEventListener("message", messageListener);

    websocketStore.integrationEvents$.subscribe((event: any) => {
      if (event.provider === "ghl") getIntegrationsList();
    });

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [location.search, oauthLoader]);

  const getIntegrationsList = () => {
    setLoader(true);
    getIntegrations()
      .then((resp: any) => {
        const integrations = resp.data.data;
        sharedStore.setIntegrationsList(integrations);
        setIntegrationsList(integrations);
        integrations.forEach((element: any) => {
          if (element?.provider === "ghl") setGhl(element);
          if (element?.provider === "google")
            setIsGoogleCalendarConnected(true);
          if (element?.provider === "cal") setIsCalConnected(true);
        });
        setLoader(false);
      })
      .catch((error: any) => {
        Toaster.error(error);
        setLoader(false);
      });
  };

  const openGhl = () => {
    navigate("/settings/integrations/ghl");
  };

  const openGcal = () => {
    navigate("/settings/integrations/google-calendar");
  };

  const openCal = () => {
    navigate("/settings/integrations/cal-dot-com");
  };

  const installGhl = () => {
    const width = 500;
    const height = 400;
    const screenLeft = window.screenLeft ?? window.screenX;
    const screenTop = window.screenTop ?? window.screenY;
    const screenWidth =
      window.innerWidth ??
      document.documentElement.clientWidth ??
      window.screen.width;
    const screenHeight =
      window.innerHeight ??
      document.documentElement.clientHeight ??
      window.screen.height;
    const left = screenLeft + (screenWidth - width) / 2;
    const top = screenTop + (screenHeight - height) / 2;
    const token = localStorage.getItem("token");
    window.open(
      `${agencyStore.defaultGhl ? 'https://app.oneinbox.ai' : window.location.origin}/hl-onboarding?token=${token}&ghl_client_id=${agencyStore.ghlClientId}`,
      "popupWindow",
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  const openPopupCalendar = () => {
    const width = 500;
    const height = 400;
    const screenLeft = window.screenLeft ?? window.screenX;
    const screenTop = window.screenTop ?? window.screenY;
    const screenWidth =
      window.innerWidth ??
      document.documentElement.clientWidth ??
      window.screen.width;
    const screenHeight =
      window.innerHeight ??
      document.documentElement.clientHeight ??
      window.screen.height;
    const left = screenLeft + (screenWidth - width) / 2;
    const top = screenTop + (screenHeight - height) / 2;
    window.open(
      `${agencyStore.defaultGoogleCal ? 'https://app.oneinbox.ai' : window.location.origin}/google-login?google_client_id=${agencyStore.googleClientId}`,
      "popupWindow",
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  const oauthLogin = (data: { code: React.SetStateAction<string> }) => {
    if (googleCode !== data.code) {
      setGoogleCode(data.code);
      console.log(data);
      setOauthLoader(true);
      googleAuth(data)
        .then((resp) => {
          console.log(resp);
          Toaster.success(resp.data.message);
          getIntegrationsList();
          setOauthLoader(false);
        })
        .catch((error) => {
          Toaster.error(error);
          setOauthLoader(false);
        });
    }
  };

  return (
    <div className="main-container h-100">
      <div className="h-16 p-4 border-b border-gray-300 flex justify-between text-primary font-inter text-lg font-medium leading-7 text-left">
        <span className="flex gap-2 items-center">Integrations</span>
      </div>
      <ScrollArea className="flex overflow-x-hidden h-[calc(100vh-64px)] ">
        <div className="flex flex-wrap gap-4 p-6">
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/gohighlevel.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.highlevel.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Calendar{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.highlevel.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <Button variant="outline" onClick={openGhl}>
                Details
              </Button>
              {!ghl?.id && (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && (
                <Button variant="outline" onClick={installGhl}>
                  Install app
                </Button>
              )}
              {ghl?.id && (
                <span
                  className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md"
                  style={{ cursor: "pointer", width: "fit-content" }}
                  onClick={openGhl}
                >
                  Installed
                </span>
              )}
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/cal.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.cal.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Calendar{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.cal.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <Button variant="outline" onClick={openCal}>
                Details
              </Button>
              {!isCalConnected && (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && (
                <Button
                  variant="outline"
                  onClick={() => setConnectCalPopup(true)}
                >
                  Install app
                </Button>
              )}
              {isCalConnected && (
                <span
                  className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md"
                  style={{ width: "fit-content" }}
                >
                  Installed
                </span>
              )}
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/google_calendar.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.google_calendar.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium w-fit">
                  {" "}
                  Calendar{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.google_calendar.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <Button variant="outline" onClick={openGcal}>
                Details
              </Button>
              {!isGoogleCalendarConnected && (sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.integrations?.edit) && (
                <Button variant="outline" onClick={openPopupCalendar}>
                  Install app
                </Button>
              )}
              {isGoogleCalendarConnected && (
                <span
                  className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md"
                  style={{ width: "fit-content" }}
                >
                  Installed
                </span>
              )}
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/twilio.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.twilio.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Voice & video{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.twilio.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/mail.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.email.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Communication{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.email.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/sms.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.sms.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Communication{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.sms.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/zoom.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.zoom.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Communication{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.zoom.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/meet.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.google_meet.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Communication{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.google_meet.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/zapier-icon.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.zapier.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Developer Tools{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.zapier.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/make.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.make.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Productivity{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.make.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/calendly.png"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.calendly.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Calendar{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.calendly.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
          <div className="integration-card flex flex-col gap-4 p-4 rounded-lg border border-gray-300 w-72 justify-between max-h-52">
            <span className="card-image-status flex h-14 items-center gap-4">
              <img
                src="../../../../assets/images/stripe-icon.svg"
                alt=""
                className="w-12 h-12 object-cover"
              />
              <span className="ver-8px flex flex-col gap-2">
                <span className="heading text-primary text-lg font-medium">
                  {" "}
                  {staticTexts.stripe.heading}{" "}
                </span>
                <span className="tag bg-gray-100 rounded-md px-2 py-1 text-center text-primary text-sm font-medium">
                  {" "}
                  Payment{" "}
                </span>
              </span>
            </span>
            <span className="subheading text-gray-500 text-sm font-normal">
              {" "}
              {staticTexts.stripe.subheading}{" "}
            </span>
            <div className="card-actions flex items-center justify-center gap-4">
              <span className="coming-soon bg-gray-50 p-2 w-full text-center text-gray-500 text-sm font-medium rounded-md">
                Coming soon
              </span>
            </div>
          </div>
        </div>
      </ScrollArea>
      {connectCalPopup && (
        <CalConnection
          onClose={() => setConnectCalPopup(false)}
          onSuccess={() => {
            setConnectCalPopup(false);
            setIsCalConnected(true);
            getIntegrationsList();
          }}
        />
      )}
    </div>
  );
};

export default IntegrationsList;
