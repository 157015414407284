import { AiAgent } from "../../../models/ai-agent";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toaster from "../../shared/customToast";
import { assignAgentToNumber, getAllAgents } from "../../../lib/agents.utils";
import {
  deleteInbox,
  deleteLabel,
  getCallLogs,
  getLabel,
  updateInbox,
} from "../../../lib/voice.utils";
import { Input } from "../../shadcn/input";
import {
  ArrowLeft,
  ChevronDown,
  EllipsisVertical,
  Frown,
  Meh,
  PhoneIncoming,
  PhoneOutgoing,
  Plus,
  Smile,
} from "lucide-react";
import { Button } from "../../shadcn/button";
import { Label as LabelModel } from "../../../models/label";
import Label from "../../shared/Label";
import { Skeleton } from "../../shadcn/skeleton";
import EmojiPicker from "emoji-picker-react";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown";
import AddLabel from "../../shared/AddLabel";
import Pagination from "../../shared/Pagination";
import { formatName, formatDuration, formatDateToMonthDayYear, formatTime, formatToE164 } from "../../../lib/ui.utils";
import ProfileIcon from "../../shared/ProfileIcon";
import CallLogDetails from "../../shared/CallLogDetails";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/popover";
import { useAgencyStore } from "../../../store/agency.store";
import useSharedStore from "../../../store/shared.store";


interface ViewNumberProps {
  viewedCloudNumber: any;
  back: (refresh: boolean) => void;
}

const ViewNumber: React.FC<ViewNumberProps> = ({ viewedCloudNumber, back }) => {
  const navigate = useNavigate();
  const [viewedNumberIcon, setViewedNumberIcon] = useState(
    viewedCloudNumber?.icon
  );
  const [viewedNumberName, setViewedNumberName] = useState(
    viewedCloudNumber?.name
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [agentList, setAgentList] = useState<AiAgent[]>(() => []);
  const [selectedAgent, setSelectedAgent] = useState(
    new AiAgent(viewedCloudNumber?.agent)
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showLabelPopup, setShowLabelPopup] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<LabelModel | undefined>(
    undefined
  );
  const [labelList, setLabelList] = useState<LabelModel[]>([]);
  const [paginationSize, setPaginationSize] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [loaderLabelList, setLoaderLabelList] = useState(false);
  const [callLogs, setCallLogs] = useState<any[]>([]);
  const [callLogsLoading, setCallLogsLoading] = useState(false);
  const [openedCallLog, setOpenedCallLog] = useState<any>(null);
  const [loaderPagination, setLoaderPagination] = useState(false);
  const [openedCallLogIndex, setOpenedCallLogIndex] = useState<number>(0);
  var paginatedPages = new Set<number>();
  const setPaginatedPages = (pages: Set<number>) => {
    paginatedPages = pages;
  };

  const sharedStore = useSharedStore()
  const agencyStore = useAgencyStore()

  useEffect(() => {
    if (viewedCloudNumber?.is_active) {
      getAllAgents(1,true).then((resp) => {
        setAgentList(resp.data.data);
      });
      getLabels(1);
    } else {
      getCallLogsList(1);
    }
  }, [viewedCloudNumber]);

  const getCallLogsList = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) {
      return;
    } else {
      setCallLogsLoading(true);
      // Replace with actual API call
      getCallLogs(pageNumber, { inbox_id: viewedCloudNumber?.id })
        .then((resp) => {
          if (resp.data?.code === 200) {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > paginationSize);
            if (callLogs.length !== resp.data.count)
              setCallLogs(new Array<any>(resp.data.count));
            setPaginationPage(pageNumber);
            if (pageNumber === 1) {
              for (let index = 0; index < resp.data.data.length; index++) {
                let call = resp.data.data[index];
                setCallLogs((prev) => {
                  const newCallLogs = [...prev];
                  newCallLogs[index] = call;
                  return newCallLogs;
                });
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            } else {
              for (
                let index = (pageNumber - 1) * paginationSize;
                index <
                (pageNumber - 1) * paginationSize + resp.data.data.length;
                index++
              ) {
                let call = resp.data.data[index % paginationSize];
                setCallLogs((prev) => {
                  const newCallLogs = [...prev];
                  newCallLogs[index] = call;
                  return newCallLogs;
                });
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            }
          }
          setCallLogsLoading(false);
          setLoaderPagination(false);
          setCallLogs([]);
        })
        .catch((error: any) => {
          setCallLogsLoading(false);
          setLoaderPagination(false);
          setCallLogs([]);
        });
    }
  };

  const getLabels = (pageNumber: number) => {
    setLoaderLabelList(true);
    getLabel(viewedCloudNumber?.id, pageNumber)
      .then((resp) => {
        setLabelList(resp.data.data);
        setLoaderLabelList(false);
      })
      .catch(() => {
        setLoaderLabelList(false);
      });
  };

  const updateInboxData = () => {
    const input = {
      id: viewedCloudNumber?.id,
      name: viewedNumberName,
      icon: viewedNumberIcon,
    };
    updateInbox(input)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const deleteNumberLabel = (id: number) => {
    deleteLabel(viewedCloudNumber?.id, id)
      .then((resp) => {
        if (resp.data.code === 200) {
          setLabelList(labelList.filter((label) => label.id !== id));
          Toaster.success(resp.data.message);
        }
      })
      .catch((error) => {
        Toaster.error(error);
        console.log(error);
      });
  };

  const deleteNumber = () => {
    setDeleteConfirmation(false);
    deleteInbox(viewedCloudNumber?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
        backToList();
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const assignAgent = (assignAgent: AiAgent) => {
    const input = { agent_id: assignAgent?.id };
    assignAgentToNumber(viewedCloudNumber?.id, input)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const backToList = () => {
    navigate("/settings/phone-numbers/");
    back(true);
  };

  const editLabel = (index: number) => {
    setSelectedLabel(labelList[index]);
    setShowLabelPopup(true);
  };

  const onLabelAdded = (label: LabelModel) => {
    setShowLabelPopup(false);
    setLabelList([label, ...(labelList || [])]);
  };

  const onLabelEdited = (label: LabelModel) => {
    setShowLabelPopup(false);
    setLabelList(
      labelList.map((item, idx) =>
        selectedLabel && idx === labelList.indexOf(selectedLabel) ? label : item
      )
    );
    setSelectedLabel(undefined);
  };

  const showCallLog = (call: any, index: number) => {
    setOpenedCallLog(call);
    setOpenedCallLogIndex(index);
  };

  return (
    <div className="h-full p-0">
      <div className="h-16 p-4 border-b border-gray-300 font-inter text-lg font-medium leading-7 text-left flex text-[var(--primary-color)] justify-between items-center">
        <div className="flex gap-1.5 w-full justify-between items-center">
          <span className="flex gap-1.5 items-center">
            <span className="cursor-pointer" onClick={backToList}>
              <ArrowLeft className="text-gray-600" />
            </span>
            {viewedCloudNumber?.name} ({formatToE164(viewedCloudNumber?.number)})
          </span>
        </div>
      </div>
      {viewedCloudNumber?.is_active ? (
        <>
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1">
              <span className="text-gray-700 font-medium">Icon</span>
              <span
                className="flex w-10 h-10 justify-center items-center gap-2.5 rounded-full border border-gray-300 bg-gray-200 cursor-pointer relative"
                onClick={() => setShowEmojiPicker(true)}
              >
                <span>{viewedNumberIcon}</span>
                <Popover
                  open={showEmojiPicker}
                  onOpenChange={setShowEmojiPicker}
                >
                  <PopoverTrigger asChild>
                    <Smile className="absolute bottom-0 right-0 w-3.5 h-3.5" />
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <EmojiPicker
                      onEmojiClick={(emojiObject) => {
                        setViewedNumberIcon(emojiObject.emoji);
                        setShowEmojiPicker(false);
                        updateInboxData();
                      }}
                    />
                  </PopoverContent>
                </Popover>
              </span>
            </div>
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-700 font-medium">
                Phone number name
              </span>
              <Input
                value={viewedNumberName}
                onChange={(e: { target: { value: any } }) =>
                  setViewedNumberName(e.target.value)
                }
                onBlur={updateInboxData}
              />
            </div>
          </div>
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1">
              <span className="text-lg font-medium text-gray-900">
                AI Agent
              </span>
              <span className="text-gray-600">
                Assign agent to phone number
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-700 font-medium">Select agent</span>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full flex justify-between font-normal"
                    style={{ width: "420px" }}
                  >
                    <span className="flex items-center gap-2">
                      {selectedAgent?.id && <img
                        src={
                          selectedAgent?.profile_pic
                        }
                        alt=""
                        className="w-6 h-6 rounded-full"
                      />}
                      {selectedAgent?.agent_name ?? "Select agent"}
                    </span>
                    <ChevronDown />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white border border-gray-300 w-[420px] rounded-lg p-2 max-h-[300px] overflow-auto">
                  {Array.isArray(agentList) &&
                    agentList.map((agent) => (
                      <DropdownMenuItem
                        key={agent.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                        onSelect={() => {
                          setSelectedAgent(agent);
                          assignAgent(agent);
                        }}
                      >
                        <img
                          src={
                            agent?.profile_pic ??
                            "../../../../../assets/images/avatar.png"
                          }
                          alt=""
                          className="w-6 h-6 rounded-full"
                        />
                        {agent?.agent_name}
                      </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-1">
                <span className="text-lg font-medium text-gray-900">
                  Labels
                </span>
                <span className="text-gray-600">
                  Manage all your labels in your workspace
                </span>
              </div>
              <Button
                variant="secondary"
                onClick={() => setShowLabelPopup(true)}
              >
                <Plus /> New label
              </Button>
            </div>
            {!loaderLabelList && labelList.length > 0 && (
              <div className="border border-gray-300 rounded-lg">
                <table className="w-full rounded-lg overflow-hidden">
                  {labelList.length > 0 && (
                    <thead>
                      <tr className="bg-gray-200 sticky top-0 z-10 shadow">
                        <th className="text-xs font-medium text-gray-600 p-2.5 text-left">
                          Label
                        </th>
                        <th className="text-xs font-medium text-gray-600 p-2.5 text-center">
                          Applied call logs
                        </th>
                        <th className="text-xs font-medium text-gray-600 p-2.5 text-left"></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {Array.isArray(labelList) &&
                      labelList.map((label, index) => (
                        <tr
                          key={index}
                          className="cursor-pointer border-t border-gray-300 hover:bg-gray-200"
                        >
                          <td className="p-4 text-sm text-gray-900">
                            <Label labelElement={label}></Label>
                          </td>
                          <td className="p-4 text-sm text-center text-gray-900">
                            {label?.call_count}
                          </td>
                          <td className="p-4 flex justify-end">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <button className="p-0 border-none bg-transparent">
                                  <EllipsisVertical className="w-5 h-5 text-gray-500" />
                                </button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="bg-white border border-gray-300 w-40 rounded-lg p-2.5">
                                <DropdownMenuItem
                                  className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                  onSelect={() => {
                                    setSelectedLabel(label);
                                    setShowLabelPopup(true);
                                  }}
                                >
                                  Edit
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                  onSelect={() => deleteNumberLabel(label.id)}
                                >
                                  Delete
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {loaderLabelList && (
              <div className="flex flex-col gap-4 p-4 border border-gray-300 rounded-lg">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    className="h-4 bg-gray-300 rounded w-full"
                  ></Skeleton>
                ))}
              </div>
            )}
          </div>
          {!agencyStore.isAgency && <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1 max-w-xs">
              <span className="text-gray-500 font-medium">
                Telephony provider
              </span>
              <span className="font-medium text-gray-900">
                {viewedCloudNumber?.provider}
              </span>
            </div>
          </div>}
          {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.phone_numbers["number_"+viewedCloudNumber?.id]?.edit) && <div className="flex flex-col gap-6 p-6 border-b border-gray-300">
            <div className="flex flex-col gap-1">
              <span className="text-lg font-medium text-gray-900">Delete</span>
              <span className="text-gray-600">
                Delete your phone number from your account
              </span>
            </div>
            <Button
              variant="destructive"
              className="w-fit"
              onClick={() => setDeleteConfirmation(true)}
            >
              Delete phone number
            </Button>
          </div>}
        </>
      ) : (
        <div className="flex flex-col overflow-auto h-[calc(100%-64px)]">
          {!callLogsLoading && callLogs.length > 0 && (
            <div className="border border-border-color rounded-lg">
              <table className="w-full rounded-lg overflow-hidden">
                <thead>
                  <tr className="border-b border-border-color bg-[var(--hover-color)]">
                    <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                      Contacts
                    </th>
                    <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                      Type
                    </th>
                    <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                      Time
                    </th>
                    <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                      Duration
                    </th>
                    <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                      Sentiment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {callLogs.map((call, i) => (
                    <tr
                      key={i}
                      className="cursor-pointer border-b border-border-color hover:bg-[var(--hover-color)]"
                      onClick={() => showCallLog(call, i)}
                    >
                      <td className="flex gap-2 align-center p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                        <ProfileIcon
                          iconSize="16px"
                          size="20px"
                          name={call?.customer?.name}
                        />
                        {call?.customer
                          ? formatName(call?.customer)
                          : "Test web call"}
                      </td>
                      <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                        <span className="flex gap-2 items-center">
                          {call?.direction === "inbound" && (
                            <PhoneIncoming color="#64748B" size="20px" />
                          )}
                          {call?.direction === "outbound" && (
                            <PhoneOutgoing color="#64748B" size="20px" />
                          )}
                          <span>{call?.direction}</span>
                        </span>
                      </td>
                      <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {formatDateToMonthDayYear(call?.created_at)},{" "}
                        {formatTime(call?.created_at)}
                      </td>
                      <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                        <span className="flex gap-2 items-center">
                          {call?.end_timestamp && call?.start_timestamp
                            ? formatDuration(
                                call?.end_timestamp / 1000 -
                                  call?.start_timestamp / 1000
                              )
                            : "-"}
                        </span>
                      </td>
                      <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                        <span className="flex gap-2 items-center">
                          {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                            "neutral" && <Meh size="16px" color="#2563EB" />}
                          {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                            "positive" && <Smile size="16px" color="#16A34A" />}
                          {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                            "negative" && <Frown size="16px" color="#DC2626" />}
                          <span>{call?.call_analysis?.user_sentiment}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {callLogsLoading && (
            <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="w-full gap-4 flex flex-col">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton key={index} className="h-8 w-full" />
                  ))}
                </div>
              ))}
            </div>
          )}
          {!callLogsLoading && callLogs.length === 0 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-4 font-inter text-base font-medium text-left text-[var(--primary-color)]">
                <img
                  src="../../../../assets/images/logs_empty.png"
                  alt="No call logs found"
                />
                <span>No call logs found</span>
              </div>
            </div>
          )}
          {pagination && !callLogsLoading && (
            <div className="flex justify-center mt-4">
              <Pagination
                currentPage={paginationPage}
                listLength={callLogs.length}
                paginationSize={paginationSize}
                onPageChange={(page) => {
                  setPaginationPage(page);
                  getCallLogsList(page);
                }}
              />
            </div>
          )}
        </div>
      )}
      {deleteConfirmation && (
        <ConfirmationPopup
          positiveButtonText="Yes, delete number"
          negativeButtonText="No, keep number"
          heading="Confirm delete phone number"
          description={`Deleting the phone number <span class="col-pri">${formatToE164(viewedCloudNumber?.number)}</span> will result in losing access to all calls associated with this number.`}
          onClose={() => setDeleteConfirmation(false)}
          onSuccess={deleteNumber}
        />
      )}
      {openedCallLog?.id && (
        <CallLogDetails
          callLog={openedCallLog}
          close={() => setOpenedCallLog(null)}
          customerUpdate={(customer) =>
            (callLogs[openedCallLogIndex].customer = customer)
          }
          callUpdate={(call) => (callLogs[openedCallLogIndex] = call)}
        />
      )}
      {showLabelPopup && (
        <AddLabel
          inbox={viewedCloudNumber?.id ?? 0}
          label={selectedLabel ?? undefined}
          onAdded={(event) => onLabelAdded(event)}
          onEdited={(event) => onLabelEdited(event)}
          onClose={() => setShowLabelPopup(false)}
        />
      )}
    </div>
  );
};

export default ViewNumber;
