import apiClient from '../interceptors/axiosApiInterceptor';

export const getPaymentGatewayKeys = () =>
  apiClient.get('/business/p-access');

export const addPaymentGateway = (obj: any) =>
  apiClient.post('/business/payment-gateway', obj);

export const deletePaymentGatewayKeys = () =>
  apiClient.delete('/business/payment-gateway');

export const getAgencyPaymentGatewayKeys = () =>
  apiClient.get('/business/payment-gateway');
