import React, { useState, useEffect, useContext } from "react";
import { AiAgent } from "../../../models/ai-agent";
import { addAgent } from "../../../lib/agents.utils";
import Toaster from "../../shared/customToast";
import { AgentTemplate } from "../../../models/agent-templates";
import AgentTemplateComponent from "../../shared/AgentTemplate";

interface WelcomeForm1Props {
  agentTemplates: AgentTemplate[];
  onSkip: () => void;
  onNext: (agent: AiAgent) => void;
}

const WelcomeForm1: React.FC<WelcomeForm1Props> = ({ agentTemplates, onSkip, onNext }) => {
  const [loader, setLoader] = useState(0);
  const [audioPlayingId, setAudioPlayingId] = useState(0);

  const createAgent = (agent: AgentTemplate) => {
    const profilePic = agent?.voice?.avatar_url.split("oneinbox.ai/")[1];
    const input = {
      agent_name: agent?.agent_name,
      language: agent?.language,
      voice_id: agent.voice?.voice_id,
      general_prompt: agent?.general_prompt,
      profile_pic: profilePic,
    };
    setLoader(agent?.id);
    addAgent(input)
      .then((resp) => {
        Toaster.success(resp.data.message);
        onNext(new AiAgent(resp.data.data));
        setLoader(0);
      })
      .catch((error) => {
        console.error(error);
        setLoader(0);
      });
  };

  return (
    <div className="flex flex-col gap-4 h-full p-4 overflow-auto">
      {/* Welcome Stepper */}
      <div className="flex items-center justify-between text-sm font-medium text-[var(--secondary-color)] p-4">
        <span className="cursor-pointer"></span>
        <div className="flex gap-4 items-center">
          <span className="font-semibold text-purple-700">Create AI agent</span>
          <hr className="w-20 h-px bg-gray-300 border-none" />
          <span>Test AI agent</span>
          <hr className="w-20 h-px bg-gray-300 border-none" />
          <span>Deploy AI agent</span>
          <hr className="w-20 h-px bg-gray-300 border-none" />
          <span>Go live</span>
        </div>
        <span className="cursor-pointer text-[var(--secondary-color)] hover:underline" onClick={onSkip}>Skip</span>
      </div>
      
      <div className="flex justify-center items-center">
        <div className="flex flex-col gap-1">
          <span className="text-xl font-semibold text-[var(--primary-color)]">Create your first AI Agent</span>
          <span className="text-sm font-normal text-[var(--secondary-color)]">Use our built-in template or start from scratch.</span>
        </div>
      </div>
      <div className="p-6 flex flex-col gap-8">
        <div className="flex flex-wrap gap-4 justify-center">
          {agentTemplates.map((agent: AgentTemplate) => (
            <AgentTemplateComponent
              key={agent.id}
              agent={agent}
              loading={agent.id === loader}
              disable={loader > 0}
              playing={audioPlayingId}
              onCreate={() => createAgent(agent)}
              onAudioStarted={() => setAudioPlayingId(agent.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WelcomeForm1;
