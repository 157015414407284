import { getLogs } from "../../lib/business.utils";
import React, { useState, useEffect } from "react";
import Toaster from "./customToast";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { ScrollArea } from "../shadcn/scrollArea";
import Pagination from "./Pagination";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/accordian";
import { ChevronDown } from "lucide-react";
import { formatDateToMonthDayYear, formatTime } from "../../lib/ui.utils";

interface WebhookLogsProps {
  onClose: () => void;
  params: any;
}

const WebhookLogs: React.FC<WebhookLogsProps> = ({ onClose, params }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [customToolList, setCustomToolList] = useState<any[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState<boolean>(false);

  const [openItem, setOpenItem] = useState<string>("");

  useEffect(() => {
    getCustomTools(1);
  }, [params]);

  const getCustomTools = async (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    const input: Record<string, any> = { page: pageNumber };
    if (params?.type === "action") input["custom_tool"] = params?.id;
    if (params?.type === "webhook") input["webhook"] = params?.id;

    try {
      const resp = await getLogs(input);
      if (resp.data.code === 200) {
        setPaginationSize(resp.data.size);
        setPagination(resp.data.count > resp.data.size);
        const newCustomToolList = [...customToolList];
        if (pageNumber === 1) {
          resp.data.data.forEach((item: any, index: number) => {
            newCustomToolList[index] = {
              ...item,
              method: JSON.stringify(item.request_body, null, 2),
              endpoint: JSON.stringify(item.response, null, 2),
            };
          });
        } else {
          resp.data.data.forEach((item: any, index: number) => {
            newCustomToolList[(pageNumber - 1) * paginationSize + index] = {
              ...item,
              method: JSON.stringify(item.request_body, null, 2),
              endpoint: JSON.stringify(item.response, null, 2),
            };
          });
        }
        setCustomToolList(newCustomToolList);
        setPaginatedPages(new Set(paginatedPages).add(pageNumber));
      } else {
        Toaster.error(resp.data.message);
      }
    } catch (error: any) {
      Toaster.error(JSON.stringify(error?.message ?? error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <DialogContent className="max-w-[782px] max-h-[653px]">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center text-primary font-inter text-xl font-semibold leading-[30px]">
            <span>
              {params?.type === "action" ? "Action logs" : "Webhook logs"}
            </span>
          </div>
          <span className="text-secondary font-inter text-sm font-normal leading-[21px]">
            {params?.type === "action"
              ? "This logs contains all requests made by your action."
              : "This logs contains all requests made by webhook endpoint."}
          </span>
        </div>
        <ScrollArea className="flex flex-col overflow-x-hidden w-[740px] h-full max-h-[550px]">
          {!isLoading && customToolList.length > 0 && (
            <div className="border border-border-bd-1 rounded-lg">
              <div className="flex bg-gray-50 rounded-t-lg font-inter text-sm font-medium leading-[19.5px] text-secondary h-[38px] items-center">
                <span className="w-[240px] pl-2.5">Status</span>
                <span className="w-[240px] pl-2.5">Event ID</span>
                <span className="w-[240px] pl-2.5">Date</span>
              </div>
              <div className="pt-4">
                <Accordion
                  type="single"
                  collapsible
                  value={openItem}
                  onValueChange={(value) =>
                    setOpenItem(value === openItem ? "" : value)
                  }
                >
                  {customToolList.map((item, index) => {
                    const isOpen = openItem === `item${index}`;

                    return (
                      <AccordionItem key={index} value={`item${index}`}>
                        <AccordionTrigger className="flex items-center h-[53px] font-inter text-sm font-normal leading-[21px] text-primary after:hidden">
                          <span className="flex gap-2 items-center w-[240px] pl-2.5">
                            <ChevronDown
                              className={`w-4 h-4 transition-transform duration-300 ${
                                isOpen ? "rotate-180" : "rotate-[-90deg]"
                              }`}
                            />
                            <span
                              className={`px-2 py-1 rounded-md text-center text-sm font-medium flex items-center gap-2 ${
                                item?.response?.status === 200
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              }`}
                            >
                              {item?.response?.status === 200
                                ? "Delivered"
                                : "Failed"}
                            </span>
                          </span>
                          <span className="w-[240px] pl-2.5 text-secondary">
                            {item?.event_id}
                          </span>
                          <span className="w-[240px] pl-2.5">
                            {formatDateToMonthDayYear(item?.created_at)}{" "}
                            {formatTime(item?.created_at)}
                          </span>
                        </AccordionTrigger>
                        <AccordionContent className="flex flex-col gap-2.5 p-2.5 text-secondary">
                          <span>Request:</span>
                          <pre className="w-[700px] font-inter text-sm leading-[19.5px] text-primary">
                            {item?.method}
                          </pre>
                          <span>Response:</span>
                          <pre className="w-[700px] font-inter text-sm leading-[19.5px] text-primary">
                            {item?.endpoint}
                          </pre>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="border border-border-bd-1 rounded-lg flex w-full h-auto gap-4 p-4">
              <span className="w-full">Loading...</span>
            </div>
          )}
          {pagination && !isLoading && (
            <div className="pagination-container">
              <Pagination
                currentPage={paginationPage}
                listLength={customToolList.length}
                paginationSize={paginationSize}
                onPageChange={(page: number) => {
                  setPaginationPage(page);
                  getCustomTools(page);
                }}
              />
            </div>
          )}
          {!isLoading && customToolList.length === 0 && (
            <div className="flex flex-col items-center justify-center gap-4 text-primary font-inter text-lg font-medium leading-[24px] min-w-[500px] min-h-[400px]">
              <div className="flex flex-col items-center justify-center gap-4 text-sm font-medium leading-[21px]">
                <img
                  src={
                    params?.type === "action"
                      ? "../../../../assets/images/tools_empty.png"
                      : "../../../../assets/images/webhooks_empty.png"
                  }
                  alt="No logs"
                />
                <span>No logs have been registered yet</span>
              </div>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default WebhookLogs;
