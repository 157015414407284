import apiClient from '../interceptors/axiosApiInterceptor';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useSharedStore } from '../store/shared.store';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionStore } from '../store/subscription.store';
import { getBusiness } from './business.utils';
import { navigateTo } from './navigate.utils';
import { getInboxes } from './voice.utils';
import { getWidgets } from './widget.utils';
import { Widget } from '../models/widget';
import { getPaymentGatewayKeys } from './stripe.utils';
import { getIntegrations } from './integrations.utils';
import { BillingDetails } from '../models/billing-details';
import { Subscription } from '../models/subscription';
import { Business } from '../models/business';
import { CloudNumber } from '../models/cloud-number';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// Validate phone number with a country code
export const isValidNumber = (number: string, code: string): boolean => {
  try {
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(number, code);
    return phoneNumberUtil.isValidNumber(phoneNumber);
  } catch (er) {
    return false;
  }
};

// Validate phone number without a specific country code
export const isValidNumber2 = (number: string): boolean => {
  try {
    const phoneNumber = phoneNumberUtil.parse(number, "ZZ");
    return phoneNumberUtil.isValidNumber(phoneNumber);
  } catch (er) {
    return false;
  }
};

// Get country code from phone number
export const getCountryCodeFromNumber = (number: string): string => {
  try {
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput('+' + number);
    const countryCode = phoneNumber.getCountryCode();
    return countryCode ? '' + countryCode : '';
  } catch (er) {
    return '';
  }
};

// Get national significant number from phone number
export const getMobileNumber = (number: string): string => {
  try {
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput('+' + number);
    return phoneNumberUtil.getNationalSignificantNumber(phoneNumber);
  } catch (er) {
    return '';
  }
};

// Helper function to get shared store
const getSharedStore = () => {
  return useSharedStore.getState();
};

// Logout function
export const logout = (navigate: ReturnType<typeof useNavigate>) => {
  const sharedStore = getSharedStore();
  if (sessionStorage.getItem('client_token')) {
    window.close();
  } else {
    console.log('logout');
    sharedStore.setIsLoggedIn(false);
    sharedStore.setIsWabaEnabled(true);
    sharedStore.setIsPartnerBusiness(false);
    localStorage.clear();
    navigate('/');
    useSharedStore.setState({
      isLoggedIn: false,
      inAuthModule: true,
      business: JSON.parse(localStorage.getItem("business") || "null"),
      whatsappDetails: null,
      isWabaEnabled: true,
      newVersionEnabled: false,
      isPartnerBusiness: false,
      partnerConversationRate: null,
      businessCountry: localStorage.getItem("country"),

      apiCountryList: [],
      apiStateList: [],
      apiCityList: [],

      languageList: [],
      customFields: [],
      agentCharacteristics: [],

      responsiveMenuBarOpen: false,
      hoverActive: true,

      audioPlayer: false,
      audioPlayerMessage: null,

      subscriptionDaysLeft: null,

      cloudNumbers: [],
      callTunes: [],
      widgetList: [],

      gst_no: "",

      remoteConfig: {
      frill: false,
      downtime: false,
      },

      templateStudioEnabled: false,
      templateStudioOpened: false,

      voiceList: [],

      loggedUser: null,

      paymentGatewayKeys: {
      is_default: true,
      stripe: "",
      default: "",
      },

      dialerPopup: false,
      dialerInboxSelected: null,

      agencyPlans: [],
      agencyAddOns: [],

      gettingStartedEnabled: false,
      gettingStartedStepCount: 0,

      importingContacts: false,
      importingContactsLength: 0,

      integrationsList: [],
      outgoingCallCustomerDetails: null,

      permissions: {},
    });
  }
};

export const getPresignUrl = (presignObj: any) =>
  apiClient.post(`/pre-sign/`, presignObj);

export const uploadToS3 = (
  presignUrl: string,
  presignFields: Record<string, any>,
  media: File,
  onProgress?: (progress: number) => void 
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    formData.append("key", presignFields["key"]);
    formData.append("Content-Type", presignFields["Content-Type"]);
    if (presignFields["acl"]) formData.append("acl", presignFields["acl"]);
    formData.append("policy", presignFields["policy"]);
    formData.append("x-amz-algorithm", presignFields["x-amz-algorithm"]);
    formData.append("x-amz-credential", presignFields["x-amz-credential"]);
    formData.append("x-amz-signature", presignFields["x-amz-signature"]);
    formData.append("x-amz-date", presignFields["x-amz-date"]);
    formData.append("file", media);

    xhr.open("POST", presignUrl, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded * 100) / event.total);
        if(onProgress) onProgress(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(formData);
  });
};


export const getBusinessData = async () => {
  const sharedStore = useSharedStore.getState();
  const subscriptionStore = useSubscriptionStore.getState();
  try {
    let business = JSON.parse(localStorage.getItem('business')!);
    sharedStore.setBusiness(new Business(business));
    subscriptionStore.setSubscription(business?.subscription);
    const resp = await getBusiness();
    localStorage.setItem('business', JSON.stringify(resp.data.data));
    let updatedBusiness = new Business(resp.data.data);
    sharedStore.setBusiness(updatedBusiness);
    subscriptionStore.setSubscription(updatedBusiness?.subscription);
    // if (sharedStore.business?.subscription?.id) {
    // } else {
    //   navigateTo('/onboarding-plans');
    // }
  } catch (error) {
    console.error("Error fetching business:", error);
  }
};

export const getInboxesData = async () => {
  try {
    const sharedStore = useSharedStore.getState();
    const resp = await getInboxes();
    console.log(resp);
    if (resp.status === 200) {
      let responseList = resp.data.data;
      responseList?.forEach((element: any) => {
        element = new CloudNumber(element);
      })
      localStorage.setItem('cloud-numbers', JSON.stringify(resp.data.data));
      sharedStore.setCloudNumbers(responseList);
    }
  } catch (error) {
    console.error("Error fetching inboxes:", error);
  }
};

export const getWidgetsData = async () => {
  try {
    const sharedStore = useSharedStore.getState();
    const resp = await getWidgets();
    let responseList = resp.data.data;
    responseList?.forEach((element: any) => {
      element = new Widget(element);
    })
    sharedStore.setWidgetList(responseList);
  } catch (error) {
    console.error("Error fetching widgets:", error);
  }
};

export const getStripeKeys = async () => {
  try {
    const sharedStore = useSharedStore.getState();
    const resp = await getPaymentGatewayKeys();
    console.log(resp);
    sharedStore.setPaymentGatewayKeys({...sharedStore.paymentGatewayKeys, is_default: resp.data.data.is_default});
    if (resp.data.data.access_key) {
      if (sharedStore.paymentGatewayKeys.is_default) {
        sharedStore.setPaymentGatewayKeys({...sharedStore.paymentGatewayKeys, default: resp.data.data.access_key});
      } else {
        sharedStore.setPaymentGatewayKeys({...sharedStore.paymentGatewayKeys, stripe: resp.data.data.access_key});
      }
    }
  } catch (error) {
    console.error("Error fetching Stripe keys:", error);
  }
};

export const getBillingDetails = async () => {
  try {
    const subscriptionStore = useSubscriptionStore.getState();
    const resp = await subscriptionStore.getBillingDetails();
    console.log(resp);
    let responseList = resp.data;
    responseList?.forEach((element: any) => {
      element = new BillingDetails(element);
    })
    subscriptionStore.setBillingDetails(responseList);
  } catch (error) {
    console.error("Error fetching billing details:", error);
  }
};

export const getAllSubscriptions = async () => {
  try {
    const subscriptionStore = useSubscriptionStore.getState();
    const resp = await subscriptionStore.getAllSubscriptions();
    console.log(resp);
    if (resp.code === 200) {
      let subscriptionList = resp.data?.reverse();
      let allSubscriptionList = [];
      for(var index=0;index<subscriptionList?.length;index++) {
        let subscription = new Subscription(subscriptionList[index]);
        if(subscription.is_active) {
          allSubscriptionList.unshift(subscription);
        }
        else allSubscriptionList.push(subscription);
      }
      if(allSubscriptionList.length === 0) {
        allSubscriptionList.push(new Subscription(subscriptionStore.subscription));
      }
      subscriptionStore.setAllSubscriptions(allSubscriptionList);
    }
  } catch (error) {
    console.error("Error fetching billing details:", error);
  }
}; 

export const getIntegrationsList = () => {
  const sharedStore = useSharedStore.getState();
  getIntegrations(true)
    .then((resp: any) => {
      const integrations = resp.data.data;
      sharedStore.setIntegrationsList(integrations);
    })
    .catch((error: any) => {
      console.log(error);
    });
};