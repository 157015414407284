import {
  addNotes,
  deleteNote,
  getNotes,
  updateNote,
} from "../../lib/notes.utils";
import { getSingleUser } from "../../lib/users.utils";
import { Customer } from "../../models/customer";
import { Note } from "../../models/note";
import useSharedStore from "../../store/shared.store";
import React, { useEffect, useState } from "react";
import { useWebSocketStore } from "../../store/websocket.store";
import { takeUntil } from "rxjs";
import {
  assignLabel,
  getCallRecording,
  getSingleCallLog,
  killCall,
  rateCallLog,
} from "../../lib/voice.utils";
import Toaster from "../shared/customToast";
import { useRetellStore } from "../../store/retell.store";
import "../Calls/SingleCall.css";
import { DisconnectionReasonData } from "../../lib/constants";
import AudioStream from "../shared/AudioStream";
import { Input } from "../shadcn/input";
import {
  Copy,
  Grid2x2,
  Mail,
  MessageSquare,
  PhoneForwarded,
  PhoneIncoming,
  PhoneOff,
  PhoneOutgoing,
  User,
  Zap,
  CirclePlus,
} from "lucide-react";
import {
  formatName,
  formatDuration,
  formatDateToMonthDayYear,
  formatTime,
  formatToE164,
  markdownToHtml,
} from "../../lib/ui.utils";
import TooltipComponent from "../shared/Tooltip";
import AssignLabel from "../shared/AssignLabel";
import { Button } from "../shadcn/button";
import CallFeedback from "../shared/CallFeedback";
import Label from "../shared/Label";
import { Label as LabelModel } from "../../models/label";
import MessageInput from "../shared/MessageInput";
import EditNote from "../shared/EditNote";
import { v4 as uuidv4 } from "uuid";
import NameTag from "../shared/NameTag";
import ContactDetails from "../Contacts/ContactDetails";

interface SingleCallProps {
  callLog: any;
  customerUpdate: (customer: any) => void;
  callUpdate: (call: any) => void;
  callEnded: () => void;
}

const SingleCall: React.FC<SingleCallProps> = ({
  callLog,
  customerUpdate,
  callUpdate,
  callEnded,
}) => {
  const [showCustomerProfile, setShowCustomerProfile] = useState(false);
  const [currentTab, setCurrentTab] = useState("overview");
  const [searchTag, setSearchTag] = useState("");
  const [newComment, setNewComment] = useState("");
  const [isFeedBack, setIsFeedBack] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState<number>(-1);
  const user = JSON.parse(localStorage.getItem("user")!);
  const [ratingList, setRatingList] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [comments, setComments] = useState<Note[]>([]);
  const disconnectionReasonData = DisconnectionReasonData; // Add appropriate data here
  const sentimentHoverInfo =
    "Analyzes the tone of the conversation, indicating whether it was neutral, positive, or negative.";
  const callStatusHoverInfo =
    "Indicates whether the conversation was completed or left incomplete.";
  const taskStatusHoverInfo =
    "Indicates whether the task discussed in the conversation was completed and resolved.";
  const ratingHoverInfo =
    "Tell us how the AI did—your feedback helps us get better.";
  const [killCallLoader, setKillCallLoader] = useState(false);
  const [showCallDetails, setShowCallDetails] = useState(false);
  const [customer, setCustomer] = useState<Customer>();
  const [isEditLabels, setIsEditLabels] = useState(false);

  const sharedStore = useSharedStore();
  const websocketStore = useWebSocketStore();
  const retellStore = useRetellStore();

  const [activeCloudNumber, setActiveCloudNumber] = useState<number>(0);
  const [actionsList, setActionsList] = useState<any[]>([]);
  const [currentCallId, setCurrentCallId] = useState<number | undefined>(
    undefined
  );
  const [loaderCallLog, setLoaderCallLog] = useState<boolean>(false);
  const [loaderGetRecording, setLoaderGetRecording] = useState<boolean>(false);
  const [extractInfoDropdown, setExtractInfoDropdown] =
    useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [callUsage, setCallUsage] = useState<number>(0);
  const [callSummary, setCallSummary] = useState<string>("");

  useEffect(() => {
    if (callLog) {
      console.log(callLog);
      initializeComponent();
    }
  }, [callLog]);

  const initializeComponent = async () => {
    setRatingList([false, false, false, false, false]);
    if (callLog?.ratings) {
      if (callLog?.ratings?.rating > 0)
        setRatingList((prev) => {
          prev[0] = true;
          return [...prev];
        });
      if (callLog?.ratings?.rating > 1)
        setRatingList((prev) => {
          prev[1] = true;
          return [...prev];
        });
      if (callLog?.ratings?.rating > 2)
        setRatingList((prev) => {
          prev[2] = true;
          return [...prev];
        });
      if (callLog?.ratings?.rating > 3)
        setRatingList((prev) => {
          prev[3] = true;
          return [...prev];
        });
      if (callLog?.ratings?.rating > 4)
        setRatingList((prev) => {
          prev[4] = true;
          return [...prev];
        });
    }

    if (currentCallId !== callLog?.id) {
      setCurrentCallId(callLog?.id);
      setCurrentTab("overview");
      if (callLog?.id && callLog?.recording_url) {
        setAudioUrl(callLog?.recording_url);
      } else {
        setAudioUrl("");
      }
      if (callLog?.id) getSingleCallLogData(callLog?.id);
      if (callLog?.customer) {
        getSingleUser(callLog?.customer?.id)
          .then((resp) => {
            setCustomer(new Customer(resp.data?.data));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setCustomer(new Customer(null));
      }
      getNotes({ call: callLog?.id })
        .then((resp) => {
          let responseList = resp.data.data;
          let commentList: Note[] = [];
          responseList?.forEach((element: any) => {
            commentList.push(new Note(element));
          });
          setComments(commentList.reverse());
        })
        .catch((error) => {
          console.error(error);
        });
    }

    setCallSummary(await markdownToHtml(
        callLog?.call_summary ??
        callLog?.call_analysis?.call_summary ??
        ""
      ))

    if (callLog?.cost) {
      setCallUsage(Math.ceil(callLog?.cost / 60));
    } else if (callLog?.duration) {
      setCallUsage(Math.ceil(callLog?.duration / 60));
    }

    sharedStore.cloudNumbers?.forEach((element) => {
      if (element?.is_active) setActiveCloudNumber((prev) => prev + 1);
    });

    websocketStore.callEvents$.subscribe((event: any) => {
      if (callLog?.id === event?.call_log_id) {
        callLog.call_status = event?.call_status;
        if (event?.call_status === "ended") {
          getSingleCallLogData(event?.call_log_id);
        }
      }
    });
  };

  const copyMessage = () => {
    let copyString = "";
    callLog?.transcript_object?.forEach((element: any) => {
      copyString += element?.role + ": " + element?.content + " \n ";
    });
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = copyString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    // Show tooltip
  };

  const rateCall = (rate: number, reason: string = "") => {
    if (rate + 1 < 3 && !isFeedBack) {
      setIsFeedBack(true);
      setFeedbackRating(rate);
      return;
    }
    setIsFeedBack(false);
    let input: Record<string, any> = {
      call: callLog?.id,
      rating: rate + 1,
    };
    if (reason !== "") {
      input["reason"] = reason;
    }

    setRatingList([
      rate + 1 > 0,
      rate + 1 > 1,
      rate + 1 > 2,
      rate + 1 > 3,
      rate + 1 > 4,
    ]);
    rateCallLog(input)
      .then((resp) => {
        Toaster.success(resp?.data?.message);
        callLog.ratings = {
          id: callLog?.ratings?.id,
          rating: input["rating"],
          reason: callLog?.ratings?.reason,
          created_at: callLog?.ratings?.created_at,
          call: callLog?.ratings?.call,
        };
        console.log(resp);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateCustomer = (customer: Customer) => {
    setCustomer(new Customer(customer));
    setShowCustomerProfile(false);
    customerUpdate({
      id: customer.id,
      name: customer.name,
      mobile: customer?.mobile,
    });
  };

  const makeCall = () => {
    sharedStore.cloudNumbers?.forEach((element) => {
      if (callLog?.voice_inbox?.id === element?.id)
        sharedStore.dialerInboxSelected = element;
    });
    sharedStore.dialerPopup = true;
    retellStore.makeCall.next(customer);
  };

  const getSingleCallLogData = (id: number) => {
    if (!loaderCallLog) {
      setLoaderCallLog(true);
      let input = { id: id };
      getSingleCallLog(input)
        .then((resp) => {
          let call = resp.data.data;
          setLoaderCallLog(false);
          updateCallLog(call);
        })
        .catch((error) => {
          console.error(error);
          setLoaderCallLog(false);
        });
    }
  };

  const updateCallLog = (call: any) => {
    if (
      call?.call_status === "registered" &&
      timeDifferenceOf5Mins(call?.created_at)
    )
      call.call_status = "ended";

    callLog = call;
    setAudioUrl(callLog?.recording_url);
    if (callLog?.ratings) {
      setRatingList([
        callLog?.ratings.rating > 0,
        callLog?.ratings.rating > 1,
        callLog?.ratings.rating > 2,
        callLog?.ratings.rating > 3,
        callLog?.ratings.rating > 4,
      ]);
    }
    setActionsList([]);
    callLog?.actions?.forEach((element: any) => {
      setActionsList((prev) => [...prev, element]);
    });
    setActionsList((prev) => [
      ...prev,
      {
        name:
          callLog?.direction === "outbound" ? "Outgoing call" : "Incoming call",
        type: "inbound",
        timestamp: callLog?.start_timestamp,
      },
    ]);
    setActionsList((prev) => [
      ...prev,
      {
        name: "Call ended",
        type: "ended",
        timestamp: callLog?.end_timestamp,
      },
    ]);

    setActionsList((prev) => {
      const updatedList = prev.map((element) => ({
        ...element,
        timestamp: new Date(element.timestamp),
      }));
      return updatedList.sort(
        (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
      );
    });
    if (Object.keys(callLog?.call_analysis?.custom_analysis_data)?.length > 0) {
      setActionsList((prev) => [
        ...prev,
        {
          name: "Information extracted",
          type: "extract_info",
          timestamp: callLog?.end_timestamp,
          data: callLog?.call_analysis?.custom_analysis_data,
        },
      ]);
    }
    callUpdate(call);
  };

  const retryRecording = () => {
    setLoaderGetRecording(true);
    getCallRecording(callLog?.id)
      .then((resp) => {
        let call = resp.data.data;
        updateCallLog(call);
        setLoaderGetRecording(false);
      })
      .catch((error) => {
        console.error(error);
        setLoaderGetRecording(false);
        Toaster.error(error);
      });
  };

  const timeDifferenceOf5Mins = (time: any): boolean => {
    const currentTime = new Date();
    const targetDateTime = new Date(time);
    const targetTimeInMs = targetDateTime.getTime();
    const currentTimeInMs = currentTime.getTime();
    const differenceInMs = currentTimeInMs - targetTimeInMs;
    const fiveMinutesInMs = 5 * 60 * 1000;
    return differenceInMs > fiveMinutesInMs;
  };

  const killLiveCall = () => {
    let input = { call_id: callLog?.call_id };
    setKillCallLoader(true);
    killCall(input)
      .then((resp) => {
        console.log(resp);
        callLog.call_status = "canceled";
        updateCallLog(callLog);
        setKillCallLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setKillCallLoader(false);
        Toaster.error(error);
      });
  };

  const deleteComment = (comment: any, index: number) => {
    deleteNote(comment?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });
    setComments((prev) => prev.filter((_, i) => i !== index));
  };

  const updateComment = (text: string, index: number) => {
    let input = { note: text };
    setComments((prev) => {
      const updatedComments = [...prev];
      updatedComments[index].note = text;
      updatedComments[index].added_by.name = user?.name;
      updatedComments[index].added_by.id = user?.id;
      updatedComments[index].updated_at = new Date();
      return updatedComments;
    });

    updateNote(input, comments[index]?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const addComment = () => {
    let input: Record<string, any> = {
      call: callLog?.id,
      note: newComment,
    };
    input["added_by"] = {
      id: user?.id,
      name: user?.name,
    };
    input["created_at"] = new Date();
    input["uuid"] = uuidv4();
    setComments((prev) => [...prev, new Note(input)]);
    setNewComment("");

    addNotes(input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
        if (resp.data.data) {
          setComments((prevNotes) => [
            ...prevNotes.slice(0, prevNotes.length - 1),
            new Note(resp.data.data),
          ]);
        }
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  const assignCallLabel = (labels: LabelModel[]) => {
    let labelIds = labels.map((label: LabelModel) => label.id);
    let assignedLabels = callLog?.labels?.map(
      (element: { label_id: any }) => element?.label_id
    );

    if (labelIds.length === assignedLabels.length) {
      const sorted1 = [...labelIds].sort((a, b) => a - b);
      const sorted2 = [...assignedLabels].sort((a, b) => a - b);

      if (sorted1.every((value, index) => value === sorted2[index])) {
        setIsEditLabels(false);
        return;
      }
    }

    let input = {
      label_id: labelIds,
      call_id: callLog?.id,
    };
    let updatedLabels: any = [];
    labels?.forEach((element) => {
      updatedLabels.push({
        label_id: element?.id,
        name: element?.name,
        color: element?.color,
        bg_color: element?.bg_color,
      });
    });
    callLog.labels = updatedLabels;
    // setCallLog((prev: any) => ({ ...prev, labels: updatedLabels }));
    setIsEditLabels(false);
    assignLabel(callLog?.voice_inbox?.id, input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        console.error(error);
        Toaster.error(error);
      });
  };

  return (
    <div className="main-container">
      {callLog?.id ? (
        <>
          <div
            className="container-heading"
            onClick={() => customer?.id && setShowCustomerProfile(true)}
            style={{ cursor: customer?.id ? "pointer" : "default" }}
          >
            <span>
              <span className="user-pic">
                {callLog?.direction === "inbound" && (
                  <PhoneIncoming size={"20px"} />
                )}
                {callLog?.direction === "outbound" && (
                  <PhoneOutgoing size={"20px"} />
                )}
              </span>
              <span className="call-details">
                <span className="call-details-type">
                  {callLog?.customer
                    ? formatName(callLog?.customer)
                    : callLog?.widget && "Widget call"}
                  {!callLog?.customer && !callLog?.widget && "Test web call"}
                </span>
                {callLog?.call_status !== "no-answer" &&
                callLog?.call_status !== "busy" ? (
                  <span className="call-details-direction">
                    {callLog?.direction === "outbound"
                      ? "Outbound call"
                      : "Inbound call"}
                  </span>
                ) : (
                  <span className="call-details-direction">
                    Call not connected
                  </span>
                )}
              </span>
              {callLog?.call_status === "ongoing" && (
                <span className="ongoing-call">
                  <i
                    className="material-icons"
                    style={{ color: "#16A34A", fontSize: "14px" }}
                  >
                    ongoing_call
                  </i>
                  In call
                </span>
              )}
              {(callLog?.call_status === "ringing" ||
                callLog?.call_status === "registered") && (
                <span className="ongoing-call">
                  <i
                    className="material-icons"
                    style={{ color: "#16A34A", fontSize: "14px" }}
                  >
                    ringing
                  </i>
                  Ringing
                </span>
              )}
            </span>
            {/* {callLog?.customer && sharedStore?.business?.subscription?.outbound_call && (
              <span
                style={{ cursor: activeCloudNumber > 0 ? 'pointer' : 'default' }}
                onClick={(e) => {
                  if (activeCloudNumber > 0) {
                    e.stopPropagation();
                    makeCall();
                  }
                }}
                title={activeCloudNumber > 0 ? '' : 'No active number available for outgoing call'}
              >
                <i className="material-icons">phone</i>
              </span>
            )} */}
          </div>
          {callLog?.call_status === "ongoing" ||
          callLog?.call_status === "ringing" ||
          callLog?.call_status === "registered" ? (
            <div
              className="empty-screen"
              style={{ height: "calc(100vh - 64px)" }}
            >
              <div className="empty-card">
                <img
                  src="../../../../assets/images/outgoing_live_call.png"
                  alt="Live Call"
                />
                <p className="chat-sinppet-with-count">
                  {callLog?.call_status === "ongoing" && (
                    <span className="ongoing-call">
                      <i
                        className="material-icons"
                        style={{ color: "#16A34A", fontSize: "14px" }}
                      >
                        ongoing_call
                      </i>
                      In call
                    </span>
                  )}
                  {(callLog?.call_status === "ringing" ||
                    callLog?.call_status === "registered") && (
                    <span className="ongoing-call">
                      <i
                        className="material-icons"
                        style={{ color: "#16A34A", fontSize: "14px" }}
                      >
                        ringing
                      </i>
                      Ringing
                    </span>
                  )}
                </p>
                {(callLog?.call_status === "ringing" ||
                  callLog?.call_status === "registered") && (
                  <p className="chat-sinppet-with-count">
                    <span>Waiting for contact to pick the call</span>
                  </p>
                )}
                {callLog?.direction === "outbound" && (
                  <button onClick={killLiveCall} disabled={killCallLoader}>
                    End call
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="agent-details">
                {callLog?.call_status !== "no-answer" &&
                  callLog?.call_status !== "busy" &&
                  callLog?.call_status !== "canceled" && (
                    <>
                      {callLog?.recording_url ? (
                        <AudioStream audioUrl={callLog?.recording_url} />
                      ) : (
                        <div className="retry-recording">
                          <span>Oops! Something went wrong</span>
                          <Button
                            onClick={retryRecording}
                            disabled={loaderGetRecording}
                          >
                            Retry
                          </Button>
                        </div>
                      )}
                    </>
                  )}
              </div>
              <div
                className="agent-details"
                style={{
                  height: callLog?.recording_url
                    ? "calc(100% - 173px)"
                    : "calc(100% - 64px)",
                }}
              >
                <div className="agent-details-tab">
                  <div
                    className={`detail-type-tab ${
                      currentTab === "overview" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTab("overview")}
                  >
                    <span>Overview</span>
                  </div>
                  <div
                    className={`detail-type-tab ${
                      currentTab === "summary" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTab("summary")}
                  >
                    <span>Summary</span>
                  </div>
                  <div
                    className={`detail-type-tab ${
                      currentTab === "transcript" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTab("transcript")}
                  >
                    <span>Transcript</span>
                  </div>
                  <div
                    className={`detail-type-tab ${
                      currentTab === "activity" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTab("activity")}
                  >
                    <span>Activity</span>
                  </div>
                  <div
                    className={`detail-type-tab ${
                      currentTab === "comments" ? "active" : ""
                    }`}
                    onClick={() => setCurrentTab("comments")}
                  >
                    <span>Comments</span>
                  </div>
                </div>
                <div className="agent-tab-description h-full">
                  {currentTab === "overview" && (
                    <>
                      <div className="card-fields">
                        <span className="overview-heading">
                          Call Information
                        </span>
                        <div className="call-log-details">
                          <div className="call-log-timings">
                            <span className="heading w-120">Labels</span>
                            <span
                              className="subheading"
                              onClick={() => {
                                if (!isEditLabels) setIsEditLabels(true);
                              }}
                              style={{
                                minWidth: "300px",
                                transform: "translateY(-4px)",
                              }}
                            >
                              {!isEditLabels && (
                                <div className="call-log-label-list">
                                  {callLog?.labels?.map(
                                    (label: any, index: any) => (
                                      <Label key={index} labelElement={label} />
                                    )
                                  )}
                                  <span className="cursor-pointer">
                                    <CirclePlus
                                      size="20px"
                                      color="var(--icon-sec-color)"
                                    />
                                  </span>
                                </div>
                              )}
                              {isEditLabels && (
                                <AssignLabel
                                  autofocus={true}
                                  assignedLabel={callLog?.labels}
                                  inbox={callLog?.voice_inbox?.id}
                                  onClose={(labels) => assignCallLabel(labels)}
                                />
                              )}
                            </span>
                          </div>
                          {callLog?.from_number && (
                            <div className="call-log-timings">
                              <span className="heading w-120">Call from</span>
                              <span className="subheading">
                                {callLog?.from_number}
                              </span>
                            </div>
                          )}
                          {callLog?.to_number && (
                            <div className="call-log-timings">
                              <span className="heading w-120">Call to</span>
                              <span className="subheading">
                                {callLog?.to_number}
                              </span>
                            </div>
                          )}
                          {callLog?.widget && (
                            <div className="call-log-timings">
                              <span className="heading w-120">Widget</span>
                              <span className="subheading hor-8px">
                                <img
                                  src={callLog?.widget?.profile_pic}
                                  alt=""
                                />
                                <span>{callLog?.widget.name}</span>
                              </span>
                            </div>
                          )}
                          {callLog?.call_status !== "canceled" && (
                            <div className="call-log-timings">
                              <span className="heading w-120">AI Agent</span>
                              <span className="subheading hor-8px">
                                <img src={callLog?.agent?.profile_pic} alt="" />
                                <span>{callLog?.agent?.name}</span>
                              </span>
                            </div>
                          )}
                          <div className="call-log-timings">
                            <span className="heading w-120">Duration</span>
                            <span className="subheading">
                              00:
                              {formatDuration(
                                (callLog?.end_timestamp ??
                                  callLog?.start_timestamp) /
                                  1000 -
                                  callLog?.start_timestamp / 1000
                              )}
                            </span>
                          </div>
                          <div className="call-log-timings">
                            <span className="heading w-120">Usage</span>
                            <span className="subheading">
                              00:{Math.floor(callLog?.cost / 60)}:
                              {Math.floor(callLog?.cost % 60)}
                            </span>
                          </div>
                          <div className="call-log-timings">
                            <span className="heading w-120">Started at</span>
                            <span className="subheading">
                              {formatDateToMonthDayYear(callLog?.created_at)},{" "}
                              {formatTime(callLog?.created_at)}
                            </span>
                          </div>
                          <div className="call-log-timings">
                            <span className="heading w-120">Ended at</span>
                            <span className="subheading">
                              {formatDateToMonthDayYear(callLog?.updated_at)},{" "}
                              {formatTime(callLog?.updated_at)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {callLog?.call_analysis && (
                        <div className="card-fields">
                          <span className="overview-heading">
                            Call Analysis
                          </span>
                          <div className="call-log-details">
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                <span style={{ whiteSpace: "nowrap" }}>
                                  Rating{" "}
                                </span>
                                <TooltipComponent text={ratingHoverInfo} />
                              </span>
                              <span className="ratings">
                                {ratingList.map((rating, i) => (
                                  <span
                                    key={i}
                                    className={`material-icons cursor-pointer ${
                                      rating ? "star_fill" : "star_empty"
                                    }`}
                                    onClick={() => rateCall(i)}
                                  >
                                    {rating ? "star" : "star_border"}
                                  </span>
                                ))}
                              </span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                <span style={{ whiteSpace: "nowrap" }}>
                                  Call sentiment
                                </span>
                              </span>
                              <div className="subheading hor-8px">
                                {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                  "neutral" && (
                                  <span
                                    className="material-icons"
                                    style={{
                                      color: "#2563EB",
                                      fontSize: "20px",
                                    }}
                                  >
                                    sentiment_neutral
                                  </span>
                                )}
                                {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                  "positive" && (
                                  <span
                                    className="material-icons"
                                    style={{
                                      color: "#16A34A",
                                      fontSize: "20px",
                                    }}
                                  >
                                    sentiment_satisfied
                                  </span>
                                )}
                                {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                  "negative" && (
                                  <span
                                    className="material-icons"
                                    style={{
                                      color: "#DC2626",
                                      fontSize: "20px",
                                    }}
                                  >
                                    sentiment_dissatisfied
                                  </span>
                                )}
                                <span>
                                  {callLog?.call_analysis?.user_sentiment}
                                </span>
                              </div>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">Call status</span>
                              <span className="subheading hor-8px">
                                <span
                                  className={
                                    disconnectionReasonData[
                                      callLog?.disconnection_reason
                                    ]?.color
                                  }
                                ></span>
                                {callLog?.disconnection_reason
                                  ? disconnectionReasonData[
                                      callLog?.disconnection_reason
                                    ]?.title
                                  : ""}
                              </span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                End call reason
                              </span>
                              <span className="subheading hor-8px">
                                <span
                                  className={
                                    disconnectionReasonData[
                                      callLog?.disconnection_reason
                                    ]?.color
                                  }
                                ></span>
                                {callLog?.disconnection_reason
                                  ? disconnectionReasonData[
                                      callLog?.disconnection_reason
                                    ]?.reason
                                  : ""}
                              </span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                Task status{" "}
                                <TooltipComponent text={taskStatusHoverInfo} />
                              </span>
                              <span className="call-status-details">
                                <span className="hor-8px subheading">
                                  <span
                                    className={
                                      callLog?.call_analysis
                                        ?.agent_task_completion_rating
                                    }
                                  ></span>
                                  {
                                    callLog?.call_analysis
                                      ?.agent_task_completion_rating
                                  }
                                </span>
                                <span className="call-status-description">
                                  {
                                    callLog?.call_analysis
                                      ?.agent_task_completion_rating_reason
                                  }
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {!callLog?.call_analysis && (
                        <div className="card-fields">
                          <span className="overview-heading">
                            Call Analysis
                          </span>
                          <div className="call-log-details">
                            <div className="call-log-timings">
                              <span className="heading w-120">Rating</span>
                              <span className="subheading">-</span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                Call sentiment
                              </span>
                              <span className="subheading">-</span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">Call status</span>
                              <span className="subheading">-</span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">
                                End call reason
                              </span>
                              <span className="subheading">-</span>
                            </div>
                            <div className="call-log-timings">
                              <span className="heading w-120">Task status</span>
                              <span className="subheading">-</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {currentTab === "summary" && (
                    <div className="card-fields">
                      <span className="summary-heading">Summary</span>
                      <div className="transcript-lines">
                        <div className="transcript-log">
                          <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={{
                              __html: callSummary,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === "transcript" && (
                    <div className="card-fields">
                      <span className="heading">
                        <Input
                          type="text"
                          value={searchTag}
                          onChange={(e) => setSearchTag(e.target.value)}
                          placeholder="Search..."
                        />
                        <i
                          className="material-icons"
                          title="Copy to clipboard"
                          onClick={copyMessage}
                          style={{ width: "20px", cursor: "pointer" }}
                        >
                          <Copy />
                        </i>
                      </span>
                      <div className="transcript-lines">
                        {callLog?.transcript_object
                          ?.filter((message: { content: string | string[] }) =>
                            message.content.includes(searchTag)
                          )
                          .map(
                            (
                              message: {
                                role: string;
                                words: any[];
                                content: any;
                              },
                              index: React.Key | null | undefined
                            ) => (
                              <div
                                key={index}
                                className={`msg-body ${message.role}`}
                              >
                                <div className="transcript-messages">
                                  {message.role === "user" && (
                                    <div className="transcript-details">
                                      <span className="icon-grey">
                                        <User size="14px" />
                                      </span>
                                      <span className="name">
                                        {callLog?.customer
                                          ? formatName(callLog?.customer)
                                          : user.name}
                                      </span>
                                    </div>
                                  )}
                                  <span className="col-sec">
                                    {formatDuration(message.words[0].start)}
                                  </span>
                                  {message.role === "agent" && (
                                    <div className="transcript-details user">
                                      <span className="name">
                                        {callLog?.agent.name}
                                      </span>
                                      <span className="icon-grey">
                                        <img
                                          src={
                                            callLog?.agent.profile_pic ??
                                            "../../../../assets/images/avatar.png"
                                          }
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="msg-content">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message.content,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  )}
                  {currentTab === "activity" && (
                    <div className="activity-tab">
                      {actionsList.map((action, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 && (
                            <span className="activity-divider">
                              <hr />
                            </span>
                          )}
                          <div
                            className="activity-item"
                            style={{
                              alignItems: action.data ? "flex-start" : "center",
                            }}
                          >
                            <span className="icon">
                              {action.type === "inbound" && (
                                <PhoneIncoming className="h-5 w-5" />
                              )}
                              {action.type === "send_sms" && (
                                <MessageSquare className="h-5 w-5" />
                              )}
                              {action.type === "send_email" && (
                                <Mail className="h-5 w-5" />
                              )}
                              {action.type === "send_webhook" && (
                                <Zap className="h-5 w-5" />
                              )}
                              {action.type === "transfer_call" && (
                                <PhoneForwarded className="h-5 w-5" />
                              )}
                              {action.type === "ended" && (
                                <PhoneOff className="h-5 w-5" />
                              )}
                              {action.type === "extract_info" && (
                                <Grid2x2 className="h-5 w-5" />
                              )}
                            </span>
                            <div className="activity-details">
                              <span className="time">
                                {formatTime(action.timestamp)}
                              </span>
                              <span className="type">{action.name}</span>
                              {action.data && (
                                <div className="extracted-info">
                                  <span
                                    className="extracted-info-heading"
                                    onClick={() =>
                                      setExtractInfoDropdown(
                                        !extractInfoDropdown
                                      )
                                    }
                                  >
                                    <i
                                      className="material-icons"
                                      style={{
                                        color: "#64748B",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {extractInfoDropdown
                                        ? "indeterminate_check_box"
                                        : "add_box_solid"}
                                    </i>
                                    Extracted fields
                                  </span>
                                  {extractInfoDropdown && (
                                    <span className="extracted-info-list">
                                      {Object.entries(action.data).map(
                                        ([key, value]) => (
                                          <span key={key}>
                                            {key}: {String(value)}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {currentTab === "comments" && (
                    <>
                      <div className="comment-list">
                        {comments.length > 0 ? (
                          comments.map((comment, index) => (
                            <div
                              key={index}
                              className="comment-item"
                              style={{
                                alignItems:
                                  comment?.added_by?.id === user?.id
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                            >
                              <span
                                className="comment-added"
                                style={{
                                  flexDirection:
                                    comment?.added_by?.id === user?.id
                                      ? "row-reverse"
                                      : "row",
                                }}
                              >
                                <NameTag name={comment?.added_by.name ?? ""} />
                                <span>
                                  {formatDateToMonthDayYear(
                                    comment?.updated_at ?? comment?.created_at
                                  )}
                                  ,{" "}
                                  {formatTime(
                                    comment?.updated_at ?? comment?.created_at
                                  )}
                                </span>
                              </span>
                              <EditNote
                                disable={comment?.added_by?.id !== user.id}
                                note={comment?.note ?? ""}
                                onSave={(note: any) =>
                                  updateComment(note, index)
                                }
                                onDelete={() => deleteComment(comment, index)}
                                contact={false}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="comment-list comment-empty-screen">
                            <img
                              src="../../../../assets/images/comments_empty.png"
                              alt="No Comments"
                            />
                            <span>Engage with your team on this call</span>
                          </div>
                        )}
                      </div>
                      <div className="add-comment">
                        <MessageInput
                          rows={2}
                          message={newComment}
                          onTextChange={(text) => setNewComment(text)}
                          onSend={addComment}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="empty-screen">
          <div className="empty-card">
            <img
              src="../../../../assets/images/logs_empty.png"
              alt="No Call Selected"
            />
            <span>Select a call for details</span>
          </div>
        </div>
      )}
      {isFeedBack && (
        <CallFeedback
          onClose={() => setIsFeedBack(false)}
          onSuccess={(rating: string) => rateCall(feedbackRating, rating)}
        />
      )}
      {showCustomerProfile && (
        <ContactDetails
          customer={customer ?? new Customer(null)}
          close={updateCustomer}
          onAdd={() => {}}
          onDelete={() => {}}
        />
      )}
    </div>
  );
};

export default SingleCall;
