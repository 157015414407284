import { create } from "zustand";
import { Subject } from 'rxjs';
import Vapi from "@vapi-ai/web";
import { useRetellStore } from "./retell.store";

interface VapiState {
  vapi: Vapi | null;
  callLiveEvents: Subject<any>;
  liveCallTranscript: Subject<any>;
  transcript: any[];
  initializeVapi: () => void;
  destroyVapi: () => void;
  startCall: (id: string) => void;
  stopCall: () => void;
}

export const useVapiStore = create<VapiState>((set, get) => ({
  vapi: null,
  callLiveEvents: new Subject<any>(),
  liveCallTranscript: new Subject<any>(),
  transcript: [],

  initializeVapi: () => {
    if (!get().vapi) {
      const apiKey = process.env.REACT_APP_VAPI_API_KEY;
      if (!apiKey) {
        throw new Error("REACT_APP_VAPI_API_KEY is not defined");
      }
      set({ vapi: new Vapi(apiKey) });
    }
  },

  destroyVapi: () => {
    const vapi = get().vapi;
    if (vapi) {
      if (typeof vapi.stop === 'function') {
        vapi.stop();
      }
      set({ vapi: null });
    }
  },

  startCall: (id: string) => {
    get().initializeVapi();

    const vapi = get().vapi;
    if (!vapi) {
      console.error('Vapi instance is not initialized.');
      return;
    }

    vapi.start(id);

    vapi.on("call-start", () => {
      get().callLiveEvents.next({
        type: 'call_started',
        call_id: id,
      });
    });

    vapi.on("message", (message: any) => {
      console.log(message);
      const transcript = get().transcript;
      if (message?.type === "transcript") {
        if (transcript.length === 0) {
          transcript.push(message);
        } else {
          let recentMsg = transcript.pop();
          if (recentMsg?.transcriptType === 'final') {
            transcript.push(recentMsg);
            transcript.push(message);
          } else {
            transcript.push(message);
          }
        }
      }
      get().liveCallTranscript.next(transcript);
    });

    vapi.on("call-end", () => {
      get().callLiveEvents.next('call-end');
      get().stopCall();
    });

    vapi.on("error", (e) => {
      get().callLiveEvents.next('call-end');
      get().stopCall();
    });
  },

  stopCall: () => {
    const vapi = get().vapi;
    if (vapi) {
      vapi.stop();
    }
    set({ transcript: [], liveCallTranscript: new Subject<any>() });
    useRetellStore.getState().callLive = false;

    get().destroyVapi();
  },
}));
