import {
  CallEnum,
  DisconnectionReasonData,
  DisconnectionReasons,
} from "../../lib/constants";
import { getSingleUser } from "../../lib/users.utils";
import {
  assignLabel,
  getCallLogs,
  getSingleCallLog,
} from "../../lib/voice.utils";
import { LoggedUser } from "../../models/logged-user";
import useSharedStore from "../../store/shared.store";
import { useWebSocketStore } from "../../store/websocket.store";
import React, { useEffect, useState, useRef } from "react";
import { Subject, takeUntil } from "rxjs";
import Toaster from "../shared/customToast";
import { Customer } from "../../models/customer";
import { useRetellStore } from "../../store/retell.store";
import ProfileIcon from "../shared/ProfileIcon";
import {
  AlertTriangle,
  Frown,
  Icon,
  Info,
  Loader2,
  Meh,
  Phone,
  PhoneCall,
  PhoneMissed,
  PhoneOutgoing,
  ShieldOff,
  Smile,
  Sparkles,
  Tag,
  TimerOff,
  Voicemail,
  Volume2,
} from "lucide-react";
import {
  formatDateToMonthDayYear,
  formatName,
  formatTime,
  markdownToHtml,
} from "../../lib/ui.utils";
import Label from "../shared/Label";
import "./CallLogs.css";
import CallLogDetails from "../shared/CallLogDetails";
import AssignLabel from "../shared/AssignLabel";
import ContactDetails from "../Contacts/ContactDetails";
import CallAudioPlayer from "./CallAudioPlayer";
import { Label as LabelModel } from "../../models/label";
import AudioStream from "../shared/AudioStream";

interface CallLogsComponentProps {
  conversation: any;
  customerUpdate: (customer: any) => void;
  callUpdate: (call: any) => void;
  callEnded: (callId: string) => void;
}

const CallLogsComponent: React.FC<CallLogsComponentProps> = ({
  conversation,
  customerUpdate,
  callUpdate,
  callEnded,
}) => {
  const [audioUrl, setAudioUrl] = useState("");
  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [callLogsList, setCallLogsList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(new Customer(null));
  const [showCustomerProfile, setShowCustomerProfile] = useState(false);
  const [activeCloudNumber, setActiveCloudNumber] = useState(0);
  const [selectedCallLog, setSelectedCallLog] = useState<any>(null);
  const [showCallDetails, setShowCallDetails] = useState(false);
  const [loaderPagination, setLoaderPagination] = useState(false);
  const [pageNext, setPageNext] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [labelEditLogId, setLabelEditLogId] = useState(0);
  const [currentAudioPlaying, setCurrentAudioPlaying] = useState(0);
  const [loaderCallLog, setLoaderCallLog] = useState(false);
  const [callLogsListCopy, setCallLogsListCopy] = useState<any[]>([]);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const [scrollerPosition, setScrollerPosition] = useState(0);
  const destroy$ = useRef(new Subject());
  const scrollBottom = useRef<HTMLDivElement>(null);

  const disconnectionReasonData = DisconnectionReasonData;
  const disconnectionReasons = DisconnectionReasons;
  const callStatuses = CallEnum;

  const sharedStore = useSharedStore();
  const websocketStore = useWebSocketStore();
  const retellStore = useRetellStore();

  useEffect(() => {
    if (!conversation?.conversation) {
      init();
    }
  }, [conversation]);

  const init = () => {
    sharedStore.cloudNumbers?.forEach((element) => {
      if (element?.is_active) setActiveCloudNumber((prev) => prev + 1);
    });

    websocketStore.callEvents$
      .pipe(takeUntil(destroy$.current))
      .subscribe((event) => {
        setCallLogsList((prev) =>
          prev.map((element) => {
            if (element?.id === event?.call_log_id) {
              element.call_status = event?.call_status;
              if (event?.call_status === "ended") {
                getSingleCallLogData(event?.call_log_id);
              }
            }
            return element;
          })
        );
      });

    setCallLogsList([]);
    setCallLogsListCopy([]);
    setPageNumber(1);
    setPageNext(true);
    if (!isLoading) {
      setIsLoading(true);
      getConversationsMessage(1);
    }
    if (conversation?.contact?.id) getCustomerDetails();
  };

  const getSingleCallLogData = (id: any) => {
    if (!loaderCallLog) {
      setLoaderCallLog(true);
      let input = { id: id };
      getSingleCallLog(input)
        .then((resp) => {
          setLoaderCallLog(false);
          let call = resp.data.data;
          setCallLogsList((prev) =>
            prev.map((element) => {
              if (element?.id === call?.id) element = call;
              return element;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          setLoaderCallLog(false);
        });
    }
  };

  const getCustomerDetails = () => {
    getSingleUser(conversation?.contact?.id, true)
      .then((resp) => {
        setCustomer(new Customer(resp.data.data));
      })
      .catch((error) => {
        console.error(error);
        Toaster.error(error);
      });
  };

  const scrollToBottom = () => {
    try {
      setTimeout(() => {
        if (scrollBottom.current) {
          scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
        }
        console.log("inside scrollbottom");
      }, 100);
    } catch (err) {}
  };

  const onScroll = (event: { target: any }) => {
    const elem = event.target;
    setScrollerPosition(elem.scrollTop);
    if (elem.scrollTop < 1) {
      elem.scrollTo(0, 1);
    }
    if (isLoading) {
      return;
    } else if (elem.scrollTop < 50) {
      setIsLoading(true);
      if (pageNext && pageNumber !== 1) {
        console.log("inside scroller");
        getConversationsMessage(pageNumber);
      } else setIsLoading(false);
    }
  };

  const getConversationsMessage = (page: number) => {
    let params = { conversation_id: conversation?.id };
    getCallLogs(page, params)
      .then((resp) => {
        if (resp.data.code === 200) {
          let conversationsMessage = resp.data.data;
          if (resp.data.next == null) setPageNext(false);
          let filteredCopyList = callLogsListCopy.filter(
            (log) => log?.conversation === conversation?.id
          );
          if (page === 1) {
            setCallLogsList((prev) => [
              ...conversationsMessage.reverse(),
              ...filteredCopyList,
            ]);
            setCallLogsListCopy((prev: any) => [
              ...conversationsMessage.reverse(),
              ...filteredCopyList,
            ]);
          } else {
            setCallLogsList([...conversationsMessage.reverse()]);
            setCallLogsListCopy([...conversationsMessage.reverse()]);
          }
          setCallLogsList((prev) => addDateSeparators(prev));
          console.log(callLogsList);
          setIsLoading(false);
          if (pageNumber === 1) {
            scrollToBottom();
          }
          setPageNumber((prev) => prev + 1);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (pageNumber === 2) scrollToBottom();
  }, [pageNumber]);

  useEffect(() => {
    if (selectedCallLog) {
      setCallLogsList((prev) =>
        prev.map((element) => {
          if (element?.id === selectedCallLog?.id) element = selectedCallLog;
          return element;
        })
      );
      setCallLogsListCopy((prev) =>
        prev.map((element) => {
          if (element?.id === selectedCallLog?.id) element = selectedCallLog;
          return element;
        })
      );
    }
  }, [selectedCallLog]);

  const addDateSeparators = (messages: any[]) => {
    const result = [];
    let previousDate = null;

    for (const message of messages) {
      const currentDate = formatDateToMonthDayYear(message.created_at);
      console.log(currentDate);

      if (currentDate !== previousDate) {
        result.push({ label: "date", date: message.created_at });
        previousDate = currentDate;
      }

      result.push(message);
    }
    result.map(async (callLog) => {
      callLog.call_summary = await markdownToHtml(
        callLog?.call_summary ?? callLog?.call_analysis?.call_summary ?? ""
      );
    });

    return result;
  };

  const formatDate = (date: string | number | Date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  const updateCustomer = (customer: { id: any; name: any; mobile: any }) => {
    setCustomer(new Customer(customer));
    setShowCustomerProfile(false);
    customerUpdate({
      id: customer.id,
      name: customer.name,
      mobile: customer?.mobile,
    });
  };

  const makeCall = () => {
    sharedStore?.cloudNumbers?.forEach((element) => {
      if (conversation?.inbox === element?.id)
        sharedStore.setDialerInboxSelected(element);
    });
    if (conversation?.contact?.id) {
      sharedStore.setOutgoingCallCustomerDetails(
        new Customer(conversation?.contact)
      );
    }
    sharedStore.setDialerPopup(true);
    retellStore.makeCall.next(customer);
  };

  const assignCallLabel = (labels: LabelModel[]) => {
    let labelIds = labels.map((label: LabelModel) => label.id);
    let assignedLabels = selectedCallLog?.labels?.map(
      (element: { label_id: any }) => element?.label_id
    );

    if (labelIds.length === assignedLabels.length) {
      const sorted1 = [...labelIds].sort((a, b) => a - b);
      const sorted2 = [...assignedLabels].sort((a, b) => a - b);

      if (sorted1.every((value, index) => value === sorted2[index])) {
        setLabelEditLogId(0);
        return;
      }
    }

    let input = {
      label_id: labelIds,
      call_id: selectedCallLog?.id,
    };
    let updatedLabels: any = [];
    labels?.forEach((element) => {
      updatedLabels.push({
        label_id: element?.id,
        name: element?.name,
        color: element?.color,
        bg_color: element?.bg_color,
      });
    });
    setSelectedCallLog((prev: any) => ({ ...prev, labels: updatedLabels }));
    setLabelEditLogId(0);
    assignLabel(selectedCallLog?.voice_inbox?.id, input)
      .then((resp) => {
        console.log(resp);
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        console.error(error);
        Toaster.error(error);
      });
  };

  return (
    <div className="main-container">
      {conversation?.id && (
        <>
          <div
            className="container-heading"
            onClick={() => customer?.id && setShowCustomerProfile(true)}
            style={{ cursor: customer?.id ? "pointer" : "default" }}
          >
            <span>
              <ProfileIcon name={conversation?.contact?.name ?? ""} />
              <span className="call-details">
                <span className="call-details-type">
                  {formatName(conversation?.contact)}
                </span>
              </span>
              {conversation?.last_call_status === "ongoing" && (
                <span className="ongoing-call">
                  <PhoneOutgoing size="14px" color="#16A34A" />
                  In call
                </span>
              )}
              {(conversation?.last_call_status === "ringing" ||
                conversation?.last_call_status === "registered") && (
                <span className="ongoing-call">
                  <Phone size="14px" color="#16A34A" />
                  Ringing
                </span>
              )}
            </span>
            {conversation?.contact &&
              sharedStore?.business?.subscription?.outbound_call && (
                <span
                  style={{
                    cursor: activeCloudNumber > 0 ? "pointer" : "default",
                  }}
                  onClick={(e) => {
                    if (activeCloudNumber > 0) {
                      e.stopPropagation();
                      makeCall();
                    }
                  }}
                  title={
                    activeCloudNumber > 0
                      ? ""
                      : "No active number available for outgoing call"
                  }
                >
                  <PhoneCall />
                </span>
              )}
          </div>
          <div className="call-logs-list">
            <div ref={scrollBottom} className="chat-window" onScroll={onScroll}>
              {isLoading && (
                <div className="loader-container" style={{ left: "0%" }}>
                  <Loader2 />
                </div>
              )}
              {callLogsList.map((callLog, index) => (
                <div key={index} className="chat-msg-element">
                  {callLog?.label === "date" ? (
                    <div className="msg-date-time-label">
                      <span>{formatDateToMonthDayYear(callLog?.date)}</span>
                    </div>
                  ) : (
                    <>
                      {callLog.direction === "inbound" ? (
                        <div className="messages received-msg">
                          <div className="message-user">
                            <ProfileIcon
                              iconSize="20px"
                              name={customer?.name ?? ""}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span className="message-time">
                              {formatTime(callLog?.created_at)}
                            </span>
                            <div className="hor-8px">
                              <div className="msg-wrap">
                                <div className="incoming-container">
                                  {callLog?.call_status !== "ongoing" &&
                                    callLog?.call_status !== "ringing" &&
                                    callLog?.call_status !== "registered" && (
                                      <div className="incoming-call-description">
                                        <span className="icon">
                                          {callLog?.disconnection_reason ===
                                            disconnectionReasons.agent_hangup ||
                                          callLog?.disconnection_reason ===
                                            disconnectionReasons.user_hangup ? (
                                            <PhoneCall
                                              size="24px"
                                              color="#16A34A"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.call_transfer ? (
                                            <PhoneOutgoing
                                              size="24px"
                                              color="#16A34A"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_busy ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_no_answer ? (
                                            <PhoneMissed
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.voicemail_reached ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.machine_detected ? (
                                            <Voicemail
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.inactivity ? (
                                            <Volume2
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.max_duration_reached ? (
                                            <TimerOff
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.scam_detected ? (
                                            <ShieldOff
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_failed ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.concurrency_limit_reached ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.no_valid_payment ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_inbound_webhook ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_open ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_lost_connection ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_runtime ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_corrupt_payload ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_frontend_corrupted_payload ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_twilio ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_no_audio_received ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_asr ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_retell ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_unknown ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_user_not_joined ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.registered_call_timeout ? (
                                            <AlertTriangle
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : (
                                            !callLog?.disconnection_reason &&
                                            (callLog?.call_status ===
                                            "ended" ? (
                                              <PhoneCall
                                                size="24px"
                                                color="#16A34A"
                                              />
                                            ) : (
                                              <AlertTriangle
                                                size="24px"
                                                color="#DC2626"
                                              />
                                            ))
                                          )}
                                        </span>
                                        <span className="description ver-4px">
                                          <p className="person-name">
                                            {callLog?.disconnection_reason
                                              ? disconnectionReasonData[
                                                  callLog?.disconnection_reason
                                                ]?.title
                                              : callLog?.call_status === "ended"
                                              ? "Call completed"
                                              : "Call failed"}
                                          </p>
                                          {callLog?.call_status ===
                                            callStatuses.ended && (
                                            <div className="hor-8px">
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "neutral" && (
                                                <Meh
                                                  size="16px"
                                                  color="#2563EB"
                                                />
                                              )}
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "positive" && (
                                                <Smile
                                                  size="16px"
                                                  color="#16A34A"
                                                />
                                              )}
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "negative" && (
                                                <Frown
                                                  size="16px"
                                                  color="#DC2626"
                                                />
                                              )}
                                              <div className="call-log-agent">
                                                <img
                                                  src={
                                                    callLog?.agent?.profile_pic
                                                  }
                                                  alt=""
                                                />
                                                <span>
                                                  {callLog?.agent?.name}
                                                </span>
                                                <Sparkles
                                                  size="14px"
                                                  color="#9333EA"
                                                />
                                              </div>
                                            </div>
                                          )}
                                          {callLog?.call_status !==
                                            callStatuses.ended &&
                                            callLog?.disconnection_reason && (
                                              <div className="call-disconnection-reason">
                                                {
                                                  disconnectionReasonData[
                                                    callLog
                                                      ?.disconnection_reason
                                                  ]?.subheading
                                                }
                                              </div>
                                            )}
                                        </span>
                                      </div>
                                    )}
                                  {callLog?.call_status === "ongoing" && (
                                    <div className="incoming-call-description">
                                      <span className="icon">
                                        <Phone color="#2563EB" size="24px" />
                                      </span>
                                      <span className="description ver-4px">
                                        <p
                                          className="person-name"
                                          style={{ color: "#2563EB" }}
                                        >
                                          In call...
                                        </p>
                                      </span>
                                    </div>
                                  )}
                                  {(callLog?.call_status === "ringing" ||
                                    callLog?.call_status === "registered") && (
                                    <div className="incoming-call-description">
                                      <span className="icon">
                                        <Phone color="#2563EB" size="24px" />
                                      </span>
                                      <span className="description ver-4px">
                                        <p
                                          className="person-name"
                                          style={{ color: "#2563EB" }}
                                        >
                                          Ringing...
                                        </p>
                                      </span>
                                    </div>
                                  )}
                                  {labelEditLogId !== callLog?.id &&
                                    callLog?.labels?.length > 0 && (
                                      <div className="call-log-label-list">
                                        {callLog?.labels?.map(
                                          (label: any, index: any) => (
                                            <Label
                                              key={index}
                                              labelElement={label}
                                            />
                                          )
                                        )}
                                      </div>
                                    )}
                                  {labelEditLogId === callLog?.id && (
                                    <AssignLabel
                                      assignedLabel={callLog?.labels}
                                      autofocus={true}
                                      inbox={callLog?.voice_inbox?.id}
                                      onClose={(labels) =>
                                        assignCallLabel(labels)
                                      }
                                    />
                                  )}
                                  {callLog?.recording_url && (
                                    <div className="audio-player">
                                      {/* <CallAudioPlayer
                                        isPlaying={callLog?.id === currentAudioPlaying}
                                        audioUrl={callLog.recording_url}
                                        onEnd={() =>
                                          setCurrentAudioPlaying(0)
                                        }
                                        onStart={() =>
                                          setCurrentAudioPlaying(callLog?.id)
                                        }
                                      /> */}
                                      <AudioStream
                                        settings={false}
                                        audioUrl={callLog?.recording_url}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="call-log-action">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedCallLog(callLog);
                                    setLabelEditLogId(callLog?.id);
                                  }}
                                >
                                  <Tag color="#64748B" size="24px" />
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    setSelectedCallLog(callLog);
                                    setShowCallDetails(true);
                                  }}
                                >
                                  <Info color="#64748B" size="24px" />
                                </span>
                              </div>
                            </div>
                            {(callLog?.call_analysis?.call_summary ||
                              callLog?.call_summary) && (
                              <div className="call-log-summary ver-8px">
                                <span className="left-line"></span>
                                <span className="hor-8px summary-heading">
                                  Call summary{" "}
                                  <Sparkles size="14px" color="#9333EA" />
                                </span>
                                <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={{
                              __html: callLog?.call_summary ,
                            }}
                          ></div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="messages sent-msg">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <span
                              className="message-time"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <span
                                style={{
                                  fontWeight: 500,
                                  color: "var(--primary-color)",
                                }}
                              >
                                {callLog?.user?.name}
                              </span>{" "}
                              {formatTime(callLog?.created_at)}
                            </span>
                            <div
                              className="hor-8px"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <div className="call-log-action">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedCallLog(callLog);
                                    setLabelEditLogId(callLog?.id);
                                  }}
                                >
                                  <Tag color="#64748B" size="24px" />
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedCallLog(callLog);
                                    setShowCallDetails(true);
                                  }}
                                >
                                  <Info color="#64748B" size="24px" />
                                </span>
                              </div>
                              <div className="msg-wrap">
                                <div className="outgoing-container">
                                  {callLog?.call_status !== "ongoing" &&
                                    callLog?.call_status !== "ringing" &&
                                    callLog?.call_status !== "registered" && (
                                      <div className="incoming-call-description">
                                        <span className="icon">
                                          {callLog?.disconnection_reason ===
                                            disconnectionReasons.agent_hangup ||
                                          callLog?.disconnection_reason ===
                                            disconnectionReasons.user_hangup ? (
                                            <PhoneCall
                                              size="24px"
                                              color="#16A34A"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.call_transfer ? (
                                            <PhoneOutgoing
                                              size="24px"
                                              color="#16A34A"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_busy ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_no_answer ? (
                                            <PhoneMissed
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.voicemail_reached ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.machine_detected ? (
                                            <Voicemail
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.inactivity ? (
                                            <Volume2
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.max_duration_reached ? (
                                            <TimerOff
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                            disconnectionReasons.scam_detected ? (
                                            <ShieldOff
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : callLog?.disconnection_reason ===
                                              disconnectionReasons.dial_failed ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.concurrency_limit_reached ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.no_valid_payment ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_inbound_webhook ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_open ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_lost_connection ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_runtime ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_llm_websocket_corrupt_payload ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_frontend_corrupted_payload ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_twilio ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_no_audio_received ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_asr ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_retell ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_unknown ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.error_user_not_joined ||
                                            callLog?.disconnection_reason ===
                                              disconnectionReasons.registered_call_timeout ? (
                                            <AlertTriangle
                                              size="24px"
                                              color="#DC2626"
                                            />
                                          ) : (
                                            !callLog?.disconnection_reason &&
                                            (callLog?.call_status ===
                                            "ended" ? (
                                              <PhoneCall
                                                size="24px"
                                                color="#16A34A"
                                              />
                                            ) : (
                                              <AlertTriangle
                                                size="24px"
                                                color="#DC2626"
                                              />
                                            ))
                                          )}
                                        </span>
                                        <span className="description ver-4px">
                                          <p className="person-name">
                                            {callLog?.disconnection_reason
                                              ? disconnectionReasonData[
                                                  callLog?.disconnection_reason
                                                ]?.title
                                              : callLog?.call_status === "ended"
                                              ? "Call completed"
                                              : "Call failed"}
                                          </p>
                                          {callLog?.call_status ===
                                            callStatuses.ended && (
                                            <div className="hor-8px">
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "neutral" && (
                                                <Meh
                                                  size="16px"
                                                  color="#2563EB"
                                                />
                                              )}
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "positive" && (
                                                <Smile
                                                  size="16px"
                                                  color="#16A34A"
                                                />
                                              )}
                                              {callLog?.call_analysis?.user_sentiment?.toLowerCase() ===
                                                "negative" && (
                                                <Frown
                                                  size="16px"
                                                  color="#DC2626"
                                                />
                                              )}
                                              <div className="call-log-agent">
                                                <img
                                                  src={
                                                    callLog?.agent?.profile_pic
                                                  }
                                                  alt=""
                                                />
                                                <span>
                                                  {callLog?.agent?.name}
                                                </span>
                                                <Sparkles
                                                  size="14px"
                                                  color="#9333EA"
                                                />
                                              </div>
                                            </div>
                                          )}
                                          {callLog?.call_status !==
                                            callStatuses.ended &&
                                            callLog?.disconnection_reason && (
                                              <div className="call-disconnection-reason">
                                                {
                                                  disconnectionReasonData[
                                                    callLog
                                                      ?.disconnection_reason
                                                  ]?.subheading
                                                }
                                              </div>
                                            )}
                                        </span>
                                      </div>
                                    )}
                                  {callLog?.call_status === "ongoing" && (
                                    <div className="incoming-call-description">
                                      <span className="icon">
                                        <Phone color="#2563EB" size="24px" />
                                      </span>
                                      <span className="description ver-4px">
                                        <p
                                          className="person-name"
                                          style={{ color: "#2563EB" }}
                                        >
                                          In call...
                                        </p>
                                      </span>
                                    </div>
                                  )}
                                  {(callLog?.call_status === "ringing" ||
                                    callLog?.call_status === "registered") && (
                                    <div className="incoming-call-description">
                                      <span className="icon">
                                        <Phone color="#2563EB" size="24px" />
                                      </span>
                                      <span className="description ver-4px">
                                        <p
                                          className="person-name"
                                          style={{ color: "#2563EB" }}
                                        >
                                          Ringing...
                                        </p>
                                      </span>
                                    </div>
                                  )}
                                  {labelEditLogId !== callLog?.id &&
                                    callLog?.labels?.length > 0 && (
                                      <div className="call-log-label-list">
                                        {callLog?.labels?.map(
                                          (label: any, index: any) => (
                                            <Label
                                              key={index}
                                              labelElement={label}
                                            />
                                          )
                                        )}
                                      </div>
                                    )}
                                  {labelEditLogId === callLog?.id && (
                                    <AssignLabel
                                      autofocus={true}
                                      assignedLabel={callLog?.labels}
                                      inbox={callLog?.voice_inbox?.id}
                                      onClose={(labels) =>
                                        assignCallLabel(labels)
                                      }
                                    />
                                  )}
                                  {callLog?.recording_url && (
                                    <div className="audio-player">
                                      <AudioStream
                                        settings={false}
                                        audioUrl={callLog?.recording_url}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {(callLog?.call_analysis?.call_summary ||
                              callLog?.call_summary) && (
                              <div
                                className="call-log-summary"
                                style={{
                                  transform:
                                    labelEditLogId !== callLog?.id
                                      ? "translateX(52px)"
                                      : "translateX(52px)",
                                }}
                              >
                                <span className="right-line"></span>
                                <span className="hor-8px summary-heading">
                                  Call summary{" "}
                                  <Sparkles size="14px" color="#9333EA" />
                                </span>
                                <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={{
                              __html: callLog?.call_summary,
                            }}
                          ></div>
                              </div>
                            )}
                          </div>
                          <div className="message-user">
                            <ProfileIcon
                              iconSize="20px"
                              name={callLog?.user?.name ?? ""}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          {showCallDetails && (
            <CallLogDetails
              callLog={selectedCallLog}
              close={() => setShowCallDetails(false)}
              customerUpdate={() => {}}
              callUpdate={() => {}}
            />
          )}
          {showCustomerProfile && (
            <ContactDetails
              customer={customer}
              close={(updatedCustomer: any) => updateCustomer(updatedCustomer)}
              onAdd={function (customer: Customer): void {
                throw new Error("Function not implemented.");
              }}
              onDelete={function (): void {
                throw new Error("Function not implemented.");
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CallLogsComponent;
