import { SubscriptionPlan } from "./subscription_plan";

export class Subscription {
  private _id: number;
  private _start_date: string;
  private _end_date: string;
  private _conversation_quota: number;
  private _conversation_consumed: number;
  private _conversation_consumed_date: Date;
  private _agent_quota: number;
  private _agent_consumed: number;
  private _customer_quota: number;
  private _customer_consumed: number;
  private _label_quota: number;
  private _label_consumed: number;
  private _subscription_type: number;
  private _team_inbox_login: boolean;
  private _is_active: boolean;
  private _is_future: boolean;
  private _is_free: boolean;
  private _is_expired: boolean;
  private _is_terminated: boolean;
  private _terminated_on: Date;
  private _utility: number;
  private _uic: number;
  private _created_at: string;
  private _business: number;
  private _plan: SubscriptionPlan;
  private _user: number;
  private _terminated_by: number;
  private _order: number;
  private _duration: string;
  private _top_up_quota: number;
  private _marketing_price: number;
  private _bring_own_twilio: boolean;
  private _outbound_call: boolean;
  private _white_label: boolean;
  private _call_rate: number;
  private _voice_detection_rate: number;

    public get voice_detection_rate(): number {
        return this._voice_detection_rate;
    }

    public set voice_detection_rate(voice_detection_rate: number) {
        this._voice_detection_rate = voice_detection_rate;
    }

    private _cloud_numbers_quota: number

    public get cloud_numbers_quota(): number {
        return this._cloud_numbers_quota;
    }

    public set cloud_numbers_quota(cloud_numbers_quota: number) {
        this._cloud_numbers_quota = cloud_numbers_quota;
    }


    public get call_rate(): number {
        return this._call_rate;
    }

    public set call_rate(call_rate: number) {
        this._call_rate = call_rate;
    }

    public get bring_own_twilio(): boolean {
        return this._bring_own_twilio;
    }

    public set bring_own_twilio(bring_own_twilio: boolean) {
        this._bring_own_twilio = bring_own_twilio;
    }

    public get outbound_call(): boolean {
        return this._outbound_call;
    }

    public set outbound_call(outbound_call: boolean) {
        this._outbound_call = outbound_call;
    }

    public get white_label(): boolean {
        return this._white_label;
    }

    public set white_label(white_label: boolean) {
        this._white_label = white_label;
    }


  constructor(subscriptionJson: any) {
    this._id = subscriptionJson?.id;
    this._start_date = subscriptionJson?.start_date;
    this._end_date = subscriptionJson?.end_date;
    this._conversation_quota = subscriptionJson?.conversation_quota;
    this._conversation_consumed = subscriptionJson?.conversation_consumed;
    this._conversation_consumed_date = subscriptionJson?.conversation_consumed_date;
    this._agent_quota = subscriptionJson?.agent_quota;
    this._agent_consumed = subscriptionJson?.agent_consumed;
    this._customer_consumed = subscriptionJson?.customer_consumed;
    this._customer_quota = subscriptionJson?.customer_quota;
    this._label_quota = subscriptionJson?.label_quota;
    this._label_consumed = subscriptionJson?.label_consumed;
    this._subscription_type = subscriptionJson?.subscription_type;
    this._team_inbox_login = subscriptionJson?.team_inbox_login;
    this._is_active = subscriptionJson?.is_active;
    this._is_future = subscriptionJson?.is_future;
    this._is_free = subscriptionJson?.is_free;
    this._is_expired = subscriptionJson?.is_expired;
    this._is_terminated = subscriptionJson?.is_terminated;
    this._terminated_on = subscriptionJson?.terminated_on;
    this._utility = subscriptionJson?.utility;
    this._uic = subscriptionJson?.uic;
    this._created_at = subscriptionJson?.created_at;
    this._business = subscriptionJson?.business;
    this._plan = new SubscriptionPlan(subscriptionJson?.plan);
    this._user = subscriptionJson?.user;
    this._terminated_by = subscriptionJson?.terminated_by;
    this._order = subscriptionJson?.order;
    this._duration = subscriptionJson?.duration;
    this._top_up_quota = subscriptionJson?.top_up_quota;
    this._marketing_price = subscriptionJson?.marketing_price;
    this._bring_own_twilio = subscriptionJson?.bring_own_twilio;
    this._outbound_call = subscriptionJson?.outbound_call;
    this._white_label = subscriptionJson?.white_label;
    this._call_rate = subscriptionJson?.call_rate;
    this._cloud_numbers_quota = subscriptionJson?.cloud_numbers_quota;
    this._voice_detection_rate = subscriptionJson?.voice_detection_rate;
  }

  public toJson() {
    return {
      id: this._id,
      start_date: this._start_date,
      end_date: this._end_date,
      conversation_quota: this._conversation_quota,
      conversation_consumed: this._conversation_consumed,
      conversation_consumed_date: this._conversation_consumed_date,
      agent_quota: this._agent_quota,
      agent_consumed: this._agent_consumed,
      customer_consumed: this._customer_consumed,
      customer_quota: this._customer_quota,
      label_quota: this._label_quota,
      label_consumed: this._label_consumed,
      subscription_type: this._subscription_type,
      team_inbox_login: this._team_inbox_login,
      is_active: this._is_active,
      is_future: this._is_future,
      is_free: this._is_free,
      is_expired: this._is_expired,
      is_terminated: this._is_terminated,
      terminated_on: this._terminated_on,
      utility: this._utility,
      uic: this._uic,
      created_at: this._created_at,
      business: this._business,
      plan: this._plan.toJson(),
      user: this._user,
      terminated_by: this._terminated_by,
      order: this._order,
      duration: this._duration,
      top_up_quota: this._top_up_quota,
      marketing_price: this._marketing_price,
      bring_own_twilio: this._bring_own_twilio,
      outbound_call: this._outbound_call,
      white_label: this._white_label,
      call_rate: this._call_rate,
      cloud_numbers_quota: this._cloud_numbers_quota,
      voice_detection_rate: this._voice_detection_rate,
    };
  }
  

  public get id(): number {
      return this._id;
  }
  public set id(id: number) {
      this._id = id;
  }
  public get start_date(): string {
      return this._start_date;
  }
  public set start_date(start_date: string) {
      this._start_date = start_date;
  }
  public get end_date(): string {
      return this._end_date;
  }
  public set end_date(end_date: string) {
      this._end_date = end_date;
  }
  public get conversation_quota(): number {
      return this._conversation_quota;
  }
  public set conversation_quota(conversation_quota: number) {
      this._conversation_quota = conversation_quota;
  }
  public get conversation_consumed(): number {
      return this._conversation_consumed;
  }
  public set conversation_consumed(conversation_consumed: number) {
      this._conversation_consumed = conversation_consumed;
  }
  public get conversation_consumed_date(): Date {
      return this._conversation_consumed_date;
  }
  public set conversation_consumed_date(conversation_consumed_date: Date) {
      this._conversation_consumed_date = conversation_consumed_date;
  }
  public get agent_quota(): number {
      return this._agent_quota;
  }
  public set agent_quota(agent_quota: number) {
      this._agent_quota = agent_quota;
  }
  public get agent_consumed(): number {
      return this._agent_consumed;
  }
  public set agent_consumed(agent_consumed: number) {
      this._agent_consumed = agent_consumed;
  }
  public get customer_quota(): number {
      return this._customer_quota;
  }
  public set customer_quota(customer_quota: number) {
      this._customer_quota = customer_quota;
  }
  public get customer_consumed(): number {
      return this._customer_consumed;
  }
  public set customer_consumed(customer_consumed: number) {
      this._customer_consumed = customer_consumed;
  }
  public get label_quota(): number {
      return this._label_quota;
  }
  public set label_quota(label_quota: number) {
      this._label_quota = label_quota;
  }
  public get label_consumed(): number {
      return this._label_consumed;
  }
  public set label_consumed(label_consumed: number) {
      this._label_consumed = label_consumed;
  }
  public get subscription_type(): number {
      return this._subscription_type;
  }
  public set subscription_type(subscription_type: number) {
      this._subscription_type = subscription_type;
  }
  public get team_inbox_login(): boolean {
      return this._team_inbox_login;
  }
  public set team_inbox_login(team_inbox_login: boolean) {
      this._team_inbox_login = team_inbox_login;
  }
  public get is_active(): boolean {
      return this._is_active;
  }
  public set is_active(is_active: boolean) {
      this._is_active = is_active;
  }
  public get is_future(): boolean {
      return this._is_future;
  }
  public set is_future(is_future: boolean) {
      this._is_future = is_future;
  }
  public get is_free(): boolean {
      return this._is_free;
  }
  public set is_free(is_free: boolean) {
      this._is_free = is_free;
  }
  public get is_expired(): boolean {
      return this._is_expired;
  }
  public set is_expired(is_expired: boolean) {
      this._is_expired = is_expired;
  }
  public get is_terminated(): boolean {
      return this._is_terminated;
  }
  public set is_terminated(is_terminated: boolean) {
      this._is_terminated = is_terminated;
  }
  public get terminated_on(): Date {
      return this._terminated_on;
  }
  public set terminated_on(terminated_on: Date) {
      this._terminated_on = terminated_on;
  }
  public get utility(): number {
      return this._utility;
  }
  public set utility(utility: number) {
      this._utility = utility;
  }
  public get uic(): number {
      return this._uic;
  }
  public set uic(uic: number) {
      this._uic = uic;
  }
  public get created_at(): string {
      return this._created_at;
  }
  public set created_at(created_at: string) {
      this._created_at = created_at;
  }
  public get business(): number {
      return this._business;
  }
  public set business(business: number) {
      this._business = business;
  }
  public get plan(): SubscriptionPlan {
      return this._plan;
  }
  public set plan(plan: SubscriptionPlan) {
      this._plan = new SubscriptionPlan(plan);
  }
  public get user(): number {
      return this._user;
  }
  public set user(user: number) {
      this._user = user;
  }
  public get terminated_by(): number {
      return this._terminated_by;
  }
  public set terminated_by(terminated_by: number) {
      this._terminated_by = terminated_by;
  }
  public get order(): number {
      return this._order;
  }
  public set order(order: number) {
      this._order = order;
  }
  public get duration(): string {
    return this._duration;
  }
  public set duration(duration: string) {
      this._duration = duration;
  }
  public get top_up_quota(): number {
    return this._top_up_quota;
  } 
  public set top_up_quota(top_up_quota: number) {
      this._top_up_quota = top_up_quota;
  }
  public get marketing_price(): number {
    return this._marketing_price;
  }
  public set marketing_price(marketing_price: number) {
      this._marketing_price = marketing_price;
  }

}