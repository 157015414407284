import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../shadcn/input-otp";
import { Input } from "../shadcn/input";
import { Button } from "../shadcn/button";
import { Loader2 } from "lucide-react";
import { oneInboxSendOtp, oneInboxLogin } from "../../lib/authentication.utils";
import { useAgencyStore } from "./../../store/agency.store";
import { useSharedStore } from "../../store/shared.store";
import { LoggedUser } from "../../models/logged-user";
import {
  getInboxesData,
  getStripeKeys,
  getWidgetsData,
} from "../../lib/common.utils";
import { verifyUser } from "../../lib/agents.utils";
import Toaster from "../shared/customToast";

const UserInvite = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [verifyOtpScreen, setVerifyOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [loaderSendOtp, setLoaderSendOtp] = useState(false);
  const [loaderEmailLogin, setLoaderEmailLogin] = useState(false);
  const [emailRetryCount, setEmailRetryCount] = useState(0);
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();
  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && token.length > 10) {
      navigate("/agents");
    }

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("invite_code");
    if (code) {
      navigate(".", { replace: true });
      verifyUser(code)
        .then((resp) => {
          setBusinessName(resp.data.data.business_name);
          setEmail(resp.data.data.email);
        })
        .catch((error) => {
          Toaster.error(error);
          navigate('/');
        });
    } else navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (emailRetryCount > 0) {
      const timer = setInterval(() => {
        setEmailRetryCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [emailRetryCount]);

  const sendOtp = () => {
    setLoaderSendOtp(true);
    oneInboxSendOtp({ email }).then(
      () => {
        setVerifyOtpScreen(true);
        setLoaderSendOtp(false);
        setEmailRetryCount(30);
      },
      () => setLoaderSendOtp(false)
    );
  };

  const verifyOtp = () => {
    setLoaderEmailLogin(true);
    oneInboxLogin({ email, otp }).then(
      (resp) => {
        successfulLogin(resp);
        setLoaderEmailLogin(false);
      },
      () => setLoaderEmailLogin(false)
    );
  };

  const successfulLogin = (resp: any) => {
    if (resp.data?.token) {
      localStorage.setItem("token", `JWT ${resp.data.token.access}`);
      localStorage.setItem("refresh", resp.data.token.refresh);
    }
    if (resp.data?.user) {
      localStorage.setItem("user", JSON.stringify(resp.data.user));
      sharedStore.setLoggedUser(new LoggedUser(resp.data.user));
    }
    if (resp.data?.business) {
      localStorage.setItem("business", JSON.stringify(resp.data.business));
      sharedStore.setBusiness(resp.data.business);
      getInboxesData();
      getWidgetsData();
      getStripeKeys();
    } else {
      navigate("/onboarding");
      return;
    }
    navigate("/agents");
  };

  const handleOtpChange = (value: any) => {
    setOtp(value);
    setIsValidOtp(value.length === 6);
  };

  const backToLogin = () => {
    setOtp("");
    setIsValidOtp(false);
    setVerifyOtpScreen(false);
  };

  return (
    <div className="w-full h-screen flex">
      <div className="w-1/2 flex justify-center items-center bg-primary">
        <div className="text-4xl font-medium text-gray-50 flex items-center gap-2">
          <img src={agencyStore.lightLogo} alt="" className="h-16" />
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex items-center justify-center min-h-screen bg-white">
          {!verifyOtpScreen ? (
            <div className="flex flex-col gap-6 p-6 bg-white rounded-lg w-96">
              <div className="flex flex-col items-center gap-1">
                <h2 className="text-2xl font-semibold text-primary">
                  You've been invited to join
                </h2>
                <p className="text-sm text-secondary">
                  {businessName} on {agencyStore.brand}
                </p>
                <p className="text-sm text-secondary">
                  Create an account with us 😀
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium text-primary">
                  Email
                </label>
                <Input
                  placeholder="m@example.com"
                  disabled={true}
                  type="email"
                  value={email}
                />
              </div>
              <Button
                variant="default"
                onClick={sendOtp}
                disabled={loaderSendOtp}
              >
                {loaderSendOtp ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Please wait
                  </>
                ) : (
                  "Continue"
                )}
              </Button>
            </div>
          ) : (
            <div className="flex flex-col gap-6 p-6 bg-white rounded-lg w-96">
              <div className="flex flex-col items-center gap-1">
                <h2 className="text-2xl font-semibold text-primary">
                  Verify email
                </h2>
                <p className="text-sm text-secondary">
                  Enter the 6-digit code we just sent to
                </p>
                <p className="text-sm text-secondary">
                  <span className="text-primary">{email}</span> to continue.
                </p>
              </div>
              <div className="flex justify-center">
                <InputOTP
                  maxLength={6}
                  value={otp}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      verifyOtp();
                    }
                  }}
                  onChange={(e) => setOtp(e)}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
              <Button onClick={verifyOtp} disabled={loaderEmailLogin}>
                {loaderEmailLogin ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Please wait
                  </>
                ) : (
                  "Verify"
                )}
              </Button>
              <Button variant="outline" onClick={backToLogin}>
                Go back
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInvite;
