import React, { useEffect, useState } from 'react';
import { Button } from '../../shadcn/button';
import { Input } from '../../shadcn/input';
import { Textarea } from '../../shadcn/textarea';
import { ChevronDown, EllipsisVertical, Loader2, Plus, Trash } from 'lucide-react';
import { AgentAction } from '../../../models/agent-action';
import { updateAgentAction, addAgentAction } from '../../../lib/agents.utils';
import Toaster from '../../shared/customToast';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../shadcn/dropdown-menu';
import { v4 as uuidv4 } from 'uuid';
import { ScrollArea } from '../../shadcn/scrollArea';
import { SheetContent, Sheet } from "../../shadcn/sheet";

interface InfoExtractActionComponentProps {
  action: AgentAction;
  isEdit?: boolean;
  agentId: number;
  onSuccess: (action: AgentAction) => void;
  onClose: () => void;
}

interface Extraction {
  id: string;
  text: string;
  name: string;
  type: string;
  examples: { id: string; value: string }[];
}

const InfoExtractActionComponent: React.FC<InfoExtractActionComponentProps> = ({
  action,
  isEdit,
  agentId,
  onSuccess,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [actionName, setActionName] = useState('');
  const [trigger, setTrigger] = useState('call_ended');
  const [isOpen, setIsOpen] = useState(true);
  const [extractionsList, setExtractionsList] = useState<Extraction[]>([]);


  const fieldMap: Record<string,string> = {
    Text: 'string',
    Number: 'number',
    'Yes/No': 'boolean',
    'Multiple choice': 'enum',
  };

  const textMap: Record<string,string> = {
    string: 'Text',
    number: 'Number',
    boolean: 'Yes/No',
    enum: 'Multiple choice',
  };

  const exampleHoverInfo =
    'Provide data samples to help the AI better understand your needs and deliver more accurate results';

  useEffect(() => {
    if (action?.id && isEdit) {
      setActionName(action?.name);
      setTrigger(action?.trigger);
      const fields = action?.fields?.map((field: { name: any; description: any; type: string; examples: any[]; choices: any[]; }) => ({
        id: uuidv4(),
        name: field?.name,
        text: field?.description,
        type: textMap[field?.type],
        examples:
          field?.type === 'string'
        ? field?.examples?.map((example: any) => ({ id: uuidv4(), value: example })) || []
        : field?.type === 'enum'
        ? field?.choices?.map((choice: any) => ({ id: uuidv4(), value: choice })) || []
        : [],
      }));
      setExtractionsList(fields);
    } else {
      let extractList: any[] = [{ id: uuidv4(), text: '', name: '', type: 'Text', examples: [] }]
      setExtractionsList(extractList);
    }
  }, [action, isEdit]);

  const addExtraction = () => {
    setExtractionsList((prev) => [
      ...prev,
      { id: uuidv4(), text: '', name: '', type: 'Text', examples: [{ id: uuidv4(), value: '' }] },
    ]);
  };

  const removeExtraction = (index: number) => {
    setExtractionsList((prev) => prev.filter((_, i) => i !== index));
  };

  const updateName = (index: number, name: string) => {
    setExtractionsList((prev) => {
      const updated = [...prev];
      updated[index].name = name.replaceAll(' ', '_');
      return updated;
    });
  };

  const updateDesc = (index: number, text: any) => {
    setExtractionsList((prev) => {
      const updated = [...prev];
      updated[index].text = text;
      return updated;
    });
  };

  const updateType = (index: number, type: string) => {
    setExtractionsList((prev) => {
      const updated = [...prev];
      updated[index].type = type;
      updated[index].examples = type === 'Multiple choice' ? [{ id: uuidv4(), value: '' }] : [];
      return updated;
    });
  };

  const addExample = (index: number) => {
    setExtractionsList((prev) => {
      const updated = [...prev];
      updated[index].examples.push({ id: uuidv4(), value: '' });
      return updated;
    });
  };

  const removeExample = (index: number, exampleIndex: any) => {
    setExtractionsList((prev) => {
      const updated = [...prev];
      updated[index].examples = updated[index].examples.filter((_: any, i: any) => i !== exampleIndex);
      return updated;
    });
  };

  const handleSubmit = async () => {
    const extracts = [];

    for (const field of extractionsList) {
      if (!field.name) {
        Toaster.error('Enter name of the field');
        return;
      }
      if (!field.text) {
        Toaster.error('Enter information of the field');
        return;
      }

      for (const example of field.examples) {
        if (!example.value) {
          Toaster.error(`Enter ${field.type === 'Text' ? 'example' : 'choice'} value of the field`);
          return;
        }
      }

      
      const fieldObj: Record<string, any> = {
        name: field.name,
        description: field.text,
        type: fieldMap[field.type],
      };

      if (field.examples?.length > 0) {
        const values: string[] = field.examples.map((example: { value: any }) => example.value);
        if (fieldObj.type === 'string') fieldObj.examples = values;
        if (fieldObj.type === 'enum') fieldObj.choices = values;
      }

      if (trigger === 'during_call') fieldObj.type = 'string';

      extracts.push(fieldObj);
    }

    setIsLoading(true);
    const payload = {
      name: actionName,
      type: trigger === 'call_ended' ? 'post_call_extract_info' : 'extract_info',
      fields: extracts,
      trigger,
    };

    try {
      const response = isEdit
      ? await updateAgentAction(agentId, action?.id, payload)
      : await addAgentAction(agentId, payload);
      Toaster.success(response.data.message);
      onSuccess(new AgentAction(response.data.data));
    } catch (error) {
      Toaster.error(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={() => {setIsOpen(false); onClose();}}>
      <SheetContent className='p-0 w-[450px] !max-w-[450px]'>
        <ScrollArea className='w-full h-full overflow-auto p-4'>
          <div className="bg-white w-full flex flex-col h-full gap-4 p-2">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center text-primary text-lg font-semibold leading-[30px]">
                <span>Extract info</span>
              </div>
              <span className="text-secondary text-sm font-normal leading-[21px]">
                Capture relevant information from the call conversations
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium leading-[21px]">Action name</span>
                <Input
                  placeholder="e.g. good bye"
                  value={actionName}
                  onChange={(e) => setActionName(e.target.value)}
                  className="border p-2 rounded-lg"
                />
              </div>
              <div className="flex-1 flex flex-col gap-1">
                <span className="text-primary text-sm font-medium leading-[21px]">Trigger</span>
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={isEdit}>
                    <Button variant="outline" disabled={isEdit} className='w-full justify-between font-normal'>{trigger === 'during_call' ? 'During call' : 'After call'} <ChevronDown /></Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                    <DropdownMenuItem onClick={() => setTrigger('call_ended')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]">
                      After call
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setTrigger('during_call')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[390px]">
                      During call
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex flex-col gap-4">
                {extractionsList.map((item, index) => (
                  <div key={item.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-center pb-4 text-sm text-secondary">
                      <span>Info {index + 1}</span>
                      {extractionsList.length > 1 && (
                        <Button variant="ghost" size="icon" onClick={() => removeExtraction(index)}>
                          <Trash />
                        </Button>
                      )}
                    </div>

                    <div className="flex flex-col gap-4">
                      <div>
                        <label className="block text-sm font-medium">What information should be extracted?</label>
                        <Input
                          placeholder="e.g. Capture customer name"
                          value={item.text}
                          onChange={(e) => updateDesc(index, e.target.value)}
                        />
                      </div>

                      <div className="flex gap-4">
                        <div className="flex-1">
                          <label className="block text-sm font-medium">Info name</label>
                          <Input
                            placeholder="e.g. user name"
                            value={item.name}
                            onChange={(e) => updateName(index, e.target.value)}
                          />
                        </div>

                        <div className="flex-1">
                          <label className="block text-sm font-medium">Info type</label>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="outline" className="w-full flex justify-between font-normal">
                                <span>{item.type}</span>
                                <ChevronDown />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem onClick={() => updateType(index, "Text")}>Text</DropdownMenuItem>
                              <DropdownMenuItem onClick={() => updateType(index, "Number")}>Number</DropdownMenuItem>
                              <DropdownMenuItem onClick={() => updateType(index, "Yes/No")}>Yes/No</DropdownMenuItem>
                              <DropdownMenuItem onClick={() => updateType(index, "Multiple choice")}>Multiple choice</DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>

                      {(item.type === "Text" || item.type === "Multiple choice") && (
                        <div>
                          {item.examples.map((example: { id: React.Key | null | undefined; value: string | number | readonly string[] | undefined; }, exampleIndex: number) => (
                            <div key={example.id} className="flex items-center gap-2 mb-2">
                              <Input
                                placeholder={item.type === "Text" ? "Example" : "Choice"}
                                value={example.value}
                                onChange={(e) => {
                                  const updated = [...extractionsList];
                                  updated[index].examples[exampleIndex].value = e.target.value;
                                  setExtractionsList(updated);
                                }}
                              />
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => removeExample(index, exampleIndex)}
                                disabled={item.examples.length === 1 && item.type === "Multiple choice"}
                              >
                                <Trash />
                              </Button>
                            </div>
                          ))}
                          <Button
                            variant="ghost"
                            onClick={() => addExample(index)}
                            className="text-primary flex items-center gap-2"
                          >
                            <Plus /> Add {item.type === "Text" ? "example" : "choice"}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <Button variant="ghost" onClick={addExtraction} className="text-primary flex items-center gap-2 w-fit">
                  <Plus /> Extract more
                </Button>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                disabled={isLoading || actionName === ''}
                onClick={handleSubmit}
                className="bg-primary text-white p-2 rounded-lg"
              >
                {isLoading ? <span className="flex gap-2 align-center"><Loader2 className="animate-spin" /> Loading...</span> : <span>{isEdit ? 'Save changes' : 'Add action'}</span>}
              </Button>
            </div>
          </div>
        </ScrollArea>
      </SheetContent>
      </Sheet>
  );
};

export default InfoExtractActionComponent;
