import React, { useState } from "react";
import { CheckCircle, ArrowLeft, Plus } from "lucide-react";
import { Button } from "../../shadcn/button";
import WelcomeAddPhoneNumber from "./WelcomeAddNumber";
import WelcomeAddWidget from "./WelcomeAddWidget";

interface WelcomeForm3Props {
  isNumberAdded: boolean;
  isWidgetAdded: boolean;
  onSkip: () => void;
  onBack: () => void;
  onContinue: () => void;
  onNumberAdded: (number: any) => void;
  onWidgetAdded: (widget: any) => void;
}

const WelcomeForm3: React.FC<WelcomeForm3Props> = ({
  isNumberAdded,
  isWidgetAdded,
  onSkip,
  onBack,
  onContinue,
  onNumberAdded,
  onWidgetAdded,
}) => {
  const [currentScreen, setCurrentScreen] = useState<string>("");

  return (
    <div className="w-full flex flex-col gap-4 p-4 h-full overflow-auto">
      <div className="flex items-center justify-between p-4">
        <button onClick={onBack} className="flex items-center text-gray-500">
          <ArrowLeft size={20} className="mr-1" /> Back
        </button>
        <div className="flex items-center space-x-2 text-purple-600">
          <CheckCircle size={18} /> <span>Create AI agent</span>
          <hr className="border-t w-12" />
          <CheckCircle size={18} /> <span>Test AI agent</span>
          <hr className="border-t w-12" />
          <span className="font-semibold">Deploy AI agent</span>
          <hr className="border-t w-12" />
          <span>Go live</span>
        </div>
        <button onClick={onSkip} className="text-gray-500">Skip</button>
      </div>


      <div className="flex flex-col items-center p-4">
        <h2 className="text-xl font-semibold text-gray-800">Deploy AI agent</h2>
        <p className="text-sm text-gray-500">Deploy your AI agent on a phone number or website.</p>
      </div>

      {currentScreen === "" && (
        <div className="flex gap-8 p-6 justify-center items-center">
          <div className="p-6 rounded-lg w-full max-w-md border border-[var(--border-color)] flex justify-center flex-col gap-10 items-center">
            <img src="./assets/images/widget_empty.png" alt="Widget" className="mb-2" />
            <div className="flex flex-col gap-1 items-center">
              <h3 className="text-md ">Widget</h3>
              <p className="text-sm text-gray-500">Deploy voice AI agent in your website</p>
            </div>
            <div className="mt-3">
              {!isNumberAdded && !isWidgetAdded ? (
                <Button variant={"outline"} onClick={() => setCurrentScreen("add-widget")}>
                  <Plus></Plus> Add Widget
                </Button>
              ) : (
                <Button onClick={onContinue}>Continue</Button>
              )}
            </div>
          </div>

          <span className="block text-center text-gray-400">or</span>

          <div className="p-6 rounded-lg w-full max-w-md border border-[var(--border-color)] flex justify-center flex-col gap-10 items-center">
            <img src="./assets/images/phone_number_empty.png" alt="Phone Number" className="mb-2" />
            <div className="flex flex-col gap-1 items-center">
              <h3 className="text-md ">Phone Number</h3>
              <p className="text-sm text-gray-500">Deploy voice AI agent on your phone number</p>
            </div>
            <div className="mt-3 space-x-2">
              {!isNumberAdded && !isWidgetAdded ? (
                <>
                  <Button onClick={() => setCurrentScreen("add-number")}>
                  <Plus></Plus> Add Number
                  </Button>
                  <Button variant="secondary" disabled={true} onClick={() => setCurrentScreen("import-number")}>
                  <Plus></Plus> Import Number
                  </Button>
                </>
              ) : (
                <Button onClick={onContinue}>Continue</Button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full justify-center">
        {currentScreen === "add-number" && <WelcomeAddPhoneNumber onClose={() => setCurrentScreen("")} onSuccess={(number) => onNumberAdded(number) } />}
        {currentScreen === "add-widget" && <WelcomeAddWidget  onClose={() => setCurrentScreen("")} onSuccess={(widget) => onWidgetAdded(widget)} />}
        {currentScreen === "import-number" && <div>{/* Number Import Component Here */}</div>}
      </div>
    </div>
  );
};

export default WelcomeForm3;
