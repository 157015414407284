import {
  getCallConversations,
  getCallLogs,
  getSingleCallLog,
  searchCallLogs,
} from "../../lib/voice.utils";
import { CloudNumber } from "../../models/cloud-number";
import { useRetellStore } from "../../store/retell.store";
import useSharedStore from "../../store/shared.store";
import { useWebSocketStore } from "../../store/websocket.store";
import { ChevronDown, ListFilter, PanelLeft, PhoneCall, Sheet } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, Params, useSearchParams } from "react-router-dom";
import { Subject, takeUntil } from "rxjs";
import { Input } from "../shadcn/input";
import CallConversation from "./CallConversation";
import { Skeleton } from "../shadcn/skeleton";
import { ScrollArea } from "../shadcn/scrollArea";
import SingleCall from "./SingleCall";
import CallLogsComponent from "./CallLogs";
import CallsTableView from "./CallsTableView";
import CallFilter from "./CallFilter";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";

const CallsComponent = () => {
  const [loaderList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]);
  const [callLogsList, setCallLogsList] = useState<any[]>([]);
  const [listType, setListType] = useState("conversation");
  const [isLoading, setIsLoading] = useState(false);
  const [loaderPagination, setLoaderPagination] = useState(false);
  const [pageNext, setPageNext] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [conversationCount, setConversationCount] = useState(0);
  const [openConversationId, setOpenConversationId] = useState(0);
  const [openedConversationIndex, setOpenedConversationIndex] =
    useState<number>(0);
  const [openedConversation, setOpenedConversation] = useState<any>(null);
  const [currentInboxId, setCurrentInboxId] = useState<number>(0);
  const currentInboxIdRef = useRef(currentInboxId); 
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [labelFilters, setLabelFilters] = useState([]);
  const [assigneeFilter, setAssigneeFilter] = useState([]);
  const [callTypeFilter, setCallTypeFilter] = useState("");
  const [callStatusFilter, setCallStatusFilter] = useState("");
  const [assignAgentPopup, setAssignAgentPopup] = useState(false);

  const [activeCloudNumber, setActiveCloudNumber] = useState(0);
  const [filterPopup, setFilterPopup] = useState(false);
  const [uiFilterTab, setUiFilterTab] = useState<any[]>([]);
  const [currentAgentId, setCurrentAgentId] = useState(null);
  const [contactsSearchTag, setContactsSearchTag] = useState("");
  const [prevContactsSearchTag, setPrevContactsSearchTag] = useState("");
  const [viewType, setViewType] = useState("conversation");
  const [callLogsSource, setCallLogsSource] = useState("inbox");
  const destroy$ = useRef(new Subject<void>());
  const [loaderCallLog, setLoaderCallLog] = useState(false);

  const location = useLocation();
  const urlParams = useParams();
  const websocketStore = useWebSocketStore();
  const sharedStore = useSharedStore();
  const retellStore = useRetellStore();
  var params: Params;
  const setParams = (parms: any) => {
    params = parms as Params;
  };
  const [searchParams] = useSearchParams();

  useEffect(() => {
    sharedStore.inAuthModule = false;
    setPageNumber(1);
    setPageNext(true);
    setIsLoading(true);
    // getCallConversations(pageNumber);

    websocketStore.callEvents$
      .pipe(takeUntil(destroy$.current))
      .subscribe((event) => {
        console.log(event);
        console.log(currentInboxIdRef.current);
        if (event && event?.number_id) {
          if (event.number_id === params?.inbox_id) {
            let callLogFound = false;
            setCallLogsList((prev) =>
              prev.map((element) => {
                if (element?.id === event?.conversation_id) {
                  element.last_call_status = event?.call_status;
                  callLogFound = true;
                }
                return element;
              })
            );
            if (!callLogFound) {
              let callDetails = {
                id: event?.conversation_id,
                contact: event?.customer,
                last_call_status: event?.call_status,
              };
              setCallLogsList((prev) => [callDetails, ...prev]);
            }
          }
        }
      });

    retellStore.outgoingCallTrigger
      .pipe(takeUntil(destroy$.current))
      .subscribe((data) => {
        console.log(data);
        console.log(currentInboxIdRef.current);
        if (data?.type === "call_started") {
          if(data?.inbox_id) {
            setPageNumber(1);
            setPageNext(true);
            setIsLoading(true);
            setOpenedConversation(null);
            setCallLogsList([]);
            getCallConversationsList(1);
          }
        }
      });

    sharedStore.cloudNumbers?.forEach((element) => {
      if (element?.is_active) setActiveCloudNumber((prev) => prev + 1);
    });

    const queryParams = new URLSearchParams(location.search);
    const agentId = queryParams.get("agent");
    const widgetId = queryParams.get("widget");

    if (agentId) {
      const filters = {
        and: {
          agent_id__in: [agentId],
        },
      };
      setParams({
        call_type: "web",
        filters: JSON.stringify(filters),
      });
      setPageNumber(1);
      setPageNext(true);
      setIsLoading(true);
      setViewType("conversation");
      setCallLogsSource("agent");
      setCallLogsList([]);
      getCallLogsList(1);
    } else if (widgetId) {
      setParams({
        widget_id: widgetId,
      });
      setPageNumber(1);
      setIsLoading(true);
      setPageNext(true);
      setViewType("conversation");
      setCallLogsSource("widget");
      setCallLogsList([]);
      getCallLogsList(1);
    } else {
      const id = urlParams?.id;
      if (id) {
        setViewType("conversation");
        setCallLogsSource("inbox");
        setCurrentInboxId(Number(id))
      }
    }

    return () => {
      destroy$.current.next();
      destroy$.current.complete();
      sessionStorage.removeItem("call-filters");
    };
  }, [urlParams,searchParams]);

  useEffect(() => {
    let currentInbox = sharedStore.cloudNumbers[0];
    sharedStore.cloudNumbers?.forEach((element) => {
      if (element?.id === currentInboxId) currentInbox = element;
    });
    if(currentInboxId) {
      currentInboxIdRef.current = currentInboxId; 
      numberChange(currentInbox);
    }
  }, [currentInboxId])

  const onScroll = (event: any) => {
    console.log(event);
    if (
      event.target.scrollHeight -  event.target.scrollTop === event.target.clientHeight
    ) {
      if (pageNext && !loaderPagination) {
        if (callLogsSource === "inbox") getCallConversationsList(pageNumber);
        else getCallLogsList(pageNumber);
      }
    }
  };

  const getCallLogsList = (page: number) => {
    setLoaderPagination(true);
    setListType("logs");

    // Replace voiceService with appropriate API call
    getCallLogs(page, params)
      .then(
        (resp) => {
          if (resp.data?.code === 200) {
            setPageNext(resp.data.next !== null);
            if (page === 1) {
              setCallLogsList([]);
              setConversationCount(resp.data.count);
            }

            let updatedCallLogsList: any[] = [];
            if (page === 1) {
              setCallLogsList([]);
              setConversationCount(resp.data.count);
            } else updatedCallLogsList = [...callLogsList];
            resp.data.data.forEach((call: any) => {
              if (
                call?.call_status === "registered" &&
                timeDifferenceOf5Mins(call?.created_at)
              ) {
                call.call_status = "ended";
              }
              updatedCallLogsList.push(call);
            });

            if (page === 1) {
              setOpenedConversation(updatedCallLogsList[0]);
              setOpenedConversationIndex(0);
            }

            setCallLogsList(updatedCallLogsList);
            setPageNumber(page + 1);
          }
          setIsLoading(false);
          setLoaderPagination(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
          setLoaderPagination(false);
          setCallLogsList([]);
        }
      )
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setLoaderPagination(false);
        setCallLogsList([]);
      });
  };

  useEffect(() => {
    contactsSearchInput();
  }, [contactsSearchTag])

  const contactsSearchInput = () => {
    setTimeout(() => {
      if (
        prevContactsSearchTag.trim() !== contactsSearchTag.trim() &&
        contactsSearchTag.length > 2
      ) {
        setPageNumber(1);
        setPageNext(true);
        setPrevContactsSearchTag(contactsSearchTag);
        setCallLogsList([]);
        searchCallLogsList(1);
      } else if (
        prevContactsSearchTag.trim() !== contactsSearchTag.trim() &&
        prevContactsSearchTag.length > 2
      ) {
        setPageNumber(1);
        setPageNext(true);
        setPrevContactsSearchTag(contactsSearchTag);
        getCallConversationsList(1);
      }
    }, 300);
  };

  const searchCallLogsList = (pageNumber: number) => {
    setIsLoading(true);
    setPrevContactsSearchTag(contactsSearchTag);
    setListType("logs");

    searchCallLogs(pageNumber, contactsSearchTag)
      .then(
        (resp) => {
          if (resp.data?.code === 200) {
            setPageNext(resp.data.next !== null);
            let updatedCallLogsList: any[] = [];
            if (pageNumber === 1) {
              setCallLogsList([]);
              setConversationCount(resp.data.count);
            } else updatedCallLogsList = [...callLogsList];
            resp.data.data.forEach((call: any) => {
              if (
                call?.call_status === "registered" &&
                timeDifferenceOf5Mins(call?.created_at)
              ) {
                call.call_status = "ended";
              }
              updatedCallLogsList.push(call);
            });

            if (pageNumber === 1) {
              setOpenedConversation(updatedCallLogsList[0]);
              setOpenedConversationIndex(0);
            }

            setCallLogsList(updatedCallLogsList);
            setPageNumber(pageNumber + 1);
          }
          setIsLoading(false);
          setLoaderPagination(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
          setLoaderPagination(false);
          setCallLogsList([]);
        }
      )
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setLoaderPagination(false);
        setCallLogsList([]);
      });
  };

  const timeDifferenceOf5Mins = (time: string | number | Date) => {
    const currentTime = new Date();
    const targetDateTime = new Date(time);
    const targetTimeInMs = targetDateTime.getTime();
    const currentTimeInMs = currentTime.getTime();
    const differenceInMs = currentTimeInMs - targetTimeInMs;
    const fiveMinutesInMs = 5 * 60 * 1000;

    return differenceInMs > fiveMinutesInMs;
  };

  const getCallConversationsList = (pageNumber: number) => {
    if(currentInboxIdRef.current === 0) return ;
    setLoaderPagination(true);
    setListType("conversation");

    getCallConversations(pageNumber, currentInboxIdRef.current)
      .then(
        (resp) => {
          if (resp.data?.code === 200) {
            setPageNext(resp.data.next !== null);
            let updatedCallLogsList: any[] = [];
            if (pageNumber === 1) {
              setCallLogsList([]);
              setConversationCount(resp.data.count);
            } else updatedCallLogsList = [...callLogsList];
            resp.data.data.forEach((call: any) => {
              if (
                call?.call_status === "registered" &&
                timeDifferenceOf5Mins(call?.created_at)
              ) {
                call.call_status = "ended";
              }
              updatedCallLogsList.push(call);
            });

            if (pageNumber === 1) {
              setOpenedConversation(updatedCallLogsList[0]);
              setOpenedConversationIndex(0);
            }

            setCallLogsList(updatedCallLogsList);
            setPageNumber(pageNumber + 1);
          }
          setIsLoading(false);
          setLoaderPagination(false);
        },
        (error) => {
          console.error(error);
          setIsLoading(false);
          setLoaderPagination(false);
          setCallLogsList([]);
        }
      )
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setLoaderPagination(false);
        setCallLogsList([]);
      });
  };

  const numberChange = (number: CloudNumber) => {
    setParams({ inbox_id: number.id });
    setPageNumber(1);
    setIsLoading(true);
    setPageNext(true);
    getCallConversationsList(1);
  };

  const filterChange = (filter: any) => {
    setFilterPopup(false);
    if (filter?.api) {
      setUiFilterTab(filter?.ui);
      setParams({ ...params, filters: JSON.stringify(filter.api), inbox_id: currentInboxId });
      setPageNumber(1);
      setIsLoading(true);
      setPageNext(true);
      setOpenConversationId(0);
      setOpenedConversation(null);
      getCallLogsList(1);
    } else {
      setUiFilterTab([]);
      const newParams = { ...params };
      delete newParams.filters;
      setParams(newParams);
      setPageNumber(1);
      setIsLoading(true);
      setPageNext(true);
      setOpenConversationId(0);
      setOpenedConversation(null);
      getCallConversationsList(1);
    }
  };

  const onCustomerUpdate = (customer: any) => {
    setOpenedConversation((prev: any) => ({ ...prev, customer }));
    setCallLogsList((prev) =>
      prev.map((log, index) =>
        index === openedConversationIndex ? { ...log, customer } : log
      )
    );
  };

  const openDialer = () => {
    sharedStore?.cloudNumbers?.forEach((element) => {
      if (currentInboxId === element?.id)
        sharedStore.setDialerInboxSelected(element);
    });
    sharedStore.setDialerPopup(true);
  };

  const updateCallLog = (call: any) => {
    if (openedConversation.id === call?.id) {
      setOpenedConversation(call);
      setCallLogsList((prev) =>
        prev.map((log, index) =>
          index === openedConversationIndex ? call : log
        )
      );
    }
  };

  return (
    <div className="flex w-full h-screen p-0 m-0 overflow-hidden">
      {viewType === "conversation" && (
        <div
          className="flex w-full"
          style={{ paddingTop: `${sharedStore.getNotificationBarSize()}px` }}
        >
          <div className="w-72 h-full border-r border-gray-300">
            <div className="flex items-center justify-between h-16 p-4 border-b border-gray-300 text-primary font-inter text-lg font-semibold">
              <span>
                {listType === "conversation" ? "Conversations" : "Calls"}{" "}
                {conversationCount && (
                  <span className="text-secondary text-base font-medium">
                    {conversationCount}
                  </span>
                )}
              </span>
              <span className="flex items-center gap-2">
                {sharedStore?.business?.subscription?.outbound_call && (
                  <span
                    onClick={() => (activeCloudNumber > 0 ? openDialer() : "")}
                    style={{
                      cursor: activeCloudNumber > 0 ? "pointer" : "default",
                    }}
                    title={
                      activeCloudNumber > 0
                        ? ""
                        : "No active number available for outgoing call"
                    }
                    className="cursor-pointer h-6"
                  >
                    <PhoneCall color="#5521B5" size="24px" />
                  </span>
                )}
                <span className="relative flex">
                  <Popover 
                    open={filterPopup}
                  >
                    <PopoverTrigger>
                      <span className="cursor-pointer" onClick={() => setFilterPopup(!filterPopup)}>
                      <ListFilter color="#64748B" size="24px" />
                      </span>
                    </PopoverTrigger>
                    <PopoverContent onClick={(event) => {event.stopPropagation();event.preventDefault();}} className="w-full max-w-[none]">
                      <CallFilter onClose={() => setFilterPopup(false)} agentsHide={callLogsSource === 'agent'} filterChange={filterChange} />
                    </PopoverContent>
                  </Popover>
                </span>
              </span>
            </div>
            <div className="p-2">
              <Input
                type="text"
                value={contactsSearchTag}
                onChange={(e) => setContactsSearchTag(e.target.value)}
                placeholder="Search contacts"
                className="shadcn-input"
              />
            </div>
            {uiFilterTab && uiFilterTab.length > 0 && (
              <div className="flex gap-2 p-2 items-center whitespace-nowrap overflow-hidden">
                <div
                  className="bg-[var(--hover-color)] text-primary text-sm font-normal text-center p-1 rounded cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilterPopup(true);
                  }}
                >
                  <span className="font-medium">{uiFilterTab[0]?.type}</span>{" "}
                  {uiFilterTab[0]?.condition} {uiFilterTab[0]?.text}
                </div>
                {uiFilterTab.length > 1 && (
                  <div
                    className="bg-[var(--hover-color)] text-primary text-sm font-normal text-center p-1 rounded cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFilterPopup(true);
                    }}
                  >
                    +{uiFilterTab.length - 1}
                  </div>
                )}
              </div>
            )}
            <ScrollArea
              className="flex flex-col gap-2 h-[calc(100%-120px)] overflow-auto p-2 pt-2"
              onScroll={onScroll}
            >
              {callLogsList.length > 0 && !isLoading && (
                <>
                  {callLogsList.map((logs, i) => (
                    <CallConversation
                      key={i}
                      type={listType}
                      openConversationId={openedConversation?.id}
                      callConversation={logs}
                      onConversationClicked={() => {
                        setOpenedConversation(logs);
                        setOpenedConversationIndex(i);
                      }}
                    ></CallConversation>
                  ))}
                </>
              )}
              {loaderPagination && !isLoading && (
                <div className="flex flex-col gap-2 mt-2 h-24">
                  {loaderList.map((loader, index) => (
                    <div key={index} className="flex gap-1">
                      <span className="w-20">
                        <Skeleton className="w-8 h-8 bg-gray-300 rounded-full"></Skeleton>
                      </span>
                      <span className="flex flex-col w-full">
                        <span className="w-full">
                          <Skeleton className="h-3 bg-gray-300 rounded w-full"></Skeleton>
                        </span>
                        <span className="w-full">
                          <Skeleton className="h-3 bg-gray-300 rounded w-full"></Skeleton>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {isLoading && (
                <div className="flex flex-col gap-2 mt-2">
                  {loaderList.map((loader, index) => (
                    <div key={index} className="flex gap-1">
                      <span className="w-20">
                        <Skeleton className="w-12 h-12 bg-gray-300 rounded-full"></Skeleton>
                      </span>
                      <span className="flex flex-col w-full gap-2">
                        <span className="w-full">
                          <Skeleton className="h-3 bg-gray-300 rounded w-full"></Skeleton>
                        </span>
                        <span className="w-full">
                          <Skeleton className="h-3 bg-gray-300 rounded w-full"></Skeleton>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {!isLoading && callLogsList.length === 0 && (
                <div className="flex justify-center items-center w-full h-72 text-primary text-base font-medium">
                  Try changing filters
                </div>
              )}
            </ScrollArea>
          </div>

          {callLogsList.length > 0 && (
            <div className="w-[calc(100%-18rem)]">
              {listType === "logs" && (
                <SingleCall
                  callLog={openedConversation}
                  customerUpdate={onCustomerUpdate}
                  callUpdate={updateCallLog}
                  callEnded={function (): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              )}
              {listType === "conversation" && (
                <CallLogsComponent
                  conversation={openedConversation}
                  customerUpdate={onCustomerUpdate}
                  callUpdate={updateCallLog}
                  callEnded={function (callId: string): void {
                    throw new Error("Function not implemented.");
                  }}
                ></CallLogsComponent>
              )}
            </div>
          )}
          {!isLoading && callLogsList.length === 0 && (
            <div className="flex flex-col items-center justify-center gap-4 w-[calc(100%-280px)] h-full text-primary text-sm font-medium">
              <div className="flex flex-col items-center justify-center gap-4">
                <img
                  src="../../../../assets/images/logs_empty.png"
                  alt="No call history"
                  // className="w-5 h-5"
                />
                <span>No call history</span>
              </div>
            </div>
          )}
        </div>
      )}

      {viewType === "table" && (
        <div
          className="w-full h-full"
          style={{ paddingTop: `${sharedStore.getNotificationBarSize()}px` }}
        >
          <CallsTableView
            currentInboxId={currentInboxId}
            activeCloudNumber={activeCloudNumber}
          ></CallsTableView>
        </div>
      )}

      {callLogsSource !== "agent" && (
        <div className="fixed bottom-4 left-72">
          <div className="flex gap-2 p-1 list-none bg-[var(--bg-color)] shadow-md cursor-pointer bg-hover rounded w-fit">
            <span
              onClick={() => setViewType("conversation")}
              className={`flex items-center h-9 p-1 text-sm font-medium ${
                viewType === "conversation"
                  ? "bg-bg rounded text-primary"
                  : "text-secondary"
              }`}
            >
              <PanelLeft
                size="24px"
                color={
                  viewType === "conversation"
                    ? "var(--primary-color)"
                    : "#64748B"
                }
              />
            </span>
            <span
              onClick={() => setViewType("table")}
              className={`flex items-center h-9 p-1 text-sm font-medium ${
                viewType === "table"
                  ? "bg-bg rounded text-primary"
                  : "text-secondary"
              }`}
            >
              <Sheet
                size="24px"
                color={
                  viewType === "table" ? "var(--primary-color)" : "#64748B"
                }
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallsComponent;
