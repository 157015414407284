import React, { useEffect, useState } from "react";
import Toaster from "../../shared/customToast";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import { Switch } from "../../shadcn/switch";
import { Button } from "../../shadcn/button";
import { ChevronDown, Loader2 } from "lucide-react";
import { useAgencyStore } from "../../../store/agency.store";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/accordian";
import { CloudNumber } from "../../../models/cloud-number";
import { Widget } from "../../../models/widget";
import { Checkbox } from "../../shadcn/checkbox";
import { addAgentRole, updateAgentRole } from "../../../lib/agents.utils";
import useSharedStore from "../../../store/shared.store";
import { toPascalCase } from "../../../lib/ui.utils";
import {
  PermissionDetails,
  UserPermission,
  UserRole,
} from "../../../models/user-role";

interface AddRoleProps {
  role?: UserRole;
  onClose: () => void;
  onSuccess: () => void;
  onEdit: () => void;
  permissions: UserPermission[];
}

type PermissionType = "view" | "edit" | "delete";

interface PermissionData {
  id?: number;
  name?: string;
  permissions?: Record<PermissionType, boolean>; // Using a Record instead of a Map
  subItems?: {
    id?: number;
    name?: string;
    permissions?: Record<PermissionType, boolean>;
    subItems?: {
      [index: number]: {
        number?: CloudNumber;
        widget?: Widget;
        permissions?: Record<PermissionType, boolean>;
      };
    };
  }[];
}

const AddRole: React.FC<AddRoleProps> = ({
  role,
  onClose,
  onSuccess,
  onEdit,
  permissions,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [openItem, setOpenItem] = useState<string>("");
  const [openSubItem, setOpenSubItem] = useState<string>("");

  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();

  const [permissionsData, setPermissionsData] = useState<PermissionData[]>([]);
  interface HideCheckboxesType {
    [key: string]: {
      permissions?: string[];
      subItems?: {
        [key: string]: string[];
      };
    };
  }

  const HIDE_CHECKBOXES: HideCheckboxesType = {
    ai_agents: {
      subItems: {
        Profile: ["delete"],
        Role: ["delete"],
        Performance: ["edit", "delete"],
        "Test Agents": ["edit", "delete"],
      },
    },
    Analytics: {
      permissions: ["edit", "delete"], // Hide edit & delete at root level
    },
    Client: {
      permissions: ["edit", "delete"], // Hide edit & delete at root level
    },
    Settings: {
      subItems: {
        Agency: ["delete"],
        "API Keys": ["edit", "delete"],
      },
    },
  };
  const [intermediateStateMap, setIntermediateStateMap] = useState<
    Record<string, Record<string, boolean | "indeterminate">>
  >({});

  const shouldHideCheckbox = (
    module: string,
    subItem: string | null,
    type: string
  ) => {
    // Hide checkbox at module level (e.g., Analytics)
    if (HIDE_CHECKBOXES[module]?.permissions?.includes(type)) return true;

    // Hide checkbox at sub-item level (e.g., Role under AI Agents)
    if (subItem && HIDE_CHECKBOXES[module]?.subItems?.[subItem]?.includes(type))
      return true;

    return false;
  };

  const transformPermissionsData = (
    serverData: UserPermission[],
    savedPermissions: PermissionDetails[]
  ) => {
    const structuredData: PermissionData[] = [];
    const moduleMap: Record<string, PermissionData> = {};

    // Prepare Cloud Numbers and Widget Permissions with Default False
    let cloudNumbersPermissions =
      sharedStore.cloudNumbers?.map((element: CloudNumber) => ({
        number: element,
        permissions: { view: false, edit: false, delete: false },
      })) || [];

    let widgetPermissions =
      sharedStore.widgetList?.map((element: Widget) => ({
        widget: element,
        permissions: { view: false, edit: false, delete: false },
      })) || [];

    savedPermissions.forEach((perm) => {
      if (perm.inbox) {
        cloudNumbersPermissions.forEach((num) => {
          if (num.number?.id === perm.inbox) {
            num.permissions = {
              view: perm.view || false,
              edit: perm.edit || false,
              delete: perm.delete || false,
            };
          }
        });
      }

      if (perm.widget) {
        widgetPermissions.forEach((wid) => {
          if (wid.widget?.id === perm.widget) {
            wid.permissions = {
              view: perm.view || false,
              edit: perm.edit || false,
              delete: perm.delete || false,
            };
          }
        });
      }
    });

    console.log(cloudNumbersPermissions);
    console.log(widgetPermissions);

    // Process `serverData` and apply saved permissions
    serverData?.forEach((item: any) => {
      const moduleName = item.module; // Dynamic module name
      const subItemName = item.name; // Dynamic sub-item name

      if (!moduleMap[moduleName]) {
        moduleMap[moduleName] = {
          name: moduleName.charAt(0).toUpperCase() + moduleName.slice(1),
          permissions: { view: false, edit: false, delete: false },
        };
      }

      if (!moduleMap[moduleName].subItems) {
        moduleMap[moduleName].subItems = [];
      }

      let subItemData: any = {
        id: item.id,
        name: subItemName.charAt(0).toUpperCase() + subItemName.slice(1),
        permissions: { view: false, edit: false, delete: false },
      };

      // Check if permissions exist in savedPermissions
      const savedPerm = savedPermissions.find(
        (perm) => perm.permission.id === item.id
      );
      if (savedPerm) {
        subItemData.permissions = {
          view: savedPerm.view || false,
          edit: savedPerm.edit || false,
          delete: savedPerm.delete || false,
        };
      }

      // Attach Cloud Numbers & Widgets
      if (subItemName === "phone_numbers") {
        subItemData.subItems = cloudNumbersPermissions;
      }
      if (subItemName === "widgets") {
        subItemData.subItems = widgetPermissions;
      }

      moduleMap![moduleName]!.subItems?.push(subItemData);

      // Update module-level permissions based on sub-items
      if (moduleMap![moduleName]!.permissions) {
        moduleMap[moduleName]!.permissions!.view =
          moduleMap[moduleName]!.permissions!.view ||
          subItemData!.permissions!.view;
        moduleMap[moduleName]!.permissions!.edit =
          moduleMap[moduleName]!.permissions!.edit ||
          subItemData!.permissions!.edit;
        moduleMap[moduleName]!.permissions!.delete =
          moduleMap[moduleName]!.permissions!.delete ||
          subItemData!.permissions!.delete;
      }
    });

    console.log(moduleMap);

    // Convert moduleMap into an array
    Object.values(moduleMap).forEach((module: PermissionData) => {
      if (module.subItems?.length === 1) {
        const singlePermission = module.subItems[0];
        delete module.subItems;
        module.id = singlePermission.id;
      }
      structuredData.push(module);
    });

    return structuredData;
  };

  useEffect(() => {
    let transformedData = transformPermissionsData(
      permissions,
      role?.id ? role?.permissions : []
    );
    if (role?.id) {
      setName(role?.name);
      setPermissionsData(transformedData);
    } else setPermissionsData(transformedData);
  }, [permissions, role]);

  type PermissionType = "view" | "edit" | "delete";

  const handleCheckboxChange = (
    level: string,
    index: number,
    subIndex: number | null,
    nestedIndex: number | null,
    type: PermissionType
  ) => {
    setPermissionsData((prevData) => {
      const newData = [...prevData];

      const updatePermissions = (item: any, value: boolean) => {
        if (item && item.permissions) {
          item.permissions[type] = value;
          if (item.subItems && item.subItems.length > 0) {
            item.subItems.forEach((sub: any) => updatePermissions(sub, value));
          }
        }
      };

      if (level === "main") {
        if (newData[index]) {
          updatePermissions(
            newData[index],
            !newData[index]?.permissions?.[type]
          );
        }
      } else if (level === "sub" && subIndex !== null) {
        const subItems = newData[index]?.subItems;
        if (subItems && subItems[subIndex]) {
          updatePermissions(
            subItems[subIndex],
            !subItems[subIndex]?.permissions?.[type]
          );

          // Sync parent checkbox
          const allSelected = (subItems as any[]).every(
            (sub) => sub.permissions?.[type]
          );
          if (newData[index]?.permissions) {
            newData[index]!.permissions![type] = allSelected;
          }
        }
      } else if (
        level === "nested" &&
        subIndex !== null &&
        nestedIndex !== null
      ) {
        const subItems = newData[index]?.subItems;
        if (
          subItems &&
          subItems[subIndex] &&
          subItems[subIndex]?.subItems &&
          subItems[subIndex]?.subItems![nestedIndex]
        ) {
          updatePermissions(
            subItems[subIndex]?.subItems![nestedIndex],
            !subItems[subIndex]?.subItems![nestedIndex]?.permissions?.[type]
          );

          // Sync sub-parent checkbox
          const allNestedSelected = (
            subItems[subIndex]?.subItems as any[]
          ).every((sub) => sub?.permissions?.[type]);
          if (subItems[subIndex] && subItems[subIndex]?.permissions) {
            subItems[subIndex]!.permissions![type] = allNestedSelected;
          }

          // Sync parent checkbox
          const allSubSelected = (subItems as any[]).every(
            (sub) => sub?.permissions?.[type]
          );
          if (newData[index] && newData[index]?.permissions) {
            newData[index]!.permissions![type] = allSubSelected;
          }
        }
      }

      return newData;
    });
  };

  const addRole = () => {
    setIsLoading(true);

    let input: Record<string, any> = {
      name: name,
    };

    let permissionList: any[] = [];

    // Transform `permissionsData` into API payload format
    permissionsData.forEach((module: any) => {
      if (module.subItems) {
        module.subItems.forEach((subItem: any) => {
          // Handle items with additional lists (Numbers & Widgets)
          if (subItem.subItems) {
            subItem.subItems.forEach((nestedItem: any) => {
              permissionList.push({
                view: nestedItem.permissions.view,
                add: nestedItem.permissions.edit, // Use edit value for add
                edit: nestedItem.permissions.edit,
                delete: nestedItem.permissions.delete,
                permission: subItem.id, // Parent permission ID
                inbox: nestedItem.number?.id ?? null, // If it's a CloudNumber, set id
                widget: nestedItem.widget?.id ?? null, // If it's a Widget, set id
              });
            });
          } else {
            permissionList.push({
              view: subItem.permissions.view,
              add: subItem.permissions.edit, // Use edit value for add
              edit: subItem.permissions.edit,
              delete: subItem.permissions.delete,
              permission: subItem.id, // Use ID from transformed data
              inbox: null, // Default null
              widget: null, // Default null
            });
          }
        });
      } else {
        // If the module does not have `subItems`, add directly
        permissionList.push({
          view: module.permissions.view,
          add: module.permissions.edit, // Use edit value for add
          edit: module.permissions.edit,
          delete: module.permissions.delete,
          permission: module.id, // Use ID from transformed data
          inbox: null, // Default null
          widget: null, // Default null
        });
      }
    });

    let payload = {
      role: input,
      permissions: permissionList,
    };

    const request = role?.id
      ? updateAgentRole(payload, role?.id)
      : addAgentRole(payload);

    request
      .then((resp) => {
        setIsLoading(false);
        Toaster.success(resp.data.message);
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        Toaster.error(error);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <DialogContent className="max-w-[576px] max-h-[653px] overflow-auto overflow-x-hidden">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center text-primary font-inter text-lg font-semibold">
            <span>{role?.id ? "Edit role" : "Add role"}</span>
          </div>
          <span className="text-gray-500 font-inter text-[14px] font-normal">
            Invite others to collaborate in {agencyStore.brand}. Each invite is
            specific to an email address and expires in 3 days.
          </span>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-1">
            <span className="text-primary font-inter text-base font-medium">
              Name
            </span>
            <Input
              placeholder="e.g. Manager"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-1">
            <span className="text-primary font-inter text-base font-medium">
              Workspace permissions
            </span>
            <span className="text-gray-500 font-inter text-[13px] font-normal">
              Define access levels and manage permissions for team collaboration
              in {sharedStore.business?.name}.
            </span>
            <div className="border border-border-bd-1 rounded-lg">
              <div className="flex bg-gray-50 rounded-t-lg font-inter text-sm font-medium leading-[19.5px] text-secondary h-[38px] items-center border-b border-[var(--border-color)]">
                <span className="w-[336px] pl-2.5">Permissions</span>
                <span className="w-[64px] flex items-center justify-center">
                  View
                </span>
                <span className="w-[64px] flex items-center justify-center">
                  Add
                </span>
                <span className="w-[64px] flex items-center justify-center">
                  Delete
                </span>
              </div>
              <div className="">
                <Accordion
                  type="single"
                  collapsible
                  className="p-0"
                  value={openItem}
                  onValueChange={(value) =>
                    setOpenItem(value === openItem ? "" : value)
                  }
                >
                  {permissionsData.map((item, index) => {
                    const isOpen = openItem === `item${index}`;

                    return (
                      <AccordionItem key={index} value={`item${index}`}>
                        <AccordionTrigger className="flex items-center p-0 text-sm font-normal gap-0 justify-start hover:no-underline">
                          <span className="w-[336px] pl-2.5 flex items-center gap-2">
                            {toPascalCase(item?.name?.replaceAll("_", " "))}
                            {item?.subItems && item?.subItems?.length > 0 && (
                              <ChevronDown
                                className={`w-4 h-4 transition-transform duration-300 ${
                                  isOpen ? "rotate-180" : "rotate-0"
                                }`}
                              />
                            )}
                          </span>
                          <div className="flex" onClick={(e) => e.stopPropagation()}>
                            <span className="w-[64px] flex items-center justify-center py-2.5">
                                {!shouldHideCheckbox(
                                item?.name ?? "",
                                null,
                                "view"
                                ) && (
                                <Checkbox
                                  onCheckedChange={() =>
                                  handleCheckboxChange(
                                    "main",
                                    index,
                                    null,
                                    null,
                                    "view"
                                  )
                                  }
                                  checked={
                                  item.subItems
                                    ? item.subItems.every(
                                      (subItem) =>
                                      subItem.permissions?.view
                                    )
                                    ? true
                                    : item.subItems.some(
                                      (subItem) =>
                                        subItem.permissions?.view
                                      )
                                    ? "indeterminate"
                                    : false
                                    : item.permissions?.view
                                  }
                                />
                                )}
                            </span>
                            <span className="w-[64px] flex items-center justify-center py-2.5">
                                {!shouldHideCheckbox(
                                item?.name ?? "",
                                null,
                                "edit"
                                ) && (
                                <Checkbox
                                  onCheckedChange={() =>
                                  handleCheckboxChange(
                                    "main",
                                    index,
                                    null,
                                    null,
                                    "edit"
                                  )
                                  }
                                  disabled={
                                  item.subItems
                                    ? item.subItems.every(
                                      (subItem) =>
                                      subItem.permissions?.view === false
                                    )
                                    : item.permissions?.view === false
                                  }
                                  checked={
                                  item.subItems
                                    ? item.subItems.every(
                                      (subItem) =>
                                      subItem.permissions?.edit
                                    )
                                    ? true
                                    : item.subItems.some(
                                      (subItem) =>
                                        subItem.permissions?.edit
                                      )
                                    ? "indeterminate"
                                    : false
                                    : item.permissions?.edit
                                  }
                                />
                                )}
                            </span>
                            <span className="w-[64px] flex items-center justify-center py-2.5">
                                {!shouldHideCheckbox(
                                item?.name ?? "",
                                null,
                                "delete"
                                ) && (
                                <Checkbox
                                  onCheckedChange={() =>
                                  handleCheckboxChange(
                                    "main",
                                    index,
                                    null,
                                    null,
                                    "delete"
                                  )
                                  }
                                  disabled={
                                    item.subItems
                                      ? item.subItems.every(
                                        (subItem) =>
                                        subItem.permissions?.view === false
                                      )
                                      : item.permissions?.view === false
                                    }
                                  checked={
                                  item.subItems
                                    ? item.subItems.every(
                                      (subItem) =>
                                      subItem.permissions?.delete
                                    )
                                    ? true
                                    : item.subItems.some(
                                      (subItem) =>
                                        subItem.permissions?.delete
                                      )
                                    ? "indeterminate"
                                    : false
                                    : item.permissions?.delete
                                  }
                                />
                                )}
                            </span>
                          </div>
                        </AccordionTrigger>

                        {item?.subItems && (
                          <AccordionContent className="p-0 border-0">
                            <div className="w-full border-t border-[var(--border-color)]">
                              {item?.subItems?.map(
                                (subItem: any, subIndex: number) => (
                                  <Accordion
                                    type="single"
                                    key={subIndex}
                                    collapsible
                                    value={openSubItem}
                                    onValueChange={(value) =>
                                      setOpenSubItem(
                                        value === openSubItem ? "" : value
                                      )
                                    } // Separate state for nested accordion
                                    className="w-full last:border-0"
                                  >
                                    <AccordionItem
                                      value={`subItem${index}-${subIndex}`}
                                      className="p-0"
                                    >
                                      <AccordionTrigger className="flex items-center p-0 pl-6 text-sm font-normal gap-0 justify-start hover:no-underline">
                                        <span className="w-[312px] flex items-center gap-2">
                                          {toPascalCase(
                                            subItem?.name?.replaceAll("_", " ")
                                          )}
                                          {subItem?.subItems &&
                                            subItem?.subItems?.length > 0 && (
                                              <ChevronDown
                                                className={`w-4 h-4 transition-transform duration-300 ${
                                                  openSubItem ===
                                                  `subItem${index}-${subIndex}`
                                                    ? "rotate-180"
                                                    : "rotate-0"
                                                }`}
                                              />
                                            )}
                                        </span>
                                        <div className="flex" onClick={(e) => e.stopPropagation()}>
                                          <span className="w-[64px] flex items-center justify-center py-2.5">
                                            <Checkbox
                                              checked={
                                                subItem.subItems?.every(
                                                  (nestedItem: any) =>
                                                    nestedItem.permissions?.view
                                                )
                                                  ? true
                                                  : subItem.subItems?.some(
                                                      (nestedItem: any) =>
                                                        nestedItem.permissions
                                                          ?.view
                                                    )
                                                  ? "indeterminate"
                                                  : subItem.permissions?.view
                                              }
                                              onCheckedChange={() =>
                                                handleCheckboxChange(
                                                  "sub",
                                                  index,
                                                  subIndex,
                                                  null,
                                                  "view"
                                                )
                                              }
                                            />
                                          </span>
                                          <span className="w-[64px] flex items-center justify-center py-2.5">
                                            {!shouldHideCheckbox(
                                              item?.name ?? "",
                                              subItem.name,
                                              "edit"
                                            ) && (
                                              <Checkbox
                                                checked={
                                                  subItem.subItems?.every(
                                                    (nestedItem: any) =>
                                                      nestedItem.permissions
                                                        ?.edit
                                                  )
                                                    ? true
                                                    : subItem.subItems?.some(
                                                        (nestedItem: any) =>
                                                          nestedItem.permissions
                                                            ?.edit
                                                      )
                                                    ? "indeterminate"
                                                    : subItem.permissions?.edit
                                                }
                                                disabled={
                                                  !subItem.permissions?.view ||
                                                  subItem.subItems?.every(
                                                    (nestedItem: any) =>
                                                      nestedItem.permissions
                                                        ?.view === false
                                                  )
                                                }
                                                onCheckedChange={() =>
                                                  handleCheckboxChange(
                                                    "sub",
                                                    index,
                                                    subIndex,
                                                    null,
                                                    "edit"
                                                  )
                                                }
                                              />
                                            )}
                                          </span>
                                          <span className="w-[64px] flex items-center justify-center py-2.5">
                                            {!shouldHideCheckbox(
                                              item?.name ?? "",
                                              subItem.name,
                                              "delete"
                                            ) && (
                                              <Checkbox
                                                checked={
                                                  subItem.subItems?.every(
                                                    (nestedItem: any) =>
                                                      nestedItem.permissions
                                                        ?.delete
                                                  )
                                                    ? true
                                                    : subItem.subItems?.some(
                                                        (nestedItem: any) =>
                                                          nestedItem.permissions
                                                            ?.delete
                                                      )
                                                    ? "indeterminate"
                                                    : subItem.permissions
                                                        ?.delete
                                                }
                                                disabled={
                                                  !subItem.permissions?.view ||
                                                  subItem.subItems?.every(
                                                    (nestedItem: any) =>
                                                      nestedItem.permissions
                                                        ?.view === false
                                                  )
                                                }
                                                onCheckedChange={() =>
                                                  handleCheckboxChange(
                                                    "sub",
                                                    index,
                                                    subIndex,
                                                    null,
                                                    "delete"
                                                  )
                                                }
                                              />
                                            )}
                                          </span>
                                        </div>
                                      </AccordionTrigger>

                                      {/* Nested Accordion for Phone Numbers and Widgets */}
                                      {subItem?.subItems &&
                                        subItem.subItems.length > 0 && (
                                          <AccordionContent className="p-0 border-t border-[var(--border-color)]">
                                            {subItem?.subItems?.map(
                                              (
                                                nestedItem: any,
                                                nestedIndex: number
                                              ) => (
                                                <div
                                                  key={nestedIndex}
                                                  className="flex justify-between items-center border-b border-[var(--border-color)] last:border-0"
                                                >
                                                  <span className="w-[312px] pl-10">
                                                    {"number" in nestedItem ? (
                                                      <span className="flex gap-2 items-center justify-start truncate">
                                                        {
                                                          nestedItem?.number
                                                            ?.icon
                                                        }
                                                        <span className="font-medium">
                                                          {
                                                            nestedItem?.number
                                                              ?.name
                                                          }
                                                        </span>{" "}
                                                        -{" "}
                                                        <span className="text-secondary">
                                                          {
                                                            nestedItem?.number
                                                              ?.number
                                                          }
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      <span className="flex gap-2 items-center truncate">
                                                        <img
                                                          src={
                                                            nestedItem?.widget
                                                              ?.logo
                                                          }
                                                          className="w-[20px] h-[20px] rounded-full"
                                                          alt=""
                                                        />
                                                        {
                                                          nestedItem?.widget
                                                            ?.name
                                                        }
                                                      </span>
                                                    )}
                                                  </span>
                                                  <div className="flex">
                                                    <span className="w-[64px] flex items-center justify-center py-2.5">
                                                      <Checkbox
                                                        checked={
                                                          nestedItem.permissions
                                                            ?.view
                                                        }
                                                        onCheckedChange={() =>
                                                          handleCheckboxChange(
                                                            "nested",
                                                            index,
                                                            subIndex,
                                                            nestedIndex,
                                                            "view"
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                    <span className="w-[64px] flex items-center justify-center py-2.5">
                                                      <Checkbox
                                                        checked={
                                                          nestedItem.permissions
                                                            ?.edit
                                                        }
                                                        disabled={
                                                          !nestedItem
                                                            .permissions?.view
                                                        }
                                                        onCheckedChange={() =>
                                                          handleCheckboxChange(
                                                            "nested",
                                                            index,
                                                            subIndex,
                                                            nestedIndex,
                                                            "edit"
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                    <span className="w-[64px] flex items-center justify-center py-2.5">
                                                      <Checkbox
                                                        checked={
                                                          nestedItem.permissions
                                                            ?.delete
                                                        }
                                                        disabled={
                                                          !nestedItem
                                                            .permissions?.view
                                                        }
                                                        onCheckedChange={() =>
                                                          handleCheckboxChange(
                                                            "nested",
                                                            index,
                                                            subIndex,
                                                            nestedIndex,
                                                            "delete"
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </AccordionContent>
                                        )}
                                    </AccordionItem>
                                  </Accordion>
                                )
                              )}
                            </div>
                          </AccordionContent>
                        )}
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end p-2 pr-0">
          <Button variant="default" disabled={isLoading} onClick={addRole}>
            {isLoading ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                {role?.id ? "Saving..." : "Loading..."}
              </>
            ) : role?.id ? (
              "Save"
            ) : (
              "Create role"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddRole;
