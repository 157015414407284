import {
  deleteCustomTools,
  getCustomTools,
  searchCustomTools,
  updateCustomTools,
} from "../../../lib/custom-tools.utils";
import { CustomTool } from "../../../models/custom-tool";
import { LoggedUser } from "../../../models/logged-user";
import useSharedStore from "../../../store/shared.store";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Clock, EllipsisVertical, Plus } from "lucide-react";
import React, { useState, useEffect } from "react";
import { Button } from "../../shadcn/button";
import { Input } from "../../shadcn/input";
import { Skeleton } from "../../shadcn/skeleton";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import { Switch } from "../../shadcn/switch";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown-menu";
import AddCustomTool from "./AddCustomTool";
import WebhookLogs from "../../shared/WebhookLogs";
import Toaster from "../../shared/customToast";
import { formatDateToMonthDayYear, formatTime } from "../../../lib/ui.utils";

const CustomTools: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customToolList, setCustomToolList] = useState<CustomTool[]>([]);
  const [paginationSize, setPaginationSize] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState(false);
  const [toolSearchTag, setToolSearchTag] = useState("");
  const [prevToolSearchTag, setPrevToolSearchTag] = useState("");

  const [viewedTool, setViewedTool] = useState<CustomTool | null>(null);
  const [isAddTool, setIsAddTool] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteHeading, setDeleteHeading] = useState("");
  const [deleteDescription, setDeleteDescription] = useState("");
  const [negativeButton, setNegativeButton] = useState("");
  const [positiveButton, setPositiveButton] = useState("");
  const [showLogs, setShowLogs] = useState(false);

  const sharedStore = useSharedStore();
  const loggedInUser = new LoggedUser(
    JSON.parse(localStorage.getItem("user")!)
  );

  useEffect(() => {
    sharedStore.inAuthModule = false;
    getCustomToolsList(paginationPage);
  }, []);

  const getCustomToolsList = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevToolSearchTag(toolSearchTag);

    getCustomTools(pageNumber).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            setPrevToolSearchTag(toolSearchTag);
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > resp.data.size);
            const updatedToolList = new Array<CustomTool>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedToolList[i] = new CustomTool(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedToolList[i] = new CustomTool(
                  resp.data.data[i % paginationSize]
                );
              }
            }

            setCustomToolList(updatedToolList);
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
          console.error(resp.data.message);
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolSearchTag(e.target.value);
    setTimeout(() => {
      if (
        prevToolSearchTag.trim() !== toolSearchTag.trim() &&
        toolSearchTag.length > 2
      ) {
        setPaginatedPages(new Set());
        searchCustomToolsList(1);
        setPaginationPage(1);
      } else if (
        prevToolSearchTag.trim() !== toolSearchTag.trim() &&
        prevToolSearchTag.length > 2
      ) {
        setPaginatedPages(new Set());
        setPaginationPage(1);
        getCustomToolsList(1);
      }
    }, 300);
  };

  const searchCustomToolsList = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevToolSearchTag(toolSearchTag);

    searchCustomTools(toolSearchTag, pageNumber).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            if (pageNumber === 1) {
              setPagination(resp.data.count > paginationSize);
              const responseList = new Array<CustomTool>(resp.data.count);
              for (let i = 0; i < resp.data.data.length; i++) {
                responseList[i] = new CustomTool(resp.data.data[i]);
              }
              setCustomToolList(responseList);
            } else {
              const updatedToolList = [...customToolList];
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedToolList[i] = new CustomTool(
                  resp.data.data[i % paginationSize]
                );
              }
              setCustomToolList(updatedToolList);
            }
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
          console.error(resp.data.message);
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const handleAddTool = () => {
    setIsAddTool(true);
    setViewedTool(new CustomTool(null));
  };

  const handleEditTool = (tool: CustomTool) => {
    setViewedTool(tool);
    setIsAddTool(true);
  };

  const handleDeleteTool = (tool: CustomTool) => {
    setViewedTool(tool);
    setPositiveButton("Yes, delete action");
    setDeleteHeading("Confirm delete action");
    setDeleteDescription(
      `Deleting the custom action <span class="col-pri">${tool.name}</span> will remove it from all AI agents actions.`
    );
    setNegativeButton("No, keep action");
    setConfirmationPopup(true);
  };

  const confirmDeleteTool = () => {
    setConfirmationPopup(false);
    deleteCustomTools(viewedTool?.id ?? 0).then(
      (resp: any) => {
        console.log(resp.data.message);
        setCustomToolList(
          customToolList.filter((tool) => tool.id !== viewedTool?.id)
        );
        setViewedTool(new CustomTool(null));
      },
      (error: any) => {
        Toaster.error(error);
        console.error(error);
      }
    );
  };

  const handleEnableDisableTool = (index: number) => {
    const updatedToolList = [...customToolList];
    updatedToolList[index].is_enabled = !updatedToolList[index].is_enabled;
    setCustomToolList(updatedToolList);
    updateCustomTools(
      {
        is_enabled: updatedToolList[index]?.is_enabled,
      },
      updatedToolList[index].id
    ).then(
      (resp: any) => {
        console.log(resp.data.message);
      },
      (error: any) => {
        Toaster.error(error);
        console.error(error);
      }
    );
  };

  return (
    <ScrollArea className="container mx-auto">
      <div className="bg-white p-6">
        {/* Header Section */}
        <div className="flex justify-between align-center">
          <div className="flex gap-x-2 mb-4 flex-col">
            <h1 className="text-xl font-bold text-gray-800">Custom actions</h1>
            <div className="text-sm text-gray-600">
              Manage all custom actions in your workspace
            </div>
          </div>
          {(sharedStore.loggedUser?.user_type === 1 ||
            sharedStore.permissions?.settings?.custom_actions?.edit) && (
            <Button onClick={handleAddTool}>
              <Plus /> New action
            </Button>
          )}
        </div>

        {/* Search Input */}
        {!(
          toolSearchTag === "" &&
          !isLoading &&
          customToolList.length === 0
        ) && (
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Search Custom Actions"
              value={toolSearchTag}
              onChange={handleSearchInput}
              className="w-full"
            />
          </div>
        )}

        {/* Table Section */}
        {isLoading && (
          <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="w-full gap-4 flex flex-col">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton key={index} className="h-8 w-full" />
                ))}
              </div>
            ))}
          </div>
        )}
        {!isLoading && customToolList.length > 0 && (
          <div className="overflow-x-auto border border-gray-300 rounded-lg">
            <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
              <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10 shadow-sm">
                <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                  <th
                    style={{ borderRadius: "8px 0 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  >
                    Name
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Updated
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Updated by
                  </th>
                  <th
                    style={{ borderRadius: "0 8px 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {customToolList.length > 0 &&
                  customToolList.map((tool, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                    >
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {tool?.name}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {formatDateToMonthDayYear(
                          tool?.updated_at ?? tool?.created_at
                        )}
                        , {formatTime(tool?.updated_at ?? tool?.created_at)}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        {tool?.added_by?.name ?? loggedInUser?.name ?? ""}
                      </td>
                      <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                        <div className="flex justify-end gap-2">
                          <Switch
                            checked={tool.is_enabled}
                            onCheckedChange={() =>
                              handleEnableDisableTool(index)
                            }
                          />
                          <Clock
                            onClick={() => {
                              setViewedTool(tool);
                              setShowLogs(true);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          {
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <button className="p-0 border-none bg-transparent">
                                  <EllipsisVertical className="w-5 h-5 text-gray-500" />
                                </button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="bg-white border border-gray-300 w-40 rounded-lg p-2.5">
                                {(sharedStore.loggedUser?.user_type === 1 ||
                                  sharedStore.permissions?.settings
                                    ?.custom_actions?.edit) && (
                                  <DropdownMenuItem
                                    className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                    onSelect={() => handleEditTool(tool)}
                                  >
                                    Edit
                                  </DropdownMenuItem>
                                )}
                                {(sharedStore.loggedUser?.user_type === 1 ||
                                  sharedStore.permissions?.settings
                                    ?.custom_actions?.delete) && (
                                  <DropdownMenuItem
                                    className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                                    onSelect={() => handleDeleteTool(tool)}
                                  >
                                    Delete
                                  </DropdownMenuItem>
                                )}
                              </DropdownMenuContent>
                            </DropdownMenu>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {toolSearchTag === "" && !isLoading && customToolList.length === 0 && (
          <div className="w-full h-[600px] flex flex-col items-center justify-center gap-4 font-inter text-base font-medium leading-6 text-left text-[var(--primary-color)]">
            <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
              <img
                src="../../../../assets/images/tools_empty.png"
                alt="Empty tools"
              />
              <span>
                AI Agents can use custom actions during calls to enhance
                interactions.
              </span>
            </div>
          </div>
        )}
        {toolSearchTag !== "" && !isLoading && customToolList.length === 0 && (
          <div className="w-full h-[600px] flex items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-4 font-inter text-sm font-medium leading-5">
              <img
                src="../../../../assets/images/tools_empty.png"
                alt="No tools Found"
              />
              <span>No actions were found matching your search</span>
            </div>
          </div>
        )}
      </div>
      {isAddTool && (
        <AddCustomTool
          tool={viewedTool?.id ? viewedTool : undefined}
          onClose={() => setIsAddTool(false)}
          onSuccess={(tool: CustomTool) => {
            setCustomToolList([tool, ...customToolList]);
            setIsAddTool(false);
          }}
          onEdit={(tool: CustomTool) => {
            setCustomToolList(
              customToolList.map((t) => (t.id === tool.id ? tool : t))
            );
            setIsAddTool(false);
          }}
        />
      )}
      {confirmationPopup && (
        <ConfirmationPopup
          positiveButtonText={positiveButton}
          negativeButtonText={negativeButton}
          heading={deleteHeading}
          description={deleteDescription}
          onClose={() => setConfirmationPopup(false)}
          onSuccess={confirmDeleteTool}
        />
      )}
      {showLogs && (
        <WebhookLogs
          params={{ type: "action", id: viewedTool?.id }}
          onClose={() => {
            setShowLogs(false);
            setViewedTool(new CustomTool(null));
          }}
        />
      )}
    </ScrollArea>
  );
};

export default CustomTools;
