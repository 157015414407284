import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  useEditor,
  EditorContent,
  ReactRenderer,
  mergeAttributes,
} from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Mention from "@tiptap/extension-mention";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "./CommandsInput.css";
import { ScrollArea } from "../shadcn/scrollArea";
import { v4 as uuidv4 } from "uuid";
import Placeholder from "@tiptap/extension-placeholder";

var variablesList: any = [];
var selectedVariable: any = null;

const MentionList = forwardRef(
  ({ items, command }: { items: any[]; command: (item: any) => void }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = items[index];
      let nodePayload = {
        id: uuidv4(),
        label: item?.label,
        field: item?.field,
        type: item?.type,
      };
      selectedVariable = nodePayload;
      if (item) command(nodePayload);
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }
        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }
        if (event.key === "Enter") {
          enterHandler();
          return true;
        }
        return false;
      },
    }));

    return (
      <div className="position-absolute z-[9999] flex-flex-col gap-2 bg-white border border-gray-300 w-200 max-h-150 overflow-y-auto p-2 rounded max-h-[300px] pointer-events-auto">
        {items.length ? (
          items.map((item, index) => (
            <button
              className={`commands-input-dropdown ${
                index === selectedIndex ? "bg-[var(--hover-color)]" : ""
              }`}
              key={index}
              onClick={() => selectItem(index)}
            >
              {item.label} {/* Display the label instead of just item */}
            </button>
          ))
        ) : (
          <div className="item">No matches found</div>
        )}
      </div>
    );
  }
);

const mentionSuggestion = {
  char: "/",
  items: ({ query }: { query: string }) => {
    return variablesList.filter((item: any) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );
  },

  render: () => {
    let component: any = null;
    let popup: any = null;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) return;

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          allowHTML: true,
          trigger: "manual",
          placement: "bottom-start",
          zIndex: 9999,
          onTrigger(instance, event) {
            event.stopPropagation(); // Prevent parent scroll from blocking dropdown scroll
          },
          onMount(instance) {
            // Enable proper scrolling inside the mention list
            instance.popper.addEventListener("wheel", (event) => {
              event.stopPropagation(); // Prevents tippy from blocking scroll
            });
          },
        });
      },
      onUpdate(props: any) {
        component?.updateProps(props);
        if (!props.clientRect) return;
        popup[0].setProps({ getReferenceClientRect: props.clientRect });
      },
      onKeyDown(props: any) {
        if (props.event.key === "Escape") {
          popup[0].hide();
          return true;
        }
        return component?.ref?.onKeyDown(props);
      },
      onExit() {
        popup[0].destroy();
        component?.destroy();
      },
    };
  },
};

interface EditorProps {
  variables?: any[]; // Equivalent to @Input() variables
  inputText?: string; // Equivalent to @Input() inputText
  placeholder?: string; // Equivalent to @Input() placeholder
  onChange?: (value: string) => void; // Equivalent to @Output() change
  height?: string; // Equivalent to @Input() height
  count?: boolean; // Equivalent to @Input() count
  showUrl?: boolean; // Equivalent to @Input() showUrl
}

const CommandsEditor: React.FC<EditorProps> = ({
  variables = [],
  inputText = "",
  placeholder = "Write something or ‘/’ to insert variables...",
  onChange,
  height = "200px",

  count = false,
  showUrl = true,
}) => {
  const [content, setContent] = useState("");
  const placeholderRefWrapper = useRef(placeholder);
  const staticPlaceholderRefWrapper = useRef(() => {
    return placeholderRefWrapper.current;
  });
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        renderHTML({ options, node }) {
          return [
            "span",
            mergeAttributes(options.HTMLAttributes, {
              label: node.attrs.label,
              variable:
                selectedVariable?.id === node.attrs.id
                  ? selectedVariable?.field
                  : node.attrs.field, // Custom field attribute
              variableType:
                selectedVariable?.id === node.attrs.id
                  ? selectedVariable?.type
                  : node.attrs.type, // Custom type attribute
            }),

            `${node.attrs.label ?? node.attrs.id}`,
          ];
        },
        suggestion: mentionSuggestion,
      }),
      Placeholder.configure({
        placeholder: staticPlaceholderRefWrapper.current,
      }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      const text = editor.getHTML();
      setContent(text);
      console.log(text);
      if (onChange) onChange(text); // Emit change event
    },
  });

  useEffect(() => {
    console.log(placeholder);
    placeholderRefWrapper.current = placeholder;
    console.log(placeholderRefWrapper.current);
    if (!editor) return;
    if (editor.getHTML().trim() !== inputText.trim()) {
      let afterSplit = inputText.split("\n");
      let afterMap: string[] = afterSplit;
      if (afterMap?.length > 1)
        afterMap = afterSplit.map((line) => {
          console.log(line);
          if (line.trim() === "") return `<p><br></p>`;
          else return `<p>${line}</p>`;
        });
      let formattedContent = afterMap.join("");

      editor.commands.setContent(formattedContent);
    }
  }, [content, editor, inputText, placeholder]);

  useEffect(() => {
    variablesList = variables;
  }, [variables]);

  if (!editor) return null;

  return (
    <div className="commands-editor" style={{ height: height }}>
      <EditorContent
        className={`h-[${height}]`}
        style={{ height: height }}
        editor={editor}
      />
    </div>
  );
};

export default CommandsEditor;
