import {
  Bot,
  CircleAlert,
  Frown,
  Meh,
  Phone,
  PhoneIncoming,
  PhoneMissed,
  PhoneOutgoing,
  Smile,
} from "lucide-react";
import React, { useEffect } from "react";
import ProfileIcon from "../shared/ProfileIcon";
import {
  formatDateToMonthDay,
  formatDuration,
  formatName,
  formatTime,
  isRecent,
} from "../../lib/ui.utils";

interface CallConversationProps {
  callConversation: any;
  openConversationId: number;
  type: string;
  onConversationClicked: (conversation: any) => void;
}

const CallConversation: React.FC<CallConversationProps> = ({
  callConversation,
  openConversationId,
  type,
  onConversationClicked,
}) => {
  useEffect(() => {
    // ComponentDidMount logic here
  }, []);

  const openConversation = (event: React.MouseEvent) => {
    onConversationClicked(callConversation);
  };

  return (
    <div
      className="w-full bg-[var(--bg-color)] flex p-2 items-center gap-2 rounded-lg cursor-pointer hover:bg-[var(--hover-color)] mb-1"
      onClick={openConversation}
      style={{
        backgroundColor:
          callConversation?.id === openConversationId
            ? "var(--hover-color)"
            : "",
      }}
    >
      {type === "logs" && (
        <>
          <div className="flex items-center justify-center h-12 w-12 bg-[var(--hover-color)] rounded-full">
            <span className="flex">
              {callConversation?.direction === "inbound" && <PhoneIncoming />}
              {callConversation?.direction === "outbound" && <PhoneOutgoing />}
            </span>
          </div>
          <div className="flex flex-col gap-1 w-[calc(100%-56px)]">
            <div className="flex items-center justify-between">
              <div className="flex items-center h-5 gap-2">
                <span className="font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] overflow-hidden truncate max-w-[148px]">
                  {callConversation?.customer ? formatName(callConversation?.customer) : (callConversation?.widget && "Widget call")}
                  {!callConversation?.customer &&
                    !callConversation?.widget &&
                    "Test web call"}
                </span>
              </div>
              <span className="font-inter text-xs font-normal leading-4 text-left text-[var(--secondary-color)] min-w-[50px] flex justify-end whitespace-nowrap">
                {callConversation?.created_at
                  ? formatDateToMonthDay(new Date(callConversation?.created_at))
                  : formatDateToMonthDay(
                      new Date(callConversation?.created_at)
                    )}
              </span>
            </div>
            {callConversation?.call_status !== "no-answer" &&
              callConversation?.call_status !== "busy" &&
              callConversation?.call_status !== "canceled" &&
              callConversation?.call_status !== "ongoing" &&
              callConversation?.call_status !== "ringing" &&
              callConversation?.call_status !== "registered" && (
                <p className="m-0 flex w-full justify-between">
                  {
                    <span className="flex gap-1 items-center h-full overflow-hidden">
                      <span className="flex">
                        {callConversation?.direction === "inbound" && (
                          <PhoneIncoming color="#64748B" size="20px" />
                        )}
                        {callConversation?.direction === "outbound" && (
                          <PhoneOutgoing color="#64748B" size="20px" />
                        )}
                      </span>
                      {callConversation?.call_analysis?.user_sentiment?.toLowerCase() ===
                        "neutral" && <Meh size="16px" color="#2563EB" />}
                      {callConversation?.call_analysis?.user_sentiment?.toLowerCase() ===
                        "positive" && <Smile size="16px" color="#16A34A" />}
                      {callConversation?.call_analysis?.user_sentiment?.toLowerCase() ===
                        "negative" && <Frown size="16px" color="#DC2626" />}
                      {callConversation?.end_timestamp &&
                        callConversation?.start_timestamp && (
                          <span className="font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                            {formatDuration(
                              callConversation?.end_timestamp / 1000 -
                                callConversation?.start_timestamp / 1000
                            )}
                          </span>
                        )}
                    </span>
                  }
                  {callConversation?.agent && (
                    <span className="w-5 h-5 flex items-center justify-center bg-[var(--hover-color)] rounded-full">
                      {callConversation?.agent?.profile_pic ? (
                        <img
                          src={callConversation?.agent?.profile_pic}
                          alt=""
                          className="w-5 h-5 rounded-full"
                        />
                      ) : (
                        <Bot size="12px" />
                      )}
                    </span>
                  )}
                </p>
              )}
            {callConversation?.call_status === "ongoing" && (
              <p className="m-0 flex w-full justify-between">
                <span className="rounded-md gap-1 flex items-center border-none font-inter text-xs font-medium leading-4 whitespace-nowrap text-left text-[#16A34A] bg-[#DCFCE7] p-1">
                  <Phone color="#16A34A" size="14px" /> In call
                </span>
              </p>
            )}
            {callConversation?.call_status === "ringing" ||
              (callConversation?.call_status === "registered" && (
                <p className="m-0 flex w-full justify-between">
                  <span className="rounded-md gap-1 flex items-center border-none font-inter text-xs font-medium leading-4 whitespace-nowrap text-left text-[#16A34A] bg-[#DCFCE7] p-1">
                    <Phone color="#16A34A" size="14px" /> Ringing
                  </span>
                </p>
              ))}
            {(callConversation?.call_status === "no-answer" ||
              callConversation?.call_status === "busy" ||
              callConversation?.call_status === "canceled") && (
              <p className="m-0 flex w-full justify-between">
                {callConversation?.call_status === "no-answer" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <PhoneMissed size="20px" color="#DC2626" /> No answer
                  </span>
                )}
                {callConversation?.call_status === "busy" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <PhoneMissed size="20px" color="#DC2626" /> Busy
                  </span>
                )}
                {callConversation?.call_status === "canceled" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <CircleAlert size="20px" color="#DC2626" /> Cancelled
                  </span>
                )}
              </p>
            )}
          </div>
        </>
      )}
      {type === "conversation" && (
        <>
          <div className="flex items-center justify-center h-12 w-12 bg-[var(--hover-color)] rounded-full">
            <span className="flex">
              <ProfileIcon name={callConversation?.contact?.name} />
            </span>
          </div>
          <div className="flex flex-col gap-1 w-[calc(100%-56px)]">
            <div className="flex items-center justify-between">
              <div className="flex items-center h-5 gap-2">
                <span className="font-inter text-sm font-medium leading-5 text-left text-[var(--primary-color)] overflow-hidden truncate max-w-[148px]">
                  {formatName(callConversation?.contact)}
                </span>
              </div>
              <span className="font-inter text-xs font-normal leading-4 text-left text-[var(--secondary-color)] min-w-[50px] flex justify-end whitespace-nowrap">
                {isRecent(callConversation?.last_call_at ?? callConversation?.created_at)
                  ? formatTime(new Date(callConversation?.last_call_at ?? callConversation?.created_at))
                  : formatDateToMonthDay(
                      new Date(callConversation?.last_call_at ?? callConversation?.created_at)
                    )}
              </span>
            </div>
            {callConversation?.last_call_status !== "no-answer" &&
              callConversation?.last_call_status !== "busy" &&
              callConversation?.last_call_status !== "canceled" &&
              callConversation?.last_call_status !== "ongoing" &&
              callConversation?.last_call_status !== "ringing" &&
              callConversation?.last_call_status !== "registered" && (
                <p className="m-0 flex w-full justify-between">
                  {
                    <span className="flex gap-1 items-center h-full overflow-hidden whitespace-nowrap">
                      <span className="flex">
                        {callConversation?.last_call_direction ===
                          "inbound" && (
                          <PhoneIncoming color="#64748B" size="20px" />
                        )}
                        {callConversation?.last_call_direction ===
                          "outbound" && (
                          <PhoneOutgoing color="#64748B" size="20px" />
                        )}
                      </span>
                      {callConversation?.last_call_log?.user_sentiment?.toLowerCase() ===
                        "neutral" && <Meh size="16px" color="#2563EB" />}
                      {callConversation?.last_call_log?.user_sentiment?.toLowerCase() ===
                        "positive" && <Smile size="16px" color="#16A34A" />}
                      {callConversation?.last_call_log?.user_sentiment?.toLowerCase() ===
                        "negative" && <Frown size="16px" color="#DC2626" />}
                      {callConversation?.last_call_duration && (
                        <span className="font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                          {formatDuration(callConversation?.last_call_duration)}
                        </span>
                      )}
                    </span>
                  }
                  {callConversation?.last_ai_agent && (
                    <span className="w-5 h-5 flex items-center justify-center bg-[var(--hover-color)] rounded-full">
                      {callConversation?.last_ai_agent?.profile_pic ? (
                        <img
                          src={callConversation?.last_ai_agent?.profile_pic}
                          alt=""
                          className="w-5 h-5 rounded-full"
                        />
                      ) : (
                        <Bot size="12px" />
                      )}
                    </span>
                  )}
                </p>
              )}
            {callConversation?.last_call_status === "ongoing" && (
              <p className="m-0 flex w-full justify-between">
                <span className="rounded-md gap-1 flex items-center border-none font-inter text-xs font-medium whitespace-nowrap leading-4 text-left text-[#16A34A] bg-[#DCFCE7] p-1">
                  <Phone color="#16A34A" size="14px" /> In call
                </span>
              </p>
            )}
            {(callConversation?.last_call_status === "ringing" ||
              callConversation?.last_call_status === "registered") && (
              <p className="m-0 flex w-full justify-between">
                <span className="rounded-md gap-1 flex items-center border-none font-inter text-xs font-medium whitespace-nowrap leading-4 text-left text-[#16A34A] bg-[#DCFCE7] p-1">
                  <Phone color="#16A34A" size="14px" /> Ringing
                </span>
              </p>
            )}
            {(callConversation?.last_call_status === "no-answer" ||
              callConversation?.last_call_status === "busy" ||
              callConversation?.last_call_status === "canceled") && (
              <p className="m-0 flex w-full justify-between">
                {callConversation?.last_call_status === "no-answer" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <PhoneMissed size="20px" color="#DC2626" /> No answer
                  </span>
                )}
                {callConversation?.last_call_status === "busy" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <PhoneMissed size="20px" color="#DC2626" /> Busy
                  </span>
                )}
                {callConversation?.last_call_status === "canceled" && (
                  <span className="flex items-center gap-2 font-inter text-sm font-normal leading-5 text-left text-[var(--secondary-color)] overflow-hidden text-ellipsis whitespace-nowrap transform translate-y-[2px]">
                    <CircleAlert size="20px" color="#DC2626" /> Cancelled
                  </span>
                )}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CallConversation;
