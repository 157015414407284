import { create } from "zustand";
import { persist } from "zustand/middleware";
import apiClient from "../interceptors/axiosApiInterceptor";
import { SubscriptionPlan } from "../models/subscription_plan";

interface WalletState {
  walletBalance: number;
  totalMinutes: number;
  addMoney: (obj: any) => Promise<any>;
  validateWalletRecharge: (obj: any) => Promise<any>;
  getWallet: () => Promise<any>;
  getSubscriptionWallet: () => Promise<any>;
  getWalletTransactions: (page: number) => Promise<any>;
  getAutoRecharge: () => Promise<any>;
  addAutoRecharge: (obj: any) => Promise<any>;
  disableAutoRecharge: () => Promise<any>;
  addVoiceDetectionRecharge: (obj: any) => Promise<any>;
  validateVoiceDetectionRecharge: (obj: any) => Promise<any>;
  numberAddOnDetails: SubscriptionPlan;
  aiAgentAddOnDetails: SubscriptionPlan;
  infobaseAddOnDetails: SubscriptionPlan;
  userAddOnDetails: SubscriptionPlan;
  concurrentAddOnDetails: SubscriptionPlan;
  selectedAddOn: SubscriptionPlan;
  setWalletBalance: (balance: number) => void;
  setTotalMinutes: (minutes: number) => void;
  setNumberAddOnDetails: (details: SubscriptionPlan) => void;
  setAiAgentAddOnDetails: (details: SubscriptionPlan) => void;
  setInfobaseAddOnDetails: (details: SubscriptionPlan) => void;
  setUserAddOnDetails: (details: SubscriptionPlan) => void;
  setConcurrentAddOnDetails: (details: SubscriptionPlan) => void;
  setSelectedAddOn: (details: SubscriptionPlan) => void;
}

export const useWalletStore = create<WalletState>()(
  persist(
    (set) => ({
      walletBalance: 0,
      totalMinutes: 0,
      numberAddOnDetails: new SubscriptionPlan(null),
      aiAgentAddOnDetails: new SubscriptionPlan(null),
      infobaseAddOnDetails: new SubscriptionPlan(null),
      userAddOnDetails: new SubscriptionPlan(null),
      concurrentAddOnDetails: new SubscriptionPlan(null),
      selectedAddOn: new SubscriptionPlan(null),

      addMoney: async (obj) => {
        try {
          const response = await apiClient.post("/wallet/recharge", obj);
          return response.data;
        } catch (error) {
          console.error("Error adding money", error);
          throw error;
        }
      },

      validateWalletRecharge: async (obj) => {
        try {
          const response = await apiClient.put("/wallet/recharge", obj);
          return response.data;
        } catch (error) {
          console.error("Error validating wallet recharge", error);
          throw error;
        }
      },

      getWallet: async () => {
        try {
          const response = await apiClient.get("/wallet/");
          return response.data;
        } catch (error) {
          console.error("Error fetching wallet", error);
          throw error;
        }
      },

      getSubscriptionWallet: async () => {
        try {
          const response = await apiClient.get("/wallet/subscription");
          return response.data;
        } catch (error) {
          console.error("Error fetching subscription wallet", error);
          throw error;
        }
      },

      getWalletTransactions: async (page) => {
        try {
          const response = await apiClient.get(`/wallet/transactions?page=${page}`);
          return response.data;
        } catch (error) {
          console.error("Error fetching wallet transactions", error);
          throw error;
        }
      },

      getAutoRecharge: async () => {
        try {
          const response = await apiClient.get("/wallet/auto-recharge");
          return response.data;
        } catch (error) {
          console.error("Error fetching auto recharge", error);
          throw error;
        }
      },

      addAutoRecharge: async (obj) => {
        try {
          const response = await apiClient.post("/wallet/auto-recharge", obj);
          return response.data;
        } catch (error) {
          console.error("Error adding auto recharge", error);
          throw error;
        }
      },

      disableAutoRecharge: async () => {
        try {
          const response = await apiClient.delete("/wallet/auto-recharge");
          return response.data;
        } catch (error) {
          console.error("Error disabling auto recharge", error);
          throw error;
        }
      },

      addVoiceDetectionRecharge: async (obj) => {
        try {
          const response = await apiClient.post("/wallet/recharge/voice-detection", obj);
          return response.data;
        } catch (error) {
          console.error("Error adding voice detection recharge", error);
          throw error;
        }
      },

      validateVoiceDetectionRecharge: async (obj) => {
        try {
          const response = await apiClient.put("/wallet/recharge/voice-detection", obj);
          return response.data;
        } catch (error) {
          console.error("Error validating voice detection recharge", error);
          throw error;
        }
      },

      setWalletBalance: (balance) => set({ walletBalance: balance }),
      setTotalMinutes: (minutes) => set({ totalMinutes: minutes }),
      setNumberAddOnDetails: (details) => set({ numberAddOnDetails: details }),
      setAiAgentAddOnDetails: (details) => set({ aiAgentAddOnDetails: (details) }),
      setInfobaseAddOnDetails: (details) => set({ infobaseAddOnDetails: details }),
      setUserAddOnDetails: (details) => set({ userAddOnDetails: (details) }),
      setConcurrentAddOnDetails: (details) => set({ concurrentAddOnDetails: details }),
      setSelectedAddOn: (details) => set({selectedAddOn: details}) ,
    }),
    {
      name: "wallet-storage", // State persistence key
    }
  )
);

export default useWalletStore;
