import { getCallLogs, searchCallLogs } from "../../lib/voice.utils";
import useSharedStore from "../../store/shared.store";
import React, { useEffect, useState } from "react";
import { Button } from "../shadcn/button";
import { Params } from "react-router-dom";
import { Input } from "../shadcn/input";
import ProfileIcon from "../shared/ProfileIcon";
import { PhoneIncoming, PhoneOutgoing, Meh, Smile, Frown } from "lucide-react";
import Pagination from "../shared/Pagination";
import { Skeleton } from "../shadcn/skeleton";
import { formatDateToMonthDayYear, formatDuration, formatName, formatTime } from "../../lib/ui.utils";
import CallLogDetails from "../shared/CallLogDetails";
// import "./CallsTableView.css";

interface CallsTableViewProps {
  activeCloudNumber: number;
  currentInboxId: number;
}

const CallsTableView: React.FC<CallsTableViewProps> = ({
  activeCloudNumber,
  currentInboxId,
}) => {
  const [callLogs, setCallLogs] = useState<any[]>([]);
  const [callTab, setCallTab] = useState<string>("all");
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [contactsSearchTag, setContactsSearchTag] = useState<string>("");
  const [prevContactsSearchTag, setPrevContactsSearchTag] =
    useState<string>("");
  const [pagination, setPagination] = useState<boolean>(false);
  const [paginationSize, setPaginationSize] = useState<number>(0);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  var paginatedPages = new Set<number>();
  const setPaginatedPages = (pages: Set<number>) => {
    paginatedPages = pages;
  }
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaderPagination, setLoaderPagination] = useState<boolean>(false);
  const [pageNext, setPageNext] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [conversationCount, setConversationCount] = useState<number>(0);
  const [openCallLogId, setOpenCallLogId] = useState<number>(0);
  const [openedCallLogIndex, setOpenedCallLogIndex] = useState<number>(0);
  const [openedCallLog, setOpenedCallLog] = useState<any>(null);
  const [uiFilterTab, setUiFilterTab] = useState<
    { type: string; condition: string; text: string }[]
  >([]);
  const [audioPlayingId, setAudioPlayingId] = useState<number>(0);
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio());

  var params: any;
  const setParams = (parms: any) => {
    params = parms;
  };
  const sharedStore = useSharedStore();

  useEffect(() => {
    // setParams({ inbox_id: currentInboxId });
    setPaginatedPages(new Set());
    getCallLogsList(1);
  }, [currentInboxId]);

  const openDialer = () => {
    sharedStore?.cloudNumbers?.forEach((element) => {
      if (currentInboxId === element?.id)
        sharedStore.setDialerInboxSelected(element);
    });
    sharedStore.setDialerPopup(true);
  };

  const getCallLogsList = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) {
      return;
    } else {
      setIsLoading(true);
      setPrevContactsSearchTag(contactsSearchTag);
      // Replace with actual API call
      getCallLogs(pageNumber, {...params, inbox_id: currentInboxId})
        .then((resp) => {
          if (resp.data?.code === 200) {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > paginationSize);
            if (callLogs.length !== resp.data.count)
              setCallLogs(new Array<any>(resp.data.count));
            setPaginationPage(pageNumber);
            if (pageNumber === 1) {
              for (let index = 0; index < resp.data.data.length; index++) {
                let call = resp.data.data[index];
                if (
                  call?.call_status === "registered" &&
                  timeDifferenceOf5Mins(call?.created_at)
                )
                  call.call_status = "ended";
                setCallLogs((prev) => {
                  const newCallLogs = [...prev];
                  newCallLogs[index] = call;
                  return newCallLogs;
                });
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            } else {
              for (
                let index = (pageNumber - 1) * paginationSize;
                index <
                (pageNumber - 1) * paginationSize + resp.data.data.length;
                index++
              ) {
                let call = resp.data.data[index % paginationSize];
                if (
                  call?.call_status === "registered" &&
                  timeDifferenceOf5Mins(call?.created_at)
                )
                  call.call_status = "ended";
                  setCallLogs((prev) => {
                    const newCallLogs = [...prev];
                    newCallLogs[index] = call;
                    return newCallLogs;
                  });
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            }
          }
          setIsLoading(false);
          setLoaderPagination(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          setLoaderPagination(false);
        });
    }
  };

  const timeDifferenceOf5Mins = (time: any): boolean => {
    const currentTime = new Date();
    const targetDateTime = new Date(time);
    const targetTimeInMs = targetDateTime.getTime();
    const currentTimeInMs = currentTime.getTime();
    const differenceInMs = currentTimeInMs - targetTimeInMs;
    const fiveMinutesInMs = 5 * 60 * 1000;
    return differenceInMs > fiveMinutesInMs;
  };

  const handleSearchInputChange = (event: any) => {
    setContactsSearchTag(event.target.value);
    setTimeout(() => {
      if (
        prevContactsSearchTag.trim() !== contactsSearchTag.trim() &&
        contactsSearchTag.length > 2
      ) {
        setPageNumber(1);
        setPaginatedPages(new Set());
        searchCampaign(1);
      } else if (
        prevContactsSearchTag.trim() !== contactsSearchTag.trim() &&
        prevContactsSearchTag.length > 2
      ) {
        setPageNumber(1);
        setPaginatedPages(new Set());
        getCallLogsList(1);
      }
    }, 300);
  };

  const searchCampaign = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) {
      return;
    } else {
      setIsLoading(true);
      setPrevContactsSearchTag(contactsSearchTag);
      // Replace with actual API call
      searchCallLogs(pageNumber, contactsSearchTag)
        .then((resp) => {
          if (resp.data?.code === 200) {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > paginationSize);
            if (callLogs.length !== resp.data.count)
              setCallLogs(new Array<any>(resp.data.count));
            setPaginationPage(pageNumber);
            if (pageNumber === 1) {
              for (let index = 0; index < resp.data.data.length; index++) {
                let call = resp.data.data[index];
                if (
                  call?.call_status === "registered" &&
                  timeDifferenceOf5Mins(call?.created_at)
                )
                  call.call_status = "ended";
                  setCallLogs((prev) => {
                    const newCallLogs = [...prev];
                    newCallLogs[index] = call;
                    return newCallLogs;
                  });
                
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            } else {
              for (
                let index = (pageNumber - 1) * paginationSize;
                index <
                (pageNumber - 1) * paginationSize + resp.data.data.length;
                index++
              ) {
                let call = resp.data.data[index % paginationSize];
                if (
                  call?.call_status === "registered" &&
                  timeDifferenceOf5Mins(call?.created_at)
                )
                  call.call_status = "ended";
                  setCallLogs((prev) => {
                    const newCallLogs = [...prev];
                    newCallLogs[index] = call;
                    return newCallLogs;
                  });
              }
              setPaginatedPages(new Set(paginatedPages).add(pageNumber));
            }
          }
          setIsLoading(false);
          setLoaderPagination(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          setLoaderPagination(false);
          setCallLogs([]);
        });
    }
  };

  const handleTabChange = (tab: string) => {
    console.log(callTab, tab);
    if (callTab === tab) return;
    setCallTab(tab);
    let array: string[] = [];
    if (tab === "missed") array = ["no-answer", "busy"];
    else if (tab === "in-call") array = ["ongoing"];
    let filters = {
      and: {
        call_status__in: array,
      },
    };
    if (tab === "all") {
      setParams({ ...params, filters: "" });
    } else {
      setParams({ ...params, filters: JSON.stringify(filters) });
    }
    setPageNumber(1);
    setPaginatedPages(new Set());
    setCallLogs([]);
    getCallLogsList(1);
  };

  const showCallLog = (call: any, index: number) => {
    setOpenedCallLog(call);
    setOpenedCallLogIndex(index);
  };

  const hideCallDetails = () => {
    setOpenedCallLog(null);
    setOpenedCallLogIndex(0);
  };

  const filterChange = (filter: any) => {
    setFilterPopup(false);
    if (filter?.api) {
      setUiFilterTab(filter.ui);
      setParams({ ...params, filters: JSON.stringify(filter.api) });
    } else {
      setUiFilterTab([]);
      const newParams = { ...params };
      delete newParams.filters;
      setParams(newParams);
    }
    setPageNumber(1);
    setIsLoading(true);
    setPageNext(true);
    setPaginatedPages(new Set());
    getCallLogsList(1);
  };

  const playAudio = (call: any) => {
    setAudioPlayingId(call?.id);
    const newAudio = new Audio(call?.recording_url);
    setAudio(newAudio);
    newAudio.play();
    newAudio.addEventListener("ended", () => {
      setAudioPlayingId(0);
    });
  };

  const pauseAudio = () => {
    setAudioPlayingId(0);
    audio.pause();
  };

  return (
    <div className="w-full h-full overflow-auto p-4">
      <div className="container flex flex-col gap-4">
        <div className="flex justify-between items-center font-inter text-lg font-semibold text-left text-[var(--primary-color)]">
          <span>Conversation history</span>
        </div>
        <div className="flex w-full items-center justify-between p-1">
          <div className="flex gap-2 items-center">
            <div
              className={`px-2 py-1  flex gap-2 items-center rounded-lg font-inter text-base font-medium text-left cursor-pointer ${
              callTab === "all"
                ? "bg-[#E2E8F0] text-[#070A0C]"
                : "text-[#747D82]"
              }`}
              style={{ fontSize: "14px" }}
              onClick={() => handleTabChange("all")}
            >
              All{" "}
              <span className="font-inter text-sm font-normal text-left hidden">
              {callLogs?.length}
              </span>
            </div>
            <div
              className={`px-2 py-1 flex gap-2 items-center rounded-lg font-inter text-base font-medium text-left cursor-pointer ${
                callTab === "missed"
                  ? "bg-[#E2E8F0] text-[#070A0C]"
                  : "text-[#747D82]"
              }`}
              style={{ fontSize: "14px" }}
              onClick={() => handleTabChange("missed")}
            >
              Missed{" "}
              <span className="font-inter text-sm font-normal text-left hidden">
                {callLogs?.length}
              </span>
            </div>
            <div
              className={`px-2 py-1 flex gap-2 items-center rounded-lg font-inter text-base font-medium text-left cursor-pointer ${
                callTab === "in-call"
                  ? "bg-[#E2E8F0] text-[#070A0C]"
                  : "text-[#747D82]"
              }`}
              style={{ fontSize: "14px" }}
              onClick={() => handleTabChange("in-call")}
            >
              In call{" "}
              <span className="font-inter text-sm font-normal text-left hidden">
                {callLogs?.length}
              </span>
            </div>
          </div>
          <div className="relative flex gap-2 items-center">
            {/* <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setFilterPopup(true);
              }}
            >
              Filter
            </Button> */}
            {sharedStore?.business?.subscription?.outbound_call && (
              <Button
                onClick={() => openDialer()}
                disabled={activeCloudNumber <= 0}
              >
                Make a call
              </Button>
            )}
            {/* {filterPopup && <CallFilter onClose={() => setFilterPopup(false)} onFilterChange={(event) => dispatch(filterChange(event))} />} */}
          </div>
        </div>
        {uiFilterTab.length > 0 && (
          <div className="flex gap-2 items-center overflow-hidden whitespace-nowrap text-ellipsis">
            {uiFilterTab.map((filter, index) => (
              <div
                key={index}
                className="bg-hover-color font-inter text-base font-normal text-center text-[var(--primary-color)] whitespace-nowrap px-1.5 py-0.5 rounded cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setFilterPopup(true);
                }}
              >
                <span className="font-medium">{filter.type}</span>{" "}
                {filter.condition} {filter.text}
              </div>
            ))}
          </div>
        )}
        {!(contactsSearchTag === "" && !isLoading && callLogs.length === 0) && (
          <>
            <div className="max-w-lg">
              <Input
                placeholder="Search contact"
                value={contactsSearchTag}
                onChange={handleSearchInputChange}
              />
            </div>
            {!isLoading && callLogs.length > 0 && (
              <div className="border border-border-color rounded-lg">
                <table className="w-full rounded-lg overflow-hidden">
                  <thead>
                    <tr className="border-b border-border-color bg-[var(--hover-color)]">
                      <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                        Contacts
                      </th>
                      <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                        Type
                      </th>
                      <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                        Time
                      </th>
                      <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                        Duration
                      </th>
                      <th className="font-inter text-xs font-medium text-left text-[var(--secondary-color)] p-2 bg-hover-color">
                        Sentiment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {callLogs.slice(
                      (paginationPage - 1) * paginationSize,
                      paginationPage * paginationSize
                      ).map((call, i) => (
                      <tr
                        key={i}
                        className="cursor-pointer border-b border-border-color hover:bg-[var(--hover-color)]"
                        onClick={() => showCallLog(call, i)}
                      >
                        <td className="flex gap-2 align-center p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                          <ProfileIcon
                            iconSize="16px"
                            size="20px"
                            name={call?.customer?.name}
                          />
                          {call?.customer
                            ? formatName(call?.customer)
                            : "Test web call"}
                        </td>
                        <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                          <span className="flex gap-2 items-center">
                            {call?.direction === "inbound" && (
                              <PhoneIncoming color="#64748B" size="20px" />
                            )}
                            {call?.direction === "outbound" && (
                              <PhoneOutgoing color="#64748B" size="20px" />
                            )}
                            <span>{call?.direction}</span>
                          </span>
                        </td>
                        <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                          {formatDateToMonthDayYear(call?.created_at)},{" "}
                          {formatTime(call?.created_at)}
                        </td>
                        <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                          <span className="flex gap-2 items-center">
                            {call?.end_timestamp && call?.start_timestamp
                              ? formatDuration(call?.end_timestamp / 1000 -
                                call?.start_timestamp / 1000)
                              : "-"}
                          </span>
                        </td>
                        <td className="p-4 font-inter text-sm font-normal text-left text-[var(--primary-color)] min-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                          <span className="flex gap-2 items-center">
                            {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                              "neutral" && <Meh size="16px" color="#2563EB" />}
                            {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                              "positive" && (
                              <Smile size="16px" color="#16A34A" />
                            )}
                            {call?.call_analysis?.user_sentiment?.toLowerCase() ===
                              "negative" && (
                              <Frown size="16px" color="#DC2626" />
                            )}
                            <span>{call?.call_analysis?.user_sentiment}</span>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {isLoading && (
              <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="w-full gap-4 flex flex-col">
                    {Array.from({ length: 10 }).map((_, index) => (
                      <Skeleton key={index} className="h-8 w-full" />
                    ))}
                  </div>
                ))}
              </div>
            )}
            {contactsSearchTag !== "" &&
              !isLoading &&
              callLogs.length === 0 && (
                <div className="w-full h-full flex items-center justify-center">
                  <div className="flex flex-col items-center justify-center gap-4 font-inter text-base font-medium text-left text-[var(--primary-color)]">
                    <img
                      src="../../../../assets/images/logs_empty.png"
                      alt="No call logs found"
                    />
                    <span>No call logs were found matching your search</span>
                  </div>
                </div>
              )}
            {pagination && !isLoading && (
              <div className="flex justify-end mt-4">
                <Pagination
                  currentPage={paginationPage}
                  listLength={callLogs.length}
                  paginationSize={paginationSize}
                  onPageChange={(page) => {
                    setPaginationPage(page);
                    contactsSearchTag === ""
                      ? getCallLogsList(page)
                      : searchCampaign(page);
                  }}
                />
              </div>
            )}
          </>
        )}
        {contactsSearchTag === "" && !isLoading && callLogs.length === 0 && (
          <div className="w-full h-full flex flex-col items-center justify-center gap-4 font-inter text-base font-medium text-left text-[var(--primary-color)]">
            <div className="flex flex-col items-center justify-center gap-4">
              <img
                src="../../../../assets/images/logs_empty.png"
                alt="No call logs found"
              />
              <span>No call logs found</span>
            </div>
          </div>
        )}
      </div>
      {openedCallLog?.id && <CallLogDetails 
        callLog={openedCallLog} 
        close={() => (hideCallDetails())} 
        customerUpdate={(customer) => callLogs[openedCallLogIndex].customer = customer} 
        callUpdate={(call) => callLogs[openedCallLogIndex] = call} />}
    </div>
  );
};

export default CallsTableView;
