import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../shadcn/dialog";
import { Button } from "../shadcn/button";
import { X, Plus, ChevronDown, Trash2, Frown, Meh, Smile } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import { AiAgent } from "../../models/ai-agent";
import { getAllAgents } from "../../lib/agents.utils";
import { Checkbox } from "../shadcn/checkbox";
import CustomIcon from "../shared/CustomIcon";
import { Input } from "../shadcn/input";
import { Slider } from "../shadcn/slider";

interface CallFilterProps {
  onClose: () => void;
  agentsHide: boolean;
  filterChange: (filters: any) => void;
}

const CallFilter: React.FC<CallFilterProps> = ({
  onClose,
  filterChange,
  agentsHide,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [filters, setFilters] = useState([]);
  const [loaderApply, setLoaderApply] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<Set<string>>(new Set());
  const [filtersList, setFiltersList] = useState<
    {
      group: string;
      type: string;
      condition: string;
      values: any[];
    }[]
  >([]);
  const [callFrom, setCallFrom] = useState("");
  const [callTo, setCallTo] = useState("");
  const [loaderPagination, setLoaderPagination] = useState(false);
  const [pageNext, setPageNext] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaderTeamMemberList, setLoaderTeamMemberList] = useState(false);

  const filterEnums = {
    ai_agent: "AI Agent",
    call_type: "Call type",
    call_duration: "Call duration",
    call_sentiment: "Call sentiment",
    call_status: "Call status",
    task_status: "Task status",
    call_rating: "Call rating",
    date: "Date",
    from_number: "Call from",
    to_number: "Call to",
  };

  const filterType = Object.values(filterEnums);

  const [ratingsList, setRatingsList] = useState([
    { text: "5 stars", selected: false },
    { text: "4 stars", selected: false },
    { text: "3 stars", selected: false },
    { text: "2 stars", selected: false },
    { text: "1 star", selected: false },
    { text: "No rating", selected: false },
  ]);

  const [callTypeList, setCallTypeList] = useState([
    { text: "Inbound", selected: false },
    { text: "Outbound", selected: false },
  ]);

  const [callSentimentList, setCallSentimentList] = useState([
    { text: "Positive", selected: false },
    { text: "Neutral", selected: false },
    { text: "Negative", selected: false },
  ]);

  const [callStatusList, setCallStatusList] = useState([
    { text: "Complete", selected: false },
    { text: "Partial", selected: false },
    { text: "Incomplete", selected: false },
  ]);

  const [taskStatusList, setTaskStatusList] = useState([
    { text: "Complete", selected: false },
    { text: "Partial", selected: false },
    { text: "Incomplete", selected: false },
  ]);

  const [aiAgentList, setAiAgentList] = useState<
    {
      agent: AiAgent;
      selected: boolean;
    }[]
  >([]);
  const [selectAiAgentSet, setSelectAiAgentSet] = useState<Set<number>>(
    new Set()
  );

  useEffect(() => {
    if (agentsHide) {
      const index = filterType.indexOf(filterEnums.ai_agent);
      if (index !== -1) {
        filterType.splice(index, 1);
      }
    }
  }, [agentsHide]);

  useEffect(() => {
    setLoaderTeamMemberList(true);
    getAgents(1);

    const storedFilters = sessionStorage.getItem("call-filters");
    if (sessionStorage.getItem("call-filters")) {
      const filters = sessionStorage.getItem("call-filters");
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        setFiltersList(parsedFilters);
        parsedFilters.forEach((element: any) => {
          setSelectedFilter(
            (prevSelected) => new Set(prevSelected.add(element.type))
          );
          if (element.type === filterEnums.call_rating) {
            setRatingsList((prevRatings) =>
              prevRatings.map((rating, index) => ({
                ...rating,
                selected: element.values.indexOf(index) > -1,
              }))
            );
          }
          if (element.type === filterEnums.call_sentiment) {
            setCallSentimentList((prevSentiments) =>
              prevSentiments.map((sentiment, index) => ({
                ...sentiment,
                selected: element.values.indexOf(index) > -1,
              }))
            );
          }
          if (element.type === filterEnums.call_status) {
            setCallStatusList((prevStatuses) =>
              prevStatuses.map((status, index) => ({
                ...status,
                selected: element.values.indexOf(index) > -1,
              }))
            );
          }
          if (element.type === filterEnums.call_type) {
            setCallTypeList((prevTypes) =>
              prevTypes.map((type, index) => ({
                ...type,
                selected: element.values.indexOf(index) > -1,
              }))
            );
          }
          if (element.type === filterEnums.task_status) {
            setTaskStatusList((prevTasks) =>
              prevTasks.map((task, index) => ({
                ...task,
                selected: element.values.indexOf(index) > -1,
              }))
            );
          }
          if (element.type === filterEnums.from_number) {
            setCallFrom(element?.values?.length > 0 ? element.values[0] : "");
          }
          if (element.type === filterEnums.to_number) {
            setCallTo(element?.values?.length > 0 ? element.values[0] : "");
          }
        });
      }
    } else {
      setFiltersList([]);
      if (filtersList?.length < 1) addFilter();
    }
  }, []);

  const getAgents = (page: number) => {
    setLoaderPagination(true);
    getAllAgents(page,true)
      .then((response) => {
        if (response.data.next) setPageNext(true);
        else setPageNext(false);
        let responseList = response.data.data;
        const agentsArray = JSON.parse(
          sessionStorage.getItem("selected-agents")!
        );
        let selectedSet: Set<number> = new Set();
        agentsArray?.forEach((agent: number) => selectedSet.add(agent));
        setSelectAiAgentSet(selectedSet);
        let newAiAgentList = responseList.map((agent: any) => ({
          agent: new AiAgent(agent),
          selected: selectedSet.has(agent?.id),
        }));
        if (page === 1) setAiAgentList(newAiAgentList);
        else setAiAgentList((prev) => [...prev, ...newAiAgentList]);
        localStorage.setItem("aiagents", JSON.stringify(aiAgentList));

        setPageNumber(page + 1);
        setLoaderPagination(false);
        setLoaderTeamMemberList(false);
      })
      .catch((error) => {
        console.error(error);
        setLoaderPagination(false);
        setLoaderTeamMemberList(false);
      });
  };

  const onScroll = (event: any) => {
    if (
      event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop <
        50 &&
      pageNext &&
      !loaderPagination
    ) {
      getAgents(pageNumber);
    }
  };

  const addFilter = () => {
    setFiltersList((prevFilters) => [
      ...prevFilters,
      {
        group: prevFilters.length === 0 ? "Where" : "and",
        type: "",
        condition: "is",
        values: [],
      },
    ]);
  };

  const changeTypeOfFilter = (type: string, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[index];
      if (currentFilter?.type !== "") {
        setSelectedFilter((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(currentFilter.type);
          return newSelected;
        });
        const resetSelection = (filterType: string, list: any[]) => {
          list.forEach((obj) => (obj.selected = false));
        };

        if (currentFilter?.type === filterEnums.ai_agent) {
          resetSelection(filterEnums.ai_agent, aiAgentList);
        }
        if (currentFilter?.type === filterEnums.call_type) {
          resetSelection(filterEnums.call_type, callTypeList);
        }
        if (currentFilter?.type === filterEnums.call_rating) {
          resetSelection(filterEnums.call_rating, ratingsList);
        }
        if (currentFilter?.type === filterEnums.call_status) {
          resetSelection(filterEnums.call_status, callStatusList);
        }
        if (currentFilter?.type === filterEnums.call_sentiment) {
          resetSelection(filterEnums.call_sentiment, callSentimentList);
        }
        if (currentFilter?.type === filterEnums.task_status) {
          resetSelection(filterEnums.task_status, taskStatusList);
        }
        if (currentFilter?.type === filterEnums.call_duration) {
          setSelectedFilter((prevSelected) => {
            const newSelected = new Set(prevSelected);
            newSelected.delete(filterEnums.call_duration);
            return newSelected;
          });
        }
        if (currentFilter?.type === filterEnums.date) {
          setSelectedFilter((prevSelected) => {
            const newSelected = new Set(prevSelected);
            newSelected.delete(filterEnums.date);
            return newSelected;
          });
        }
        if (currentFilter?.type === filterEnums.from_number) {
          setCallFrom("");
          setSelectedFilter((prevSelected) => {
            const newSelected = new Set(prevSelected);
            newSelected.delete(filterEnums.from_number);
            return newSelected;
          });
        }
        if (currentFilter?.type === filterEnums.to_number) {
          setCallFrom("");
          setSelectedFilter((prevSelected) => {
            const newSelected = new Set(prevSelected);
            newSelected.delete(filterEnums.to_number);
            return newSelected;
          });
        }
      } else {
        setSelectedFilter((prevSelected) => {
          const newSelected = new Set(prevSelected);
          newSelected.add(type);
          return newSelected;
        });
      }

      currentFilter.type = type;
      currentFilter.values = type === filterEnums.call_duration ? [0, 60] : [];
      updatedFilters[index] = currentFilter;
      return updatedFilters;
    });
  };

  const closeFilter = () => {
    onClose();
  };

  const changeAgentFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      let aiAgentsSet: Set<number> = new Set();
      currentFilter.values.forEach((agent: number) =>
        aiAgentsSet.add(aiAgentList[agent]?.agent?.id)
      );
      setSelectAiAgentSet(aiAgentsSet);
      aiAgentList[index].selected = !aiAgentList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const changeCallTypeFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      callTypeList[index].selected = !callTypeList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const changeRatingFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      ratingsList[index].selected = !ratingsList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const changeCallStatusFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      callStatusList[index].selected = !callStatusList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const changeTaskStatusFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      taskStatusList[index].selected = !taskStatusList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const changeCallSentimentFilter = (listIndex: number, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const currentFilter = updatedFilters[listIndex];
      const findIndex = currentFilter.values.indexOf(index);

      if (findIndex !== -1) {
        currentFilter.values.splice(findIndex, 1);
      } else {
        currentFilter.values.push(index);
        currentFilter.values.sort();
      }
      callSentimentList[index].selected = !callSentimentList[index].selected;
      updatedFilters[listIndex] = currentFilter;
      return updatedFilters;
    });
  };

  const removeFilter = (index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      const removedFilter = updatedFilters[index];

      setSelectedFilter((prevSelected) => {
        const newSelected = new Set(prevSelected);
        newSelected.delete(removedFilter.type);
        return newSelected;
      });

      if (removedFilter.type === filterEnums.ai_agent) {
        aiAgentList?.forEach((element) => (element.selected = false));
        setSelectAiAgentSet(new Set());
      }
      if (removedFilter.type === filterEnums.call_duration) {
        removedFilter.values = [];
      }
      if (removedFilter.type === filterEnums.call_rating) {
        ratingsList?.forEach((element) => (element.selected = false));
      }
      if (removedFilter.type === filterEnums.call_sentiment) {
        callSentimentList?.forEach((element) => (element.selected = false));
      }
      if (removedFilter.type === filterEnums.call_status) {
        callStatusList?.forEach((element) => (element.selected = false));
      }
      if (removedFilter.type === filterEnums.call_type) {
        callTypeList?.forEach((element) => (element.selected = false));
      }
      if (removedFilter.type === filterEnums.task_status) {
        taskStatusList?.forEach((element) => (element.selected = false));
      }
      if (removedFilter.type === filterEnums.from_number) {
        setCallFrom("");
      }
      if (removedFilter.type === filterEnums.to_number) {
        setCallFrom("");
      }

      updatedFilters.splice(index, 1);
      return updatedFilters;
    });
  };

  const applyFilter = () => {
    console.log(filtersList);
    let andObj: Record<string, any> = {};
    let orObj: Record<string, any> = {};

    filtersList?.forEach((element) => {
      const targetObj: Record<string, any> =
        element.group === "and" || element.group === "Where" ? andObj : orObj;

      if (element.type === filterEnums.ai_agent) {
        const agentIds =
          aiAgentList
            ?.filter((agent) => agent.selected)
            .map((agent) => agent.agent.agent_id) || [];
        targetObj[
          element.condition === "is" ? "agent_id__in" : "~agent_id__in"
        ] = agentIds;
        let agents = Array.from(selectAiAgentSet);
        sessionStorage.setItem("selected-agents", JSON.stringify(agents));
      }
      if (element.type === filterEnums.call_type) {
        const typeIds =
          callTypeList
            ?.filter((type) => type.selected)
            .map((type) => type.text.toLowerCase()) || [];
        targetObj[
          element.condition === "is" ? "direction__in" : "~direction__in"
        ] = typeIds;
      }
      if (element.type === filterEnums.call_rating) {
        const ratingIds =
          ratingsList
            ?.filter((rating) => rating.selected)
            .map((_, index) => index + 1) || [];
        targetObj[
          element.condition === "is"
            ? "callrating__rating__in"
            : "~callrating__rating__in"
        ] = ratingIds;
      }
      if (element.type === filterEnums.call_sentiment) {
        const sentimentIds =
          callSentimentList
            ?.filter((sentiment) => sentiment.selected)
            .map((sentiment) => sentiment.text) || [];
        targetObj[
          element.condition === "is"
            ? "raw_log__call_analyzed__call_analysis__user_sentiment__in"
            : "~raw_log__call_analyzed__call_analysis__user_sentiment__in"
        ] = sentimentIds;
      }
      if (element.type === filterEnums.call_status) {
        const statusIds =
          callStatusList
            ?.filter((status) => status.selected)
            .map((status) => status.text) || [];
        targetObj[
          element.condition === "is"
            ? "raw_log__call_analyzed__call_analysis__call_completion_rating__in"
            : "~raw_log__call_analyzed__call_analysis__call_completion_rating__in"
        ] = statusIds;
      }
      if (element.type === filterEnums.task_status) {
        const taskIds =
          taskStatusList
            ?.filter((task) => task.selected)
            .map((task) => task.text) || [];
        targetObj[
          element.condition === "is"
            ? "raw_log__call_analyzed__call_analysis__agent_task_completion_rating__in"
            : "~raw_log__call_analyzed__call_analysis__agent_task_completion_rating__in"
        ] = taskIds;
      }
      if (element.type === filterEnums.call_duration) {
        targetObj[
          element.condition === "is" ? "duration__range" : "~duration__range"
        ] = element.values;
      }
      if (element.type === filterEnums.date) {
        targetObj[element.condition === "is" ? "created_at" : "~created_at"] =
          element.values;
      }
      if (element.type === filterEnums.from_number) {
        element.values = [callFrom];
        targetObj["from_number"] = callFrom;
      }
      if (element.type === filterEnums.to_number) {
        element.values = [callTo];
        targetObj["to_number"] = callTo;
      }
    });

    const filterObj: Record<string, any> = {};
    if (Object.keys(andObj).length > 0) filterObj["and"] = andObj;
    if (Object.keys(orObj).length > 0) filterObj["or"] = orObj;

    const filterTabsObj =
      filtersList?.map((element) => {
        let text = "";
        if (element.type === filterEnums.ai_agent)
          text =
            element.values?.length > 0
              ? aiAgentList[element.values[0]].agent.agent_name +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.call_rating)
          text =
            element.values?.length > 0
              ? ratingsList[element.values[0]].text +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.call_type)
          text =
            element.values?.length > 0
              ? callTypeList[element.values[0]].text +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.call_sentiment)
          text =
            element.values?.length > 0
              ? callSentimentList[element.values[0]].text +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.call_status)
          text =
            element.values?.length > 0
              ? callStatusList[element.values[0]].text +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.task_status)
          text =
            element.values?.length > 0
              ? taskStatusList[element.values[0]].text +
                (element.values.length > 1
                  ? " +" + (element.values.length - 1)
                  : "")
              : "";
        if (element.type === filterEnums.call_duration)
          text = element.values[0] + "min to " + element.values[1] + "min";
        if (element.type === filterEnums.date)
          text = element.values[0] + " to " + element.values[1];
        if (element.type === filterEnums.from_number) text = callFrom;
        if (element.type === filterEnums.to_number) text = callTo;

        return {
          type: element.type,
          condition: element.condition,
          text: text,
        };
      }) || [];

    sessionStorage.setItem("call-filters", JSON.stringify(filtersList));
    filterChange({ ui: filterTabsObj, api: filterObj });
  };

  const durationStartChange = (event: any, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index].values[0] = event;
      return updatedFilters;
    });
  };

  const durationEndChange = (event: any, index: number) => {
    setFiltersList((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index].values[1] = event;
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setFiltersList(() => {
      const initialFilter = [
        {
          group: "Where",
          type: "",
          condition: "is",
          values: [],
        },
      ];
      sessionStorage.removeItem("selected-agents");
      sessionStorage.removeItem("call-filters");
      filterChange(null);
      return initialFilter;
    });
  };

  return (
    <div className="flex w-full bg-[var(--bg-color)]">
      <div className="rounded-lg w-full flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">Filter Calls</h2>
          <X className="cursor-pointer" size={20} onClick={onClose} />
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="w-full">
            <table className="w-full border-collapse text-secondary text-sm">
              <tbody>
                {filtersList.map((filter, i) => (
                  <tr key={i} className="">
                    {i === 0 && <td className="p-1 w-20">Where</td>}
                    {i !== 0 && (
                      <td className="p-1 w-20">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              {filter.group} <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            <DropdownMenuItem
                              className="cursor-pointer hover:bg-[var(--hover-color)] rounded"
                              onClick={() =>
                                setFiltersList((prev) =>
                                  prev.map((f, j) =>
                                    j === i ? { ...f, group: "and" } : f
                                  )
                                )
                              }
                            >
                              and
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="cursor-pointer hover:bg-[var(--hover-color)] rounded"
                              onClick={() =>
                                setFiltersList((prev) =>
                                  prev.map((f, j) =>
                                    j === i ? { ...f, group: "or" } : f
                                  )
                                )
                              }
                            >
                              or
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    <td className="p-1 w-40">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="outline"
                            className="w-full flex justify-between"
                          >
                            {filter.type || "Select property"}{" "}
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                          }}
                        >
                          {Object.entries(filterEnums).map(
                            ([key, type]) =>
                              !selectedFilter.has(type) &&
                              key !== "date" && (
                                <DropdownMenuItem
                                  className="cursor-pointer hover:bg-[var(--hover-color)] rounded"
                                  key={key}
                                  onClick={() => changeTypeOfFilter(type, i)}
                                >
                                  {type}
                                </DropdownMenuItem>
                              )
                          )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </td>
                    <td className="p-1 w-32">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="outline"
                            className="w-full flex justify-between"
                          >
                            {filter.condition || "Condition"}{" "}
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                          }}
                        >
                          <DropdownMenuItem
                            className="cursor-pointer hover:bg-[var(--hover-color)] rounded"
                            onClick={() =>
                              setFiltersList((prev) =>
                                prev.map((f, j) =>
                                  j === i ? { ...f, condition: "is" } : f
                                )
                              )
                            }
                          >
                            is
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer hover:bg-[var(--hover-color)] rounded"
                            onClick={() =>
                              setFiltersList((prev) =>
                                prev.map((f, j) =>
                                  j === i ? { ...f, condition: "is not" } : f
                                )
                              )
                            }
                          >
                            is not
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </td>
                    {filter.type === "" && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span style={{ color: "var(--secondary-color)" }}>
                                Select value
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.ai_agent && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : aiAgentList[filter?.values[0]]?.agent
                                      .agent_name +
                                    (filter?.values?.length > 1
                                      ? " +" + (filter?.values?.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            onScroll={onScroll}
                          >
                            {aiAgentList.map((agent, agentIndex) => (
                              <DropdownMenuItem
                                key={agentIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  changeAgentFilter(i, agentIndex);
                                }}
                                className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                  agent.selected
                                    ? "bg-[var(--hover-color)]"
                                    : ""
                                }`}
                              >
                                <Checkbox checked={agent.selected} />
                                <span className="flex items-center gap-2">
                                  {agent.agent?.profile_pic ? (
                                    <img
                                      src={agent.agent?.profile_pic}
                                      alt=""
                                      className="w-[18px] h-[18px] rounded-full"
                                    />
                                  ) : (
                                    <span className="agent-logo">
                                      <CustomIcon
                                        iconName="aiagents"
                                        width={12}
                                        height={12}
                                      />
                                    </span>
                                  )}
                                  {agent?.agent?.agent_name}
                                </span>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.call_type && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : callTypeList[filter.values[0]].text +
                                    (filter.values.length > 1
                                      ? " +" + (filter.values.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {callTypeList.map((callType, callTypeIndex) => (
                              <DropdownMenuItem
                                key={callTypeIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  changeCallTypeFilter(i, callTypeIndex);
                                }}
                                className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                  callType.selected
                                    ? "bg-[var(--hover-color)]"
                                    : ""
                                }`}
                              >
                                <Checkbox
                                  checked={callType.selected}
                                />
                                {callType.text}
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.call_rating && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : ratingsList[filter.values[0]].text +
                                    (filter.values.length > 1
                                      ? " +" + (filter.values.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {ratingsList.map((rating, ratingIndex) => (
                              <DropdownMenuItem
                                key={ratingIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  changeRatingFilter(i, ratingIndex)
                                }}
                                className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                  rating.selected
                                    ? "bg-[var(--hover-color)]"
                                    : ""
                                }`}
                              >
                                <Checkbox checked={rating.selected} />
                                {rating.text}
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.call_status && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : callStatusList[filter.values[0]].text +
                                    (filter.values.length > 1
                                      ? " +" + (filter.values.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {callStatusList.map((status, statusIndex) => (
                              <DropdownMenuItem
                                key={statusIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  changeCallStatusFilter(i, statusIndex)
                                }}
                                className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                  status.selected
                                    ? "bg-[var(--hover-color)]"
                                    : ""
                                }`}
                              >
                                <Checkbox checked={status.selected} />
                                <span className="hor-8px">
                                  <span
                                    className={status.text.toLowerCase()}
                                  ></span>
                                  {status.text}
                                </span>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.task_status && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : taskStatusList[filter.values[0]].text +
                                    (filter.values.length > 1
                                      ? " +" + (filter.values.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {taskStatusList.map((status, statusIndex) => (
                              <DropdownMenuItem
                                key={statusIndex}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  changeTaskStatusFilter(i, statusIndex)
                                }}
                                className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                  status.selected
                                    ? "bg-[var(--hover-color)]"
                                    : ""
                                }`}
                              >
                                <Checkbox checked={status.selected} />
                                <span className="hor-8px">
                                  <span
                                    className={status.text.toLowerCase()}
                                  ></span>
                                  {status.text}
                                </span>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.call_sentiment && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span
                                style={{
                                  color:
                                    filter.values?.length === 0
                                      ? "var(--secondary-color)"
                                      : "var(--primary-color)",
                                }}
                              >
                                {filter.values?.length === 0
                                  ? "Select value"
                                  : callSentimentList[filter.values[0]].text +
                                    (filter.values.length > 1
                                      ? " +" + (filter.values.length - 1)
                                      : "")}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {callSentimentList.map(
                              (sentiment, sentimentIndex) => (
                                <DropdownMenuItem
                                  key={sentimentIndex}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    changeCallSentimentFilter(i, sentimentIndex)
                                  }}
                                  className={`cursor-pointer hover:bg-[var(--hover-color)] rounded ${
                                    sentiment.selected
                                      ? "bg-[var(--hover-color)]"
                                      : ""
                                  }`}
                                >
                                  <Checkbox
                                    checked={sentiment.selected}
                                  />
                                  <span className="hor-8px">
                                    {sentiment.text === "Positive" && (
                                      <Smile size="20px" color="#16A34A" />
                                    )}
                                    {sentiment.text === "Neutral" && (
                                      <Meh size="20px" color="#2563EB" />
                                    )}
                                    {sentiment.text === "Negative" && (
                                      <Frown size="20px" color="#DC2626" />
                                    )}
                                    {sentiment.text}
                                  </span>
                                </DropdownMenuItem>
                              )
                            )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.call_duration && (
                      <td className="p-1 w-40">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full flex justify-between"
                            >
                              <span>
                                {filter.values[0] +
                                  "min - " +
                                  filter.values[1] +
                                  "min"}
                              </span>
                              <ChevronDown className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            style={{ overflow: "hidden" }}
                            className="w-[230px]"
                          >
                            <span className="w-[230px] flex flex-col gap-4 text-sm p-3">
                              <span className="flex items-center justify-between">
                                <span className="flex flex-col gap-2">
                                  <span style={{ fontWeight: 500 }}>From</span>
                                  <span>
                                    {filter.values[0]}{" "}
                                    <span
                                      style={{
                                        color: "var(--secondary-color)",
                                      }}
                                    >
                                      min
                                    </span>
                                  </span>
                                </span>
                                <span className="flex flex-col gap-2">
                                  <span style={{ fontWeight: 500 }}>To</span>
                                  <span>
                                    {filter.values[1]}{" "}
                                    <span
                                      style={{
                                        color: "var(--secondary-color)",
                                      }}
                                    >
                                      min
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <Slider
                                min={0}
                                max={60}
                                value={filter.values}
                                onValueChange={(values) => {
                                  durationStartChange(values[0], i);
                                  durationEndChange(values[1], i);
                                }}
                              />
                            </span>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                    )}
                    {filter.type === filterEnums.date && (
                      <td className="p-1 w-40"></td>
                    )}
                    {filter.type === filterEnums.from_number && (
                      <td className="p-1 w-40">
                        <Input
                          value={callFrom}
                          onChange={(e) => setCallFrom(e.target.value)}
                        />
                      </td>
                    )}
                    {filter.type === filterEnums.to_number && (
                      <td className="p-1 w-40">
                        <Input
                          value={callTo}
                          onChange={(e) => setCallTo(e.target.value)}
                        />
                      </td>
                    )}
                    {i !== 0 && (
                      <td className="p-1">
                        <Button variant="ghost" onClick={() => removeFilter(i)}>
                          <Trash2 className="h-5 w-5 text-gray-500" />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button variant="ghost" className="w-fit" onClick={addFilter}>
            <Plus size={16} className="mr-2" /> Add filter
          </Button>
        </div>
        <div className="flex justify-end space-x-2">
          <Button variant="ghost" onClick={clearFilters}>
            Clear Filters
          </Button>
          <Button onClick={applyFilter}>Apply Filters</Button>
        </div>
      </div>
    </div>
  );
};

export default CallFilter;
