import { addLabel, updateLabel } from "../../lib/voice.utils";
import React, { useEffect, useState } from "react";
import Label from "./Label";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";
import { Dialog, DialogContent } from "../shadcn/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import { ChevronDown } from "lucide-react";
import Toaster from "./customToast";

interface AddLabelProps {
  label?: {
    id?: number;
    name: string;
    color: string;
  };
  inbox: any;
  onClose: () => void;
  onAdded: (label: any) => void;
  onEdited: (label: any) => void;
}

const AddLabel: React.FC<AddLabelProps> = ({
  label,
  inbox,
  onClose,
  onAdded,
  onEdited,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [name, setName] = useState("");
  const [loaderAddLabel, setLoaderAddLabel] = useState(false);
  const [selectedColor, setSelectedColor] = useState("Gray");
  const labelColors: Record<string, any> = {
    Gray: { bg_color: "#F1F5F9", color: "#1E293B" },
    Red: { bg_color: "#FEE2E2", color: "#DC2626" },
    Orange: { bg_color: "#FFEDD5", color: "#EA580C" },
    Yellow: { bg_color: "#FEF9C3", color: "#CA8A04" },
    Green: { bg_color: "#DCFCE7", color: "#16A34A" },
    Blue: { bg_color: "#DBEAFE", color: "#1D4ED8" },
    Purple: { bg_color: "#F3E8FF", color: "#7E22CE" },
    Pink: { bg_color: "#FCE7F3", color: "#DB2777" },
  };

  useEffect(() => {
    if (label?.id) {
      setName(label.name);
      Object.keys(labelColors).forEach((color) => {
        if (label.color === labelColors[color].color.substring(1)) {
          setSelectedColor(color);
        }
      });
    }
  }, [label]);

  const createLabel = () => {
    setLoaderAddLabel(true);
    const input = {
      name,
      bg_color: labelColors[selectedColor].bg_color.substring(1),
      color: labelColors[selectedColor].color.substring(1),
    };

    if (label?.id) {
      // Update label logic here
      // Example:
      updateLabel(inbox, input, label.id)
        .then((resp) => {
          if (resp.data.code === 200) {
            onEdited(resp.data.data);
            setLoaderAddLabel(false);
          }
        })
        .catch((error) => {
          Toaster.error(error)
          console.error(error);
          setLoaderAddLabel(false);
        });
    } else {
      // Add label logic here
      // Example:
      addLabel(inbox, input)
        .then((resp) => {
          if (resp.data.code === 201) {
            onAdded(resp.data.data);
            setLoaderAddLabel(false);
          }
        })
        .catch((error) => {
          console.error(error);
          Toaster.error(error)
          setLoaderAddLabel(false);
        });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() =>{ setIsOpen(false); onClose();}}>
      <DialogContent className="p-6">
        <div className="flex justify-between items-center text-primary text-lg font-semibold">
          <span>{label?.id ? "Edit label" : "New label"}</span>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-1 w-64">
            <span className="text-primary text-sm font-medium">Name</span>
            <Input
              type="text"
              placeholder="e.g. interested"
              maxLength={32}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-300 rounded-lg p-2"
            />
          </div>
          <div className="flex flex-col gap-1 w-48">
            <span className="text-primary text-sm font-medium">Color</span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full flex justify-between font-normal"
                >
                  <span className="flex items-center gap-2">
                    <span
                      className="w-4 h-4 rounded-sm"
                      style={{ background: labelColors[selectedColor].color }}
                    ></span>
                    {selectedColor}
                  </span>
                  <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white border border-gray-300 w-full rounded-lg p-2 max-h-[300px] overflow-auto">
                {Object.entries(labelColors).map(([key, value]) => (
                  <DropdownMenuItem
                    key={key}
                    className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                    onSelect={() => setSelectedColor(key)}
                  >
                    <span
                      className="w-4 h-4 rounded-sm"
                      style={{ background: value.color }}
                    ></span>
                    {key}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <Button
            disabled={name === ""}
            onClick={createLabel}
            className="text-white rounded-lg px-4 py-2 disabled:bg-gray-300"
          >
            {loaderAddLabel
              ? "Loading..."
              : label?.id
              ? "Save"
              : "Create new label"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddLabel;
