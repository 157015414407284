import { logout } from "../../lib/common.utils";
import { LoggedUser } from "../../models/logged-user";
import useSharedStore from "../../store/shared.store";
import React, { useEffect, useState } from "react";
import { Button } from "../shadcn/button";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../lib/authentication.utils";

const Profile = () => {

  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );
  const sharedStore = useSharedStore();
  const navigate = useNavigate();

  useEffect(() => {
    getUserProfile()
      .then((resp) => {
        if (resp.data.code === 200) {
          localStorage.setItem("user", JSON.stringify(resp.data.data));
          setUser(new LoggedUser(resp.data.data));
          sharedStore.setLoggedUser(new LoggedUser(resp.data.data));
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  },[])
  return (
    <div className="w-full">
      <div className="h-16 px-4 border-b border-gray-300 flex items-center justify-between text-lg font-medium text-primary">
        <span className="flex items-center gap-2">Profile</span>
      </div>
      <div className="flex flex-col gap-4 p-6 border-b border-gray-300">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-primary">Name</span>
          <span className="text-sm text-secondary">{user?.name}</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-primary">Email</span>
          <span className="text-sm text-secondary">{user?.email}</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-primary">Business Name</span>
          <span className="text-sm text-secondary">{sharedStore?.business?.name}</span>
        </div>
      </div>
    </div>
  );
};

export default Profile;