import axios from 'axios';
import Toaster from '../components/shared/customToast';
import { logout } from '../lib/common.utils';
import { useNavigate } from 'react-router-dom';
import useSharedStore from '../store/shared.store';

const apiClient = axios.create({
  timeout: 30000, // Adjust timeout as needed
});

export const setupInterceptors = (navigate: ReturnType<typeof useNavigate>) => {
  apiClient.interceptors.request.use(
    (config) => {

      const apiEndpoint = useSharedStore.getState().apiUrl; // 🔥 Get latest API endpoint

      if (!apiEndpoint) {
        console.warn("API Endpoint is not set yet!");
      } else {
        config.baseURL = 'https://'+apiEndpoint; // ✅ Dynamically set base URL
      }

      // Skip adding base URL for fully qualified URLs or static assets
      if (!config.url!.includes("http") && !config.url!.includes("assets")) {
        config.baseURL = 'https://'+apiEndpoint;
      }
      // Add custom headers
      config.headers['ngrok-skip-browser-warning'] = process.env.REACT_APP_PRODUCTION ? 'false' : 'true';
      const token = sessionStorage.getItem('client_token') || localStorage.getItem('token');

      if (token) {
        // Check if the request has a 'skip' header
        if (!config.headers['skip']) {
          config.headers['Authorization'] = token;
          config.headers['Content-Type'] = 'application/json; charset=utf-8';
        }
      } else {
        // Clear storage and redirect to login if no token
        localStorage.clear();
      }

      // Set additional headers
      config.headers['ngrok-skip-browser-warning'] = process.env.REACT_APP_PRODUCTION
        ? 'false'
        : 'true';

      return config;
    },
    (error) => {
      // Handle request errors
      return Promise.reject(error);
    }
  );

  // Add response interceptor
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response: errorResponse } = error;

      let responseMessage = "";

      if (errorResponse) {
        const { status, data } = errorResponse;

        // Handle token expiration (401)
        if (status === 401) {
          const refreshToken = localStorage.getItem('refresh');
          if (refreshToken) {
            try {
              const refreshResponse = await axios.post(
                `${process.env.REACT_APP_HTTPS ? 'https' : 'http'}://${process.env.REACT_APP_API_URL}/refresh/`,
                { refresh: refreshToken }
              );
              const newToken = `JWT ${refreshResponse.data.access}`;
              localStorage.setItem('token', newToken);

              // Retry the original request with the new token
              error.config.headers['Authorization'] = newToken;
              return apiClient(error.config);
            } catch (refreshError) {
              Toaster.error("Session expired. Please log in again.");
              logout(navigate);
            }
          } else {
            responseMessage = data.message || data.detail || "Session expired. Please log in again.";
            Toaster.error(responseMessage);
            logout(navigate);
          }
        }

        // Handle forbidden (403)
        if (status === 403) {
          responseMessage = data?.message || data?.detail || "Access denied.";
          // Toaster.error(responseMessage);
        }

        // Handle server error (500)
        if (status === 500) {
          console.error("Server error:", data);
        }

        // Handle precondition failed (412)
        if (status === 412) {
          if (data.code === 1050) {
            navigate('/subscription-expired');
          } else {
            navigate('/onboarding');
          }
        }

        // Handle locked resource (423)
        if (status === 423) {
          // Toaster.error("Resource is locked. Logging out.");
          localStorage.clear();
          navigate('/login');
        }

        // Handle agency expired (424)
        if (status === 424) {
          navigate('/agency-expired');
        }

        // Handle other errors
        responseMessage = data.message || data.detail || "An error occurred.";
        // Toaster.error(responseMessage);
      }

      return Promise.reject(responseMessage);
    }
  );
};

const HandleAuthError = () => {
  const navigate = useNavigate();
  localStorage.removeItem('auth');
  navigate('/');
};

export default apiClient;
