import {
  deleteApiKey,
  generateApiKeys,
  getApiKeys,
} from "../../../lib/business.utils";
import { ApiKeys } from "../../../models/api-key";
import React, { useState, useEffect, useRef } from "react";
import Toaster from "../../shared/customToast";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../shadcn/tooltip";
import { Copy } from "lucide-react";
import { Button } from "../../shadcn/button";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import { DialogContent, Dialog } from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import useSharedStore from "../../../store/shared.store";

const ApiKeysComponent = () => {
  const [apiKeys, setApiKeys] = useState<ApiKeys | null>(null);
  const [newApiKeys, setNewApiKeys] = useState<ApiKeys | null>(null);
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [createConfirmation, setCreateConfirmation] = useState(false);
  const [loaderKeys, setLoaderKeys] = useState(false);

  const [accessKeyMessage, setAccessKeyMessage] = useState("Copy to clipboard");
  const [secretKeyMessage, setSecretKeyMessage] = useState("Copy to clipboard");

  const sharedStore = useSharedStore();
  
  useEffect(() => {
    getApiKey();
  }, []);

  const getApiKey = () => {
    setLoaderKeys(true);
    getApiKeys().then(
      (resp) => {
        setApiKeys(new ApiKeys(resp.data.data));
        setLoaderKeys(false);
      },
      (error) => {
        setLoaderKeys(false);
        Toaster.error(error);
      }
    );
  };

  const deleteKey = (id: number) => {
    deleteApiKey(id).then(
      (resp) => {
        setApiKeys(null);
        Toaster.success(resp.data.message);
      },
      (error) => {
        Toaster.error(error);
      }
    );
  };

  const generateApiKey = () => {
    setLoader(true);
    generateApiKeys().then(
      (resp) => {
        if (resp.data.code === 201) {
          setNewApiKeys(new ApiKeys(resp.data.data));
          console.log(newApiKeys);
          setPopup(true);
          Toaster.success(resp.data.message);
          getApiKey();
        }
        setLoader(false);
      },
      (error) => {
        Toaster.error(error);
        setLoader(false);
      }
    );
  };

  const copyMessage = (val: string) => {
    console.log(val);
    navigator.clipboard.writeText(val).then(() => {
      setTimeout(() => {
        if (val === newApiKeys?.access_key) {
          setAccessKeyMessage("Copied!");
        } else {
          setSecretKeyMessage("Copied!");
        }
      });
    }).catch((error) => {
      console.error("Failed to copy text: ", error);
    });
  };

  return (
    <div className="flex flex-col w-full h-full p-8 gap-4 overflow-auto">
      <div className="flex justify-between items-center text-left text-[var(--primary-color)] font-inter font-semibold text-lg leading-7">
        <span className="flex flex-col gap-1">
          <span>API Keys</span>
          <span className="text-left text-[var(--secondary-color)] font-inter text-sm leading-6 font-normal">
              Use your API key if you want to interact with our APIs via your
              Backend Systems.
            </span>
          </span>
        {(sharedStore.loggedUser?.user_type === 1 || sharedStore.permissions?.settings?.api_keys?.edit) && <Button onClick={() => setCreateConfirmation(true)}>
          {apiKeys?.id ? "Regenerate Key" : "Generate Key"}
        </Button>}
      </div>
      <div className="flex flex-col gap-4">
        {!loaderKeys ? (
          <>
            {apiKeys?.access_key && (
              <div className="flex flex-col gap-1 w-96">
                <span className="text-left text-[var(--primary-color)] font-inter text-sm font-medium leading-6">
                  Access key
                </span>
                <Input
                  type="text"
                  value={apiKeys.access_key}
                  disabled
                  className="p-2 border border-gray-300 rounded"
                />
              </div>
            )}
            {apiKeys?.access_secret && (
              <div className="flex flex-col gap-1 w-96">
                <span className="text-left text-[var(--primary-color)] font-inter text-sm font-medium leading-6">
                  Secret key
                </span>
                <Input
                  type="text"
                  value={apiKeys.access_secret}
                  disabled
                  className="p-2 border border-gray-300 rounded"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <span className="w-full">
              <div className="h-8 bg-gray-200 rounded"></div>
            </span>
            <span className="w-full">
              <div className="h-8 bg-gray-200 rounded"></div>
            </span>
          </>
        )}
      </div>

      {popup && (
        <Dialog open={popup} onOpenChange={() => setPopup(false)}>
          <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
            <div className="flex flex-col gap-1 w-96">
              <div className="flex justify-between items-center text-left text-[var(--primary-color)] font-inter font-semibold text-lg leading-7">
                <span>New API Key</span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-left text-[var(--primary-color)] font-inter text-sm font-medium leading-6">
                  Access key
                </span>
                <div className="flex items-center gap-4">
                  <input
                    type="text"
                    value={newApiKeys?.access_key ?? ""}
                    disabled
                    className="p-2 border border-gray-300 rounded w-full"
                  />
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Copy
                        className="cursor-pointer"
                        onClick={() =>
                          copyMessage(newApiKeys?.access_key ?? "")
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <span>{accessKeyMessage}</span>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-left text-[var(--primary-color)] font-inter text-sm font-medium leading-6">
                  Secret key
                </span>
                <div className="flex items-center gap-4">
                  <input
                    type="text"
                    value={newApiKeys?.access_secret ?? ""}
                    disabled
                    className="p-2 border border-gray-300 rounded w-full"
                  />
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Copy
                        className="cursor-pointer"
                        onClick={() =>
                          copyMessage(newApiKeys?.access_secret ?? "")
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <span>{secretKeyMessage}</span>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex justify-end p-2">
              <Button
                onClick={() => setPopup(false)}
                className="px-4 py-2 ext-white rounded"
              >
                Okay
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {createConfirmation && (
        <ConfirmationPopup
          positiveButtonText="Create"
          negativeButtonText="Cancel"
          heading="Are you sure you want to generate new keys?"
          description="Once you create new keys the previous keys will stop working."
          onClose={() => setCreateConfirmation(false)}
          onSuccess={() => {
            generateApiKey();
            setCreateConfirmation(false);
          }}
        />
      )}
    </div>
  );
};

export default ApiKeysComponent;
