export class GlobalStrings {
  
  /* Regex */
  public static websiteRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  public static templateVariableRegex: string = '{{[0-9][0-9]*}}';
  public static templateNewLineRegex: string = '\n\n\n'
  public static newUrlRegex: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  public static boldRegex: RegExp = /\*\*(.*?)\*\*/g;
  public static italicRegex: RegExp = /(?:^|\s)(_)([^_\s](?:(?=(\\?))\3.)*?[^_\s])_(?:\s|$)/g;
  public static strikeRegex: RegExp = /(?:^|\s)(~)(~?[^~\s](?:(?=(\\?))\3.)*?[^~\s])~(?:\s|$)/g;
  public static emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static templateNameRegex: RegExp = /^[A-Za-z0-9_-]+$/;

  /* Error string */
  public static invalidImageType: string = 'Only jpeg, jpg & png files are allowed';
  public static invalidImageSize: string = 'Please upload images less than 1MB';
  public static enterValidField: string = 'Enter {field} field';
  public static enterValidDetails: string = 'Enter valid {field}';
  public static minimumMinutes: string = 'Minimum 50 minutes needs to be added';
  public static validNumberCountryCode: string = 'Enter valid phone number with country code';

  //Hover info
  public static agentProfileImage = 'Supported size: 256 x 400px, max 1MB. Accepted formats: PNG, JPEG and JPG.';
  public static agentName = 'This is the name your AI will use in every conversation';
  public static agentTimezone = 'Set the timezone that the AI Agent will follow during calls for accurate time-based interactions';
  public static agentResponsiveness = 'Adjust the agent’s reply speed based on your audience. Higher: Ideal for younger users, quick responses. Lower: Suits older users, slower, more deliberate replies.'
  public static agentInteruption = 'Adjust AI sensitivity to human interruptions: High stops instantly, Low allows a few words before stopping.';
  public static agentPrompt = 'Provide AI agents with intelligence through information or use \'Co-pilot\' to assist with writing prompts.';


  public order_status: Record<number,string> = {
    1 : 'Pending',
    2 : 'Accepted',
    3 : 'Delivered',
    4 : 'Payment-pending',
    5 : 'Cancelled',
    6 : 'Processing',
    7 : 'On hold',
    8 : 'Completed',
    9 : 'Refunded',
    10 : 'Failed',
    11 : 'Unknown'
  }

  public analytics_help_text = {
    active: 'Total conversations which are yet to complete 24 hour window',
    open: 'Total conversations which are assigned to someone but not closed, may include conversations outside 24 hours window.',
    subscribed: 'Total conversations conusmed based on your Subscription',
    uic: 'A conversation starts when the first business message is delivered and ends 24 hours later. Conversais marked as UIC when first message sent to business is by your customer',
    status: {
      all:'Denotes your connection status with Facebook. Status can be Connected, disconnected etc',
      restricted: 'Your number has reached its 24-hour messaging limit and can no longer send messages to contacts. Please wait until the messaging limit is reset to send messages.',
      flagged: 'Your quality rating reaches to low state. You can\'t upgrade messaging limit tiers during the Flagged phase. If the message quality improves to a high or medium state by the seventh day from when your status was moved to Flagged, the status will return to Connected. If the quality rating doesn\'t improve, your status will still return to Connected, but you\'ll be placed in a lower',
    },
    quality_rating: 'This is rating of your account quality. Green is best, Red is worst',
    messaging_limit: 'This is per day conversation limit of your business. If you run campaign for 7 days rolling period to unique users upto your current BIC limit, your limit will be increased to next slot upto unlimited',
    new: 'Conversations by first time visitors of the business',
    monthly_free_uic: 'Free conversations consumed out of 1000. You will be charged for UIC after 1000 free conversations are over.',
    active_user: 'Total users who have read message sent by business.',
    interactive_user: 'Total users who have read your message and replied back.',
    direct_customers_added: 'Users who reached your business by sending first message on WhatsApp.',
    manual_customers_added: 'Users added by Excel sheet.',
    undelivered : 'Messages sent by business but not yet delivered to contact',
    delivered : 'Messages sent by business and delivered to contact',
    read : 'Messages sent by business and read by contact',
    received : 'Messages sent by cutomer  to business',
    failed : 'Messages failed to send by business.',
    conversation_type: 'Conversation type depends on type of message sent or received. It could be Marketing, Service, Utility or Authentication.',
    marketing : 'Total no of Conversations created by Marketing templates.',
    utility : 'Total no of Conversations created by Utility templates.',
    authentication : 'Total no of Conversations created Authentication templates',
    service : 'Total no of Conversations created by contact sending first message.',
    free_entry_point : 'The number of messaging conversations on WhatsApp that are free of charge because the conversations start from specific entry points, which include Ads that click to WhatsApp and Facebook Page CTAs. The conversation window for each free entry point conversation is 72-hours. Only the first conversation that starts from the entry point is free of charge. Subsequent conversations with the user are charged.',
    free : 'You are not charged for these conversations.',
    paid : 'You are charged for these conversations.',
    total : 'Total conversations including Free and Paid both',
  }

  public static AiAgentActions: Record<string,string> = {
    "user_hangup": "Call ended by the caller.",
    "agent_hangup": "Call ended by the AI Agent.",
    "call_transfer": "AI Agent transferred call to ",
    "voicemail_reached": "Voicemail detected",
    "inactivity": "Call ended by AI Agent.",
    "machine_detected": "Call ended by AI Agent.",
    "concurrency_limit_reached": "Call not Connected.",
    "dial_busy": "Call not Connected.",
    "dial_failed": "Call not Connected.",
    "dial_no_answer": "Call not Connected.",
    "error_llm_websocket_open": "Call not Connected.",
    "error_llm_websocket_lost_connection": "Call ended by AI Agent.",
    "error_llm_websocket_runtime": "Call ended by AI Agent.",
    "error_llm_websocket_corrupt_payload": "Call ended by AI Agent.",
    "error_frontend_corrupted_payload": "Call ended by AI Agent.",
    "error_twilio": "Call not Connected.",
    "error_no_audio_received": "Call ended by AI Agent.",
    "error_asr": "Call ended by AI Agent.",
    "error_retell": "Call ended by AI Agent.",
    "error_unknown": "Call ended by AI Agent.",
    "" : ""
  }
  
}

export const MediaContentTypes: Record<string, string> = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  rtf: "application/rtf",
  txt: "text/plain",
  csv: "text/csv",
  html: "text/html",
  bmp: "image/bmp",
  eml: "message/rfc822",
  epub: "application/epub+zip",
  heic: "image/heic",
  md: "text/markdown",
  msg: "application/vnd.ms-outlook",
  odt: "application/vnd.oasis.opendocument.text",
  org: "text/org",
  p7s: "application/pkcs7-signature",
  tiff: "image/tiff",
  tsv: "text/tab-separated-values",
  xml: "application/xml"
};

export enum GlobalEnums {
  male = 1,
  female = 2,
  last_week = 1,
  last_month = 2,
  custom = 3,
  inProgress = '2',
  scheduled = '1',
  completed = '3',
  failed = '4',
  cancelled = '5',
  preparing = '7',
  isPaused = '6',
  approved = 1,
  inReview = 0,
  rejected = 2,
  inAppeal = 5,
  paused = 7,
  disabled = 4,
  payment_success = 3001,
  payment_failed = 3002,
  payment_already_done = 3003,
  available = "available",
  busy = "busy",
  away = "away",
  dnd = "dnd",
  in_call = "in_call",
  tran_now = "tran_now",
  talk_first = "talk_first",
}


export enum TemplateHeaderTypes {
  image = 'IMAGE',
  video = 'VIDEO',
  text = 'TEXT',
  document = 'DOCUMENT',
  location = 'LOCATION'
}

export enum CallEnum {
  in_progress = 'in-progress',
  completed = 'completed',
  inbound = 'inbound',
  outbound = 'outbound',
  failed = 'failed',
  ringing = 'ringing',
  initiated = 'initiated',
  conference_end = 'conference-end',
  error = 'error',
  ended = 'ended',
  busy = 'busy',
  canceled = 'canceled',
  no_answer = 'no-answer',
  ongoing = 'ongoing',
  registered = 'registered',
  rejected = 'rejected',
}

interface CallStatusDetails {
  title: string;
  reason: string;
  subheading: string;
  color: string;
}

export const DisconnectionReasonData: Record<string, CallStatusDetails> = {
  user_hangup: {
    title: "Call completed",
    reason: "The contact ended the call.",
    subheading: "",
    color: "green",
  },
  agent_hangup: {
    title: "Call completed",
    reason: "The AI agent ended the call.",
    subheading: "",
    color: "green",
  },
  call_transfer: {
    title: "Call transferred",
    reason: "The AI agent transferred call.",
    subheading: "",
    color: "green",
  },
  voicemail_reached: {
    title: "Voicemail detected",
    reason: "The call ended because voicemail or a machine was detected.",
    subheading: "",
    color: "red",
  },
  machine_detected: {
    title: "Voicemail detected",
    reason: "The call ended because voicemail or a machine was detected.",
    subheading: "",
    color: "red",
  },
  inactivity: {
    title: "Silence detected",
    reason: "The call ended because the contact was silent for too long.",
    subheading: "",
    color: "red",
  },
  max_duration_reached: {
    title: "Max duration reached",
    reason: "The call ended because it reached the maximum time limit.",
    subheading: "",
    color: "red",
  },
  scam_detected: {
    title: "Spam detected",
    reason: "The call was flagged as a possible scam.",
    subheading: "Call was flagged as scam.",
    color: "red",
  },
  dial_busy: {
    title: "Busy",
    reason: "The dialed number was busy.",
    subheading: "The dialed number was busy.",
    color: "red",
  },
  dial_no_answer: {
    title: "No answer",
    reason: "The dialed number did not answer.",
    subheading: "The dialed number did not answer.",
    color: "red",
  },
  dial_failed: {
    title: "Call failed",
    reason: "Unable to connect due to an invalid number or spam blocking.",
    subheading: "Unable to connect.",
    color: "red",
  },
  concurrency_limit_reached: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  no_valid_payment: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_inbound_webhook: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_llm_websocket_open: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_llm_websocket_lost_connection: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_llm_websocket_runtime: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_llm_websocket_corrupt_payload: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_frontend_corrupted_payload: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_twilio: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_no_audio_received: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_asr: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_retell: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_unknown: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  error_user_not_joined: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
  registered_call_timeout: {
    title: "Call failed",
    reason:
      "Unable to connect due to a technical issue. Please contact support for assistance.",
    subheading: "Unable to connect.",
    color: "red",
  },
};

export enum DisconnectionReasons {
  user_hangup = "user_hangup",
  agent_hangup = "agent_hangup",
  call_transfer = "call_transfer",
  voicemail_reached = "voicemail_reached",
  machine_detected = "machine_detected",
  inactivity = "inactivity",
  max_duration_reached = "max_duration_reached",
  scam_detected = "scam_detected",
  dial_busy = "dial_busy",
  dial_no_answer = "dial_no_answer",
  dial_failed = "dial_failed",
  concurrency_limit_reached = "concurrency_limit_reached",
  no_valid_payment = "no_valid_payment",
  error_inbound_webhook = "error_inbound_webhook",
  error_llm_websocket_open = "error_llm_websocket_open",
  error_llm_websocket_lost_connection = "error_llm_websocket_lost_connection",
  error_llm_websocket_runtime = "error_llm_websocket_runtime",
  error_llm_websocket_corrupt_payload = "error_llm_websocket_corrupt_payload",
  error_frontend_corrupted_payload = "error_frontend_corrupted_payload",
  error_twilio = "error_twilio",
  error_no_audio_received = "error_no_audio_received",
  error_asr = "error_asr",
  error_retell = "error_retell",
  error_unknown = "error_unknown",
  error_user_not_joined = "error_user_not_joined",
  registered_call_timeout = "registered_call_timeout",
}


export enum UserStatus {
  active = 'active',
  invited = 'invited',
  removed = 'removed',
  available = 'available',
  away = 'away',
  incall = 'in_call',
}
