import React, { useState, useEffect } from "react";
import useWalletStore from "../../store/wallet.store";
import Toaster from "../shared/customToast";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { Input } from "../shadcn/input";
import { Button } from "../shadcn/button";

interface AutoRechargeProps {
  onClose: () => void;
  onAdded: (data: { minimumBalance: number; amount: number; enabled: boolean }) => void;
  enabled: boolean;
  minimumBalance: number;
  amount: number;
}

const AutoRecharge: React.FC<AutoRechargeProps> = ({ onClose, onAdded, enabled, minimumBalance: initialMinBalance, amount: initialAmount }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [minimumBalance, setMinimumBalance] = useState(initialMinBalance);
  const [amount, setAmount] = useState(initialAmount);

  const walletStore = useWalletStore();
  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    setMinimumBalance(minimumBalance)
    setAmount(amount) 
  }, [minimumBalance, amount])

  const addAutoRecharge = async () => {
    setIsLoading(true);
    try {      
      walletStore.addAutoRecharge({
        auto_add_mins: amount,
        trigger_mins: minimumBalance,
        enable_auto_recharge: true,
      }).then((resp) => {
        console.log(resp);
        setIsLoading(false);
        Toaster.success(resp.data.message);
        onAdded({ minimumBalance, amount, enabled: true });
        setIsOpen(false);
      }).catch((error) => {
        console.error(error);
        Toaster.error(error);
      })
    } catch (error: any) {
      console.error(error);
      
      setIsLoading(false);
    }
  };

  const disableAutoRecharge = async () => {
    setDisableLoading(true);
    try {
      walletStore.disableAutoRecharge().then((resp) => {
        setDisableLoading(false);
        Toaster.success(resp.data.message);
        onAdded({ minimumBalance: 0, amount: 0, enabled: false });
      }).catch((error) => {
        console.error(error);
        Toaster.error(error);
        setDisableLoading(false);
      });
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      setDisableLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => { setIsOpen(false); onClose(); }}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-[450px]">
        <div className="text-[var(--primary-color)] font-inter font-semibold text-24 leading-30">
          <span>Set auto-recharge</span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              When minute balance goes below
            </span>
            <Input
              type="number"
              placeholder="e.g. 100"
              value={minimumBalance}
              onChange={(e) => setMinimumBalance(Number(e.target.value))}
            />
            <span className="text-gray-400 text-sm">Minimum: 60 minutes</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              Auto-recharge minute balance of
            </span>
            <Input
              type="number"
              placeholder="e.g. 500"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            <span className="text-gray-400 text-sm">Minimum: 60 minutes</span>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          {enabled && (
            <Button variant="secondary" disabled={disableLoading} onClick={disableAutoRecharge}>
              {disableLoading ? "Disabling..." : "Disable"}
            </Button>
          )}
          <Button disabled={isLoading} onClick={addAutoRecharge}>
            {isLoading ? "Loading..." : "Set auto-recharge"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AutoRecharge;
