import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import ToasterService from './services/ToasterService';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../shadcn/input-otp"
import { Input } from '../shadcn/input';
import { Button } from '../shadcn/button';
import { Loader2 } from 'lucide-react';
import { oneInboxSendOtp, oneInboxLogin } from '../../lib/authentication.utils';
import { useAgencyStore } from './../../store/agency.store';
import { useSharedStore } from '../../store/shared.store';
import { LoggedUser } from '../../models/logged-user';
import { getInboxesData, getStripeKeys, getWidgetsData } from '../../lib/common.utils';



const Login = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [verifyOtpScreen, setVerifyOtpScreen] = useState(false);
  const [otp, setOtp] = useState('');
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [loaderSendOtp, setLoaderSendOtp] = useState(false);
  const [loaderEmailLogin, setLoaderEmailLogin] = useState(false);
  const [emailRetryCount, setEmailRetryCount] = useState(0);

  const navigate = useNavigate();

  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();

  useEffect(() => {
    // Check for token on component mount
    const token = (localStorage.getItem('token') || sessionStorage.getItem('client_token'));
    if (token && token.length > 10) {
      navigate('/agents');
    }
  }, [navigate]);

  // Start countdown for OTP resend
  useEffect(() => {
    if (emailRetryCount > 0) {
      const timer = setInterval(() => {
        setEmailRetryCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer); // Cleanup
    }
  }, [emailRetryCount]);

  const sendOtp = () => {
    const input = { email };
    setLoaderSendOtp(true);
    oneInboxSendOtp(input).then(
      () => {
        setVerifyOtpScreen(true);
        setLoaderSendOtp(false);
        setEmailRetryCount(30);
      },
      (error: { message: any; }) => {
        setLoaderSendOtp(false);
      }
    );
  };

  const verifyOtp = () => {
    const input = { email, otp };
    setLoaderEmailLogin(true);
    oneInboxLogin(input).then(
      (resp: any) => {
        successfulLogin(resp);
        setLoaderEmailLogin(false);
      },
      (error: any) => {
        setLoaderEmailLogin(false);
      }
    );
  };

  const successfulLogin = (resp: any) => {
    console.log(resp);
    if (resp.data?.token) {
      const token = `JWT ${resp.data.token.access}`;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh', resp.data.token.refresh);
    }

    if (resp.data?.user) {
      const user = resp.data.user;
      localStorage.setItem('username', user.name || '');
      localStorage.setItem('user', JSON.stringify(user));
      sharedStore.setLoggedUser(new LoggedUser(user));
    }

    if (resp.data?.business) {
      const business = resp.data.business;
      localStorage.setItem('business', JSON.stringify(business));      
      sharedStore.setBusiness(business);
      getInboxesData();
      getWidgetsData();
      getStripeKeys();
    } 
    else {
      navigate('/onboarding');
      return;
    }

    navigate('/agents');
  };

  const handleOtpChange = (value: any) => {
    setOtp(value);
    setIsValidOtp(value.length === 6);
  };

  const backToLogin = () => {
    setOtp('');
    setIsValidOtp(false);
    setVerifyOtpScreen(false);
  };

  return (
    <div className="w-full h-screen flex">
      {/* Left Container */}
      <div className="w-1/2 flex justify-center items-center bg-primary">
        <div className="flex justify-center items-center gap-1 font-inter text-4xl font-medium text-gray-50">
          <img src={agencyStore.lightLogo} alt="" className="h-16" />
        </div>
      </div>

      {/* Right Container */}
      <div className="w-1/2">
        <div className="flex items-center justify-center min-h-screen bg-white">
          {!verifyOtpScreen ? (
            <div className="flex flex-col gap-6 p-6 bg-white rounded-lg w-96">
              <div className="flex flex-col items-center gap-1">
                <h2 className="text-2xl font-semibold text-primary">Welcome back!</h2>
                <p className="text-sm text-secondary">It’s great to see you 👋</p>
                <p className="text-sm text-secondary">Log in to your account below</p>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium text-primary">Email</label>
                <Input
                  placeholder="m@example.com"
                  disabled={false}
                  type="email"
                  value={email}
                  onKeyDown={(e) => {
                    if(e.key === 'Enter') {
                      sendOtp();
                    }
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <Button variant="default" onClick={sendOtp} disabled={loaderSendOtp}>
                {loaderSendOtp ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Please wait
                  </>
                ) : (
                  "Continue"
                )}
              </Button>
              <p className="text-center text-sm text-secondary">
                By clicking continue, you agree to our{" "}
                <a
                  href={agencyStore.termsConditions === '' ? 'https://oneinbox.ai/terms' : agencyStore.termsConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href={agencyStore.privacyPolicy === '' ? 'https://oneinbox.ai/terms' : agencyStore.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <div className="flex flex-col items-center gap-1">
                <p className="text-sm text-secondary">Don’t have an account yet?</p>
                <a href="/signup" className="text-primary text-base font-medium hover:underline">
                  Sign up for free
                </a>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-6 p-6 bg-white rounded-lg w-96">
              <div className="flex flex-col items-center gap-1">
                <h2 className="text-2xl font-semibold text-primary">Verify email</h2>
                <p className="text-sm text-secondary">Enter the 6-digit code we just sent to</p>
                <p className="text-sm text-secondary">
                  <span className="text-primary">{email}</span> to continue.
                </p>
              </div>
              <div className="flex justify-center">
                <InputOTP maxLength={6} value={otp} onKeyDown={(e) => {
                  if(e.key === 'Enter') {
                    verifyOtp();
                  }
                }} onChange={(e) => setOtp(e)}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
              <Button onClick={verifyOtp} disabled={loaderEmailLogin}>
                {loaderEmailLogin ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Please wait
                  </>
                ) : (
                  "Verify"
                )}
              </Button>
              <Button variant="outline" onClick={backToLogin}>
                Go back
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
