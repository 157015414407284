import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "../shadcn/dialog";
import Toaster from "../shared/customToast";
import { Input } from "../shadcn/input";
import { InfoBase } from "../../models/infobase";
import { AiAgent } from "../../models/ai-agent";
import { Checkbox } from "../shadcn/checkbox";
import { Button } from "../shadcn/button";
import {
  attachInfoBase,
  getActiveInfoBaseFile,
  searchActiveInfoBaseFile,
} from "../../lib/infobase.utils";
import { Skeleton } from "../shadcn/skeleton";
import { Brain } from "lucide-react";

interface AttachInfobaseProps {
  onClose: () => void;
  assignedInfobaseList: any[];
  agent: AiAgent;
  onInfoAttach: (infoBaseIds: InfoBase[]) => void;
}
const AttachInfobase: React.FC<AttachInfobaseProps> = ({
  onClose,
  assignedInfobaseList,
  agent,
  onInfoAttach,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [infobaseList, setInfobaseList] = useState<InfoBase[]>([]);
  const [infoSet, setInfoSet] = useState<Set<number>>(new Set());
  const [initialInfoSet, setInitialInfoSet] = useState(new Set());
  const [loaderInfobaseList, setLoaderInfobaseList] = useState(false);
  const [loaderAssignInfo, setLoaderAssignInfo] = useState(false);
  const [infoSearchTag, setInfoSearchTag] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageNext, setPageNext] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(assignedInfobaseList)
    setInfoSet(
      new Set(assignedInfobaseList.map((infobase) => infobase.info_base_id))
    );
    setInitialInfoSet(
      new Set(assignedInfobaseList.map((infobase) => infobase.info_base_id))
    );
    fetchLabels(1);
  }, [assignedInfobaseList]);

  const fetchLabels = (page: number) => {
    setLoaderInfobaseList(true);
    try {
      getActiveInfoBaseFile(page, true)
        .then((response) => {
          if (response.data.code === 200) {
            setPageNext(response.data.next ? true : false);
            let responseList: InfoBase[] = [];
            response.data.data?.forEach((element: any) => {
              responseList.push(new InfoBase(element));
            });
            setInfobaseList(
              page === 1 ? responseList : [...infobaseList, ...responseList]
            );
            setPageNumber((prev) => prev + 1);
          }
          setLoaderInfobaseList(false);
        })
        .catch((error) => {
          console.error(error);
          setLoaderInfobaseList(false);
        });
    } catch (error) {
      console.error(error);
      setLoaderInfobaseList(false);
    }
  };

  const fetchSearchedLabels = (page: number) => {
    setLoaderInfobaseList(true);

    searchActiveInfoBaseFile(infoSearchTag, page, true)
      .then((response) => {
        if (response.data.code === 200) {
          setPageNext(response.data.next ? true : false);
          let responseList: InfoBase[] = [];
          response.data.data?.forEach((element: any) => {
            responseList.push(new InfoBase(element));
          });
          setInfobaseList(
            page === 1 ? responseList : [...infobaseList, ...responseList]
          );
          setPageNumber((prev) => prev + 1);
        }
        setLoaderInfobaseList(false);
      })
      .catch((error) => {
        console.error(error);
        setLoaderInfobaseList(false);
      });
  };

  const handleSearch = (event: any) => {
    setInfoSearchTag(event.target.value);
    if (event.target.value.length > 2) {
      fetchSearchedLabels(1);
    } else {
      fetchLabels(1);
    }
  };

  const handleLabelClick = (id: number) => {
    setInfoSet((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) newSet.delete(id);
      else newSet.add(id);
      return newSet;
    });

  };

  const handleAssign = async () => {
    if (
      infoSet.size === initialInfoSet.size &&
      [...infoSet].every((x) => initialInfoSet.has(x))
    ) {
      onClose();
      return;
    }
    setLoaderAssignInfo(true);
    attachInfoBase({ info_base_ids: [...infoSet] }, agent.id)
      .then((response) => {
        if (response.data.code === 200) {
          Toaster.success(response.data.message);
          let infobaseArray: InfoBase[] = [];
          infobaseArray = assignedInfobaseList.filter(a => !infobaseList.map(b=>b.id).includes(a.id));
          infobaseList?.forEach((infobase)=>{
            if(infoSet.has(infobase.id)){
              infobaseArray.push(infobase);
            }
          })
          onInfoAttach(infobaseArray);
          onClose();
        } else {
          Toaster.error(response.data.message);
        }
        setLoaderAssignInfo(false);
      })
      .catch((error) => {
        console.error(error);
        setLoaderAssignInfo(false);
        Toaster.error(error);
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="spartan-popup">
        <div className="flex flex-col gap-4">
          <div className="text-[var(--primary-color)] font-inter font-semibold text-[20px] leading-30">
            <span>Attachments</span>
            <p className="text-gray-500 text-[14px]">
              Select attachments that have been uploaded to the infobase.
            </p>
          </div>
          <Input
            placeholder="Search"
            value={infoSearchTag}
            onChange={handleSearch}
          />
          {infobaseList.length > 0 && <div className="border border-[var(--border-color)] rounded-[8px] max-h-[300px] overflow-y-auto">
            {infobaseList.map((infobase) => (
              <div
                key={infobase.id}
                className={`h-[53px] px-4 gap-4 flex items-center cursor-pointer border-b border-[var(--border-color)] hover:bg-[var(--hover-color)] last:border-none ${
                  infoSet.has(infobase.id) ? "bg-[var(--hover-color)]" : ""
                }`}
                onClick={() => handleLabelClick(infobase.id)}
              >
                <Checkbox checked={infoSet.has(infobase.id)} />
                <span className="flex items-center gap-3"><Brain size={"16px"} />  {infobase.name}</span>
              </div>
            ))}
          </div>}
          {infobaseList.length === 0 && !loaderInfobaseList && (
            <p className="h-[200px] flex justify-center items-center">
              {infoSearchTag.length > 1
                ? "No infobase found matching your search"
                : "Start uploading files to get started."}
            </p>
          )}
          {loaderInfobaseList && (
            <div className="w-full gap-4 flex flex-col max-h-[300px] overflow-y-auto">
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={index} className="h-8 w-full" />
              ))}
            </div>
          )}
          <div className="flex justify-between items-center">
            <Button variant="outline" onClick={() => navigate("/infobase")}>
              Upload New File
            </Button>
            <Button onClick={handleAssign} disabled={loaderAssignInfo}>
              {loaderAssignInfo ? "Adding..." : "Add Attachment"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AttachInfobase;
