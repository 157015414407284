import React, { useState } from "react";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";
import { AiAgent } from "../../models/ai-agent";
import { addAgent } from "../../lib/agents.utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../shadcn/dropdown-menu";
import useSharedStore from "../../store/shared.store";
import { ScrollArea } from "../shadcn/scrollArea";
import { ChevronDown } from "lucide-react";
import Toaster from "../shared/customToast";
// import { Toaster, toast } from "react-hot-toast";

interface AddAgentProps {
  onClose: () => void, 
  onAdd: (agent: AiAgent) => void, 
}

const AddAgent: React.FC<AddAgentProps> = ({ 
  onClose,
  onAdd,
 }) => {
  const [name, setName] = useState("");
  const [language, setLanguage] = useState({ label: "English (US)", code: "en-US" });
  const [isLoading, setIsLoading] = useState(false);

  const sharedStore = useSharedStore();

  const addBlankAgent = async () => {
    setIsLoading(true);
    const input = {
      agent_name: name,
      language: language.code,
    };

    addAgent(input).then((resp) => {
      onAdd(new AiAgent(resp.data.data));
      setIsLoading(false);
      Toaster.success(resp.data.message);
      onClose();
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
      Toaster.error(error);
    });
  };

  return (
    <div className="fixed inset-0 z-50 flex w-full">
      <div className="rounded-lg w-full max-w-md p-6 space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">Create New Agent</h2>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Agent Name</label>
            <Input
              placeholder="Enter agent name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Language</label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full flex justify-between items-center px-4 py-2 border border-gray-300 rounded-md bg-white text-left focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  {language.label} <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-full w-[400px]">
                <ScrollArea className="flex flex-col max-h-[300px] overflow-auto">  
                  {sharedStore.languageList.map((lang) => (
                    <DropdownMenuItem
                      key={lang.code}
                      onClick={() => setLanguage(lang)}
                      className="cursor-pointer"
                    >
                      {lang.label}
                    </DropdownMenuItem>
                  ))}
                </ScrollArea>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <Button
            variant="default"
            disabled={!name || isLoading}
            onClick={addBlankAgent}
          >
            {isLoading ? "Loading..." : "Add Agent"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;