import { BillingDetails } from "../../models/billing-details";
import React, { useState, useEffect } from "react";
import Toaster from "./customToast";
import { Dialog, DialogContent } from "../shadcn/dialog";
import { Input } from "../shadcn/input";
import { Button } from "../shadcn/button";
import { useSubscriptionStore } from "../../store/subscription.store";
import useSharedStore from "../../store/shared.store";

interface BillingFormProps {
  onClose: () => void;
  onSave: (billingDetails: BillingDetails) => void;
}

const BillingForm: React.FC<BillingFormProps> = ({ onClose, onSave }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [defaultBillingDetails, setDefaultBillingDetails] = useState<BillingDetails>(new BillingDetails({
    address_line_1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    address_line_1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  })
  const subscriptionStore = useSubscriptionStore();
  const sharedStore = useSharedStore();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      let input = {
        billing_name: sharedStore.business?.name,
        address_line_1: billingDetails.address_line_1,
        zip: billingDetails.zip,
        state: billingDetails.state,
        city: billingDetails.city,
        country: billingDetails.country,
        default: true,
      };
      subscriptionStore.addBillingDetails(input).then((response) => {
        console.log(response);
        // setDefaultBillingDetails(new BillingDetails(response.data.data));
        subscriptionStore.setBillingDetails([new BillingDetails(response.data.data)]);
        Toaster.success(response.data.message);
        setIsLoading(false);
        setIsOpen(false);
        onSave(new BillingDetails(response.data.data));
      }).catch((error) => { 
        console.error(error);
        setIsLoading(false);
        Toaster.error(error);
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const isSaveDisabled = Object.values(billingDetails).some((value) => (value ?? "")?.trim() === "");

  return (
    <Dialog open={isOpen} onOpenChange={() => { setIsOpen(false); onClose(); }}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
        <div className="flex justify-between items-center text-[var(--primary-color)] font-inter font-semibold text-20 leading-30">
          <span>Billing Address</span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              Address
            </span>
            <Input
              type="text"
              placeholder="Address"
              value={billingDetails.address_line_1}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  address_line_1: e.target.value,
                })
              }
            />
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col gap-1 w-200px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              City
              </span>
              <Input
              type="text"
              value={billingDetails.city}
              onChange={(e) =>
                setBillingDetails({
                ...billingDetails,
                city: e.target.value,
                })
              }
              />
            </div>
            <div className="flex flex-col gap-1 w-200px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
                State
              </span>
              <Input
                type="text"
                placeholder="State"
                value={billingDetails.state}
                onChange={(e) =>
                  setBillingDetails({
                    ...billingDetails,
                    state: e.target.value,
                    })
                }
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col gap-1 w-200px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
                Country
              </span>
              <Input
                type="text"
                placeholder="Country"
                value={billingDetails.country}
                onChange={(e) =>
                  setBillingDetails({
                    ...billingDetails,
                    country: e.target.value,
                    })
                }
              />
            </div>
            <div className="flex flex-col gap-1 w-200px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
                ZIP Code
              </span>
              <Input
                type="text"
                placeholder="ZIP Code"
                value={billingDetails.zip}
                onChange={(e) =>
                  setBillingDetails({
                    ...billingDetails,
                    zip: e.target.value,
                    })
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <Button disabled={isSaveDisabled || isLoading} onClick={handleSave}>
            {isLoading ? "Saving..." : "Confirm Address"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BillingForm;
