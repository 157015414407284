// utils/navigation.utils.ts
let globalNavigate: (path: string) => void;

export const setGlobalNavigate = (navigate: (path: string) => void): void => {
  globalNavigate = navigate;
};

export const navigateTo = (path: string): void => {
  if (globalNavigate) {
    globalNavigate(path);
  } else {
    console.error('Navigate function is not set');
  }
};
