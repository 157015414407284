import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../shadcn/input';
import { Button } from '../shadcn/button';
import { Loader2 } from 'lucide-react';
import { oneInboxSendOtp, oneInboxLogin, oneInboxOnboarding } from '../../lib/authentication.utils';
import { useAgencyStore } from '../../store/agency.store';
import { useSharedStore } from '../../store/shared.store';
import { getBusinessData, getInboxesData, getStripeKeys, getWidgetsData, logout } from '../../lib/common.utils';
import Toaster from '../shared/customToast';



const Onboarding = () => {

  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const agencyStore = useAgencyStore();
  const sharedStore = useSharedStore();

  const profileSave = () => {
    if(name === '' || businessName === '' || loader) return ;
    let input: Record<string,any> = {
      name: name,
      business_name: businessName,
    }
    setLoader(true);
    oneInboxOnboarding(input).then((resp) => {
      Toaster.success(resp.data.message);
      setLoader(false);
      getStripeKeys();
      getBusinessData();
      navigate('/welcome');
    }).catch((error: string) => {
      console.log(error);
      setLoader(false);
      Toaster.error(error);
    })
  }

  const logoutAccount = () => {
    logout(navigate);
  }


  return (
    <div className="w-full h-screen flex">
      {/* Left Container */}
      <div className="w-1/2 flex justify-center items-center bg-primary">
        <div className="flex justify-center items-center gap-1 font-inter text-4xl font-medium text-gray-50">
          <img src={agencyStore.lightLogo} alt="" className="h-16" />
        </div>
      </div>

      {/* Right Container */}
      <div className="w-1/2">
        <div className="position-relative flex items-center justify-center min-h-screen bg-white">
          <Button variant="ghost" onClick={logoutAccount} style={{ position: "absolute", top: "2rem", right: "2rem" }}>Logout</Button>
          <div className="flex flex-col gap-6 p-6 bg-white rounded-lg w-96">
            <div className="flex flex-col gap-1">
              <h2 className="text-2xl font-semibold text-primary">Your details</h2>
              <p className="text-sm text-secondary">Enter your full name and business name to create your account</p>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium text-primary">Full name</label>
              <Input
                placeholder=""
                disabled={false}
                type="text"
                value={name}
                onKeyDown={(e) => {
                  if(e.key === 'Enter') {
                    profileSave();
                  }
                }}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium text-primary">Business name</label>
              <Input
                placeholder=""
                disabled={false}
                type="text"
                value={businessName}
                onKeyDown={(e) => {
                  if(e.key === 'Enter') {
                    profileSave();
                  }
                }}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <Button variant="default" onClick={profileSave} disabled={loader}>
              {loader ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Please wait
                </>
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
