// src/router.tsx
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Login from './components/Auth/Login';
import Agents from './components/Agents/Agents';
import AgentDetails from './components/Agents/AgentView';
import AgentView from './components/Agents/AgentView';
import Contacts from './components/Contacts/Contacts';
import Infobase from './components/Infobase/Infobase';
import Clients from './components/Clients/Clients';
import Settings from './components/Settings/Settings';
import PhoneNumbers from './components/Settings/PhoneNumber/PhoneNumber';
import Widgets from './components/Settings/Widget/Widgets';
import IntegrationsList from './components/Settings/Integrations/IntegrationsList';
import GhlView from './components/Settings/Integrations/GhlView';
import GoogleCalendarView from './components/Settings/Integrations/GoogleCalendarView';
import CalDotComView from './components/Settings/Integrations/CalDotComView';
import CustomTools from './components/Settings/CustomTools/CustomTools';
import WebhookLogs from './components/shared/WebhookLogs';
import Webhooks from './components/Settings/Webhooks/Webhooks';
import ApiKeysComponent from './components/Settings/ApiKeys/ApiKeys';
import WalletComponent from './components/Billing/Wallet';
import Calls from './components/Calls/Calls';
import Analytics from './components/Analytics/Analytics';
import SignUp from './components/Auth/SignUp';
import Onboarding from './components/Auth/Onboarding';
import WelcomeComponent from './components/Auth/Welcome/Welcome';
import SubscriptionComponent from './components/Billing/Subscription';
import Users from './components/Settings/Users/Users';
import Profile from './components/Profile/Profile';
import SubscriptionExpired from './components/shared/SubscriptionExpired';
import UserInvite from './components/Auth/UserInvite';
import GettingStarted from './components/GettingStarted/GettingStarted';
import HlOnboarding from './components/Auth/HlOnboarding';
import GoogleLogin from './components/Auth/GoogleLogin';
import PartnerPaymentProcessing from './components/Auth/PartnerPaymentProcessing';
import PaymentProcessing from './components/Auth/PaymentProcessing';
import AgencyExpired from './components/shared/AgencyExpired';
// import AuthGuard from './guards/AuthGuard';

// Define the routes
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Login /> }, // Default to login if the path is empty
      { path: 'login', element: <Login /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'onboarding', element: <Onboarding /> },
      { path: 'welcome', element: <WelcomeComponent /> },
      {
        path: 'agents',
        element: 
        // <AuthGuard>
          <Agents />,
        // {/* </AuthGuard>, */}
        // children: [
        //   { path: ':id', element: <AgentView agent={undefined} testCall={undefined} sharedDataService={undefined} /> },
        // ],
      },
      {
        path: 'inbox',
        element: <Calls />, // Replace with your actual Calls component
      },
      {
        path: 'inbox/:id',
        element: <Calls />, // Replace with your actual Calls component
      },
      {
        path: 'infobase',
        element: <Infobase />,
      },
      {
        path: 'billing/wallet',
        element: <WalletComponent />,
      },
      {
        path: 'billing/subscription',
        element: <SubscriptionComponent />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'hl-onboarding',
        element: <HlOnboarding />,
      },
      {
        path: 'google-login',
        element: <GoogleLogin />,
      },
      {
        path: 'agency-expired',
        element: <AgencyExpired />,
      },
      {
        path: 'getting-started',
        element: <GettingStarted />,
      },
      {
        path: 'invite',
        element: <UserInvite />,
      },
      {
        path: 'invite/:id',
        element: <UserInvite />,
      },
      {
        path: 'subscription-expired',
        element: <SubscriptionExpired />,
      },
      {
        path: 'onboarding-plans',
        element: <SubscriptionExpired />
      },
      {
        path: 'payment-processing',
        element: <PaymentProcessing />
      },
      {
        path: 'payment-verification',
        element: <PartnerPaymentProcessing />
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: 'phone-numbers',
            element: <PhoneNumbers />,
            children: [
              { path: ':id', element: <PhoneNumbers /> },
            ],
          },
          {
            path: 'widgets',
            element: <Widgets />,
            children: [
              { path: ':id', element: <Widgets /> },
            ],
          },
          {
            path: 'users',
            element: <Users />,
            children: [
              { path: ':id', element: <Users /> },
            ],
          },
          {
            path: 'custom-tools',
            element: <CustomTools />,
          },
          {
            path: 'webhooks',
            element: <Webhooks />,
          },
          // {
          //   path: 'agency',
          //   element: <AgencyComponent />,
          //   // canActivate: [RoleGuard], // Uncomment and implement RoleGuard if needed
          // },
          {
            path: 'api-keys',
            element: <ApiKeysComponent />,
            // canActivate: [RoleGuard], // Uncomment and implement RoleGuard if needed
          },
          {
            path: 'integrations/ghl',
            element: <GhlView />,
          },
          {
            path: 'integrations/google-calendar',
            element: <GoogleCalendarView />,
          },
          {
            path: 'integrations/cal-dot-com',
            element: <CalDotComView />,
          },
          {
            path: 'integrations',
            element: <IntegrationsList />,
          },
        ],
      },
      { 
        path: 'contacts', 
        element: 
        //<AuthGuard>
          <Contacts />
        //</AuthGuard>
      },
    ],
  },
  // { path: '*', element: <NotFound /> }, // 404 route
]);

export default router;
