import { connectCal } from "../../lib/integrations.utils";
import React, { useState, useEffect } from "react";
import Toaster from "./customToast";
import { Dialog, DialogContent } from "../shadcn/dialog";
import useSharedStore from "../../store/shared.store";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";

interface CalConnectionProps {
  onClose: () => void;
  onSuccess: () => void;
}

const CalConnection: React.FC<CalConnectionProps> = ({ onClose, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const sharedStore = useSharedStore();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const connect = async () => {
    setIsLoading(true);
    const input = { secret: apiKey };

    connectCal(input).then((resp) => {
      setIsLoading(false);
      Toaster.success(resp.data.message);
      onSuccess()
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
      Toaster.error(error);
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={() =>{ setIsOpen(false); onClose();}}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
        <div className="flex justify-between items-center text-[var(--primary-color)] font-inter font-semibold text-20 leading-30">
          <span>Connect Cal.com</span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              API key
            </span>
            <Input
              type="text"
              placeholder=""
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <span className="warning">Ensure that the API key is set to "Never Expires."</span>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <Button disabled={isLoading} onClick={connect}>
            {isLoading ? "Loading..." : "Connect"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CalConnection;
