import React, { useEffect, useState } from "react";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../shadcn/chart";
import {
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  Label,
} from "recharts";
import { Calendar } from "../shadcn/calendar";
import { GlobalStrings } from "../../lib/constants";
import DateDropdown from "../shared/DateDropdown";
import {
  getActionTypes,
  getAgents,
  getAverageTalktimeUsageCount,
  getBusyHours,
  getCallDirection,
  getCallRating,
  getCallSentiments,
  getCallTrend,
  getConnectedNotConnectedCalls,
  getEndCallReasons,
  getPhoneNumbers,
  getTaskStatus,
  getUniqueRepeatCustomers,
  getWidgets,
} from "../../lib/analytics.utils";
import { Skeleton } from "../shadcn/skeleton";
import CustomIcon from "../shared/CustomIcon";
import { toPascalCase } from "../../lib/ui.utils";
import { CalendarDays, X } from "lucide-react";
import { Switch } from "../shadcn/switch";

const Analytics = () => {
  const [params, setParams] = useState({ from_date: "", to_date: "" });
  const [last7Days, setLast7Days] = useState({ from_date: "", to_date: "" });
  const [toggleView, setToggleView] = useState(true);
  const [demoDataEnabled, setDemoDataEnabled] = useState<boolean>(false);

  const [loaderTalkTime, setLoaderTalkTime] = useState<boolean>(false);
  const [talkTime, setTalkTime] = useState({
    total_calls: 0,
    avg_talk_time: 0,
    avg_usage_time: 0,
    usage_time: 0,
  });

  //Line chart data
  const [loaderCallDirectionData, setLoaderCallDirectionData] =
    useState<boolean>(false);
  const [callDirectionData, setCallDirectionData] = useState<any[]>([]);
  const [callDirectionDemoData, setCallDirectionDemoData] = useState<any[]>([
    { "date": "Feb 1", "inbound": 20, "outbound": 120 },
    { "date": "Feb 2", "inbound": 60, "outbound": 140 },
    { "date": "Feb 3", "inbound": 40, "outbound": 60 },
    { "date": "Feb 4", "inbound": 80, "outbound": 80 },
    { "date": "Feb 5", "inbound": 140, "outbound": 100 },
    { "date": "Feb 6", "inbound": 100, "outbound": 120 },
    { "date": "Feb 7", "inbound": 120, "outbound": 140 }
  ]
  );
  const [isCallDirectionEmpty, setIsCallDirectionEmpty] = useState(false);

  useEffect(() => {
    console.log(window.innerWidth);
    let totalWidth = window.innerWidth;
    totalWidth = totalWidth - 280 - 2 * 32 - 2 * 16 - 2 * 24 - 200;
    // setPieChartGraph({ view: [totalWidth / 3, totalWidth / 3] });
    // setPieChartGraph({ view: [330, 300] });

    const toDate = new Date();
    const fromDate = new Date(toDate.getTime() - 90 * 24 * 60 * 60 * 1000);

    const from_date = new Date(
      fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    const to_date = new Date(
      toDate.getTime() - toDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    setParams({ from_date, to_date });
    setLast7Days({ from_date, to_date });
  }, []);

  const toggleDisabled = () => {
    setDemoDataEnabled(false);
    setToggleView(false);
    setTimeout(() => {
      setToggleView(true);
    }, 100);
  };

  const dateChanged = (dates: string[]) => {
    setParams({ from_date: dates[0], to_date: dates[1] });
  };

  useEffect(() => {
    if (params.from_date === "" || params.to_date === "") return;
    // getCallTrend({ from_date: dates[0], to_date: dates[1] });
    fetchCallTrend();
    fetchCallDirection();
    fetchUniqueRepeatCustomers();

    setTimeout(() => {
      fetchConnectedNotConnectedCalls();
      fetchCallSentiments();
      fetchTaskStatus();
    }, 1000);

    setTimeout(() => {
      fetchPhoneNumbers();
      fetchAgents();
      fetchWidgets();
    }, 1500);

    setTimeout(() => {
      fetchActionTypes();
      fetchCallRating();
      fetchEndCallReasons();
    }, 2000);

    // fetchAverageTalktimeUsage();

    // getAverageTalktimeUsage({ from_date: dates[0], to_date: dates[1] });
    fetchAverageTalktimeUsageCount();
    // getBusyHours({ from_date: dates[0], to_date: dates[1] });
  }, [params]);

  const fetchCallDirection = async () => {
    try {
      const response = await getCallDirection(params); // Replace with your API URL
      const data: any[] = await response.data.data;

      // Transform Data for Line Chart
      const formattedData: {
        [key: string]: { date: string; inbound: number; outbound: number };
      } = {};

      if (data?.length === 0) setIsCallDirectionEmpty(true);
      else setIsCallDirectionEmpty(false);

      data.forEach((entry) => {
        const [day, month, year] = entry.created_at.split("/");
        const formattedDate = `${month}-${day}-${year}`; // Convert "10/02/2025" to "02-10-2025"
        const shortDate = new Date(formattedDate).toUTCString().slice(5, 11); // "Feb 10"

        if (!formattedData[shortDate]) {
          formattedData[shortDate] = {
            date: shortDate,
            inbound: 0,
            outbound: 0,
          };
        }

        if (entry.direction === "outbound") {
          formattedData[shortDate].outbound += entry.count;
        } else if (entry.direction === "inbound") {
          formattedData[shortDate].inbound += entry.count;
        }
      });

      setCallDirectionData(Object.values(formattedData));
    } catch (error) {
      console.error("Error fetching call direction:", error);
    }
  };

  const lineChartConfig = {
    outbound: {
      label: "Outbound",
      color: "#9333EA",
    },
    inbound: {
      label: "Inbound",
      color: "#1D4ED8",
    },
  } satisfies ChartConfig;

  const [loaderCallTypes, setLoaderCallTypes] = useState(false);
  const [loaderCallSentiments, setLoaderCallSentiments] = useState(false);
  const [loaderCallStatus, setLoaderCallStatus] = useState(false);
  const [loaderTaskStatus, setLoaderTaskStatus] = useState(false);

  const [isCallTypesEmpty, setIsCallTypesEmpty] = useState(false);
  const [isCallSentimentsEmpty, setIsCallSentimentsEmpty] = useState(false);
  const [isCallStatusEmpty, setIsCallStatusEmpty] = useState(false);
  const [isTaskStatusEmpty, setIsTaskStatusEmpty] = useState(false);
  const callSentimentColors = ["#86EFAC", "#93C5FD", "#FCA5A5"];
  const callStatusColors = ["#86EFAC", "#FCA5A5"];
  const taskStatusColors = ["#86EFAC", "#FDBA74", "#FCA5A5"];
  const [callTypesData, setCallTypesData] = useState([
    { label: "unique", value: 0, fill: "#2563EB" },
    { label: "repeat", value: 0, fill: "#9333EA" },
  ]);
  const [callSentimentsData, setCallSentimentsData] = useState([
    { label: "Positive", value: 0, fill: callSentimentColors[0] },
    { label: "Neutral", value: 0, fill: callSentimentColors[1] },
    { label: "Negative", value: 0, fill: callSentimentColors[2] },
  ]);
  const [callStatusData, setCallStatusData] = useState([
    { label: "Connected", value: 0, fill: callStatusColors[0] },
    { label: "Not Connected", value: 0, fill: callStatusColors[1] },
  ]);
  const [taskStatusData, setTaskStatusData] = useState([
    { label: "Complete", value: 0, fill: taskStatusColors[0] },
    { label: "Partial", value: 0, fill: taskStatusColors[1] },
    { label: "Incomplete", value: 0, fill: taskStatusColors[2] },
  ]);

  const [callTypesDemoData, setCallTypesDemoData] = useState([
    { label: "unique", value: 18, fill: "#2563EB" },
    { label: "repeat", value: 10, fill: "#9333EA" },
  ]);
  const [callSentimentsDemoData, setCallSentimentsDemoData] = useState([
    { label: "Positive", value: 30, fill: callSentimentColors[0] },
    { label: "Neutral", value: 12, fill: callSentimentColors[1] },
    { label: "Negative", value: 6, fill: callSentimentColors[2] },
  ]);
  const [callStatusDemoData, setCallStatusDemoData] = useState([
    { label: "Connected", value: 63, fill: callStatusColors[0] },
    { label: "Not Connected", value: 12, fill: callStatusColors[1] },
  ]);
  const [taskStatusDemoData, setTaskStatusDemoData] = useState([
    { label: "Complete", value: 86, fill: taskStatusColors[0] },
    { label: "Partial", value: 45, fill: taskStatusColors[1] },
    { label: "Incomplete", value: 21, fill: taskStatusColors[2] },
  ]);

  const callTypesConfig = {
    unique: {
      label: "Unique Customers",
      color: "#2563EB",
    },
    repeat: {
      label: "Repeat Customers",
      color: "#9333EA",
    },
  } satisfies ChartConfig;

  const fetchUniqueRepeatCustomers = async () => {
    setLoaderCallTypes(true);
    try {
      const resp = await getUniqueRepeatCustomers(params);
      if (resp.data.data) {
        let updatedCallTypesData = [...callTypesData];
        let isEmpty = true;

        resp.data.data.forEach((element: any) => {
          if (element.unique_customers)
            updatedCallTypesData[0].value = element.unique_customers;
          if (element.repeat_customers)
            updatedCallTypesData[1].value = element.repeat_customers;
          if (element.total_customers) isEmpty = false;
        });

        setCallTypesData(updatedCallTypesData);
        setIsCallTypesEmpty(isEmpty);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderCallTypes(false);
    }
  };

  const fetchCallSentiments = async () => {
    setLoaderCallSentiments(true);
    try {
      const resp = await getCallSentiments(params);
      if (resp.data.data) {
        let updatedSentimentsData = [...callSentimentsData];
        let isEmpty = resp.data.data.length === 0;

        resp.data.data.forEach((element: any) => {
          if (element.sentiment === "Neutral")
            updatedSentimentsData[1].value = element.count;
          if (element.sentiment === "Positive")
            updatedSentimentsData[0].value = element.count;
          if (element.sentiment === "Negative")
            updatedSentimentsData[2].value = element.count;
          if (element.sentiment == null)
            updatedSentimentsData[1].value += element.count;
        });

        setCallSentimentsData(updatedSentimentsData);
        setIsCallSentimentsEmpty(isEmpty);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderCallSentiments(false);
    }
  };

  const fetchConnectedNotConnectedCalls = async () => {
    setLoaderCallStatus(true);
    try {
      const resp = await getConnectedNotConnectedCalls(params);
      if (resp.data.data) {
        let updatedCallStatusData = [...callStatusData];
        let isEmpty = true;

        resp.data.data.forEach((element: any) => {
          if (element.call_connected)
            updatedCallStatusData[0].value = element.call_connected;
          if (element.not_connected)
            updatedCallStatusData[1].value = element.not_connected;
          if (element.call_connected || element.not_connected) isEmpty = false;
        });

        setCallStatusData(updatedCallStatusData);
        setIsCallStatusEmpty(isEmpty);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderCallStatus(false);
    }
  };

  const fetchTaskStatus = async () => {
    setLoaderTaskStatus(true);
    try {
      const resp = await getTaskStatus(params);
      if (resp.data.data) {
        let updatedTaskStatusData = [...taskStatusData];
        let isEmpty = resp.data.data.length === 0;

        resp.data.data.forEach((element: any) => {
          if (element.sentiment === "Partial")
            updatedTaskStatusData[1].value = element.count;
          if (element.sentiment === "Complete")
            updatedTaskStatusData[0].value = element.count;
          if (element.sentiment === "Incomplete")
            updatedTaskStatusData[2].value = element.count;
          if (element.sentiment == null)
            updatedTaskStatusData[1].value += element.count;
        });

        setTaskStatusData(updatedTaskStatusData);
        setIsTaskStatusEmpty(isEmpty);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderTaskStatus(false);
    }
  };

  const [loaderCallTrendData, setLoaderCallTrendData] = useState(false);
  const [loaderPhoneNumberData, setLoaderPhoneNumberData] = useState(false);
  const [loaderActionsData, setLoaderActionsData] = useState(false);
  const [loaderHeatmapData, setLoaderHeatmapData] = useState(false);
  const [loaderRatingsData, setLoaderRatingsData] = useState(false);
  const [loaderWidgetData, setLoaderWidgetData] = useState(false);
  const [loaderEndCallData, setLoaderEndCallData] = useState(false);
  const [loaderAgentData, setLoaderAgentData] = useState(false);

  const [callTrendData, setCallTrendData] = useState([]);
  const [phoneNumberData, setPhoneNumberData] = useState([]);
  const [actionsData, setActionsData] = useState<any[]>([]);
  const [heatmapData, setHeatmapData] = useState<any[]>([]);
  const [ratingsData, setRatingsData] = useState([
    { value: 0 },
    { value: 0 },
    { value: 0 },
    { value: 0 },
    { value: 0 },
    { value: 0 },
  ]);
  const [isRatingEmpty, setIsRatingEmpty] = useState(false);
  const [widgetData, setWidgetData] = useState<any[]>([]);
  const [endCallData, setEndCallData] = useState<any[]>([]);
  const [agentData, setAgentData] = useState<any[]>([]);

  const [phoneNumberDemoData, setPhoneNumberDemoData] = useState([
    {
      created_at: "24/09/2024",
      count: 451,
      name: "Customer Support",
      number: "+12512500508",
    },
    {
      created_at: "24/09/2024",
      count: 400,
      name: "Sales",
      number: "+12512554433",
    },
    {
      created_at: "24/09/2024",
      count: 89,
      name: "Marketing",
      number: "+17865249977",
    },
    {
      created_at: "24/09/2024",
      count: 23,
      name: "Legal",
      number: "+15546674232",
    },
    {
      created_at: "24/09/2024",
      count: 10,
      name: "Founder's Office",
      number: "+12067768888",
    },
  ]);
  const [actionsDemoData, setActionsDemoData] = useState<any[]>([
    {
      created_at: "26/09/2024",
      action_type: "send_sms",
      action_count: 2,
    },
    {
      created_at: "26/09/2024",
      action_type: "send_webhook",
      action_count: 3,
    },
    {
      created_at: "25/09/2024",
      action_type: "send_email",
      action_count: 2,
    },
    {
      created_at: "26/09/2024",
      action_type: "transfer_call",
      action_count: 2,
    },
    {
      created_at: "26/09/2024",
      action_type: "end_call",
      action_count: 3,
    },
  ]);
  const [heatmapDemoData, setHeatmapDemoData] = useState<any[]>([]);
  const [ratingsDemoData, setRatingsDemoData] = useState<any[]>([]);
  const [widgetDemoData, setWidgetDemoData] = useState<any[]>([
    {
      created_at: "26/09/2024",
      count: 432,
      name: "Main Website",
      logo: "../../../assets/images/avatar.png",
    },
    {
      created_at: "26/09/2024",
      count: 66,
      name: "New Landing Page",
      logo: "../../../assets/images/avatar.png",
    },
    {
      created_at: "26/09/2024",
      count: 23,
      name: "Offer LTD",
      logo: "../../../assets/images/avatar.png",
    },
    {
      created_at: "26/09/2024",
      count: 5,
      name: "Support",
      logo: "../../../assets/images/avatar.png",
    },
  ]);
  const [endCallDemoData, setEndCallDemoData] = useState<any[]>([
    {
      created_at: "26/09/2024",
      disconnection_reason: "user_hangup",
      count: 4,
    },
    {
      created_at: "26/09/2024",
      disconnection_reason: "call_transfer",
      count: 1,
    },
    {
      created_at: "25/09/2024",
      disconnection_reason: "agent_hangup",
      count: 3,
    },
    {
      created_at: "25/09/2024",
      disconnection_reason: "dial_busy",
      count: 3,
    },
    {
      created_at: "25/09/2024",
      disconnection_reason: null,
      count: 3,
    },
  ]);
  const [agentDemoData, setAgentDemoData] = useState<any[]>([
    {
      created_at: "26/09/2024",
      count: 544,
      agent_name: "Restaurant AI",
      profile_pic: "../../../assets/images/avatar.png",
    },
    {
      created_at: "25/09/2024",
      count: 321,
      agent_name: "Real Estate AI",
      profile_pic: "../../../assets/images/avatar.png",
    },
    {
      created_at: "26/09/2024",
      count: 240,
      agent_name: "Dental AI",
      profile_pic: "../../../assets/images/avatar.png",
    },
    {
      created_at: "25/09/2024",
      count: 87,
      agent_name: "Business Coach AI",
      profile_pic: "../../../assets/images/avatar.png",
    },
  ]);

  // Fetch Call Trend
  const fetchCallTrend = () => {
    setLoaderCallTrendData(true);
    getCallTrend(params)
      .then((resp) => {
        let responseList = resp.data?.data || [];
        let totalCalls = 0;
        let formattedData = responseList.map((element: any) => {
          totalCalls += element.count;
          let [month, day, year] = element.created_at.split("/");
          return {
            name: new Date(`${year}-${month}-${day}`)
              .toUTCString()
              .slice(5, 11),
            value: element.count,
          };
        });

        setCallTrendData(formattedData);
        setTalkTime((prev) => ({ ...prev, total_calls: totalCalls }));
        setLoaderCallTrendData(false);
      })
      .catch((error) => {
        console.error("Error fetching call trend:", error);
        setLoaderCallTrendData(false);
      });
  };

  // Fetch Phone Numbers
  const fetchPhoneNumbers = () => {
    setLoaderPhoneNumberData(true);
    getPhoneNumbers(params)
      .then((resp) => {
        setPhoneNumberData(resp.data?.data || []);
        setLoaderPhoneNumberData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderPhoneNumberData(false);
      });
  };

  // Fetch Action Types
  const fetchActionTypes = () => {
    setLoaderActionsData(true);
    getActionTypes(params)
      .then((resp) => {
        let responseList = resp.data?.data || [];
        let actionsSet = new Set();
        let formattedActions: any[] = [];

        responseList.forEach((element: any) => {
          if (actionsSet.has(element?.action_type)) {
            formattedActions.forEach((item) => {
              if (item.action_type === element?.action_type) {
                item.action_count += element.action_count;
              }
            });
          } else {
            formattedActions.push(element);
            actionsSet.add(element.action_type);
          }
        });

        setActionsData(formattedActions);
        setLoaderActionsData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderActionsData(false);
      });
  };

  // Fetch Busy Hours
  const fetchBusyHours = () => {
    getBusyHours(params)
      .then((resp) => setHeatmapData(resp.data?.data || []))
      .catch((error) => console.error("Error fetching busy hours:", error));
  };

  // Fetch Call Ratings
  const fetchCallRating = () => {
    setLoaderRatingsData(true);
    getCallRating(params)
      .then((resp) => {
        let ratingList = resp.data.data;
        if (ratingList?.length === 0) setIsRatingEmpty(true);
        else setIsRatingEmpty(false);
        let ratings = [
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
          { value: 0 },
        ];
        ratingList?.forEach((element: any) => {
          if (element.rating === 5) ratings[0].value = element.count;
          if (element.rating === 4) ratings[1].value = element.count;
          if (element.rating === 3) ratings[2].value = element.count;
          if (element.rating === 2) ratings[3].value = element.count;
          if (element.rating === 1) ratings[4].value = element.count;
          if (element.rating === 0) ratings[5].value = element.count;
        });
        setRatingsData(ratings);
        setLoaderRatingsData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderRatingsData(false);
      });
  };

  // Fetch Widgets
  const fetchWidgets = () => {
    setLoaderWidgetData(true);
    getWidgets(params)
      .then((resp) => {
        let widgetList = resp.data?.data || [];
        widgetList.forEach((element: any) => {
          element.logo = `https://dtonp9kqo7651.cloudfront.net/${element.logo}`;
        });

        setWidgetData(widgetList);
        setLoaderWidgetData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderWidgetData(false);
      });
  };

  // Fetch End Call Reasons
  const fetchEndCallReasons = () => {
    setLoaderEndCallData(true);
    getEndCallReasons(params)
      .then((resp) => {
        let responseList = resp.data?.data || [];
        let endCallSet = new Set();
        let formattedData: any[] = [];

        responseList.forEach((element: any) => {
          if (endCallSet.has(element?.disconnection_reason)) {
            formattedData.forEach((item) => {
              if (item.disconnection_reason === element?.disconnection_reason) {
                item.count += element.count;
              }
            });
          } else {
            formattedData.push(element);
            endCallSet.add(element.disconnection_reason);
          }
        });

        setEndCallData(formattedData);
        setLoaderEndCallData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderEndCallData(false);
      });
  };

  // Fetch Agents
  const fetchAgents = () => {
    setLoaderAgentData(true);
    getAgents(params)
      .then((resp) => {
        let agentList = resp.data?.data || [];
        agentList.forEach((element: any) => {
          element.profile_pic = `https://dtonp9kqo7651.cloudfront.net/${element.profile_pic}`;
        });

        setAgentData(agentList);
        setLoaderAgentData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoaderAgentData(false);
      });
  };

  const fetchAverageTalktimeUsageCount = () => {
    setLoaderTalkTime(true);

    getAverageTalktimeUsageCount(params)
      .then((resp) => {
        if (resp.data?.data?.length > 0) {
          setTalkTime((prev) => ({
            ...prev,
            avg_talk_time: Math.ceil(resp.data.data[0].avg_talk_time_min),
            avg_usage_time: Math.ceil(resp.data.data[0].avg_usage_time_min),
            usage_time: Math.ceil(resp.data.data[0].usage_time_min),
          }));
        }
        setLoaderTalkTime(false);
      })
      .catch((error) => {
        console.error(error);
        setLoaderTalkTime(false);
      });
  };

  const endCallReasons = GlobalStrings.AiAgentActions;
  const tableGroups = [
    {
      title: "Group 1",
      tables: [
        {
          title: "Phone Numbers",
          data: phoneNumberData,
          demoData: phoneNumberDemoData,
          isLoading: loaderPhoneNumberData,
        },
        {
          title: "AI Agents",
          data: agentData,
          demoData: agentDemoData,
          isLoading: loaderAgentData,
        },
        {
          title: "Widgets",
          data: widgetData,
          demoData: widgetDemoData,
          isLoading: loaderWidgetData,
        },
      ],
    },
    {
      title: "Group 2",
      tables: [
        {
          title: "Ratings",
          data: ratingsData,
          demoData: ratingsDemoData,
          isLoading: loaderRatingsData,
        },
        {
          title: "End Call Reasons",
          data: endCallData,
          demoData: endCallDemoData,
          isLoading: loaderEndCallData,
        },
        {
          title: "Actions",
          data: actionsData,
          demoData: actionsDemoData,
          isLoading: loaderActionsData,
        },
      ],
    },
  ];

  const chartConfig = {
    desktop: {
      label: "Desktop",
      color: "hsl(var(--chart-1))",
    },
    mobile: {
      label: "Mobile",
      color: "hsl(var(--chart-2))",
    },
  } satisfies ChartConfig;

  return (
    <div className="w-full h-full overflow-auto pt-8 pb-6 flex flex-col gap-4 items-center container">
      {demoDataEnabled && (
        <div className="absolute top-[0px] w-full bg-purple-600 h-[6px] flex items-start justify-center">
          <span className="flex gap-2 items-center justify-center bg-purple-600 p-2 rounded-b-lg h-[34px] text-white text-sm font-medium leading-[18px]">
            Demo Data
            <X
              size={16}
              className="cursor-pointer"
              onClick={() => setDemoDataEnabled(false)}
            />
          </span>
        </div>
      )}
      <div className="flex items-center justify-between w-full text-primary text-2xl font-medium w-full max-w-[1098px]">
        <span>Analytics</span>
        <span className="flex gap-4 items-center">
          {talkTime.total_calls < 11 && (
            <span className="flex gap-4 items-center font-normal text-primary text-[16px] ">
              <span>Demo Data</span>
              <Switch
                checked={demoDataEnabled}
                onCheckedChange={(value) => setDemoDataEnabled(value)}
              />{" "}
            </span>
          )}
          <DateDropdown
            inputDates={[params.from_date, params.to_date]}
            onChange={dateChanged}
          ></DateDropdown>
        </span>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex gap-6 items-center w-full max-w-[1190px]">
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Total calls
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-0.5">
                <span className="text-primary text-2xl font-semibold">
                  {demoDataEnabled ? 53 : talkTime.total_calls}
                </span>
              </div>
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Average Talk Time
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-0.5">
                <span className="text-primary text-2xl font-semibold">
                  {demoDataEnabled ? 3.02 : talkTime.avg_talk_time} min
                </span>
              </div>
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">Usage</div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-0.5">
                <span className="text-primary text-2xl font-semibold">
                  {demoDataEnabled ? 112 : talkTime.usage_time} min
                </span>
              </div>
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Average usage
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-0.5">
                <span className="text-primary text-2xl font-semibold">
                  {demoDataEnabled ? 4 : talkTime.avg_usage_time} min
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-6 items-center w-full max-w-[1190px]">
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Calls volume
            </div>
            <div className="flex justify-start gap-2 items-center">
              <span className="flex gap-1 items-center">
                <span className="w-2 h-2 rounded-full bg-[#1D4ED8]"></span>{" "}
                Inbound
              </span>
              <span className="flex gap-1 items-center">
                <span className="w-2 h-2 rounded-full bg-[#9333EA]"></span>{" "}
                Outbound
              </span>
            </div>
            {demoDataEnabled || !isCallDirectionEmpty ? (
              <ChartContainer config={chartConfig}>
                <AreaChart
                  accessibilityLayer
                  data={demoDataEnabled ? callDirectionDemoData : callDirectionData}
                  margin={{
                    left: 12,
                    right: 12,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="month"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={(value) => value.slice(0, 3)} // Convert full month name to short
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent />}
                  />

                  {/* Gradient Definitions for Area Fill */}
                  <defs>
                    <linearGradient
                      id="fillInbound"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#1D4ED8" stopOpacity={0.8} />
                      <stop
                        offset="95%"
                        stopColor="#1D4ED8"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                    <linearGradient
                      id="fillOutbound"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#9333EA" stopOpacity={0.8} />
                      <stop
                        offset="95%"
                        stopColor="#9333EA"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  </defs>

                  {/* Areas with Gradient Fills */}
                  <Area
                    dataKey="outbound"
                    type="natural"
                    fill="url(#fillOutbound)"
                    fillOpacity={0.4}
                    stroke="#9333EA"
                    stackId="a"
                  />
                  <Area
                    dataKey="inbound"
                    type="natural"
                    fill="url(#fillInbound)"
                    fillOpacity={0.4}
                    stroke="#1D4ED8"
                    stackId="a"
                  />
                </AreaChart>
              </ChartContainer>
            ) : (
              <div className="flex flex-col w-full items-center justify-center py-4 text-gray-500 h-[300px]">
                <span className="text-purple-400 text-2xl">
                  <CustomIcon iconName="moon" width={24} height={24} />
                </span>
                <span>Nothing to see here</span>
              </div>
            )}
          </div>
          <div className="border border-gray-300 rounded-lg p-6 h-full flex-1.5 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Caller types
            </div>
            <div className="flex justify-center items-center">
              {demoDataEnabled || !isCallTypesEmpty ? (
                <PieChart width={300} height={300}>
                  <Pie
                    data={demoDataEnabled ? callTypesDemoData : callTypesData}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    fill="#8884d8"
                  >
                    {/* Custom Centered Label */}
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-3xl font-bold"
                              >
                                {callTypesData[0]?.value +
                                  callTypesData[1]?.value}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground"
                              >
                                Total calls
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </Pie>
                  <Tooltip cursor={false} />
                </PieChart>
              ) : (
                <div className="flex flex-col h-[220px] items-center justify-center py-4 text-gray-500 w-[300px]">
                  <span className="text-purple-400 text-2xl">
                    <CustomIcon iconName="moon" width={24} height={24} />
                  </span>
                  <span>Nothing to see here</span>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center gap-2 p-3">
              <div className="flex flex-col items-center gap-2 p-2 bg-gray-100 text-gray-700 rounded-lg">
                <span className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                  {demoDataEnabled
                    ? 28
                    : callTypesData[0]?.value + callTypesData[1]?.value}
                </span>
                <span>Total</span>
              </div>
              <div className="flex flex-col items-center gap-2 p-2 bg-purple-100 text-purple-700 rounded-lg">
                <span className="flex items-center justify-center w-8 h-8 bg-purple-200 rounded-full">
                  {demoDataEnabled ? 10 : callTypesData[1]?.value}
                </span>
                <span>Repeat</span>
              </div>
              <div className="flex flex-col items-center gap-2 p-2 bg-blue-100 text-blue-700 rounded-lg">
                <span className="flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full">
                  {demoDataEnabled ? 28 : callTypesData[0]?.value}
                </span>
                <span>Unique</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-6 items-center w-full max-w-[1190px]">
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              User sentiments
            </div>
            <div className="flex justify-center items-center">
              {demoDataEnabled || !isCallSentimentsEmpty ? (
                <PieChart width={300} height={300}>
                  <Pie
                    data={
                      demoDataEnabled
                        ? callSentimentsDemoData
                        : callSentimentsData
                    }
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    fill="#8884d8"
                  >
                    {/* Custom Centered Label */}
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-3xl font-bold"
                              >
                                {callSentimentsData[0].value}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground"
                              >
                                Positive
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </Pie>
                  <Tooltip cursor={false} />
                </PieChart>
              ) : (
                <div className="flex flex-col items-center justify-center py-4 text-gray-500 w-[300px] h-[300px]">
                  <span className="text-purple-400 text-2xl">
                    <CustomIcon iconName="moon" width={24} height={24} />
                  </span>
                  <span>Nothing to see here</span>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center gap-2">
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: callSentimentColors[0] }}
                ></span>{" "}
                Positive
              </span>
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: callSentimentColors[1] }}
                ></span>{" "}
                Neutral
              </span>
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: callSentimentColors[2] }}
                ></span>{" "}
                Negative
              </span>
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Call status
            </div>
            <div className="flex justify-center items-center">
              {demoDataEnabled || !isCallStatusEmpty ? (
                <PieChart width={300} height={300}>
                  <Pie
                    data={demoDataEnabled ? callStatusDemoData : callStatusData}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    fill="#8884d8"
                  >
                    {/* Custom Centered Label */}
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-3xl font-bold"
                              >
                                {callStatusData[0].value}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground"
                              >
                                Connected
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </Pie>
                  <Tooltip cursor={false} />
                </PieChart>
              ) : (
                <div className="flex flex-col items-center justify-center py-4 text-gray-500 w-[300px] h-[300px]">
                  <span className="text-purple-400 text-2xl">
                    <CustomIcon iconName="moon" width={24} height={24} />
                  </span>
                  <span>Nothing to see here</span>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center gap-2">
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: callStatusColors[0] }}
                ></span>{" "}
                Connected
              </span>
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: callStatusColors[1] }}
                ></span>{" "}
                Not connected
              </span>
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg p-6 flex-1 flex flex-col gap-4">
            <div className="text-primary text-lg font-semibold">
              Task status
            </div>
            <div className="flex justify-center items-center">
              {demoDataEnabled || !isTaskStatusEmpty ? (
                <PieChart width={300} height={300}>
                  <Pie
                    data={demoDataEnabled ? taskStatusDemoData : taskStatusData}
                    dataKey="value"
                    nameKey="label"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    fill="#8884d8"
                  >
                    {/* Custom Centered Label */}
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-3xl font-bold"
                              >
                                {taskStatusData[0].value}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground"
                              >
                                Complete
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </Pie>
                  <Tooltip cursor={false} />
                </PieChart>
              ) : (
                <div className="flex flex-col items-center justify-center py-4 text-gray-500 w-[300px] h-[300px]">
                  <span className="text-purple-400 text-2xl">
                    <CustomIcon iconName="moon" width={24} height={24} />
                  </span>
                  <span>Nothing to see here</span>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center gap-2">
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: taskStatusColors[0] }}
                ></span>{" "}
                Complete
              </span>
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: taskStatusColors[1] }}
                ></span>{" "}
                Partial
              </span>
              <span className="flex gap-1 items-center">
                <span
                  className="w-2 h-2 rounded-full"
                  style={{ background: taskStatusColors[2] }}
                ></span>{" "}
                Incomplete
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-6 w-full max-w-[1190px]">
          {tableGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="space-y-4">
              <div className="grid md:grid-cols-3 gap-4">
                {group.tables.map(
                  ({ title, data, demoData, isLoading }, index) => (
                    <div
                      key={index}
                      className="w-full bg-white  rounded-lg border border-[var(--border-color)] flex flex-col flex-1 h-[287px]"
                    >
                      <div className="flex justify-between text-sm font-semibold border-b p-2 pr-4 bg-[var(--hover-color)] text-primary border-b border-[var(--border-color)]">
                        <span>{title}</span>
                        <span>Calls</span>
                      </div>
                      <div className="max-h-[246px] overflow-y-scroll flex flex-col h-full">
                        {isLoading ? (
                          <div className="space-y-2">
                            {[...Array(5)].map((_, i) => (
                              <Skeleton key={i} className="h-[20px]" />
                            ))}
                          </div>
                        ) : data.length === 0 && !demoDataEnabled ? (
                          <div className="flex flex-col h-full items-center justify-center py-4 text-gray-500">
                            <span className="text-purple-400 text-2xl">
                              <CustomIcon
                                iconName="moon"
                                width={24}
                                height={24}
                              />
                            </span>
                            <span>Nothing to see here</span>
                          </div>
                        ) : title === "Ratings" ? (
                          ratingsData.map((item, i) => (
                            <div className="flex justify-between items-center p-2 pr-4 text-primary text-sm">
                              {" "}
                              <span className="flex gap-2 items-center">
                                {[...Array(5)]
                                  .map((_, j) => (
                                    <span
                                      key={j}
                                      className={
                                        j < i
                                          ? "text-yellow-500"
                                          : "text-gray-300"
                                      }
                                    >
                                      {j >= i ? (
                                        <CustomIcon
                                          iconName="star_fill"
                                          width={16}
                                          height={16}
                                        />
                                      ) : (
                                        <CustomIcon
                                          iconName="star_empty"
                                          width={16}
                                          height={16}
                                        />
                                      )}
                                    </span>
                                  ))
                                  .reverse()}
                              </span>
                              <span>{item.value}</span>
                            </div>
                          ))
                        ) : (
                          (demoDataEnabled ? demoData : data).map((item, i) => (
                            <div
                              key={i}
                              className="flex justify-between items-center p-2 pr-4 text-primary text-sm"
                            >
                              <span className="flex items-center gap-2">
                                {item.profile_pic || item.logo ? (
                                  <>
                                    <img
                                      src={item.profile_pic || item.logo}
                                      alt=""
                                      // onError={(e) => (e.currentTarget.src = "assets/error_image.png")}
                                      className="w-6 h-6 rounded-full object-cover"
                                    />
                                    <span>{item.name || item.agent_name}</span>
                                  </>
                                ) : item.icon ? (
                                  <>
                                    <span className="text-lg">{item.icon}</span>
                                    <span>{item.name || item.agent_name}</span>
                                  </>
                                ) : item.disconnection_reason ? (
                                  <span>
                                    {item?.disconnection_reason
                                      ? endCallReasons[
                                          item?.disconnection_reason
                                        ]
                                      : "Others"}
                                  </span>
                                ) : item.action_type ? (
                                  <span className="flex gap-2">
                                    {item.action_type === "send_sms" && (
                                      <CustomIcon iconName="send_sms" />
                                    )}
                                    {item.action_type === "send_email" && (
                                      <CustomIcon iconName="email" />
                                    )}
                                    {item.action_type === "send_webhook" && (
                                      <CustomIcon iconName="webhook" />
                                    )}
                                    {item.action_type === "transfer_call" && (
                                      <CustomIcon iconName="phone_forwarded" />
                                    )}
                                    {item.action_type === "end_call" && (
                                      <CustomIcon iconName="call_end" />
                                    )}
                                    {item.action_type === "extract_info" && (
                                      <CustomIcon iconName="info_alert" />
                                    )}
                                    {item.action_type === "calendar" && (
                                      <CalendarDays className="h-5 w-5" />
                                    )}
                                    {toPascalCase(
                                      item.action_type.replace("_", " ")
                                    )}
                                  </span>
                                ) : (
                                  <span>{item.name || item.agent_name}</span>
                                )}
                              </span>
                              <span>{item.count || item?.action_count}</span>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
