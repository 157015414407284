import React, { useEffect, useState } from 'react';
import { Button } from '../../shadcn/button';
import { Input } from '../../shadcn/input';
import { Textarea } from '../../shadcn/textarea';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../shadcn/dropdown-menu';
import { AgentAction } from '../../../models/agent-action';
import { addAgentAction, updateAgentAction } from '../../../lib/agents.utils';
import Toaster from '../../shared/customToast';
import { getCountryCodeFromNumber, getMobileNumber, isValidNumber, isValidNumber2 } from '../../../lib/common.utils';
import { ChevronDown } from 'lucide-react';
import { ScrollArea } from '../../shadcn/scrollArea';
import { Sheet, SheetContent } from '../../shadcn/sheet';


interface TransferCallActionProps {
  action: AgentAction;
  isEdit?: boolean;
  agentId: number;
  onSuccess?: (data: any) => void;
  onClose: () => void;
}

const TransferCallAction: React.FC<TransferCallActionProps> = ({
  action,
  isEdit,
  agentId,
  onSuccess,
  onClose
}) => {
  const [actionName, setActionName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [trigger, setTrigger] = useState('during_call');
  const [prompt, setPrompt] = useState('');
  const [delay, setDelay] = useState(0);
  const [transferType, setTransferType] = useState('Cold transfer');
  const [handoffMessage, setHandoffMessage] = useState('');
  const [handoffMessageType, setHandoffMessageType] = useState('prompt');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (action?.id && isEdit) {
      setActionName(action.name || '');
      setPhoneNumber(action?.data?.transfer_to || '');
      setPrompt(action?.data?.action_prompt || '');
      setDelay(action?.data?.delay || 2);

      if (action?.data?.warm_transfer_option) {
        setTransferType('Warm Transfer');
        setHandoffMessage(action?.data?.warm_transfer_option?.prompt || '');
        setHandoffMessageType(action?.data?.warm_transfer_option?.type || 'prompt');
      } else {
        setTransferType('Cold Transfer');
      }
    }
  }, [action, isEdit]);

  const handleSubmit = async () => {
    let numberDetails = isNumberValid(phoneNumber);
    if(numberDetails[0] === '' || numberDetails[1] === '') {
      Toaster.error("Enter valid phone number");
      return ;
    }
    setIsLoading(true);
    const input: Record<string, any> = {
      name: actionName,
      type: 'transfer_call',
      data: {
        transfer_to: '+'+numberDetails[1],
        action_prompt: prompt,
        delay: delay,
        warm_transfer_option:  {
          type: handoffMessageType,
          prompt: handoffMessage,
        },
      },
      trigger: trigger,
    };
    if(transferType === 'Cold transfer') delete input['data'].warm_transfer_option;

    try {
      const response = isEdit
        ? await updateAgentAction(agentId, action?.id, input)
        : await addAgentAction(agentId, input);

      // toastr.success(response.body.message);
      onSuccess?.(response.data.data);
    } catch (error) {
      console.error(error);
      // toastr.error(error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const isNumberValid = (number: string): string[] => {
    if(isValidNumber2('+'+number)) {
      return [getCountryCodeFromNumber(number),getCountryCodeFromNumber(number) + getMobileNumber(number)];
    } else {
      if(isValidNumber(number, 'US')) {
        return [getCountryCodeFromNumber('1'+number),'1'+ getMobileNumber('1'+number)];
      } else return ['', '']
    }
  }

  return (
    <Sheet open={isOpen} onOpenChange={() => {setIsOpen(false); onClose();}}>
      <SheetContent className='p-6 w-[450px] !max-w-[450px]'>
        <div className="flex flex-col gap-4">
          {/* Header */}
          <div className="flex flex-col gap-1">
            <h2 className="text-lg font-semibold text-primary">Transfer Call</h2>
            <p className="text-sm text-secondary">
              Transfer the call to a number or human
            </p>
          </div>

          {/* Action Name */}
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-primary">Action Name</label>
            <Input
              placeholder="e.g. general inquiry"
              value={actionName}
              onChange={(e) => setActionName(e.target.value)}
              className="border p-2 rounded-lg"
            />
          </div>

          {/* Phone Number */}
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-primary">Phone Number</label>
            <Input
              placeholder="e.g. (555) 123-4567"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="border p-2 rounded-lg"
            />
          </div>

          {/* Transfer Prompt */}
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-primary">
              When should the transfer happen?
            </label>
            <Textarea
              placeholder="e.g. when a user is upset, requests to speak with a human..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="border p-2 rounded-lg h-[100px]"
            />
          </div>

          {/* Delay Dropdown */}
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium text-primary">Delay</label>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button variant="outline" className='w-fit justify-between font-normal'>
                  <span>{delay} Sec</span>
                  <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white rounded-lg shadow-md border p-1">
                <ScrollArea className="max-h-[200px] flex flex-col gap-2">
                  {Array.from({ length: 11 }, (_, i) => (
                  <DropdownMenuItem
                    key={i}
                    onClick={() => setDelay(i)}
                    className="p-2 rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    {i}
                  </DropdownMenuItem>
                  ))}
                </ScrollArea>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* Transfer Type Dropdown */}
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-primary">Type</label>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button variant="outline" className="w-full justify-between font-normal">
                  {transferType}
                  <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white rounded-lg shadow-md border p-1">
                <DropdownMenuItem
                  onClick={() => setTransferType('Cold transfer')}
                  className="flex flex-col gap-1 items-start p-2 rounded-md cursor-pointer hover:bg-gray-100"
                >
                  Cold transfer
                  <p className="text-xs text-secondary">
                    AI transfers the call to the next user without a debrief.
                  </p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setTransferType('Warm transfer')}
                  className="flex flex-col gap-1 items-start p-2 rounded-md cursor-pointer hover:bg-gray-100"
                >
                  Warm transfer
                  <p className="text-xs text-secondary">
                    AI provides a debrief to the next user after transferring the call.
                  </p>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* Handoff Message */}
          {transferType === 'Warm transfer' && (
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium text-primary">Handoff Instruction</label>
              <Textarea
                placeholder="Provide a brief summary of the call and key details before transferring it to ensure a smooth handoff."
                value={handoffMessage}
                onChange={(e) => setHandoffMessage(e.target.value)}
                className="border p-2 rounded-lg h-[100px]"
              />
            </div>
          )}

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button
              disabled={isLoading || actionName === '' || prompt === ''  || phoneNumber === ''}
              onClick={handleSubmit}
              className="bg-primary text-white px-4 py-2 rounded-lg"
            >
              Submit
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default TransferCallAction;
