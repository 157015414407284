import React, { useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useWebSocketStore } from './store/websocket.store';
import useSharedStore from './store/shared.store';

const WebSocketComponent: React.FC = () => {

  const sharedStore = useSharedStore()
  const { handleWebSocketMessage, setSendMessageFunc, setCloseWebSocketFunc } = useWebSocketStore();
  let token = sessionStorage.getItem('client_token')?.substring(4);
  if(!token) token = localStorage.getItem('token')?.substring(4)
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    (process.env.REACT_APP_HTTPS ? 'wss' : 'ws')+'://'+sharedStore.apiUrl+'/ws/chat?token=' + token,
    {
      onOpen: () => console.log('WebSocket connection opened'),
      onClose: () => console.log('WebSocket connection closed'),
      onError: (event) => console.error('WebSocket error:', event),
      reconnectAttempts: Infinity,
      reconnectInterval: 15000, 
      // shouldReconnect: () => true, // Auto-reconnect on disconnection
    }
  );

  // Handle incoming messages
  const handleMessage = useCallback(() => {
    if (lastMessage) {
      handleWebSocketMessage(lastMessage.data);
    }
  }, [lastMessage]);

  // Keep the connection alive with pings
  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (readyState === ReadyState.OPEN) {
        sendMessage(JSON.stringify({ type: 'ping'}));
      }
    }, 15000);

    // Cleanup the interval on unmount
    return () => clearInterval(pingInterval);
  }, [readyState, sendMessage]);

  // React to new WebSocket messages
  React.useEffect(() => {
    handleMessage();
  }, [handleMessage]);

  useEffect(() => {
    setSendMessageFunc(sendMessage);
  }, [sendMessage, setSendMessageFunc]);

  // // Function to close the WebSocket connection
  // const closeWebSocket = useCallback(() => {
  //   const webSocket = getWebSocket();
  //   if (webSocket) {
  //     webSocket.close();
  //   }
  // }, [getWebSocket]);

  // useEffect(() => {
  //   setCloseWebSocketFunc(closeWebSocket);
  // }, [closeWebSocket, setCloseWebSocketFunc]);

  // WebSocket connection status
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div>
    </div>
  );
};

export default WebSocketComponent;
