import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Toaster from "../shared/customToast";
import {
  getCountryCodeFromNumber,
  getMobileNumber,
  getPresignUrl,
  isValidNumber,
  isValidNumber2,
  uploadToS3,
} from "../../lib/common.utils";
import { Button } from "../shadcn/button";
import { Dialog, DialogContent } from "../shadcn/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import { Loader2, ChevronDown, Paperclip } from "lucide-react";
import { Checkbox } from "../shadcn/checkbox";
import { CustomProperty } from "../../models/customer";
import { getCustomProperty } from "../../lib/business.utils";
import useSharedStore from "../../store/shared.store";
import { GlobalStrings } from "../../lib/constants";
import { bulkCustomerUpload } from "../../lib/users.utils";
import { LoggedUser } from "../../models/logged-user";
import { useAgencyStore } from "../../store/agency.store";

interface ImportContactsProps {
  onClose: () => void;
}

const MAX_LIMIT = 500000;
const sampleExcelUrl =
  "https://s3.us-east-1.amazonaws.com/assets.oneinbox.ai/demo_customer_data.xlsx";

const ImportContacts: React.FC<ImportContactsProps> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [importStatus, setImportStatus] = useState("selection");
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState<string[]>([]);
  const [nameColumnIndex, setNameColumnIndex] = useState(-1);
  const [numberColumnIndex, setNumberColumnIndex] = useState(-1);
  const [emailColumnIndex, setEmailColumnIndex] = useState(-1);

  const [fileReading, setFileReading] = useState(false);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [validatedExcelData, setValidatedExcelData] = useState<any[]>([]);
  const [customFields, setCustomFields] = useState<CustomProperty[]>([]);
  const [fieldMap, setFieldMap] = useState<Record<string, string>>({});
  const [selectedFields, setSelectedFields] = useState<Record<string, number>>(
    {}
  );
  const [loaderExcelUpload, setLoaderExcelUpload] = useState(false);

  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();
  var numbersSet = new Set<string>();

  const [user, setUser] = useState(
    new LoggedUser(JSON.parse(localStorage.getItem("user")!))
  );

  useEffect(() => {
    // Fetch custom properties if needed
    getCustomProperty().then((resp) => {
      let responseList = resp.data.data;
      let map: Record<string, string> = {};
      responseList?.forEach((element: any) => {
        element = new CustomProperty(element);
        map[element.field] = element.datatype;
      });
      setFieldMap(map);
      setCustomFields(responseList);
      sharedStore.setCustomFields(responseList);
    });
  }, []);

  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = sampleExcelUrl;
    link.download = "demo_customer_data.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const selectExcel = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx, .csv";
    input.multiple = false;

    input.onchange = (_) => {
      let files = input.files!;
      const file = files[0];
      if (!file) return;

      const fileExtension = file.name.split(".").pop();
      if (!["xlsx", "csv"].includes(fileExtension!)) {
        Toaster.error("Please select a valid .xlsx or .csv file");
        return;
      }

      setFileReading(true);
      setFile(file);

      const fileReader = new FileReader();
      if (fileExtension === "csv") {
        fileReader.readAsText(file);
        fileReader.onload = (e) => {
          const result = e.target?.result as string;
          let jsonData: string[] = result.split("\n");
          jsonData.pop();
          if (jsonData.length > MAX_LIMIT) {
            Toaster.error("File cannot have more than 500,000 records");
            setFileReading(false);
            setFile(null);
            return;
          }
          let headers: string[] = jsonData[0].split(",");
          setColumns(headers);
          jsonData.shift();
          setExcelData(jsonData.map((row) => row.split(",")));
          setFileReading(false);
        };
      } else {
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const arrayBuffer = e.target?.result as ArrayBuffer;
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          if (jsonData.length > MAX_LIMIT) {
            Toaster.error("File cannot have more than 500,000 records");
            setFileReading(false);
            return;
          }
          setColumns(jsonData[0] as string[]);
          jsonData.shift();
          setExcelData(jsonData);
          setFileReading(false);
        };
      }
    };
    input.click();
  };

  const validateExcel = () => {
    const validatedData = excelData
      .map(validateRow)
      .filter((row) => row !== null);
    setValidatedExcelData(validatedData);
    setImportStatus("upload");
  };

  const validateRow = (row: Record<string, any>): (string | null)[] | null => {
    console.log(row);

    // Name validation
    let name: string =
      row[nameColumnIndex]
        ?.toString()
        .replaceAll(",", "")
        .replaceAll(/\n/g, " ")
        .replaceAll(/\\/g, "")
        .trim() || "";

    if (name?.trim() === "") name = row[nameColumnIndex];
    if (name?.length > 100) return null;

    // Mobile number validation
    let mobile = "";
    let countryCode = "";
    const mobileValues = isNumberValid(row[numberColumnIndex]?.toString());

    if (mobileValues[0] !== "") {
      mobile = mobileValues[1];
      countryCode = mobileValues[0];

      if (!numbersSet.has(mobile)) {
        numbersSet.add(mobile);
      } else {
        return null;
      }
    } else {
      return null;
    }

    // Email validation
    let email = "";
    if (row[emailColumnIndex]) {
      email = row[emailColumnIndex]?.toString().trim();
      if (email && !GlobalStrings.emailRegex.test(email)) {
        return null;
      }
    }

    // Validating other fields based on `fieldMap`
    const fieldsValues: string[] = Object.entries(selectedFields).map(
      ([key, value]) => {
        const fieldValue = row[value]?.toString().trim() || "";

        switch (fieldMap[key]) {
          case "date": {
            const date = new Date(fieldValue);
            return isNaN(date.getTime())
              ? ""
              : `${date.getFullYear()}-${
                  date.getMonth() + 1
                }-${date.getDate()}`;
          }
          case "url":
            return GlobalStrings.websiteRegex.test(fieldValue)
              ? fieldValue
              : "";
          case "bool":
            return fieldValue.toLowerCase() === "true" ||
              fieldValue.toLowerCase() === "false"
              ? fieldValue.toLowerCase()
              : "";
          case "int":
            return !isNaN(Number(fieldValue)) ? fieldValue : "";
          case "text":
          case "address":
            return fieldValue;
          default:
            return "";
        }
      }
    );

    return [name, mobile, email, countryCode, ...fieldsValues];
  };

  const isNumberValid = (number: string): string[] => {
    if (isValidNumber2("+" + number)) {
      return [
        getCountryCodeFromNumber(number),
        "+" + getCountryCodeFromNumber(number) + getMobileNumber(number),
      ];
    } else {
      if (isValidNumber(number, "US")) {
        return [
          getCountryCodeFromNumber("1" + number),
          "+1" + getMobileNumber("1" + number),
        ];
      } else return ["", ""];
    }
  };

  const uploadExcel = () => {
    const csvContent =
      ["name", "mobile", "email", "country_code", ...Object.keys(selectedFields)].join(",") +
      "\n" +
      validatedExcelData.map((row) => row.join(",")).join("\r\n");
    const file = new File([csvContent], "bulk_upload.csv", {
      type: "text/csv",
    });
    uploadCSV(file);
  };

  const uploadCSV = (updatedFile: File) => {
    const epoch = `${Date.now()}-${user?.id}.${updatedFile.name
      .split(".")
      .pop()}`;
    const presignObj = {
      key: `excel-upload/${epoch}`,
      content_type: "text/csv",
      excel: true,
      private_file: true,
    };
    setIsLoading(true);

    getPresignUrl(presignObj)
      .then((res: any) => {
        const { url, fields } = res.data.data;
        uploadToS3(url, fields, updatedFile)
          .then((res: any) => {
            const input = { file_path: fields["key"] };
            bulkCustomerUpload(input)
              .then((res: any) => {
                setImportStatus("uploading");
                Toaster.success(
                  `${validatedExcelData?.length} contacts successfully uploaded`
                );
                onClose();
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                Toaster.error(error);
              });
          })
          .catch((error) => {
            setIsLoading(false);
            Toaster.error(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        Toaster.error(error);
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[720px] w-fit max-h-[720px] overflow-auto">
        {importStatus === "selection" && (
          <div className="w-[460px]">
            <div className="text-lg font-semibold">Import contacts by CSV</div>
            <div className="mt-4 flex flex-col items-center border border-[var(--border-color)] gap-4 p-4 rounded bg-[var(--hover-color)]">
              {!file ? (
                <Button variant="outline" onClick={() => selectExcel()}>
                  Add file
                </Button>
              ) : (
                <div className="flex justify-between items-center w-full">
                  <span className="text-sm font-semibold truncate max-w-[220px] flex gap-2 items-center"><Paperclip size={"20px"}/> {file?.name}</span>
                  <Button variant="outline" onClick={selectExcel}>
                    Replace file
                  </Button>
                </div>
              )}
              <p className="text-xs text-gray-500">
                XLSX or CSV (Max. 500,000 rows)
              </p>
            </div>
            <div className="mt-6 flex justify-between">
              <Button variant="link" onClick={downloadExcel}>
                Download CSV sample
              </Button>
              <Button disabled={!file} onClick={() => setImportStatus("map")}>
                Upload and preview
              </Button>
            </div>
          </div>
        )}

        {importStatus === "map" && (
          <div className="flex flex-col gap-4 w-[600px]">
            <div className="">
              <h2 className="text-lg font-semibold">Map properties</h2>
              <p className="text-sm text-gray-500">
                Review the matching column titles and use the dropdowns to make
                any changes needed.
              </p>
            </div>
            <div className="max-h-[400px] overflow-y-auto">
              <table className="min-w-full border rounded-lg">
                <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10">
                  <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    <th
                      style={{ borderRadius: "8px 0 0 0" }}
                      className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                    >
                      Properties in {agencyStore.brand}
                    </th>
                    <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                      Columns in your file
                    </th>
                    <th
                      style={{ borderRadius: "0 8px 0 0" }}
                      className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                    >
                      Preview
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      label: "Name",
                      state: nameColumnIndex,
                      setState: setNameColumnIndex,
                    },
                    {
                      label: "Phone number",
                      state: numberColumnIndex,
                      setState: setNumberColumnIndex,
                    },
                    {
                      label: "Email",
                      state: emailColumnIndex,
                      setState: setEmailColumnIndex,
                    },
                  ].map(({ label, state, setState }) => (
                    <tr
                      key={label}
                      className={state > -1 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-2 py-4 flex items-center text-sm text-[var(--primary-color)]">
                        <Checkbox checked disabled className="mr-2" />
                        {label}
                      </td>
                      <td className="p-2">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full justify-between"
                            >
                              {state > -1 ? columns[state] : "Choose column"}{" "}
                              <ChevronDown />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {columns.map((column, i) => (
                              <DropdownMenuItem
                                key={i}
                                onClick={() => setState(i)}
                              >
                                {column}
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                      <td className="px-2 py-4 flex items-center text-sm text-[var(--primary-color)]">
                        {state > -1 ? excelData[0][state] : ""}
                      </td>
                    </tr>
                  ))}
                  {customFields.map((field) => (
                    <tr
                      key={field.field}
                      className={
                        selectedFields[field.field] > -1
                          ? "bg-white"
                          : "bg-gray-50"
                      }
                    >
                      <td className="px-2 py-4 flex items-center text-sm text-[var(--primary-color)]">
                        <Checkbox
                          checked={selectedFields[field.field] > -1}
                          disabled
                          className="mr-2"
                        />
                        {field.label}
                      </td>
                      <td className="p-2">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="outline"
                              className="w-full justify-between"
                            >
                              {selectedFields[field.field] > -1
                                ? columns[selectedFields[field.field]]
                                : "Choose column"}{" "}
                              <ChevronDown />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {columns.map((column, i) => (
                              <DropdownMenuItem
                                key={i}
                                onClick={() =>
                                  setSelectedFields({
                                    ...selectedFields,
                                    [field.field]: i,
                                  })
                                }
                              >
                                {column}
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </td>
                      <td className="px-2 py-4 flex items-center text-sm text-[var(--primary-color)]">
                        {selectedFields[field.field] > -1
                          ? excelData[0][selectedFields[field.field]]
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between">
              <Button
                variant="outline"
                onClick={() => setImportStatus("selection")}
              >
                Back
              </Button>
              <Button
                disabled={
                  nameColumnIndex < 0 ||
                  numberColumnIndex < 0 ||
                  emailColumnIndex < 0
                }
                onClick={validateExcel}
              >
                {isLoading ? "Uploading..." : "Upload and preview"}
              </Button>
            </div>
          </div>
        )}

        {importStatus === "upload" && (
          <div className="flex flex-col gap-4 w-[450px]">
            <div className="flex justify-between items-center text-xl font-semibold text-primary">
              <span>Import contacts</span>
            </div>

            {/* File Selection */}
            <div className="flex flex-col items-center gap-2">
              <div className="flex flex-col gap-4 p-4 items-center border-2 border-border-color bg-[var(--hover-color)] rounded-lg">
                <span className="text-2xl font-medium text-primary">
                  {validatedExcelData?.length}
                </span>
                <span className="text-sm font-normal text-secondary">
                  Contacts will be created
                </span>
              </div>
              <span className="text-sm text-secondary text-center">
                Importing will override any existing contacts that have the same
                phone number.
              </span>
            </div>

            {/* Footer Buttons */}
            <div className="flex justify-between items-center py-2">
              <Button
                variant={"outline"}
                onClick={() => setImportStatus("map")}
              >
                Back
              </Button>
              <Button
                disabled={validatedExcelData?.length === 0 || isLoading}
                onClick={uploadExcel}
              >
                {isLoading ? "Importing..." : "Import Contacts"}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImportContacts;
