import apiClient from '../interceptors/axiosApiInterceptor';

export const getCallTrend = (params: any) =>
  apiClient.get('/analytics/call-trend', { params });

export const getCallDirection = (params: any) =>
  apiClient.get('/analytics/direction', { params });

export const getUniqueRepeatCustomers = (params: any) =>
  apiClient.get('/analytics/unique-or-repeat_customer', { params });

export const getCallSentiments = (params: any) =>
  apiClient.get('/analytics/sentiments', { params });

export const getConnectedNotConnectedCalls = (params: any) =>
  apiClient.get('/analytics/call-connected-not-connected', { params });

export const getTaskStatus = (params: any) =>
  apiClient.get('/analytics/task-status', { params });

export const getPhoneNumbers = (params: any) =>
  apiClient.get('/analytics/phone-number', { params });

export const getActionTypes = (params: any) =>
  apiClient.get('/analytics/action-type', { params });

export const getAverageTalktimeUsage = (params: any) =>
  apiClient.get('/analytics/avg-talktime-and-usage', { params });

export const getAverageTalktimeUsageCount = (params: any) =>
  apiClient.get('/analytics/avg_talktime-and-usage-count', { params });

export const getBusyHours = (params: any) =>
  apiClient.get('/analytics/busy-hour', { params });

export const getCallRating = (params: any) =>
  apiClient.get('/analytics/call-rating', { params });

export const getWidgets = (params: any) =>
  apiClient.get('/analytics/widget', { params });

export const getEndCallReasons = (params: any) =>
  apiClient.get('/analytics/end-call-reason', { params });

export const getAgents = (params: any) =>
  apiClient.get('/analytics/agent', { params });

export const getAgentCallTrend = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/call-trend`, { params });

export const getAgentCallDirection = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/direction`, { params });

export const getAgentUniqueRepeatCustomers = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/unique-or-repeat_customer`, { params });

export const getAgentCallSentiments = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/sentiments`, { params });

export const getAgentConnectedNotConnectedCalls = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/call-connected-not-connected`, { params });

export const getAgentTaskStatus = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/task-status`, { params });

export const getAgentActionTypes = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/action-type`, { params });

export const getAgentAverageTalktimeUsage = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/avg-talktime-and-usage`, { params });

export const getAgentAverageTalktimeUsageCount = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/avg_talktime-and-usage-count`, { params });

export const getAgentBusyHours = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/busy-hour`, { params });

export const getAgentCallRating = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/call-rating`, { params });

export const getAgentEndCallReasons = (id: number, params: any) =>
  apiClient.get(`/analytics/agent/${id}/end-call-reason`, { params });
