import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { show } from "@intercom/messenger-js-sdk";
import { loadStripe } from "@stripe/stripe-js";
import { useSubscriptionStore } from "../../store/subscription.store";
import useSharedStore from "../../store/shared.store";
import { logout } from "../../lib/common.utils";
import { BillingDetails } from "../../models/billing-details";
import { SubscriptionPlan } from "../../models/subscription_plan";
import Toaster from "./customToast";
import { useAgencyStore } from "../../store/agency.store";
import { Button } from "../shadcn/button";
import { Tabs, TabsList, TabsTrigger } from "../shadcn/tabs";
import SubscriptionPlanCard from "./SubscriptionPlan";
import BillingForm from "./BillingForm";
import { Skeleton } from "../shadcn/skeleton";
import useWalletStore from "../../store/wallet.store";
import { ArrowLeft } from "lucide-react";

const SubscriptionExpired = () => {
  const [plansScreen, setPlansScreen] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [loaderBuyPlan, setLoaderBuyPlan] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [billingForm, setBillingForm] = useState(false);
  const [checkoutObject, setCheckoutObject] = useState<any>(null);
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [billingDetails, setBillingDetails] = useState<BillingDetails[]>([]);
  const [duration, setDuration] = useState("monthly");

  const location = useLocation();
  const navigate = useNavigate();
  const subscriptionStore = useSubscriptionStore();
  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();
  const walletStore = useWalletStore();

  useEffect(() => {
    const currentUrl = location.pathname;
    if (currentUrl.includes("onboarding-plans")) {
      setIsOnboarding(true);
      setPlansScreen(true);
    }

    fetchInitialData();
  }, [location.pathname]);

  const fetchInitialData = async () => {
    try {
      const plansResponse = await subscriptionStore.getPlans();
      if (plansResponse.data) {
        let plansList: SubscriptionPlan[] = [];
        plansResponse.data.forEach((element: any) => {
          plansList.push(new SubscriptionPlan(element));
        });
        setPlans([...plansList]);
        if (plansResponse.data.length) {
          const lastPlan = plansResponse.data[plansResponse.data.length - 1];
          const diff = lastPlan.monthly_price - lastPlan.yearly_price;
          setPercentage(Math.round((diff / lastPlan.monthly_price) * 100));
        }
      }
      const resp = await subscriptionStore.getBillingDetails();
      console.log(resp);
      if (resp.code === 200) {
        let responseList = resp.data;
        let billingDetails: BillingDetails[] = [];
        responseList?.forEach((element: any) => {
          billingDetails.push(new BillingDetails(element));
        });
        subscriptionStore.setBillingDetails(billingDetails);
        setBillingDetails(billingDetails);
      }
      getAddOns();
    } catch (error) {
      console.error(error);
    }
  };

  const getAddOns = () => {
    subscriptionStore.getAddOns().then((resp) => {
      const responseList = resp.data;
      responseList?.forEach((element: any) => {
        const plan = new SubscriptionPlan(element);
        if (plan?.add_on_type === "agent") {
          walletStore.setAiAgentAddOnDetails(plan);
        }
        if (plan?.add_on_type === "number") {
          walletStore.setNumberAddOnDetails(plan);
        }
        if (plan?.add_on_type === "infobase") {
          walletStore.setInfobaseAddOnDetails(plan);
        }
        if (plan?.add_on_type === "user") {
          walletStore.setUserAddOnDetails(plan);
        }
        if (plan?.add_on_type === "concurrent_call") {
          walletStore.setConcurrentAddOnDetails(plan);
        }
      });
    });
  };

  const buyPlan = (plan: SubscriptionPlan, term: string) => {
    if (sharedStore.business?.partner?.name) {
      if (billingDetails.length > 0 && billingDetails[0]?.id) {
        setLoaderBuyPlan(plan?.id);
        const input = {
          plan_id: plan?.id,
          billing_id: billingDetails[0]?.id,
          duration: term,
          success_url: isOnboarding
            ? "/payment-verification?order_id="
            : "/billing/wallet?order_id=",
          failed_url: "/onboarding-plans",
        };
        subscriptionStore
          .createSubscriptionOrder(input)
          .then((response) => {
            localStorage.setItem("payment_id", response.data.payment_id);
            const stripeKeys = sharedStore.paymentGatewayKeys;
            loadStripe(
              stripeKeys.is_default ? stripeKeys.default : stripeKeys.stripe
            ).then((stripe) => {
              if (stripe) {
                stripe.redirectToCheckout({
                  sessionId: response.data.payment_id,
                });
              }
            });
            setLoaderBuyPlan(0);
          })
          .catch((error) => {
            Toaster.error(error.message);
            setLoaderBuyPlan(0);
          });
      } else {
        setBillingForm(true);
        setCheckoutObject({
          term: term,
          plan: plan,
        });
      }
    } else {
      redirectToStripe(plan, term);
    }
  };

  const redirectToStripe = (plan: SubscriptionPlan, term: string) => {
    setLoaderBuyPlan(plan?.id);
    const input = {
      plan_id: plan?.id,
      billing_id: billingDetails[0]?.id,
      duration: term,
      success_url: isOnboarding
        ? "/payment-processing?order_id="
        : "/billing/wallet?order_id=",
      failed_url: "/onboarding-plans",
    };
    subscriptionStore
      .buyStripePlan(input)
      .then((response) => {
        setLoaderBuyPlan(0);
        window.open(response.data.url);
      })
      .catch((error) => {
        Toaster.error(error.message);
        setLoaderBuyPlan(0);
      });
  };

  const handleBillingAdded = (billing: BillingDetails) => {
    setBillingForm(false);
    buyPlan(checkoutObject?.plan, checkoutObject?.term);
  };

  const handleMailTo = () => {
    window.location.href = `mailto:${agencyStore.supportEmail}`;
  };

  const handleIntercom = () => {
    show();
  };

  return (
    <div>
      {plansScreen ? (
        <div className="fixed z-99 bg-bg-color p-6 w-full h-full left-0 overflow-auto">
          <div className="absolute w-full flex justify-between p-12 pt-4">
            <Button variant="ghost" onClick={() => setPlansScreen(false)}>
              <ArrowLeft /> Back
            </Button>
            <Button variant="ghost" onClick={() => logout(navigate)}>
              Logout
            </Button>
          </div>
          <div className="flex flex-col gap-4 p-6 w-full justify-center items-center">
            <h2 className="text-lg font-bold text-primary flex gap-8">
              Choose the plan that’s right for you
            </h2>
            <p className="text-sm font-normal text-secondary">
              Flexible Pricing to Fit Your Unique Needs
            </p>
            <Tabs defaultValue={duration}>
              <TabsList>
                <TabsTrigger
                  onClick={() => {
                    setDuration("monthly");
                  }}
                  value="monthly"
                >
                  Monthly
                </TabsTrigger>
                <TabsTrigger
                  onClick={() => {
                    setDuration("annually");
                  }}
                  value="annually"
                >
                  Annually{" "}
                  <span
                    className="bg-[#EDEBFE] px-2 py-1 rounded-md text-[10px] font-medium text-[#5521B5]"
                    style={{ lineHeight: "12px" }}
                  >
                    Save {percentage}%
                  </span>
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <div className="flex gap-4 p-6 items-start">
              {plans.length
                ? plans.map((plan: SubscriptionPlan) => (
                    <SubscriptionPlanCard
                      key={plan.id}
                      plan={plan}
                      isMonthly={duration === "monthly"}
                      onPlanBuy={(data) => buyPlan(data.plan, data.duration)}
                      loader={loaderBuyPlan === plan?.id}
                      numberAddOnDetails={walletStore.numberAddOnDetails}
                      aiAgentAddOnDetails={walletStore.aiAgentAddOnDetails}
                      userAddOnDetails={walletStore.userAddOnDetails}
                      infobaseAddOnDetails={walletStore.infobaseAddOnDetails}
                      concurrentAddOnDetails={
                        walletStore.concurrentAddOnDetails
                      }
                    />
                  ))
                : [1, 2, 3].map((i) => (
                    <div
                      key={i}
                      className="border border-[var(--border-color)] max-w-[325px] w-full min-w-[240px] rounded-lg p-4 flex flex-col gap-4"
                    >
                      <div className="w-full gap-4 flex flex-col">
                        {Array.from({ length: 10 }).map((_, index) => (
                          <Skeleton key={index} className="h-8 w-full" />
                        ))}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed z-99 bg-bg-color p-6 w-full h-full left-0 overflow-auto">
          <div className="absolute w-full flex justify-between p-12 pt-4">
            <span></span>
            <Button variant="ghost" onClick={() => logout(navigate)}>
              Logout
            </Button>
          </div>
          <div className="w-full h-full flex justify-center items-center flex-col gap-4">
            <img
              src="../../../../assets/images/subscription_expired.png"
              alt="Subscription Expired"
            />
            <div className="flex flex-col gap-4 items-center">
              <h3 className="text-lg font-bold text-primary flex gap-8">
                Your subscription has expired!
              </h3>
              <p className="text-sm font-normal text-secondary">
                To continue using {agencyStore.brand}, you need to pick a plan.
              </p>
            </div>
            <div className="flex gap-4 items-center font-normal text-lg text-secondary-color text-center">
              <Button onClick={() => setPlansScreen(true)}> Renew now</Button>
              <span>or</span>
              <Button onClick={handleMailTo}> Contact support</Button>
            </div>
          </div>
        </div>
      )}

      {billingForm && (
        <BillingForm
          onClose={() => setBillingForm(false)}
          onSave={handleBillingAdded}
        />
      )}
    </div>
  );
};

export default SubscriptionExpired;
