import apiClient from '../interceptors/axiosApiInterceptor';

export const getWebhooks = (page: number) =>
  apiClient.get(`/business/manage-webhooks?page=${page}`);

export const getActiveWebhooks = (page: number) =>
  apiClient.get(`/business/manage-webhooks?is_enabled=true&page=${page}`);

export const addWebhook = (obj: any) =>
  apiClient.post('/business/manage-webhooks/', obj);

export const updateWebhook = (obj: any, id: number) =>
  apiClient.put(`/business/manage-webhooks/${id}/`, obj);

export const deleteWebhook = (id: number) =>
  apiClient.delete(`/business/manage-webhooks/${id}`);

export const searchWebhook = (tag: string, page: number) =>
  apiClient.get(`/search/event-webhooks?q=${tag}&page=${page}`);
