import React, { useEffect, useState } from "react";
import { Minus, Plus } from "lucide-react";

interface NumberStepperProps {
  value: number;
  min?: number;
  max?: number;
  onValueChange: (value: number) => void;
}

const NumberStepper: React.FC<NumberStepperProps> = ({
  value,
  min = 0,
  max = 5000,
  onValueChange,
}) => {
  const [inputValue, setInputValue] = useState<number>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value])

  const handleIncrease = () => {
    if (inputValue < max) {
      const newValue = inputValue + 1;
      setInputValue(newValue);
      onValueChange(newValue);
    }
  };

  const handleDecrease = () => {
    if (inputValue > min) {
      const newValue = inputValue - 1;
      setInputValue(newValue);
      onValueChange(newValue);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    if (newValue >= min && newValue <= max) {
      setInputValue(newValue);
      onValueChange(newValue);
    } else if (newValue < min) {
      newValue = min;
      setInputValue(newValue);
      onValueChange(newValue);
    } else if (newValue > max) {
      newValue = max;
      setInputValue(newValue);
      onValueChange(newValue);
    }
  };

  return (
    <div className="flex items-center border rounded-md bg-gray-100 overflow-hidden">
      <button
        className="h-9 w-10 flex items-center justify-center border-r bg-gray-200 hover:bg-gray-300"
        onClick={handleDecrease}
      >
        <Minus size={16} className="text-primary" />
      </button>
      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        className="h-9 w-20 text-center border-none bg-transparent focus:outline-none text-primary"
      />
      <button
        className="h-9 w-10 flex items-center justify-center border-l bg-gray-200 hover:bg-gray-300"
        onClick={handleIncrease}
      >
        <Plus size={16} className="text-primary" />
      </button>
    </div>
  );
};

export default NumberStepper;
