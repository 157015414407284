import React, { useEffect, useState } from 'react';
import { Button } from '../../shadcn/button';
import { Input } from '../../shadcn/input';
import { Textarea } from '../../shadcn/textarea';
import { ChevronDown, EllipsisVertical, Loader2 } from 'lucide-react';
import { AgentAction } from '../../../models/agent-action';
import { updateAgentAction, addAgentAction } from '../../../lib/agents.utils';
import Toaster from '../../shared/customToast';
import { SheetContent, Sheet } from "../../shadcn/sheet";

interface KillCallActionComponentProps {
  action: AgentAction;
  isEdit?: boolean;
  agentId: number;
  onSuccess?: (data: any) => void;
  onClose: () => void;
}

const KillCallActionComponent: React.FC<KillCallActionComponentProps> = ({
  action,
  isEdit,
  agentId,
  onSuccess,
  onClose,
}) => {
  const [actionName, setActionName] = useState('');
  const [direction, setDirection] = useState('inbound');
  const [trigger, setTrigger] = useState('during_call');
  const [actionPrompt, setActionPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (action?.id && isEdit) {
      setActionName(action?.name || '');
      setTrigger(action?.trigger || 'during_call');
      setDirection(action?.call_type || 'inbound');
      setActionPrompt(action?.data?.action_prompt || '');
    }
  }, [action, isEdit]);

  const handleSubmit = async () => {

    setIsLoading(true);
    const input: Record<string, any> = {
      name: actionName,
      type: 'end_call',
      data: {
        action_prompt: actionPrompt,
      },
      trigger: trigger,
    };


    try {
      const response = isEdit
        ? await updateAgentAction(agentId, action?.id, input)
        : await addAgentAction(agentId, input);

      Toaster.success(response.data.message)
      // toastr.success(response.body.message);
      onSuccess?.(response.data.data);
    } catch (error) {
      console.error(error);
      Toaster.error(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={() => {setIsOpen(false); onClose();}}>
      <SheetContent className='p-6 w-[450px] !max-w-[450px]'>
        <div className="bg-white w-full flex flex-col h-full gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center text-primary text-lg font-semibold leading-[30px]">
              <span>End call</span>
            </div>
            <span className="text-secondary text-sm font-normal leading-[21px]">
              The agent will end the call once the condition is met.
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <span className="text-primary text-sm font-medium leading-[21px]">Action name</span>
              <Input
                placeholder="e.g. good bye"
                value={actionName}
                onChange={(e) => setActionName(e.target.value)}
                className="border p-2 rounded-lg"
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-primary text-sm font-medium leading-[21px]">When should the call end?</span>
              <Textarea
                placeholder="e.g. End the call if the user uses goodbye phrases such as 'bye,' 'goodbye,' or 'have a nice day.'"
                className="h-[100px] border p-2 rounded-lg"
                value={actionPrompt}
                onChange={(e) => setActionPrompt(e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              disabled={isLoading || actionName === '' || actionPrompt === ''}
              onClick={handleSubmit}
              className="bg-primary text-white p-2 rounded-lg"
            >
              {isLoading ? <span className="flex gap-2 align-center"><Loader2 className="animate-spin" /> Loading...</span> : <span>{isEdit ? 'Save changes' : 'Add action'}</span>}
            </Button>
          </div>
        </div>
      </SheetContent>

    </Sheet>
  );
};

export default KillCallActionComponent;
