export class CustomTool {
    private _id: number
    private _authentication: Authentication
    private _name: string
    private _description: string
    private _url: string
    private _method: string
    private _body: string
    private _json_body: any
    private _header: any;
    private _created_at: string
    private _updated_at: string
    private _is_enabled: boolean
    private _business: number
    private _added_by: AddedBy
    private _execution_message_description: string;
    private _raw_body: string;
    private _raw_url: string;

    public get raw_url(): string {
        return this._raw_url;
    }

    public set raw_url(raw_url: string) {
        this._raw_url = raw_url;
    }

  
      public get raw_body(): string {
          return this._raw_body;
      }
  
      public set raw_body(raw_body: string) {
          this._raw_body = raw_body;
      }
  
  
      public get execution_message_description(): string {
          return this._execution_message_description;
      }
  
      public set execution_message_description(execution_message_description: string) {
          this._execution_message_description = execution_message_description;
      }
  
  
    constructor(customToolJson: any) {
      this._id = customToolJson?.id;
      this._authentication = new Authentication(customToolJson?.authentication);
      this._name = customToolJson?.name;
      this._description = customToolJson?.description;
      this._url = customToolJson?.url;
      this._method = customToolJson?.method;
      this._body = customToolJson?.body;
      this._json_body = (customToolJson?.json_body);
      this._header = customToolJson?.header;
      this._created_at = customToolJson?.created_at;
      this._updated_at = customToolJson?.updated_at;
      this._is_enabled = customToolJson?.is_enabled;
      this._business = customToolJson?.business;
      this._added_by = new AddedBy(customToolJson?.added_by);
      this._execution_message_description = customToolJson?.execution_message_description;
      this._raw_body = customToolJson?.raw_body;
      this._raw_url = customToolJson?.raw_url;
    }
  
      public get id(): number {
          return this._id;
      }
  
      public set id(id: number) {
          this._id = id;
      }
  
      public get authentication(): Authentication {
          return this._authentication;
      }
  
      public set authentication(authentication: Authentication) {
          this._authentication = authentication;
      }
  
      public get name(): string {
          return this._name;
      }
  
      public set name(name: string) {
          this._name = name;
      }
  
      public get description(): string {
          return this._description;
      }
  
      public set description(description: string) {
          this._description = description;
      }
  
      public get url(): string {
          return this._url;
      }
  
      public set url(url: string) {
          this._url = url;
      }
  
      public get method(): string {
          return this._method;
      }
  
      public set method(method: string) {
          this._method = method;
      }
  
      public get body(): string {
          return this._body;
      }
  
      public set body(body: string) {
          this._body = body;
      }
  
      public get json_body(): any {
          return this._json_body;
      }
  
      public set json_body(json_body: any) {
          this._json_body = json_body;
      }
  
      public get header(): any {
          return this._header;
      }
  
      public set header(header: any) {
          this._header = header;
      }
  
      public get created_at(): string {
          return this._created_at;
      }
  
      public set created_at(created_at: string) {
          this._created_at = created_at;
      }
  
      public get updated_at(): string {
          return this._updated_at;
      }
  
      public set updated_at(updated_at: string) {
          this._updated_at = updated_at;
      }
  
      public get is_enabled(): boolean {
          return this._is_enabled;
      }
  
      public set is_enabled(is_enabled: boolean) {
          this._is_enabled = is_enabled;
      }
  
      public get business(): number {
          return this._business;
      }
  
      public set business(business: number) {
          this._business = business;
      }
  
      public get added_by(): AddedBy {
          return this._added_by;
      }
  
      public set added_by(added_by: AddedBy) {
          this._added_by = added_by;
      }
  
  }
  
  export class AddedBy {
    private _id: number
    private _name: string
  
    constructor(addedByJson: any) {
      this._id = addedByJson?.id;
      this._name = addedByJson?.name;
    }
  
      public get id(): number {
          return this._id;
      }
  
      public set id(id: number) {
          this._id = id;
      }
  
      public get name(): string {
          return this._name;
      }
  
      public set name(name: string) {
          this._name = name;
      }
  
  }
  
  export class Authentication {
    private _auth_name: string
    private _auth_value: string
  
    constructor(authenticationJson: any) {
      this._auth_name = authenticationJson?.auth_name;
      this._auth_value = authenticationJson?.auth_value;
    }
  
      public get auth_name(): string {
          return this._auth_name;
      }
  
      public set auth_name(auth_name: string) {
          this._auth_name = auth_name;
      }
  
      public get auth_value(): string {
          return this._auth_value;
      }
  
      public set auth_value(auth_value: string) {
          this._auth_value = auth_value;
      }
  
  }
  