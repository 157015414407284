import { assignAgentToNumber, getAgentTemplate } from "../../../lib/agents.utils";
import { getInboxesData, getWidgetsData } from "../../../lib/common.utils";
import { AgentTemplate } from "../../../models/agent-templates";
import { AiAgent } from "../../../models/ai-agent";
import { CloudNumber } from "../../../models/cloud-number";
import { Widget } from "../../../models/widget";
import { useAgencyStore } from "../../../store/agency.store";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Toaster from "../../shared/customToast";
import { updateWidget } from "../../../lib/widget.utils";
import { Button } from "../../shadcn/button";
import WelcomeForm1 from "./WelcomeForm1";
import WelcomeForm2 from "./WelcomeForm2";
import WelcomeForm3 from "./WelcomeForm3";
import WelcomeForm4 from "./WelcomeForm4";


const WelcomeComponent = () => {
  const [welcomeForm, setWelcomeForm] = useState(0);
  const [agentTemplates, setAgentTemplates] = useState<AgentTemplate[]>([]);
  const [createdAgent, setCreatedAgent] = useState<AiAgent>(new AiAgent(null));
  const [number, setNumber] = useState<CloudNumber>(new CloudNumber(null));
  const [widget, setWidget] = useState<Widget>(new Widget(null));

  const navigate = useNavigate();
  const agencyStore = useAgencyStore();

  useEffect(() => {
    getAgentTemplate().then((res) => {
      let responseList = res.data.data;
      responseList?.forEach((element: any) => {
        element = new AgentTemplate(element);
      })
      setAgentTemplates(responseList);
    }).catch((error) => console.error(error));
  }, []);

  const skip = () => navigate("/getting-started");

  const agentCreated = (agent: AiAgent) => {
    setCreatedAgent(agent);
    setWelcomeForm(2);
  };

  const numberAdded = (newNumber: CloudNumber) => {
    setNumber(newNumber);
    setWelcomeForm(4);
    assignAgent();
  };

  const widgetAdded = (newWidget: Widget) => {
    setWidget(newWidget);
    setWelcomeForm(4);
    assignAgent();
  };

  const assignAgent = () => {
    if(number?.id) {
      let input = {
        agent_id: createdAgent?.id,
      }
      assignAgentToNumber(number?.id,input).then((resp) => {
        getInboxesData()
      }).catch((error) => {
        console.log(error)
        Toaster.error(error);
      })
    }
    if(widget?.id) {
      let input = {
        agent: createdAgent?.id,
      }
      updateWidget(input, widget?.id).then((resp) => {
        getWidgetsData();
      }).catch((error) => {
        console.log(error)
        Toaster.error(error);
      })
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center bg-[var(--bg-color)] fixed top-0 left-0">
      {welcomeForm === 0 && (
        <div className="flex flex-col gap-6 rounded-lg p-6 w-96 items-center bg-white">
          <div className="flex flex-col gap-1 items-center">
            {agencyStore.favicon && <img className="w-12 h-12" src={agencyStore.favicon} alt="" />}
          </div>
          <div className="flex flex-col gap-1 items-center">
            <span className="text-2xl font-semibold text-[var(--primary-color)]">Welcome to {agencyStore.brand}</span>
          </div>
          <div className="flex flex-col gap-1 items-center">
            <span className="text-lg font-medium text-[var(--secondary-color)]">
              Your {agencyStore?.isAgency ? "7-day free trial" : "subscription"} is now active!
            </span>
          </div>
          <div className="flex flex-col gap-1 items-center">
            <span className="text-sm font-normal text-[var(--secondary-color)] text-center">
              Start building the AI Voice Agents that Understand, Interact, and Speak.
            </span>
          </div>
          <Button  onClick={() => setWelcomeForm(1)}>Get Started</Button>
        </div>
      )}
      {welcomeForm === 1 && <WelcomeForm1 agentTemplates={agentTemplates} onSkip={skip} onNext={agentCreated} />}
      {welcomeForm === 2 && <WelcomeForm2 agent={createdAgent} onDeploy={() => setWelcomeForm(3)} onSkip={skip} onBack={() => setWelcomeForm(1)} />}
      {welcomeForm === 3 && (
        <WelcomeForm3
          isNumberAdded={!!number?.id}
          isWidgetAdded={!!widget?.id}
          onNumberAdded={numberAdded}
          onWidgetAdded={widgetAdded}
          onContinue={() => setWelcomeForm(4)}
          onBack={() => setWelcomeForm(2)}
          onSkip={skip}
        />
      )}
      {welcomeForm === 4 && (
        <WelcomeForm4 agent={createdAgent} number={number} widget={widget} onSkip={skip} onBack={() => setWelcomeForm(3)} onNext={function (): void {
          throw new Error("Function not implemented.");
        } } />
      )}
    </div>
  );
};

export default WelcomeComponent;
