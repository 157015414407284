import { formatDuration } from "../../lib/ui.utils";
import { useRetellStore } from "../../store/retell.store";
import useSharedStore from "../../store/shared.store";
import { useVapiStore } from "../../store/vapi.store";
import { useWebSocketStore } from "../../store/websocket.store";
import {
  Captions,
  CaptionsOff,
  Loader2,
  PhoneOff,
  Sparkles,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { interval, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ScrollArea } from "../shadcn/scrollArea";
import Draggable from "react-draggable";

const LiveCall = () => {
  const [callStarted, setCallStarted] = useState(false);
  const callStartedRef = useRef(callStarted);
  const [duration, setDuration] = useState(0);
  const [callEnded, setCallEnded] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcript, setTranscript] = useState<any[]>([]);
  const [isRetell, setIsRetell] = useState(true);
  const [callId, setCallId] = useState("");
  const callIdRef = useRef(callId);
  const destroy$ = useRef(new Subject());

  const retellStore = useRetellStore();
  const sharedStore = useSharedStore();
  const vapiStore = useVapiStore();
  const websocketStore = useWebSocketStore();
  const scrollBottom = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const audio = new Audio("/assets/call_start.mp3");
    audio.load();
    audio.play();
    setAudioPlaying(true);
    audio.addEventListener("ended", () => {
      setAudioPlaying(false);
    });

    const retellSubscription = retellStore.callLiveEvents.subscribe((data) => {
      if (!callStarted) {
        if (data.type === "call_started") {
          setIsRetell(true);
          setCallId(data.call_id);
          callIdRef.current = data?.call_id;
          setTranscript([]);
          setShowTranscript(true);
          startCountDown();
          setCallStarted(true);
          callStartedRef.current = true;
          console.log(data);
        }
      }
    });

    const vapiSubscription = vapiStore.callLiveEvents.subscribe((data) => {
      if (data === "call-end") killVapiCall();
      if (!callStarted) {
        if (data.type === "call_started") {
          setIsRetell(false);
          setCallId(data.call_id);
          callIdRef.current = data?.call_id;
          setTranscript([]);
          setShowTranscript(true);
          startCountDown();
          setCallStarted(true);
          callStartedRef.current = true;
          console.log(data);
        }
      }
    });

    const webSocketSubscription = websocketStore.callEvents$.subscribe(
      (event) => {
        if (event) {
          console.log(event);
          if (
            event.call_id &&
            callIdRef.current === event.call_id &&
            !retellStore.callIdSet.has(callIdRef.current)
          ) {
            if (
              event?.call_status === "ended" &&
              !callEnded &&
              callStartedRef.current
            ) {
              retellStore.callIdSet.add(callIdRef.current);
              setCallId("");
              callIdRef.current = "";
              websocketStore.callEvents$.next(null);
              console.log(event?.call_id);
              killCall();
            }
          }
        }
      }
    );

    const retellTranscriptSubscription =
      retellStore.liveCallTranscript.subscribe((event) => {
        setTranscript(event);
        scrollToBottom();
      });

    const vapiTranscriptSubscription = vapiStore.liveCallTranscript.subscribe(
      (event) => {
        setTranscript(event);
        scrollToBottom();
      }
    );

    return () => {
      destroy$.current.next(null);
      destroy$.current.complete();
      retellSubscription.unsubscribe();
      vapiSubscription.unsubscribe();
      webSocketSubscription.unsubscribe();
      retellTranscriptSubscription.unsubscribe();
      vapiTranscriptSubscription.unsubscribe();
    };
  }, []);

  const scrollToBottom = () => {
    try {
      setTimeout(() => {
        if (scrollBottom.current) {
          scrollBottom.current!.scrollTop = scrollBottom.current!.scrollHeight;
          console.log("inside scrollbottom");
        }
      }, 100);
    } catch (err) {}
  };

  const startCountDown = () => {
    interval(1000)
      .pipe(takeUntil(destroy$.current))
      .subscribe(() => setDuration((prevDuration) => prevDuration + 1));
  };

  const killCall = () => {
    setCallEnded(true);
    if (!audioPlaying) {
      const audio = new Audio("/assets/call_end.mp3");
      audio.load();
      audio.play();
    }
    setCallStarted(false);
    destroy$.current.next(null);
    destroy$.current.complete();
    retellStore.stopCall();
  };

  const killVapiCall = () => {
    setCallEnded(true);
    if (!audioPlaying) {
      const audio = new Audio("/assets/call_end.mp3");
      audio.load();
      audio.play();
    }
    setCallStarted(false);
    destroy$.current.next(null);
    destroy$.current.complete();
    vapiStore.stopCall();
  };

  return (
    <Draggable>
      <div className="flex fixed bottom-4 right-4 border border-purple-700 bg-gray-900 p-6 gap-4 rounded-lg flex-col items-center min-w-[360px] z-[101]">
        <div
          className={`flex justify-between items-center w-full gap-4 ${
            showTranscript ? "flex-row" : "flex-col"
          }`}
        >
          <span
            className={`flex items-center gap-4 text-[#FFF] ${
              showTranscript ? "flex-row text-left" : "flex-col text-center"
            }`}
          >
            <img
              src={
                retellStore?.liveAgent?.profile_pic ??
                "../../../../assets/images/avatar.png"
              }
              alt=""
              className="h-12 w-12 rounded-full"
            />
            {retellStore?.liveAgent?.agent_name}
          </span>
          <span className="flex items-center gap-4">
            {showTranscript && (
              <span className="flex items-center gap-1 text-purple-600 text-sm font-medium">
                Live Transcript <Sparkles size="18px" color="#9333EA" />
              </span>
            )}
            {callStarted && (
              <span className="text-white text-sm font-medium">
                {formatDuration(duration)}
              </span>
            )}
          </span>
        </div>
        {showTranscript && (
          <div className="w-full">
            <div
              className="flex flex-col gap-4 h-[250px] w-[464px] overflow-auto pt-1"
              ref={scrollBottom}
            >
              {transcript.map((message, index) => (
                <div
                  key={index}
                  className={`flex flex-col gap-1 mt-1 ${
                    message?.role === "user" ? "items-end" : ""
                  }`}
                >
                  <div className="flex gap-1 text-sm font-normal items-center">
                    {(message?.role === "agent" ||
                      message?.role === "assistant") && (
                      <div className="relative bg-gray-800 flex items-center pl-5 rounded-lg">
                        <span className="absolute left-0 h-5 w-5 bg-gray-400 rounded-full flex items-center justify-center">
                          <img
                            src={
                              retellStore?.liveAgent?.profile_pic ??
                              "../../../../assets/images/avatar.png"
                            }
                            alt=""
                            className="h-5 w-5 rounded-full"
                          />
                        </span>
                        <span className="text-white text-sm font-normal pl-1 pr-2">
                          {retellStore?.liveAgent?.agent_name}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="p-1 text-sm font-normal text-white max-w-[80%] w-fit whitespace-pre-wrap break-words rounded-lg bg-gray-800">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message?.content ?? message?.transcript,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {callStarted && (
          <div className="w-full pt-4 flex justify-center items-center gap-12">
            <button
              className="bg-gray-300 border-none h-11 w-11 rounded-full flex items-center justify-center p-0"
              onClick={() => setShowTranscript(!showTranscript)}
            >
              {showTranscript ? (
                <Captions size="24px" color="#020617" />
              ) : (
                <CaptionsOff size="24px" color="#020617" />
              )}
            </button>
            <button
              className="bg-red-600 border-none h-11 w-11 rounded-full flex items-center justify-center p-0"
              onClick={isRetell ? killCall : killVapiCall}
            >
              <PhoneOff size="20px" color="#F3E8FF" />
            </button>
          </div>
        )}
        {!callStarted && (
          <div className="h-12 flex items-center justify-center">
            <Loader2 color="#FFFFFF" className="animate-spin" />
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default LiveCall;
