import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import { Button } from "../../shadcn/button";
import Toaster from "../../shared/customToast";
import { CustomTool } from "../../../models/custom-tool";
import {
  addCustomTools,
  updateCustomTools,
} from "../../../lib/custom-tools.utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown-menu";
import { ChevronDown, Plus, Trash } from "lucide-react";
import { Switch } from "../../shadcn/switch";
import { Textarea } from "../../shadcn/textarea";
import { ScrollArea } from "../../shadcn/scrollArea";
import CommandsInput from "../../shared/CommandsInput";
import { convert } from "html-to-text";
import { getActionVariables } from "../../../lib/agents.utils";

interface AddCustomToolProps {
  onClose: () => void;
  onSuccess: (tool: CustomTool) => void;
  onEdit: (tool: CustomTool) => void;
  tool?: CustomTool;
}

const AddCustomTool: React.FC<AddCustomToolProps> = ({
  onClose,
  onSuccess,
  onEdit,
  tool,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [currentTab, setCurrentTab] = useState("endpoint");
  const [method, setMethod] = useState("post");
  const [url, setUrl] = useState("");
  const [urlHtml, setUrlHtml] = useState("");
  const [bodyType, setBodyType] = useState("json");
  const [body, setBody] = useState("");
  const [bodyHtml, setBodyHtml] = useState("");
  const [headers, setHeaders] = useState([{ key: "", value: "" }]);
  const [authKey, setAuthKey] = useState("");
  const [authKeyValue, setAuthKeyValue] = useState("");
  const [customFieldVariables, setCustomFieldVariables] = useState<any[]>([]);
  const [speakMessage, setSpeakMessage] = useState("");
  const [runInBackground, setRunInBackground] = useState(false);
  const [loaderTestTool, setLoaderTestTool] = useState(false);

  useEffect(() => {
    if (tool) {
      setName(tool.name);
      setDescription(tool.description);
      setUrlHtml(tool.raw_url);
      setUrl(replaceSpansWithVariables(tool.raw_url));
      setBodyHtml(tool.raw_body);
      setMethod(tool.method);
      setBody(replaceSpansWithVariables(tool.raw_body));
      setHeaders(
        Object.entries(tool.header).map(([key, value]) => ({
          key,
          value: value as string,
        }))
      );
      setSpeakMessage(tool.execution_message_description ?? "");
      setBodyType(tool.json_body ? "json" : "text");
      setRunInBackground(!tool?.execution_message_description || tool.execution_message_description === "");
      setAuthKey(tool.authentication?.auth_name ?? "");
      setAuthKeyValue(tool.authentication?.auth_value ?? "");
    } else {
      addHeader();
    }

    getActionVariables("during_call", {})
      .then((resp) => {
        let responseList = resp.data.data;
        let variableList = [];
        for (var index = 0; index < responseList?.length; index++) {
          let variableObj = {
            id: index,
            field: responseList[index]?.field,
            label: responseList[index]?.label,
            type: responseList[index]?.type,
          };
          variableList.push(variableObj);
        }
        setCustomFieldVariables(variableList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tool]);

  const handleContentChange = (text: string) => {
    setBodyHtml(text);
    setBody(replaceSpansWithVariables(text));
  };

  const handleUrlChange = (text: string) => {
    setUrlHtml(text);
    setUrl(replaceSpansWithVariables(text));
  };

  const handleAddTool = async () => {
    if (method !== "get" && method !== "delete" && bodyType !== "text") {
      try {
        JSON.parse(body);
      } catch {
        Toaster.error("JSON structure is invalid");
        return;
      }
    }

    if (
      (authKey === "" && authKeyValue === "") ||
      (authKey !== "" && authKeyValue !== "")
    ) {
    } else {
      Toaster.error("Authentication fields data is invalid");
      return;
    }

    const headersList: Record<string, string> = {};
    if (bodyType === "json") headersList["content-type"] = "application/json";
    else headersList["content-type"] = "text/plain";

    headers.forEach((header) => {
      if (header.key && header.value) {
        headersList[header.key] = header.value;
      }
    });

    const input: Record<string, any> = {
      name,
      description,
      url,
      method,
      json_body:
        method === "get" || method === "delete"
          ? null
          : bodyType === "text"
          ? null
          : JSON.parse(body),
      authentication:
        authKey && authKeyValue
          ? { auth_name: authKey, auth_value: authKeyValue }
          : null,
      raw_body: method === "get" || method === "delete" ? null : bodyHtml,
      raw_url: urlHtml,
      body:
        method === "get" || method === "delete"
          ? null
          : bodyType === "json"
          ? null
          : body,
      execution_message_description: runInBackground ? null : speakMessage,
      header: Object.keys(headersList).length > 0 ? headersList : null,
    };

    setIsLoading(true);
    try {
      if (tool?.id) {
        const response = await updateCustomTools(input, tool.id);
        Toaster.success(response.data.message);
        onEdit(new CustomTool(response.data.data));
      } else {
        const response = await addCustomTools(input);
        Toaster.success(response.data.message);
        onSuccess(new CustomTool(response.data.data));
      }
    } catch (error: any) {
      Toaster.error(JSON.stringify(error?.message ?? error));
    } finally {
      setIsLoading(false);
    }
  };

  const addHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  const removeHeader = (index: number) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const contentChange = (text: string) => {
    setBodyHtml(text);
    setBody(replaceSpansWithVariables(text));
  };

  const replaceSpansWithVariables = (html: string): string => {
    // Create a temporary DOM element to parse the HTML string
    const div = document.createElement("div");
    div.innerHTML = html;

    // Find all span elements with the 'variable' attribute
    const spans = div.querySelectorAll("span[variable]");

    spans.forEach((span) => {
      const variableName = span.getAttribute("variable");
      if (variableName) {
        span.replaceWith(`{{${variableName}}}`);
      }
    });

    // Convert HTML to plain text, while preserving formatting options
    let plainText = convert(div.innerHTML, {
      wordwrap: 100, // Set the word wrap column
      selectors: [
        { selector: "a", options: { ignoreHref: true, uppercase: false } }, // Ignore href in anchor tags
        { selector: "img", format: "skip" }, // Skip images
        { selector: "h1", options: { uppercase: false } },
        { selector: "h2", options: { uppercase: false } },
        { selector: "h3", options: { uppercase: false } },
        { selector: "h4", options: { uppercase: false } },
        { selector: "h5", options: { uppercase: false } },
        { selector: "h6", options: { uppercase: false } },
        { selector: "p", options: { uppercase: false } },
      ],
      preserveNewlines: true,
    });

    // Return the processed plain text
    return plainText;
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <ScrollArea className="h-full flex">
        <DialogContent
          className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px overflow-auto"
          style={{ maxWidth: "566px", maxHeight: "653px" }}
        >
          <div className="flex justify-between flex-col gap-2 text-[var(--primary-color)] text-lg font-inter font-semibold text-20 leading-30">
            <span>{tool?.id ? "Edit action" : "New action"}</span>
            <span className="text-sm text-gray-500 font-normal">
              Manage all custom actions in your workspace
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1 w-400px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
                Action name
              </span>
              <Input
                type="text"
                placeholder="e.g. CRM entry"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1 w-400px">
              <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
                Description
              </span>
              <Input
                type="text"
                placeholder="e.g. Describe the purpose and functionality of the action, e.g. Logs customer inquiries into the CRM system."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="agent-details-tab-group flex items-center border-b border-gray-300">
                <div
                  className={` ${
                    currentTab === "endpoint"
                      ? "border-b-[3px] border-b-black text-[var(--primary-color)]"
                      : "border-b-[3px] border-b-white text-[var(--secondary-color)]"
                  } flex items-center flex-col cursor-pointer`}
                  onClick={() => setCurrentTab("endpoint")}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  <span className="px-3 pb-2.5">Endpoint</span>
                </div>
                {(method === "post" ||
                  method === "put" ||
                  method === "patch") && (
                  <div
                    className={` ${
                      currentTab === "body"
                        ? "border-b-[3px] border-b-black text-[var(--primary-color)]"
                        : "border-b-[3px] border-b-white text-[var(--secondary-color)]"
                    } flex items-center flex-col cursor-pointer`}
                    onClick={() => setCurrentTab("body")}
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "21px",
                      textAlign: "left",
                    }}
                  >
                    <span className="px-3 pb-2.5">Body</span>
                  </div>
                )}
                <div
                  className={` ${
                    currentTab === "headers"
                      ? "border-b-[3px] border-b-black text-[var(--primary-color)]"
                      : "border-b-[3px] border-b-white text-[var(--secondary-color)]"
                  } flex items-center flex-col cursor-pointer`}
                  onClick={() => setCurrentTab("headers")}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  <span className="px-3 pb-2.5">Headers</span>
                </div>
                <div
                  className={` ${
                    currentTab === "authentication"
                      ? "border-b-[3px] border-b-black text-[var(--primary-color)]"
                      : "border-b-[3px] border-b-white text-[var(--secondary-color)]"
                  } flex items-center flex-col cursor-pointer`}
                  onClick={() => setCurrentTab("authentication")}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  <span className="px-3 pb-2.5">Authentication</span>
                </div>
              </div>
              {currentTab === "endpoint" && (
                <div className="flex gap-2 items-baseline">
                  <div className="flex flex-col gap-1">
                    <span className="text-[var(--primary-color)] font-medium text-sm">
                      Method
                    </span>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          className="w-32 flex justify-between font-normal"
                        >
                          <span className="flex items-center gap-2">
                            {method.toUpperCase()}
                          </span>
                          <ChevronDown />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white border border-gray-300 w-32 rounded-lg p-2 max-h-72 overflow-auto">
                        <DropdownMenuItem
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onSelect={() => setMethod("post")}
                        >
                          POST
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onSelect={() => setMethod("get")}
                        >
                          GET
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onSelect={() => setMethod("put")}
                        >
                          PUT
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onSelect={() => setMethod("patch")}
                        >
                          PATCH
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onSelect={() => setMethod("delete")}
                        >
                          DELETE
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-[var(--primary-color)] font-medium text-sm">
                      URL
                    </span>
                    <Input
                      type="text"
                      placeholder="e.g. http://example.com/test"
                      value={urlHtml}
                      onChange={(e) => handleUrlChange(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {currentTab === "body" && (
                <>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-24 flex justify-between font-normal"
                      >
                        <span className="flex items-center gap-2">
                          {bodyType.toUpperCase()}
                        </span>
                        <ChevronDown />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white border border-gray-300 w-24 rounded-lg p-2 max-h-72 overflow-auto">
                      <DropdownMenuItem
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onSelect={() => setBodyType("json")}
                      >
                        JSON
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onSelect={() => setBodyType("text")}
                      >
                        TEXT
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <div className="flex flex-col gap-1">
                    <CommandsInput
                      inputText={bodyHtml}
                      height="100px"
                      variables={customFieldVariables}
                      onChange={contentChange}
                      placeholder="Use '/' commands to add variables"
                    />
                  </div>
                </>
              )}
              {currentTab === "headers" && (
                <div>
                  <table className="w-full">
                    {headers.length > 0 && (
                      <thead>
                        <tr>
                          <th className="text-[var(--primary-color)] font-medium text-sm text-left">
                            Key
                          </th>
                          <th className="text-[var(--primary-color)] font-medium text-sm text-left">
                            Value
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {headers.map((header, index) => (
                        <tr key={index}>
                          <td className="max-w-xs mr-4">
                            <Input
                              type="text"
                              placeholder="Key"
                              value={header.key}
                              onChange={(e) => {
                                const newHeaders = [...headers];
                                newHeaders[index].key = e.target.value;
                                setHeaders(newHeaders);
                              }}
                            />
                          </td>
                          <td className="max-w-xs">
                            <Input
                              type="text"
                              placeholder="Value"
                              value={header.value}
                              onChange={(e) => {
                                const newHeaders = [...headers];
                                newHeaders[index].value = e.target.value;
                                setHeaders(newHeaders);
                              }}
                            />
                          </td>
                          <td>
                            <Trash
                              className="cursor-pointer"
                              size={"20px"}
                              onClick={() => removeHeader(index)}
                              color="rgb(100, 116, 139)"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    className="text-gray-500"
                    variant="ghost"
                    onClick={addHeader}
                  >
                    {" "}
                    <Plus size={"16px"} color="rgb(100, 116, 139)" /> Add Header
                  </Button>
                </div>
              )}
              {currentTab === "authentication" && (
                <>
                  <div className="flex flex-col gap-1">
                    <span className="text-[var(--primary-color)] font-medium text-sm">
                      Authentication Key
                    </span>
                    <Input
                      type="text"
                      placeholder="e.g. api_key"
                      value={authKey}
                      onChange={(e) => setAuthKey(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-[var(--primary-color)] font-medium text-sm">
                      Authentication Value
                    </span>
                    <Input
                      type="text"
                      placeholder="e.g. {your_api_key}"
                      value={authKeyValue}
                      onChange={(e) => setAuthKeyValue(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between gap-2">
                <span className="text-[var(--primary-color)] font-medium text-sm">
                  Run this action in background
                </span>
                <Switch
                  checked={runInBackground}
                  onCheckedChange={(checked) => setRunInBackground(checked)}
                />
              </div>
              {!runInBackground && (
                <div className="flex flex-col gap-1">
                  <span className="text-[var(--primary-color)] font-medium text-sm">
                    Speak During Execution
                  </span>
                  <Input
                    type="text"
                    placeholder="e.g. Let me check that for you."
                    value={speakMessage}
                    onChange={(e) => setSpeakMessage(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end p-2">
            <Button disabled={isLoading} onClick={handleAddTool}>
              {isLoading ? "Loading..." : tool?.id ? "Save" : "Create action"}
            </Button>
          </div>
        </DialogContent>
      </ScrollArea>
    </Dialog>
  );
};

export default AddCustomTool;
