import { getBusinessData } from "../../lib/common.utils";
import { useWebSocketStore } from "../../store/websocket.store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentProcessing = () => {
  const navigate = useNavigate();
  const websocketStore = useWebSocketStore();

  useEffect(() => {
    const subscription = websocketStore.subscriptionEvent$.subscribe({
      next: () => navigate("/welcome"),
    });

    getBusinessData()

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-gray-100 flex flex-col items-center justify-center p-16 gap-6 text-gray-800 text-lg font-medium leading-[30px]">
      Verifying the payment. Please wait...
    </div>
  );
};

export default PaymentProcessing;
