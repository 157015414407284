import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import Hover from "wavesurfer.js/dist/plugins/hover";
import CustomIcon from "./CustomIcon";
import { PauseCircle, PlayCircle, Download } from "lucide-react";
import { formatDuration } from "../../lib/ui.utils";

interface AudioStreamProps {
  audioUrl: string;
  settings?: boolean;
}

const AudioStream: React.FC<AudioStreamProps> = ({ audioUrl, settings = true }) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const waveSurfer = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [waveLoading, setWaveLoading] = useState(true);

  useEffect(() => {
    if (!waveformRef.current) return;
  
  
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#cdcdcf",
      progressColor: "var(--primary-color)",
      cursorColor: "var(--primary-color)",
      cursorWidth: 3,
      height: settings ? 60 : 30,
      plugins: [
        Hover.create({
          lineColor: "#000",
          lineWidth: 3,
          labelBackground: "#555",
          labelColor: "var(--bg-color)",
          labelSize: "12px",
        }),
      ],
    });
  
    waveSurfer.current.load(audioUrl);
  
    waveSurfer.current.on("audioprocess", () => {
      setCurrentTime(waveSurfer.current?.getCurrentTime() || 0);
    });
  
    waveSurfer.current.on("ready", () => {
      setWaveLoading(false);
      setDuration(waveSurfer.current?.getDuration() || 0);
    });
  
    waveSurfer.current.on("finish", () => {
      setIsPlaying(false);
    });
  
    return () => {
      if (waveSurfer.current) {
        try {
          // waveSurfer.current.cancelAjax()
          waveSurfer.current.destroy();
          waveSurfer.current = null;
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [audioUrl]); // Runs when audioUrl changes
  

  const toggleAudio = () => {
    if (waveSurfer.current) {
      if (isPlaying) {
        waveSurfer.current.pause();
      } else {
        waveSurfer.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const downloadAudio = () => {
    const link = document.createElement("a");
    link.setAttribute("href", audioUrl);
    link.setAttribute("download", "call_recording.mp4");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex items-center gap-4">
      {settings && <button className="p-0 border-none outline-none" onClick={() => waveSurfer.current?.skip(-10)}>
        <CustomIcon iconName="replay_10"/>
      </button>}

      <button className="mt-1 p-0 border-none outline-none" onClick={toggleAudio}>
        {isPlaying ? <PauseCircle size="20px" /> : <PlayCircle size="20px" />}
      </button>

      {settings && <button className="p-0 border-none outline-none" onClick={() => waveSurfer.current?.skip(10)}>
        <CustomIcon iconName="forward_10"/>
      </button>}

      <div className="w-full"> <div ref={waveformRef} /></div>

      <span className="flex whitespace-nowrap text-[var(--primary-color)] text-[14px] letter-spacing-[0.25px]">
        {formatDuration(currentTime)} / {formatDuration(duration)}
      </span>

      {settings && <button onClick={downloadAudio}><Download /></button>}
    </div>
  );
};

export default AudioStream;
