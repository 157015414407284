import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CirclePlus, Icon } from "lucide-react";
import Label from "./Label";
import { Label as LabelModel } from "../../models/label";
import { getLabel } from "../../lib/voice.utils";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/popover";

interface AssignLabelProps {
  assignedLabel?: any[];
  inbox: number;
  autofocus?: boolean;
  onClose: (labels: LabelModel[]) => void;
  inPopup?: boolean;
}

const AssignLabel: React.FC<AssignLabelProps> = ({
  assignedLabel = [],
  inbox,
  autofocus = false,
  onClose,
  inPopup = false,
}) => {
  const [labelsList, setLabelsList] = useState<LabelModel[]>([]);
  const [labelSet, setLabelSet] = useState(new Set<number>());
  const [showLabelsDropdown, setShowLabelsDropdown] = useState(autofocus);
  const [divHeight, setDivHeight] = useState(40);
  const labelsDivRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageNext, setPageNext] = useState<boolean>(false);
  const [loadingLabels, setLoadingLabels] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // Fetch Labels Manually
  const fetchLabels = async (page: number) => {
    setLoadingLabels(true);
    try {
      const response = await getLabel(inbox, page);
      let responseList = response.data.data;
      responseList?.forEach((element: any) => {
        element = new LabelModel(element);
      });
      setLabelsList((prev) =>
        page === 1 ? responseList : [...prev, ...responseList]
      );
      setPageNext(response.data.next);
    } catch (error) {
      console.error("Error fetching labels:", error);
    }
    setLoadingLabels(false);
  };

  // Handle Autofocus
  useEffect(() => {
    if (autofocus) {
      setOpen(true);
      setShowLabelsDropdown(true);
      fetchLabels(1);
    }
  }, [autofocus]);

  // Initialize Selected Labels
  useEffect(() => {
    const newSet = new Set<number>();
    assignedLabel.forEach((label) => {
      if (label?.id) newSet.add(label.id);
      if (label?.label_id) newSet.add(label.label_id);
    });
    setLabelSet(newSet);
  }, [assignedLabel]);

  const handleLabelClick = (labelId: number) => {
    setLabelSet((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(labelId)) newSet.delete(labelId);
      else newSet.add(labelId);
      return newSet;
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (
      scrollHeight - scrollTop - clientHeight < 50 &&
      pageNext &&
      !loadingLabels
    ) {
      setPageNumber((prev) => {
        fetchLabels(prev + 1);
        return prev + 1;
      });
    }
  };

  const closeDropdown = () => {
    setShowLabelsDropdown(false);
    const selectedLabels = labelsList.filter((label) => labelSet.has(label.id));
    onClose(selectedLabels);
  };

  return (
    <Popover modal={inPopup} open={open} onOpenChange={(value) => {
      setOpen(value)
      if(!value){
        closeDropdown();
    }
    }}>
      <PopoverTrigger asChild>
        <div
          className="flex flex-wrap gap-2 p-2 cursor-pointer items-center w-full border rounded-md"
          ref={labelsDivRef}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            autofocus = true;
          }}
        >
          {labelsList.map(
            (label) =>
              labelSet.has(label.id) && (
                <Label
                  key={label.id}
                  closeButton={open}
                  labelElement={label}
                  onClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLabelClick(label.id);
                  }}
                />
              )
          )}
          {!open && <CirclePlus size="20px" color="var(--icon-sec-color)" />}
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="z-[10200] w-64 max-h-[200px] p-2 overflow-y-auto"
        onScroll={handleScroll}
      >
        {labelsList.map(
          (label) =>
            !labelSet.has(label.id) && (
              <div
                key={label.id}
                className="p-1 rounded cursor-pointer hover:bg-muted"
                onClick={() => handleLabelClick(label.id)}
              >
                <Label labelElement={label} />
              </div>
            )
        )}
        {loadingLabels && (
          <p className="text-center text-sm text-gray-500">Loading...</p>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default AssignLabel;
