import { getAllAgents, testWebhook } from "../../../lib/agents.utils";
import { AiAgent } from "../../../models/ai-agent";
import { Webhook } from "../../../models/webhook";
import React, { useEffect, useState } from "react";
import Toaster from "../../shared/customToast";
import { addWebhook, updateWebhook } from "../../../lib/webhook.utils";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import { Switch } from "../../shadcn/switch";
import { Button } from "../../shadcn/button";
import { Loader2 } from "lucide-react";

interface AddWebhookProps {
  tool?: Webhook;
  onClose: (value: boolean) => void;
  onSuccess: (webhook: any) => void;
  onEdit: (webhook: any) => void;
}

const AddWebhook: React.FC<AddWebhookProps> = ({
  tool,
  onClose,
  onSuccess,
  onEdit,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [urlValid, setUrlValid] = useState(true);
  const [agentsList, setAgentsList] = useState<AiAgent[]>([]);
  const [selectedAgents, setSelectedAgents] = useState(new Set());
  const [callInitiatedSelected, setCallInitiatedSelected] = useState(false);
  const [callEndedSelected, setCallEndedSelected] = useState(false);
  const [callMissedSelected, setCallMissedSelected] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [loaderTestWebhook, setLoaderTestWebhook] = useState(false);

  useEffect(() => {
    if (tool?.id) {
      setName(tool.name);
      setUrl(tool.url);
      tool.agents.forEach((element) => {
        selectedAgents.add(element.agent);
        element.events.forEach((event) => {
          if (event === "call_ended") setCallEndedSelected(true);
          if (event === "call_started") setCallInitiatedSelected(true);
          if (event === "no-answer") setCallMissedSelected(true);
        });
      });
      refreshAllToggles();
    }
    getAllAgents(1, true)
      .then((resp) => {
        setAgentsList(resp.data.data.map((data: any) => new AiAgent(data)));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tool]);

  const toggleAllAgents = () => {
    if (selectedAgents.size === agentsList.length) {
      setSelectedAgents(new Set());
    } else {
      const newSelectedAgents = new Set(agentsList.map((agent) => agent.id));
      setSelectedAgents(newSelectedAgents);
    }
    refreshAllToggles();
  };

  const refreshAllToggles = () => {
    setToggleRefresh(true);
    setTimeout(() => {
      setToggleRefresh(false);
    }, 1);
  };

  const addNewWebhook = () => {
    setIsLoading(true);
    let events: any[] = [];
    if (callEndedSelected) events.push("call_ended");
    if (callInitiatedSelected) events.push("call_started");
    if (callMissedSelected) events.push("no-answer");
    const input = {
      name,
      url,
      agents: Array.from(selectedAgents).map((agentId) => ({
        agent_id: agentId,
        events,
      })),
    };

    const webhookServiceCall = tool?.id
      ? updateWebhook(input, tool.id)
      : addWebhook(input);

    webhookServiceCall
      .then((resp) => {
        Toaster.success(resp.data.message);
        setIsLoading(false);
        const webhook = new Webhook(resp.data.data);
        tool?.id ? onEdit(webhook) : onSuccess(webhook);
      })
      .catch((error) => {
        Toaster.error(error);
        setIsLoading(false);
      });
  };

  const toggleAllEvents = () => {
    const newState = !(callInitiatedSelected && callEndedSelected);
    setCallInitiatedSelected(newState);
    setCallEndedSelected(newState);
    refreshAllToggles();
  };

  const testWebhookFunc = () => {
    setLoaderTestWebhook(true);
    const input = { url };
    testWebhook(input)
      .then((resp) => {
        Toaster.success(resp.data.message);
        setLoaderTestWebhook(false);
      })
      .catch((error) => {
        Toaster.error(error);
        setLoaderTestWebhook(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose(true);
      }}
    >
      <DialogContent className="max-w-[566px] max-h-[653px] overflow-auto overflow-x-hidden">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between items-center text-primary font-inter text-lg font-semibold">
            <span>{tool?.id ? "Edit Webhook" : "Add Webhook"}</span>
          </div>
          <span className="text-gray-500 font-inter text-[14px] font-normal">
            Setup your webhook endpoint to receive live events from your
            workspace
          </span>
        </div>
        <div className="flex flex-col gap-4 w-[508px]">
          <div className="flex flex-col gap-1">
            <span className="text-primary font-inter text-base font-medium">
              Webhook name
            </span>
            <Input
              placeholder="e.g. Status webhook"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1">
          <span className="text-primary font-inter text-base font-medium">
            Endpoint URL
          </span>
          <Input
            className="w-full"
            placeholder="e.g. http://example.com/test"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-primary font-inter text-base font-medium">
            AI Agents
          </span>
          <div className="flex justify-between items-center font-inter text-base font-normal">
            <span>
              All{" "}
              <span className="text-secondary">
                {selectedAgents.size}/{agentsList.length}
              </span>
            </span>
            <Switch
              checked={selectedAgents.size === agentsList.length}
              onCheckedChange={toggleAllAgents}
            />
          </div>
          {agentsList.map((agent) => (
            <div
              className="flex justify-between items-center py-1 pl-4"
              key={agent.id}
            >
              <div className="flex items-center gap-1 bg-gray-200 rounded-lg pr-1">
                <img
                  src={agent.profile_pic}
                  alt=""
                  className="w-5 h-5 rounded-full"
                />
                <span className="max-w-[135px] truncate">
                  {agent.agent_name}
                </span>
              </div>
              <Switch
                checked={selectedAgents.has(agent.id)}
                onCheckedChange={() => {
                  const newSelectedAgents = new Set(selectedAgents);
                  if (newSelectedAgents.has(agent.id)) {
                    newSelectedAgents.delete(agent.id);
                  } else {
                    newSelectedAgents.add(agent.id);
                  }
                  setSelectedAgents(newSelectedAgents);
                  refreshAllToggles();
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-primary font-inter text-base font-medium">
            Events
          </span>
          <div className="flex justify-between items-center font-inter text-base font-normal">
            <span>
              All{" "}
              <span className="text-secondary">
                {(callEndedSelected ? 1 : 0) + (callInitiatedSelected ? 1 : 0)}
                /2
              </span>
            </span>
            <Switch
              checked={callInitiatedSelected && callEndedSelected}
              onCheckedChange={toggleAllEvents}
            />
          </div>
          <div className="flex justify-between items-center py-1 pl-4">
            <span>Call started </span>
            <Switch
              checked={callInitiatedSelected}
              onCheckedChange={() => {
                setCallInitiatedSelected(!callInitiatedSelected);
                refreshAllToggles();
              }}
            />
          </div>
          <div className="flex justify-between items-center py-1 pl-4">
            <span>Call completed </span>
            <Switch
              checked={callEndedSelected}
              onCheckedChange={() => {
                setCallEndedSelected(!callEndedSelected);
                refreshAllToggles();
              }}
            />
          </div>
        </div>
        <div className="flex justify-between p-2 pr-0">
          <Button
            variant="secondary"
            disabled={!url || !urlValid}
            onClick={testWebhookFunc}
          >
            {loaderTestWebhook ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Testing webhook...
              </>
            ) : (
              "Test webhook"
            )}
          </Button>
          <Button
            variant="default"
            disabled={
              !name ||
              !url ||
              !urlValid ||
              selectedAgents.size === 0 ||
              (!callEndedSelected && !callInitiatedSelected)
            }
            onClick={addNewWebhook}
          >
            {isLoading ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                {tool?.id ? "Saving..." : "Loading..."}
              </>
            ) : tool?.id ? (
              "Save"
            ) : (
              "Create webhook"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddWebhook;
