import React, { useEffect, useState } from 'react';
import { Button } from '../../shadcn/button';
import { Input } from '../../shadcn/input';
import { Textarea } from '../../shadcn/textarea';
import { ChevronDown, EllipsisVertical, Loader2 } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../shadcn/dropdown-menu';
import { toPascalCase } from '../../../lib/ui.utils';
import { AgentAction } from '../../../models/agent-action';
import { updateAgentAction, addAgentAction, getActionVariables } from '../../../lib/agents.utils';
import { Sheet, SheetContent } from '../../shadcn/sheet';
import { convert } from "html-to-text";
import CommandsInput from '../../shared/CommandsInput';


interface SendSmsActionComponentProps {
  action: AgentAction;
  isEdit?: boolean;
  agentId: number;
  onSuccess?: (data: any) => void;
  onClose: () => void;
}

const SendSmsActionComponent: React.FC<SendSmsActionComponentProps> = ({
  action,
  isEdit,
  agentId,
  onSuccess,
  onClose
}) => {
  const [actionName, setActionName] = useState('');
  const [direction, setDirection] = useState('inbound');
  const [trigger, setTrigger] = useState('call_ended');
  const [sendNumberType, setSendNumberType] = useState('Custom phone number');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsContent, setSmsContent] = useState<string>('');
  const [actionPrompt, setActionPrompt] = useState('');
  const [smsContentHTML, setSmsContentHTML] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen,setIsOpen] = useState(true);
  const [endCallVariables, setEndCallVariables] = useState<any>([]);
  const [duringCallVariables, setDuringCallVariables] = useState<any>([]);


  useEffect(() => {
    if (action?.id && isEdit) {
      setActionName(action?.name || '');
      setTrigger(action?.trigger || 'call_ended');
      setPhoneNumber(action?.data?.to_number || '');
      setSendNumberType(action?.data?.to_number ? 'Custom phone number' : 'Contact number');
      setSmsContent(action?.data?.body || '');
      setSmsContentHTML(action?.data?.raw_body || action?.data?.body || '');
      setDirection(action?.call_type || 'inbound');
      setActionPrompt(action?.data?.action_prompt || '');
    }
    getActionVariables('call_ended', {agent: agentId}).then(resp => {
      let responseList = resp.data.data;
      let variableList = [];
      for(var index=0;index < responseList?.length;index++) {
        let variableObj = {
          id: index,
          field: responseList[index]?.field,
          label: responseList[index]?.label,
          type: responseList[index]?.type,
        }
        variableList.push(variableObj);
      }
      setEndCallVariables(variableList);
    }).catch((error) => {
      console.log(error);
    })
    getActionVariables('during_call', {agent: agentId}).then(resp => {
      let responseList = resp.data.data;
      let variableList = [];
      for(var index=0;index < responseList?.length;index++) {
        let variableObj = {
          id: index,
          field: responseList[index]?.field,
          label: responseList[index]?.label,
          type: responseList[index]?.type,
        }
        variableList.push(variableObj);
      }
      setDuringCallVariables(variableList);
    }).catch((error) => {
      console.log(error);
    })
  }, [action, isEdit]);

  const validateForm = (): boolean => {
    if (trigger === 'during_call' && actionPrompt.trim() === '') {
      // toastr.error('Enter valid details');
      return false;
    }
    if (sendNumberType === 'Custom phone number' && phoneNumber.trim() === '') {
      // toastr.error('Enter phone number');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    const input: Record<string, any> = {
      name: actionName,
      type: 'send_sms',
      call_type: direction,
      data: {
        to_number: phoneNumber,
        body: smsContent, //To be changes
        action_prompt: trigger === 'during_call' ? actionPrompt : null,
        raw_body: smsContentHTML,
      },
      trigger: trigger,
    };

    if (sendNumberType === 'Contact number') delete input.data?.to_number;

    try {
      const response = isEdit
        ? await updateAgentAction(agentId, action?.id, input)
        : await addAgentAction(agentId, input);

      // toastr.success(response.body.message);
      onSuccess?.(response.data.data);
    } catch (error) {
      console.error(error);
      // toastr.error(error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const contentChange = (text: string) => {
    setSmsContentHTML(text)
    setSmsContent(replaceSpansWithVariables(text))
  }

  const replaceSpansWithVariables = (html: string): string => {
      // Create a temporary DOM element to parse the HTML string
      const div = document.createElement("div");
      div.innerHTML = html;
  
      // Find all span elements with the 'variable' attribute
      const spans = div.querySelectorAll("span[variable]");
  
      spans.forEach((span) => {
        const variableName = span.getAttribute("variable");
        if (variableName) {
          span.replaceWith(`{{${variableName}}}`);
        }
      });
  
      // Convert HTML to plain text, while preserving formatting options
      let plainText = convert(div.innerHTML, {
        wordwrap: 100, // Set the word wrap column
        selectors: [
          { selector: "a", options: { ignoreHref: true, uppercase: false } }, // Ignore href in anchor tags
          { selector: "img", format: "skip" }, // Skip images
          { selector: "h1", options: { uppercase: false } },
          { selector: "h2", options: { uppercase: false } },
          { selector: "h3", options: { uppercase: false } },
          { selector: "h4", options: { uppercase: false } },
          { selector: "h5", options: { uppercase: false } },
          { selector: "h6", options: { uppercase: false } },
          { selector: "p", options: { uppercase: false } },
        ],
        preserveNewlines: true,
      });
  
      // Return the processed plain text
      return plainText;
    };

  return (
    <Sheet open={isOpen} onOpenChange={() => {setIsOpen(false); onClose();}}>
      <SheetContent className='p-0 w-[450px] !max-w-[450px]'>
        <div className="w-full h-full flex items-center justify-end">
          <div className="bg-white w-[448px] p-6 flex flex-col h-full gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center text-primary text-lg font-semibold leading-[30px]">
                <span>Send SMS</span>
              </div>
              <span className="text-secondary text-sm font-normal leading-[21px]">
                The agent will send the user an SMS with the desired content once the condition is met.
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium leading-[21px]">Action name</span>
                <Input
                  placeholder="e.g. confirmation sms"
                  value={actionName}
                  onChange={(e) => setActionName(e.target.value)}
                  className="border p-2 rounded-lg"
                />
              </div>
              <div className="flex gap-4">
                <div className="flex-1 flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium leading-[21px]">Call type</span>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button variant="outline" className='w-full justify-between font-normal'>{toPascalCase(direction)} <ChevronDown /></Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                      <DropdownMenuItem onClick={() => setDirection('inbound')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[200px]">
                        Inbound
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setDirection('outbound')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[200px]">
                        Outbound
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setDirection('both')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100 w-[200px]">
                        Both
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="flex-1 flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium leading-[21px]">Trigger</span>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button variant="outline" disabled={isEdit} className='w-full justify-between font-normal'>{trigger === 'during_call' ? 'During call' : 'After call'} <ChevronDown /></Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                      <DropdownMenuItem onClick={() => setTrigger('call_ended')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100">
                        After call
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setTrigger('during_call')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100">
                        During call
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              <div className="flex gap-4 items-end">
                <div className="flex-1 flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium leading-[21px]">Phone number to send SMS</span>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button variant="outline" className='w-full justify-between font-normal'>{sendNumberType} <ChevronDown /></Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white rounded-lg shadow-md border border-gray-200 p-1">
                      <DropdownMenuItem onClick={() => setSendNumberType('Custom phone number')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100">
                        Custom phone number
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => setSendNumberType('Contact number')} className="p-2 rounded-md cursor-pointer hover:bg-gray-100">
                        Contact number
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                {sendNumberType === 'Custom phone number' && (
                  <div className="flex-1 flex flex-col gap-1">
                    <Input
                      placeholder="+15551234567"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="border p-2 rounded-lg"
                    />
                  </div>
                )}
              </div>
              {trigger === 'during_call' && (
                <div className="flex flex-col gap-1">
                  <span className="text-primary text-sm font-medium leading-[21px]">When should the SMS be sent?</span>
                  <Textarea
                    placeholder="e.g. If the user reaches for general queries or needs confirmation and then sends an SMS."
                    className="h-[100px] border p-2 rounded-lg"
                    value={actionPrompt}
                    onChange={(e) => setActionPrompt(e.target.value)}
                  />
                </div>
              )}
              <div className="flex flex-col gap-1">
                <span className="text-primary text-sm font-medium leading-[21px]">SMS Content</span>
                {trigger === 'during_call' && <CommandsInput
                  inputText={smsContentHTML}
                  height="100px"
                  variables={duringCallVariables}
                  onChange={contentChange}
                  placeholder="Write something or ‘/’ to insert variables..." 
                />}
                {trigger === 'call_ended' && <CommandsInput
                  inputText={smsContentHTML}
                  height="100px"
                  variables={endCallVariables}
                  onChange={contentChange}
                  placeholder="Write something or ‘/’ to insert variables..." 
                />}
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                disabled={isLoading || actionName === '' || smsContentHTML === ''}
                onClick={handleSubmit}
                className="bg-primary text-white p-2 rounded-lg"
              >
                {isLoading ? <span className="flex gap-2 align-center"><Loader2 className="animate-spin" /> Loading...</span> : <span>{isEdit ? 'Save changes' : 'Add action'}</span>}
              </Button>
            </div>
          </div>
          <div className="relative mt-4">
            <div className="flex justify-center items-start h-[300px] overflow-hidden">
              <img src="../../../../../assets/images/mobile_preview.png" alt="" className="absolute w-[280px] h-[565px] object-cover" />
            </div>
            <div className="absolute top-[118px] left-[88px] w-[200px] max-h-[180px] overflow-auto space-y-1">
              <div className="bg-[#F8FAFC] rounded-lg p-2 text-sm font-normal leading-[21px] text-left break-words" dangerouslySetInnerHTML={{ __html: smsContentHTML }} />
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SendSmsActionComponent;
