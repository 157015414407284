import { UserRound } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface ProfileIconProps {
  name: string;
  iconSize?: string;
  size?: string;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ name, iconSize = '24px', size = '48px' }) => {
  const [letters, setLetters] = useState<string>('');

  useEffect(() => {
    if (name) {
      const words = name.split(' ').filter(word => word !== '');
      if (words.length > 1) {
        setLetters((words[0][0] + words[1][0]).toUpperCase());
      } else {
        setLetters(words[0].substring(0, 2).toUpperCase());
      }
    } else {
      setLetters('');
    }
  }, [name]);

  return (
    <div className="bg-gray-200 rounded-full flex items-center justify-center font-inter text-base font-medium leading-6 text-left text-gray-700" style={{ width: size, height: size }}>
      {letters === '' ? (
        <UserRound size={iconSize} color='#FFFFFF'/>
      ) : (
        <span>{letters}</span>
      )}
    </div>
  );
};

export default ProfileIcon;