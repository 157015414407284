import React, { useState, useEffect } from "react";
import { buyCloudNumber, searchAreaCode } from "../../../lib/voice.utils";
import { AiAgent } from "../../../models/ai-agent";
import { CloudNumber } from "../../../models/cloud-number";
import Toaster from "../../shared/customToast";
import useSharedStore from "../../../store/shared.store";
import { Button } from "../../shadcn/button";
import { CircleCheck, Loader2 } from "lucide-react";
import { Input } from "../../shadcn/input";
import { Skeleton } from "../../shadcn/skeleton";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { ScrollArea } from "../../shadcn/scrollArea";

const AddPhoneNumber: React.FC<{
  onClose: (success: boolean) => void;
  onSuccess: (number: CloudNumber) => void;
}> = ({ onClose, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [areaCode, setAreaCode] = useState("");
  const [agent, setAgent] = useState<AiAgent | null>(null);
  const [numbers, setNumbers] = useState<
    {
      capabilities: {};
      friendly_name: string;
      iso_country: string;
      phone_number: string;
    }[]
  >([]);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [buyNumber, setBuyNumber] = useState(false);
  const loaderList = [1, 2, 3, 4, 5, 6];

  const sharedStore = useSharedStore();
  useEffect(() => {
    setIsOpen(true);
  }, []);

  const addNumber = async () => {
    setBuyNumber(true);
    const input: Record<string, any> = {
      inbox_name: "Phone Number 1",
    };
    if (agent?.id) input["agent_id"] = agent.id;
    if (selectedNumber) input["phone_number"] = selectedNumber;
    try {
      const response = await buyCloudNumber(input);
      Toaster.success(response.data.message);
      sharedStore.cloudNumbers.push(new CloudNumber(response.data.data));
      setIsOpen(false);
      onSuccess(new CloudNumber(response.data.data));
    } catch (error) {
      console.error(error);
      Toaster.error(JSON.stringify(error));
    } finally {
      setBuyNumber(false);
    }
  };


  useEffect(() => {
    setIsLoading(true);
    searchAreaCode(areaCode).then((response) => {
      setNumbers(response?.data?.data);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    })
  }, [areaCode])

  const numberSelected = (selectedNum: string) => {
    setSelectedNumber(selectedNum);
  };

  return (
    <Dialog open={true} onOpenChange={() =>{ setIsOpen(false); onClose(true);}}>
      <DialogContent className="p-6">
        <div className="card-fields">
          <div className="flex justify-between items-center font-inter text-2xl font-semibold leading-7 text-left text-primary">
            <span>Add USA number</span>
          </div>
        </div>
        <div className="billing-card-body flex flex-col gap-4">
          <div className="card-fields">
            <Input
              placeholder="Search area code (e.g. 202, 206)"
              value={areaCode}
              onChange={(e) => {
                setAreaCode(e.target.value);
              }}
            />
          </div>
          <ScrollArea className="number-list-wrap border border-gray-300 rounded-lg h-56">
            {!isLoading &&
              numbers.length > 0 &&
              numbers.map((num) => (
                <div
                  key={num.phone_number}
                  onClick={() => numberSelected(num.phone_number)}
                  className={`search-numbers ${
                    num.phone_number === selectedNumber ? "bg-gray-200" : ""
                  } h-9 flex justify-between items-center pl-2 cursor-pointer font-inter text-sm font-medium leading-5 text-left text-primary mb-0.5 border-b border-gray-300`}
                >
                  <span>{num.phone_number}</span>
                  {num.phone_number === selectedNumber && (
                    <CircleCheck size="16px" />
                  )}
                </div>
              ))}
            {isLoading &&
                <div className="flex flex-col gap-3 h-56">
                  {loaderList.map((loader) => (
                    <div className="flex w-full flex-col">
                      <Skeleton className="h-6 bg-gray-300 rounded w-full" />
                    </div>
                ))}
                </div>
            }
            {!isLoading && numbers.length === 0 && (
              <div className="flex items-center justify-center h-56">
                <p className="text-black text-sm font-medium leading-5 text-center">
                  No results found. <br />
                  Please check the area code and try again.
                </p>
              </div>
            )}
          </ScrollArea>
        </div>
        <div className="footer-buttons flex justify-end">
          <Button disabled={isLoading || buyNumber} onClick={addNumber}>
            {buyNumber ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Please wait
              </>
            ) : (
              "Add phone number"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddPhoneNumber;
