import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getAllSubscriptions, getBillingDetails } from "../../lib/common.utils";
import { SubscriptionPlan } from "../../models/subscription_plan";
import useSharedStore from "../../store/shared.store";
import { useSubscriptionStore } from "../../store/subscription.store";
import useWalletStore from "../../store/wallet.store";
import BillingForm from "../shared/BillingForm";
import { BillingDetails } from "../../models/billing-details";
import SubscriptionPlanCard from "../shared/SubscriptionPlan";
import { ArrowLeft } from "lucide-react";
import { Skeleton } from "../shadcn/skeleton";
import { Business } from "../../models/business";
import Toaster from "../shared/customToast";
import { Tabs, TabsList, TabsTrigger } from "../shadcn/tabs";

const SubscriptionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [billingDetails, setBillingDetails] = useState<BillingDetails[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [loaderBuyPlan, setLoaderBuyPlan] = useState(null);
  const [billingForm, setBillingForm] = useState(false);
  const [checkoutObject, setCheckoutObject] = useState<any>(null);
  const walletStore = useWalletStore();
  const subscriptionStore = useSubscriptionStore();
  const sharedStore = useSharedStore();
  const [stripe, setStripe] = useState<Stripe>();
  const [duration, setDuration] = useState("monthly");

  useEffect(() => {
    const initializeStripe = async () => {
      const keys = sharedStore.paymentGatewayKeys; // Replace with actual service
      const stripeInstance = await loadStripe(
        keys.is_default ? keys.default : keys.stripe
      );
      if (stripeInstance) setStripe(stripeInstance);
    };
    initializeStripe();
    // fetchInitialData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("order_id") && localStorage.getItem("payment_id")) {
      let input = {
        order_id: params.get("order_id"),
        payment_id: localStorage.getItem("payment_id"),
      };
      subscriptionStore
        .validateSubscriptionOrder(input)
        .then((resp) => {
          let business = JSON.parse(localStorage.getItem("business")!);
          if (business && business.subscription) {
          } else business = { subscription: {} };
          business.subscription = resp;
          localStorage.setItem("business", JSON.stringify(business));
          sharedStore.setBusiness(new Business(business));
          subscriptionStore.setSubscription(resp.data);
          getAllSubscriptions();
          Toaster.success("Payment Successful");
          localStorage.removeItem("payment_id");
          navigate("/billing/wallet");
        })
        .catch((error) => {
          console.error(error);
          Toaster.error(error);
        });
    } else fetchInitialData();
  }, [location.search]);

  const fetchInitialData = async () => {
    try {
      getAllSubscriptions();
      const plansResponse = await subscriptionStore.getPlans();
      if (plansResponse.data) {
        let plansList: SubscriptionPlan[] = [];
        plansResponse.data.forEach((element: any) => {
          plansList.push(new SubscriptionPlan(element));
        });
        setPlans(plansList);
        if (plansResponse.data.length) {
          const lastPlan = plansResponse.data[plansResponse.data.length - 1];
          const diff = lastPlan.monthly_price - lastPlan.yearly_price;
          setPercentage(Math.round((diff / lastPlan.monthly_price) * 100));
        }
      }
      const resp = await subscriptionStore.getBillingDetails();
      console.log(resp);
      if (resp.code === 200) {
        let responseList = resp.data;
        let billingDetails: BillingDetails[] = [];
        responseList?.forEach((element: any) => {
          billingDetails.push(new BillingDetails(element));
        });
        subscriptionStore.setBillingDetails(billingDetails);
        setBillingDetails(billingDetails);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const buyPlan = async (plan: any, term: any) => {
    if (subscriptionStore.billingDetails.length && subscriptionStore.billingDetails[0].id) {
      setLoaderBuyPlan(plan.id);
      try {
        const response = await subscriptionStore.createSubscriptionOrder({
          plan_id: plan.id,
          billing_id: subscriptionStore.billingDetails[0].id,
          duration: term,
        });
        localStorage.setItem("payment_id", response.data.payment_id);
        stripe?.redirectToCheckout({ sessionId: response.data.payment_id });
      } catch (error) {
        console.error(error);
      } finally {
        setLoaderBuyPlan(null);
      }
    } else {
      setBillingForm(true);
      setCheckoutObject({ plan, term });
    }
  };

  const billingAdded = (billing: any) => {
    setBillingForm(false);
    buyPlan(checkoutObject.plan, checkoutObject.term);
  };

  return (
    <div className="bg-[var(--bg-color)] w-full h-full overflow-auto">
      <div className="h-[64px] p-4 border-b border-[var(--border-color)] font-inter text-[20px] font-medium leading-[30px] text-left flex justify-between text-[var(--primary-color)]">
        <span
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => navigate("/billing/wallet")}
        >
          <ArrowLeft size={"20px"} /> Plan Pricing
        </span>
      </div>
      <div className="flex flex-col gap-4 p-6 items-center">
        <div className="flex flex-col gap-1 items-center">
          <span className="font-inter text-[20px] font-medium leading-[30px] text-left text-[var(--primary-color)]">
            Choose the plan that’s right for you
          </span>
          <span className="font-inter text-[14px] font-normal leading-[21px] text-left text-[var(--secondary-color)]">
            Flexible pricing to fit your unique needs.
          </span>
        </div>
        <Tabs defaultValue={duration} >
          <TabsList>
            <TabsTrigger
              onClick={() => {
                setDuration("monthly");
              }}
              value="monthly"
            >
              Monthly
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setDuration("annually");
              }}
              value="annually"
            >
              Annually {" "}
              <span className="bg-[#EDEBFE] px-2 py-1 rounded-md text-[10px] font-medium text-[#5521B5]" style={{ lineHeight: "12px" }}>
                Save {percentage}%
              </span>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="flex gap-4 p-6">
          {plans.length
            ? plans.map((plan: SubscriptionPlan) => (
                <SubscriptionPlanCard
                  key={plan.id}
                  plan={plan}
                  isMonthly={duration === "monthly"}
                  onPlanBuy={(data) => buyPlan(data.plan, data.duration)}
                  loader={loaderBuyPlan === plan?.id}
                  numberAddOnDetails={walletStore.numberAddOnDetails}
                  aiAgentAddOnDetails={walletStore.aiAgentAddOnDetails}
                  userAddOnDetails={walletStore.userAddOnDetails}
                  infobaseAddOnDetails={walletStore.infobaseAddOnDetails}
                  concurrentAddOnDetails={walletStore.concurrentAddOnDetails}
                />
              ))
            : [1, 2, 3].map((i) => (
                <div
                  key={i}
                  className="border border-[var(--border-color)] max-w-[325px] w-full min-w-[240px] rounded-lg p-4 flex flex-col gap-4"
                >
                  <div className="w-full gap-4 flex flex-col">
                    {Array.from({ length: 10 }).map((_, index) => (
                      <Skeleton key={index} className="h-8 w-full" />
                    ))}
                  </div>
                </div>
              ))}
        </div>
      </div>
      {billingForm && (
        <BillingForm
          onClose={() => setBillingForm(false)}
          onSave={billingAdded}
        />
      )}
    </div>
  );
};

export default SubscriptionComponent;
