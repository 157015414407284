import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import { Button } from "../../shadcn/button";
import Toaster from "../../shared/customToast";
import { Agent } from "../../../models/team-member";
import useSharedStore from "../../../store/shared.store";
import { useAgencyStore } from "../../../store/agency.store";
import { addUser, getAgentRoles, updateAgent, updateUser } from "../../../lib/agents.utils";
import { GlobalStrings } from "../../../lib/constants";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn/dropdown-menu";
import { ChevronDown, EllipsisVertical } from "lucide-react";

interface AddUserProps {
  onClose: () => void;
  onSuccess: (agent: Agent) => void;
  onEdit: (agent: Agent) => void;
  agent?: Agent;
}

const AddUser: React.FC<AddUserProps> = ({ onClose, onSuccess, agent, onEdit }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(agent?.name || "");
  const [email, setEmail] = useState(agent?.email || "");
  const [emailValid, setEmailValid] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [contactList, setContactList] = useState<any[]>([]);

  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();

  useEffect(() => {
    setIsOpen(true);
    getAgentRoles(1).then(
      (resp: any) => {
        console.log(resp);
        setContactList(resp.data.data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, []);

  useEffect(() => {
    if(agent?.id) {
      setName(agent?.name);
      setEmail(agent?.email);
      setSelectedRole(agent?.role);
    }
  }, [agent])

  const inviteUser = () => {
    setIsLoading(true);
    if(agent?.id) {
      const input = { role: selectedRole?.id };
      updateUser(input, agent?.id)
        .then((resp) => {
          Toaster.success(resp.data.message);
          onEdit(new Agent({
            ...agent.toJson(),
            role: selectedRole
          }));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          Toaster.error(error);
          setIsLoading(false);
        });
    } else {
      const input = { name, email, role: selectedRole?.id };
      addUser(input)
        .then((resp) => {
          Toaster.success(resp.data.message);
          onSuccess(new Agent(resp.data.data));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          Toaster.error(error);
          setIsLoading(false);
        });
    }

  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    const emailRegex = GlobalStrings.emailRegex;
    if (emailRegex.test(emailValue)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
        <div className="flex flex-col gap-2">
          <div className="text-[var(--primary-color)] font-inter font-semibold text-[20px] leading-30">
            {agent?.id ? `Update user` : `Invite others to ${sharedStore.business?.name}`}
          </div>
          {!agent?.id && <span className="text-gray-500 text-[14px]">
            Invite others to collaborate in {agencyStore.brand}. Each invite is
            specific to an email address and expires in 3 days.
          </span>}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-medium text-[14px] leading-21">
              Email address
            </span>
            <Input
              type="email"
              disabled={agent?.id ? true : false}
              placeholder="m@example.com"
              value={email}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-medium text-[14px] leading-21">
              Name
            </span>
            <Input
              type="text"
              disabled={agent?.id ? true : false}
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-medium text-[14px] leading-21">
              Role
            </span>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="outline"
                  className="w-full justify-between font-normal"
                >
                  {selectedRole?.name ?? <span className="text-secondary" >Select role</span>} <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white w-[466px] rounded-lg shadow-md border border-gray-200 p-1 overflow-auto max-h-[300px]">
                {contactList.map((role) => (
                  <DropdownMenuItem
                    key={role.id}
                    onClick={() => setSelectedRole(role)}
                    className="p-2 w-full font-normal text-sm rounded-md cursor-pointer hover:bg-gray-100"
                  >
                    {role.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex justify-end p-2">
          {agent?.id ? 
          <Button disabled={isLoading} onClick={inviteUser}>{isLoading ? "Loading..." : "Update user"}</Button> :
           <Button
            disabled={!name || !emailValid || isLoading || !selectedRole?.id}
            onClick={inviteUser}
          >
            {isLoading ? "Loading..." : "Invite user"}
          </Button>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddUser;
