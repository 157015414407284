export class InfoBase {
    private _id: number
    private _name: string
    private _asset_url: string
    private _asset_type: string
    private _created_at: string
    private _updated_at: string
    private _business: number
    private _added_by: number
    private _user: InfoBaseUser;
    private _content: string;
    private _enable_auto_refresh: boolean;
    private _status: string;
    private _info_base_id: number
    private _asset_json: any[]
  
      public get asset_json(): any[] {
          return this._asset_json;
      }
  
      public set asset_json(asset_json: any[]) {
          this._asset_json = asset_json;
      }
  
  
      public get info_base_id(): number {
          return this._info_base_id;
      }
  
      public set info_base_id(info_base_id: number) {
          this._info_base_id = info_base_id;
      }
  
  
      public get status(): string {
          return this._status;
      }
  
      public set status(status: string) {
          this._status = status;
      }
  
  
      public get enable_auto_refresh(): boolean {
          return this._enable_auto_refresh;
      }
  
      public set enable_auto_refresh(enable_auto_refresh: boolean) {
          this._enable_auto_refresh = enable_auto_refresh;
      }
  
  
      public get content(): string {
          return this._content;
      }
  
      public set content(content: string) {
          this._content = content;
      }
  
  
    constructor(infoBaseJson: any) {
      this._id = infoBaseJson?.id;
      this._name = infoBaseJson?.name;
      this._asset_url = infoBaseJson?.asset_url;
      this._asset_type = infoBaseJson?.asset_type;
      this._created_at = infoBaseJson?.created_at;
      this._updated_at = infoBaseJson?.updated_at;
      this._business = infoBaseJson?.business;
      this._added_by = infoBaseJson?.added_by;
      this._user = new InfoBaseUser(infoBaseJson?.user);
      this._content = infoBaseJson?.content;
      this._enable_auto_refresh = infoBaseJson?.enable_auto_refresh;
      this._status = infoBaseJson?.status;
      this._info_base_id = infoBaseJson?.info_base_id
      this._asset_json = infoBaseJson?.asset_json;
    }

    public toJson() {
        return {
          id: this._id,
          name: this._name,
          asset_url: this._asset_url,
          asset_type: this._asset_type,
          created_at: this._created_at,
          updated_at: this._updated_at,
          business: this._business,
          added_by: this._added_by,
          user: this._user.toJson(),
          content: this._content,
          enable_auto_refresh: this._enable_auto_refresh,
          status: this._status,
          info_base_id: this._info_base_id,
          asset_json: this._asset_json,
        };
      }
  
      public get id(): number {
          return this._id;
      }
  
      public set id(id: number) {
          this._id = id;
      }
  
      public get name(): string {
          return this._name;
      }
  
      public set name(name: string) {
          this._name = name;
      }
  
      public get asset_url(): string {
          return this._asset_url;
      }
  
      public set asset_url(asset_url: string) {
          this._asset_url = asset_url;
      }
  
      public get asset_type(): string {
          return this._asset_type;
      }
  
      public set asset_type(asset_type: string) {
          this._asset_type = asset_type;
      }
  
      public get created_at(): string {
          return this._created_at;
      }
  
      public set created_at(created_at: string) {
          this._created_at = created_at;
      }
  
      public get updated_at(): string {
          return this._updated_at;
      }
  
      public set updated_at(updated_at: string) {
          this._updated_at = updated_at;
      }
  
      public get business(): number {
          return this._business;
      }
  
      public set business(business: number) {
          this._business = business;
      }
  
      public get added_by(): number {
          return this._added_by;
      }
  
      public set added_by(added_by: number) {
          this._added_by = added_by;
      }
  
      public get user(): InfoBaseUser {
          return this._user;
      }
  
      public set user(user: InfoBaseUser) {
          this._user = user;
      }
  
  }
  
  export class InfoBaseUser {
    private _id: number
    private _name: string
  
      public get id(): number {
          return this._id;
      }
  
      public set id(id: number) {
          this._id = id;
      }
  
      public get name(): string {
          return this._name;
      }
  
      public set name(name: string) {
          this._name = name;
      }
  
  
    constructor(infoBaseUserJson: any) {
      this._id = infoBaseUserJson?.id;
      this._name = infoBaseUserJson?.name;
    }

    public toJson() {
        return {
          id: this._id,
          name: this._name,
        };
      }
  }