import { importNumber } from "../../../lib/voice.utils";
import React, { useState, useEffect } from "react";
import Toaster from "../../shared/customToast";
import useSharedStore from "../../../store/shared.store";
import { CloudNumber } from "../../../models/cloud-number";
import { Input } from "../../shadcn/input";
import { Button } from "../../shadcn/button";
import { Dialog, DialogContent } from "../../shadcn/dialog";

interface ImportNumberProps {
  onClose: () => void;
  onSuccess: (cloudNumber: CloudNumber) => void;
}

const ImportNumber: React.FC<ImportNumberProps> = ({ onClose, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [twilioSid, setTwilioSid] = useState("");
  const [twilioAuthToken, setTwilioAuthToken] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const sharedStore = useSharedStore();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onImportNumber = async () => {
    setIsLoading(true);
    const data = {
      auth_id: twilioSid,
      auth_sec: twilioAuthToken,
      number: phoneNumber,
    };
    try {
      const response = await importNumber(data);
      setIsLoading(false);
      if (response.status === 200) {
        Toaster.success(response.data.message);
        sharedStore.cloudNumbers.push(new CloudNumber(response.data));
        onSuccess(new CloudNumber(response.data));
      } else {
        Toaster.error(response.data.message);
      }
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() =>{ setIsOpen(false); onClose();}}>
      <DialogContent className="p-6 flex flex-col gap-4 bg-white rounded-lg w-500px">
        <div className="flex justify-between items-center text-[var(--primary-color)] font-inter font-semibold text-20 leading-30">
          <span>Import Twilio phone number</span>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              Twilio Phone Number
            </span>
            <Input
              type="text"
              placeholder=""
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              Twilio Account SID
            </span>
            <Input
              type="text"
              placeholder=""
              value={twilioSid}
              onChange={(e) => setTwilioSid(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1 w-400px">
            <span className="text-[var(--primary-color)] font-inter font-medium text-14 leading-21">
              Twilio Auth Token
            </span>
            <Input
              type="text"
              placeholder=""
              value={twilioAuthToken}
              onChange={(e) => setTwilioAuthToken(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end p-2">
          <Button disabled={isLoading} onClick={onImportNumber}>
            {isLoading ? "Loading..." : "Import from Twilio"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImportNumber;
