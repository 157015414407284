import React, { useState, useEffect } from "react";
import { Ellipsis, Plus, Sheet, X, ArrowLeft } from "lucide-react";
import {
  deleteAgentRole,
  deleteUser,
  getActiveUsers,
  getAgentPermissions,
  getAgentRoles,
  getPendingUsers,
  searchAgentRoles,
  searchUsers,
} from "../../../lib/agents.utils";
import { Customer } from "../../../models/customer";
import { LoggedUser } from "../../../models/logged-user";
import useSharedStore from "../../../store/shared.store";
import { Input } from "../../shadcn/input";
import { SheetContent } from "../../shadcn/sheet";
import { Skeleton } from "../../shadcn/skeleton";
import { ScrollArea } from "../../shadcn/scrollArea";
import { Button } from "../../shadcn/button";
import Pagination from "../../shared/Pagination";
import ProfileIcon from "../../shared/ProfileIcon";
import {
  formatDateToMonthDay,
  formatDateToMonthDayYear,
  toPascalCase,
} from "../../../lib/ui.utils";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../shadcn/dropdown-menu";
import Toaster from "../../shared/customToast";
import { useAgencyStore } from "../../../store/agency.store";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import AddRole from "./AddRole";
import NameTag from "../../shared/NameTag";
import { UserPermission, UserRole } from "../../../models/user-role";

interface UsersPermissionProps {
  onClose: () => void;
}

const UsersPermission: React.FC<UsersPermissionProps> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState<UserRole[]>([]);
  const [paginationSize, setPaginationSize] = useState<number>(20);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [paginatedPages, setPaginatedPages] = useState<Set<number>>(new Set());
  const [pagination, setPagination] = useState<boolean>(false);
  const [customerSearchTag, setCustomerSearchTag] = useState<string>("");
  const [prevCustomerSearchTag, setPrevCustomerSearchTag] =
    useState<string>("");
  const [permissions, setPermissions] = useState<UserPermission[]>([]);

  const [isAddRole, setIsAddRole] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<UserRole>(new UserRole(null));
  const [confirmationPopup, setConfirmationPopup] = useState<boolean>(false);
  const [deleteHeading, setDeleteHeading] = useState<string>("");
  const [deleteDescription, setDeleteDescription] = useState<string>("");
  const [negativeButton, setNegativeButton] = useState<string>("");
  const [positiveButton, setPositiveButton] = useState<string>("");
  const [userPermissionsPopup, setUserPermissionsPopup] =
    useState<boolean>(false);

  const sharedStore = useSharedStore();
  const agencyStore = useAgencyStore();

  const [listUpdated, setListUpdated] = useState<boolean>(false);


  useEffect(() => {
    sharedStore.inAuthModule = false;
    getCustomers(paginationPage);
    getAgentPermissions().then((resp) => {
      console.log(resp);
      let responseList: UserPermission[] = resp.data?.data?.map((element: any) => new UserPermission(element))
      setPermissions(responseList);
    });
  }, []);

  const getCustomers = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);
    setListUpdated(false);

    getAgentRoles(pageNumber).then(
      (resp: any) => {
        console.log(resp);
        if (resp.data.code === 200) {
          try {
            setPaginationSize(resp.data.size);
            setPagination(resp.data.count > resp.data.size);
            const updatedContactList = new Array<UserRole>(resp.data.count);

            if (pageNumber === 1) {
              for (let i = 0; i < resp.data.data.length; i++) {
                updatedContactList[i] = new UserRole(resp.data.data[i]);
              }
            } else {
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new UserRole(resp.data.data[i % paginationSize]);
              }
            }

            setContactList(updatedContactList);
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSearchTag(e.target.value);
  };

  useEffect(() => {
    if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      customerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      searchCampaign(1);
      setPaginationPage(1);
    } else if (
      prevCustomerSearchTag.trim() !== customerSearchTag.trim() &&
      prevCustomerSearchTag.length > 2
    ) {
      setPaginatedPages(new Set());
      setPaginationPage(1);
      getCustomers(1);
    }
  }, [customerSearchTag]);

  const searchCampaign = (pageNumber: number) => {
    if (paginatedPages.has(pageNumber)) return;

    setIsLoading(true);
    setPrevCustomerSearchTag(customerSearchTag);

    searchAgentRoles(customerSearchTag, pageNumber).then(
      (resp: any) => {
        if (resp.data.code === 200) {
          try {
            if (pageNumber === 1) {
              setPagination(resp.data.count > paginationSize);
              const responseList = new Array<UserRole>(resp.data?.count ?? 0);
              for (let i = 0; i < resp.data.data.length; i++) {
                responseList[i] = new UserRole(resp.data.data[i]);
              }
              setContactList(responseList);
            } else {
              const updatedContactList = [...contactList];
              for (
                let i = (pageNumber - 1) * paginationSize;
                i < (pageNumber - 1) * paginationSize + resp.data.data.length;
                i++
              ) {
                updatedContactList[i] = new UserRole(resp.data.data[i % paginationSize]);
              }
              setContactList(updatedContactList);
            }
            setPaginatedPages((prev) => new Set(prev).add(pageNumber));
          } catch (ex) {
            console.error(ex);
          }
        } else {
        }
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  const onCustomerAdded = () => {
    setPaginationPage(1);
    setPaginatedPages(new Set());
    setIsAddRole(false);
    setSelectedRole(new UserRole(null));
    setListUpdated(true);
  };

  useEffect(() => {
    if(listUpdated) {
      getCustomers(1);
    }
  }, [listUpdated])

  const openDeleteConfirmation = (user: UserRole) => {
    setSelectedRole(user);
    setPositiveButton("Yes, cancel role");
    setDeleteHeading("Confirm cancel role");
    setDeleteDescription(
      `The role will be deleted, and all users under <span class="col-pri">${user?.name}</span> will no longer be able to access ${sharedStore.business?.name} on ${agencyStore.brand}.`
    );
    setNegativeButton("No, keep role");
    setConfirmationPopup(true);
  };

  const deleteRole = () => {
    setConfirmationPopup(false);
    deleteAgentRole(selectedRole?.id)
      .then((resp) => {
        Toaster.success(resp.data.message);
      })
      .catch((error) => {
        Toaster.error(error);
        console.log(error);
      });

    setContactList((prev) =>
      prev.filter((contact) => contact.id !== selectedRole?.id)
    );
    setSelectedRole(new UserRole(null));
  };

  const editRole = (role: UserRole) => {
    setSelectedRole(role);
    setIsAddRole(true);
  };

  // useEffect(() => {
  //   if (selectedRole?.id) {
  //     setIsAddRole(true);
  //   }
  // }, [selectedRole]);

  return (
    <div className="container mx-auto p-0">
      <div className="bg-white p-6 flex flex-col gap-4">
        <div className="py-2 cursor-pointer" onClick={() => onClose()}>
          <span className="flex gap-2 items-center px-2 text-sm font-medium text-left text-[var(--secondary-color)] rounded hover:bg-hover-color">
            <ArrowLeft size="20px" color="#64748B" /> Users
          </span>
        </div>
        {/* Header Section */}
        <div className="flex justify-between align-center">
          <div className="flex flex-col gap-1">
            <h1 className="text-xl font-bold text-gray-800">
              Roles & permissions
            </h1>
            <span className="text-sm text-secondary font-normal">Manage all roles and permissions in your workspace</span>
          </div>
          <Button onClick={() => setIsAddRole(true)}>
            <Plus /> Add role
          </Button>
        </div>

        {/* Search Input */}
        <div className=" w-full flex justify-between items-center">
          <Input
            type="text"
            className="w-fit"
            placeholder="Search Roles"
            value={customerSearchTag}
            onChange={handleSearchInput}
          />
        </div>

        {isLoading ? (
          <div className="border border-gray-300 rounded-lg flex w-full h-auto gap-4 p-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="w-full gap-4 flex flex-col">
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton key={index} className="h-8 w-full" />
                ))}
              </div>
            ))}
          </div>
        ) : contactList.length > 0 ? (
          <div className="overflow-x-auto border border-gray-300 rounded-lg">
            <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
              <thead className="bg-gray-100 text-gray-700 border-b border-gray-300 bg-gray-100 sticky top-0 z-10 shadow-sm">
                <tr className="border-b border-gray-200 font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                  <th
                    style={{ borderRadius: "8px 0 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  >
                    Roles
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Users
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Updated
                  </th>
                  <th className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4">
                    Updated by
                  </th>
                  <th
                    style={{ borderRadius: "0 8px 0 0" }}
                    className="font-inter text-xs font-medium leading-5 text-left text-secondary bg-gray-100 py-2 px-4"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {contactList.map((user, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 cursor-pointer border-b border-gray-300 last:border-b-0"
                  >
                    <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                      {toPascalCase(user?.name)}
                    </td>
                    <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                      {user?.user_count}
                    </td>
                    <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                      {formatDateToMonthDayYear(
                        user?.updated_at ?? user?.created_at
                      )}
                    </td>
                    <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[120px]">
                      <NameTag name={sharedStore.business?.admin?.name ?? ""} />
                    </td>
                    <td className="font-inter text-sm font-normal leading-6 text-left text-primary py-4 px-2 min-w-[50px]">
                      {(
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <button className="p-0 border-none bg-transparent">
                              <Ellipsis className="w-5 h-5 text-gray-500" />
                            </button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="bg-white border border-gray-300 w-40 rounded-lg p-2.5">
                            <DropdownMenuItem
                              className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                              onSelect={() => editRole(user)}
                            >
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="p-2.5 rounded-lg cursor-pointer hover:bg-gray-200"
                              onSelect={() => openDeleteConfirmation(user)}
                            >
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="empty-screen">
            <div className="empty-card">
              <img
                src="../../../../assets/images/contacts_empty.png"
                alt="Start uploading files"
              />
              <span>No roles found</span>
            </div>
          </div>
        )}
        {pagination && !isLoading && (
          <div className="pagination-container">
            <Pagination
              currentPage={paginationPage}
              listLength={contactList.length}
              paginationSize={paginationSize}
              onPageChange={(page) => {
                setPaginationPage(page);
                getCustomers(page);
              }}
            />
          </div>
        )}
        {confirmationPopup && (
          <ConfirmationPopup
            positiveButtonText={positiveButton}
            negativeButtonText={negativeButton}
            heading={deleteHeading}
            description={deleteDescription}
            onClose={() => setConfirmationPopup(false)}
            onSuccess={deleteRole}
          />
        )}
        {isAddRole && (
          <AddRole
            onClose={() => {setIsAddRole(false); setSelectedRole(new UserRole(null))}}
            onSuccess={onCustomerAdded}
            onEdit={onCustomerAdded}
            role={selectedRole}
            permissions={permissions}
          />
        )}
      </div>
    </div>
  );
};

export default UsersPermission;
